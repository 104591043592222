import { createAsyncThunk } from '@reduxjs/toolkit';
import { Promocode, UsePromocodeDto } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';

import { ExtraParamsThunkType } from '../../store/types';
import { SerializedError } from '../auth/types';

export const applyPromocode = createAsyncThunk<Promocode, UsePromocodeDto, ExtraParamsThunkType<SerializedError>>(
  'promocode/use',
  async (usePromocodeDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PromocodesApi.promocodeControllerUse(usePromocodeDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
