import * as React from 'react';

const NutritionPlan = () => (
  <svg width={70} height={73} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.871 8.095h25.8a2.141 2.141 0 0 0 2.141-2.212V2.19A2.083 2.083 0 0 0 39.671 0H13.788a2.15 2.15 0 0 0-2.176 2.188v3.694a2.211 2.211 0 0 0 2.176 2.176"
      fill="#DADADA"
    />
    <path
      d="M34.67 59.13c0 .211 0 .423.084.635h-12.59a1.177 1.177 0 0 1 0-2.353h12.319c0 .435.07.882.13 1.33-.56-3.677.211-7.431 2.176-10.589.445-.678.949-1.316 1.505-1.906l-.176.164-15.953.001a1.177 1.177 0 0 1 0-2.353h18.824l-.117.082a14.012 14.012 0 0 1 4.6-1.858 14.28 14.28 0 0 1 1.518-.248 10.706 10.706 0 0 0-2.353-1.541 3.528 3.528 0 0 1-1.15-5.383 3.53 3.53 0 0 1 2.761-1.3 3.607 3.607 0 0 1 1.6.389c1.159.586 2.248 1.3 3.247 2.13v-.083a8.607 8.607 0 0 1 2.353-2.188V9.553A5.577 5.577 0 0 0 47.989 4h-3.835v1.882a4.492 4.492 0 0 1-4.482 4.565H13.789A4.54 4.54 0 0 1 9.33 5.882V4H5.553A5.576 5.576 0 0 0 0 9.553V63.6a5.576 5.576 0 0 0 5.553 5.553h33.2a20.844 20.844 0 0 1-3.788-8.506 25.705 25.705 0 0 1-.26-1.518h-.034ZM22.165 17.26h23.718a1.176 1.176 0 1 1 0 2.352H22.164a1.177 1.177 0 0 1 0-2.353Zm0 13.388h23.718a1.177 1.177 0 1 1 0 2.353H22.164a1.177 1.177 0 0 1 0-2.354ZM16.8 56.07l-4.812 6.447c-.214.287-.548.46-.906.471-.344 0-.67-.15-.894-.412l-2.352-2.764h-.001a1.177 1.177 0 1 1 1.777-1.542L10.976 60l3.988-5.342a1.177 1.177 0 0 1 1.883 1.412H16.8Zm.047-13.389-4.812 6.46c-.215.286-.548.46-.906.47-.344 0-.67-.15-.894-.412l-2.353-2.764a1.177 1.177 0 1 1 1.777-1.541l1.446 1.67 3.86-5.282a1.177 1.177 0 1 1 1.882 1.4Zm0-13.388-4.812 6.459c-.215.286-.548.46-.906.47a1.173 1.173 0 0 1-.894-.4l-2.353-2.764a1.18 1.18 0 0 1 1.776-1.553l1.447 1.67 3.86-5.282a1.176 1.176 0 1 1 1.882 1.4Zm0-13.376-4.86 6.435c-.214.287-.547.46-.905.47a1.174 1.174 0 0 1-.894-.4l-2.353-2.764a1.176 1.176 0 0 1 1.777-1.541l1.447 1.659 3.905-5.271a1.177 1.177 0 0 1 1.882 1.412"
      fill="#DADADA"
    />
    <path
      d="M60.766 44.555a11.905 11.905 0 0 0-6.66.47 13.018 13.018 0 0 0-.576-1.694 6.024 6.024 0 0 0 4.094-2.53 6.294 6.294 0 0 0 .718-5.74 6.13 6.13 0 0 0-5.212 2.505 6.666 6.666 0 0 0-1.176 2.976 14.276 14.276 0 0 0-5.153-4.27 1.176 1.176 0 0 0-1.071 2.094 11.247 11.247 0 0 1 5.8 6.342 12.02 12.02 0 0 0-5.576-.13c-6.377 1.353-10.506 7.365-8.706 15.623 1.8 8.259 8.306 13.918 14.717 12.541.447-.082.894-.27 1.341-.352.447-.083.988.27 1.447.352 6.365 1.377 13.012-4.306 14.719-12.564 1.706-8.26-2.353-14.271-8.706-15.623"
      fill="#DADADA"
    />
  </svg>
);

export default NutritionPlan;
