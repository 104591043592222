import classnames from 'classnames';
import { FC } from 'react';

import styles from './style.module.scss';

interface TabsProp {
  active: boolean;
  toggleTabs: () => void;
  leftTitle: string;
  rightTitle: string;
}

const TabsMini: FC<TabsProp> = ({ active, toggleTabs, leftTitle, rightTitle }) => {
  return (
    <div className={styles.tabs}>
      <ul className={'flexbox'}>
        <button
          type="button"
          onClick={toggleTabs}
          className={classnames(styles.tabs__button, active && styles['tabs__button_active-left'])}>
          <li className={classnames(styles.tabs__tab, active && styles.tabs__active)}>{leftTitle}</li>
        </button>
        <button
          type="button"
          onClick={toggleTabs}
          className={classnames(styles.tabs__button, !active && styles['tabs__button_active-right'])}>
          <li className={classnames(styles.tabs__tab, !active && styles.tabs__active)}>{rightTitle}</li>
        </button>
      </ul>
    </div>
  );
};

export default TabsMini;
