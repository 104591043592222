import { PatientDiaryDayEnum } from 'api/generated';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { PatientStore } from './types';

export const selectPatient = (state: RootState): PatientStore => state.patient;

export const selectPatientData = createSelector(selectPatient, (patient) => patient.me);
export const selectAnamnes = createSelector(selectPatient, (patient) => patient.anamnes);
export const selectPatientDiary = createSelector(selectPatient, (patient) => patient.diary);
export const selectPatientId = createSelector(selectPatient, (patient) => patient.me.id);
export const selectPatientName = createSelector(selectPatient, (patient) => patient.me.name);
export const selectPatientEmail = createSelector(selectPatient, (patient) => patient.me.email);
export const selectPatientAge = createSelector(selectPatient, (patient) => patient.me.age);
export const selectPatientGender = createSelector(selectPatient, (patient) => patient.me.gender);

export const selectPatientIsOnboardingCompleted = createSelector(
  selectPatient,
  (patient) => patient.me.isOnboardingCompleted,
);
export const selectPatientBirthday = createSelector(selectPatient, (patient) => patient.me.birthday);

export const selectUpdatingPatientEmailStatus = createSelector(
  selectPatient,
  (patient) => patient.updatingPatientEmailStatus,
);
export const selectAnamnesisStatus = createSelector(selectPatient, (patient) => patient.anamnesisStatus);
export const selectMutationAnamnesisStatus = createSelector(
  selectPatient,
  (patient) => patient.mutationAnamnesisStatus,
);

export const selectUpdatingPatientStatus = createSelector(selectPatient, (patient) => patient.loading);
export const selectDeletingPatientStatus = createSelector(selectPatient, (patient) => patient.deletingPatientStatus);
export const selectGetPatientStatus = createSelector(selectPatient, (patient) => patient.getPatientStatus);
export const selectPatientDiaryStatus = createSelector(selectPatient, (patient) => patient.diaryStatus);

export const selectPatientDiaryPageByDay = (day: PatientDiaryDayEnum) =>
  createSelector(selectPatientDiary, (diary) => diary.find((page) => page.day === day));

export const selectGetNutritionPlan = createSelector(selectPatient, (patient) => patient.nutrionPlan);

export const selectPatientExpert = createSelector(selectPatient, (patient) => patient.expert);

export const selectisNewlyPrescribedPlan = createSelector(
  selectPatient,
  (patient) => patient.nutrionPlan.isNewlyPrescribed,
);

export const selectRoomChatLoadingStatus = createSelector(selectPatient, (patient) => patient.roomChatLoading);

export const selectGetNutrionPlanStatus = createSelector(selectPatient, (patient) => patient.getNutrionPlanStatus);

export const selectPatientAvatarUrl = createSelector(selectPatient, (patientStore) => patientStore.me.avatar);

export const selectPatientRecommendationsLoadingStatus = createSelector(
  selectPatient,
  (patient) => patient.recommendationsLoadingStatus,
);
export const selectPatientRecommendations = createSelector(selectPatient, (patient) => patient.recommendations);

export const selectAllExperts = createSelector(selectPatient, (patient) => patient.experts);
export const selectAllExpertsLoadingStatus = createSelector(selectPatient, (patient) => patient.expertsLoadingStatus);
export const selectExpertsSearchStatus = createSelector(selectPatient, (patient) => patient.expertsSearchStatus);

export const selectPatientChats = createSelector(selectPatient, (patient) => patient.chatRooms);
export const selectActiveChat = createSelector(selectPatient, (patient) => patient.activeChat);

export const selectAssignedCheckups = createSelector(selectPatient, (patient) => patient.assignedCheckups);
export const selectAssignedCheckupsStatus = createSelector(selectPatient, (patient) => patient.assignedCheckupsStatus);

export const selectParsingAnalyzesCounter = createSelector(
  selectPatient,
  (patient) => patient.me.parsingAnalyzesCounter,
);

export const selectDemoMode = createSelector(selectPatient, (patient) => patient.me.demoMode);
