import * as React from 'react';

const CalendarArrowRight = () => (
  <svg width={10} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.961 2a1.126 1.126 0 0 1 0-1.616 1.184 1.184 0 0 1 1.65 0l6.38 6.808c.442.433.457 1.13.035 1.58L2.61 15.63a1.184 1.184 0 0 1-1.649.07 1.127 1.127 0 0 1-.071-1.615l5.662-6.05L.962 2Z"
      fill="#262626"
    />
  </svg>
);

export default CalendarArrowRight;
