import * as React from 'react';

function LogoBiogeekPatient(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={148} height={40} viewBox="0 0 148 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_11714_6370)">
        <path
          d="M55.3138 9C57.3907 9 59.0202 9.43235 60.2133 10.2914C61.4064 11.1505 61.9974 12.4869 61.9974 14.2893C62.0234 15.3083 61.7669 16.3143 61.2572 17.1922C60.7543 18.0129 60.0372 18.6757 59.1859 19.1069C58.2087 19.5961 57.1422 19.8734 56.0539 19.9211L55.6562 18.4387C56.9397 18.4565 58.209 18.7134 59.4013 19.1968C60.4255 19.6045 61.3292 20.2736 62.025 21.1395C62.6775 21.9833 63.0212 23.0313 62.9972 24.1042C63.0257 25.0326 62.8364 25.9546 62.4448 26.7938C62.0859 27.5227 61.5568 28.1512 60.9037 28.6243C60.2332 29.1014 59.4853 29.4549 58.6943 29.6687C57.8595 29.8915 56.9998 30.0028 56.1368 29.9999H50V9H55.3138ZM55.8661 18.1805C56.325 18.2233 56.7875 18.1591 57.2182 17.9927C57.649 17.8263 58.0367 17.5621 58.3518 17.2203C58.8724 16.5631 59.1465 15.7392 59.1251 14.8957C59.1678 14.4373 59.099 13.9753 58.9246 13.5503C58.7503 13.1254 58.4758 12.7505 58.1253 12.4588C57.3343 11.9185 56.3926 11.6526 55.4408 11.7008H52.7508V18.1805H55.8661ZM55.9821 27.389C56.7018 27.3981 57.4176 27.2804 58.0977 27.0409C58.6903 26.8336 59.2105 26.455 59.5946 25.9516C59.9763 25.4263 60.171 24.7848 60.147 24.1323C60.1854 23.4338 59.9557 22.7474 59.5062 22.2176C59.0519 21.7385 58.4797 21.3915 57.8491 21.2125C57.1824 21.0103 56.4901 20.9081 55.7943 20.9093H52.7342V27.389H55.9821Z"
          fill="#646EFA"
        />
        <path
          d="M67.0004 13.5198C66.9983 13.3124 67.0392 13.1071 67.12 12.9193C67.2009 12.7315 67.3196 12.566 67.4674 12.4351C67.7524 12.1559 68.1219 12.001 68.5052 12C68.8892 11.9972 69.26 12.1526 69.5431 12.4351C69.6875 12.5684 69.8033 12.7347 69.8822 12.9221C69.9611 13.1096 70.0012 13.3136 69.9997 13.5198C70.0039 13.7281 69.965 13.9347 69.886 14.1244C69.807 14.3142 69.6898 14.4821 69.5431 14.6159C69.2566 14.8909 68.8872 15.0417 68.5052 15.0396C68.1233 15.0417 67.7539 14.8909 67.4674 14.6159C67.3174 14.4846 67.1971 14.3174 67.1161 14.1273C67.0351 13.9372 66.9955 13.7293 67.0004 13.5198ZM67.1613 17.904H69.6832V30H67.1613V17.904Z"
          fill="#646EFA"
        />
        <path
          d="M73.002 23.4856C72.9853 22.3258 73.2743 21.1832 73.8378 20.1809C74.3968 19.201 75.2027 18.3973 76.1691 17.8562C77.2123 17.2739 78.3825 16.9796 79.5672 17.0015C80.74 16.9761 81.8986 17.2708 82.9267 17.8562C83.8712 18.402 84.6529 19.2062 85.1866 20.1809C85.7203 21.1936 86 22.3289 86 23.4828C86 24.6366 85.7203 25.772 85.1866 26.7846C84.6535 27.7642 83.872 28.574 82.9267 29.1264C81.8931 29.7252 80.7236 30.0262 79.5397 29.9982C78.3687 30.0231 77.2103 29.745 76.1691 29.1891C75.2107 28.6634 74.41 27.875 73.8543 26.91C73.2676 25.8732 72.9724 24.6871 73.002 23.4856ZM75.6743 23.4856C75.6645 24.2316 75.8406 24.9675 76.1856 25.6223C76.5079 26.2398 76.9774 26.7616 77.5492 27.1379C78.1274 27.5184 78.7999 27.7164 79.4847 27.7077C80.1784 27.7186 80.8603 27.5207 81.4476 27.1379C82.0141 26.7705 82.4798 26.2584 82.8003 25.6508C83.1257 24.989 83.2953 24.2568 83.2953 23.5141C83.2953 22.7714 83.1257 22.0392 82.8003 21.3774C82.4826 20.7525 82.0173 20.2212 81.4476 19.8333C80.866 19.4516 80.1896 19.2537 79.5012 19.2635C78.8125 19.2513 78.1355 19.4495 77.5548 19.8333C76.9795 20.2219 76.5085 20.7551 76.1856 21.3831C75.836 22.0349 75.6524 22.7684 75.6523 23.5141L75.6743 23.4856Z"
          fill="#646EFA"
        />
        <path
          d="M94.9949 35.9977C94.182 36.0104 93.3719 35.8998 92.5916 35.6695C91.925 35.4628 91.2905 35.1624 90.7065 34.7772C90.1391 34.3919 89.5935 33.9749 89.0721 33.528L90.6951 31.6168C91.2856 32.1736 91.9485 32.6462 92.6656 33.0214C93.3628 33.3631 94.129 33.5365 94.9037 33.528C95.7106 33.5528 96.5126 33.3934 97.2501 33.0617C97.8449 32.7948 98.3468 32.3536 98.6909 31.7953C99.0236 31.2286 99.2024 30.5832 99.2091 29.9243L99.2376 26.5854L99.4711 27.1208C99.0757 28.0272 98.4056 28.7831 97.5576 29.2796C96.5437 29.8914 95.3778 30.197 94.1975 30.1603C93.1168 30.1654 92.0559 29.8664 91.1336 29.2968C90.1906 28.7255 89.4068 27.9212 88.8557 26.9596C88.2795 25.9506 87.9843 24.8028 88.0014 23.638C87.9773 22.4742 88.2639 21.3253 88.8312 20.3122C89.3984 19.2992 90.2253 18.4594 91.2248 17.8812C92.189 17.3053 93.2883 17.0011 94.4083 17.0004C95.4406 16.9883 96.4612 17.223 97.3867 17.6855C98.1975 18.062 98.8879 18.6602 99.38 19.4125L99.2034 19.9134L99.4654 17.5358H102V29.9416C102.011 31.0126 101.721 32.0648 101.163 32.9754C100.571 33.9178 99.7333 34.6768 98.7422 35.1687C97.5768 35.7446 96.2916 36.0289 94.9949 35.9977ZM90.7806 23.5804C90.7713 24.3428 90.9682 25.0932 91.3501 25.7507C91.7181 26.3933 92.2463 26.9273 92.882 27.2992C93.5197 27.6809 94.2481 27.8799 94.9892 27.8749C95.6632 27.884 96.3313 27.7466 96.9482 27.472C97.5116 27.2161 98.0101 26.8342 98.4061 26.3551C98.7895 25.8949 99.0711 25.3571 99.2319 24.7778V22.3024C99.0627 21.723 98.7602 21.1924 98.3492 20.7538C97.9288 20.3134 97.4279 19.9595 96.8742 19.7119C96.2836 19.4421 95.6429 19.3027 94.9949 19.3031C94.2642 19.2979 93.5446 19.4846 92.9067 19.8448C92.2688 20.2051 91.7344 20.7266 91.3558 21.3583C90.9637 22.0265 90.7644 22.7922 90.7806 23.5689V23.5804Z"
          fill="#646EFA"
        />
        <path
          d="M111.757 29.9972C110.505 30.0318 109.265 29.7429 108.157 29.1584C107.183 28.629 106.377 27.8385 105.829 26.8757C105.263 25.8512 104.978 24.6958 105.001 23.5259C104.988 22.3671 105.311 21.2294 105.931 20.2503C106.542 19.2685 107.39 18.4561 108.397 17.8877C109.438 17.291 110.62 16.9855 111.82 17.0032C113.282 16.9546 114.709 17.46 115.815 18.4185C116.931 19.4584 117.695 20.821 118 22.3161L107.906 25.8599L107.255 24.2335L115.489 21.1919L114.919 21.6085C114.69 20.9471 114.291 20.3583 113.76 19.9022C113.152 19.4098 112.385 19.1579 111.603 19.1946C110.903 19.1822 110.213 19.3661 109.612 19.7253C109.03 20.0777 108.557 20.583 108.242 21.1862C107.894 21.8549 107.722 22.601 107.74 23.3547C107.727 24.1238 107.903 24.8843 108.254 25.5689C108.583 26.2024 109.086 26.7289 109.703 27.0868C110.342 27.4659 111.072 27.6633 111.814 27.6575C112.344 27.6585 112.869 27.5578 113.361 27.3608C113.867 27.1641 114.348 26.9073 114.793 26.5961L116.043 28.6105C115.408 29.0352 114.719 29.373 113.994 29.6149C113.275 29.8645 112.519 29.9937 111.757 29.9972Z"
          fill="#646EFA"
        />
        <path
          d="M126.757 29.9973C125.505 30.0311 124.265 29.7423 123.157 29.1585C122.185 28.6277 121.379 27.8375 120.829 26.8758C120.263 25.8513 119.978 24.6959 120.001 23.526C119.988 22.3672 120.311 21.2295 120.931 20.2504C121.542 19.2686 122.39 18.4562 123.397 17.8879C124.438 17.2912 125.62 16.9857 126.82 17.0034C128.283 16.9536 129.71 17.4592 130.815 18.4186C131.933 19.4569 132.698 20.8202 133 22.3162L122.906 25.86L122.272 24.2108L130.512 21.1692L129.941 21.5858C129.703 20.9301 129.296 20.3493 128.76 19.9023C128.155 19.4098 127.389 19.1579 126.609 19.1947C125.918 19.178 125.236 19.3559 124.64 19.708C124.045 20.0601 123.561 20.5723 123.242 21.1863C122.894 21.855 122.722 22.6012 122.74 23.3548C122.727 24.1239 122.903 24.8845 123.254 25.569C123.589 26.2056 124.1 26.7325 124.726 27.087C125.279 27.3976 125.894 27.5812 126.527 27.6247C127.159 27.6681 127.794 27.5702 128.384 27.3381C128.89 27.1414 129.371 26.8846 129.816 26.5734L131.06 28.6106C130.423 29.0354 129.732 29.3732 129.006 29.615C128.283 29.8659 127.523 29.9951 126.757 29.9973Z"
          fill="#646EFA"
        />
        <path
          d="M136 30V7H138.692V30H136ZM137.855 26.0727L137.601 23.399L144.801 17.419L146.463 18.8795L137.855 26.0727ZM141.583 21.8235L147 30H143.876L139.777 23.399L141.583 21.8235Z"
          fill="#646EFA"
        />
        <path
          d="M20.0007 40.0001C30.9648 40.0001 39.8529 31.112 39.8529 20.1479C39.8529 9.1838 30.9648 0.295654 20.0007 0.295654C9.03658 0.295654 0.148438 9.1838 0.148438 20.1479C0.148438 31.112 9.03658 40.0001 20.0007 40.0001Z"
          fill="white"
        />
        <path
          d="M20 40C16.0444 40 12.1776 38.827 8.8886 36.6294C5.59962 34.4318 3.03617 31.3082 1.52242 27.6537C0.008666 23.9992 -0.387401 19.9778 0.384303 16.0982C1.15601 12.2186 3.06082 8.65493 5.85787 5.85788C8.65492 3.06082 12.2186 1.15601 16.0982 0.384303C19.9778 -0.387401 23.9992 0.008666 27.6537 1.52242C31.3082 3.03617 34.4318 5.59962 36.6294 8.88861C38.827 12.1776 40 16.0444 40 20C39.994 25.3025 37.8849 30.3861 34.1355 34.1355C30.3861 37.8849 25.3025 39.994 20 40ZM20 1.84712C16.4095 1.846 12.8992 2.90969 9.91326 4.90367C6.92728 6.89766 4.5997 9.73236 3.22488 13.0493C1.85006 16.3662 1.48976 20.0163 2.18955 23.538C2.88934 27.0597 4.61779 30.2947 7.15629 32.834C9.69479 35.3733 12.9293 37.1028 16.4508 37.8037C19.9722 38.5046 23.6225 38.1454 26.9398 36.7716C30.2572 35.3979 33.0926 33.0712 35.0875 30.0858C37.0824 27.1005 38.1472 23.5906 38.1472 20C38.1382 15.1893 36.2236 10.5782 32.8224 7.17594C29.4213 3.77373 24.8107 1.85764 20 1.84712Z"
          fill="white"
        />
        <path
          d="M20.001 36.0557C28.8683 36.0557 36.0567 28.8673 36.0567 20C36.0567 11.1327 28.8683 3.94434 20.001 3.94434C11.1337 3.94434 3.94531 11.1327 3.94531 20C3.94531 28.8673 11.1337 36.0557 20.001 36.0557Z"
          fill="#646EFA"
        />
        <path
          d="M20 40C16.0444 40 12.1776 38.827 8.8886 36.6294C5.59962 34.4318 3.03617 31.3082 1.52242 27.6537C0.008666 23.9992 -0.387401 19.9778 0.384303 16.0982C1.15601 12.2186 3.06082 8.65493 5.85787 5.85788C8.65492 3.06082 12.2186 1.15601 16.0982 0.384303C19.9778 -0.387401 23.9992 0.008666 27.6537 1.52242C31.3082 3.03617 34.4318 5.59962 36.6294 8.88861C38.827 12.1776 40 16.0444 40 20C39.994 25.3025 37.8849 30.3861 34.1355 34.1355C30.3861 37.8849 25.3025 39.994 20 40ZM20 1.84712C16.4095 1.846 12.8992 2.90969 9.91326 4.90367C6.92728 6.89766 4.5997 9.73236 3.22488 13.0493C1.85006 16.3662 1.48976 20.0163 2.18955 23.538C2.88934 27.0597 4.61779 30.2947 7.15629 32.834C9.69479 35.3733 12.9293 37.1028 16.4508 37.8037C19.9722 38.5046 23.6225 38.1454 26.9398 36.7716C30.2572 35.3979 33.0926 33.0712 35.0875 30.0858C37.0824 27.1005 38.1472 23.5906 38.1472 20C38.1382 15.1893 36.2236 10.5782 32.8224 7.17594C29.4213 3.77373 24.8107 1.85764 20 1.84712Z"
          fill="#646EFA"
        />
        <path
          d="M20 40C16.0444 40 12.1776 38.827 8.8886 36.6294C5.59962 34.4318 3.03617 31.3082 1.52242 27.6537C0.008666 23.9992 -0.387401 19.9778 0.384303 16.0982C1.15601 12.2186 3.06082 8.65493 5.85787 5.85788C8.65492 3.06082 12.2186 1.15601 16.0982 0.384303C19.9778 -0.387401 23.9992 0.008666 27.6537 1.52242C31.3082 3.03617 34.4318 5.59962 36.6294 8.88861C38.827 12.1776 40 16.0444 40 20C39.994 25.3025 37.8849 30.3861 34.1355 34.1355C30.3861 37.8849 25.3025 39.994 20 40ZM20 1.84712C16.4095 1.846 12.8992 2.90969 9.91326 4.90367C6.92728 6.89766 4.5997 9.73236 3.22488 13.0493C1.85006 16.3662 1.48976 20.0163 2.18955 23.538C2.88934 27.0597 4.61779 30.2947 7.15629 32.834C9.69479 35.3733 12.9293 37.1028 16.4508 37.8037C19.9722 38.5046 23.6225 38.1454 26.9398 36.7716C30.2572 35.3979 33.0926 33.0712 35.0875 30.0858C37.0824 27.1005 38.1472 23.5906 38.1472 20C38.1382 15.1893 36.2236 10.5782 32.8224 7.17594C29.4213 3.77373 24.8107 1.85764 20 1.84712Z"
          fill="#646EFA"
        />
        <path
          d="M19.9709 33.5266C17.6759 33.5321 15.4337 32.8383 13.543 31.5374L14.583 30.0256C16.172 31.1255 18.0612 31.709 19.9937 31.6966C21.9294 31.71 23.8293 31.174 25.4725 30.1507L26.4557 31.6852C24.5132 32.9032 22.2637 33.542 19.9709 33.5266Z"
          fill="#FDFDFD"
        />
        <path
          d="M19.9994 14.0097C18.268 14.0113 16.605 14.6858 15.3617 15.8909V10.1733H13.293V21.7448C13.5033 24.9673 16.6632 27.4282 20.0051 27.4282C21.7838 27.4282 23.4896 26.7217 24.7473 25.464C26.005 24.2063 26.7115 22.5005 26.7115 20.7218C26.7115 18.9431 26.005 17.2373 24.7473 15.9796C23.4896 14.7219 21.7838 14.0153 20.0051 14.0153L19.9994 14.0097ZM19.9994 25.3765C19.0806 25.3776 18.182 25.1061 17.4176 24.5963C16.6531 24.0866 16.0571 23.3614 15.7049 22.5127C15.3528 21.664 15.2603 20.7299 15.4393 19.8287C15.6183 18.9274 16.0607 18.0995 16.7104 17.4498C17.3601 16.8001 18.188 16.3577 19.0893 16.1787C19.9905 15.9998 20.9246 16.0922 21.7733 16.4443C22.622 16.7965 23.3471 17.3925 23.8569 18.157C24.3667 18.9215 24.6382 19.82 24.6371 20.7388C24.6296 21.9644 24.1379 23.1373 23.2691 24.0018C22.4003 24.8663 21.225 25.3523 19.9994 25.3538V25.3765Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11714_6370">
          <rect width={148} height={40} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoBiogeekPatient;
