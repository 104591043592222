import * as React from 'react';

function MessageIcon() {
  return (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.974 1.025a1.502 1.502 0 00-1.468-.383L2.038 5.569a1.5 1.5 0 00-.235 2.799l7.306 3.46 4.996-4.995a.75.75 0 111.06 1.061L10.17 12.89l3.461 7.307a1.487 1.487 0 001.481.852 1.487 1.487 0 001.318-1.087l4.927-17.468a1.502 1.502 0 00-.383-1.468z"
        fill="#fff"
      />
    </svg>
  );
}

export default MessageIcon;
