import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#000' } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5 3h-2.25v-.75a.75.75 0 10-1.5 0V3h-7.5v-.75a.75.75 0 10-1.5 0V3H4.5A1.501 1.501 0 003 4.5v15A1.502 1.502 0 004.5 21h15a1.501 1.501 0 001.5-1.5v-15A1.501 1.501 0 0019.5 3zm-3.611 9.545l-4.375 4.125a.75.75 0 01-1.03-.001l-2.375-2.25A.75.75 0 119.14 13.33L11 15.093l3.86-3.64a.749.749 0 011.199.854.751.751 0 01-.17.238zm3.61-5.045h-15v-3h2.25v.75a.75.75 0 001.5 0V4.5h7.5v.75a.75.75 0 101.5 0V4.5h2.25v3z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgCalendar;
