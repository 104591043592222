import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { CalendarHeaderIcon, EmailIcon } from 'assets/svg';
import classnames from 'classnames';
import { CooperationStateEnum } from 'components/BaseTable/components/Cell/Cell';
import { UserProfileCard } from 'components/UserProfileCard';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectExpert, selectExpertPatients } from 'features/expert/selectors';
import { selectPatientData } from 'features/patient/selectors';
import { selectActivePromocode } from 'features/promocodes/selectors';
import { selectSubscription, selectSubscriptionsConstants } from 'features/subscriptions/selectors';
import { useToggle } from 'hooks/useToggle';
import { useUserAccess } from 'hooks/useUserAccess';
import { AddPatientForm } from 'pages/DashboardExpert/components/AddPatientForm';
import { LimitPatientModal } from 'pages/DashboardExpert/components/LimitPatientModal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { DEFAULT_AVATAR } from 'types/user';
import { ButtonDefault, IconWithBackground } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './../../styles.module.scss';

enum HeaderButtonStateEnum {
  isPatientButton,
  isExpertButton,
}

export const HeaderContent: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.header' });

  const { push } = useHistory();
  const { pathname } = useLocation();
  const hasAccess = useUserAccess();

  const { isOpened: isOpenPatientForm, onToggle: togglePatientForm } = useToggle();
  const { isOpened: isLimitPatientModalOpened, onToggle: toggleLimitPatientModal } = useToggle();

  const role = useAppSelector(selectRole);
  const expert = useAppSelector(selectExpert);
  const patient = useAppSelector(selectPatientData);

  const { educationDocumentStatus } = expert;
  const isExpertApproved = educationDocumentStatus === ExpertEducationDocumentStatusEnum.Approved;
  const isExpert = role === RolesEnum.EXPERT;
  const isDisabledApp = isExpert ? !isExpertApproved || !hasAccess : false;

  const userDataMapping = {
    [RolesEnum.PATIENT]: () => ({ username: patient.name, avatar: patient.avatar || DEFAULT_AVATAR }),
    [RolesEnum.EXPERT]: () => ({ username: expert.name, avatar: expert.avatar || DEFAULT_AVATAR }),
  };

  const { username, avatar } = userDataMapping[role as RolesEnum]();

  const patients = useAppSelector(selectExpertPatients);
  const totalPatientsWithCooperation =
    patients.data &&
    patients.data.filter((patient) => {
      const signUpStatusState =
        patient.signUpStatus === 'sent' ? CooperationStateEnum.RequestFromExpert : CooperationStateEnum.Cooperation;

      const cooperationState = patient.isNewRequest ? CooperationStateEnum.RequestFromPatient : signUpStatusState;
      return cooperationState === CooperationStateEnum.Cooperation && patient;
    });
  const activePromocode = useAppSelector(selectActivePromocode);
  const hasActivePromocode = !!activePromocode;
  const subscription = useAppSelector(selectSubscription);
  const subscriptionConstants = useAppSelector(selectSubscriptionsConstants);
  const { subscriptionPlan } = subscription;
  const subscriptionConstant = subscriptionConstants[subscriptionPlan];
  // INFO: 50 захардкожено
  const patientLimit = hasActivePromocode ? 50 : subscriptionConstant?.patientLimit;

  const handleAddPatientClick = () => {
    if (patientLimit && totalPatientsWithCooperation.length < patientLimit) {
      togglePatientForm();
    } else {
      toggleLimitPatientModal();
    }
  };

  const handleCreateAnalysisClick = () => {
    push(CommonRoutes.NEW_ANALYSIS);

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CREATE_NEW_ANALYSIS_PRESSED);
  };

  const headerButtonMapping = {
    [HeaderButtonStateEnum.isPatientButton]: (
      <ButtonDefault
        text={t('addAnalysis')}
        onClick={handleCreateAnalysisClick}
        customStyles={{ marginRight: '60px', width: '200px' }}
      />
    ),
    [HeaderButtonStateEnum.isExpertButton]: (
      <ButtonDefault
        text={t('addPatient')}
        disabled={isDisabledApp}
        onClick={handleAddPatientClick}
        customStyles={{ marginRight: '60px' }}
      />
    ),
  };

  const renderHeaderButton = (state: HeaderButtonStateEnum) => {
    return headerButtonMapping[state];
  };

  const headerButtonState = isExpert ? HeaderButtonStateEnum.isExpertButton : HeaderButtonStateEnum.isPatientButton;

  const hasCalendar = isExpert;

  const hasNewMessagesExpert = false; //TODO: need FE

  return (
    <div className={classnames('flexbox', styles['header-content'])}>
      <div className={styles['header-content_hide-mobile']}>
        {hasCalendar && (
          <Link to={ExpertRoutes.CALENDAR} className={styles.header__link}>
            <IconWithBackground
              disabled={isDisabledApp}
              icon={
                <CalendarHeaderIcon
                  className={classnames(styles['header-content__message-icon'], {
                    [styles['header-content__message-icon_blue']]: pathname.includes('/calendar'),
                  })}
                />
              }
            />
          </Link>
        )}

        <Link to={CommonRoutes.MESSAGE} className={styles.header__link}>
          <IconWithBackground
            disabled={isDisabledApp}
            icon={
              <EmailIcon
                className={classnames(styles['header-content__message-icon'], {
                  [styles['header-content__message-icon_blue']]: pathname.includes('/message'),
                })}
              />
            }
            isActive={false}
          />
          {hasNewMessagesExpert && <span className={classnames(styles.marker)} />}
        </Link>
        {renderHeaderButton(headerButtonState)}
      </div>
      <UserProfileCard name={username} avatarUrl={avatar} isActive={true} />
      {isOpenPatientForm && <AddPatientForm onClose={togglePatientForm} isVisible={true} />}
      <LimitPatientModal onClose={toggleLimitPatientModal} isVisible={isLimitPatientModalOpened} />
    </div>
  );
};
