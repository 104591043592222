import { FoldersIcon } from 'assets/svg';
import { selectAnalyzesHistory } from 'features/analyzes/selectors';
import { selectAssignedCheckups, selectPatientExpert } from 'features/patient/selectors';
import { useUserAccess } from 'hooks/useUserAccess';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { AnalysisCard, AnalysisRequestCard, SubscribeAnalysisHistoryCard } from 'UIcomponents';

import { AsideHeader, EmptyAnalyzesHistory } from './components/';
import styles from './styles.module.scss';

export const Aside: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const hasAccess = useUserAccess();

  const analyzesHistory = useSelector(selectAnalyzesHistory);
  const expertsRequests = useAppSelector(selectAssignedCheckups).slice(0, 2);
  const patientCooperationExpert = useAppSelector(selectPatientExpert);

  const hasPatientCooperateWithExpert = !!patientCooperationExpert;
  const hasExpertsRequests = expertsRequests.length > 0;
  const hasAnalyzesHistory = analyzesHistory.length > 0;
  const hasAllAnalyzesHistory = analyzesHistory.length > 0 && (hasAccess || hasPatientCooperateWithExpert);

  const expertsRequestsReversedSliced = [...expertsRequests].reverse().slice(0, 2);
  const analyzesHistoryReversed = [...analyzesHistory].reverse().slice(0, 6);
  const analyzesHistoryReversedSliced = [...analyzesHistory].reverse().slice(0, 1);

  const patientAnalyzesHistory =
    hasAccess || hasPatientCooperateWithExpert ? analyzesHistoryReversed : analyzesHistoryReversedSliced;

  const hasBuySubscriptionBanner = !hasAccess && !hasPatientCooperateWithExpert;

  return (
    <div className={styles['aside-history']}>
      <AsideHeader
        header={t('requests')}
        hasLink={hasExpertsRequests}
        link={CommonRoutes.ANALYZES_HISTORY}
        isRequestsActive={true}
      />

      {hasExpertsRequests ? (
        <div className={styles['aside-history__list']}>
          {expertsRequestsReversedSliced.map((request, id) => (
            <div key={id} className={styles['aside-history__item']}>
              <AnalysisRequestCard request={request} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles['aside-history__empty-requests']}>
          <FoldersIcon />
          <p className={`body body_medium ${styles['aside-history__subtitle']}`}>{t('noRequests')}</p>
        </div>
      )}
      <AsideHeader header={t('myAnalisis')} hasLink={hasAllAnalyzesHistory} link={CommonRoutes.ANALYZES_HISTORY} />

      {hasAnalyzesHistory ? (
        <div className={styles['aside-history__list']}>
          {patientAnalyzesHistory.map((analysis, id) => (
            <div key={id} className={styles['aside-history__item']}>
              <AnalysisCard analysis={analysis} />
            </div>
          ))}
          {hasBuySubscriptionBanner && <SubscribeAnalysisHistoryCard />}
        </div>
      ) : (
        <EmptyAnalyzesHistory />
      )}
    </div>
  );
};
