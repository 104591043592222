import classnames from 'classnames';
import { FooterMobile, HeaderDefault } from 'components';
import useWindowWidth from 'hooks/useWindowWidth';
import { FC, ReactElement, ReactNode } from 'react';

import styles from './DashboardLayout.module.scss';

interface MainLayoutProps {
  children?: ReactNode;
  RightBox?: ReactElement;
  Header?: ReactNode;
}

const DashboardLayout: FC<MainLayoutProps> = ({ children, RightBox, Header }) => {
  const width = useWindowWidth();

  return (
    <>
      <HeaderDefault />
      <div className={styles.dashboard}>
        <div className={classnames(styles['dashboard__header'])}>
          {Header}
          {RightBox}
        </div>
        {children}
        {width <= 760 && <FooterMobile />}
      </div>
    </>
  );
};

export default DashboardLayout;
