import { TubeIcon } from 'assets/svg';
import CloseIcon from 'assets/svg/CloseIcon';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'UIcomponents';

import styles from './styles.module.scss';

const Goal: FC<{
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  disabled: boolean;
  remove: (index: number) => void;
  index: number;
  error?: 'error' | 'warning';
}> = ({ value, onChange, disabled, remove, index, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  return (
    <div className={styles.goal}>
      <button type={'button'} className={styles.goal__avatar}>
        <TubeIcon />
      </button>
      <TextArea
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={t('enterNewGoal') as string}
        customStyles={{ marginRight: 20 }}
        error={error}
      />
      {!disabled && (
        <button type={'button'} className={styles['goal__button_delete']} onClick={() => remove(index)}>
          {' '}
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default Goal;
