import { ArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { PatientRoutes } from 'types/routes';

import styles from './styles.module.scss';

export const UserSettings: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.settingsTabs' });

  const width = useWindowWidth();

  const dispatch = useAppDispatch();
  const handleSignOut = () => {
    dispatch(actions.authActions.signOut());
  };

  if (width > 760) {
    return <Redirect to={PatientRoutes.PATIENT_PROFILE()} />;
  }

  return (
    <MainLayout>
      <div className={classNames(styles['patient-profile'])}>
        <h4 className={styles['patient-profile__title']}>{t('title')}</h4>

        <div className={classNames(styles['patient-profile__links'])}>
          <Link
            to={PatientRoutes.PROFILE}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('myData')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.PROFILE_SETTINGS}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('email')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.ANAMNESIS}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('personalData')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.NUTRITION_DIARY}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('diary')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.NUTRITION_PLAN}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('nutrition')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.RECOMMENDATIONS}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('recommendations')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.USER_SUBSCRIPTIONS}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('subscriptions')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
          <Link
            to={PatientRoutes.NOTIFICATIONS}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('notifications')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
        </div>

        <div
          role="button"
          tabIndex={0}
          onClick={handleSignOut}
          onKeyDown={handleSignOut}
          className={styles['patient-profile__sign-out-btn']}>
          <p className={classNames('body', 'body_medium')}>{t('logout')}</p>
        </div>
      </div>
      {/* <RemoveAccountModal isModalOpened={isModalOpened} onCloseModal={handleCloseModal} /> */}
    </MainLayout>
  );
};
