import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { DocumentCheck, DocumentFinished } from 'assets/svg';
import classnames from 'classnames';
import { selectExpert } from 'features/expert/selectors';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';

import styles from './DocumentsStatus.module.scss';

export const DocumentStatus: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui' });

  const me = useAppSelector(selectExpert);
  const check = me.educationDocumentStatus === ExpertEducationDocumentStatusEnum.Approved;
  const date = me.educationDocumentsSentAt;
  return (
    <div className={styles['document-status']}>
      <div className={styles['document-status__circle']}>{check ? <DocumentFinished /> : <DocumentCheck />}</div>
      <div className={styles['document-status__info']}>
        <h6 className={styles['document-status__info-title']}>{check ? t('docsChecked') : t('docsChecking')}</h6>
        <p className={classnames(styles['document-status__info-subtitle'], styles.subhead)}>
          {t('onModeration')} {moment(date).format('DD.MM.YYYY')}
        </p>
      </div>
    </div>
  );
};
