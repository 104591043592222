import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BiomarkerGetResDto, UnitsOptionDto } from 'api/generated';
import { LoadingStatus } from 'types';

import { exportCheckUpsPdfAsync, findAllBiomarkersAsync, getUnitsCoefficients } from './thunks';
import { BiomarkersStore } from './types';

const initialState: BiomarkersStore = {
  biomarkers: [],
  loading: LoadingStatus.idle,
  checkUpsLoading: LoadingStatus.idle,
  error: null,
  currentRequestId: undefined,
  unitsCoefficients: [],
};
const biomarkersSlice = createSlice({
  initialState,
  name: 'biomarkers',
  reducers: {
    unsetBiomarkers: () => initialState,
  },
  extraReducers: {
    [findAllBiomarkersAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [findAllBiomarkersAsync.fulfilled.type]: (state, { payload }: PayloadAction<BiomarkerGetResDto[]>) => {
      state.biomarkers = payload;
      state.loading = LoadingStatus.fulfilled;
    },
    [findAllBiomarkersAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload?.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload?.error;
      }
    },
    [exportCheckUpsPdfAsync.pending.type]: (state) => {
      if (state.checkUpsLoading === LoadingStatus.idle) {
        state.checkUpsLoading = LoadingStatus.pending;
      }
    },
    [exportCheckUpsPdfAsync.fulfilled.type]: (state) => {
      state.checkUpsLoading = LoadingStatus.idle;
    },
    [exportCheckUpsPdfAsync.rejected.type]: (state, { payload }) => {
      state.checkUpsLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [getUnitsCoefficients.fulfilled.type]: (state, { payload }: PayloadAction<UnitsOptionDto[]>) => {
      state.unitsCoefficients = payload;
    },
  },
});
export const actions = {
  ...biomarkersSlice.actions,
};
export const { reducer } = biomarkersSlice;
