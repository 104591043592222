import classnames from 'classnames';
import { CSSProperties, FC, FocusEvent, MouseEvent } from 'react';

import styles from './styles.module.scss';

interface RadioButtonProps {
  checked: boolean;
  name: string;
  value?: string;
  onChange: (e: MouseEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  labelText?: string;
  isDisabled?: boolean;
  error?: boolean;
  customStyles?: CSSProperties;
}

export const RadioButton: FC<RadioButtonProps> = ({
  checked,
  onChange,
  onBlur,
  name,
  value,
  labelText,
  isDisabled,
  error,
  customStyles,
}) => {
  return (
    <div className={classnames(styles.radio)} style={customStyles}>
      <input
        type="radio"
        name={name}
        disabled={isDisabled}
        id={name}
        onClick={onChange}
        onBlur={onBlur}
        value={value}
        checked={checked}
      />
      <label htmlFor={name} className={styles['radio-button__label']}>
        {labelText}
      </label>
    </div>
  );
};
