import { CheckCircle } from 'assets/svg';
import { BaseModal } from 'components/BaseModal';
import { useClientSize } from 'hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type CancelSubscriptionModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  successText: string;
  description?: string;
  isNotVisibleCross?: boolean;
  submitFunction?: () => void;
};

export const SuccessModal: FC<CancelSubscriptionModalProps> = ({
  isModalOpened,
  onCloseModal,
  isNotVisibleCross,
  successText,
  submitFunction,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const handleClick = () => {
    onCloseModal();
    if (submitFunction) {
      submitFunction();
    }
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <BaseModal
      isVisible={isModalOpened}
      onClose={onCloseModal}
      customStyles={{ maxWidth: '572px', padding: isMobile ? '60px 24px 24px 24px' : '60px 100px' }}
      isNotVisibleCross={isNotVisibleCross}>
      <div className={styles['success-modal']}>
        <h3 className={styles['success-modal__text']}>{successText}</h3>
        <CheckCircle className={styles['success-modal__icon']} />
        <ButtonDefault
          containerClassNames={styles['success-modal__button']}
          text={t('continue')}
          type="button"
          variant="primary"
          onClick={handleClick}
        />
      </div>
    </BaseModal>
  );
};
