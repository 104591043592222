export { SubscriptionPlanName, SubscriptionPlan } from './subscriptions';
export { ensure } from './ensure-value-exists';
export enum LoadingStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface DropdownOptions<T> {
  label: string;
  value: T;
}

export enum BreakpointsEnum {
  lg = 1440,
  lgm = 1200,
  md = 1024,
  sm = 768,
  s = 576,
  xs = 320,
}

export type BreakpointsType = keyof typeof BreakpointsEnum;

export const SOCIALS = {
  twitter: 'https://twitter.com/biogeekhealth',
  instagram: 'https://www.instagram.com/biogeek.health/',
  telegram: 'https://t.me/biogeekhealth',
  youtube: 'https://www.youtube.com/channel/UCUzg1CLn82mYkvqpGA73cRg',
};
