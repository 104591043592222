import { unwrapResult } from '@reduxjs/toolkit';
import {
  GetPaymentUrlDtoPaymentPlatformEnum,
  SubscriptionConstantsDto,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
  SubscriptionsConstantsResponseDto,
  UpdateSubscriptionDtoSubscriptionDurationEnum,
} from 'api/generated';
import classNames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectExpert } from 'features/expert/selectors';
import { findMeAsync as findExpertAsync } from 'features/expert/thunks';
import { selectPatientData } from 'features/patient/selectors';
import { findMeAsync as findPatientAsync } from 'features/patient/thunks';
import {
  selectGetSessionLinkLoading,
  selectSubscription,
  selectSubscriptionsConstants,
  selectUpdateSubscriptionLoadingStatus,
} from 'features/subscriptions/selectors';
import { getPaymentUrlAsync, updateSubscriptionAsync } from 'features/subscriptions/thunks';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes } from 'types/routes';
import { SubscriptionCard } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { getIsSubscriptionExpired } from 'utils/subscriptions';

import config from '../../config';
import styles from './styles.module.scss';

export interface SubscriptionsConstantsWithDay extends SubscriptionsConstantsResponseDto {
  day: SubscriptionConstantsDto;
}

/* Это старый компонент для отображения карточки подписки для пациента ЕГО нужно отрефакторить*/

export const Subscription: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const expert = useAppSelector(selectExpert);
  const patient = useAppSelector(selectPatientData);
  const role = useAppSelector(selectRole);
  const subscription = useAppSelector(selectSubscription);
  const { subscriptionPlan, status, paymentPlatform } = subscription;
  const sessionLinkLoading = useAppSelector(selectGetSessionLinkLoading) === LoadingStatus.pending;
  const subscriptionUpdating = useAppSelector(selectUpdateSubscriptionLoadingStatus) === LoadingStatus.pending;
  const allSubscriptionConstants = useSelector(selectSubscriptionsConstants);

  const isEditSubscription =
    !!subscriptionPlan &&
    (status === SubscriptionDtoStatusEnum.Active || status === SubscriptionDtoStatusEnum.CanceledActive);
  const isLoading = sessionLinkLoading || subscriptionUpdating;
  const isExpert = role === RolesEnum.EXPERT;
  const AccountId = isExpert ? expert.id : patient.id;

  const subscriptionConstants = [allSubscriptionConstants.year, allSubscriptionConstants.month];

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(subscriptionPlan);
  const [selectedPlatform, setSelectedPlatform] = useState<GetPaymentUrlDtoPaymentPlatformEnum>();

  const isSubscriptionExpired = getIsSubscriptionExpired(status);

  const updateSubscription = (subscriptionDuration: UpdateSubscriptionDtoSubscriptionDurationEnum) => {
    batch(async () => {
      await dispatch(updateSubscriptionAsync({ subscriptionDuration }))
        .then(unwrapResult)
        .then((res) => {
          if (res.hasPendingUpdate) {
            notify('error', t('notifyError'), true);
          } else {
            notify('success', t('notifySuccess'));
          }
          //history.push(ExpertRoutes.SUBSCRIPTION, { subscriptionDuration });
        })
        .catch((error) => {
          console.warn(error);
          notify('error', error.message);
        });

      isExpert
        ? await dispatch(findExpertAsync()).catch((error) => {
            console.warn({ error });
          })
        : await dispatch(findPatientAsync()).catch((error) => {
            console.warn({ error });
          });
    });
  };

  const subscribe = (subscriptionPlan: any) => {
    dispatch(
      getPaymentUrlAsync({
        cancelUrl: `${window.location.origin}${CommonRoutes.DASHBOARD}`,
        successUrl: `${window.location.origin}${CommonRoutes.DASHBOARD}`,
        subscriptionPlan: subscriptionPlan,
        paymentPlatform: GetPaymentUrlDtoPaymentPlatformEnum.Stripe,
      }),
    )
      .then(unwrapResult)
      .then((result) => {
        window.location.assign(result.redirectUrl);
      });
  };

  const handleChooseSubscriptionClick = (
    plan: SubscriptionDtoSubscriptionPlanEnum | undefined,
    platform: GetPaymentUrlDtoPaymentPlatformEnum,
  ) => {
    plan && setSelectedSubscriptionPlan(plan);
    setSelectedPlatform(platform);
    switch (platform) {
      case GetPaymentUrlDtoPaymentPlatformEnum.Stripe: {
        if (isEditSubscription) {
          return updateSubscription(plan as unknown as UpdateSubscriptionDtoSubscriptionDurationEnum);
        }
        if (isSubscriptionExpired || isEmpty(subscription)) return subscribe(plan);
        break;
      }
      case GetPaymentUrlDtoPaymentPlatformEnum.CloudPayments: {
        if (isEditSubscription) {
          return updateSubscription(plan as unknown as UpdateSubscriptionDtoSubscriptionDurationEnum);
        } else if (isSubscriptionExpired || isEmpty(subscription)) {
          const amount = plan && (allSubscriptionConstants[plan]?.price.rub as number);
          const description = 'Biogeek Subscription';
          const widget = new window.cp.CloudPayments({ language: 'ru-RU' });

          amount &&
            widget.charge(
              {
                amount,
                description,
                publicId: config.CP_PUBLIC_ID,
                currency: 'RUB',
                accountId: AccountId,
                skin: 'mini',
                requireEmail: true,
                data: {
                  recurrent: {
                    selectedPlan: plan,
                  },
                },
              },
              () => {
                //Metrics
                analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_SUBSCRIPTION_BOUGHT);

                history.push(CommonRoutes.DASHBOARD);
                window.location.reload();
              },
              (reason) => {
                if (reason && reason !== 'User has cancelled') {
                  notify('error', reason);
                }
              },
            );
        }
        break;
      }
    }
  };

  useEffect(() => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_SUBSCRIPTIONS_PAGE_VIEWED);
  }, []);

  return (
    <div className={classNames('flexbox', 'justify-center', styles['subscriptions'])}>
      {subscriptionConstants.map(
        (plan, id) =>
          plan && (
            <SubscriptionCard
              key={id}
              period={plan?.period}
              interval={plan?.interval}
              amountRub={plan?.price.rub}
              amountUsd={plan?.price.usd}
              onClickButton={handleChooseSubscriptionClick}
              isLoading={false}
              selectedPlatform={selectedPlatform}
              currentPaymentPlatform={
                status === SubscriptionDtoStatusEnum.Active || status === SubscriptionDtoStatusEnum.CanceledActive
                  ? paymentPlatform
                  : undefined
              }
            />
          ),
      )}
    </div>
  );
};
