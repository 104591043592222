import classNames from 'classnames';
import { BaseModal } from 'components/BaseModal';
import { findMeAsync, getExpertsAsync, stopCooperationWithExpert } from 'features/patient/thunks';
import { useClientSize } from 'hooks';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';
import { getRandomInt } from 'utils/getRandomInt';

import styles from './styles.module.scss';

type CancelCooperationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  expertId: string;
};

export const CancelCooperationModal: FC<CancelCooperationModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'message' });

  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const randomSeedForShuffle = useMemo(() => getRandomInt(99), []);

  const handleCancelCooperation = async () => {
    setLoading(true);
    await dispatch(stopCooperationWithExpert())
      .then(() => {
        notify('success', t('notifyStopCooperation'));
        onClose();
      })
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => setLoading(false));
    await dispatch(findMeAsync()).catch((error) => {
      console.warn({ error });
    });
    await dispatch(
      getExpertsAsync({
        page: 1,
        limit: 2,
        seedForShuffle: randomSeedForShuffle,
      }),
    ).catch((error) => {
      console.warn({ error });
    });
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <BaseModal title={t('cancelCooperation') as string} isVisible={isOpen} onClose={onClose}>
      <div className={classNames('body body_medium', styles['content'])}>{t('cancelContent')}</div>

      <div className={classNames('flexbox', 'justify-between')}>
        <ButtonDefault
          text={t('close')}
          variant="secondary"
          onClick={onClose}
          customStyles={{ margin: 0, minWidth: isMobile ? 'max-content' : '177px' }}
          containerClassNames={styles.button}
        />
        <ButtonDefault
          text={isMobile ? t('stop') : t('stopCooperation')}
          customStyles={{
            backgroundColor: 'var(--red)',
            marginLeft: 8,
            maxWidth: '100%',
            minWidth: isMobile ? 'max-content' : '177px',
          }}
          onClick={handleCancelCooperation}
          containerClassNames={styles.button}
          isLoading={isLoading}
        />
      </div>
    </BaseModal>
  );
};
