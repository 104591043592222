import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Analysis,
  Anamnesis,
  CheckupResDto,
  DocumentUploadSignedUrlDto,
  EventsGetEventResDto,
  Expert,
  ExpertCreateChatRoomDto,
  ExpertCreateCustomCheckupDto,
  ExpertCreateEventDto,
  ExpertCreatePatientDto,
  ExpertCreatePaymentsDiaryDto,
  ExpertEditEventDto,
  ExpertEmailCodeDto,
  ExpertGetPaginatedPaymentsDiaryDto,
  ExpertGetPatientsResDto,
  ExpertGetPaymentsDiaryDto,
  ExpertLoadEducationDocumentsDto,
  ExpertSetNewEmailDto,
  ExpertSettings,
  ExpertUpdateExpertDto,
  ExpertUpdatePasswordDto,
  ExpertUpdateSettingsDto,
  ExpertVerifyCurrentEmailDto,
  FileUploadSignedUrlResDto,
  FindOneAnalysisWithPreviousResDto,
  FirstTypeCardsPatientGetResDto,
  GetChatRoomsResDto,
  GetPatientNutritonPlanResDto,
  Patient,
  PatientDiary,
  PatientRecommendationCardsGetResDto,
  PhotoUploadSignedUrlDto,
  PrescribePatientNutritionPlanDto,
  SearchAnalyzesResDto,
  SecondTypeCardsPatientGetResDto,
  ThirdTypeCardsPatientGetResDto,
  UpdatePatientNutritionPlanDto,
} from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from '../analyzes/types';
import { ExpertValidationsErrors } from './types';

interface QueryDto {
  page: string;
  limit: string;
  sort?: 'name' | 'date' | 'amount' | 'paid';
  sortDirection?: 'ASC' | 'DESC';
}

interface QueryPatientsDto {
  sortField?: 'name' | 'birthday' | 'createdAt';
  sortDirection?: 'ASC' | 'DESC';
  signUpStatus?: 'sent' | 'approved';
  onlyNewRequests?: boolean;
  limit: number;
  filter?: string;
  options?: any;
  page: number;
}

export const createPaymentsDiaryAsync = createAsyncThunk<
  ExpertGetPaymentsDiaryDto,
  ExpertCreatePaymentsDiaryDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/expert/payments-diary-create',
  async (userData: ExpertCreatePaymentsDiaryDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPaymentsDiaryApi.expertsPaymentsDiaryControllerCreatePayment(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeExpertAsync = createAsyncThunk<
  Expert,
  ExpertUpdateExpertDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/update', async (userData: ExpertUpdateExpertDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerUpdateMe(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPaymentsDiaryAsync = createAsyncThunk<
  ExpertGetPaginatedPaymentsDiaryDto,
  QueryDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/payments-diary', async (searchQuery: QueryDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, limit, sort, sortDirection } = searchQuery;
    const { data } = await api.ExpertsPaymentsDiaryApi.expertsPaymentsDiaryControllerGetPayments(
      page,
      limit,
      sort,
      sortDirection,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sortPaymentsDiaryAsync = createAsyncThunk<
  ExpertGetPaginatedPaymentsDiaryDto,
  QueryDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/sort-payments-diary', async (searchQuery: QueryDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, limit, sort, sortDirection } = searchQuery;
    const { data } = await api.ExpertsPaymentsDiaryApi.expertsPaymentsDiaryControllerGetPayments(
      page,
      limit,
      sort,
      sortDirection,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deletePaymentsDiaryAsync = createAsyncThunk<string, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/payments-diary/{paymentId}',
  async (id: string, { extra: { api } }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPaymentsDiaryApi.expertsPaymentsDiaryControllerDeletePaymentByPaymentId(id, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      return err.response.data;
    }
  },
);

export const deletePatientAsync = createAsyncThunk<void, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/payments-diary/{paymentId}',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      //const { data } = await api.ExpertsApi.expertsControllerDeletePatient(id);
      //return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const togglePaymentsDiaryAsync = createAsyncThunk<string, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/payments-diary/{paymentId}/toggle',
  async (id: string, { extra: { api } }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPaymentsDiaryApi.expertsPaymentsDiaryControllerTogglePaymentPaidStatus(id, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      return err.response.data;
    }
  },
);

export const getPatientsAsync = createAsyncThunk<
  ExpertGetPatientsResDto,
  QueryPatientsDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/patients', async (searchQuery: QueryPatientsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, signUpStatus, onlyNewRequests, limit, sortField, sortDirection, filter } = searchQuery;
    const { data } = await api.ExpertsPatientsApi.expertPatientsControllerGetPatients(
      page,
      limit,
      sortField,
      sortDirection,
      signUpStatus,
      onlyNewRequests,
      filter,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sortPatientsAsync = createAsyncThunk<
  ExpertGetPatientsResDto,
  QueryPatientsDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/sort-patients', async (searchQuery: QueryPatientsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, signUpStatus, limit, sortField, sortDirection, onlyNewRequests, filter } = searchQuery;
    const { data } = await api.ExpertsPatientsApi.expertPatientsControllerGetPatients(
      page,
      limit,
      sortField,
      sortDirection,
      signUpStatus,
      onlyNewRequests,
      filter,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientInvitationAsync = createAsyncThunk<
  unknown,
  ExpertCreatePatientDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/patientInvitationAsync', async (userData: ExpertCreatePatientDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientsApi.expertPatientsControllerCreatePatient(userData, {
      params: { l: language },
    });

    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const findMeAsync = createAsyncThunk<Expert, undefined, ExtraParamsThunkType<ExpertValidationsErrors>>(
  'expert/findMe',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerFindMe({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertChangePasswordAsync = createAsyncThunk<
  void,
  ExpertUpdatePasswordDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/me/password/change', async (userData: ExpertUpdatePasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerUpdatePassword(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertCurrentEmailAsync = createAsyncThunk<
  string,
  ExpertEmailCodeDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/email-current', async (userData: ExpertEmailCodeDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSendCurrentEmailVerificationCode(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertNewCodeEmailAsync = createAsyncThunk<
  string,
  ExpertEmailCodeDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/email-new-code', async (userData: ExpertEmailCodeDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSendNewEmailVerificationCode(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertGetVerificationCodeCurrentAsync = createAsyncThunk<
  string,
  ExpertVerifyCurrentEmailDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/currentEmail', async (userData: ExpertVerifyCurrentEmailDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerVerifyCurrentEmail(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertNewCurrentEmailAsync = createAsyncThunk<
  Expert,
  ExpertSetNewEmailDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/new-email-current', async (userData: ExpertSetNewEmailDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSetNewEmail(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
export const deleteMeAsync = createAsyncThunk<void, undefined, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/me',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerDeleteMe({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPatientByIdAsync = createAsyncThunk<Patient, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/getOnePatient',
  async (userData: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPatientsApi.expertPatientsControllerGetOnePatient(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAnalysisExpertIdAsync = createAsyncThunk<
  FindOneAnalysisWithPreviousResDto,
  string,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/getAnalysisExpertIdAsync', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerFindOneAnalysis(id, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createSignedUrlAsync = createAsyncThunk<
  FileUploadSignedUrlResDto,
  DocumentUploadSignedUrlDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/experts/signed-url-to-upload',
  async (documents: DocumentUploadSignedUrlDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerGetDocumentSignedUrlToUpload(documents, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getExpertAvatarSignedUrlAsync = createAsyncThunk<
  FileUploadSignedUrlResDto,
  PhotoUploadSignedUrlDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/experts/signed-url-to-upload-photo',
  async (photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const loadArrayUrlsAsync = createAsyncThunk<
  string,
  ExpertLoadEducationDocumentsDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/experts/signed-url-to-upload',
  async (urls: ExpertLoadEducationDocumentsDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerLoadEducationDocumentsURLs(urls, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
export type AnalyzesSearchQueryDto = {
  id: string;
  startDate?: string;
  endDate?: string;
  sortBy?: 'date' | 'createdAt' | 'updatedAt';
  sortDirection?: 'ASC' | 'DESC';
  normFilter?: 'all' | 'in-normal' | 'not-in-normal';
  page?: number;
  limit?: number;
};

export const getAnalyzesAsync = createAsyncThunk<
  SearchAnalyzesResDto,
  AnalyzesSearchQueryDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('experts/getAnalyzesAsync', async (params: AnalyzesSearchQueryDto, { extra: { api }, rejectWithValue }) => {
  const { id, limit, page, normFilter, sortBy, sortDirection, startDate, endDate } = params;
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerSearchPatientAnalyzes(
      id,
      startDate,
      endDate,
      sortBy,
      sortDirection,
      normFilter,
      page,
      limit,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAnamnesByIdAsync = createAsyncThunk<Anamnesis, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  'experts/getAnamnes',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPatientAnamnesisApi.expertPatientAnamnesisControllerGetAnamnesisByPatientId(
        id,
        {
          params: { l: language },
        },
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createChatAsync = createAsyncThunk<
  GetChatRoomsResDto,
  ExpertCreateChatRoomDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/createChat', async (chatParams: ExpertCreateChatRoomDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsChatsApi.expertsChatsControllerCreateChatRoom(chatParams, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface GetChat {
  searchQuery?: string;
}

export const getChatAsync = createAsyncThunk<
  { data: Array<GetChatRoomsResDto>; withSearch: boolean },
  GetChat,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/getChats', async (search: GetChat, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { searchQuery } = search;
    const { data } = await api.ExpertsChatsApi.expertsChatsControllerGetChatRooms(searchQuery, {
      params: { l: language },
    });
    return { data, withSearch: !!searchQuery };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getEventsAsync = createAsyncThunk<
  EventsGetEventResDto[],
  Array<'CALL' | 'VIDEOCALL' | 'MEETING'> | undefined,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/experts/getEvents',
  async (eventType: Array<'CALL' | 'VIDEOCALL' | 'MEETING'> | undefined, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsEventsApi.expertsEventsControllerGetEvents(eventType, undefined, undefined, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createEventAsync = createAsyncThunk<
  EventsGetEventResDto,
  ExpertCreateEventDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/createEvent', async (newEvent: ExpertCreateEventDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsEventsApi.expertsEventsControllerCreateEvent(newEvent, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface ChangeEvent {
  id: string;
  expertEditEventDto: ExpertEditEventDto;
}

export const changeEventAsync = createAsyncThunk<
  EventsGetEventResDto,
  ChangeEvent,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/changeEvent', async (newEvent: ChangeEvent, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { id, expertEditEventDto } = newEvent;
    const { data } = await api.ExpertsEventsApi.expertsEventsControllerEditEvent(id, expertEditEventDto, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteEventAsync = createAsyncThunk<string, string, ExtraParamsThunkType<ExpertValidationsErrors>>(
  '/experts/deleteEvent',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      await api.ExpertsEventsApi.expertsEventsControllerDeleteEvent(id, {
        params: { l: language },
      });
      return id;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getNutritionsPlansByIdAsync = createAsyncThunk<
  GetPatientNutritonPlanResDto,
  string,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/getNutrionPlanById', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } =
      await api.ExpertsPatientNutritionPlansApi.expertsNutritionPlansControllerGetOnePatientNutritionPlan(id, {
        params: { l: language },
      });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface UpdatePatientNutritionPlanDtoQuery {
  nutritionPlanId: string;
  plan: UpdatePatientNutritionPlanDto;
}

export const updateNutritionsPlansByIdAsync = createAsyncThunk<
  GetPatientNutritonPlanResDto,
  UpdatePatientNutritionPlanDtoQuery,
  ExtraParamsThunkType<ExpertValidationsErrors>
>(
  '/experts/updateNutrionPlan',
  async (nutritionPlan: UpdatePatientNutritionPlanDtoQuery, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { nutritionPlanId, plan } = nutritionPlan;
      const { data } =
        await api.ExpertsPatientNutritionPlansApi.expertsNutritionPlansControllerUpdatePatientNutritionPlan(
          nutritionPlanId,
          plan,
          {
            params: { l: language },
          },
        );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getNutritionPlansAsync = createAsyncThunk<
  GetPatientNutritonPlanResDto[],
  string,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/getNutrionPlans', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } =
      await api.ExpertsPatientNutritionPlansApi.expertsNutritionPlansControllerGetAllPatientNutritionPlans(id, {
        params: { l: language },
      });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const prescribeNutritionsPlansAsync = createAsyncThunk<
  GetPatientNutritonPlanResDto,
  PrescribePatientNutritionPlanDto,
  ExtraParamsThunkType<SerializedError>
>(
  '/experts/createNutritionPlan',
  async (planDto: PrescribePatientNutritionPlanDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } =
        await api.ExpertsPatientNutritionPlansApi.expertsNutritionPlansControllerPrescribePatientNutritionPlan(
          planDto,
          {
            params: { l: language },
          },
        );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleSwitch = createAction<string>('toggleSwitch');

export const getPatientDiaryByPatientIdAsync = createAsyncThunk<
  PatientDiary[],
  string,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/getPatientDiary', async (patientId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientDiaryApi.expertsPatientDiaryControllerGetPatientDiary(patientId, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertGetAnalysisAsync = createAsyncThunk<
  FindOneAnalysisWithPreviousResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('expert/findOneAnalysis', async (analysisId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerFindOneAnalysis(analysisId, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

type AssignCheckupToPatient = {
  id: string;
  checkupId: string;
};

export const expertSetCheckUpAsync = createAsyncThunk<
  boolean,
  AssignCheckupToPatient,
  ExtraParamsThunkType<SerializedError>
>('expert/set-checkup', async (checkup: AssignCheckupToPatient, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerAssignCheckupToPatient(
      checkup.id,
      checkup.checkupId,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createAndAssignCustomCheckUpForPatient = createAsyncThunk<
  CheckupResDto[],
  ExpertCreateCustomCheckupDto,
  ExtraParamsThunkType<SerializedError>
>(
  'expert/create-custom-checkup',
  async (checkup: ExpertCreateCustomCheckupDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerCreateCustomCheckup(checkup, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const acceptRequestForCooperation = createAsyncThunk<boolean, string, ExtraParamsThunkType<SerializedError>>(
  'expert/accept-request-for-cooperation',
  async (patientId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.RequestsApi.requestsControllerExpertAcceptRequest(patientId, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const declineRequestForCooperation = createAsyncThunk<boolean, string, ExtraParamsThunkType<SerializedError>>(
  'expert/decline-request-for-cooperation',
  async (patientId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.RequestsApi.requestsControllerExpertDeclineRequest(patientId, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllRecommendationsForPatient = createAsyncThunk<
  PatientRecommendationCardsGetResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('expert/get-all-recommendation-for-patient', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } =
      await api.ExpertsPatientRecommendationsApi.expertPatientRecommendationsControllerGetAllRecommendationCards(id, {
        params: { l: language },
      });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const stopCooperationWithPatient = createAsyncThunk<boolean, string, ExtraParamsThunkType<SerializedError>>(
  'expert/stop-cooperation-with-patient',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPatientsApi.expertPatientsControllerStopCooperation(id, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAssignedCheckupsForPatiens = createAsyncThunk<
  CheckupResDto[],
  string,
  ExtraParamsThunkType<SerializedError>
>('expert/get-assigned-checkups-for-patient', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerFindPatientAssignedCheckups(id, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export type UnsetCheckupsForPatiensProps = {
  checkupId: string;
  id: string;
};

export const unsetCheckupsForPatiens = createAsyncThunk<
  boolean,
  UnsetCheckupsForPatiensProps,
  ExtraParamsThunkType<SerializedError>
>(
  'expert/unset-checkups-for-patient',
  async (checkup: UnsetCheckupsForPatiensProps, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerCanselCheckupForPatient(
        checkup.id,
        checkup.checkupId,
        {
          params: { l: language },
        },
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export type GetRecommendationDto = {
  id: string;
  cardId: string;
};

export const getFirstTypeRecommendationByCardIdAsync = createAsyncThunk<
  FirstTypeCardsPatientGetResDto,
  GetRecommendationDto,
  ExtraParamsThunkType<SerializedError>
>(
  'expert/get-first-type-recommendation',
  async (patientRecommendation: GetRecommendationDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } =
        await api.ExpertsPatientRecommendationsApi.expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(
          patientRecommendation.id,
          patientRecommendation.cardId,
          {
            params: { l: language },
          },
        );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSecondTypeRecommendationByCardIdAsync = createAsyncThunk<
  SecondTypeCardsPatientGetResDto,
  GetRecommendationDto,
  ExtraParamsThunkType<SerializedError>
>(
  'expert/get-second-type-recommendation',
  async (patientRecommendation: GetRecommendationDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } =
        await api.ExpertsPatientRecommendationsApi.expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(
          patientRecommendation.id,
          patientRecommendation.cardId,
          {
            params: { l: language },
          },
        );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getThirdTypeRecommendationByCardIdAsync = createAsyncThunk<
  ThirdTypeCardsPatientGetResDto,
  GetRecommendationDto,
  ExtraParamsThunkType<SerializedError>
>(
  'expert/get-third-type-recommendation',
  async (patientRecommendation: GetRecommendationDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } =
        await api.ExpertsPatientRecommendationsApi.expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(
          patientRecommendation.id,
          patientRecommendation.cardId,
          {
            params: { l: language },
          },
        );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExpertSettings = createAsyncThunk<
  ExpertSettings,
  ExpertUpdateSettingsDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/experts/settings', async (expertSettings: ExpertUpdateSettingsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ExpertsSettingsApi.expertSettingsControllerUpdateExpertSettings(expertSettings);

    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getExpertSettings = createAsyncThunk<ExpertSettings, void, ExtraParamsThunkType<SerializedError>>(
  'expert/settings',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsSettingsApi.expertSettingsControllerGetExpertSettings({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
