// import 'react-big-calendar/lib/css/react-big-calendar.css';

import './customCalendarStyles.css';

import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
export { DatePicker } from 'UIcomponents';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import { ExpertCreateEventDtoEventTypeEnum } from 'api/generated';
import { actions } from 'features/expert';
import { selectCalendar, selectCalendarEvents, selectExpert } from 'features/expert/selectors';
import { getEventsAsync, getPatientsAsync } from 'features/expert/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { Modifier } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import { Spinner } from 'UIcomponents';

import { DatePickerCalendar, EventWrapper, Toolbar } from './components';
import { NewEventModal } from './components';
import styles from './styles.module.scss';

interface NewEventProps {
  begining: number;
  start: string;
  isChange: boolean;
  cost?: string;
  timeZone?: string;
  event?: ExpertCreateEventDtoEventTypeEnum;
  id: string;
}

const BigCalendar: FC = () => {
  const { i18n } = useTranslation();

  if (i18n.language === 'ru') {
    moment.locale('ru');
  } else {
    moment.locale('en-gb');
  }

  const localizer = momentLocalizer(moment);

  const dispatch = useAppDispatch();
  const events = useSelector(selectCalendarEvents);
  const calendarProps = useSelector(selectCalendar);
  const width = useWindowWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<NewEventProps>();
  const me = useSelector(selectExpert);

  const formats = {
    dayFormat: 'dddd',
    weekdayFormat: 'dddd',
  };

  const toggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const getBeginingEvent = (startDate: string, endDate: string) => {
    return moment(endDate).unix() - moment(startDate).unix();
  };

  const slotClick = (props: SlotInfo) => {
    if (new Date(props.start).getTime() >= new Date().getTime()) {
      const begining = getBeginingEvent(props.start as string, props.end as string);
      const startDate = moment(props.start).format('hh:mm');
      setModalProps({ begining: begining, start: props.start as string, isChange: true, id: '' });
      setIsModalOpen(!isModalOpen);
    }
  };

  useEffect(() => {
    dispatch(
      getPatientsAsync({
        page: 1, //TODO: check and fix after new api
        limit: 100000,
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    );
    dispatch(getEventsAsync(undefined)).catch((e) => {
      console.warn(e);
    });
  }, []);

  const disabledDays: Modifier | Modifier[] = {
    before: new Date(me.createdAt),
    after: new Date(new Date().setMonth(new Date().getMonth() + 4)),
  };

  return (
    <>
      <MainLayout>
        <div className={styles.calendars}>
          {width > 968 && (
            <DatePickerCalendar
              calendarProps={calendarProps}
              onCloseDatePicker={() => console.log('close')}
              selectedDay={new Date(calendarProps.date)}
              disabledDays={disabledDays}
            />
          )}
          <div style={{ height: 700, width: '100%', maxWidth: 954 }}>
            {events ? (
              <Calendar
                selectable
                formats={formats}
                onSelectSlot={slotClick}
                timeslots={1}
                components={{
                  toolbar: Toolbar,
                  eventWrapper: (props, event) => (
                    <EventWrapper
                      {...props}
                      {...event}
                      setIsModalOpen={setIsModalOpen}
                      setModalProps={setModalProps}
                      isModalOpen={isModalOpen}
                    />
                  ),
                }}
                onShowMore={(events, date) => dispatch(actions.setdate({ view: 'day', date: date }))}
                onDrillDown={(date, view) => {
                  dispatch(
                    actions.setdate({
                      view: 'day',
                      date: date,
                      onNavigate: calendarProps?.onNavigate?.('DATE', date),
                      onView: calendarProps?.onView?.('day'),
                    }),
                  );
                }}
                localizer={localizer}
                events={[...events]}
                step={30}
                defaultDate={calendarProps.date ? new Date(calendarProps.date) : new Date()}
              />
            ) : (
              <div className={styles.loader}>
                <Spinner radius={30} variant={'secondary'} color={'#646EFA'} />
              </div>
            )}
          </div>
        </div>
        {isModalOpen && modalProps && <NewEventModal onClose={toggleModal} {...modalProps} />}
      </MainLayout>
    </>
  );
};

export default BigCalendar;
