import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoadingStatus } from 'features/auth/selectors';
import { expertSignInAsync, patientSignInAsync } from 'features/auth/thunks';
import { actions as ExpertActions } from 'features/expert/slice';
import { actions } from 'features/patient/slice';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes, PatientRoutes, UnauthorizedRoutes } from 'types/routes';
import { ButtonDefault, FormError, FormLabel, InputDefault, PasswordInput } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface FormValues {
  email: string;
  password: string;
}

interface SignInFormProps {
  isExpert: boolean;
}

export const SignInForm: FC<SignInFormProps> = ({ isExpert }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const expertSignIn = async (data: FormValues) => {
    await dispatch(expertSignInAsync(data))
      .then(unwrapResult)
      .then((data) => {
        history.push(CommonRoutes.ONBOARDING);
        dispatch(ExpertActions.saveExpert(data.expert));
      })
      .catch((error) => {
        if (error.message === 'Неверный пароль') {
          setError('password', {
            type: 'backendError',
            message: error.message,
          });
        } else if (error.message === 'Пользователь с таким email адресом не найден') {
          setError('email', {
            type: 'backendError',
            message: error.message,
          });
        } else notify('error', error.message);
      });
  };

  const patientSignIn = async (data: FormValues) => {
    await dispatch(patientSignInAsync(data))
      .then(unwrapResult)
      .then((data) => {
        dispatch(actions.savePatient(data.patient));
        history.push(PatientRoutes.ONBOARDING_PATIENT);
      })
      .catch((error) => {
        if (error.message === 'Неверный пароль') {
          setError('password', {
            type: 'backendError',
            message: error.message,
          });
        } else if (error.message === 'Пациент с таким email адресом не найден') {
          setError('email', {
            type: 'backendError',
            message: error.message,
          });
        } else notify('error', error.message);
      });
  };

  const onSubmit = async (data: FormValues) => {
    isExpert ? await expertSignIn(data) : await patientSignIn(data);
  };

  return (
    <div className={styles['sign-in']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['sign-in__field']}>
          <FormLabel text={t('email')} />
          <Controller
            name="email"
            control={control}
            rules={{
              required: t('required') as string,
              pattern: {
                value: patterns.email,
                message: t('emailPattern'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                onChange={(e) => {
                  const value = e.target.value;
                  const trimValue = value.trim();
                  e.target.value = trimValue;
                  onChange(e);
                }}
                value={value}
                error={errors.email && 'error'}
                placeholder="example@email.com"
              />
            )}
          />
          {errors.email?.message && <FormError type="error" text={errors.email.message} />}
        </div>
        <div className={styles['sign-in__field']}>
          <FormLabel
            text={t('password')}
            link={CommonRoutes.FORGOT_PASSWORD}
            linkLocationState={isExpert ? 'expert' : 'patient'}
            linkText={t('forgotPassword') as string}
            customContainerStyles={{ display: 'flex', justifyContent: 'space-between' }}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: t('required') as string }}
            render={({ field: { onChange, value } }) => (
              <PasswordInput onChange={onChange} value={value} error={errors.password && true} placeholder="••••••" />
            )}
          />
          {errors.password?.message && <FormError type="error" text={errors.password.message} />}
        </div>
        <ButtonDefault
          text={t('signIn')}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </form>

      <div className={styles['sign-in__header']}>
        <span className={`body body_medium ${styles['sign-in__subtitle']}`}></span>
        <Link to={UnauthorizedRoutes.SIGN_UP} className={`body body_bold ${styles['sign-in__link']}`}>
          {t('signUp')}
        </Link>
      </div>
    </div>
  );
};
