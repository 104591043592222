import { ArrowIcon } from 'assets/svg';
import { FC } from 'react';

import styles from '../../styles.module.scss';

interface ButtonProps {
  onClick: () => void;
}
export const ScrollToBottom: FC<ButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.scrollToBottom} onClick={onClick}>
      <ArrowIcon />
    </button>
  );
};
