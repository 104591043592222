import { Check, Eng, Ru } from 'assets/svg';
import { FC, useEffect, useState } from 'react';
import { getLanguageFromStorage, LanguagesEnum, setLanguageToStorage } from 'services/storageService';

import styles from './styles.module.scss';

export const LanguageSelector: FC = () => {
  const [languageSelector, setLanguage] = useState<LanguagesEnum>(LanguagesEnum.en);
  const isEnglish = languageSelector === LanguagesEnum.en;

  const handleClickEn = () => {
    setLanguageToStorage(LanguagesEnum.en);
    setLanguage(LanguagesEnum.en);
    window.location.reload();
  };

  const handleClickRu = () => {
    setLanguageToStorage(LanguagesEnum.ru);
    setLanguage(LanguagesEnum.ru);
    window.location.reload();
  };

  useEffect(() => {
    const language = getLanguageFromStorage();

    if (language === null) {
      setLanguage(LanguagesEnum.ru);
      setLanguageToStorage(LanguagesEnum.ru);
    }

    if (language === LanguagesEnum.en) {
      setLanguage(LanguagesEnum.en);
    } else {
      setLanguage(LanguagesEnum.ru);
    }
  }, []);

  return (
    <div className={styles.root}>
      <button className={styles.button} onClick={handleClickRu}>
        <div className={styles.language}>
          <Ru />
          <p>Рус</p>
        </div>
        {!isEnglish && <Check pathFill={'var(--blue)'} className={styles.check} />}
      </button>
      <button className={styles.button} onClick={handleClickEn}>
        <div className={styles.language}>
          <Eng />
          <p>Eng</p>
        </div>
        {isEnglish && <Check pathFill={'var(--blue)'} className={styles.check} />}
      </button>
    </div>
  );
};
