import * as React from 'react';

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#8E8E8E' } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 14.123l9.346-9.346A3.69 3.69 0 0019.076 4H4.925a3.69 3.69 0 00-2.27.777L12 14.123zM16.03 12l6.23 6.237c.458-.701.74-1.555.74-2.483V8.209c0-.93-.282-1.782-.74-2.484L16.03 12zM7.97 12L1.74 5.763A4.536 4.536 0 001 8.246v7.545c0 .93.282 1.782.74 2.484L7.97 12z"
        fill={fill}
      />
      <path
        d="M15.08 12.948L12 16.038l-3.08-3.09-6.266 6.275a3.69 3.69 0 002.27.777h14.134a3.69 3.69 0 002.27-.777l-6.247-6.275z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgEmail;
