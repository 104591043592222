import classNames from 'classnames';
import { selectExpert, selectLoadingChange } from 'features/expert/selectors';
import { FC, ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';
import { ButtonDefault, FormError, FormLabel, InputDefault } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';
import { EmailSendingFormValues } from './UpdateEmailSteps';

type SendEmailStepProps = {
  type: 'current' | 'new';
  text: string | ReactElement;
  currentEmail: (data: EmailSendingFormValues) => void;
  newEmail: (data: EmailSendingFormValues) => void;
  nextStep: () => void;
  disableInput?: boolean;
};

export const SendEmailStep: FC<SendEmailStepProps> = ({ type, text, currentEmail, newEmail, disableInput = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.profileSettingsTab' });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const email = useAppSelector(selectExpert).email;
  const loadingStatus = useAppSelector(selectLoadingChange);

  const onSubmit = (data: EmailSendingFormValues) => {
    type === 'current' ? currentEmail(data) : newEmail(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['update-email-step']}>
        <div className={classNames('body_medium', styles['update-email-step__text'])}>{text}</div>
        <div className={classNames('subhead_regular', styles['update-email-step__label'])}>
          <FormLabel text={t('updateEmailLabel') as string} />
        </div>
        <Controller
          name="email"
          control={control}
          defaultValue={email}
          rules={{
            required: t('updateEmailLabel') as string,
            pattern: {
              value: patterns.email,
              message: t('emailPattern') as string,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <InputDefault
              onChange={(e) => {
                const value = e.target.value;
                const trimValue = value.trim();
                e.target.value = trimValue;
                onChange(e);
              }}
              customStyles={{ maxWidth: '371px' }}
              value={value}
              error={errors.email && 'error'}
              disabled={disableInput}
            />
          )}
        />
        {errors.email?.message && <FormError type="error" text={errors.email.message} />}
      </div>

      <ButtonDefault
        type="submit"
        customStyles={{ maxWidth: '371px' }}
        isLoading={loadingStatus === 'pending'}
        text={t('newCode')}
      />
    </form>
  );
};
