import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertSignUpDto } from 'api/generated';
import { selectLoadingStatus } from 'features/auth/selectors';
import {
  expertGetVerificationCodeAsync,
  expertSignUpAsync,
  patientGetVerificationCodeAsync,
  patientSignUpAsync,
} from 'features/auth/thunks';
import { actions as expertActions } from 'features/expert/slice';
import { actions as patientActions } from 'features/patient/slice';
import { AuthLayout } from 'layouts';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { AppDispatch } from 'store/types';
import { LoadingStatus } from 'types';
import { AllowedMasks } from 'types/Input';
import { UnauthorizedRoutes } from 'types/routes';
import { LastLoginedType } from 'types/user';
import { ButtonDefault, FormError, FormLabel, MaskedInput, Spinner } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './styles.module.scss';

interface LocationState {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface FormValues {
  verificationCode: string;
}

export const EmailVerification: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const location = useLocation<LocationState>();
  const data = location?.state;
  const dispatch: AppDispatch = useDispatch();
  const [seconds, setSeconds] = useState(59);
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;

  const [lastLoginedType] = useLocalStorage('last-logined-type');
  const isExpert = lastLoginedType === LastLoginedType.EXPERT;

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  const verificationCodeWatch = watch('verificationCode');

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (verificationCodeWatch && verificationCodeWatch.length === 4) {
      onSubmit({ ...data, verificationCode: verificationCodeWatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCodeWatch]);

  const getExpertVerificationCode = async (email: string) => {
    await dispatch(expertGetVerificationCodeAsync({ email }))
      .then(unwrapResult)
      .then(() => {
        setSeconds(59);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const getPatientVerificationCode = async (email: string) => {
    await dispatch(patientGetVerificationCodeAsync({ email }))
      .then(unwrapResult)
      .then(() => {
        setSeconds(59);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const expertSignUp = async (data: ExpertSignUpDto) => {
    await dispatch(expertSignUpAsync(data))
      .then(unwrapResult)
      .then((data) => {
        expertActions.saveExpert(data.expert);

        //Metrics
        analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_VERIFICATION_CODE_ENTERED);
      })
      .catch((error) => {
        if (error.message === 'Неверный код') {
          setError('verificationCode', {
            type: 'backendError',
            message: error.message,
          });
        } else notify('error', error.message);
      });
  };

  const patientSignUp = async (data: ExpertSignUpDto) => {
    await dispatch(patientSignUpAsync(data))
      .then(unwrapResult)
      .then((data) => {
        patientActions.savePatient(data.patient);

        //Metrics
        analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_VERIFICATION_CODE_ENTERED);
      })
      .catch((error) => {
        if (error.message === 'Неверный код') {
          setError('verificationCode', {
            type: 'backendError',
            message: error.message,
          });
        } else notify('error', error.message);
      });
  };

  const onSubmit = async (data: ExpertSignUpDto) => {
    reset({ verificationCode: '' });
    isExpert ? await expertSignUp(data) : await patientSignUp(data);
  };

  const handleResendCode = async (email: string) => {
    isExpert ? await getExpertVerificationCode(email) : getPatientVerificationCode(email);
    reset({ verificationCode: '' }, { keepErrors: false });
  };

  if (!data?.email) {
    return <Redirect to={UnauthorizedRoutes.ROOT} />;
  }

  return (
    <AuthLayout
      title={t('verif') as string}
      backOptions={{ url: UnauthorizedRoutes.SIGN_UP, text: t('goBack') as string }}
      withFooter
      isPatient={!isExpert}>
      <div className={styles['email-verification']}>
        <div className={styles['email-verification__header']}>
          <span className={`body body_medium ${styles['email-verification__subtitle']}`}>
            {t('sendCode')}
            <span className="body_bold">{data?.email}</span>
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['email-verification__field']}>
            <FormLabel text={t('code')} />
            <Controller
              name="verificationCode"
              control={control}
              rules={{
                required: true,
                minLength: { value: 4, message: t('codePattern') },
              }}
              render={({ field: { onChange, value } }) =>
                isLoading ? (
                  <div className={styles['email-verification__spinner']}>
                    <Spinner variant="secondary" />
                  </div>
                ) : (
                  <MaskedInput
                    mask={AllowedMasks.verificationCode}
                    onChange={onChange}
                    value={value}
                    error={errors.verificationCode && 'error'}
                    placeholder={t('code') as string}
                  />
                )
              }
            />
            {errors.verificationCode?.message && <FormError type="error" text={errors.verificationCode.message} />}
          </div>
          {seconds > 0 ? (
            <p className={`body body_medium ${styles['email-verification__repeat-code']}`}>
              {t('resend')} 00:{seconds.toString().length === 1 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <ButtonDefault text={t('getNewCode')} onClick={() => handleResendCode(data.email)} />
          )}
        </form>
      </div>
    </AuthLayout>
  );
};
