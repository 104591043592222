import { FooterMobile, HeaderDefault } from 'components';
import useWindowWidth from 'hooks/useWindowWidth';
import { ReactNode } from 'react';

import styles from './MainLayout.module.scss';

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const width = useWindowWidth();
  const isMobile = width <= 760;

  return (
    <div className={styles.root}>
      <HeaderDefault />
      {children}
      {isMobile && <FooterMobile />}
    </div>
  );
};

export default MainLayout;
