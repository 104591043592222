import { unwrapResult } from '@reduxjs/toolkit';
import { PatientSettings } from 'api/generated';
import classNames from 'classnames';
import { getPatientSettings, updatePatientSettings } from 'features/patient/thunks';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault, SwitchButton } from 'UIcomponents';

import styles from './styles.module.scss';

interface FormValues {
  newMessageInChatNotification: boolean;
}

export const PatientNotificationsTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNotificationsTab' });
  const [patientSettings, setPatientSettings] = useState<PatientSettings>({} as PatientSettings);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      newMessageInChatNotification: patientSettings.newMessageInChatNotification,
    },
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = async (data: FormValues) => {
    await dispatch(
      updatePatientSettings({
        ...data,
      }),
    )
      .then(unwrapResult)
      .then(() => notify('success', t('notifySucces')))
      .catch((error) => {
        notify('error', error.message);
      });
  };
  useEffect(() => {
    dispatch(getPatientSettings())
      .then(unwrapResult)
      .then((result) => {
        setPatientSettings(result);

        reset(result);
      });
    return () => {
      setPatientSettings({} as PatientSettings);
      reset({} as FormValues);
    };
  }, [dispatch, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <h4 className={styles['patient-info-form__title']}>{t('notifications')}</h4>

      <p className={classNames('body_medium', styles['patient-info-form__subtitle'])}>{t('description')}</p>

      <div className={classNames('flexbox', 'justify-between', styles['patient-info-form'])}>
        <div className={classNames(styles['patient-info-form__input'])}>
          <div className={classNames(styles['patient-info-form__label'])}>{t('chatNotifications')}</div>
          <Controller
            name="newMessageInChatNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <div className={classNames('flexbox', 'justify-end', styles['patient-info-form__buttons'])}>
        <ButtonDefault
          onClick={handleSubmit(onSubmit)}
          text={t('action')}
          type={'button'}
          isDisabled={!isDirty}
          customStyles={{ width: '200px', margin: 0 }}
        />
      </div>
    </form>
  );
};
