import 'react-day-picker/lib/style.css';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import { EventsGetEventResDtoEventTypeEnum } from 'api/generated';
import { CalendarArrowLeft, CalendarArrowRight } from 'assets/svg';
import classnames from 'classnames';
import { getEventsAsync } from 'features/expert/thunks';
import { Calendar } from 'features/expert/types';
import useWindowWidth from 'hooks/useWindowWidth';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-big-calendar';
import DayPicker, { AfterModifier, BeforeModifier, DayModifiers, Modifier } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { ClassNames } from 'react-day-picker/types/ClassNames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/reducers';
import { Checkbox } from 'UIcomponents';

import { Navbar } from './components';
import styles from './styles.module.scss';

const classNames: ClassNames = {
  container: styles.datepicker__container,
  wrapper: styles.datepicker__wrapper,
  caption: styles.datepicker__caption,
  body: styles.datepicker__body,
  interactionDisabled: styles.datepicker__interaction_disabled,
  navBar: styles.datepicker__navbar,
  navButtonInteractionDisabled: styles['datepicker__nav-button-interaction_disabled'],
  navButtonNext: styles['datepicker__nav-button_next'],
  navButtonPrev: styles['datepicker__nav-button_prev'],
  footer: styles.datepicker__footer,

  weekNumber: styles['datepicker__week-number'],
  months: styles.datepicker__months,
  month: styles.datepicker__month,
  weekdays: styles.datepicker__weekdays,
  weekdaysRow: styles['datepicker-weekdays__row'],
  weekday: styles.datepicker__weekday,
  week: styles.datepicker__week,
  day: styles.datepicker__day,
  outside: styles.datepicker__day_outside,
  today: styles.datepicker__today,
  todayButton: styles['datepicker__today-button'],

  selected: styles.datepicker_selected,
  disabled: styles.datepicker_disabled,
};

type DatePickerProps = {
  selectedDay: Date;
  calendarProps: Calendar;
  setSelectedDay?: (date: Date) => void;
  showOutsideDays?: boolean;
  disabledDays?: Modifier | Modifier[];
  onCloseDatePicker: () => void;
};
export const DatePickerCalendar: FC<DatePickerProps> = ({
  selectedDay,
  calendarProps,
  showOutsideDays = true,
  disabledDays = { after: new Date() },
}: DatePickerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'calendar' });

  const fromMonth = (disabledDays as BeforeModifier).before;
  const [type, setType] = useState<Array<EventsGetEventResDtoEventTypeEnum>>([
    EventsGetEventResDtoEventTypeEnum.Call,
    EventsGetEventResDtoEventTypeEnum.Meeting,
    EventsGetEventResDtoEventTypeEnum.Videocall,
  ]);

  const toMonth = (disabledDays as AfterModifier).after || new Date();
  const width = useWindowWidth();
  const [month, setMonth] = useState(selectedDay || toMonth);
  const dispatch = useAppDispatch();
  const [preselect, setPreselect] = useState(new Date(calendarProps.date));

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers[styles.datepicker_disabled]) {
      return;
    }
    calendarProps.onNavigate && calendarProps.onNavigate('DATE', day);
    setPreselect(new Date(day));
  };

  const toggleTypeEvent = (event: EventsGetEventResDtoEventTypeEnum) => {
    if (!type.includes(event)) {
      const newType = [...type, event];
      setType(newType);
      dispatch(getEventsAsync([...newType]));
    } else {
      const newType = type.filter((elem) => elem !== event);
      setType((state) => state.filter((elem) => elem !== event));
      dispatch(getEventsAsync([...newType]));
    }
  };

  const onChangeYear = (newMonth: Date) => {
    calendarProps.onNavigate && calendarProps.onNavigate('DATE', newMonth);
    setMonth(newMonth);
  };

  const navigate = (dayValue: 'PREV' | 'NEXT') => {
    calendarProps?.onNavigate?.(dayValue);
  };

  const isNextButtonRestrict = useMemo(() => {
    const isYearWithRestrict =
      new Date(selectedDay).getFullYear() >= new Date((disabledDays as AfterModifier).after).getFullYear();

    if (calendarProps.view === 'month' && isYearWithRestrict) {
      return new Date(selectedDay).getMonth() >= new Date((disabledDays as AfterModifier).after).getMonth();
    }

    if (calendarProps.view === 'week' && isYearWithRestrict) {
      return (
        new Date(selectedDay).getMonth() >= new Date((disabledDays as AfterModifier).after).getMonth() &&
        new Date(selectedDay).getDate() + 6 >= new Date((disabledDays as AfterModifier).after).getDate()
      );
    }

    if (calendarProps.view === 'day' && isYearWithRestrict) {
      return (
        new Date(selectedDay).getMonth() >= new Date((disabledDays as AfterModifier).after).getMonth() &&
        new Date(selectedDay).getDate() >= new Date((disabledDays as AfterModifier).after).getDate()
      );
    }
  }, [calendarProps.view, selectedDay]);

  const isPrevButtonRestrict = useMemo(() => {
    const isYearWithRestrict =
      new Date(selectedDay).getFullYear() <= new Date((disabledDays as BeforeModifier).before).getFullYear();

    if (calendarProps.view === 'month' && isYearWithRestrict) {
      return new Date(selectedDay).getMonth() <= new Date((disabledDays as BeforeModifier).before).getMonth();
    }

    if (calendarProps.view === 'week' && isYearWithRestrict) {
      return (
        new Date(selectedDay).getMonth() <= new Date((disabledDays as BeforeModifier).before).getMonth() &&
        new Date(selectedDay).getDate() - 6 <= new Date((disabledDays as BeforeModifier).before).getDate()
      );
    }

    if (calendarProps.view === 'day' && isYearWithRestrict) {
      return (
        new Date(selectedDay).getMonth() <= new Date((disabledDays as BeforeModifier).before).getMonth() &&
        new Date(selectedDay).getDate() <= new Date((disabledDays as BeforeModifier).before).getDate()
      );
    }
  }, [calendarProps.view, selectedDay]);

  const { i18n } = useTranslation();

  const getTitleDate = (date: Date, view?: View): string | undefined => {
    if (view === 'month')
      return i18n.language === 'ru'
        ? moment(date).locale('ru').format('MMMM YYYY')
        : moment(date).locale('en-gb').format('MMMM YYYY');

    if (view === 'week') {
      const monthYearDate =
        i18n.language === 'ru'
          ? moment(date).locale('ru').format('D MMMM YYYY').split(' ')
          : moment(date).locale('en-gb').format('D MMMM YYYY').split(' ');

      monthYearDate.shift();

      return i18n.language === 'ru'
        ? moment(date).locale('ru').startOf('week').toDate().getDate() +
            '-' +
            moment(date).locale('ru').endOf('week').toDate().getDate() +
            ' ' +
            monthYearDate.join(' ')
        : moment(date).locale('en-gb').startOf('week').toDate().getDate() +
            '-' +
            moment(date).locale('en-gb').endOf('week').toDate().getDate() +
            ' ' +
            monthYearDate.join(' ');
    }

    if (view === 'day')
      return i18n.language === 'ru'
        ? moment(date).locale('ru').format('D MMMM YYYY')
        : moment(date).locale('en-gb').format('D MMMM YYYY');
  };

  const titleDate = getTitleDate(calendarProps.date, calendarProps.view);

  const titleCalendarePropsDateFormat =
    i18n.language === 'ru'
      ? moment(calendarProps.date).locale('ru').format('DD MMMM YYYY')
      : moment(calendarProps.date).locale('en-gb').format('DD MMMM YYYY');

  return (
    <div className={styles.datepicker__box}>
      {width > 968 && (
        <div className={styles.toolbar__navigation}>
          <h1 className={styles.datepicker__date}>{titleDate ? titleDate : titleCalendarePropsDateFormat}</h1>
          <div className={styles.toolbar__arrows}>
            <button
              className={styles.toolbar__arrow}
              onClick={() => {
                !isPrevButtonRestrict && navigate('PREV');
              }}>
              <CalendarArrowLeft />
            </button>
            <button
              className={styles.toolbar__arrow}
              onClick={() => {
                !isNextButtonRestrict && navigate('NEXT');
              }}>
              <CalendarArrowRight />
            </button>
          </div>
        </div>
      )}
      <div className={classnames(styles['datepicker-wrapper'])}>
        <DayPicker
          month={calendarProps.date as unknown as Date}
          fromMonth={fromMonth}
          toMonth={toMonth}
          classNames={classNames}
          navbarElement={(props) => (
            <Navbar fromMonth={fromMonth} toMonth={toMonth} onChangeYear={onChangeYear} {...props} />
          )}
          selectedDays={new Date(calendarProps.date)}
          onDayClick={handleDayClick}
          localeUtils={MomentLocaleUtils}
          locale="en-gb"
          disabledDays={disabledDays}
          showOutsideDays={showOutsideDays}
        />
        <div className={styles.filter}>
          <div className={styles.filter__container}>
            <div className={styles.filter__title}>
              <div className={styles.dot__call} />
              <p>{t('call')}</p>
            </div>
            <Checkbox
              checked={type.includes(EventsGetEventResDtoEventTypeEnum.Call)}
              onChange={() => toggleTypeEvent(EventsGetEventResDtoEventTypeEnum.Call)}
            />
          </div>
          <div className={styles.filter__container}>
            <div className={styles.filter__title}>
              <div className={styles.dot__video} />
              <p>{t('videocall')}</p>
            </div>
            <Checkbox
              checked={type.includes(EventsGetEventResDtoEventTypeEnum.Videocall)}
              onChange={() => toggleTypeEvent(EventsGetEventResDtoEventTypeEnum.Videocall)}
            />
          </div>
          <div className={styles.filter__container}>
            <div className={styles.filter__title}>
              <div className={styles.dot__meeting} />
              <p>{t('meeting')}</p>
            </div>
            <Checkbox
              checked={type.includes(EventsGetEventResDtoEventTypeEnum.Meeting)}
              onChange={() => toggleTypeEvent(EventsGetEventResDtoEventTypeEnum.Meeting)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
