import 'react-day-picker/lib/style.css';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import classnames from 'classnames';
import { FC, useState } from 'react';
import DayPicker, { AfterModifier, DayModifiers, Modifier } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { ClassNames } from 'react-day-picker/types/ClassNames';
import { useTranslation } from 'react-i18next';
import { ButtonDefault } from 'UIcomponents';

import { Navbar } from './components';
import styles from './styles.module.scss';

const classNames: ClassNames = {
  container: styles.datepicker__container,
  wrapper: styles.datepicker__wrapper,
  caption: styles.datepicker__caption,
  body: styles.datepicker__body,
  interactionDisabled: styles.datepicker__interaction_disabled,
  navBar: styles.datepicker__navbar,
  navButtonInteractionDisabled: styles['datepicker__nav-button-interaction_disabled'],
  navButtonNext: styles['datepicker__nav-button_next'],
  navButtonPrev: styles['datepicker__nav-button_prev'],
  footer: styles.datepicker__footer,

  weekNumber: styles['datepicker__week-number'],
  months: styles.datepicker__months,
  month: styles.datepicker__month,
  weekdays: styles.datepicker__weekdays,
  weekdaysRow: styles['datepicker-weekdays__row'],
  weekday: styles.datepicker__weekday,
  week: styles.datepicker__week,
  day: styles.datepicker__day,
  outside: styles.datepicker__day_outside,
  today: styles.datepicker__today,
  todayButton: styles['datepicker__today-button'],

  selected: styles.datepicker_selected,
  disabled: styles.datepicker_disabled,
};

type DatePickerProps = {
  selectedDay: Date;
  setSelectedDay?: (date: Date) => void;
  showOutsideDays?: boolean;
  disabledDays?: Modifier | Modifier[];
  onCloseDatePicker: () => void;
};

export const DatePicker: FC<DatePickerProps> = ({
  selectedDay,
  setSelectedDay,
  showOutsideDays = true,
  disabledDays = { after: new Date() },
  onCloseDatePicker,
}: DatePickerProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'datePicker' });

  const fromMonth = new Date('1900');
  const toMonth = (disabledDays as AfterModifier).after || new Date();
  const [month, setMonth] = useState(selectedDay || toMonth);
  const [preselect, setPreselect] = useState(selectedDay || toMonth);

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers[styles.datepicker_disabled]) {
      return;
    }

    setPreselect(new Date(day));
  };

  const handleSaveClick = () => {
    if (setSelectedDay) {
      setSelectedDay(new Date(preselect));
    }

    onCloseDatePicker();
  };

  const onChangeYear = (newMonth: Date) => {
    setMonth(newMonth);
  };

  const isDateChanged = selectedDay.toDateString() === preselect.toDateString();

  return (
    <div className={classnames(styles['datepicker-wrapper'])}>
      <DayPicker
        month={month}
        fromMonth={fromMonth}
        toMonth={toMonth}
        classNames={classNames}
        navbarElement={(props) => (
          <Navbar fromMonth={fromMonth} toMonth={toMonth} onChangeYear={onChangeYear} {...props} />
        )}
        selectedDays={new Date(preselect || '')}
        onDayClick={handleDayClick}
        localeUtils={MomentLocaleUtils}
        showOutsideDays={showOutsideDays}
        disabledDays={disabledDays}
      />

      <div className={classnames('flexbox', 'justify-between', styles['datepicker__actions'])}>
        <ButtonDefault
          text={t('cancel')}
          onClick={onCloseDatePicker}
          variant="secondary"
          containerClassNames={styles['datepicker__actions-btn']}
        />
        <ButtonDefault
          text={t('save')}
          onClick={handleSaveClick}
          containerClassNames={styles['datepicker__actions-btn']}
          isDisabled={isDateChanged}
        />
      </div>
    </div>
  );
};
