import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertGetPatientsResDto } from 'api/generated';
import { TableColumType, TableHeader } from 'components';
import { selectLoadingPaymentsStatus, selectPayments } from 'features/expert/selectors';
import { getPatientsAsync, getPaymentsDiaryAsync, sortPaymentsDiaryAsync } from 'features/expert/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ButtonDefault, Spinner } from 'UIcomponents';

import { NewPaymentModal } from '../../NewPaymentModal';
import { EmptyPaymentsDiary } from './EmptyPaymentsDiary';
import { ResponsivePaymentItem } from './ResponsivePaymentItem';
import styles from './styles.module.scss';
import { TableRow } from './TableRow';

enum sortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface SortingTypes {
  sort: 'name' | 'date' | 'amount' | 'paid';
  sortDirection: 'ASC' | 'DESC';
}

export const PaymentsDiaryTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.paymentsDiaryTab' });

  const columns: TableColumType[] = [
    { label: t('name'), hasSorting: true, aspect: 2, sort: 'name', isSort: false },
    { label: t('date'), hasSorting: true, aspect: 1, sort: 'date', isSort: false },
    { label: t('sum'), hasSorting: true, aspect: 0.8, sort: 'amount', isSort: false },
    { label: t('status'), hasSorting: true, aspect: 1.2, sort: 'paid', isSort: false },
  ];

  const [patients, setPatients] = useState<Array<{ label: string; value: string }>>();
  const dispatch = useAppDispatch();
  const [modalOpened, setModalOpened] = useState(false);
  const [header, setHeader] = useState<TableColumType[]>(columns);
  const [sort, setSort] = useState('date');
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState<sortDirectionEnum>(sortDirectionEnum.DESC);
  const width = useWindowWidth();
  const payments = useAppSelector(selectPayments);
  const loading = useAppSelector(selectLoadingPaymentsStatus) === LoadingStatus.pending;

  const sorting = (sortName: string) => {
    const result = columns.map((elem) => {
      if (elem.sort === sortName) {
        elem.isSort = true;
        if (sortName !== sort) {
          setSortDirection(sortDirectionEnum.ASC);
        } else {
          setSortDirection(sortDirection === sortDirectionEnum.ASC ? sortDirectionEnum.DESC : sortDirectionEnum.ASC);
        }
      } else {
        elem.isSort = false;
      }
      return elem;
    });
    setHeader(result);
    setSort(sortName);
  };

  const loadMore = () => {
    payments.count === 20 &&
      dispatch(
        getPaymentsDiaryAsync({
          page: String(page + 1),
          limit: '20',
          sort: sort as SortingTypes['sort'],
          sortDirection: sortDirection,
        }),
      );
  };

  const createDropdownArray = (patients: ExpertGetPatientsResDto) => {
    const result = patients.data.map((patient) => {
      return { label: patient.name, value: patient.id };
    });
    setPatients(result);
  };

  useEffect(() => {
    dispatch(
      getPatientsAsync({
        page: 1, //TODO: need pagination
        limit: 100000,
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    )
      .then(unwrapResult)
      .then((result) => createDropdownArray(result));
  }, []);

  useEffect(() => {
    dispatch(
      sortPaymentsDiaryAsync({
        page: '1',
        limit: payments.data && payments.data.length > 0 ? String(payments.data.length) : '20',
        sort: sort as SortingTypes['sort'],
        sortDirection: sortDirection,
      }),
    );
  }, [sort, sortDirection]);

  const isPaymentsDiaryEmpty = payments.data && payments.data.length === 0;
  const hasPatients = patients && patients.length > 0;

  return (
    <>
      {isPaymentsDiaryEmpty ? (
        <EmptyPaymentsDiary patients={patients} />
      ) : (
        <div className={styles['payments-diary']}>
          <div className={styles['payments-diary__header']}>
            <h4>{t('paymentsDiary')}</h4>
            <ButtonDefault
              text={t('create')}
              isDisabled={isEmpty(patients)}
              onClick={() => setModalOpened(true)}
              variant="secondary"
              containerClassNames={styles['payments-diary__download-button']}
            />
          </div>

          <div className={styles.table}>
            <div className={styles.table__header}>
              <TableHeader sorting={sorting} columns={columns} />
            </div>

            <div className={styles.table__body}>
              {payments.data && payments.data.length > 0 && (
                <Virtuoso
                  style={{
                    height: '674px',
                  }}
                  data={payments.data}
                  totalCount={payments.data.length}
                  endReached={loadMore}
                  itemContent={(index, user) => (
                    <>
                      {width > 1000 ? (
                        <TableRow key={index} data={user} sorting={sorting} columns={columns} />
                      ) : (
                        <ResponsivePaymentItem key={index} data={user} sorting={sorting} columns={columns} />
                      )}
                    </>
                  )}
                />
              )}
              {loading && (
                <div className={styles.table__loading}>
                  <Spinner variant={'secondary'} radius={20} color="var(--background-primary)" />
                </div>
              )}
            </div>
          </div>
          {hasPatients && (
            <NewPaymentModal
              isModalOpened={modalOpened}
              onCloseModal={() => setModalOpened(false)}
              openSuccessModal={() => setModalOpened(true)}
              patients={patients}
            />
          )}
        </div>
      )}
    </>
  );
};
