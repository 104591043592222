import { ThirdTypeCardsPatientGetResDto } from 'api/generated';
import { ErrorIcon, HorizontalArrowIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import moment from 'moment';
import { CSSProperties, FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { ExpertRoutes, PatientRoutes } from 'types/routes';
import { RecommendationScale } from 'UIcomponents';

import styles from './styles.module.scss';

type DysfunctionRecommendationCardProps = {
  recommendation: ThirdTypeCardsPatientGetResDto;
  score: number;
  missingBiomarkers?: string[];
  linkMoreText?: string;
  tooltipText?: string;
  showTooltip?: boolean;
  hideLinkText?: boolean;
  customStyles?: CSSProperties;
  textSize?: 'big' | 'small';
  heightSize?: 'big' | 'small';
};

export const DysfunctionRecommendationCard: FC<DysfunctionRecommendationCardProps> = ({
  recommendation,
  score,
  missingBiomarkers,
  linkMoreText,
  tooltipText,
  showTooltip = false,
  hideLinkText = false,
  textSize = 'small',
  heightSize = 'small',
  customStyles,
}) => {
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'recommendationCard' });

  const { thirdTypeCardId, label, description, createdAt, updatedAt } = recommendation;

  const isUpdated = !(createdAt === updatedAt);
  const date = createdAt === updatedAt ? createdAt : updatedAt;
  const formattedDate =
    i18n.language === 'ru'
      ? moment(date).locale('ru').format('DD MMMM, YYYY')
      : moment(date).locale('en-gb').format('DD MMMM, YYYY');

  const role = useAppSelector(selectRole);
  const isExpert = role === RolesEnum.EXPERT;
  const { id: patientId } = useParams<{ id: string }>();

  return (
    <div
      style={customStyles}
      className={classnames(styles.card, {
        [styles.card_small]: textSize === 'small',
        [styles.card_big]: textSize === 'big',
        [styles['card_small-height']]: heightSize === 'small',
        [styles['card_big-height']]: heightSize === 'big',
      })}>
      <div className={classnames('flexbox', 'justify-between')}>
        <Dotdotdot
          tagName="p"
          useNativeClamp
          clamp={2}
          className={classnames(styles.title, {
            [styles.title_small]: textSize === 'small',
            [styles.title_big]: textSize === 'big',
          })}>
          {label}
        </Dotdotdot>
        {showTooltip && (
          <div
            data-for="main"
            data-tip={tooltipText ? tooltipText : t('tooltipDefault')}
            data-iscapture="true"
            className={styles.tooltip}>
            <ErrorIcon fill={'var(--svg-grey)'} />
          </div>
        )}
      </div>
      <Dotdotdot
        clamp={2}
        useNativeClamp
        tagName="p"
        className={classnames(styles.content, {
          [styles.content_small]: textSize === 'small',
          [styles.content_big]: textSize === 'big',
        })}>
        {description}
      </Dotdotdot>
      <div className={classnames(styles.date_container)}>
        <span className={classnames(styles.date)}>{isUpdated ? t('updated') : t('created')}&nbsp;</span>
        <span className={classnames(styles.date)}>{formattedDate}</span>
      </div>
      <div
        className={classnames(styles.footer, {
          [styles['footer__hide-text']]: hideLinkText,
        })}>
        <RecommendationScale score={score} />

        <div
          className={classnames(styles['link-more'], {
            [styles['link-more_hide']]: hideLinkText,
          })}>
          <Link
            to={{
              pathname: isExpert
                ? ExpertRoutes.PATIENT_RECOMMENDATION(patientId, thirdTypeCardId)
                : PatientRoutes.RECOMMENDATION(thirdTypeCardId),
              state: { score, missingBiomarkers, type: 'third' },
            }}
            className={styles['link-more__link']}>
            <p
              className={classnames(styles['link-more__text'], {
                [styles['link-more__text_hide']]: hideLinkText,
              })}>
              {linkMoreText ? linkMoreText : t('learnMore')}
            </p>
            <HorizontalArrowIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};
