import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import styled from './styles.module.scss';

interface EmptyTabsContentProps {
  text: string;
  icon: ReactNode;
  className?: string;
}

const EmptyTabsContent: FC<EmptyTabsContentProps> = ({ text, icon, className }) => {
  return (
    <div className={classNames(styled['empty-block'], 'flexbox', className)}>
      <div className={classNames(styled['empty-block__content'], 'flexbox')}>
        <div className={styled['empty-block__content-icon']}>{icon}</div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default EmptyTabsContent;
