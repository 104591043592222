import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgSearchIcon(props: React.SVGProps<SVGSVGElement>) {
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);
  return (
    <svg ref={ref} width={128} height={132} fill={props.fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M33.522 78.71c0-10.992 4.284-21.34 12.056-29.12 6.759-6.754 15.463-10.877 24.858-11.828C63.189 24.795 54.5 11.899 48.616 3.53a8.315 8.315 0 00-13.596-.004C23.18 20.362.029 55.496.029 72.68c0 23.077 18.715 41.797 41.797 41.797 3.575 0 7.048-.447 10.359-1.296a41.185 41.185 0 01-6.601-5.359c-7.781-7.789-12.065-18.124-12.06-29.112zm-6.377 17.619a5.784 5.784 0 01-4.441 2.069 5.737 5.737 0 01-3.737-1.366C2.494 83.159 8.173 62.656 8.425 61.787c.89-3.08 4.11-4.867 7.19-3.965a5.799 5.799 0 013.968 7.156c-.178.666-3.737 14.245 6.862 23.172 2.446 2.078 2.765 5.74.7 8.178z"
        fill={props.fill}
      />
      <path
        d="M72.695 92.678c-9.051-1.64-11.323-10.38-11.418-10.782a3.488 3.488 0 00-4.189-2.586 3.501 3.501 0 00-2.603 4.196c.128.56 3.332 13.571 16.969 16.046a3.489 3.489 0 004.052-2.802 3.5 3.5 0 00-2.81-4.072z"
        fill={props.fill}
      />
      <path
        d="M126.345 115.578l-13.318-13.323a5.234 5.234 0 00-7.396 0l-3.617-3.617c9.651-13.21 8.521-31.914-3.41-43.854-6.387-6.39-14.888-9.904-23.918-9.904-9.04 0-17.528 3.514-23.914 9.904-6.394 6.394-9.908 14.887-9.908 23.926-.008 9.035 3.514 17.527 9.908 23.913 6.382 6.386 14.875 9.908 23.914 9.908 7.267 0 14.183-2.272 19.932-6.489l3.609 3.621a5.233 5.233 0 000 7.396l13.318 13.31a5.575 5.575 0 007.884 0l6.908-6.911a5.572 5.572 0 00.008-7.88zM91.199 95.232a23.208 23.208 0 01-16.513 6.837 23.227 23.227 0 01-16.518-6.837c-4.407-4.404-6.841-10.28-6.841-16.522 0-3.944.977-7.752 2.814-11.125a23.024 23.024 0 014.027-5.384c.232-.236.472-.46.716-.691a23.223 23.223 0 0115.802-6.155c6.237 0 12.101 2.438 16.513 6.846.857.852 1.606 1.771 2.297 2.71 6.701 9.135 5.956 22.068-2.297 30.32z"
        fill={props.fill}
      />
      <path d="M58.611 75.672a3.419 3.419 0 100-6.837 3.419 3.419 0 000 6.837z" fill={props.fill} />
    </svg>
  );
}

export default SvgSearchIcon;
