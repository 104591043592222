import { unwrapResult } from '@reduxjs/toolkit';
import { BaseModal } from 'components';
import { applyPromocode } from 'features/promocodes/thunks';
import { useClientSize } from 'hooks';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault, FormError, FormLabel, InputDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type CancelSubscriptionModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  openSuccessModal: () => void;
};

interface FormValues {
  promoCode: string;
}

export const EnterPromocodeModal: FC<CancelSubscriptionModalProps> = ({
  isModalOpened,
  onCloseModal,
  openSuccessModal,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    watch,
  } = useForm<FormValues>({ defaultValues: { promoCode: '' } });

  const promoCodeWatch = watch('promoCode');

  const onSubmit = async ({ promoCode }: FormValues) => {
    await dispatch(applyPromocode({ name: promoCode }))
      .then(unwrapResult)
      .then(() => {
        notify('success', t('promoSuccess'));
        onCloseModal();
        openSuccessModal();
      })
      .catch((e) => setError('promoCode', { message: e.message }));
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <BaseModal
      title={t('activatePromocode') as string}
      isVisible={isModalOpened}
      customStyles={{ padding: isMobile ? '60px 24px 24px 24px' : '60px 100px' }}
      onClose={() => {
        reset({ promoCode: '' });
        onCloseModal();
      }}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['promocode__form']}>
        <FormLabel text={t('inputPromoLabel')} />
        <Controller
          name="promoCode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputDefault
              onKeyDown={(event) => {
                event.keyCode == 32 && event.preventDefault();
              }}
              onChange={onChange}
              onPaste={(event) => {
                event.preventDefault();
                onChange(event.clipboardData.getData('Text')?.trim());
              }}
              value={value}
              error={errors.promoCode ? 'error' : undefined}
              maxLength={20}
            />
          )}
        />
        {errors.promoCode?.message && <FormError type="error" text={errors.promoCode.message} />}
        <ButtonDefault
          isDisabled={promoCodeWatch.length === 0}
          type="submit"
          text={t('activate')}
          containerClassNames={styles['promocode__button']}
        />
      </form>
    </BaseModal>
  );
};
