import * as React from 'react';

function ChatBigIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 51.0638C8.5 28.6753 26.3925 8.5 51.085 8.5C75.225 8.5 93.5 28.2922 93.5 50.9362C93.5 77.1981 72.08 93.5 51 93.5C44.03 93.5 36.295 91.6272 30.09 87.9667C27.9225 86.6472 26.095 85.6683 23.7575 86.4344L15.1725 88.9882C13.005 89.6693 11.05 87.9667 11.6875 85.6683L14.535 76.1339C15.0025 74.8145 14.9175 73.4099 14.2375 72.3032C10.5825 65.5781 8.5 58.2146 8.5 51.0638ZM45.475 51.0638C45.475 54.0859 47.8975 56.512 50.915 56.5546C53.9325 56.5546 56.355 54.0859 56.355 51.1064C56.355 48.0844 53.9325 45.6582 50.915 45.6582C47.94 45.6157 45.475 48.0844 45.475 51.0638ZM65.0675 51.1064C65.0675 54.0859 67.49 56.5546 70.5075 56.5546C73.525 56.5546 75.9475 54.0859 75.9475 51.1064C75.9475 48.0844 73.525 45.6582 70.5075 45.6582C67.49 45.6582 65.0675 48.0844 65.0675 51.1064ZM31.3225 56.5546C28.3475 56.5546 25.8825 54.0859 25.8825 51.1064C25.8825 48.0844 28.305 45.6582 31.3225 45.6582C34.34 45.6582 36.7625 48.0844 36.7625 51.1064C36.7625 54.0859 34.34 56.512 31.3225 56.5546Z"
        fill="#DADADA"
      />
    </svg>
  );
}

export default ChatBigIcon;
