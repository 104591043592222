import * as React from 'react';

function SvgEyeOn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.181 12c.94-5.12 5.427-9 10.82-9 5.391 0 9.877 3.88 10.818 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zm10.82 5A5 5 0 1012 7a5 5 0 000 10zm0-2a3 3 0 110-6 3 3 0 010 6z"
        fill="#1F1E3D"
      />
    </svg>
  );
}

export default SvgEyeOn;
