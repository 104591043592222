import classNames from 'classnames';
import { CSSProperties, FC, MouseEventHandler } from 'react';
import { Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

interface ButtonDefaultProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  text?: string | null;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  variant?: 'primary' | 'secondary' | 'delete' | 'message' | 'square' | 'subscribe-primary' | 'subscribe-secondary';
  isLoading?: boolean;
  customStyles?: CSSProperties;
  containerClassNames?: string;
}

export const ButtonDefault: FC<ButtonDefaultProps> = ({
  type = 'button',
  text,
  onClick = () => void 0,
  isLoading = false,
  isDisabled = false,
  customStyles,
  containerClassNames,
  variant = 'primary',
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      className={classNames('btn', styles.button, styles['button-' + variant], containerClassNames)}
      onClick={onClick}
      disabled={isDisabled}
      style={customStyles}
      {...props}>
      {isLoading ? <Spinner variant={variant} /> : children ? children : text}
    </button>
  );
};
