import { DotsIcon } from 'assets/svg';
import UserTableIcon from 'assets/svg/UserTable';
import classNames from 'classnames';
import { UserProfileMenu } from 'components';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FC, useRef, useState } from 'react';
import { DEFAULT_AVATAR } from 'types/user';

import styles from './styles.module.scss';

type UserProfileCardProps = {
  avatarUrl?: string;
  name?: string;
  isActive?: boolean;
};

export const UserProfileCard: FC<UserProfileCardProps> = ({
  name = 'Unknown',
  isActive = false,
  avatarUrl = DEFAULT_AVATAR,
}) => {
  const ref = useRef(null);
  const [mainMenuIsOpen, setMainMenuIsOpen] = useState(false);
  const handleClick = () => {
    setMainMenuIsOpen(!mainMenuIsOpen);
  };
  const handleClickOutside = () => {
    setMainMenuIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const hasDefaultAvatar = avatarUrl === DEFAULT_AVATAR;

  return (
    <div className={classNames('flexbox', 'justify-end', 'align-center')}>
      {isActive && (
        <>
          <div className={classNames(styles['profile-name'])}>{name}</div>
          {hasDefaultAvatar ? (
            <div
              style={{ background: '#F7F7F7' }}
              className={classNames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
              role="button"
              tabIndex={-1}
              onClick={handleClick}
              onKeyDown={handleClick}
              onTouchEnd={handleClick}>
              <UserTableIcon />
            </div>
          ) : (
            <div
              style={{ background: `center / cover no-repeat url(${avatarUrl})` }}
              className={classNames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
              role="button"
              tabIndex={-1}
              onClick={handleClick}
              onKeyDown={handleClick}
              onTouchEnd={handleClick}
            />
          )}
        </>
      )}
      <div className={classNames('flexbox', styles['main-menu-button'])}>
        <DotsIcon onClick={handleClick} className={styles['main-menu-button__dots']} />
        {mainMenuIsOpen && <UserProfileMenu forwardedRef={ref} isActive={isActive} />}
      </div>
    </div>
  );
};
