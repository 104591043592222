import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertGetPatientsResDto } from 'api/generated';
import { EditIcon, PdfIcon, PlusIcon } from 'assets/svg';
import classNames from 'classnames';
import { CheckUpForm, CheckUpsFormsWrapper } from 'components';
import { BiomarkerRow } from 'components/CheckUpForm/CheckUpForm';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { exportCheckUpsPdfAsync } from 'features/biomarkers/thunks';
import { FormBiomarkerItemField } from 'features/biomarkers/types';
import { selectCheckup, selectCheckupLoadingStatus } from 'features/checkups/selectors';
import { getOneExpertCheckupAsync, getOnePatientCheckupAsync } from 'features/checkups/thunks';
import { selectSetCustomCheckupForPatientStatus } from 'features/expert/selectors';
import { getPatientsAsync } from 'features/expert/thunks';
import { useClientSize, useToggle } from 'hooks';
import { LayoutWithBackOptions } from 'layouts';
import { FormBiomarkerItem, SearchBiomarkerInput } from 'pages/CreateCustomCheckUp/components';
import { FC, useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppSelector } from 'store/reducers';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault, IconButton, Spinner } from 'UIcomponents';

import { SetCustomEditedCheckupModal, TableHeader } from './components';
import styles from './styles.module.scss';

export type FormValues = {
  biomarkers: FormBiomarkerItemField[];
  analysisName: string;
  [key: string]: any;
};

export type PatientDropdownProps = {
  label: string;
  value: string;
};

export const CheckUp: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const id = pathname.split('/')[2];
  const { isOpened: isEditModeCheckUp, onToggle: toggleEditModeCheckup } = useToggle();

  const role = useAppSelector(selectRole);
  const checkup = useAppSelector(selectCheckup);
  const isCheckupLoading = useAppSelector(selectCheckupLoadingStatus) === LoadingStatus.pending;
  const allBiomarkers = useAppSelector(selectBiomarkers);
  const isSetCustomCheckupLoading = useAppSelector(selectSetCustomCheckupForPatientStatus) === LoadingStatus.pending;

  const isExpert = role === RolesEnum.EXPERT;
  const checkupName = checkup.name;

  const [isSearchInputVisible, setSearchInputVisible] = useState(false);
  const [patients, setPatients] = useState<Array<PatientDropdownProps>>();
  const {
    isOpened: isOpenSetPatientCheckup,
    onOpenToggle: openPatientCheckupModal,
    onToggle: toggleSetPatientCheckup,
  } = useToggle();

  const isPatientCheckupModal = isOpenSetPatientCheckup && !!patients;

  const biomarkers = checkup?.biomarkers?.map((biomarker) => ({
    name: biomarker.label,
    intName: biomarker.name,
    value: biomarker.lowerNormMale,
    unit: biomarker.unit?.name,
  })) as BiomarkerRow[];

  const biomarkerNames = checkup?.biomarkers?.map((biomarker) => biomarker.name);

  const handleExportInPdfClick = () => {
    const pdfFilename = checkup?.name.replace(/[.]/g, '․') || 'Чекап';
    dispatch(
      exportCheckUpsPdfAsync({
        biomarkerNames: [biomarkerNames] as unknown as string[][],
        label: pdfFilename,
        pdfFilename,
      }),
    )
      .then(unwrapResult)
      .catch((error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!checkup) {
      notify('error', ' Чекап не найден');
      history.push(CommonRoutes.CHECK_UPS);
    }
    if (isExpert) {
      dispatch(getOneExpertCheckupAsync(id)).catch((error) => {
        console.warn({ error });
      });
    } else {
      dispatch(getOnePatientCheckupAsync(id)).catch((error) => {
        console.warn({ error });
      });
    }
  }, []);

  const { control, reset } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'biomarkers',
    keyName: 'key',
  });

  const hasEmptyCustomCheckup = fields.length === 0;

  const biomarkerIds = fields.map((biomarker) => biomarker.id);

  const handleAssignCustomAnalysis = () => {
    openPatientCheckupModal();
  };

  const onChangeSearchInput = (data: { value: string }) => {
    const newCustomBiomarker = allBiomarkers.find((biomarker) => biomarker.id === data.value);
    if (newCustomBiomarker) {
      append(newCustomBiomarker);
      setSearchInputVisible(false);
    }
  };

  const createPatientsDropdownArray = (patients: ExpertGetPatientsResDto) => {
    const result = patients.data
      .filter((patient) => patient.cooperatedWithExpertAt !== null)
      .map((patient) => {
        return { label: patient.name, value: patient.id };
      });
    setPatients(result);
  };

  const getPatients = async () => {
    await dispatch(
      getPatientsAsync({
        page: 1,
        limit: 100000,
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    )
      .then(unwrapResult)
      .then((result) => createPatientsDropdownArray(result));
  };

  useEffect(() => {
    if (isExpert) {
      getPatients();
    }
  }, []);

  const handleEditCheckupClick = () => {
    reset();
    toggleEditModeCheckup();
    const biomarkerIds = checkup?.biomarkers?.map((biomarker) => biomarker.id);

    biomarkerIds.map((biomarkerId) => {
      const newCustomBiomarker = allBiomarkers.find((biomarker) => biomarker.id === biomarkerId);

      if (newCustomBiomarker) {
        append(newCustomBiomarker);
      }
    });
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <LayoutWithBackOptions
      backOptions={{
        text: isCheckupLoading ? 'Чекап' : (checkup?.name as string),
        url: CommonRoutes.CHECK_UPS,
        backFunction: () => history.goBack(),
      }}
      additionalNode={{
        node: (
          <>
            {isExpert ? (
              <>
                {isEditModeCheckUp && (
                  <div className={styles.header__buttons}>
                    <ButtonDefault
                      text="Назначить анализ"
                      variant="primary"
                      type="submit"
                      onClick={handleAssignCustomAnalysis}
                      customStyles={{ minWidth: isMobile ? 'none' : 220, whiteSpace: 'nowrap' }}
                      isLoading={isSetCustomCheckupLoading}
                      isDisabled={hasEmptyCustomCheckup}
                    />
                  </div>
                )}
                <IconButton
                  variant="secondary"
                  icon={<EditIcon style={{ width: '20px' }} />}
                  onClick={handleEditCheckupClick}
                  containerClassNames={styles.edit_button}
                />
              </>
            ) : (
              <IconButton
                variant="secondary"
                icon={<PdfIcon />}
                onClick={handleExportInPdfClick}
                containerClassNames="flexbox-inline justify-center align-center"
                isLoading={isCheckupLoading}
              />
            )}
          </>
        ),
        position: 'above-title',
      }}>
      <CheckUpsFormsWrapper>
        {isCheckupLoading ? (
          <div className={classNames('flexbox', 'align-center', 'justify-center')} style={{ height: '100vh' }}>
            <Spinner variant="primary" radius={30} color="var(--background-primary)" />
          </div>
        ) : (
          <div>
            {isEditModeCheckUp ? (
              <div className={styles.container}>
                <form>
                  <Container fluid className={styles.biomarkers__container}>
                    <TableHeader />
                    {fields.map((field, index) => (
                      <FormBiomarkerItem
                        key={field.key}
                        index={index}
                        control={control}
                        field={field}
                        remove={remove}
                      />
                    ))}

                    {isSearchInputVisible && (
                      <SearchBiomarkerInput
                        biomarkers={allBiomarkers.filter((biomarker) => {
                          const field = fields.find((bioField) => biomarker.id === bioField.id);
                          return biomarker.id !== field?.id;
                        })}
                        onChange={onChangeSearchInput}
                      />
                    )}
                  </Container>

                  <button type="button" onClick={() => setSearchInputVisible(true)} className={styles.add__biomarker}>
                    <div className={classNames(styles.add__biomarker__icon)}>
                      <PlusIcon fill={'var(--background-secondary-dark)'} />
                    </div>
                    <div className={classNames(styles.add__biomarker__text)}>{t('addNewBiomarker')}</div>
                  </button>
                </form>
              </div>
            ) : (
              <>{!!checkup.biomarkers && <CheckUpForm description={checkup.description} biomarkers={[biomarkers]} />}</>
            )}
            {isPatientCheckupModal && (
              <SetCustomEditedCheckupModal
                isModalOpened={isOpenSetPatientCheckup}
                onCloseModal={toggleSetPatientCheckup}
                patientsDropdown={patients}
                biomarkerIds={biomarkerIds}
                checkupName={checkupName}
              />
            )}
          </div>
        )}
      </CheckUpsFormsWrapper>
    </LayoutWithBackOptions>
  );
};
