import { unwrapResult } from '@reduxjs/toolkit';
import {
  CommonInfo,
  CommonInfoFamilyStatusEnum,
  Cycle,
  DiseasesCheck,
  HealthChecks,
  HealthChecksCardiopalmusEnum,
  HealthQuestions,
  LevelLevelEnum,
  PatientCreateAnamnesisDto,
  PhysicalActivityLevelEnum,
  StressAmountLevelEnum,
} from 'api/generated';
import classnames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { selectMutationAnamnesisStatus, selectPatientData } from 'features/patient/selectors';
import { createAnamnesAsync, updateAnamnesAsync } from 'features/patient/thunks';
import { useAlertBeforeUnload } from 'hooks/useAlertBeforeUnload';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { DownloadProfilePicture } from 'pages/ExpertProfile/components/Tabs/ExpertProfileTab/DownloadProfilePicture';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { AllowedMasks } from 'types/Input';
import { Tooltip } from 'UIcomponents';
import {
  ButtonDefault,
  Checkbox,
  CustomCheckbox,
  DropdownDefault,
  FormError,
  FormLabel,
  InputDefault,
  MaskedInput,
  NumberMaskedInput,
  RadioButton,
} from 'UIcomponents';
import { TextArea } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface FormValues {
  commonInfo: CommonInfo;
  healthChecks: HealthChecks;
  cycle: Cycle;
  diseasesCheck: DiseasesCheck;
  healthQuestions: HealthQuestions;
}

type AnamnesisTabProps = {
  id: string;
  anamnes: PatientCreateAnamnesisDto;
};

interface StepsProps {
  commonInfo: boolean;
  healthChecks: boolean;
  cycle?: boolean;
  diseasesCheck: boolean;
  healthQuestions: boolean;
}

export const AnamnesisTab: FC<AnamnesisTabProps> = ({ anamnes, id }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.anamnesisTab' });
  const { t: tTooltips } = useTranslation('translation', { keyPrefix: 'patient.anamnesisTab.tooltips' });

  //Metrics
  useEffect(() => {
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ANAMNESIS_PAGE_VIEWED);
  }, []);

  const TooltipEnum = {
    profHarm: tTooltips('profHarm'),
    healthProblemsResolve: tTooltips('healthProblemsResolve'),
    digestive: tTooltips('digestive'),
    stressLevel: tTooltips('stressLevel'),
    emotionControl: tTooltips('emotionControl'),
    respiratory: tTooltips('respiratory'),
    excretion: tTooltips('excretion'),
    respiratoryFrequency: tTooltips('respiratoryFrequency'),
  };

  const Habits = {
    coffee: t('habbitsCoffee'),
    sugar: t('habbitsSugarCake'),
    smoking: t('habbitsSmoking'),
    alcohol: t('habbitsAlcohol'),
  };

  const familyOptions = [
    { label: t('familyOptionsSingle'), value: CommonInfoFamilyStatusEnum.Single },
    { label: t('familyOptionsMarried'), value: CommonInfoFamilyStatusEnum.Married },
    { label: t('familyOptionsDivorced'), value: CommonInfoFamilyStatusEnum.Divorced },
  ];

  const dispatch = useAppDispatch();
  const loadingStatusCreate = useSelector(selectMutationAnamnesisStatus);
  const patient = useSelector(selectPatientData);
  const isLoading = loadingStatusCreate === LoadingStatus.pending;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: anamnes,
  });

  const leaveCommentWatch = useWatch({ name: 'cycle.leaveComment', control });

  useEffect(() => {
    if (!isEmpty(anamnes)) trigger();
  }, [isDirty]);

  useAlertBeforeUnload(isDirty);

  const onSubmit = (data: FormValues) => {
    const newData = structuredClone(data);

    newData.commonInfo.hasChildren = String(data.commonInfo.hasChildren) === 'true';
    newData.commonInfo.hasOccupationalHealth = String(data.commonInfo.hasOccupationalHealth) === 'true';

    newData.diseasesCheck.haveDiet = String(data.diseasesCheck.haveDiet) === 'true';
    newData.diseasesCheck.vitaminsAndDietarySupplements =
      String(data.diseasesCheck.vitaminsAndDietarySupplements) === 'true';
    newData.diseasesCheck.allergy = String(data.diseasesCheck.allergy) === 'true';
    newData.diseasesCheck.haveLivedInPatmosphericPollutionAreas =
      String(data.diseasesCheck.haveLivedInPatmosphericPollutionAreas) === 'true';
    newData.diseasesCheck.useHealthCheckGadgets = String(data.diseasesCheck.useHealthCheckGadgets) === 'true';

    if (newData?.cycle?.isRegular) {
      newData.cycle.isRegular =
        newData?.cycle?.isRegular === undefined ? false : String(data.cycle.isRegular) === 'true';
      newData.cycle.PMSSymptoms =
        newData?.cycle?.PMSSymptoms === undefined ? false : String(data.cycle.PMSSymptoms) === 'true';
    }

    if (Object.keys(anamnes).length !== 0) {
      dispatch(
        updateAnamnesAsync({
          anamnesisId: id,
          patientCreateAnamnesisDto: {
            commonInfo: newData.commonInfo,
            cycle: newData.cycle,
            diseasesCheck: newData.diseasesCheck,
            healthChecks: data.healthChecks,
            healthQuestions: data.healthQuestions,
          },
        }),
      )
        .then(unwrapResult)
        .then(() =>
          reset(
            {
              commonInfo: newData.commonInfo,
              cycle: newData.cycle,
              diseasesCheck: newData.diseasesCheck,
              healthChecks: data.healthChecks,
              healthQuestions: data.healthQuestions,
            },
            { keepTouched: false, keepValues: false, keepDirty: false },
          ),
        )
        .then(() => notify('success', t('notifySuccess')))
        .catch(() => notify('error', t('notifySuccess')));
    } else {
      dispatch(
        createAnamnesAsync({
          commonInfo: newData.commonInfo,
          cycle: newData.cycle,
          diseasesCheck: newData.diseasesCheck,
          healthChecks: data.healthChecks,
          healthQuestions: data.healthQuestions,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          reset(
            {
              commonInfo: newData.commonInfo,
              cycle: newData.cycle,
              diseasesCheck: newData.diseasesCheck,
              healthChecks: data.healthChecks,
              healthQuestions: data.healthQuestions,
            },
            { keepTouched: false, keepDirty: false, keepIsValid: false },
          );
        })
        .then(() => notify('success', t('notifySuccess')))
        .catch(() => notify('error', t('notifySuccess')));
    }
  };

  const [filled, setFilled] = useState<StepsProps>({
    ...{
      commonInfo:
        anamnes.commonInfo?.address &&
        anamnes.commonInfo?.familyStatus &&
        (anamnes.commonInfo?.hasChildren === false || anamnes.commonInfo?.hasChildren === true) &&
        (anamnes.commonInfo?.hasOccupationalHealth === false || anamnes.commonInfo?.hasOccupationalHealth === true) &&
        anamnes.commonInfo?.profession
          ? true
          : false,
      healthChecks: anamnes.healthChecks ? true : false,
    },
    ...(patient.gender === 'female' && { cycle: anamnes.cycle ? true : false }),
    ...{
      diseasesCheck: anamnes.diseasesCheck ? true : false,
      healthQuestions: anamnes.healthQuestions ? true : false,
    },
  });

  const count = Object.values(filled).filter((value) => {
    if (value) {
      return true;
    }
  });

  const values = getValues();

  const handleBlur = (name: string) => {
    const field = name?.split('.')[0] as keyof FormValues;
    let length = Object.values(values[field])?.length;
    if (field === 'cycle' && !values[field].leaveComment) {
      length = length - 1;
      setValue('cycle.leaveComment', false);
      setValue('cycle.commentText', '');
    }

    const isFilled =
      Object.values(values[field]).filter((value) => {
        if (value || value === false) {
          return true;
        } else {
          return false;
        }
      }).length === length;

    if (isFilled) {
      setFilled((prevState) => ({ ...prevState, [field]: true }));
    } else {
      setFilled((prevState) => ({ ...prevState, [field]: false }));
    }
  };

  const blocksTextMapping: Record<keyof StepsProps, string> = {
    commonInfo: t('commonInfo'),
    healthChecks: t('healthChecks'),
    cycle: t('cycle'),
    diseasesCheck: t('diseasesCheck'),
    healthQuestions: t('healthQuestions'),
  };

  return (
    <>
      <div className={styles.anamnesis}>
        <div className={styles.anamnesis__header}>
          <h4>{t('analisisData')}</h4>
          <div className={styles.progress}>
            <p className={classnames(styles.progress__total, 'subhead', 'subhead_medium')}>
              {t('progress')}
              {count.length}/{Object.values(filled).length}
            </p>
            <div className={styles['progress__filled-indicators']}>
              {Object.values(filled).map((value, id) => {
                const anchorHref = Object.keys(filled)[id] as keyof StepsProps;
                const isFilled = value;

                return (
                  <a
                    href={`#${anchorHref}`}
                    key={id}
                    data-for="main"
                    data-tip={blocksTextMapping[anchorHref]}
                    data-iscapture="true"
                    className={classnames(styles['progress__filled-indicator'], {
                      [styles['progress__filled-indicator_filled']]: isFilled,
                    })}>
                    <></>
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <form className={styles['anamnesis-form']} onSubmit={handleSubmit(onSubmit)}>
          {/* commonInfo */}
          <div id="commonInfo" className={classnames(styles['common-info'], styles['anamnesis-form__block'])}>
            <DownloadProfilePicture role={RolesEnum.PATIENT} />
            <div className={styles['common-info__fields']}>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('labelFullName')} />
                <InputDefault value={patient.name} disabled={true} placeholder={t('fullname') as string} />
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('birthday')} />
                <InputDefault
                  value={moment(patient.birthday).format('DD.MM.YYYY')}
                  disabled={true}
                  placeholder={t('fullname') as string}
                />
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('address')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  key="commonInfo.address"
                  name="commonInfo.address"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <InputDefault
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      onChange={onChange}
                      value={value}
                      error={errors?.commonInfo?.address && 'error'}
                      placeholder={t('address') as string}
                    />
                  )}
                />
                {errors.commonInfo?.address?.message && (
                  <FormError type="error" text={errors.commonInfo.address.message} />
                )}
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('phoneNumber')} />
                <Controller
                  key="commonInfo.phoneNumber"
                  name="commonInfo.phoneNumber"
                  control={control}
                  rules={{
                    pattern: {
                      value: patterns.phone,
                      message: t('phonePattern'),
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <MaskedInput
                      mask={AllowedMasks.phone}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      value={value}
                      error={errors?.commonInfo?.phoneNumber && 'error'}
                      placeholder={t('phonePlaceholder') as string}
                    />
                  )}
                />
                {errors.commonInfo?.phoneNumber?.message && (
                  <FormError type="error" text={errors.commonInfo.phoneNumber.message} />
                )}
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('profession')} />
                <Controller
                  key="commonInfo.profession"
                  name="commonInfo.profession"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <InputDefault
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      error={errors?.commonInfo?.profession && 'error'}
                      value={value}
                      placeholder={t('professionPlaceholder') as string}
                    />
                  )}
                />
                {errors.commonInfo?.profession?.message && (
                  <FormError type="error" text={errors.commonInfo.profession.message} />
                )}
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('familyStatus')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="commonInfo.familyStatus"
                  key="commonInfo.familyStatus"
                  control={control}
                  render={({ field: { onChange, name, onBlur, value } }) => {
                    const index = familyOptions.findIndex((elem) => {
                      if (value) {
                        if (elem.value === value) {
                          return true;
                        }
                      } else if (anamnes) {
                        if (elem.value === anamnes.commonInfo?.familyStatus) {
                          return true;
                        }
                      }
                    });
                    return (
                      <DropdownDefault
                        onChange={(value) => {
                          onChange(value.value);
                        }}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        error={errors?.commonInfo?.familyStatus && 'error'}
                        isSearchable={false}
                        selected={familyOptions[index]}
                        options={familyOptions}
                      />
                    );
                  }}
                />
                {errors.commonInfo?.familyStatus?.message && (
                  <FormError type="error" text={errors.commonInfo.familyStatus.message} />
                )}
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('profHarm')} toolTipText={TooltipEnum.profHarm} />
                <Controller
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="commonInfo.hasOccupationalHealth"
                  key="commonInfo.hasOccupationalHealth"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        labelText={t('yes') as string}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        checked={String(value) === 'true'}
                        value="true"
                        name="commonInfo.hasOccupationalHealth1"
                      />
                      <RadioButton
                        labelText={t('no') as string}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        checked={String(value) === 'false'}
                        value="false"
                        name="commonInfo.hasOccupationalHealth2"
                      />
                    </div>
                  )}
                />
                {errors.commonInfo?.hasOccupationalHealth?.message && (
                  <FormError type="error" text={errors.commonInfo.hasOccupationalHealth.message} />
                )}
              </div>
              <div className={styles['common-info__field']}>
                <FormLabel text={t('hasChildren')} />
                <Controller
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="commonInfo.hasChildren"
                  key="commonInfo.hasChildren"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        labelText={t('yes') as string}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={'true'}
                        checked={String(value) === 'true'}
                        name="commonInfo.hasChildren1"
                      />
                      <RadioButton
                        labelText={t('no') as string}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={'false'}
                        checked={String(value) === 'false'}
                        name="commonInfo.hasChildren2"
                      />
                    </div>
                  )}
                />
                {errors.commonInfo?.hasChildren?.message && (
                  <FormError type="error" text={errors.commonInfo.hasChildren.message} />
                )}
              </div>
            </div>
          </div>

          {/* healthChecks */}
          <div id="healthChecks" className={classnames(styles['health-checks'], styles['anamnesis-form__block'])}>
            <div className={styles['health-checks__inputs']}>
              <div className={styles['health-checks__input-field']}>
                <FormLabel text={t('bloodPressureLabel')} />
                <Controller
                  name="healthChecks.bloodPressure"
                  key="healthChecks.bloodPressure"
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (Number(value) === 0) {
                        return t('nulluble') as string;
                      }
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <NumberMaskedInput
                      placeholder={'130/80'}
                      error={errors?.healthChecks?.bloodPressure && 'error'}
                      format={AllowedMasks.bloodPressure}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      value={value}
                    />
                  )}
                />
                {errors.healthChecks?.bloodPressure?.message && (
                  <FormError type="error" text={errors.healthChecks?.bloodPressure.message} />
                )}
              </div>
              <div className={styles['health-checks__input-field']}>
                <FormLabel text={t('pulse')} />
                <Controller
                  name="healthChecks.pulse"
                  key="healthChecks.pulse"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (Number(value) === 0) {
                        return t('nulluble') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <NumberMaskedInput
                      placeholder={'75'}
                      suffix={' у/м'}
                      maxLength={7}
                      error={errors?.healthChecks?.pulse && 'error'}
                      allowNegative={false}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      value={value}
                    />
                  )}
                />
                {errors.healthChecks?.pulse?.message && (
                  <FormError type="error" text={errors.healthChecks?.pulse.message} />
                )}
              </div>
              <div className={styles['health-checks__input-field']}>
                <FormLabel text={t('labelHeight')} />
                <Controller
                  name="healthChecks.height"
                  key="healthChecks.height"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (Number(value) === 0) {
                        return t('nulluble') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => {
                    const suffix = t('suffix');
                    const maxLength = 3 + suffix.length;
                    return (
                      <NumberMaskedInput
                        maxLength={maxLength}
                        suffix={suffix}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={value}
                        error={errors?.healthChecks?.height && 'error'}
                        placeholder="165"
                        allowNegative={false}
                      />
                    );
                  }}
                />
                {errors.healthChecks?.height?.message && (
                  <FormError type="error" text={errors.healthChecks?.height.message} />
                )}
              </div>
              <div className={styles['health-checks__input-field']}>
                <FormLabel text={t('weight')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (Number(value) === 0) {
                        return t('nulluble') as string;
                      }
                    },
                  }}
                  name="healthChecks.weight"
                  key="healthChecks.weight"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => {
                    const suffix = t('suffixWeight');
                    const maxLength = 3 + suffix.length;
                    return (
                      <NumberMaskedInput
                        maxLength={maxLength}
                        suffix={suffix}
                        error={errors?.healthChecks?.height && 'error'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={value}
                        placeholder="75"
                        allowNegative={false}
                      />
                    );
                  }}
                />
                {errors.healthChecks?.weight?.message && (
                  <FormError type="error" text={errors.healthChecks?.weight.message} />
                )}
              </div>
              <div className={styles['health-checks__input-field']}>
                <FormLabel text={t('waist')} />
                <Controller
                  name="healthChecks.waist"
                  key="healthChecks.waist"
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (Number(value) === 0) {
                        return t('nulluble') as string;
                      }
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <NumberMaskedInput
                      placeholder={'100'}
                      suffix={t('suffix') as string}
                      maxLength={6}
                      error={errors?.healthChecks?.waist && 'error'}
                      allowNegative={false}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      value={value}
                    />
                  )}
                />
                {errors.healthChecks?.waist?.message && (
                  <FormError type="error" text={errors.healthChecks?.waist.message} />
                )}
              </div>
            </div>
            <div className={styles['health-checks__text-ares']}>
              <div className={styles['health-checks__field']}>
                <FormLabel text={t('shortTasksDescription')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="healthChecks.shortTasksDescription"
                  key="healthChecks.shortTasksDescription"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      value={value}
                      error={errors?.healthChecks?.shortTasksDescription && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('shortTasksDescriptionPlaceholder') as string}
                    />
                  )}
                />
                {errors.healthChecks?.shortTasksDescription?.message && (
                  <FormError type="error" text={errors.healthChecks?.shortTasksDescription.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel text={t('howResolvedTroubles')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="healthChecks.howResolvedTroubles"
                  key="healthChecks.howResolvedTroubles"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      error={errors?.healthChecks?.howResolvedTroubles && 'error'}
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('howResolvedTroublesPlaceholder') as string}
                    />
                  )}
                />
                {errors.healthChecks?.howResolvedTroubles?.message && (
                  <FormError type="error" text={errors.healthChecks?.howResolvedTroubles.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel text={t('digestionLevel')} toolTipText={TooltipEnum.digestive} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="healthChecks.digestionLevel.level"
                  key="healthChecks.digestionLevel.level"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="good"
                        name="healthChecks.digestionLevel.level1"
                        labelText={t('digestionLevelGood') as string}
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="medium"
                        name="healthChecks.digestionLevel.level2"
                        labelText={t('digestionLevelMedium') as string}
                      />
                      <RadioButton
                        checked={value === 'bad'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="bad"
                        name="healthChecks.digestionLevel.level3"
                        labelText={t('digestionLevelBad') as string}
                      />
                      <RadioButton
                        checked={value === 'other'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="other"
                        name="healthChecks.digestionLevel.level4"
                        labelText={t('digestionLevelOther') as string}
                      />
                      {value === 'other' && (
                        <>
                          <Controller
                            name="healthChecks.digestionLevel.commentText"
                            key="healthChecks.digestionLevel.commentText"
                            control={control}
                            render={({ field: { onChange, value, name, onBlur } }) => (
                              <TextArea
                                value={value || ''}
                                onChange={onChange}
                                onBlur={() => {
                                  onBlur();
                                  handleBlur(name);
                                }}
                                error={errors?.healthChecks?.digestionLevel?.commentText && 'error'}
                                placeholder={t('commentText') as string}
                                minRows={4}
                                customClassName={styles['optional-textarea']}
                              />
                            )}
                          />
                          {errors.healthChecks?.digestionLevel?.commentText?.message && (
                            <FormError type="error" text={errors.healthChecks.digestionLevel.commentText.message} />
                          )}
                        </>
                      )}
                    </div>
                  )}
                />
                {errors.healthChecks?.digestionLevel?.level?.message && (
                  <FormError type="error" text={errors.healthChecks.digestionLevel.level.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel id={'anamnesis'} text={t('stressAmount')} toolTipText={TooltipEnum.stressLevel} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="healthChecks.stressAmount.level"
                  key="healthChecks.stressAmount.level"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={String(value) === StressAmountLevelEnum.High}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.stressAmount.level1"
                        labelText={t('high') as string}
                        value={StressAmountLevelEnum.High}
                      />
                      <RadioButton
                        checked={String(value) === StressAmountLevelEnum.Medium}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.stressAmount.level2"
                        labelText={t('medium') as string}
                        value={StressAmountLevelEnum.Medium}
                      />
                      <RadioButton
                        checked={String(value) === StressAmountLevelEnum.Low}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.stressAmount.level3"
                        labelText={t('low') as string}
                        value={StressAmountLevelEnum.Low}
                      />
                      <RadioButton
                        checked={String(value) === StressAmountLevelEnum.Other}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.stressAmount.level4"
                        labelText={t('other') as string}
                        value={StressAmountLevelEnum.Other}
                      />
                      {String(value) === StressAmountLevelEnum.Other && (
                        <>
                          <Controller
                            rules={{ required: t('required') as string }}
                            name="healthChecks.stressAmount.commentText"
                            key="healthChecks.stressAmount.commentText"
                            control={control}
                            render={({ field: { onChange, value, name, onBlur } }) => (
                              <TextArea
                                value={value || ''}
                                onChange={onChange}
                                onBlur={() => {
                                  onBlur();
                                  handleBlur(name);
                                }}
                                error={errors?.healthChecks?.stressAmount?.commentText && 'error'}
                                placeholder={t('commentText') as string}
                                minRows={4}
                                customClassName={styles['optional-textarea']}
                              />
                            )}
                          />
                          {errors.healthChecks?.stressAmount?.commentText?.message && (
                            <FormError type="error" text={errors.healthChecks.stressAmount.commentText.message} />
                          )}
                        </>
                      )}
                    </div>
                  )}
                />
                {errors.healthChecks?.stressAmount?.level?.message && (
                  <FormError type="error" text={errors.healthChecks.stressAmount.level.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel text={t('breathSystem')} toolTipText={TooltipEnum.respiratory} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="healthChecks.breathSystem.level"
                  key="healthChecks.breathSystem.level"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={String(value) === LevelLevelEnum.Good}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={LevelLevelEnum.Good}
                        name="healthChecks.breathSystem.level1"
                        labelText={t('breathSystemGood') as string}
                      />
                      <RadioButton
                        checked={String(value) === LevelLevelEnum.Medium}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={LevelLevelEnum.Medium}
                        name="healthChecks.breathSystem.level2"
                        labelText={t('breathSystemMedium') as string}
                      />
                      <RadioButton
                        checked={String(value) === LevelLevelEnum.Bad}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={LevelLevelEnum.Bad}
                        name="healthChecks.breathSystem.level3"
                        labelText={t('breathSystemBad') as string}
                      />
                      <RadioButton
                        checked={String(value) === LevelLevelEnum.Other}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={LevelLevelEnum.Other}
                        name="healthChecks.breathSystem.level4"
                        labelText={t('breathSystemOther') as string}
                      />
                      {String(value) === LevelLevelEnum.Other && (
                        <>
                          <Controller
                            name="healthChecks.breathSystem.commentText"
                            key="healthChecks.breathSystem.commentText"
                            control={control}
                            rules={{ required: t('required') as string }}
                            render={({ field: { onChange, value, name, onBlur } }) => (
                              <TextArea
                                value={value || ''}
                                onChange={onChange}
                                onBlur={() => {
                                  onBlur();
                                  handleBlur(name);
                                }}
                                placeholder={t('commentText') as string}
                                minRows={4}
                                error={errors?.healthChecks?.breathSystem?.commentText && 'error'}
                                customClassName={styles['optional-textarea']}
                              />
                            )}
                          />
                          {errors.healthChecks?.breathSystem?.commentText?.message && (
                            <FormError type="error" text={errors.healthChecks.breathSystem.commentText.message} /> //check
                          )}
                        </>
                      )}
                    </div>
                  )}
                />
                {errors.healthChecks?.breathSystem?.level?.message && (
                  <FormError type="error" text={errors.healthChecks.breathSystem.level.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel
                  text={t('emotionsControlDifficulty')}
                  toolTipText={TooltipEnum.emotionControl}
                  customTextStyles={{ maxWidth: '21em' }}
                  customContainerStyles={{ alignItems: 'flex-start' }}
                />
                <Controller
                  name="healthChecks.emotionsControlDifficulty"
                  key="healthChecks.emotionsControlDifficulty"
                  control={control}
                  rules={{ required: t('required') as string }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={value === 'difficult'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="difficult"
                        name="healthChecks.emotionsControlDifficulty1"
                        labelText={t('emotionsControlDifficultyDifficult') as string}
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="medium"
                        name="healthChecks.emotionsControlDifficulty2"
                        labelText={t('emotionsControlDifficultyMedium') as string}
                      />
                      <RadioButton
                        checked={value === 'easy'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="easy"
                        name="healthChecks.emotionsControlDifficulty3"
                        labelText={t('emotionsControlDifficultyEasy') as string}
                      />
                    </div>
                  )}
                />
                {errors.healthChecks?.emotionsControlDifficulty?.message && (
                  <FormError type="error" text={errors.healthChecks.emotionsControlDifficulty.message} />
                )}
              </div>
              <div className={styles['health-checks__field']}>
                <FormLabel text={t('cardiopalmus')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="healthChecks.cardiopalmus"
                  key="healthChecks.cardiopalmus"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={String(value) === HealthChecksCardiopalmusEnum.No}
                        value={HealthChecksCardiopalmusEnum.No}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.cardiopalmus1"
                        labelText={t('cardiopalmusNo') as string}
                      />
                      <RadioButton
                        checked={String(value) === HealthChecksCardiopalmusEnum.Sometimes}
                        value={HealthChecksCardiopalmusEnum.Sometimes}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name={HealthChecksCardiopalmusEnum.Sometimes}
                        labelText={t('cardiopalmusSometimes') as string}
                      />
                      <RadioButton
                        checked={String(value) === HealthChecksCardiopalmusEnum.WithoutLoad}
                        value={HealthChecksCardiopalmusEnum.WithoutLoad}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name={HealthChecksCardiopalmusEnum.WithoutLoad}
                        labelText={t('cardiopalmusWithoutLoad') as string}
                      />
                    </div>
                  )}
                />
                {errors.healthChecks?.cardiopalmus?.message && (
                  <FormError type="error" text={errors.healthChecks.cardiopalmus.message} />
                )}
              </div>

              <div className={styles['health-checks__field']}>
                <FormLabel text={t('excretorySystem')} />
                <Controller
                  name="healthChecks.excretorySystem"
                  key="healthChecks.excretorySystem"
                  control={control}
                  rules={{ required: t('required') as string }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        value="good"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.excretorySystem1"
                        labelText={t('excretorySystemGood') as string}
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        value="medium"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.excretorySystem2"
                        labelText={t('excretorySystemMedium') as string}
                      />
                      <RadioButton
                        value="bad"
                        checked={value === 'bad'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.excretorySystem3"
                        labelText={t('excretorySystemBad') as string}
                      />
                    </div>
                  )}
                />
                {errors.healthChecks?.excretorySystem?.message && (
                  <FormError type="error" text={errors.healthChecks.excretorySystem.message} />
                )}
              </div>
            </div>
          </div>

          {/* cycle */}
          {patient.gender === 'female' && (
            <div id="cycle" className={classnames(styles.cycle, styles['anamnesis-form__block'])}>
              <div className={classnames(styles.cycle__field, styles.cycle__field_duration)}>
                <FormLabel text={t('duration')} />
                <Controller
                  name="cycle.duration"
                  key="cycle.duration"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <NumberMaskedInput
                      maxLength={2}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      value={Number(value)}
                      error={errors.cycle?.duration && 'error'}
                    />
                  )}
                />
                {errors.cycle?.duration?.message && <FormError type="error" text={errors.cycle.duration.message} />}
                <Controller
                  name="cycle.leaveComment"
                  key="cycle.leaveComment"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      labelText={t('commentText') as string}
                      customStyles={{ marginTop: 14 }}
                    />
                  )}
                />
                {leaveCommentWatch && (
                  <Controller
                    name="cycle.commentText"
                    key="cycle.commentText"
                    control={control}
                    render={({ field: { onChange, value, name, onBlur } }) => (
                      <TextArea
                        value={value || ''}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        placeholder={t('commentText') as string}
                        minRows={4}
                        customStyles={{ marginTop: 14 }}
                      />
                    )}
                  />
                )}
              </div>
              <div className={styles.cycle__field}>
                <FormLabel text={t('isRegular')} />
                <Controller
                  name="cycle.isRegular"
                  key="cycle.isRegular"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        labelText={t('yes') as string}
                        value="true"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        checked={String(value) === 'true'}
                        name="cycle.isRegular1"
                      />
                      <RadioButton
                        labelText={t('no') as string}
                        value="false"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        checked={String(value) === 'false'}
                        name="cycle.isRegular2"
                      />
                    </div>
                  )}
                />
              </div>

              <div className={styles.cycle__field}>
                <FormLabel text={t('PMSSymptoms')} />
                <Controller
                  name="cycle.PMSSymptoms"
                  key="cycle.PMSSymptoms"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        labelText={t('PMSSymptomsTrue') as string}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="true"
                        checked={String(value) === 'true'}
                        name="cycle.PMSSymptoms1"
                      />
                      <RadioButton
                        labelText={t('PMSSymptomsFalse') as string}
                        value="false"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        checked={String(value) === 'false'}
                        name="cycle.PMSSymptoms2"
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}

          {/* diseasesCheck */}
          <div id="diseasesCheck" className={classnames(styles['diseases-checks'], styles['anamnesis-form__block'])}>
            <div className={styles['diseases-checks__fields']}>
              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('productsPortability')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="diseasesCheck.productsPortability"
                  key="diseasesCheck.productsPortability"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        value="good"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.productsPortability1"
                        labelText={t('productsPortabilityGood') as string}
                      />
                      <RadioButton
                        checked={value === 'bad'}
                        value="bad"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.productsPortability2"
                        labelText={t('productsPortabilityBad') as string}
                      />
                      <RadioButton
                        checked={value === 'not-paid-attention'}
                        value="not-paid-attention"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.productsPortability3"
                        labelText={t('productsPortabilityBadPotPaidAttention') as string}
                        customStyles={{ gridColumn: 'span 3' }}
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.productsPortability?.message && (
                  <FormError type="error" text={errors.diseasesCheck.productsPortability.message} />
                )}
              </div>
              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('respiratoryDiseaseIncidence')} toolTipText={TooltipEnum.respiratoryFrequency} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="diseasesCheck.respiratoryDiseaseIncidence"
                  key="diseasesCheck.respiratoryDiseaseIncidence"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        value="good"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.respiratoryDiseaseIncidence1"
                        labelText={t('respiratoryDiseaseIncidenceGood') as string}
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="medium"
                        name="diseasesCheck.respiratoryDiseaseIncidence2"
                        labelText={t('respiratoryDiseaseIncidenceMedium') as string}
                      />
                      <RadioButton
                        checked={value === 'bad'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="bad"
                        name="diseasesCheck.respiratoryDiseaseIncidence3"
                        labelText={t('respiratoryDiseaseIncidenceBad') as string}
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.respiratoryDiseaseIncidence?.message && (
                  <FormError type="error" text={errors.diseasesCheck.respiratoryDiseaseIncidence.message} />
                )}
              </div>
              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('allergy')} />
                <Controller
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="diseasesCheck.allergy"
                  key="diseasesCheck.allergy"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        checked={String(value) === 'true'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="true"
                        name="diseasesCheck.allergy1"
                        labelText={t('yes') as string}
                      />
                      <RadioButton
                        checked={String(value) === 'false'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="false"
                        name="diseasesCheck.allergy2"
                        labelText={t('no') as string}
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.allergy?.message && (
                  <FormError type="error" text={errors.diseasesCheck.allergy.message} />
                )}
              </div>
              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('endocrinologicalProblems')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="diseasesCheck.endocrinologicalProblems"
                  key="diseasesCheck.endocrinologicalProblems"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['radio-group']}>
                      <RadioButton
                        checked={value === 'yes'}
                        value="yes"
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.endocrinologicalProblems1"
                        labelText={t('yes') as string}
                      />
                      <RadioButton
                        checked={value === 'no'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="no"
                        name="healthChecks.endocrinologicalProblems2"
                        labelText={t('no') as string}
                      />
                      <RadioButton
                        checked={value === 'unknown'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="unknown"
                        name="healthChecks.endocrinologicalProblems3"
                        labelText="Не знаю"
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.endocrinologicalProblems?.message && (
                  <FormError type="error" text={errors.diseasesCheck.endocrinologicalProblems.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('relativesDiseases')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="diseasesCheck.relativesDiseases"
                  key="diseasesCheck.relativesDiseases"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      error={errors?.diseasesCheck?.relativesDiseases && 'error'}
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                    />
                  )}
                />
                {errors.diseasesCheck?.relativesDiseases?.message && (
                  <FormError type="error" text={errors.diseasesCheck.relativesDiseases.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('vitaminsAndDietarySupplements')} />
                <Controller
                  name="diseasesCheck.vitaminsAndDietarySupplements"
                  key="diseasesCheck.vitaminsAndDietarySupplements"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        checked={String(value) === 'true'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="true"
                        name="diseasesCheck.vitaminsAndDietarySupplements1"
                        labelText={t('yes') as string}
                      />
                      <RadioButton
                        checked={String(value) === 'false'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="false"
                        name="healthChecks.vitaminsAndDietarySupplements2"
                        labelText={t('no') as string}
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.vitaminsAndDietarySupplements?.message && (
                  <FormError type="error" text={errors.diseasesCheck.vitaminsAndDietarySupplements.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('sleepQuality')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="diseasesCheck.sleepQuality"
                  key="diseasesCheck.sleepQuality"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        value="good"
                        onChange={onChange}
                        name="diseasesCheck.sleepQuality1"
                        labelText={t('sleepQualityGood') as string}
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="medium"
                        name="healthChecks.sleepQuality2"
                        labelText={t('sleepQualityMedium') as string}
                      />
                      <RadioButton
                        checked={value === 'bad'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="bad"
                        name="healthChecks.sleepQualityBad"
                        labelText={t('sleepQualityGood') as string}
                      />
                    </div>
                  )}
                />
                {errors.diseasesCheck?.sleepQuality?.message && (
                  <FormError type="error" text={errors.diseasesCheck.sleepQuality.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('physicalActivityLevel')} />
                <Controller
                  rules={{ required: t('required') as string }}
                  name="diseasesCheck.physicalActivityLevel.level"
                  key="diseasesCheck.physicalActivityLevel.level"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={value === PhysicalActivityLevelEnum.High}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={PhysicalActivityLevelEnum.High}
                        name="diseasesCheck.physicalActivityLevel.level1"
                        labelText={t('physicalActivityLevelHigh') as string}
                      />
                      <RadioButton
                        checked={value === PhysicalActivityLevelEnum.Medium}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={PhysicalActivityLevelEnum.Medium}
                        name="diseasesCheck.physicalActivityLevel.level2"
                        labelText={t('physicalActivityLevelMedium') as string}
                      />
                      <RadioButton
                        checked={value === PhysicalActivityLevelEnum.Low}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value={PhysicalActivityLevelEnum.Low}
                        name="diseasesCheck.physicalActivityLevel.level3"
                        labelText={t('physicalActivityLevelLow') as string}
                      />
                      <RadioButton
                        checked={value === PhysicalActivityLevelEnum.Other}
                        value={PhysicalActivityLevelEnum.Other}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.physicalActivityLevel.level5"
                        labelText={t('physicalActivityLevelOther') as string}
                      />
                      {value === PhysicalActivityLevelEnum.Other && (
                        <Controller
                          name="diseasesCheck.physicalActivityLevel.commentText"
                          key="diseasesCheck.physicalActivityLevel.commentText"
                          control={control}
                          render={({ field: { onChange, value, name, onBlur } }) => (
                            <TextArea
                              value={value || ''}
                              onChange={onChange}
                              onBlur={() => {
                                onBlur();
                                handleBlur(name);
                              }}
                              placeholder={t('commentText') as string}
                              minRows={4}
                              customClassName={styles['optional-textarea__column']}
                            />
                          )}
                        />
                      )}
                    </div>
                  )}
                />
                {errors?.diseasesCheck?.physicalActivityLevel?.level?.message && (
                  <FormError type="error" text={errors.diseasesCheck.physicalActivityLevel.level.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('havingHabitsAndAddictions')} />
                <div className={styles['checkbox-group']}>
                  {Object.entries(Habits).map((c, i) => (
                    <CustomCheckbox
                      key={i}
                      title={c[1]}
                      control={control}
                      customOnBlur={handleBlur}
                      name={`diseasesCheck.havingHabitsAndAddictions.${c[0]}`}
                    />
                  ))}
                </div>
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('lowEnergyTime')} />
                <Controller
                  name="diseasesCheck.lowEnergyTime"
                  key="diseasesCheck.lowEnergyTime"
                  control={control}
                  rules={{ required: t('required') as string }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={value === 'morning'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="morning"
                        name="diseasesCheck.lowEnergyTime1"
                        labelText={t('morning') as string}
                      />
                      <RadioButton
                        checked={value === 'dinner'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        value="dinner"
                        name="healthChecks.lowEnergyTime2"
                        labelText={t('dinner') as string}
                      />
                      <RadioButton
                        checked={value === 'afternoon'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.lowEnergyTime3"
                        labelText={t('afternoon') as string}
                        value="afternoon"
                      />
                      <RadioButton
                        checked={value === 'all-day'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.lowEnergyTime4"
                        labelText={t('allDay') as string}
                        value="all-day"
                      />
                    </div>
                  )}
                />
                {errors?.diseasesCheck?.lowEnergyTime?.message && (
                  <FormError type="error" text={errors.diseasesCheck.lowEnergyTime.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('haveLivedInPatmosphericPollutionAreas')} />
                <Controller
                  name="diseasesCheck.haveLivedInPatmosphericPollutionAreas"
                  key="diseasesCheck.haveLivedInPatmosphericPollutionAreas"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['double-radio-group']}>
                      <RadioButton
                        checked={String(value) === 'true'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.haveLivedInPatmosphericPollutionAreas1"
                        labelText={t('yes') as string}
                        value="true"
                      />
                      <RadioButton
                        checked={String(value) === 'false'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.haveLivedInPatmosphericPollutionAreas2"
                        labelText={t('no') as string}
                        value="false"
                      />
                    </div>
                  )}
                />
                {errors?.diseasesCheck?.haveLivedInPatmosphericPollutionAreas?.message && (
                  <FormError type="error" text={errors.diseasesCheck.haveLivedInPatmosphericPollutionAreas.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('hairNailsSkinCondition')} />
                <Controller
                  name="diseasesCheck.hairNailsSkinCondition"
                  key="diseasesCheck.hairNailsSkinCondition"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      value={value}
                      error={errors?.diseasesCheck?.hairNailsSkinCondition && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('hairNailsSkinConditionPlaceholder') as string}
                    />
                  )}
                />
                {errors?.diseasesCheck?.hairNailsSkinCondition?.message && (
                  <FormError type="error" text={errors.diseasesCheck.hairNailsSkinCondition.message} />
                )}
              </div>

              <div className={styles['diseases-checks__field-group']}>
                <div className={styles['diseases-checks__field']}>
                  <FormLabel text={t('haveDiet')} />
                  <Controller
                    rules={{
                      validate: (value) => {
                        if (value === null || value === undefined) {
                          return t('required') as string;
                        }
                      },
                    }}
                    name="diseasesCheck.haveDiet"
                    key="diseasesCheck.haveDiet"
                    control={control}
                    render={({ field: { onChange, value, name, onBlur } }) => (
                      <div className={styles['double-radio-group']}>
                        <RadioButton
                          checked={String(value) === 'true'}
                          onChange={onChange}
                          onBlur={() => {
                            onBlur();
                            handleBlur(name);
                          }}
                          name={`diseasesCheck.haveDiet${2}`}
                          labelText={t('yes') as string}
                          value="true"
                        />
                        <RadioButton
                          checked={String(value) === 'false'}
                          onChange={onChange}
                          onBlur={() => {
                            onBlur();
                            handleBlur(name);
                          }}
                          name={`diseasesCheck.haveDiet${1}`}
                          labelText={t('no') as string}
                          value="false"
                        />
                      </div>
                    )}
                  />
                  {errors?.diseasesCheck?.haveDiet?.message && (
                    <FormError type="error" text={errors.diseasesCheck.haveDiet.message} />
                  )}
                </div>
                <div className={styles['diseases-checks__field']}>
                  <FormLabel text={t('useHealthCheckGadgets')} />
                  <Controller
                    name="diseasesCheck.useHealthCheckGadgets"
                    key="diseasesCheck.useHealthCheckGadgets"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (value === null || value === undefined) {
                          return t('required') as string;
                        }
                      },
                    }}
                    render={({ field: { onChange, value, name, onBlur } }) => (
                      <div className={styles['double-radio-group']}>
                        <RadioButton
                          checked={String(value) === 'true'}
                          onChange={onChange}
                          onBlur={() => {
                            onBlur();
                            handleBlur(name);
                          }}
                          name="diseasesCheck.useHealthCheckGadgets1"
                          labelText={t('yes') as string}
                          value="true"
                        />
                        <RadioButton
                          checked={String(value) === 'false'}
                          onChange={onChange}
                          onBlur={() => {
                            onBlur();
                            handleBlur(name);
                          }}
                          name="healthChecks.useHealthCheckGadgets2"
                          labelText={t('no') as string}
                          value="false"
                        />
                      </div>
                    )}
                  />
                  {errors?.diseasesCheck?.useHealthCheckGadgets?.message && (
                    <FormError type="error" text={errors.diseasesCheck.useHealthCheckGadgets.message} />
                  )}
                </div>
              </div>

              <div className={styles['diseases-checks__field']}>
                <FormLabel text={t('memoryCondition')} />
                <Controller
                  name="diseasesCheck.memoryCondition"
                  key="diseasesCheck.memoryCondition"
                  control={control}
                  rules={{ required: t('required') as string }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <div className={styles['large-text-radio-group']}>
                      <RadioButton
                        checked={value === 'good'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="diseasesCheck.memoryCondition1"
                        labelText={t('memoryConditionGood') as string}
                        value="good"
                      />
                      <RadioButton
                        checked={value === 'medium'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.memoryCondition2"
                        labelText={t('memoryConditionMedium') as string}
                        value="medium"
                      />
                      <RadioButton
                        checked={value === 'bad'}
                        onChange={onChange}
                        onBlur={() => {
                          onBlur();
                          handleBlur(name);
                        }}
                        name="healthChecks.memoryCondition3"
                        labelText={t('memoryConditionBad') as string}
                        value="bad"
                      />
                    </div>
                  )}
                />
                {errors?.diseasesCheck?.memoryCondition?.message && (
                  <FormError type="error" text={errors.diseasesCheck.memoryCondition.message} />
                )}
              </div>
            </div>
          </div>

          {/* healthQuestions */}
          <div id="healthQuestions" className={classnames(styles['health-questions'], styles['anamnesis-form__block'])}>
            <div className={styles['health-questions__fields']}>
              <div className={styles['health-questions__field']}>
                <FormLabel text={t('lifeChangeTrigger')} />
                <Controller
                  name="healthQuestions.lifeChangeTrigger"
                  key="healthQuestions.lifeChangeTrigger"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      error={errors?.healthQuestions?.lifeChangeTrigger && 'error'}
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('lifeChangeTriggerPlaceholder') as string}
                    />
                  )}
                />
                {errors?.healthQuestions?.lifeChangeTrigger?.message && (
                  <FormError type="error" text={errors.healthQuestions.lifeChangeTrigger.message} />
                )}
              </div>
              <div className={styles['health-questions__field']}>
                <FormLabel text={t('lifeSatisfaction')} />
                <Controller
                  name="healthQuestions.lifeSatisfaction"
                  key="healthQuestions.lifeSatisfaction"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      value={value}
                      error={errors?.healthQuestions?.lifeSatisfaction && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('lifeSatisfactionPlaceholder') as string}
                    />
                  )}
                />
                {errors?.healthQuestions?.lifeSatisfaction?.message && (
                  <FormError type="error" text={errors.healthQuestions.lifeSatisfaction.message} />
                )}
              </div>
              <div className={styles['health-questions__field']}>
                <FormLabel text={t('readyToDoForHealthSupport')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="healthQuestions.readyToDoForHealthSupport"
                  key="healthQuestions.readyToDoForHealthSupport"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      value={value}
                      error={errors?.healthQuestions?.readyToDoForHealthSupport && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('readyToDoForHealthSupportPlaceholder') as string}
                    />
                  )}
                />
                {errors?.healthQuestions?.readyToDoForHealthSupport?.message && (
                  <FormError type="error" text={errors.healthQuestions.readyToDoForHealthSupport.message} />
                )}
              </div>
              <div className={styles['health-questions__field']}>
                <FormLabel text={t('anamnesis')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="healthQuestions.anamnesis"
                  key="healthQuestions.anamnesis"
                  control={control}
                  render={({ field: { onChange, value, name, onBlur } }) => (
                    <TextArea
                      value={value}
                      error={errors?.healthQuestions?.anamnesis && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('anamnesisPlaceholder') as string}
                    />
                  )}
                />
                {errors?.healthQuestions?.anamnesis?.message && (
                  <FormError type="error" text={errors.healthQuestions.anamnesis.message} />
                )}
              </div>
              <div className={styles['health-questions__field']}>
                <FormLabel text={t('complaints')} />
                <Controller
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                    },
                  }}
                  name="healthQuestions.complaints"
                  key="healthQuestions.complaints"
                  control={control}
                  render={({ field: { onChange, value, onBlur, name } }) => (
                    <TextArea
                      value={value}
                      error={errors?.healthQuestions?.complaints && 'error'}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        handleBlur(name);
                      }}
                      minRows={4}
                      placeholder={t('complaintsPlaceholder') as string}
                    />
                  )}
                />
                {errors?.healthQuestions?.complaints?.message && (
                  <FormError type="error" text={errors.healthQuestions.complaints.message} />
                )}
              </div>
            </div>
          </div>

          <ButtonDefault
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid}
            text={Object.keys(anamnes).length !== 0 ? t('change') : t('save')}
            isLoading={isLoading}
            containerClassNames={styles['save-button']}
          />
        </form>
      </div>
      <Tooltip id="main" />
    </>
  );
};
