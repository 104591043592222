import { Analysis } from 'api/generated';
import { actions } from 'features/analyzes';
import {
  selectAllAnalyzes,
  selectAnalyzesHistory,
  selectAnalyzesSearchStatus,
  selectBiologicalAgeAnalyzes,
  selectTotalAnalyzesAmount,
} from 'features/analyzes/selectors';
import { selectPatient, selectPatientExpert, selectPatientRecommendations } from 'features/patient/selectors';
import { useClientSize } from 'hooks';
import { useUserAccess } from 'hooks/useUserAccess';
import { MainLayout } from 'layouts';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { PatientRoutes } from 'types/routes';

import {
  AgeCard,
  Aside,
  EmptyChart,
  EmptyRecommendationsCard,
  Experts,
  IndicatorsChart,
  MyExpert,
  RecommendationsSwiperList,
} from './components';
import styles from './styles.module.scss';

export const DashboardPatient: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'patient' });

  const dispatch = useAppDispatch();
  const hasAccess = useUserAccess();

  const user = useSelector(selectPatient);
  const analyzes = useAppSelector(selectAllAnalyzes);
  const analyzesHistory = useSelector(selectAnalyzesHistory).slice(0, 6);
  const biologicalAgeAnalyzes = useSelector(selectBiologicalAgeAnalyzes);
  const recommendations = useAppSelector(selectPatientRecommendations);
  const totalAnalyzesAmount = useSelector(selectTotalAnalyzesAmount);

  const isAnalyzesLoading = useSelector(selectAnalyzesSearchStatus) === LoadingStatus.pending;
  const myExpert = useAppSelector(selectPatientExpert);

  const [lastAnalyzes, setLastAnalyzes] = useState<Array<Analysis>>(biologicalAgeAnalyzes);
  const [lastAnalysisDate, setLastAnalysisDate] = useState<string>();

  const setAnalysisDate = () => {
    if (analyzes.length > 0) {
      const lastAnalysisFormattedDate =
        i18n.language === 'ru'
          ? moment(analyzesHistory[analyzesHistory.length - 1]?.createdAt)
              .locale('ru')
              .format('DD.MM.YYYY')
          : moment(analyzesHistory[analyzesHistory.length - 1]?.createdAt)
              .locale('en-gb')
              .format('DD.MM.YYYY');

      setLastAnalyzes(biologicalAgeAnalyzes);
      setLastAnalysisDate(lastAnalysisFormattedDate);

      dispatch(actions.setLastAnalysisId(analyzes[analyzes.length - 1].id));
    }
  };

  useEffect(() => {
    setAnalysisDate();
  }, [analyzes]);

  const getLastAnalysisText = (): string => {
    if (isAnalyzesLoading) {
      return '';
    } else if (lastAnalysisDate) {
      return `${t('lastAnalisis')}: ${lastAnalysisDate}`;
    } else if (analyzesHistory.length === 0) {
      return t('noAnalisis');
    }
    return '';
  };

  const lastAnalysisText = getLastAnalysisText();

  const hasRecommendations =
    recommendations.firstTypeCards.length > 0 ||
    recommendations.secondTypeCards.length > 0 ||
    recommendations.thirdTypeCards.length > 0;

  const hasEmptyRecommendations =
    recommendations.firstTypeCards.length === 0 &&
    recommendations.secondTypeCards.length === 0 &&
    recommendations.thirdTypeCards.length === 0;

  const hasRecommendationsAndSubscription = hasRecommendations && hasAccess;
  const hasRecommendationWithoutSubscription = hasRecommendations && !hasAccess;

  const hasAnalyzes = totalAnalyzesAmount > 0;
  const hasMyExpert = !!myExpert;

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const hasRecommendationsAndSubscriptionLink = hasRecommendationsAndSubscription && !isMobile;

  return (
    <MainLayout>
      <div className={styles.dashboard}>
        <div className={styles.dashboard__wrapper}>
          <div className={styles['dashboard__main']}>
            <div className={styles['dashboard-user-info']}>
              <div className={styles['dashboard-user-info__main']}>
                <h2 className={styles['dashboard-user-info__title']}>
                  {t('hello')} {user.me.name}
                </h2>
                <p className={`body body_medium ${styles['dashboard-user-info__subtitle']}`}>{lastAnalysisText}</p>
              </div>
              <AgeCard lastAnalyzes={lastAnalyzes} loading={isAnalyzesLoading} />
            </div>

            <div className={styles['dashboard-pay-attention']}>
              <div className={styles['dashboard-pay-attention__container']}>
                <h4 className={styles['dashboard-pay-attention__title']}>{t('attention')}</h4>
                {hasRecommendationsAndSubscriptionLink && (
                  <Link
                    className={styles['dashboard-pay-attention__show-all']}
                    to={{
                      pathname: PatientRoutes.RECOMMENDATIONS,
                    }}>
                    {t('showResults')}
                  </Link>
                )}
              </div>

              {hasEmptyRecommendations && (
                <EmptyRecommendationsCard hasRecomendation={false} hasSubscription={hasAccess} />
              )}

              {hasRecommendationsAndSubscription && (
                <>
                  <RecommendationsSwiperList recommendations={recommendations} />
                  {isMobile && (
                    <Link
                      className={styles['dashboard-pay-attention__show-all']}
                      to={{
                        pathname: PatientRoutes.RECOMMENDATIONS,
                      }}>
                      {t('showResults')}
                    </Link>
                  )}
                </>
              )}

              {hasRecommendationWithoutSubscription && (
                <EmptyRecommendationsCard
                  hasRecomendation={true}
                  hasSubscription={false}
                  recommendations={recommendations}
                />
              )}
            </div>

            {hasAnalyzes ? <IndicatorsChart gender={user.me.gender} /> : <EmptyChart />}

            {hasMyExpert ? <MyExpert expert={myExpert} /> : <Experts />}
          </div>

          <Aside />
        </div>
      </div>
    </MainLayout>
  );
};
