import * as React from 'react';

function Sign(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={4} height={14} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0A1.2 1.2 0 00.8 1.2v7.2a1.2 1.2 0 002.4 0V1.2A1.2 1.2 0 002 0zm0 10.8a1.2 1.2 0 100 2.4 1.2 1.2 0 000-2.4z"
        fill="#646EFA"
      />
    </svg>
  );
}

export default Sign;
