export const CONSTANTS_AMPLITUDE = {
  EVENT_NAMES: {
    CLIENT: {
      CLIENT_SIGN_UP_PAGE_VIEWED: 'Client Sign Up Page Viewed',
      CLIENT_SIGN_UP_PRESSED: 'Client Sign Up Pressed',
      CLIENT_VERIFICATION_CODE_ENTERED: 'Client Verification Code Entered',

      CLIENT_ONBOARDING_NAME_ENTERED: 'Client Onboarding Name Entered',
      CLIENT_ONBOARDING_BIRTHDAY_ENTERED: 'Client Onboarding Birthday Entered',
      CLIENT_ONBOARDING_GENDER_ENTERED: 'Client Onboarding Gender Entered',
      CLIENT_ONBOARDING_WEIGHT_ENTERED: 'Client Onboarding Weight Entered',
      CLIENT_ONBOARDING_HEIGHT_ENTERED: 'Client Onboarding Height Entered',
      CLIENT_ONBOARDING_PHONE_ENTERED: 'Client Onboarding Phone Entered',

      CREATE_NEW_ANALYSIS_PRESSED: 'Create New Analysis Pressed',
      ENTER_MANUAL_ANALYSIS_PRESSED: 'Enter Manual Analysis Pressed',
      ENTER_PDF_ANALYSIS_PRESSED: 'Enter PDF Analysis Pressed',
      ENTER_IMAGE_ANALYSIS_PRESSED: 'Enter Image Analysis Pressed',
      MANUAL_ANALYSIS_SUBMITTED: 'Manual Analysis Submitted',
      PDF_ANALYSIS_SUBMITTED: 'PDF Analysis Submitted',
      IMAGE_ANALYSIS_SUBMITTED: 'Image Analysis Submitted',

      PRESS_BUY_SUBSCRIPTION: 'Press Buy Subscription',
      CLIENT_SUBSCRIPTIONS_PAGE_VIEWED: 'Client Subscriptions Page Viewed',
      CLIENT_BUY_SUBSCRIPTION_PRESSED: 'Client Buy Subscription Pressed',
      CLIENT_SUBSCRIPTION_BOUGHT: 'Client Subscription Bought',

      EXPERTS_PAGE_VIEWED: 'Experts Page Viewed',
      EXPERT_INFORMATION_VIEWED: 'Expert Information Viewed',
      COOPERATE_EXPERT_BUTTON_PRESSED: 'Cooperate Expert Button Pressed',

      EXPERT_INFORMATION_MESSENGER_PRESSED: 'Expert Information Messenger Pressed',
      MESSENGER_MESSAGE_SENT: 'Messenger Message Sent',

      CLIENT_CHECKUPS_PAGE_VIEWED: 'Client Checkups Page Viewed',
      CLIENT_ANAMNESIS_PAGE_VIEWED: 'Client Anamnesis Page Viewed',
    },

    EXPERT: {
      EXPERT_SIGN_UP_PAGE_VIEWED: 'Expert Sign Up Page Viewed',
      EXPERT_SIGN_UP_PRESSED: 'Expert Sign Up Pressed',
      EXPERT_VERIFICATION_CODE_ENTERED: 'Expert Verification Code Entered',

      EXPERT_ONBOARDING_NAME_ENTERED: 'Expert Onboarding Name Entered',
      EXPERT_ONBOARDING_BIRTHDAY_ENTERED: 'Expert Onboarding Birthday Entered',
      EXPERT_ONBOARDING_GENDER_ENTERED: 'Expert Onboarding Gender Entered',
      EXPERT_ONBOARDING_ABOUT_ENTERED: 'Expert Onboarding About Entered',
      EXPERT_ONBOARDING_DOCUMENTS_ENTERED: 'Expert Onboarding Documents Entered',

      EXPERTS_SUBSCRIPTIONS_PAGE_VIEWED: 'Experts Subscriptions Page Viewed',
      EXPERT_BUY_SUBSCRIPTION_PRESSED: 'Expert Buy Subscription Pressed',
      EXPERT_SUBSCRIPTION_BOUGHT: 'Expert Subscription Bought',

      EXPERT_ADD_PATIENT_MODAL_OPENED: 'Expert Add Patient Modal Opened',
      EXPERT_PATIENT_ADDED: 'Expert Patient Added',

      EXPERT_CHECKUPS_PAGE_VIEWED: 'Expert Checkups Page Viewed',
    },
  },
};
