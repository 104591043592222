import { unwrapResult } from '@reduxjs/toolkit';
import { ArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { DeleteModal } from 'components/DeleteModal';
import { deleteMeAsync } from 'features/expert/thunks';
import { useToggle } from 'hooks';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { notify } from 'services/notificationService';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { MESSAGES } from 'types/messages';
import { ExpertRoutes } from 'types/routes';

import styles from './style.module.scss';

export const ExpertSettings: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile' });

  const width = useWindowWidth();
  const dispatch = useAppDispatch();

  const { isOpened: isDeleteModal, onToggle: toggleDeleteModal } = useToggle();

  const handleDeleteAccount = () => {
    dispatch(deleteMeAsync())
      .then(unwrapResult)
      .then(toggleDeleteModal)
      .then(() => {
        dispatch(actions.authActions.signOut());
        notify('success', MESSAGES.account.deleted);
      })
      .catch(() => notify('error', MESSAGES.errorTryLater));
  };

  const handleSignOut = () => {
    dispatch(actions.authActions.signOut());
  };

  if (width > 768) {
    return <Redirect to={ExpertRoutes.PROFILE} />;
  }

  return (
    <MainLayout>
      <div className={classNames(styles['expert-profile__tabs-container'])}>
        <div className={classNames(styles['expert-profile'])}>
          <h4 className={styles['expert-profile__title']}>{t('menuTabs.myProfile')}</h4>

          <div className={classNames(styles['expert-profile__links'])}>
            <Link
              to={ExpertRoutes.PROFILE}
              className={classNames('body', 'body_medium', styles['expert-profile__link'])}>
              <p className={styles['expert-profile__link-text']}>{t('menuTabs.myData')}</p>
              <ArrowIcon fill="var(--dark-grey)" />
            </Link>
            <Link
              to={ExpertRoutes.SETTINGS}
              className={classNames('body', 'body_medium', styles['expert-profile__link'])}>
              <p className={styles['expert-profile__link-text']}>{t('menuTabs.profile')}</p>
              <ArrowIcon fill="var(--dark-grey)" />
            </Link>
            <Link
              to={ExpertRoutes.PAYMENTS}
              className={classNames('body', 'body_medium', styles['expert-profile__link'])}>
              <p className={styles['expert-profile__link-text']}>{t('menuTabs.paymentsDiary')}</p>
              <ArrowIcon fill="var(--dark-grey)" />
            </Link>
            <Link
              to={ExpertRoutes.SUBSCRIPTION}
              className={classNames('body', 'body_medium', styles['expert-profile__link'])}>
              <p className={styles['expert-profile__link-text']}>{t('menuTabs.subscription')}</p>
              <ArrowIcon fill="var(--dark-grey)" />
            </Link>
            <Link
              to={ExpertRoutes.NOTIFICATIONS}
              className={classNames('body', 'body_medium', styles['expert-profile__link'])}>
              <p className={styles['expert-profile__link-text']}>{t('menuTabs.notifications')}</p>
              <ArrowIcon fill="var(--dark-grey)" />
            </Link>
          </div>
        </div>
        <div className={classNames(styles['expert-profile'])}>
          <div
            role="button"
            tabIndex={0}
            onClick={toggleDeleteModal}
            onKeyDown={toggleDeleteModal}
            className={styles['expert-profile__delete-btn']}>
            <p className={classNames('body', 'body_medium')}>{t('deleteAcc')}</p>
          </div>

          <div
            role="button"
            tabIndex={0}
            onClick={handleSignOut}
            onKeyDown={handleSignOut}
            className={styles['expert-profile__sign-out-btn']}>
            <p className={classNames('body', 'body_medium')}>{t('signOut')}</p>
          </div>
        </div>
      </div>
      {isDeleteModal && (
        <DeleteModal
          onClose={toggleDeleteModal}
          onDelete={handleDeleteAccount}
          title={t('modalDeleteTitle') as string}
          subtitle={t('modalDeleteContent') as string}
          isVisible={true}
        />
      )}
    </MainLayout>
  );
};
