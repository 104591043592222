import {
  FirstTypeCardsPatientGetResDto,
  SecondTypeCardsPatientGetResDto,
  ThirdTypeCardsPatientGetResDto,
} from 'api/generated';
import { HorizontalArrowIcon } from 'assets/svg';
import classnames from 'classnames';
import { CardType } from 'pages/Recommendation/Recommendation';
import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PatientRoutes } from 'types/routes';
import { ButtonDefault, RecommendationScale } from 'UIcomponents';

import styles from '../styles.module.scss';

type RecommendationsItemProps = {
  recommendation: FirstTypeCardsPatientGetResDto | SecondTypeCardsPatientGetResDto | ThirdTypeCardsPatientGetResDto;
  missingBiomarkers?: string[];
  type?: 'blue' | 'light';
  customStyles?: CSSProperties;
  hasLeftBorder?: boolean;
  score?: number;
};
//TODO: possible unused component
export const RecommendationsItem: FC<RecommendationsItemProps> = ({
  recommendation,
  missingBiomarkers,
  type = 'blue',
  customStyles,
  hasLeftBorder = false,
  score,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'recommendationCard' });

  const linkToShowRecommendation: { id?: string; type?: CardType } = {};
  if ('firstTypeCardId' in recommendation) {
    linkToShowRecommendation.id = recommendation.firstTypeCardId;
    linkToShowRecommendation.type = 'first';
  } else if ('secondTypeCardId' in recommendation) {
    linkToShowRecommendation.id = recommendation.secondTypeCardId;
    linkToShowRecommendation.type = 'second';
  } else if ('thirdTypeCardId' in recommendation) {
    linkToShowRecommendation.id = recommendation.thirdTypeCardId;
    linkToShowRecommendation.type = 'third';
  }

  return (
    <div
      style={customStyles}
      className={classnames(styles.recommendations__item, {
        [styles.recommendations__item_blue]: type === 'blue',
        [styles.recommendations__item_light]: type === 'light',
        [styles['recommendations__item_left-border']]: hasLeftBorder,
      })}>
      <h3
        className={classnames(styles.recommendations__title, {
          [styles.recommendations__title_blue]: type === 'blue',
          [styles.recommendations__title_blue_light]: type === 'light',
        })}>
        {recommendation.label}
      </h3>
      <div className={styles['recommendations__content-wrapper']}>
        <div className={styles.recommendations__content}>
          <p
            className={classnames(styles.recommendations__text, {
              [styles.recommendations__text_blue]: type === 'blue',
              [styles.recommendations__text_blue_light]: type === 'light',
            })}>
            {recommendation.description}
          </p>
          {score && (
            <div className={styles.recommendations__score}>
              <RecommendationScale score={score} customStyles={{ maxWidth: 300, width: '100%' }} />
            </div>
          )}
        </div>
        <div className={styles.recommendations__buttons}>
          <Link
            to={{
              pathname: PatientRoutes.RECOMMENDATION(linkToShowRecommendation?.id),
              state: { score, missingBiomarkers, type: linkToShowRecommendation?.type },
            }}
            className={classnames(styles.recommendations__link, {
              [styles.recommendations__link_blue]: type === 'blue',
              [styles.recommendations__link_light]: type === 'light',
            })}>
            <ButtonDefault text={t('learnMore')} variant="primary" customStyles={{ width: 184 }} />
            <HorizontalArrowIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};
