import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ExpertRoutes } from 'types/routes';

import styles from '../../styles.module.scss';

type Props = {
  title: string;
  index: number;
  id: string;
  isActive: boolean;
};

export const TabTitle: FC<Props> = ({ title, index, isActive, id }) => {
  const RouteMap = [
    ExpertRoutes.PATIENT_PROFILE(id),
    ExpertRoutes.PATIENT_RECOMMENDATIONS(id),
    ExpertRoutes.PATIENT_ANAMNESIS(id),
    ExpertRoutes.PATIENT_NUTRITION_DIARY(id),
    ExpertRoutes.PATIENT_NUTRITION_PLAN(id),
    ExpertRoutes.PATIENT_ANALYZES(id),
  ];
  return (
    <Link
      to={RouteMap[index]}
      className={classNames(
        styles['patient-profile-tabs__tab'],
        isActive && styles['patient-profile-tabs__tab--active'],
      )}>
      {title}
    </Link>
  );
};
