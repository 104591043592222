import { unwrapResult } from '@reduxjs/toolkit';
import { BaseModal } from 'components';
import { sendCurrentEmailVerificationCode, sendNewEmailVerificationCode } from 'features/patient/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';

import styled from '../../../../styles.module.scss';
import { SendEmailStep } from '../SendEmailStep';
import { VerificationStep } from '../VerificationStep';

export interface EmailSendingFormValues {
  email: string;
}

type UpdateEmailStepsProps = {
  modalOpened: boolean;
  onCloseModal: () => void;
};

export const UpdatePatientEmailSteps: FC<UpdateEmailStepsProps> = ({
  modalOpened,
  onCloseModal: onCloseModalProps,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.patientSettingsTab' });

  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [step, setStep] = useState(0);
  const width = useWindowWidth();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    setStep(0);
    setCurrentEmail('');
    onCloseModalProps();
  };

  const nextStep = () => {
    setStep((stateStep) => stateStep + 1);
  };

  const sendCurrentEmail = (data: EmailSendingFormValues) => {
    dispatch(sendCurrentEmailVerificationCode(data))
      .then(unwrapResult)
      .then((res) => {
        notify('success', String(res));
        nextStep();
        setCurrentEmail(data.email);
      })
      .catch((e) => {
        notify('error', e.message);
      });
  };

  const resendCurrentEmailCode = (data: EmailSendingFormValues) => {
    dispatch(sendCurrentEmailVerificationCode(data))
      .then(unwrapResult)
      .then((res) => {
        notify('success', String(res));
      })
      .catch((e) => {
        notify('error', e.message);
      });
  };

  const resendNewEmailCode = (data: EmailSendingFormValues) => {
    dispatch(sendNewEmailVerificationCode(data))
      .then(unwrapResult)
      .then((res) => {
        notify('success', String(res));
      })
      .catch((e) => {
        notify('error', e.message);
      });
  };

  const sendNewEmail = async (data: EmailSendingFormValues) => {
    await dispatch(sendNewEmailVerificationCode(data))
      .then(unwrapResult)
      .then((res) => {
        notify('success', String(res));
        nextStep();
        setNewEmail(data.email);
      })
      .catch((e) => {
        notify('error', e.message);
      });
  };

  return (
    <BaseModal
      title={step === 0 || step === 2 ? (t('changeEmailModal') as string) : (t('verif') as string)}
      isVisible={modalOpened}
      customStyles={{ maxWidth: '571px', maxHeight: '428px', padding: width > 600 ? '60px 100px' : '60px 24px' }}
      onClose={onCloseModal}>
      {step === 0 && (
        <SendEmailStep
          newEmail={sendNewEmail}
          currentEmail={sendCurrentEmail}
          nextStep={nextStep}
          type="current"
          text={<p className={styled['modal-email__text']}>{t('verifEmail')}</p>}
          disableInput
        />
      )}
      {step === 1 && (
        <VerificationStep
          nextStep={nextStep}
          setNewEmail={sendNewEmail}
          setCurrentEmail={resendCurrentEmailCode}
          type="current"
          onCloseModal={onCloseModal}
          newEmail={newEmail}
          currentEmail={currentEmail}
        />
      )}
      {step === 2 && (
        <SendEmailStep
          newEmail={sendNewEmail}
          currentEmail={sendCurrentEmail}
          type="new"
          text={t('typeEmail') as string}
          nextStep={nextStep}
        />
      )}
      {step === 3 && (
        <VerificationStep
          nextStep={nextStep}
          setNewEmail={resendNewEmailCode}
          setCurrentEmail={sendCurrentEmail}
          type="new"
          newEmail={newEmail}
          onCloseModal={onCloseModal}
        />
      )}
    </BaseModal>
  );
};
