import classnames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type AnalyzesTabsProp = {
  active: boolean;
  toggleTabs: () => void;
};

export const AnalyzesTabs: FC<AnalyzesTabsProp> = ({ active, toggleTabs }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.analysisHistoryTab' });

  return (
    <div className={styles.tabs}>
      <ul className={'flexbox'}>
        <button
          type="button"
          onClick={toggleTabs}
          className={classnames(styles.tabs__button, active && styles['tabs__button_active-left'])}>
          <li className={classnames(styles.tabs__tab, active && styles.tabs__active)}>{t('allAnalysis')}</li>
        </button>
        <button
          type="button"
          onClick={toggleTabs}
          className={classnames(styles.tabs__button, !active && styles['tabs__button_active-right'])}>
          <li className={classnames(styles.tabs__tab, !active && styles.tabs__active)}>{t('requests')}</li>
        </button>
      </ul>
    </div>
  );
};
