import { AuthLayout } from 'layouts';
import { TabsMini } from 'pages/PatientProfile/components/TabsMini';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { LastLoginedType } from 'types/user';

import { SignInForm } from './SignInForm';

export const SignIn: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const [lastLoginedType, setLastLoginedType] = useLocalStorage('last-logined-type');
  const [isActiveTab, setIsActiveTab] = useState(lastLoginedType === LastLoginedType.PATIENT || !lastLoginedType);

  const toggleTabs = () => {
    setLastLoginedType(isActiveTab ? LastLoginedType.EXPERT : LastLoginedType.PATIENT);
    setIsActiveTab(!isActiveTab);
  };

  return (
    <AuthLayout title={t('title') as string} isPatient={isActiveTab}>
      <TabsMini leftTitle={t('patient')} rightTitle={t('expert')} active={isActiveTab} toggleTabs={toggleTabs} />
      <SignInForm isExpert={!isActiveTab} />
    </AuthLayout>
  );
};
