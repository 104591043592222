import { PaymentDiary } from 'assets/svg';
import { useToggle } from 'hooks';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDefault } from 'UIcomponents';

import { NewPaymentModal } from '../../NewPaymentModal';
import styles from './styles.module.scss';

type EmptyPaymentsDiaryProps = {
  patients?: Array<{ label: string; value: string }>;
};

export const EmptyPaymentsDiary: FC<EmptyPaymentsDiaryProps> = ({ patients }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.paymentsDiaryTab' });

  const { isOpened: isPaymentModalOpen, onToggle: togglePaymentModal } = useToggle();

  const hasPatients = patients && patients.length > 0;

  return (
    <div className={styles.empty_tab}>
      <div className={styles.empty_container}>
        <PaymentDiary />
        <p className={styles.empty_header}>{t('emptyTitle')}</p>
        <p className={styles.empty_content}>{t('emptyContent')}</p>
        <ButtonDefault
          text={t('addPayment')}
          isDisabled={isEmpty(patients)}
          onClick={togglePaymentModal}
          customStyles={{ maxWidth: '200px' }}
          variant="primary"
        />
      </div>
      {hasPatients && (
        <NewPaymentModal
          isModalOpened={isPaymentModalOpen}
          onCloseModal={togglePaymentModal}
          openSuccessModal={togglePaymentModal}
          patients={patients}
        />
      )}
    </div>
  );
};
