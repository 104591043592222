import { SearchIcon } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

export const EmptyAnalyzesHistoryTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.analysisHistoryTab' });

  const { push } = useHistory();

  const handleAddAnalysis = () => {
    push(CommonRoutes.NEW_ANALYSIS);
  };

  const handleCreateCheckUpClick = () => {
    push(ExpertRoutes.ASSIGN_CHECKUP);
  };

  return (
    <div className={styles.container}>
      <SearchIcon className={styles.empty__icon} fill="var(--grey)" />
      <p className={styles.heading}>{t('emptyTitle')}</p>
      <p className={styles.content}>{t('emptyContent')}</p>
      <div className={'flexbox'}>
        <ButtonDefault
          type="submit"
          text={t('addAnalysis')}
          variant="secondary"
          customStyles={{ maxWidth: '191px', whiteSpace: 'nowrap', marginRight: '16px' }}
          onClick={handleAddAnalysis}
        />
        <ButtonDefault
          type="submit"
          text={t('createRequest')}
          variant="primary"
          customStyles={{ maxWidth: '191px' }}
          onClick={handleCreateCheckUpClick}
        />
      </div>
    </div>
  );
};
