import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { Info, Verified } from 'assets/svg';
import { BaseTable } from 'components/BaseTable';
import { CooperationStateEnum } from 'components/BaseTable/components/Cell/Cell';
import { selectExpert, selectExpertPatients, selectIsSearchingPatientsOnDashboard } from 'features/expert/selectors';
import { selectActivePromocode } from 'features/promocodes/selectors';
import { selectSubscription, selectSubscriptionsConstants } from 'features/subscriptions/selectors';
import { useUserAccess } from 'hooks/useUserAccess';
import { DashboardLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';
import { DocumentStatus, Search, Tooltip } from 'UIcomponents';

import { EmptyState } from './components/EmptyState';
import styles from './styles.module.scss';

export const DashboardExpert: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert' });

  const hasAccess = useUserAccess();
  const me = useAppSelector(selectExpert);
  const patients = useAppSelector(selectExpertPatients);
  const isPatientsSearching = useAppSelector(selectIsSearchingPatientsOnDashboard);
  const { educationDocumentStatus } = me;

  const isApproved = educationDocumentStatus === ExpertEducationDocumentStatusEnum.Approved;
  const isVerifiedExpert = me.isApproved;
  const hasPatients = patients?.data?.length > 0 || isPatientsSearching;
  const hasTableWithPatients = hasPatients || isPatientsSearching;

  const totalPatientsWithCooperation = patients.data
    ? patients.data.filter((patient) => {
        const signUpStatusState =
          patient.signUpStatus === 'sent' ? CooperationStateEnum.RequestFromExpert : CooperationStateEnum.Cooperation;

        const cooperationState = patient.isNewRequest ? CooperationStateEnum.RequestFromPatient : signUpStatusState;
        return cooperationState === CooperationStateEnum.Cooperation && patient;
      })
    : [];

  const activePromocode = useAppSelector(selectActivePromocode);
  const hasActivePromocode = !!activePromocode;
  const subscription = useAppSelector(selectSubscription);
  const subscriptionConstants = useAppSelector(selectSubscriptionsConstants);
  const { subscriptionPlan } = subscription;

  const subscriptionConstant = subscriptionConstants[subscriptionPlan];
  const paymentSubscriptionConstant = subscriptionConstants[subscription.subscriptionPlan];

  //INFO: здесь 50 захардкожено для ситуации когда у эксперта есть промокод
  const tooltipText = hasActivePromocode
    ? t('tooltipTextWithPromocode')
    : t('tooltipText', { count: paymentSubscriptionConstant?.patientLimit });

  const patientLimit = hasActivePromocode ? 50 : subscriptionConstant?.patientLimit;

  return (
    <DashboardLayout
      RightBox={
        isApproved ? (
          <Search customStyles={{ marginLeft: 'auto', flexGrow: 0 }} isPatientListEmpty={!hasPatients} />
        ) : (
          <DocumentStatus />
        )
      }
      Header={
        <div className={styles.dashboard__header}>
          <div className={styles.title_container}>
            <h4 className={styles.dashboard__title}>
              {t('heading')}, {me.name}
            </h4>
            {isVerifiedExpert && <Verified data-tip data-for="verifiedTooltip" />}
            <Tooltip id="verifiedTooltip" place="top">
              <span>{t('verifiedTooltip')}</span>
            </Tooltip>
          </div>
          {patientLimit && (
            <div className={styles.subtitle}>
              <h4 className={styles.search__title}>{t('listClients')}</h4>
              <div className={styles.limit_container}>
                <span className={styles.limit_text}>{t('allClients')}</span>
                <span>
                  {totalPatientsWithCooperation.length}/{patientLimit}
                </span>
                <Info data-tip data-for="subscriptionTooltip" />
                <Tooltip id="subscriptionTooltip" place="top">
                  <span>{tooltipText}</span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      }>
      {hasTableWithPatients ? <BaseTable /> : <EmptyState hasAccess={hasAccess} />}
    </DashboardLayout>
  );
};
