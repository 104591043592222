import { LayoutWithBackOptions } from 'layouts';
import { EmptyRecommendationsCard } from 'pages/DashboardPatient/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/reducers';
import { RootState } from 'store/types';
import { LoadingStatus } from 'types';
import { Waiting } from 'UIcomponents';

import { RecommendationsItem } from './components';
import styles from './styles.module.scss';

//TODO: possible unused component
export const Recommendations: React.FC = () => {
  const recommendations = useAppSelector((state: RootState) => state.patient.recommendations);
  const { firstTypeCards, secondTypeCards, thirdTypeCards } = recommendations;

  const isLoading =
    useSelector((state: RootState) => state.patient.recommendationsLoadingStatus) === LoadingStatus.pending;

  return (
    <LayoutWithBackOptions backOptions={{ text: 'На что следует обратить внимание' }}>
      <div className={styles.recommendations}>
        {isLoading && firstTypeCards.length === 0 && secondTypeCards.length === 0 && thirdTypeCards.length === 0 && (
          <Waiting
            spinnerColor="var(--dark-grey)"
            spinnerVariant="primary"
            spinnerRadius={50}
            customStyles={{ marginTop: 100 }}
          />
        )}
        {!isLoading && firstTypeCards.length === 0 && secondTypeCards.length === 0 && thirdTypeCards.length === 0 && (
          <EmptyRecommendationsCard customStyles={{ margin: '24px 0px' }} />
        )}
        {secondTypeCards.length > 0 &&
          secondTypeCards.map((secondTypeCard) => (
            <RecommendationsItem key={secondTypeCard.secondTypeCardId} recommendation={secondTypeCard} />
          ))}
        {thirdTypeCards.length > 0 &&
          thirdTypeCards.map((thirdTypeCard) => (
            <RecommendationsItem
              key={thirdTypeCard.thirdTypeCardId}
              recommendation={thirdTypeCard}
              type="light"
              score={thirdTypeCard.scale}
              missingBiomarkers={thirdTypeCard.missingBiomarkers}
            />
          ))}
        {firstTypeCards.length > 0 &&
          firstTypeCards.map((firstTypeCard) => (
            <RecommendationsItem
              key={firstTypeCard.firstTypeCardId}
              recommendation={firstTypeCard}
              type="light"
              hasLeftBorder
            />
          ))}
      </div>
      <div>123</div>
    </LayoutWithBackOptions>
  );
};
