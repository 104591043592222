import { toast, TypeOptions } from 'react-toastify';
import { DefaultNotification } from 'UIcomponents';

import styles from '../UIcomponents/DefaultNotification/Notification.module.scss';

export const notify = (type: Extract<TypeOptions, 'success' | 'error'>, text: string, closeOnClick = false): void => {
  toast(<DefaultNotification type={type} text={text} />, {
    className: styles.notification__container,
    type: type,
    position: 'bottom-left',
    autoClose: closeOnClick ? false : 3000,
    hideProgressBar: true,
    closeOnClick: closeOnClick,
    pauseOnHover: true,
    draggable: true,
    icon: false,
  });
};
