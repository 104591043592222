import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCompleteOnboardingDtoGenderEnum } from 'api/generated';
import { HorizontalArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { actions as expertActions } from 'features/expert/slice';
import { actions } from 'features/expertOnbording';
import { selectOnboardingState } from 'features/expertOnbording/selectors';
import { OnboardingState } from 'features/expertOnbording/slice';
import { completeOnboardingAsync } from 'features/expertOnbording/thunks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ExpertRoutes } from 'types/routes';
import { IconButton } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import { Step } from './Step';
import { StepsNavigate } from './StepsNavigate';
import styles from './styles.module.scss';

export const StepsExpert: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const StepList = {
    name: {
      order: 0,
      label: t('name'),
      name: 'name',
    },
    birthDay: {
      order: 1,
      label: t('birthday'),
      name: 'birthDay',
    },
    gender: {
      order: 2,
      label: t('gender'),
      name: 'gender',
    },
    specialization: {
      order: 3,
      label: t('specialization'),
      name: 'specialization',
    },
  };

  const history = useHistory();
  const [activeStep, setActiveStep] = useState(StepList.name.order);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  //Metrics
  const metricsTextMapper = {
    [0]: CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ONBOARDING_NAME_ENTERED,

    [1]: CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ONBOARDING_BIRTHDAY_ENTERED,

    [2]: CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ONBOARDING_GENDER_ENTERED,

    [3]: CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ONBOARDING_ABOUT_ENTERED,
  }[activeStep];

  const updateAction = async (data: OnboardingState) => {
    setLoading(true);
    dispatch(actions.expertUpdateAction({ ...data }));
    if (activeStep !== StepList.specialization.order) {
      setActiveStep(activeStep + 1);
    } else {
      await onSubmit(data);
    }
    setLoading(false);

    //Metrics
    metricsTextMapper && analytics.trackEvent(metricsTextMapper);
  };
  const handleClickPrevious = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };
  const onboardingValues = useSelector(selectOnboardingState);
  const [values, setValues] = useState(onboardingValues);

  const onSubmit = async (data: OnboardingState) => {
    await dispatch(
      completeOnboardingAsync({
        name: values.name.trim(),
        birthday: (values.birthDay as Date).toISOString(),
        gender: values.gender as ExpertCompleteOnboardingDtoGenderEnum,
        spesialization: data.specialization,
        shortInfo: data.about,
        jobExperience: data.expirience,
      }),
    )
      .then(unwrapResult)
      .then((onboarding) => {
        dispatch(expertActions.completeOnboarding(onboarding));
      })
      .then(() => {
        notify('success', t('notifySuccess'));
        history.push(ExpertRoutes.PROOF_EDUCATION);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };
  useEffect(() => {
    if (onboardingValues) {
      setValues(onboardingValues);
    }
  }, [onboardingValues]);

  return (
    <div className={classNames('container', styles['onboarding-container'])}>
      <div className={classNames(styles['previous-step'])}>
        {activeStep !== StepList.name.order && (
          <>
            <div className={classNames('flexbox', 'justify-start', 'align-center', styles['previous-step__btn'])}>
              <IconButton
                variant="secondary"
                icon={<HorizontalArrowIcon />}
                onClick={handleClickPrevious}
                customStyles={{ marginRight: '10px', transform: 'rotate(180deg)' }}
              />
              <p className={classNames('body_medium', styles['previous-step__title'])}>{t('previousQuestion')}</p>
            </div>

            <div
              className={classNames('flexbox', 'justify-start', 'align-center', styles['previous-step__btn--mobile'])}>
              <button
                onClick={handleClickPrevious}
                className={classNames('body_medium', styles['previous-step__title'])}>
                <HorizontalArrowIcon fill={'var(--dark-grey)'} />
                {t('previousQuestion')}
              </button>
            </div>
          </>
        )}
      </div>

      <StepsNavigate stepsCount={Object.keys(StepList).length} step={activeStep} />

      {activeStep === StepList.name.order && (
        <Step
          onSubmit={updateAction}
          buttonText={t('next')}
          title={t('enterName')}
          type={'nameStep'}
          input={{ name: 'name', label: t('labelName') }}
        />
      )}

      {activeStep === StepList.birthDay.order && (
        <Step
          onSubmit={updateAction}
          buttonText={t('next')}
          title={t('enterBirthday')}
          type={'stepDatepicker'}
          input={{ name: 'birthDay', label: t('birthday') }}
        />
      )}

      {activeStep === StepList.gender.order && (
        <Step
          onSubmit={updateAction}
          buttonText={t('next')}
          title={t('enterGender')}
          type={'stepDropdown'}
          input={{ name: 'gender', label: t('gender') }}
        />
      )}

      {activeStep === StepList.specialization.order && (
        <Step
          onSubmit={updateAction}
          buttonText={t('next')}
          title={t('specialization')}
          type={'stepSpec'}
          subtitle={t('specializationSubtitle') as string}
          input={{ name: 'gender', label: t('gender') }}
          isLoading={loading}
        />
      )}
    </div>
  );
};
