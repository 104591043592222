import { CSSObject } from '@emotion/serialize';
import { ArrowIcon } from 'assets/svg';
import classnames from 'classnames';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Modifier, NavbarElementProps } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import Select, { ControlProps, GroupBase, OptionProps as SelectOptionProps, StylesConfig } from 'react-select';
import { DropdownIndicator } from 'UIcomponents/DropdownDefault/components';

import styles from '../styles.module.scss';

type OptionProps = { label: string; value: string | number };

type NavbarProps = {
  onPreviousClick: () => void;
  onNextClick: () => void;
  className?: string;
  fromMonth: Date;
  toMonth: Date;
  onChangeYear: (month: Date) => void;
  disabledDays?: Modifier | Modifier[];
} & NavbarElementProps;

const fontStyle = { fontSize: 16, fontWeight: 500, lineHeight: '20px', letterSpacing: '-0.01em', fontFamily: 'Inter' };

const generateStyles = (type: 'year' | 'month'): StylesConfig<OptionProps, false, GroupBase<OptionProps>> => {
  const customStyles: StylesConfig<OptionProps, false, GroupBase<OptionProps>> = {
    control: (provided: CSSObject, state: ControlProps<OptionProps, false, GroupBase<OptionProps>>) => ({
      ...provided,
      borderColor: state.isFocused ? 'var(--light-grey)' : 'white',
      borderStyle: 'solid',
      borderRadius: 12,
      outline: 'none',
      boxShadow: 'none',
      width: '100%',
      background: state.isFocused ? 'var(--light-grey)' : 'inherit',
      '&:hover': {
        borderColor: 'none',
      },
    }),
    input: (provided: CSSObject) => ({
      ...provided,
      ...fontStyle,
      color: 'var(--secondary-black)',
      marginRight: type === 'month' ? 'auto' : '0',
    }),
    placeholder: (provided: CSSObject) => ({
      ...provided,
      ...fontStyle,
      color: 'var(--dark-grey)',
    }),
    singleValue: () => ({
      ...fontStyle,
      color: 'var(--black)',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      maxWidth: '100%',
    }),
    valueContainer: (provided: CSSObject) => ({
      ...provided,
      display: 'flex',
      padding: 0,
      marginLeft: '12px',
      justifyContent: type === 'month' ? 'flex-end' : 'flex-start',
      width: type === 'month' ? '100%' : '50px',
      flexWrap: 'nowrap',
    }),
    dropdownIndicator: (provided: CSSObject) => ({
      ...provided,
      cursor: 'pointer',
      padding: '0 12px 0 4px',
    }),
    indicatorSeparator: () => ({}),
    container: (provided: CSSObject) => ({
      ...provided,
      width: 'auto',
    }),
    menu: (provided: CSSObject) => ({
      ...provided,
      width: type === 'month' ? '140px' : '100%',
      borderRadius: '12px',
      boxShadow: '0px 0px 10px rgba(120, 120, 120, 0.12)',
      background: 'var(--white)',
      overflow: 'hidden',
      marginTop: 4,
      outline: 'none',
    }),
    menuList: (provided: CSSObject) => ({
      ...provided,
      background: 'var(--white)',
      margin: 0,
      padding: 0,
    }),
    option: (provided: CSSObject, state: SelectOptionProps<OptionProps, false, GroupBase<OptionProps>>) => ({
      ...provided,
      ...fontStyle,
      textTransform: 'capitalize',
      padding: '8px 16px 8px 16px',
      backgroundColor: state.isSelected ? 'var(--light-grey)' : 'var(--white)',
      color: 'var(--secondary-black)',
      '&:hover': {
        background: 'var(--light-grey)',
      },
      '&:focus': {
        background: 'var(--light-grey)',
      },
    }),
  };
  return customStyles;
};

export const Navbar = ({
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
  month,
  onChangeYear,
  fromMonth,
  toMonth,
}: NavbarProps): ReactElement => {
  const [activeMonth, setActiveMonth] = useState(false);
  const [activeYear, setActiveYear] = useState(false);
  const monthNum = month.getMonth();
  const year = month.getFullYear();
  const startYear = new Date(fromMonth).getFullYear();
  const startMonht = new Date(fromMonth).getMonth();

  const { i18n } = useTranslation();

  const months = i18n.language === 'ru' ? localeUtils.getMonths('ru') : localeUtils.getMonths('en-gb');

  const monthsOptions = months
    .map((month, index) => {
      if (year === startYear && index >= startMonht) {
        return { label: month.toString(), value: month.toString() };
      }
      if (year === toMonth.getFullYear() && index <= toMonth.getMonth()) {
        return { label: month.toString(), value: month.toString() };
      }
      if (year !== startYear && year !== toMonth.getFullYear())
        return { label: month.toString(), value: month.toString() };
    })
    .filter((month) => month?.value !== undefined);

  useEffect(() => {
    if (year === startYear) {
      // handleChangeMonth(monthsOptions?.find((month) => month?.value === months[startMonht].toString);
    }
  }, [year]);

  const years = [];
  for (let i = moment(toMonth).year(); i >= moment(fromMonth).year(); i -= 1) {
    years.push(i);
  }

  const yearsOptions = years.map((year) => ({ label: year.toString(), value: year.toString() }));
  const yearId = yearsOptions.findIndex((option) => option.value === year.toString());

  const handleChangeMonth = (value: OptionProps | null) => {
    month.setMonth(months.findIndex((option) => option === value?.label));
    onChangeYear(new Date(month));
  };

  const handleChangeYear = (value: OptionProps | null) => {
    if (value) month.setFullYear(Number(value?.label));
    onChangeYear(new Date(month));
  };
  return (
    <div className={className}>
      <button
        type="button"
        className={classnames(styles.datepicker__icon, styles.datepicker__icon_left)}
        onClick={() => onPreviousClick()}>
        <ArrowIcon />
      </button>

      <div className={styles['datepicker-navbar__date-select']}>
        <Select
          styles={generateStyles('month')}
          options={monthsOptions as unknown as undefined}
          onChange={handleChangeMonth}
          value={monthsOptions.find((option) => option?.label === months[monthNum])}
          isSearchable={false}
          maxMenuHeight={180}
          onMenuOpen={() => setActiveMonth(true)}
          onMenuClose={() => setActiveMonth(false)}
          components={{
            // eslint-disable-next-line react/display-name
            DropdownIndicator: (props) => <DropdownIndicator active={activeMonth} icon="calendar" {...props} />,
          }}
        />
        <Select
          styles={generateStyles('year')}
          options={yearsOptions}
          onChange={handleChangeYear}
          value={yearsOptions[yearId]}
          maxMenuHeight={180}
          onMenuOpen={() => setActiveYear(true)}
          onMenuClose={() => setActiveYear(false)}
          isSearchable={false}
          components={{
            // eslint-disable-next-line react/display-name
            DropdownIndicator: (props) => <DropdownIndicator active={activeYear} icon="calendar" {...props} />,
          }}
        />
      </div>

      <button
        type="button"
        className={`${styles.datepicker__icon} ${styles.datepicker__icon_right}`}
        onClick={() => onNextClick()}>
        <ArrowIcon />
      </button>
    </div>
  );
};
