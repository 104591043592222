import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetCustomerPortalUrlDto,
  GetPaymentUrlDto,
  RedirectUrlDto,
  SubscriptionDto,
  SubscriptionsConstantsResponseDto,
  UpdateSubscriptionDto,
} from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from './types';

export const getPaymentUrlAsync = createAsyncThunk<
  RedirectUrlDto,
  GetPaymentUrlDto,
  ExtraParamsThunkType<SerializedError>
>('subscriptions/getPaymentUrl', async (getPaymentUrlDto: GetPaymentUrlDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.SubscriptionsApi.subscriptionsControllerGetCheckoutSession(getPaymentUrlDto, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const cancelSubscriptionAsync = createAsyncThunk<
  SubscriptionDto,
  undefined,
  ExtraParamsThunkType<SerializedError>
>('subscriptions/cancelSubscription', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.SubscriptionsApi.subscriptionsControllerCancelSubscription({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateSubscriptionAsync = createAsyncThunk<
  SubscriptionDto,
  UpdateSubscriptionDto,
  ExtraParamsThunkType<SerializedError>
>(
  'subscriptions/updateSubscription',
  async (updateSubscriptionDto: UpdateSubscriptionDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.SubscriptionsApi.subscriptionsControllerUpdateSubscription(updateSubscriptionDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const renewSubscriptionAsync = createAsyncThunk<
  SubscriptionDto,
  undefined,
  ExtraParamsThunkType<SerializedError>
>('subscriptions/renewSubscription', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.SubscriptionsApi.subscriptionsControllerRenewSubscription({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const retreiveSubscriptionAsync = createAsyncThunk<
  SubscriptionDto,
  undefined,
  ExtraParamsThunkType<SerializedError>
>('subscriptions/retreiveSubscription', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.SubscriptionsApi.subscriptionsControllerRetrieveSubscription({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getSubscriptionsConstantsAsync = createAsyncThunk<
  SubscriptionsConstantsResponseDto,
  undefined,
  ExtraParamsThunkType<SerializedError>
>('subscriptions/getSubscriptionConstants', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.SubscriptionsApi.subscriptionsControllerGetSubscriptionsConstants({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getCustomerPortalLinkAsync = createAsyncThunk<
  RedirectUrlDto,
  GetCustomerPortalUrlDto,
  ExtraParamsThunkType<SerializedError>
>(
  'subscriptions/getCustomerPortalLink',
  async (getCustomerPortalUrlDto: GetCustomerPortalUrlDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.SubscriptionsApi.subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
