import { OnboardingLayout } from 'layouts';
import { FC } from 'react';

import { StepsExpert } from './StepsExpert';

export const ExpertOnboarding: FC = () => {
  return (
    <OnboardingLayout>
      <StepsExpert />
    </OnboardingLayout>
  );
};
