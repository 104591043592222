import useWindowWidth from 'hooks/useWindowWidth';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDefault } from 'UIcomponents';

import { BaseModal } from '../BaseModal';
import styles from './DeleteModal.module.scss';

interface Props {
  onClose: () => void;
  onDelete: () => void;
  title?: string;
  subtitle?: string;
}

export const StopCooperationModal: FC<Props> = ({ onClose, title, subtitle, onDelete }) => {
  const width = useWindowWidth();
  const { t } = useTranslation('translation', { keyPrefix: 'stopCooperationModal' });

  return (
    <BaseModal
      customStyles={width > 768 ? { padding: '60px 100px', minWidth: '715px' } : { width: '90%' }}
      onClose={onClose}
      isVisible={true}>
      <div className={styles.modal__info}>
        <h2 className={styles.modal__title}>{title}</h2>
        <p className={styles.modal__subtitle}>{subtitle}</p>
      </div>
      <div className={styles['modal__button-box']}>
        <ButtonDefault text={t('cancel')} variant="secondary" customStyles={{ minWidth: '133px' }} onClick={onClose} />
        <ButtonDefault
          text={t('stopCooperation')}
          variant="delete"
          customStyles={{ minWidth: '294px' }}
          onClick={onDelete}
        />
      </div>
    </BaseModal>
  );
};
