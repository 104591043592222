import * as React from 'react';

function Info(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.00016 11.3333C8.18905 11.3333 8.3475 11.2693 8.4755 11.1413C8.60305 11.0138 8.66683 10.8555 8.66683 10.6666V7.98331C8.66683 7.79442 8.60305 7.63887 8.4755 7.51665C8.3475 7.39442 8.18905 7.33331 8.00016 7.33331C7.81127 7.33331 7.65305 7.39709 7.5255 7.52465C7.3975 7.65265 7.3335 7.81109 7.3335 7.99998V10.6833C7.3335 10.8722 7.3975 11.0278 7.5255 11.15C7.65305 11.2722 7.81127 11.3333 8.00016 11.3333ZM8.00016 5.99998C8.18905 5.99998 8.3475 5.93598 8.4755 5.80798C8.60305 5.68042 8.66683 5.5222 8.66683 5.33331C8.66683 5.14442 8.60305 4.98598 8.4755 4.85798C8.3475 4.73042 8.18905 4.66665 8.00016 4.66665C7.81127 4.66665 7.65305 4.73042 7.5255 4.85798C7.3975 4.98598 7.3335 5.14442 7.3335 5.33331C7.3335 5.5222 7.3975 5.68042 7.5255 5.80798C7.65305 5.93598 7.81127 5.99998 8.00016 5.99998ZM8.00016 14.6666C7.07794 14.6666 6.21127 14.4915 5.40016 14.1413C4.58905 13.7915 3.8835 13.3166 3.2835 12.7166C2.6835 12.1166 2.20861 11.4111 1.85883 10.6C1.50861 9.78887 1.3335 8.9222 1.3335 7.99998C1.3335 7.07776 1.50861 6.21109 1.85883 5.39998C2.20861 4.58887 2.6835 3.88331 3.2835 3.28331C3.8835 2.68331 4.58905 2.2082 5.40016 1.85798C6.21127 1.5082 7.07794 1.33331 8.00016 1.33331C8.92239 1.33331 9.78905 1.5082 10.6002 1.85798C11.4113 2.2082 12.1168 2.68331 12.7168 3.28331C13.3168 3.88331 13.7917 4.58887 14.1415 5.39998C14.4917 6.21109 14.6668 7.07776 14.6668 7.99998C14.6668 8.9222 14.4917 9.78887 14.1415 10.6C13.7917 11.4111 13.3168 12.1166 12.7168 12.7166C12.1168 13.3166 11.4113 13.7915 10.6002 14.1413C9.78905 14.4915 8.92239 14.6666 8.00016 14.6666Z"
        fill="#DBDBDB"
      />
    </svg>
  );
}

export default Info;
