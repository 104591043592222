const UnixEpochYear = 1970;

export const differenceOfYears = (from: Date, to: Date): number => {
  const monthDiff = new Date(to.getTime() - from.getTime());
  const age = Math.abs(monthDiff.getUTCFullYear() - UnixEpochYear);
  return age;
};

export const calculateAge = (date: Date): number => {
  const monthDiff = new Date(Date.now() - date.getTime());
  const age = Math.abs(monthDiff.getUTCFullYear() - UnixEpochYear);
  return age;
};

export const outOfSideDate = (years: number): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);

  return date;
};

export const isDateInPast = (firstDate: Date, secondDate: Date): boolean =>
  firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);
