import { SubscriptionDtoSubscriptionPlanEnum } from 'api/generated';

export const getSubscriptionNameByIntervalAndPatientLimit = (
  interval: string,
  patientLimit: number,
): SubscriptionDtoSubscriptionPlanEnum | undefined => {
  if (interval === 'Year' && patientLimit === 0) {
    return SubscriptionDtoSubscriptionPlanEnum.Year;
  }
  if (interval === 'Month' && patientLimit === 0) {
    return SubscriptionDtoSubscriptionPlanEnum.Month;
  }
  if (interval === 'Year' && patientLimit === 20) {
    return SubscriptionDtoSubscriptionPlanEnum.LowYear;
  }
  if (interval === 'Year' && patientLimit === 50) {
    return SubscriptionDtoSubscriptionPlanEnum.MediumYear;
  }
  if (interval === 'Year' && patientLimit === 100) {
    return SubscriptionDtoSubscriptionPlanEnum.HighYear;
  }
  if (interval === 'Month' && patientLimit === 20) {
    return SubscriptionDtoSubscriptionPlanEnum.LowMonth;
  }
  if (interval === 'Month' && patientLimit === 50) {
    return SubscriptionDtoSubscriptionPlanEnum.MediumMonth;
  }
  if (interval === 'Month' && patientLimit === 100) {
    SubscriptionDtoSubscriptionPlanEnum.HighMonth;
  }
};
