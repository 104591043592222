import { CheckCircle } from 'assets/svg';
import { AuthLayout } from 'layouts';
import React, { FC } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { UnauthorizedRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

interface LocationState {
  success: boolean;
}

export const PasswordChanged: FC = () => {
  const location = useLocation<LocationState>();
  const from = location?.state?.success;
  const history = useHistory();

  if (!from) {
    return <Redirect to={UnauthorizedRoutes.ROOT} />;
  }

  return (
    <AuthLayout>
      <div className={styles['forgot-password-changed']}>
        <h3 className={styles['forgot-password-changed__header']}>
          <span>Пароль</span> успешно изменен
        </h3>
        <CheckCircle className={styles['forgot-password-changed__icon']} />
        <ButtonDefault text="Готово" onClick={() => history.push(UnauthorizedRoutes.SIGN_IN)} />
      </div>
    </AuthLayout>
  );
};
