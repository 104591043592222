import { Analysis } from 'api/generated';
import { createSelector } from 'reselect';
import { State } from 'store/reducers';
import { RootState } from 'store/types';

import { AnalyzesStore } from './types';

export const selectAnalyzes = (state: RootState): AnalyzesStore => state.analyzes;
export const selectLoading = createSelector(selectAnalyzes, (analyzes) => analyzes.loading);

export const selectAllAnalyzes = createSelector(selectAnalyzes, (analyzes) => analyzes.analyzes);
export const selectAnalyzesHistory = createSelector(selectAnalyzes, (analyzes) => analyzes.analyzesHistory);
export const selectBiologicalAgeAnalyzes = createSelector(selectAnalyzes, (analyzes) => analyzes.biologicalAgeAnalyzes);

export const selectSelectedAnalysisId = createSelector(selectAnalyzes, (analyzes) => analyzes.selectedAnalysisId);

export const selectTotalAnalyzesAmount = createSelector(selectAnalyzes, (analyzes) => analyzes.totalAnalyzesAmount);

export const selectAnalyzesSearchStatus = createSelector(selectAnalyzes, (analyzes) => analyzes.analyzesSearchStatus);

export const selectDownloadingAnalysisPdfStatus = createSelector(
  selectAnalyzes,
  (analyzes) => analyzes.downloadingAnalysisPdfStatus,
);

export const selectRecommendationsCalculationStatus = createSelector(
  selectAnalyzes,
  (analyzes) => analyzes.recommendationsCalculationStatus,
);

export const selectAnalysisById = (id: string): ((state: State) => Analysis | undefined) =>
  createSelector(selectAllAnalyzes, (allAnalyzes) => allAnalyzes.find((analysis) => analysis.id === id));

export const selectDeleteAnalysisStatus = createSelector(
  selectAnalyzes,
  (analyzes) => analyzes.deleteAnalysisLoadingStatus,
);
