import { AuthLayout } from 'layouts';
import { TabsMini } from 'pages/PatientProfile/components/TabsMini';
import queryString from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { analytics } from 'services/analytics';
import { LastLoginedType } from 'types/user';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import { SignUpForm } from './SignUpForm';

export const SignUp: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const [lastLoginedType, setLastLoginedType] = useLocalStorage('last-logined-type');
  const [isActiveTab, setIsActiveTab] = useState(lastLoginedType === LastLoginedType.PATIENT || !lastLoginedType);

  const { hash } = queryString.parse(location.search);

  const isSignUpByInvitation = !!hash;

  const toggleTabs = () => {
    setLastLoginedType(isActiveTab ? LastLoginedType.EXPERT : LastLoginedType.PATIENT);
    setIsActiveTab(!isActiveTab);
  };

  const isPatientLogo = isActiveTab || isSignUpByInvitation;

  //Metrics
  useEffect(() => {
    if (isActiveTab) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_SIGN_UP_PAGE_VIEWED);
    }

    if (!isActiveTab) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_SIGN_UP_PAGE_VIEWED);
    }
  }, [isActiveTab]);

  return (
    <AuthLayout title={t('signUpTitle') as string} withFooter isPatient={isPatientLogo}>
      {!isSignUpByInvitation && (
        <TabsMini leftTitle={t('patient')} rightTitle={t('expert')} active={isActiveTab} toggleTabs={toggleTabs} />
      )}
      <SignUpForm isExpert={!isActiveTab} />
    </AuthLayout>
  );
};
