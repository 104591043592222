import useWindowWidth from 'hooks/useWindowWidth';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.scss';

type Props = {
  id?: string | undefined;
  place?: 'top' | 'right' | 'bottom' | 'left';
  clickable?: boolean;
};

export const Tooltip: FC<Props> = ({ id, place = 'bottom', clickable = true, ...props }) => {
  const width = useWindowWidth();
  return (
    <ReactTooltip
      id={id}
      arrowColor={width < 560 ? 'var(--background-black)' : undefined}
      effect="solid"
      place={place}
      multiline={true}
      clickable={clickable}
      className={styles.tooltip}
      {...props}
    />
  );
};
