import { Vector } from 'assets/svg';
import { Expert, FullInfoAboutExpertModal } from 'components';
import { selectAllExperts } from 'features/patient/selectors';
import { useToggle } from 'hooks';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { PatientRoutes } from 'types/routes';

import styles from '../../styles.module.scss';

export const Experts: FC = () => {
  const expertsSliced = useAppSelector(selectAllExperts).slice(0, 2);

  const { isOpened: isExpertInfoModalOpen, onToggle: toggleExpertInfoModal } = useToggle();

  const [clickedExpertData, setClickedExpertData] = useState({ id: '', isRequestInPending: false });

  const handleExpertCardClick = (id: string, isRequestInPending: boolean) => {
    setClickedExpertData({ id: id, isRequestInPending: isRequestInPending });
    toggleExpertInfoModal();
  };

  return (
    <>
      <div className={styles['experts__container']}>
        <div className={styles['experts__inner']}>
          {expertsSliced.map((expert, index) => (
            <Expert
              expert={expert}
              key={index}
              isDashboardCard={true}
              onClickCallBack={() => handleExpertCardClick(expert.id, expert.requestInPending)}
              isFullInfoModalOpened={clickedExpertData.id === expert.id ? isExpertInfoModalOpen : false}
            />
          ))}
          <Link to={PatientRoutes.EXPERTS}>
            <div className={styles['experts__show-all']}>
              <Vector />
            </div>
          </Link>
        </div>
      </div>

      {isExpertInfoModalOpen && (
        <FullInfoAboutExpertModal
          myExpert={false}
          isModalOpened={isExpertInfoModalOpen}
          onCloseModal={toggleExpertInfoModal}
          expertId={clickedExpertData.id}
          isRequestInPending={clickedExpertData.isRequestInPending}
        />
      )}
    </>
  );
};
