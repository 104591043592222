import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Analysis,
  AnalysisKindGetResDto,
  CreatePatientAnalysisDto,
  FindOneAnalysisWithPreviousResDto,
  SearchAnalyzesResDto,
  UpdatePatientAnalysisDto,
} from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';
import { AnalyzesNormFilter, SortDirectionFilter } from 'types/analyzes';

import { RequestErrors, SerializedError } from './types';

export type AnalyzesSearchQueryDto = {
  startDate?: string;
  endDate?: string;
  sortBy?: 'date' | 'createdAt' | 'updatedAt';
  sortDirection?: SortDirectionFilter;
  normFilter?: AnalyzesNormFilter;
  page?: number;
  limit?: number;
};

export const searchAnalyzesAsync = createAsyncThunk<
  SearchAnalyzesResDto,
  AnalyzesSearchQueryDto,
  ExtraParamsThunkType<RequestErrors>
>('analyzes/searchAnalyzes', async (searchQuery: AnalyzesSearchQueryDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { startDate, endDate, sortBy, sortDirection, normFilter, page, limit } = searchQuery;
    const { data } = await api.PatientAnalyzesApi.patientAnalyzesControllerSearch(
      startDate,
      endDate,
      sortBy,
      sortDirection,
      normFilter,
      page,
      limit,
      { params: { l: language } },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createAnalysisAsync = createAsyncThunk<
  Analysis,
  CreatePatientAnalysisDto,
  ExtraParamsThunkType<SerializedError>
>('analyzes/create', async (userData: CreatePatientAnalysisDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientAnalyzesApi.patientAnalyzesControllerCreate(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createAnalysisExpertAsync = createAsyncThunk<
  Analysis,
  { id: string; userData: CreatePatientAnalysisDto },
  ExtraParamsThunkType<SerializedError>
>('analyzes/createAnalysisExpertAsync', async ({ id, userData }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerCreate(id, userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateAnalysisAsync = createAsyncThunk<
  Analysis,
  { id: string; analysisData: UpdatePatientAnalysisDto },
  ExtraParamsThunkType<SerializedError>
>(
  'analyzes/update',
  async (userData: { id: string; analysisData: UpdatePatientAnalysisDto }, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientAnalyzesApi.patientAnalyzesControllerUpdate(
        userData.id,
        userData.analysisData,
        {
          params: { l: language },
        },
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExpertAnalysisAsync = createAsyncThunk<
  Analysis,
  { patientId: string; analysisId: string; analysisData: UpdatePatientAnalysisDto },
  ExtraParamsThunkType<SerializedError>
>(
  'analyzes/updateExpertAnalysisAsync',
  async ({ patientId, analysisId, analysisData }, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerUpdate(
        patientId,
        analysisId,
        analysisData,
        {
          params: { l: language },
        },
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAnalysisAsync = createAsyncThunk<Analysis, string, ExtraParamsThunkType<SerializedError>>(
  'analyzes/get',
  async (analysisId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientAnalyzesApi.patientAnalyzesControllerFindOne(analysisId, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAnalysisWithPreviousAsync = createAsyncThunk<
  FindOneAnalysisWithPreviousResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('analyzes/getWithPrevious', async (analysisId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientAnalyzesApi.patientAnalyzesControllerFindOneWithPrevious(analysisId, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAnalysisPdfWithPreviousAsync = createAsyncThunk<string, string, ExtraParamsThunkType<SerializedError>>(
  'analyzes/printAnalysisPdfWithPrevious',
  async (analysisId, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data: blob } = await api.AnalyzesApi.analysisPdfControllerDownloadPdf(analysisId, {
        responseType: 'blob',
        params: { l: language },
      });
      return blob;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAnalysisPdfWithoutPreviousAsync = createAsyncThunk<
  void,
  { analysisId: string; pdfFilename: string },
  ExtraParamsThunkType<SerializedError>
>('analyzes/exportAnalysisPdf', async ({ analysisId, pdfFilename }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data: blob } = await api.AnalyzesApi.analysisPdfControllerDownloadPdfWithoutPrevious(analysisId, {
      responseType: 'blob',
      params: { l: language },
    });
    return blob;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPatientAnalysisPdfWithPreviousAsync = createAsyncThunk<
  void,
  { patientId: string; analysisId: string },
  ExtraParamsThunkType<SerializedError>
>(
  '/analyzes/{patientId}/{analysisId}/patient-pdf-with-previous',
  async ({ patientId, analysisId }, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data: blob } = await api.AnalyzesApi.analysisPdfControllerDownloadPatientPdf(patientId, analysisId, {
        responseType: 'blob',
        params: { l: language },
      });
      return blob;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getManyAnalysisKinds = createAsyncThunk<
  AnalysisKindGetResDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('analyzes/getManyAnalysisKinds', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsAnalysisKindsApi.patientsAnalysisKindsControllerGetAnalysisKinds({
      params: { l: language },
    });
    return data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const getManyAnalysisKindsExperts = createAsyncThunk<
  AnalysisKindGetResDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('analyzes/getManyAnalysisKindsExperts', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsAnalysisKindsApi.expertAnalysisKindsControllerGetAnalysisKinds({
      params: { l: language },
    });
    return data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const deleteAnalysis = createAsyncThunk<string, string, ExtraParamsThunkType<SerializedError>>(
  'analyzes/deleteAnalysis',
  async (analysisId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      await api.PatientAnalyzesApi.patientAnalyzesControllerDelete(analysisId, {
        params: { l: language },
      });
      return analysisId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteExpertAnalysis = createAsyncThunk<
  string,
  { patientId: string; analysisId: string },
  ExtraParamsThunkType<SerializedError>
>('analyzes/deleteExpertAnalysis', async ({ patientId, analysisId }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    await api.ExpertsPatientAnalyzesApi.expertPatientAnalyzesControllerDelete(patientId, analysisId, {
      params: { l: language },
    });
    return analysisId;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
