import { CloseIcon, FileIcon } from 'assets/svg';
import classnames from 'classnames';
import { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import returnFileSize from 'utils/fileSize';
import stringCircumcision from 'utils/stringCircumcision';

import styles from '../../styles.module.scss';

interface DocumentProps {
  name: string;
  size: number;
  id: string;
  deleteFile: (name: string) => void;
}

export const Document: FC<DocumentProps> = ({ name, size, deleteFile, id }) => {
  return (
    <div className={styles['document']}>
      <button className={styles['document__delete']} onClick={() => deleteFile(id)}>
        <CloseIcon />
      </button>
      <div className={styles['document__circle']}>{<FileIcon />}</div>
      <div className={styles['document__info']}>
        <Dotdotdot clamp={2} useNativeClamp tagName="h6" className={styles['document__info-title']}>
          {stringCircumcision(name)}
        </Dotdotdot>
        {/* <h6 className={styles['document__info-title']}>{stringCircumcision(name)}</h6> */}
        <p className={classnames(styles['document__info-subtitle'], styles.subhead)}>{returnFileSize(size)}</p>
      </div>
    </div>
  );
};
