import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from './styled.module.scss';
interface TimerPros {
  onClose: () => void;
}

export const Timer: React.FC<TimerPros> = ({ onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const [seconds, setSeconds] = useState(59);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    timerRef.current = +setInterval(() => {
      if (seconds > 1) {
        setSeconds((state) => state - 1);
      } else {
        onClose();
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [seconds]);

  return (
    <p className={styled.timer}>
      {t('resend')} 00:{seconds < 10 && 0}
      {seconds}
    </p>
  );
};
