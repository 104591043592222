import classnames from 'classnames';
import React, { CSSProperties, FC, FocusEvent } from 'react';
import NumberFormat from 'react-number-format';
import { AllowedMasks } from 'types/Input';

import styles from './styles.module.scss';

export type NumberMaskedFieldProps = {
  format?: AllowedMasks | string | ((value: string) => string);
  suffix?: string;
  customStyles?: CSSProperties;
  onChange?: (e: number) => void;
  placeholder?: string;
  value?: string | number | null;
  required?: boolean;
  type?: 'text' | 'number';
  error?: 'error' | 'warning';
  min?: number;
  max?: number;
  disabled?: boolean;
  readOnly?: boolean;
  allowNegative?: boolean;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: () => void;
  onKeyDown?: () => void;
  componentClassNames?: string;
  decimalScale?: number;
};

export const NumberMaskedInput: FC<NumberMaskedFieldProps> = (props) => {
  const {
    format,
    disabled,
    onChange,
    onFocus,
    onBlur,
    value,
    readOnly,
    error,
    placeholder,
    required,
    allowNegative = true,
    onKeyUp,
    onKeyDown,
    customStyles,
    suffix,
    maxLength,
    componentClassNames,
    decimalScale,
  } = props;

  return (
    <NumberFormat
      suffix={suffix}
      format={format}
      className={classnames(
        styles.input,
        {
          [styles[`input_${error}`]]: error,
        },
        componentClassNames,
      )}
      placeholder={placeholder}
      required={required}
      onValueChange={(values) => {
        if (onChange) onChange(values.floatValue as number);
      }}
      allowNegative={allowNegative}
      value={value}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      disabled={disabled}
      readOnly={readOnly}
      maxLength={maxLength}
      style={customStyles}
      spellCheck="false"
      decimalScale={decimalScale}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.key === 'Enter' && e.preventDefault();
      }}
      allowedDecimalSeparators={[',']}
    />
  );
};
