import classNames from 'classnames';
import {
  selectActivePatient,
  selectAnamnes,
  selectLoadingAnamnesStatus,
  selectPatientLoadingStatus,
} from 'features/expert/selectors';
import {
  getAnamnesByIdAsync,
  getNutritionPlansAsync,
  getPatientByIdAsync,
  getPatientDiaryByPatientIdAsync,
} from 'features/expert/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useAppDispatch } from 'store/reducers';
import { ExpertRoutes } from 'types/routes';
import { LinkBack, Spinner } from 'UIcomponents';

import { LoadingStatus } from '../../types';
import {
  EmptyAnamnesTab,
  PatientInfoTab,
  PatientNutritionPlansTab,
  PatientProfilePatientDiaryTab,
  Tab,
  TabsWrapper,
  TopActionBar,
} from './components';
import { RecommendationTab } from './components/Tabs/';
import { AnalysisHistoryTab } from './components/Tabs/AnalysisHistoryTab';
import { AnamnesisReadonlyTab } from './components/Tabs/AnamnesisReadonlyTab';
import styles from './styles.module.scss';

interface LocationProps {
  id: string;
}

export const PatientProfile: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile' });
  const params = useParams<LocationProps>();
  const width = useWindowWidth();
  const patient = useSelector(selectActivePatient);
  const loadingStatus = useSelector(selectPatientLoadingStatus);
  const anamnesLoadingStatus = useSelector(selectLoadingAnamnesStatus);
  const dispatch = useAppDispatch();
  const anamnes = useSelector(selectAnamnes);

  useEffect(() => {
    batch(() => {
      dispatch(getPatientByIdAsync(params.id));
      dispatch(getNutritionPlansAsync(params.id)).catch((error) => {
        console.warn(error);
      });
      dispatch(getAnamnesByIdAsync(params.id));
      dispatch(getPatientDiaryByPatientIdAsync(params.id));
    });
  }, []);

  const anamnesNode = useMemo(() => {
    const isAnamnesLoading = anamnesLoadingStatus === LoadingStatus.pending || loadingStatus === LoadingStatus.pending;
    const isAnamnesEmpty = Object.keys(anamnes).length === 0;

    return (
      <>
        {isAnamnesLoading ? (
          <>
            <h3 className={classNames(styles['loading__title'])}>{t('patientPersonalData')}</h3>
            <div className={styles['loading__wrapper']}>
              <Spinner radius={20} variant="secondary" color="var(--background-primary)" />
            </div>
          </>
        ) : (
          <> {isAnamnesEmpty ? <EmptyAnamnesTab /> : <AnamnesisReadonlyTab anamnes={anamnes} />}</>
        )}
      </>
    );
  }, [anamnes, anamnesLoadingStatus, loadingStatus]);

  return (
    <MainLayout>
      <TopActionBar title={t('title')} patientId={patient.id} patientName={patient.name} />
      <div className={classNames('flexbox', 'justify-between', 'align-start', styles['patient-profile'])}>
        {width <= 760 && <LinkBack pathname={ExpertRoutes.PATIENT_SETTINGS(patient.id)} />}
        <TabsWrapper id={params.id} patient={patient}>
          <Tab title={t('tabs.personalData')} tabName="patient-info-tab">
            {loadingStatus === LoadingStatus.fulfilled && (
              <PatientInfoTab patient={patient} isLoading={loadingStatus} />
            )}
          </Tab>
          <Tab title={t('tabs.indicators')} tabName="patient-recommendations" withoutBackground={true}>
            <RecommendationTab />
          </Tab>
          <Tab title={t('tabs.questionnaire')} tabName="patient-anamnesis-tab">
            {anamnesNode}
          </Tab>
          <Tab title={t('tabs.diary')} tabName="patient-diary-tab">
            <PatientProfilePatientDiaryTab />
          </Tab>
          <Tab title={t('tabs.nutritionPlan')} tabName="patient-nutrition-plan-tab">
            <PatientNutritionPlansTab />
          </Tab>
          <Tab title={t('tabs.analyzes')} tabName="patient-analyzes-tab">
            <AnalysisHistoryTab />
          </Tab>
        </TabsWrapper>
      </div>
    </MainLayout>
  );
};
