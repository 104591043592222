import * as React from 'react';

function SortIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.56 9.897a.361.361 0 00.239-.095L12 6.93l3.201 2.87c.065.06.154.094.247.094a.347.347 0 00.32-.193.284.284 0 00-.08-.337l-3.444-3.09a.373.373 0 00-.487 0l-3.445 3.09a.287.287 0 00-.08.341.351.351 0 00.328.19z"
        fill="#8E8E8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.566 10.082h-.002a.56.56 0 01-.522-.304.46.46 0 01.125-.545l3.444-3.088a.597.597 0 01.779 0l3.443 3.088c.161.14.211.353.126.54a.556.556 0 01-.511.307.581.581 0 01-.394-.148L12 7.193 8.947 9.931a.58.58 0 01-.381.151zm.233-.28L12 6.93l3.201 2.87c.065.06.154.094.247.094a.347.347 0 00.32-.193.284.284 0 00-.08-.337l-3.444-3.09a.373.373 0 00-.487 0l-3.445 3.09a.287.287 0 00-.08.341.351.351 0 00.328.19.361.361 0 00.239-.094z"
        fill="#8E8E8E"
      />
      <path
        d="M8.56 14.103a.36.36 0 01.239.095L12 17.07l3.201-2.87a.362.362 0 01.247-.094c.141 0 .266.077.32.192a.285.285 0 01-.08.338l-3.444 3.09a.373.373 0 01-.487 0l-3.445-3.09a.287.287 0 01-.08-.341.351.351 0 01.328-.19z"
        fill="#8E8E8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.566 13.918h-.002a.56.56 0 00-.522.304.46.46 0 00.125.545l3.444 3.088a.597.597 0 00.779 0l3.443-3.088c.161-.14.211-.353.126-.54a.556.556 0 00-.511-.307.581.581 0 00-.394.148L12 16.807l-3.053-2.738a.58.58 0 00-.381-.151zm.233.28L12 17.07l3.201-2.87a.362.362 0 01.247-.094c.141 0 .266.077.32.192a.285.285 0 01-.08.338l-3.444 3.09a.373.373 0 01-.487 0l-3.445-3.09a.287.287 0 01-.08-.341.351.351 0 01.328-.19.36.36 0 01.239.095z"
        fill="#8E8E8E"
      />
    </svg>
  );
}

export default SortIcon;
