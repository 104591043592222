import { UpdatePatientDtoGenderEnum } from 'api/generated';
import classNames from 'classnames';
import { TermsAndConditions } from 'components';
import { DeleteModal } from 'components/DeleteModal';
import { RolesEnum } from 'features/auth/types';
import { selectPatientData } from 'features/patient/selectors';
import { deletePatientAsync, updatePatientAsync } from 'features/patient/thunks';
import { DownloadProfilePicture } from 'pages/ExpertProfile/components/Tabs/ExpertProfileTab/DownloadProfilePicture';
import React, { FC, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notify } from 'services/notificationService';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { MESSAGES } from 'types/messages';
import {
  ButtonDefault,
  DatePickerDropdown,
  DropdownDefault,
  FormError,
  FormLabel,
  InputDefault,
  NumberMaskedInput,
} from 'UIcomponents';
import { OptionProps } from 'UIcomponents/DropdownDefault';
import { hasNumbers, hasOnlyLettersAndSpaces } from 'utils/formsValidators';
import { trimString } from 'utils/textHelper';
import { outOfSideDate } from 'utils/time';

import styles from './styles.module.scss';

interface FormValues {
  name: string;
  birthday: Date;
  gender: OptionProps<string>;
  weight: number;
  height: number;
  email: string;
  avatar: string;
}

export const UserInfoTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.userInfoTab' });

  const isUpdating = false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const patient = useSelector(selectPatientData);

  const genderOptions: OptionProps<string>[] = [
    { label: t('male'), value: 'male' },
    { label: t('female'), value: 'female' },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      name: patient.name,
      birthday: new Date(patient.birthday || new Date()),
      gender: {
        label: patient.gender === 'male' ? genderOptions[0].label : genderOptions[1].label,
        value: patient.gender,
      },
      weight: patient.weight as number,
      height: patient.height as number,
      email: patient.email,
      avatar: patient.avatar,
    },
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { isDirty } = useFormState({ control });

  const onSubmit = (data: FormValues) => {
    dispatch(
      updatePatientAsync({
        name: data.name,
        weight: data.weight,
        height: data.height,
        birthday: data.birthday.toISOString(),
        gender: data.gender?.value as UpdatePatientDtoGenderEnum,
        avatar: data.avatar,
      }),
    )
      .then(() => {
        notify('success', t('notifySuccess'));
        reset({
          name: data.name,
          gender: {
            label: data.gender.value === 'male' ? (t('male') as string) : (t('female') as string),
            value: data.gender.value,
          },
          weight: data.weight,
          height: data.height,
          birthday: new Date(data.birthday),
          avatar: data.avatar,
          email: patient.email,
        });
      })
      .catch(() => {
        notify('error', MESSAGES.error);
      });
  };

  const deleteMe = () => {
    dispatch(deletePatientAsync({}))
      .then(toggleModal)
      .then(() => dispatch(actions.authActions.signOut()));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <DownloadProfilePicture role={RolesEnum.PATIENT} setValue={setValue} />
      <div className={classNames('flexbox', 'justify-between', styles['user-info-form'])}>
        <h4 className={styles['user-info-form__title']}>{t('title')}</h4>
        <div className={classNames(styles['user-info-form__input'])}>
          <FormLabel text={t('labelFullName')} />

          <Controller
            name="name"
            rules={{
              required: t('required') as string,
              validate: (value) => {
                if (!value.trim()) {
                  return t('required') as string;
                }
                if (hasNumbers(value)) {
                  return t('validateHasNumbers') as string;
                }
                if (!hasOnlyLettersAndSpaces(value)) {
                  return t('validateHasOnlyLettersAndSpaces') as string;
                }
              },
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                placeholder={t('labelFullName') as string}
                maxLength={40}
                onBlur={() => onChange(trimString(value))}
                error={errors.name && 'error'}
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.name?.message && <FormError type="error" text={errors.name.message} />}
        </div>

        <div className={classNames(styles['user-info-form__input'])}>
          <FormLabel text={t('birthday')} />

          <Controller
            name="birthday"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePickerDropdown
                icon="calendar"
                error={errors.birthday && 'error'}
                setSelectedDay={onChange}
                selectedDay={value}
                variant="max-content"
                bigHeightMobile
                disabledDays={{ after: outOfSideDate(18) }}
              />
            )}
          />
          {errors.birthday?.message && <FormError type="error" text={errors.birthday.message} />}
        </div>

        <div className={classNames(styles['user-info-form__input'])}>
          <FormLabel text={t('gender')} />

          <Controller
            name="gender"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <DropdownDefault
                  error={errors.gender && 'error'}
                  onChange={onChange}
                  noOptionsMessage=""
                  selected={value.value === 'male' ? genderOptions[0] : genderOptions[1]}
                  isSearchable={false}
                  options={genderOptions}
                />
              );
            }}
          />
        </div>

        <div className={classNames(styles['user-info-form__input'])}>
          <FormLabel text={t('height')} />
          <Controller
            name="height"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberMaskedInput
                placeholder={t('heightPlaceholder') as string}
                error={errors.height && 'error'}
                maxLength={6}
                suffix={t('suffix') as string}
                decimalScale={0}
                allowNegative={false}
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.height?.message && <FormError type="error" text={errors.height.message} />}
        </div>

        <div className={classNames(styles['user-info-form__input'])}>
          <FormLabel text={t('weight') as string} />
          <Controller
            name="weight"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberMaskedInput
                placeholder={t('placeholderWeight') as string}
                error={errors.weight && 'error'}
                maxLength={6}
                suffix={t('suffixWeight') as string}
                decimalScale={0}
                allowNegative={false}
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.weight?.message && <FormError type="error" text={errors.weight.message} />}
        </div>
      </div>

      <div className={classNames('flexbox', 'justify-between', styles['user-info-form__buttons'])}>
        <ButtonDefault
          text={t('deleteAcc')}
          variant="secondary"
          onClick={toggleModal}
          containerClassNames={styles['user-info-form__remove-button']}
          customStyles={{ width: '200px', margin: 0 }}
        />
        <ButtonDefault
          type="submit"
          text={t('update')}
          isDisabled={isUpdating || !isDirty}
          isLoading={isUpdating}
          customStyles={{ width: '200px', margin: 0 }}
        />
      </div>
      <hr className={styles.separator} />
      <TermsAndConditions />

      {isModalOpen && (
        <DeleteModal
          onClose={toggleModal}
          onDelete={deleteMe}
          title={t('deleteModalTitle') as string}
          subtitle={t('deleteModalContent') as string}
          isVisible={true}
        />
      )}
    </form>
  );
};
