import { unwrapResult } from '@reduxjs/toolkit';
import { ArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { DeleteModal } from 'components/DeleteModal';
import { selectPatientId } from 'features/expert/selectors';
import {
  getAnamnesByIdAsync,
  getNutritionPlansAsync,
  getPatientByIdAsync,
  getPatientDiaryByPatientIdAsync,
  stopCooperationWithPatient,
} from 'features/expert/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { ExpertRoutes } from 'types/routes';

import styles from './styles.module.scss';

interface LocationProps {
  id: string;
}

export const PatientSettings: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile' });

  const width = useWindowWidth();
  const history = useHistory();
  const patientId = useSelector(selectPatientId);
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const handleDeleteAccount = () => {
    setModalOpened(true);
  };

  const toggleDeleteModal = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const dispatch = useAppDispatch();
  const handleSignOut = () => {
    dispatch(actions.authActions.signOut());
  };

  const params = useParams<LocationProps>();

  useEffect(() => {
    batch(() => {
      dispatch(getPatientByIdAsync(params.id));
      dispatch(getNutritionPlansAsync(params.id)).catch((error) => {
        console.warn(error);
      });
      dispatch(getAnamnesByIdAsync(params.id));
      dispatch(getPatientDiaryByPatientIdAsync(params.id));
    });
  }, []);

  if (width > 760) {
    return <Redirect to={{ pathname: '/patient-profile', state: patientId }} />;
  }

  const handelStopCooperationClick = () => {
    dispatch(stopCooperationWithPatient(patientId))
      .then(unwrapResult)
      .then(() => {
        history.push('/dashboard');
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  return (
    <MainLayout>
      <div className={classNames(styles['patient-profile'])}>
        <h4 className={styles['patient-profile__title']}>{t('title')}</h4>

        <div className={classNames(styles['patient-profile__links'])}>
          <Link
            to={ExpertRoutes.PATIENT_PROFILE(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.personalData')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>

          <Link
            to={ExpertRoutes.PATIENT_RECOMMENDATIONS(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.indicators')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>

          <Link
            to={ExpertRoutes.PATIENT_ANAMNESIS(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.questionnaire')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>

          <Link
            to={ExpertRoutes.PATIENT_NUTRITION_DIARY(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.diary')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>

          <Link
            to={ExpertRoutes.PATIENT_NUTRITION_PLAN(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.nutritionPlan')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>

          <Link
            to={ExpertRoutes.PATIENT_ANALYZES(patientId)}
            className={classNames('body', 'body_medium', styles['patient-profile__link'])}>
            <p className={styles['patient-profile__link-text']}>{t('tabs.analyzes')}</p>
            <ArrowIcon fill="var(--dark-grey)" />
          </Link>
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={toggleDeleteModal}
          onKeyDown={handleDeleteAccount}
          className={styles['patient-profile__delete-btn']}>
          <p className={classNames('body', 'body_medium')}>{t('topActionBar.stopCooperation')}</p>
        </div>
      </div>
      {isModalDeleteOpen && (
        <DeleteModal
          onClose={toggleDeleteModal}
          onDelete={handelStopCooperationClick}
          title={t('topActionBar.stopCooperation') as string}
          subtitle={t('topActionBar.stopCooperationModalContent') as string}
          isVisible={true}
        />
      )}
    </MainLayout>
  );
};
