import { unwrapResult } from '@reduxjs/toolkit';
import { HandIcon, PdfIcon, PictureIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { findAllBiomarkersAsync } from 'features/biomarkers/thunks';
import {
  parseImageAsync,
  parseImageExpertAsync,
  parsePdfExpertAsync,
  parsePdfOpenaiAsync,
} from 'features/default/thunks';
import { selectActivePatient } from 'features/expert/selectors';
import { selectDemoMode, selectParsingAnalyzesCounter, selectPatientExpert } from 'features/patient/selectors';
import { selectGetSubscriptionLoadingState } from 'features/subscriptions/selectors';
import { useUserAccess } from 'hooks/useUserAccess';
import { LayoutWithBackOptions } from 'layouts';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes } from 'types/routes';
import { Waiting } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import { Disclaimer } from './components/Disclaimer';
import { SubscribeBanner } from './components/SubscribeBanner';
import styles from './styles.module.scss';

export const NewAnalysis: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'newAnalysis' });

  const history = useHistory();
  const hasAccess = useUserAccess();
  const dispatch = useAppDispatch();

  const [waitingPdfParse, setWaitingPdfParse] = useState(false);
  const [waitingImageParse, setWaitingImageParse] = useState(false);

  const biomarkers = useSelector(selectBiomarkers);
  const isSubscriptionLoading = useAppSelector(selectGetSubscriptionLoadingState) === LoadingStatus.pending;
  const parsingAnalyzesCounterImage = useAppSelector(selectParsingAnalyzesCounter)?.image.current;
  const parsingAnalyzesCounterPdf = useAppSelector(selectParsingAnalyzesCounter)?.pdf.current;
  const patientCooperationExpert = useAppSelector(selectPatientExpert);
  const toCreateAnalysis = useAppSelector(selectDemoMode)?.toCreateAnalysis;

  const numberCreateAnalysis = toCreateAnalysis === 0 || toCreateAnalysis === undefined ? '1' : '0';
  const hasPatientFreeAttemptCreateAnalyzes = !(toCreateAnalysis === 0);
  const hasPatientCooperateWithExpert = !!patientCooperationExpert;
  const hasSubscriptionBanner = !hasAccess && !isSubscriptionLoading && !hasPatientCooperateWithExpert;
  const isDemoMode = !hasAccess && !hasPatientCooperateWithExpert;
  const numberImages = isDemoMode ? numberCreateAnalysis : parsingAnalyzesCounterImage;
  const numberPdf = isDemoMode ? numberCreateAnalysis : parsingAnalyzesCounterPdf;
  const numberManual = isDemoMode && numberCreateAnalysis;
  const limitToLoadAnalysis = isDemoMode ? '1' : '10';

  const [numberOfPdf, setCountOfPdf] = useState<number | null | '0' | '1'>(0);

  useEffect(() => {
    const countOfPdf = isDemoMode ? numberCreateAnalysis : parsingAnalyzesCounterPdf;
    setCountOfPdf(countOfPdf);
  }, [numberPdf]);

  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;
  const activePatientData = useSelector(selectActivePatient);
  const isPatientData = activePatientData?.parsingAnalyzesCounter?.pdf?.current >= 0 ? true : false;

  const getBiomarkers = async () => {
    await dispatch(findAllBiomarkersAsync())
      .then(unwrapResult)
      .catch((error) => {
        console.warn({ error });
      });
  };

  const handleImportManually = () => {
    history.push(CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY);

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.ENTER_MANUAL_ANALYSIS_PRESSED);
  };

  const setWaiting = (type: 'pdf' | 'image', status: boolean) => {
    type === 'image' ? setWaitingImageParse(status) : setWaitingPdfParse(status);
  };

  const parsePdfFile = async (file: File, type: 'pdf' | 'image') => {
    setWaiting(type, true);
    if (isPatient) {
      await dispatch(parsePdfOpenaiAsync(file))
        .then(unwrapResult)
        .then((res) => {
          const result = res.map((item) => ({
            biomarkerId: item.id,
            value: item.value,
          }));

          if (!result || result.length === 0) {
            notify('error', t('unableToDecryptFile'));
            setCountOfPdf((prev) => {
              return typeof prev === 'number' ? prev + 1 : prev;
            });
            return;
          }

          history.push(CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY, {
            parsedBiomarkers: result,
          });
        })
        .catch((error) => {
          console.warn({ error });
          if (error.statusCode) {
            notify('error', error.message);
          }
        })
        .finally(() => setWaiting(type, false));
    } else {
      await dispatch(parsePdfExpertAsync({ id: activePatientData.id, file: file }))
        .then(unwrapResult)
        .then((res) => {
          const getBiomarkerId = (marker: string) => {
            return biomarkers.find((item) => item.name === marker)?.id as string;
          };

          const result = res.map((item) => {
            return { biomarkerId: getBiomarkerId(item.biomarkerName as string), value: item.value };
          });

          if (!result || result.length === 0) {
            notify('error', t('unableToDecryptFile'));
            setCountOfPdf((prev) => {
              return typeof prev === 'number' ? prev + 1 : prev;
            });
            return;
          }

          history.push(CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY, {
            parsedBiomarkers: result,
          });
        })
        .catch((error) => {
          console.warn({ error });
          if (error.statusCode) {
            notify('error', error.message);
          }
        })
        .finally(() => setWaiting(type, false));
    }
  };

  const parseImageFile = async (file: File, type: 'pdf' | 'image') => {
    setWaiting(type, true);
    if (isPatient) {
      await dispatch(parseImageAsync(file))
        .then(unwrapResult)
        .then((res) => {
          const getBiomarkerId = (marker: string) => {
            return biomarkers.find((item) => item.name === marker)?.id as string;
          };

          const result = res.map((item) => {
            return { biomarkerId: getBiomarkerId(item.biomarkerName as string), value: item.value };
          });

          if (!result || result.length === 0) {
            notify('error', t('unableToDecryptFile'));

            return;
          }

          history.push(CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY, {
            parsedBiomarkers: result,
          });
        })
        .catch((error) => {
          console.warn({ error });
          if (error.statusCode) {
            notify('error', error.message);
          }
        })
        .finally(() => setWaiting(type, false));
    } else {
      await dispatch(parseImageExpertAsync({ id: activePatientData.id, file: file }))
        .then(unwrapResult)
        .then((res) => {
          const getBiomarkerId = (marker: string) => {
            return biomarkers.find((item) => item.name === marker)?.id as string;
          };

          const result = res.map((item) => {
            return { biomarkerId: getBiomarkerId(item.biomarkerName as string), value: item.value };
          });

          if (!result || result.length === 0) {
            notify('error', t('buySubscription'));
            return;
          }
          history.push(CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY, {
            parsedBiomarkers: result,
          });
        })
        .catch((error) => {
          console.warn({ error });
          if (error.statusCode) {
            notify('error', error.message);
          }
        })
        .finally(() => setWaiting(type, false));
    }
  };

  const handleChangePdf = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      parsePdfFile(file, 'pdf');
    }
    e.target.value = '';

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.ENTER_PDF_ANALYSIS_PRESSED);
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      parseImageFile(file, 'image');
    }
    e.target.value = '';

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.ENTER_IMAGE_ANALYSIS_PRESSED);
  };

  useEffect(() => {
    if (biomarkers.length < 0) {
      getBiomarkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isPatientData && !isPatient) {
    history.push('/dashboard');
  }

  return (
    <>
      <LayoutWithBackOptions backOptions={{ text: t('addNewAnalyzes'), url: CommonRoutes.DASHBOARD }}>
        <p className={`${styles.subtitle} body body_medium`}>{t('selectMethod')}</p>
        <div className={styles['cards-container']}>
          <button className={styles.card} onClick={handleImportManually}>
            <div className={styles['card__icon']}>
              <HandIcon />
            </div>
            <div className={styles['card__info']}>
              <h5 className={classNames(styles['force-wrap'], styles['card__title'])}>{t('manualInput')}</h5>
              {isDemoMode && (
                <p className={styles.card__text_grey}>
                  {t('loaded')}: {numberManual}/{limitToLoadAnalysis}
                </p>
              )}
            </div>
          </button>

          <div className={styles.card}>
            {waitingPdfParse ? (
              <Waiting spinnerColor="var(--background-primary)" spinnerRadius={30} />
            ) : (
              <>
                <input type="file" className={styles.card__input} accept="application/pdf" onChange={handleChangePdf} />
                <div className={styles['card__icon']}>
                  <PdfIcon />
                </div>
                <div className={styles['card__info']}>
                  <h5 className={classNames(styles['card__title'], styles['card__title'])}>{t('importPDF')}</h5>
                  {isPatient && (
                    <p className={styles.card__text_grey}>
                      {t('loaded')}: {isPatient ? numberOfPdf : activePatientData.parsingAnalyzesCounter?.pdf.current}/
                      {limitToLoadAnalysis}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles.card}>
            {waitingImageParse ? (
              <Waiting spinnerColor="var(--background-primary)" spinnerRadius={30} />
            ) : (
              <>
                <input type="file" className={styles.card__input} accept="image/*" onChange={handleChangeImage} />
                <div className={styles['card__icon']}>
                  <PictureIcon />
                </div>
                <div className={styles['card__info']}>
                  <h5 className={classNames(styles['card__title'])}>{t('importPhoto')}</h5>
                  {isPatient && (
                    <p className={styles.card__text_grey}>
                      {t('loaded')}:{' '}
                      {isPatient ? numberImages : activePatientData.parsingAnalyzesCounter?.image.current}/
                      {limitToLoadAnalysis}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <Disclaimer
          isDemoMode={isDemoMode}
          hasAccess={hasAccess}
          hasPatientCooperateWithExpert={hasPatientCooperateWithExpert}
          hasPatientFreeAttemptCreateAnalyzes={hasPatientFreeAttemptCreateAnalyzes}
        />
        <div className={styles.banner}>{hasSubscriptionBanner && <SubscribeBanner />}</div>
      </LayoutWithBackOptions>
    </>
  );
};
