import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { SubscriptionStore } from './types';

export const selectSubscriptionsStore = (state: RootState): SubscriptionStore => state.subscriptions;

export const selectSubscription = createSelector(selectSubscriptionsStore, (subscription) => subscription.subscription);

export const selectSubscriptionsConstants = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.subscriptionsConstants,
);

export const selectGetSubscriptionsConstantsState = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.getSubscriptionsConstantsLoading,
);

export const selectGetSubscriptionLoadingState = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.getSubscriptionLoading,
);

export const selectUpdateSubscriptionLoadingStatus = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.updateSubscriptionLoading,
);

export const selectCancelSubscriptionLoadingStatus = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.cancelSubscriptionLoading,
);

export const selectRenewSubscriptionLoadingStatus = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.renewSubscriptionLoading,
);

export const selectStripeCustomerPortalLink = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.customerPortalLink,
);

export const selectGetSessionLinkLoading = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.getSessionLinkLoading,
);

export const selectGetCustomerPortalLinkLoading = createSelector(
  selectSubscriptionsStore,
  (subscription) => subscription.getCustomerPortalLinkLoading,
);
