import * as React from 'react';

function SvgHand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#hand_svg__clip0)" fill="#262626">
        <path d="M2.825 17.113l-.66.942a.7.7 0 00-.098.203l-.634 2.132a.702.702 0 001.093.765l1.777-1.319a3.504 3.504 0 01-1.478-2.723zM23.297 10.641h-2.11v10.547c0 .523.55.863 1.018.629l1.51-1.12a.703.703 0 00.285-.565v-8.788a.703.703 0 00-.703-.703zM14.036 2.33a.703.703 0 00-.98.173l-1.21 1.728 2.304 1.614 1.21-1.728a.703.703 0 00-.172-.98l-1.152-.806zM5.259 13.637a3.47 3.47 0 011.067-.183h2.495l4.521-6.458-2.303-1.613-5.78 8.254zM5.037 7.715a2.1 2.1 0 00-.94.804L.22 14.62a2.106 2.106 0 00.754 2.71c.016-.025.021-.055.039-.08L8.573 6.454c-.282-.017-.57 0-.861.099L5.037 7.715zM5.624 20.415v.422a1.055 1.055 0 002.11 0v-.351H6.326c-.24 0-.476-.025-.703-.071zM9.14 20.486v.351c0 .583.472 1.055 1.054 1.055.516 0 .926-.378 1.017-.867-.229-.095-.462-.18-.684-.29l-.487-.25h-.9z" />
        <path d="M19.78 10.642h-1.29l-4.468-2.164-3.44 4.914a4.115 4.115 0 002.28-1.138.703.703 0 01.994.994 5.544 5.544 0 01-3.893 1.613H6.327c-1.127 0-2.11.914-2.11 2.11 0 1.167.943 2.109 2.11 2.109h4.05c.338.132 2.216 1.406 5.091 1.406 1.455 0 3.002-.34 4.313-.97v-8.874z" />
      </g>
      <defs>
        <clipPath id="hand_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgHand;
