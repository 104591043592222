import { ChangeEvent, FC } from 'react';
import { TextArea } from 'UIcomponents';

export const Diet: FC<{
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  disabled: boolean;
}> = ({ onChange, value, disabled }) => {
  return (
    <div>
      <TextArea
        value={value}
        disabled={disabled}
        onChange={onChange}
        customStyles={{
          maxWidth: '360px',
          marginBottom: ' 20px',
          letterSpacing: '-0.01em',
        }}></TextArea>
    </div>
  );
};
