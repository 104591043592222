import { unwrapResult } from '@reduxjs/toolkit';
import defaultCheckupImageLink from 'assets/images/defaultCheckupImageLink.png';
import classNames from 'classnames';
import { SetPatientCheckupModal } from 'components/SetPatientCheckupModal';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectPatientId } from 'features/expert/selectors';
import { expertSetCheckUpAsync } from 'features/expert/thunks';
import { useToggle } from 'hooks';
import { PatientDropdownProps } from 'pages/CheckUps/CheckUps';
import { FC, useState } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type CheckUpCardProps = {
  checkupId: string;
  title: string;
  link: string;
  img?: string;
  patients?: PatientDropdownProps[];
  isAssignCheckUpFromPatientProfile?: boolean;
};

export const CheckUpCard: FC<CheckUpCardProps> = ({
  checkupId,
  title,
  link,
  img,
  patients,
  isAssignCheckUpFromPatientProfile = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const [isSetCheckUpLoading, setCheckUpLoading] = useState(false);
  const { isOpened: isOpenSetPatientCheckup, onToggle: toggleSetPatientCheckup } = useToggle();

  const role = useAppSelector(selectRole);
  const patientId = useAppSelector(selectPatientId);

  const isExpert = role === RolesEnum.EXPERT;
  const isPatientCheckupModal = isOpenSetPatientCheckup && !!patients;
  const isAssignCheckUpDisable = isAssignCheckUpFromPatientProfile && !patientId;

  const handleReadMoreClick = () => {
    push(link);
  };

  const assignCheckUpForPatient = () => {
    setCheckUpLoading(true);
    dispatch(expertSetCheckUpAsync({ checkupId: checkupId, id: patientId }))
      .then(unwrapResult)
      .then(() => {
        setCheckUpLoading(false);
        notify('success', t('analysisAssign'));
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const handleSetPatientCheckup = () => {
    isAssignCheckUpFromPatientProfile ? assignCheckUpForPatient() : toggleSetPatientCheckup();
  };

  return (
    <div className={styles.card}>
      <img className={styles.card__image} alt="img" src={img || defaultCheckupImageLink} />
      <Dotdotdot tagName="h5" clamp={2} className={styles.card__title}>
        {title}
      </Dotdotdot>
      <ButtonDefault
        text={t('detailed')}
        variant="secondary"
        containerClassNames={styles.card__button}
        onClick={handleReadMoreClick}
      />
      {isExpert && (
        <ButtonDefault
          text={t('assign')}
          variant="primary"
          containerClassNames={classNames(styles.card__button, styles.card__send_button)}
          onClick={handleSetPatientCheckup}
          isDisabled={isAssignCheckUpDisable}
          isLoading={isSetCheckUpLoading}
        />
      )}
      {isPatientCheckupModal && (
        <SetPatientCheckupModal
          checkupId={checkupId}
          isModalOpened={isOpenSetPatientCheckup}
          onCloseModal={toggleSetPatientCheckup}
          patientsDropdown={patients}
        />
      )}
    </div>
  );
};
