import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertResDto } from 'api/generated';
import { actions } from 'features/patient/slice';
import {
  cancelCooperationRequestWithExpert,
  getOneExpert,
  sendCooperationRequestWithExpert,
} from 'features/patient/thunks';
import { useClientSize } from 'hooks';
import { FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

type CooperationButtonProps = {
  expertId: string;
  isMyExpert: boolean | null;
  isRequestInPending: boolean;
  handleCancelCooperationClickCallBack: () => void;
  experts?: ExpertResDto[];
  setExperts?: React.Dispatch<SetStateAction<ExpertResDto[]>>;
  index?: number;
};

enum CooperationStateEnum {
  hasAttemptCooperation,
  hasSendRequest,
  hasCancelCooperation,
  empty,
}

export const CooperationButton: FC<CooperationButtonProps> = ({
  expertId,
  isMyExpert,
  isRequestInPending,
  handleCancelCooperationClickCallBack,
  experts,
  setExperts,
  index,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'cooperationButton' });

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const dispatch = useAppDispatch();

  const [isSentRequestLoading, setIsSentRequestLoading] = useState(false);
  const [isSentRequest, setSentRequest] = useState<boolean>(isRequestInPending);

  const getExpertInfo = async () => {
    await dispatch(getOneExpert(expertId))
      .then(unwrapResult)
      .then((result) => {
        dispatch(actions.updateExpertsCooperationsStatus(result));
        setSentRequest(result.requestInPending);
      })
      .catch((error) => {
        console.warn({ error });
      });
  };

  const handleSendRequestClick = async () => {
    setIsSentRequestLoading(true);
    await dispatch(sendCooperationRequestWithExpert(expertId))
      .then(() => {
        notify('success', t('notifySuccess'));
      })
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => setIsSentRequestLoading(false));
    getExpertInfo();

    const NewExpertsArray =
      experts &&
      experts.map((expert, NewExpertsArrayIndex) =>
        NewExpertsArrayIndex === index ? { ...experts[index], requestInPending: true } : expert,
      );

    setExperts && NewExpertsArray && setExperts(NewExpertsArray);

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.COOPERATE_EXPERT_BUTTON_PRESSED);
  };

  const handleCancelRequestClick = async () => {
    setIsSentRequestLoading(true);
    await dispatch(cancelCooperationRequestWithExpert(expertId))
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => setIsSentRequestLoading(false));
    getExpertInfo();

    const NewExpertsArray =
      experts &&
      experts.map((expert, NewExpertsArrayIndex) =>
        NewExpertsArrayIndex === index ? { ...experts[index], requestInPending: false } : expert,
      );

    setExperts && NewExpertsArray && setExperts(NewExpertsArray);
  };

  const myExpertCooperaionState = isMyExpert
    ? CooperationStateEnum.hasCancelCooperation
    : CooperationStateEnum.hasAttemptCooperation;

  const cooperationState = isSentRequest ? CooperationStateEnum.hasSendRequest : myExpertCooperaionState;

  const buttonCooperationMapping = {
    [CooperationStateEnum.hasAttemptCooperation]: (
      <ButtonDefault
        type="submit"
        text={t('cooperate')}
        variant="primary"
        customStyles={{
          maxWidth: '100%',
          padding: isMobile ? '12px 16px' : '16px 32px',
          fontSize: '16px',
          lineHeight: '20px',
        }}
        isLoading={isSentRequestLoading}
        onClick={handleSendRequestClick}>
        {t('cooperate')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.hasSendRequest]: (
      <ButtonDefault
        type="submit"
        text="Заявка отправлена"
        variant="message"
        isLoading={isSentRequestLoading}
        onClick={handleCancelRequestClick}
        customStyles={{
          maxWidth: '100%',
          padding: isMobile ? '12px 16px' : '16px 32px',
          fontSize: '16px',
          lineHeight: '20px',
        }}>
        {t('notifySuccess')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.hasCancelCooperation]: (
      <ButtonDefault
        type="submit"
        text={t('cancelCooperation')}
        variant="square"
        customStyles={{
          maxWidth: '100%',
          padding: isMobile ? '12px 16px' : '16px 32px',
          fontSize: '16px',
          lineHeight: '20px',
          color: 'var(--secondary-black)',
        }}
        isLoading={false}
        onClick={handleCancelCooperationClickCallBack}>
        {t('cancelCooperation')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.empty]: <></>,
  };

  const renderCooperationButton = (state: CooperationStateEnum) => {
    return buttonCooperationMapping[state];
  };

  return <>{renderCooperationButton(cooperationState)}</>;
};
