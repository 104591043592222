import { Instagram, LogoBiogeekExpert, LogoBiogeekPatient, TelegramIcon, Twitter, Youtube } from 'assets/svg';
import classNames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store/types';
import { SOCIALS } from 'types';
import { UserProfileCard } from 'UIcomponents';

import styles from './styles.module.scss';

export const HeaderOnboarding: FC = () => {
  const role = useSelector((state: RootState) => state.auth.role);

  const isPatient = role === RolesEnum.PATIENT;

  return (
    <header className={classNames('flexbox', 'justify-between', 'align-center', styles.header)}>
      <div className={styles.brand}>
        <div className={classNames('flexbox', styles.logo)}>
          <Link to="/">{isPatient ? <LogoBiogeekPatient /> : <LogoBiogeekExpert />}</Link>
        </div>
        <div className={styles.container}>
          <div className={styles.icons}>
            <Link to={{ pathname: SOCIALS.twitter }} target="_blank">
              <Twitter />
            </Link>
            <Link to={{ pathname: SOCIALS.instagram }} target="_blank">
              <Instagram />
            </Link>
            <Link to={{ pathname: SOCIALS.telegram }} target="_blank">
              <TelegramIcon />
            </Link>
            <Link to={{ pathname: SOCIALS.youtube }} target="_blank">
              <Youtube />
            </Link>
          </div>
          <UserProfileCard isActive={false} />
        </div>
      </div>
    </header>
  );
};
