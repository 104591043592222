import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputDefault, TextArea } from 'UIcomponents';

import { NutritionPlanFormValues } from '../NutritionPlanItem/NutritionPlanItem';
import styles from './styles.module.scss';

interface PlanHeaderProps {
  title: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<NutritionPlanFormValues, object>;
  isEditMode?: boolean;
}

export const PlanHeader: FC<PlanHeaderProps> = ({ text, title, isEditMode = false, control }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  return (
    <>
      {isEditMode ? (
        <div className={styles['autoimmune']}>
          {/* <h6 className={styles['autoimmune__title']}>{title}</h6> */}
          <Controller
            name={'title'}
            defaultValue={title}
            rules={{
              required: t('required') as string,
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                className={styles['autoimmune__title_edit']}
                value={value}
                onChange={onChange}
                disabled={!isEditMode}
                placeholder={t('patientNutritionName') as string}
              />
            )}
          />
          <Controller
            name={'description'}
            defaultValue={text}
            rules={{
              required: t('required') as string,
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                value={value}
                onChange={onChange}
                disabled={!isEditMode}
                placeholder={t('patientNutritionDescription') as string}
              />
            )}
          />
        </div>
      ) : (
        <div className={styles['autoimmune']}>
          <h6 className={styles['autoimmune__title']}>{title}</h6>
          <p className={styles['autoimmune__text']}>{text}</p>
        </div>
      )}
    </>
  );
};
