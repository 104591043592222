import { SearchIcon } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from '../../styles.module.scss';

export const EmptyAnalyzesHistory: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const { push } = useHistory();

  return (
    <div className={styles['aside-empty']}>
      <div className={styles['aside-empty__content']}>
        <SearchIcon className={styles['aside-empty__icon']} fill="var(--background-primary-light)" />
        <p className={`body body_medium ${styles['aside-empty__subtitle']}`}>{t('analisisEmpty')}</p>
        <ButtonDefault
          text={t('addNewAnalyzes')}
          onClick={() => push(CommonRoutes.NEW_ANALYSIS)}
          customStyles={{ width: 200 }}
        />
      </div>
    </div>
  );
};
