import { EditIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectPatientEmail } from 'features/patient/selectors';
import { useClientSize, useToggle } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';
import { ButtonDefault, FormLabel, InputDefault } from 'UIcomponents';

import styles from '../../styles.module.scss';
import { UpdatePatientEmailSteps } from './components/UpdatePatientEmailSteps/';

export const ChangeEmailSection: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.patientSettingsTab' });

  const {
    isOpened: isOpenUpdateEmailModal,
    onOpenToggle: onOpenUpdateEmailModal,
    onCloseToggle: onCloseUpdateEmailModal,
  } = useToggle();

  const activeEmail = useAppSelector(selectPatientEmail);

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <>
      <h4 className={styles['mail-address-section__title']}>{t('email')}</h4>
      <h5 className={styles['mail-address-section__mobile-subtitle']}>{t('changeEmail')}</h5>
      <FormLabel text={t('email')} />
      <div className={classNames('flexbox', 'justify-start', 'align-center', styles['mail-address-section__form'])}>
        <InputDefault disabled={true} value={activeEmail} customStyles={{ width: !isMobile ? '414px' : '262px' }} />
        <ButtonDefault
          containerClassNames={styles['mail-address-section__button']}
          onClick={onOpenUpdateEmailModal}
          text={!isMobile ? t('change') : undefined}
          variant="secondary"
          customStyles={{ backgroundColor: 'var(--light-grey)' }}>
          {isMobile && <EditIcon width={14} height={14} />}
        </ButtonDefault>
        <UpdatePatientEmailSteps modalOpened={isOpenUpdateEmailModal} onCloseModal={onCloseUpdateEmailModal} />
      </div>
    </>
  );
};
