import { EmptyAnamnes } from 'assets/svg';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const EmptyAnamnesTab = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.questionnaireTab' });

  return (
    <div className={styles.empty_tab}>
      <div className={styles.empty_container}>
        <EmptyAnamnes />
        <p className={styles.empty_header}>{t('emptyHeader')}</p>
        <p className={styles.empty_content}>{t('emptyContent')}</p>
      </div>
    </div>
  );
};
