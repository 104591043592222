import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import React, { FC } from 'react';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DysfunctionRecommendationCard, RecommendationCard } from 'UIcomponents';

SwiperCore.use([Navigation]);

import { PatientRecommendationCardsGetResDto } from 'api/generated';
import { ArrowIcon } from 'assets/svg';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { NavigationOptions } from 'swiper/types/components/navigation';

import styles from './../../styles.module.scss';

const options: SwiperOptions = {
  slidesPerView: 3,
  spaceBetween: 16,
  navigation: {
    disabledClass: styles['slider-navigation__item_disabled'],
    nextEl: null,
    prevEl: null,
  },
  breakpoints: {
    320: {
      slidesPerView: 1.2,
    },
    600: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 3,
    },
  },
};

interface RecommendationsSwiperListProps {
  recommendations: PatientRecommendationCardsGetResDto;
}

export const RecommendationsSwiperList: FC<RecommendationsSwiperListProps> = ({ recommendations }) => {
  const { firstTypeCards, secondTypeCards, thirdTypeCards } = recommendations;
  const navPrevButton = React.useRef<HTMLDivElement>(null);
  const navNextButton = React.useRef<HTMLButtonElement>(null);

  const onBeforeInit = (Swiper: SwiperCore): void => {
    const navigation = Swiper.params.navigation as NavigationOptions;
    navigation.prevEl = navPrevButton.current;
    navigation.nextEl = navNextButton.current;
  };

  return (
    <div className={styles['dashboard-recommendations__list']}>
      <div
        ref={navPrevButton}
        className={classnames(styles['slider-navigation__item'], styles['slider-navigation__item_prev'])}>
        <ArrowIcon className={classnames(styles['slider-navigation__icon'], styles['slider-navigation__icon_prev'])} />
      </div>
      <button
        ref={navNextButton}
        className={classnames(styles['slider-navigation__item'], styles['slider-navigation__item_next'])}>
        <ArrowIcon className={classnames(styles['slider-navigation__icon'], styles['slider-navigation__icon_next'])} />
      </button>
      <ReactTooltip id="main" className={styles.tooltip} arrowColor="transparent" place="right" />
      <Swiper onBeforeInit={onBeforeInit} {...options}>
        {secondTypeCards.map((secondTypeCard) => (
          <SwiperSlide key={secondTypeCard.secondTypeCardId}>
            {() => (
              <div className={styles['dashboard-recommendations__card']}>
                <RecommendationCard recommendation={secondTypeCard} type="blue" showTooltip />
              </div>
            )}
          </SwiperSlide>
        ))}
        {thirdTypeCards.map((thirdTypeCard) => (
          <SwiperSlide key={thirdTypeCard.thirdTypeCardId}>
            {() => (
              <div className={styles['dashboard-recommendations__card']}>
                <DysfunctionRecommendationCard
                  recommendation={thirdTypeCard}
                  showTooltip
                  score={thirdTypeCard.scale}
                  missingBiomarkers={thirdTypeCard.missingBiomarkers}
                  hideLinkText
                />
              </div>
            )}
          </SwiperSlide>
        ))}
        {firstTypeCards.map(
          (firstTypeCard) =>
            firstTypeCard && (
              <SwiperSlide key={firstTypeCard.firstTypeCardId}>
                {() => (
                  <div className={styles['dashboard-recommendations__card']}>
                    <RecommendationCard recommendation={firstTypeCard} type="light" showTooltip />
                  </div>
                )}
              </SwiperSlide>
            ),
        )}
      </Swiper>
    </div>
  );
};
