import { ExpertResDto } from 'api/generated';
import { Verified } from 'assets/svg';
import classNames from 'classnames';
import { useClientSize } from 'hooks';
import plural from 'plural-ru';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type ExpertProps = {
  expert: ExpertResDto;
  isDashboardCard?: boolean;
  onClickCallBack: () => void;
  isFullInfoModalOpened?: boolean;
};

const Expert: FC<ExpertProps> = ({
  expert,
  isDashboardCard = false,
  onClickCallBack,
  isFullInfoModalOpened,
  ...props
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'experts' });

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');
  const isVerifiedExpert = expert.isApproved;

  return (
    <div
      className={classNames(
        styles['expert__card'],
        isDashboardCard && styles['expert__card_dashboard'],
        isFullInfoModalOpened && styles['expert__card_info-modal-opened'],
      )}
      onClick={onClickCallBack}
      key={expert.id}
      role="button"
      tabIndex={0}
      onKeyUp={onClickCallBack}
      {...props}>
      <div className={styles['expert__wrapper']}>
        {!isMobile && (
          <div className={styles['expert__avatar']}>
            <img src={expert.avatar} alt={`${expert.name}`} />
          </div>
        )}
        <div className={styles['expert__content']}>
          <div className={styles['expert__content_row']}>
            {isMobile && (
              <div className={styles['expert__avatar']}>
                <img src={expert.avatar} alt={`${expert.name}`} />
              </div>
            )}
            <div className={styles['expert__content']}>
              <div className={styles['expert__header']}>
                <div className={styles['expert__username']}>{expert.name}</div>
                {isVerifiedExpert && <Verified data-tip data-for="verifiedTooltip" />}
              </div>
              <div className={styles['expert__info']}>
                {expert.age} {plural(expert.age, t('year'), t('years'), t('yearPlural'))}, {expert.spesialization}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expert;
