import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { ChatStore } from './types';

export const selectChatStore = (state: RootState): ChatStore => state.chat;

export const selectActiveChat = createSelector(selectChatStore, (chatStore) => chatStore.activeChat);

export const selectActiveChatId = createSelector(selectChatStore, (chatStore) => chatStore.activeChat.id);

export const selectMessages = createSelector(selectChatStore, (chatStore) => chatStore.messages);

export const selectLoadingMessage = createSelector(selectChatStore, (chatStore) => chatStore.loadingSendMessage);

export const selectLoadingGetMessage = createSelector(selectChatStore, (chatStore) => chatStore.loadingGetMessages);

export const selectExpertFromChat = createSelector(selectChatStore, (chatStore) => chatStore.expert);

export const selectAttachments = createSelector(selectChatStore, (chatStore) => chatStore.attachments);
