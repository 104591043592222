import * as React from 'react';

function ResponseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={15} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.968 10.768v3.225a.743.743 0 01-1.252.542L.656 7.91a.743.743 0 010-1.084L7.717.201a.743.743 0 011.252.542v3.272a10.952 10.952 0 019.615 8.248l.391 1.565a.945.945 0 01-1.71.743l.011.016a8.857 8.857 0 00-7.287-3.819h-1.02z"
        fill="#8E8E8E"
      />
    </svg>
  );
}

export default ResponseIcon;
