import { unwrapResult } from '@reduxjs/toolkit';
import { Analysis } from 'api/generated';
import { AnalyzesTabs } from 'components';
import {
  selectAnalysesStatus,
  selectAssignedCheckupsForPatients,
  selectAssignedCheckupsForPatientsStatus,
  selectPatientId,
} from 'features/expert/selectors';
import { getAnalyzesAsync, getAssignedCheckupsForPatiens } from 'features/expert/thunks';
import { useClientSize } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VirtuosoGrid } from 'react-virtuoso';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { AnalyzesNormFilter, SortDirectionFilter } from 'types/analyzes';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { AnalysisCard, AnalysisRequestCard, ButtonDefault, DropdownDefault, Spinner } from 'UIcomponents';

import { EmptyAnalyzesHistoryTab } from './components';
import styled from './styles.module.scss';

type PaginationState = {
  page: number;
  pageCount: number;
  total: number;
};

export const AnalysisHistoryTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.analysisHistoryTab' });

  const dispatch = useAppDispatch();

  const zoneOptions = [
    {
      label: t('allZone'),
      value: AnalyzesNormFilter.all,
    },
    {
      label: t('insideZoneNormal'),
      value: AnalyzesNormFilter.inNormal,
    },
    {
      label: t('outsideZoneNormal'),
      value: AnalyzesNormFilter.notInNormal,
    },
  ];

  const dateSortOptions = [
    {
      label: t('dateSortPlaceholder'),
      value: SortDirectionFilter.DESC,
    },
    {
      label: t('sortingOld'),
      value: SortDirectionFilter.ASC,
    },
  ];

  const patientId = useSelector(selectPatientId);
  const loadingStatus = useSelector(selectAnalysesStatus);

  const isAssignedCheckupsForPatientsLoading =
    useAppSelector(selectAssignedCheckupsForPatientsStatus) === LoadingStatus.pending;

  const expertsRequests = useAppSelector(selectAssignedCheckupsForPatients);

  const [dateSortOption, setDateSortOption] = useState(dateSortOptions[0]);
  const [isRequestsTabActive, setRequestsTabActive] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, pageCount: 0, total: 0 });
  const [zoneOption, setZoneOption] = useState(zoneOptions[0]);
  const [analyzes, setAnalyzes] = useState<Array<Analysis>>([]);

  const isLoading =
    (loadingStatus === LoadingStatus.pending && analyzes.length === 0) || isAssignedCheckupsForPatientsLoading;

  const toggleTabs = () => {
    setRequestsTabActive(!isRequestsTabActive);
  };

  const getAnalyzes = async (sortDirection: SortDirectionFilter, normFilter: AnalyzesNormFilter, newPage?: number) => {
    await dispatch(
      getAnalyzesAsync({ id: patientId, sortDirection, sortBy: 'date', normFilter, limit: 10000, page: 1 }),
    ) //TODO: need pagination
      .then(unwrapResult)
      .then((result) => {
        setAnalyzes(newPage === 1 ? result.data : analyzes?.concat(result.data));
        const { page, pageCount, total } = result;
        setPagination({ page, pageCount, total });
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    patientId && getAnalyzes(dateSortOption.value, zoneOption.value);
    patientId &&
      dispatch(getAssignedCheckupsForPatiens(patientId)).catch((error) => {
        console.warn({ error });
      });
  }, [patientId]);

  const { push } = useHistory();

  const handleAddAnalysis = () => {
    push(CommonRoutes.NEW_ANALYSIS);
  };

  const handleCreateCheckUpClick = () => {
    push(ExpertRoutes.ASSIGN_CHECKUP);
  };

  const hasEmptyAnalyzes = analyzes?.length === 0;
  const hasLoadingComplete = loadingStatus === LoadingStatus.fulfilled;
  const hasEmptyExpertsRequests = expertsRequests.length === 0;

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  if (hasEmptyAnalyzes && hasLoadingComplete && hasEmptyExpertsRequests) {
    return <EmptyAnalyzesHistoryTab />;
  }

  return (
    <>
      <div className={styled['history__header']}>
        <h4>{t('heading')}</h4>
        <div className={styled['history__sort']}>
          <DropdownDefault
            options={dateSortOptions}
            selected={dateSortOption}
            onChange={(option) => {
              setDateSortOption(option);
              setPagination((prevState) => ({ ...prevState, pageCount: 1 }));
              getAnalyzes(option.value, zoneOption.value, 1);
            }}
            isSearchable={false}
            placeholder={t('dateSortPlaceholder') as string}
            variant="small"
            className={styled['history__button']}
            bigHeightMobile
          />
          <DropdownDefault
            options={zoneOptions}
            selected={zoneOption}
            onChange={(option) => {
              setZoneOption(option);
              setPagination((prevState) => ({ ...prevState, pageCount: 1 }));
              getAnalyzes(dateSortOption.value, option.value, 1);
            }}
            isSearchable={false}
            placeholder={t('zonePlaceholder') as string}
            variant="small"
            className={styled['history__button']}
            bigHeightMobile
          />
        </div>
      </div>
      {isLoading ? (
        <div className={styled.waiting}>
          <Spinner color="#646EFA" variant="primary" radius={50} />
        </div>
      ) : (
        <>
          <div className={styled.tabs__container}>
            <AnalyzesTabs active={!isRequestsTabActive} toggleTabs={toggleTabs} />
          </div>
          <VirtuosoGrid
            style={{
              height: '674px',
              display: 'flex',
            }}
            listClassName={styled.list}
            className={styled.container}
            itemClassName={styled.item}
            totalCount={isRequestsTabActive ? expertsRequests.length : analyzes.length}
            itemContent={(index) =>
              isRequestsTabActive ? (
                <AnalysisRequestCard
                  key={expertsRequests[index].id}
                  request={expertsRequests[index]}
                  patientId={patientId}
                />
              ) : (
                <AnalysisCard key={analyzes[index].id} analysis={analyzes[index]} fromHistory />
              )
            }
            //TODO: need pagination
            // atBottomStateChange={(atBottom) => {
            //   if (atBottom && pagination.page < pagination.pageCount) {
            //     setPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            //     getAnalyzes(dateSortOption.value, zoneOption.value, pagination.page + 1);
            //   }
            // }}
          />

          <div className={styled.button__container}>
            <ButtonDefault
              type="submit"
              text={isRequestsTabActive ? t('createRequest') : t('addAnalysis')}
              variant="primary"
              customStyles={{ maxWidth: isMobile ? '100%' : '200px' }}
              onClick={isRequestsTabActive ? handleCreateCheckUpClick : handleAddAnalysis}
            />
          </div>
        </>
      )}
    </>
  );
};
