import * as React from 'react';

function DocumentFinished(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
        fill="#D7EFE7"
      />
      <path
        d="M20.416 9.917l-8.166 8.166L8.166 14"
        stroke="#35B185"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DocumentFinished;
