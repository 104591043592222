import classnames from 'classnames';
import { CSSProperties, FC } from 'react';

import styles from './styles.module.scss';

interface IconWithBackgroundProps {
  icon: JSX.Element;
  isActive?: boolean;
  customStyles?: CSSProperties;
  disabled?: boolean;
}

export const IconWithBackground: FC<IconWithBackgroundProps> = ({
  icon,
  isActive = false,
  customStyles,
  disabled = false,
}) => {
  return (
    <div
      className={classnames(styles['icon-wrapper'], {
        [styles['icon-wrapper_active']]: isActive,
        [styles['icon-wrapper_disabled']]: disabled,
      })}
      style={customStyles}>
      {icon}
    </div>
  );
};
