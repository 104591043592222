import classNames from 'classnames';
import { CSSProperties, FC, MouseEventHandler, ReactElement } from 'react';
import { Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

type IconButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  icon: ReactElement;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  variant?: 'primary' | 'secondary';
  isLoading?: boolean;
  customStyles?: CSSProperties;
  containerClassNames?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  icon,
  type = 'button',
  onClick = () => void 0,
  isDisabled = false,
  isLoading = false,
  customStyles,
  containerClassNames,
  variant = 'primary',
}) => {
  return (
    <button
      type={type}
      className={classNames('btn', styles.button, styles['button-' + variant], containerClassNames)}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      style={customStyles}>
      {isLoading ? <Spinner radius={8} variant="secondary" /> : icon}
    </button>
  );
};
