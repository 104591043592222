import { HorizontalArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommonRoutes } from 'types/routes';
import { HeaderDefault } from 'UIcomponents';

import styles from './AuthLayout.module.scss';

export interface BackOptions {
  url?: string;
  text?: string;
  onClick?: () => void;
}

export interface AuthLayoutProps {
  children: React.ReactNode;
  title?: string;
  backOptions?: BackOptions;
  withFooter?: boolean;
  isPatient?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
  backOptions,
  withFooter = false,
  isPatient = true,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  return (
    <div className={styles.root}>
      <HeaderDefault isPatient={isPatient} />
      <div className={styles.content}>
        {backOptions && (backOptions.url || backOptions.onClick) && (
          <div className={styles['content-back-options']}>
            {backOptions.url && (
              <Link to={backOptions.url} className={styles['content-back-options__icon']}>
                <HorizontalArrowIcon />
              </Link>
            )}
            {backOptions.onClick && (
              <button type="button" className={styles['content-back-options__icon']} onClick={backOptions.onClick}>
                <HorizontalArrowIcon />
              </button>
            )}
            <p className={`body body_medium ${styles['content-back-options__back-text']}`}>{backOptions?.text}</p>
          </div>
        )}
        <h2 className={styles.content__title}>{title}</h2>
        {children}
      </div>
      {withFooter && (
        <footer className={classNames('flexbox', styles['footer'])}>
          <Link to={CommonRoutes.TERMS_OF_SERVICE} className={classNames('mr-2', styles['footer__link'])}>
            {t('termsOfService')}
          </Link>
          <Link to={CommonRoutes.PRIVACY_POLICY} className={classNames(styles['footer__link'])}>
            {t('privacyPolicy')}
          </Link>
        </footer>
      )}
    </div>
  );
};

export default AuthLayout;
