import classnames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import styles from './../styles.module.scss';

type CustomTooltipProps = {
  norm: number[];
  unit: string;
  isTooltipVisible?: boolean;
} & TooltipProps<ValueType, NameType>;

export const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, norm, unit, isTooltipVisible = true }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'chart' });

  if (active && payload && payload.length) {
    const value = payload[0]?.payload.marker;
    const isInAnalysis = payload[0]?.payload.isInAnalysis;

    let isNorm = false;
    let normText = '';
    if (value || value === 0) {
      if ((value < norm[0] && isInAnalysis) || (value < norm[0] && value === 0 && isInAnalysis)) {
        normText = t('normalDown');
      } else if (value > norm[1]) {
        normText = t('normalUp');
      } else if (value === 0) {
        normText = '';
      } else {
        isNorm = true;
        normText = t('normal');
      }
    }

    return (
      <>
        {isTooltipVisible && (
          <div className={styles['chart-tooltip']}>
            <p className={`subhead subhead_medium ${styles['chart-tooltip__label']}`}>
              {value} {unit}
            </p>
            <p
              className={classnames('footnote', 'footnote_medium', styles['chart-tooltip__is-normal'], {
                [styles['chart-tooltip__is-normal_normal']]: isNorm,
                [styles['chart-tooltip__is-normal_not-normal']]: !isNorm,
              })}>
              {normText}
            </p>
          </div>
        )}
      </>
    );
  }

  return null;
};
