import { ArrowIcon, Eng, Ru } from 'assets/svg';
import { useToggle } from 'hooks';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageFromStorage, LanguagesEnum } from 'services/storageService';
import { LanguageSelector } from 'UIcomponents';

import styles from './styles.module.scss';

export const LanguageDropdown = () => {
  const language = getLanguageFromStorage();
  const { isOpened, onCloseToggle, onToggle } = useToggle();
  const ref = useRef(null);

  const isEnglish = language === LanguagesEnum.en;
  const { i18n } = useTranslation();

  useOnClickOutside(ref, onCloseToggle);

  return (
    <div ref={ref} className={styles.root}>
      <button className={styles.container} onClick={onToggle}>
        {isEnglish ? (
          <div className={styles.language}>
            <div className={styles.text}>
              <Eng />
              <p>Eng</p>
            </div>
            <ArrowIcon />
          </div>
        ) : (
          <div className={styles.language}>
            <div className={styles.text}>
              <Ru />
              <p>Рус</p>
            </div>
            <ArrowIcon />
          </div>
        )}
      </button>
      {isOpened && (
        <div className={styles.dropdown}>
          <LanguageSelector />
        </div>
      )}
    </div>
  );
};
