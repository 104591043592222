import { unwrapResult } from '@reduxjs/toolkit';
import { Analysis } from 'api/generated';
import classnames from 'classnames';
import { AnalyzesTabs } from 'components';
import { selectAnalyzesSearchStatus } from 'features/analyzes/selectors';
import { searchAnalyzesAsync } from 'features/analyzes/thunks';
import { selectAssignedCheckups, selectPatientExpert } from 'features/patient/selectors';
import { selectGetSubscriptionLoadingState } from 'features/subscriptions/selectors';
import { useUserAccess } from 'hooks/useUserAccess';
import { LayoutWithBackOptions } from 'layouts';
import { SubscribeBanner } from 'pages/NewAnalysis/components/SubscribeBanner';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { AnalyzesNormFilter, SortDirectionFilter } from 'types/analyzes';
import { AnalysisCard, AnalysisRequestCard, DropdownDefault, Spinner } from 'UIcomponents';

import { EmptyAnalyzesHistory } from './components/';
import styles from './styles.module.scss';

type PaginationState = {
  page: number;
  pageCount: number;
  total: number;
};

export enum AnalyzesHistoryTabs {
  ALL_HISTORY = 'ALL_HISTORY',
  REQUESTS = 'REQUESTS',
}

export const AnalyzesHistory: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const dateSortOptions = [
    {
      label: t('sortingNew'),
      value: SortDirectionFilter.DESC,
    },
    {
      label: t('sortingOld'),
      value: SortDirectionFilter.ASC,
    },
  ];

  const zoneOptions = [
    {
      label: t('allZone'),
      value: AnalyzesNormFilter.all,
    },
    {
      label: t('insideZoneNormal'),
      value: AnalyzesNormFilter.inNormal,
    },
    {
      label: t('outsideZoneNormal'),
      value: AnalyzesNormFilter.notInNormal,
    },
  ];

  const dispatch = useAppDispatch();
  const hasFullAccess = useUserAccess();
  const { location }: any = useHistory();
  const [analyzes, setAnalyzes] = useState<Array<Analysis>>([]);
  const [dateSortOption, setDateSortOption] = useState(dateSortOptions[0]);
  const [zoneOption, setZoneOption] = useState(zoneOptions[0]);
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, pageCount: 0, total: 0 });
  const [isRequestsTabActive, setRequestsTabActive] = useState(location.state?.isRequestsActive ? true : false);

  const analyzesSearchStatus = useSelector(selectAnalyzesSearchStatus);
  const expertsRequests = useAppSelector(selectAssignedCheckups);
  const patientCooperationExpert = useAppSelector(selectPatientExpert);
  const isSubscriptionLoading = useAppSelector(selectGetSubscriptionLoadingState) === LoadingStatus.pending;

  const ref = useRef<VirtuosoHandle>(null);

  const hasPatientCooperateWithExpert = !!patientCooperationExpert;
  const hasAccessToHistory = hasFullAccess || hasPatientCooperateWithExpert;

  const analyzesLength = hasAccessToHistory ? analyzes.length : 1;
  const isLoading = analyzesSearchStatus === LoadingStatus.pending;
  const hasAnalyzes = analyzes.length !== 0;

  const getAnalyzes = async (sortDirection: SortDirectionFilter, normFilter: AnalyzesNormFilter, newPage?: number) => {
    await dispatch(searchAnalyzesAsync({ sortDirection, sortBy: 'date', normFilter, limit: 1000, page: 1 }))
      .then(unwrapResult)
      .then((result) => {
        setAnalyzes(newPage === 1 ? result.data : analyzes.concat(result.data));
        const { page, pageCount, total } = result;
        setPagination({ page, pageCount, total });
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    getAnalyzes(dateSortOption.value, zoneOption.value, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTabs = () => {
    setRequestsTabActive(!isRequestsTabActive);
  };

  const hasSubscriptionBanner = !hasFullAccess && !isSubscriptionLoading && !hasPatientCooperateWithExpert;
  const isExpertsRequestsEmpty = expertsRequests.length === 0;
  const isAnalyzesLengthEmpty = analyzesLength === 0;

  return (
    <LayoutWithBackOptions
      backOptions={{
        text: t('history'),
      }}
      additionalNode={{
        node: (
          <>
            <DropdownDefault
              options={dateSortOptions}
              selected={dateSortOption}
              onChange={(option) => {
                setDateSortOption(option);
                setPagination((prevState) => ({ ...prevState, pageCount: 1 }));
                getAnalyzes(option.value, zoneOption.value, 1);
                ref.current?.scrollToIndex(1);
              }}
              isSearchable={false}
              placeholder={t('sortingNew') as string}
              variant="small"
              className={styles['history__button']}
              bigHeightMobile
            />
            <DropdownDefault
              options={zoneOptions}
              selected={zoneOption}
              onChange={(option) => {
                setZoneOption(option);
                setPagination((prevState) => ({ ...prevState, pageCount: 1 }));
                getAnalyzes(dateSortOption.value, option.value, 1);
                ref.current?.scrollToIndex(1);
              }}
              isSearchable={false}
              placeholder={t('insideNormal') as string}
              variant="small"
              className={styles['history__button']}
              bigHeightMobile
            />
          </>
        ),
        position: 'under-title',
      }}>
      {isLoading ? (
        <div className={styles.waiting}>
          <Spinner color="var(--background-primary)" variant="primary" radius={50} />
        </div>
      ) : (
        <>
          <div className={styles.container_banner}>
            {hasSubscriptionBanner && <SubscribeBanner isAnalyzesHistory={true} />}
          </div>
          <div className={styles.container}>
            <div className={styles.tabs__container}>
              <AnalyzesTabs active={!isRequestsTabActive} toggleTabs={toggleTabs} />
            </div>
            <div className={classnames(styles.history, { [styles.history_loading]: isLoading })}>
              {isRequestsTabActive ? (
                isExpertsRequestsEmpty ? (
                  <EmptyAnalyzesHistory isRequestsTabActive={isRequestsTabActive} />
                ) : (
                  <VirtuosoGrid
                    ref={ref}
                    overscan={600}
                    totalCount={isRequestsTabActive ? expertsRequests.length : analyzesLength}
                    style={{ height: 600 }}
                    listClassName={styles.list}
                    itemClassName={styles.item}
                    itemContent={(index) =>
                      isRequestsTabActive ? (
                        <AnalysisRequestCard key={expertsRequests[index].id} request={expertsRequests[index]} />
                      ) : (
                        <>
                          {hasAnalyzes && (
                            <AnalysisCard key={analyzes[index].id} analysis={analyzes[index]} fromHistory />
                          )}
                        </>
                      )
                    }
                  />
                )
              ) : isAnalyzesLengthEmpty ? (
                <EmptyAnalyzesHistory isRequestsTabActive={isRequestsTabActive} />
              ) : (
                <VirtuosoGrid
                  ref={ref}
                  overscan={600}
                  totalCount={isRequestsTabActive ? expertsRequests.length : analyzesLength}
                  style={{ height: 600 }}
                  listClassName={styles.list}
                  itemClassName={styles.item}
                  itemContent={(index) =>
                    isRequestsTabActive ? (
                      <AnalysisRequestCard key={expertsRequests[index].id} request={expertsRequests[index]} />
                    ) : (
                      <>
                        {hasAnalyzes && (
                          <AnalysisCard key={analyzes[index].id} analysis={analyzes[index]} fromHistory />
                        )}
                      </>
                    )
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
    </LayoutWithBackOptions>
  );
};
