import classnames from 'classnames';
import { TableHeader } from 'components';
import { selectExpertPatients, selectExpertPatientsLoadingStatus, selectFilters } from 'features/expert/selectors';
import { selectLoadingPatientSortStatus } from 'features/expert/selectors';
import { sortPatientsAsync } from 'features/expert/thunks';
import { PatientCard } from 'pages/DashboardExpert/components/PatientCard';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { useAppDispatch } from 'store/reducers';
import { Spinner } from 'UIcomponents';

import useWindowWidth from '../../hooks/useWindowWidth';
import { LoadingStatus } from '../../types';
import Cell from './components/Cell/Cell';
import styles from './styles.module.scss';

enum sortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface SortingTypes {
  sort: 'name' | 'birthday' | 'createdAt';
  sortDirection: 'ASC' | 'DESC';
}

export type TableColumType = {
  label: string;
  hasSorting: boolean;
  aspect: number;
  sort?: 'name' | 'birthday' | 'phone' | 'paid' | 'email' | 'createdAt';
  isSort?: boolean;
};

interface BaseTableProps {
  title?: ReactNode;
}

export const BaseTable: FC<BaseTableProps> = ({ title }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.baseTable' });

  const width = useWindowWidth();
  const dispatch = useAppDispatch();
  const patients = useSelector(selectExpertPatients);
  const isLoading = useSelector(selectExpertPatientsLoadingStatus);
  const patientSortLoadingStatus = useSelector(selectLoadingPatientSortStatus);

  const columns: TableColumType[] = [
    { label: t('name'), hasSorting: true, aspect: 1.8, sort: 'name', isSort: false },
    { label: t('birthday'), hasSorting: true, aspect: 1.3, sort: 'birthday', isSort: false },
    { label: t('phone'), hasSorting: false, aspect: 1.6, sort: 'phone', isSort: false },
    { label: 'Email', hasSorting: false, aspect: 2, sort: 'email', isSort: false },
    { label: t('createdAt'), hasSorting: true, aspect: 1.26, sort: 'createdAt', isSort: false },
  ];

  const [header, setHeader] = useState<TableColumType[]>(columns);
  const [sortDirection, setSortDirection] = useState<sortDirectionEnum>(sortDirectionEnum.DESC);
  const [sort, setSort] = useState('createdAt');
  const signUpStatus = useSelector(selectFilters).signUpStatus;
  const onlyNewRequests = useSelector(selectFilters).onlyNewRequests;

  useEffect(() => {
    dispatch(
      sortPatientsAsync({
        page: 1, //TODO: need pagination
        limit: 10000,
        sortField: sort as SortingTypes['sort'],
        sortDirection: sortDirection,
        signUpStatus: signUpStatus,
        onlyNewRequests: onlyNewRequests,
      }),
    );
  }, [sort, sortDirection, signUpStatus, onlyNewRequests]);

  const sorting = (sortName: string) => {
    const newTable = columns.map((patient) => {
      if (patient.sort === sortName) {
        patient.isSort = true;
        if (sortName !== sort) {
          setSortDirection(sortDirectionEnum.ASC);
        } else {
          setSortDirection(sortDirection === sortDirectionEnum.ASC ? sortDirectionEnum.DESC : sortDirectionEnum.ASC);
        }
      } else {
        patient.isSort = false;
      }
      return patient;
    });
    setHeader(newTable);
    setSort((state) => sortName);
  };

  //TODO: look network, dont stop requests
  // const loadMore = () => {
  //   dispatch(
  //     getPatientsAsync({
  //       page: 1, //TODO: need pagination
  //       limit: 100,
  //       sortField: sort as SortingTypes['sort'],
  //       signUpStatus: signUpStatus,
  //       sortDirection: sortDirection,
  //     }),
  //   );
  // };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={classnames(styles.table)}>
      {title}
      {width > 1100 && (
        <TableHeader
          customStyles={{ width: width > 1100 ? '100%' : '1100px', marginTop: '32px' }}
          sorting={sorting}
          columns={columns}
        />
      )}
      {patientSortLoadingStatus === LoadingStatus.pending ? (
        <div className={styles.spinner}>
          <Spinner radius={20} variant={'secondary'} color="var(--background-primary)" />
        </div>
      ) : (
        <>
          {isLoading === LoadingStatus.fulfilled && patients.data ? (
            <Virtuoso
              style={{
                height: '636px',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
              totalCount={patients.data.length}
              data={patients.data}
              //endReached={loadMore}
              itemContent={(index, patient) =>
                width > 1100 ? <Cell patient={patient} /> : <PatientCard patient={patient} />
              }
            />
          ) : (
            <div className={styles.spinner}>
              <Spinner radius={20} variant={'secondary'} color="var(--background-primary)" />
            </div>
          )}
        </>
      )}
    </div>
  );
};
