import * as amplitude from '@amplitude/analytics-browser';
import { Analytics, ValidPropertyType } from 'types/analytics';

export class LocalAnalytics implements Analytics {
  constructor() {
    console.log('Initialize local analytics');
  }

  setUser(id?: string) {
    console.log('Set user for analytics: ', id);
  }

  trackEvent(
    eventInput: string | amplitude.Types.BaseEvent,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitude.Types.EventOptions | undefined,
  ) {
    console.log('Track event: ', eventInput, eventProperties, eventOptions);
  }

  setEvent(property: string, value: ValidPropertyType) {
    console.log('Set event: ', property, value);
  }

  reset() {
    console.log('Reset analytics');
  }

  removeEvent() {
    console.log('Remove Event');
  }
}
