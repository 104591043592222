import * as React from 'react';

function SvgFolders(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.4241 3.27612C17.3422 3.27612 16.4648 4.15348 16.4648 5.2354V7.93414H19.8803V7.77806C19.8803 7.09164 20.4364 6.53704 21.1213 6.53704H43.4947C44.1796 6.53704 44.7357 7.09164 44.7357 7.77806V23.9341C44.7357 24.363 44.3887 24.713 43.9584 24.713V28.1285H46.0373C47.1193 28.1285 47.9981 27.2511 47.9981 26.1677V5.2354C47.9981 4.15348 47.1208 3.27612 46.0373 3.27612H18.4241Z"
        fill="#A1A7FF"
      />
      <path
        d="M9.94141 14.4574H13.2038C13.2038 13.7725 13.7584 13.2179 14.4433 13.2179H36.3212C37.2804 13.2179 38.0577 13.9952 38.0577 14.9544V30.615C38.0577 31.0438 37.7107 31.3923 37.2819 31.3923H36.9712V34.6548H39.3624C40.4428 34.6548 41.3217 33.7774 41.3217 32.694V11.5889C41.3217 10.601 40.517 9.79785 39.5275 9.79785H11.9007C10.8188 9.79785 9.94141 10.6752 9.94141 11.7571V14.4574Z"
        fill="#A1A7FF"
      />
      <path
        d="M34.7957 25.4859C34.7957 23.2524 32.988 21.4477 30.7559 21.4477H18.7942V21.4764L17.5911 18.8398C16.9349 17.4018 15.4984 16.479 13.918 16.479H4.03826C1.80775 16.479 0 18.2852 0 20.5173V21.4401V28.1271V42.3315C0 43.5816 1.01222 44.5983 2.26234 44.6044L32.9319 44.7241C34.0381 44.7286 35.0473 44.0846 35.5094 43.0815L37.7566 38.2098C37.9263 37.8401 37.8975 37.4098 37.6778 37.0643C37.4566 36.7218 37.0778 36.5173 36.6717 36.5173H34.7957V25.4859ZM23.1446 34.655H19.8822V38.072H16.4667V34.655H13.2028V31.3925H16.4652V28.1756C16.4652 28.1483 16.4864 28.1301 16.5137 28.1301H19.8261H19.8822V31.3925H23.1446V34.655Z"
        fill="#A1A7FF"
      />
    </svg>
  );
}

export default SvgFolders;
