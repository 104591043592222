import { unwrapResult } from '@reduxjs/toolkit';
import { BaseModal } from 'components/BaseModal';
import { actions } from 'features/chat/slice';
import { createChatAsync } from 'features/expert/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault, DropdownDefault, FormError, FormLabel, TextArea } from 'UIcomponents';

import styles from './styles.module.scss';

interface FormValues {
  name: string;
  message: string;
}

interface Props {
  onClose: () => void;
  patients: Array<{ label: string; value: string }>;
}

export const NewMessageModal: FC<Props> = ({ onClose, patients }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'message' });

  const width = useWindowWidth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: FormValues) => {
    dispatch(createChatAsync({ patientId: data.name, text: data.message }))
      .then(unwrapResult)
      .then((res) => {
        notify('success', t('successCreateChat'));
        dispatch(actions.activeChat(res));
        // dispatch(getChatAsync({ searchQuery: '' }));
        onClose();
      })
      .catch((e) => {
        notify('error', t('chatAlreadyCreated'));
      });
  };

  return (
    <BaseModal
      onClose={onClose}
      isVisible={true}
      customStyles={{ width: width > 768 ? '644px' : '90%' }}
      title={t('newMessage') as string}>
      <form className={styles.form}>
        <div className={styles.modal__name}>
          <FormLabel text={t('nameLabel')} />
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('required') as string,
            }}
            render={({ field: { onChange } }) => (
              <DropdownDefault
                options={patients}
                onChange={(e) => {
                  onChange(e.value);
                }}
                isSearchable={false}
                variant={'max-content'}
              />
            )}
          />
        </div>
        <div>
          <FormLabel text={t('text')} />
          <Controller
            name="message"
            defaultValue={''}
            control={control}
            rules={{
              required: t('required') as string,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextArea
                  value={value}
                  onChange={onChange}
                  error={errors.message && 'error'}
                  placeholder={t('messagePlaceholder') as string}
                  disabled={false}
                  minRows={5}
                />
                {errors.message?.message && <FormError type="error" text={errors.message.message} />}
              </>
            )}
          />
        </div>
        <div className={styles['modal__button']}>
          <ButtonDefault text={t('save')} onClick={handleSubmit(onSubmit)} />
        </div>
      </form>
    </BaseModal>
  );
};
