import { TermsLayout } from 'layouts/TermsLayout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import { CommonRoutes } from 'types/routes';

import { DisclaimerOfLiability, PrivacyPolicy, TermsOfService } from './components';

export const FAQ: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq' });

  return (
    <TermsLayout title={t('termsAndConditions')} lastUpdate="02.11.2022">
      <Switch>
        <Route path={CommonRoutes.TERMS_OF_SERVICE} component={TermsOfService} />
        <Route path={CommonRoutes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path={CommonRoutes.DISCLAIMER_OF_LIABILITY} component={DisclaimerOfLiability} />
      </Switch>
    </TermsLayout>
  );
};
