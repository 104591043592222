import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ExpertRoutes } from 'types/routes';

import styles from '../../style.module.scss';

type Props = {
  title: string;
  index: number;
  isActive: boolean;
};

const RouteMap = [
  ExpertRoutes.PROFILE,
  ExpertRoutes.SETTINGS,
  ExpertRoutes.PAYMENTS,
  ExpertRoutes.SUBSCRIPTION,
  ExpertRoutes.NOTIFICATIONS,
];

export const TabTitle: FC<Props> = ({ title, index, isActive }) => {
  return (
    <Link
      to={RouteMap[index]}
      className={classNames(
        styles['expert-profile-tabs__tab'],
        isActive && styles['expert-profile-tabs__tab--active'],
      )}>
      {title}
    </Link>
  );
};
