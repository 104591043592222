import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import styles from './styles.module.scss';

type Props = {
  title: string;
  body: string | number;
  customClassName?: string;
  customStyles?: CSSProperties;
};

export const Placeholder: React.FC<Props> = ({ title, body, customClassName, customStyles }) => {
  return (
    <div className={classNames(styles['anamnesis-placeholder'], customClassName)}>
      <p className={classNames(styles['anamnesis-placeholder__label'])}>{title}</p>
      <p className={classNames(styles['anamnesis-placeholder__body'])}>{body}</p>
    </div>
  );
};
