import {
  GetPaymentUrlDtoPaymentPlatformEnum,
  SubscriptionDtoPaymentPlatformEnum,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
} from 'api/generated';
import { ArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectSubscription } from 'features/subscriptions/selectors';
import { LanguageType } from 'i18n';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analytics } from 'services/analytics';
import { useAppSelector } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { getSubscriptionNameByIntervalAndPatientLimit } from 'utils/getSubscriptionNameByIntervalAndPatientLimit';

import styles from './styles.module.scss';

interface SubscriptionCardProps {
  amountRub: number;
  amountUsd: number;
  onClickButton: (plan: SubscriptionDtoSubscriptionPlanEnum, platform: GetPaymentUrlDtoPaymentPlatformEnum) => void;
  isLoading: boolean;
  selectedPlatform?: GetPaymentUrlDtoPaymentPlatformEnum;
  currentPaymentPlatform?: SubscriptionDtoPaymentPlatformEnum;
  onClickCard?: () => void;
  features?: string[];
  interval: string;
  period: number;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  amountRub,
  amountUsd,
  onClickButton,
  onClickCard,
  selectedPlatform,
  currentPaymentPlatform,
  isLoading = false,
  features = [],
  interval,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const featuresListRef = useRef<HTMLUListElement>(null);
  const [featuresOpened, setFeaturesOpened] = useState(false);

  const toggleFeaturesList = () => {
    setFeaturesOpened(!featuresOpened);
    featuresListRef.current?.style.setProperty('--max-height', featuresListRef.current?.scrollHeight + 'px');
  };

  const role = useAppSelector(selectRole);
  const isExpert = role === RolesEnum.EXPERT;

  const patientFeatures: string[] = [
    t('patientFeatures.fullAccess'),
    t('patientFeatures.bioAge'),
    t('patientFeatures.secureStorage'),
    t('patientFeatures.healthAssessment'),
    t('patientFeatures.print'),
    t('patientFeatures.optimalValue'),
    t('patientFeatures.dynamicMonitoring'),
  ];

  const expertFeatures: string[] = [
    t('expertFeatures.management'),
    t('expertFeatures.publishProfile'),
    t('expertFeatures.createProtocols'),
    t('expertFeatures.saveMessaging'),
    t('expertFeatures.diary'),
    t('expertFeatures.analyzes'),
    t('expertFeatures.calendar'),
    t('expertFeatures.preanalyticsOfLaboratoryData'),
  ];

  const featuresList = isExpert ? expertFeatures : patientFeatures;

  useEffect(() => {
    const handleWindowResize = () => {
      featuresOpened &&
        featuresListRef.current?.style.setProperty('--max-height', featuresListRef.current?.scrollHeight + 'px');
    };
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  });

  const yearPriceByMonthRub = Math.floor(amountRub / 12);
  const yearPriceByMonthUsd = Math.floor(amountUsd / 12);

  const subscription = useAppSelector(selectSubscription);
  const { subscriptionPlan, status } = subscription;

  const subscriptionKind = getSubscriptionNameByIntervalAndPatientLimit(interval, 0);

  const isSubscriptionExpired =
    status === SubscriptionDtoStatusEnum.Expired || status === SubscriptionDtoStatusEnum.CanceledExpired;

  const isSubscriptionActive = subscriptionPlan === subscriptionKind && !isSubscriptionExpired;

  const getButtonText = (paymentPlatoformText: string) => {
    if (isSubscriptionActive) return t('active');
    if (currentPaymentPlatform) return t('change');

    return i18n.language === LanguageType.EN ? t('pay') : paymentPlatoformText;
  };

  const getYearPriceText = () => {
    return {
      [LanguageType.EN]: `($${yearPriceByMonthUsd} ${t('priceByMonth')})`,
      [LanguageType.RU]: `(${yearPriceByMonthRub}₽ ${t('priceByMonth')})`,
    }[i18n.language];
  };

  const getPriceText = () => {
    return {
      [LanguageType.EN]: `$${amountUsd}`,
      [LanguageType.RU]: `${amountRub}₽`,
    }[i18n.language];
  };

  const isRenderRuPaymentButton =
    (currentPaymentPlatform === SubscriptionDtoPaymentPlatformEnum.CloudPayments || !currentPaymentPlatform) &&
    subscriptionKind &&
    i18n.language === LanguageType.RU;

  const handleClickButtonRuPayment = (
    plan: SubscriptionDtoSubscriptionPlanEnum,
    platform: GetPaymentUrlDtoPaymentPlatformEnum,
  ) => {
    //Metrics
    if (plan === SubscriptionDtoSubscriptionPlanEnum.Year) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'year',
      });
    }

    if (plan === SubscriptionDtoSubscriptionPlanEnum.Month) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'month',
      });
    }

    onClickButton(plan, platform);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      onClick={isLoading ? undefined : onClickCard}
      className={classNames(styles['subscription-card'], {
        [styles['subscription-card_active']]: isSubscriptionActive,
      })}>
      <div className={classNames('flexbox', 'justify-between', styles['subscription-card__header'])}>
        <ArrowIcon
          onClick={toggleFeaturesList}
          className={classNames(styles['subscription__features-toggler'], {
            [styles['subscription__features-toggler_opened']]: featuresOpened,
          })}
        />
        <div className={styles.subscription_title}>
          <h3 className={classNames(styles['subscription__name'])}>
            {subscriptionKind === SubscriptionDtoSubscriptionPlanEnum.Month ? t('month') : t('year')}
          </h3>
          <h3 className={classNames(styles['subscription__amount'])}>{getPriceText()}</h3>
        </div>
        <div className={styles.subscription_subheader}>
          {subscriptionKind === SubscriptionDtoSubscriptionPlanEnum.Year && (
            <div className={classNames('subhead', 'subhead_regular', styles['subscription__description'])}>
              {getYearPriceText()}
            </div>
          )}

          <div className={classNames('subhead', 'subhead_regular', styles['subscription__period'])}>{`${t('per')} ${
            subscriptionKind === SubscriptionDtoSubscriptionPlanEnum.Year
              ? t('subscriptionKindYear')
              : t('subscriptionKindMonth')
          }`}</div>
        </div>
      </div>
      <ul
        ref={featuresListRef}
        className={classNames('subhead', 'subhead_regular', styles['subscription-card__features'], {
          [styles['subscription-card__features_opened']]: featuresOpened,
        })}>
        {[...featuresList, ...features].map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <div className={classNames(styles['subscription-card__actions'])}>
        {isRenderRuPaymentButton && (
          <ButtonDefault
            text={getButtonText(t('paymentButtonRu'))}
            variant={isSubscriptionActive ? 'primary' : 'secondary'}
            onClick={() =>
              handleClickButtonRuPayment(subscriptionKind, GetPaymentUrlDtoPaymentPlatformEnum.CloudPayments)
            }
            isLoading={isLoading && selectedPlatform === GetPaymentUrlDtoPaymentPlatformEnum.CloudPayments}
            isDisabled={isSubscriptionActive}
          />
        )}

        {(currentPaymentPlatform === SubscriptionDtoPaymentPlatformEnum.Stripe || !currentPaymentPlatform) &&
          subscriptionKind && (
            <ButtonDefault
              text={getButtonText(t('paymentButton'))}
              variant={isSubscriptionActive ? 'primary' : 'secondary'}
              onClick={() => onClickButton(subscriptionKind, GetPaymentUrlDtoPaymentPlatformEnum.Stripe)}
              isLoading={isLoading && selectedPlatform === GetPaymentUrlDtoPaymentPlatformEnum.Stripe}
              isDisabled={isSubscriptionActive}
            />
          )}
      </div>
    </div>
  );
};
