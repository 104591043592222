import { Patient } from 'api/generated';
import { UserIcon } from 'assets/svg';
import classNames from 'classnames';
import moment from 'moment';
import plural from 'plural-ru';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface TopUserInfoBlockProps {
  patient: Patient;
}

const TopUserInfoBlock: FC<TopUserInfoBlockProps> = ({ patient }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientInfoTab' });

  return (
    <div className={classNames(styles['user-info-block'], 'flexbox')}>
      <div className={classNames(styles['user-info-block__left-block'], 'flexbox', 'align-center')}>
        {patient?.avatar ? (
          <div
            style={{ background: `center / cover no-repeat url(${patient.avatar})` }}
            className={classNames(
              styles['user-info-block__left-block__avatar'],
              'flexbox',
              'align-center',
              'justify-center',
            )}></div>
        ) : (
          <div
            className={classNames(
              styles['user-info-block__left-block__avatar'],
              'flexbox',
              'align-center',
              'justify-center',
            )}>
            <UserIcon fill={'var(--dark-grey)'} />
          </div>
        )}

        <div>
          <p className={styles['user-info-block__left-block__name']}>{patient.name}</p>
          <p className={styles['user-info-block__gray-text']}>
            {patient.age && `${patient.age} ${plural(patient.age, t('year'), t('years'), t('yearPlural'))}`}
          </p>
        </div>
      </div>
      <div className={classNames(styles['user-info-block__right-block'], 'flexbox', 'align-center')}>
        <p className={styles['user-info-block__gray-text']}>{t('dateRegistration')}:&nbsp;</p>
        <p className={styles['user-info-block__right-block__register-date']}>
          {moment(patient.createdAt).format('DD.MM.YYYY')}
        </p>
      </div>
    </div>
  );
};

export default TopUserInfoBlock;
