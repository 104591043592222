import * as React from 'react';

function Delete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.2455 9.02742C18.425 9.02742 18.5878 9.10572 18.7165 9.23802C18.8364 9.37932 18.8968 9.55481 18.8793 9.74021C18.8793 9.80141 18.3996 15.8674 18.1256 18.4206C17.9541 19.9875 16.944 20.9388 15.4288 20.9649C14.2638 20.991 13.1251 21 12.0038 21C10.8134 21 9.64926 20.991 8.51925 20.9649C7.05487 20.9298 6.04391 19.9614 5.8811 18.4206C5.59925 15.8584 5.12835 9.80141 5.11959 9.74021C5.11084 9.55481 5.17036 9.37932 5.29115 9.23802C5.41019 9.10572 5.58175 9.02742 5.76206 9.02742H18.2455ZM13.8586 3C14.6543 3 15.365 3.55529 15.5707 4.34729L15.7178 5.00428C15.8368 5.53977 16.3007 5.91867 16.8347 5.91867H19.4588C19.8089 5.91867 20.1004 6.20937 20.1004 6.57926V6.92126C20.1004 7.28216 19.8089 7.58185 19.4588 7.58185H4.54286C4.19186 7.58185 3.90039 7.28216 3.90039 6.92126V6.57926C3.90039 6.20937 4.19186 5.91867 4.54286 5.91867H7.167C7.70006 5.91867 8.16396 5.53977 8.28388 5.00518L8.4213 4.39139C8.63487 3.55529 9.33774 3 10.1421 3H13.8586Z"
        fill="#404040"
      />
    </svg>
  );
}

export default Delete;
