import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Anamnesis,
  ChatsGetPaginatedMessagesResDto,
  CheckupResDto,
  EventsGetEventResDto,
  Expert,
  ExpertEducationDocumentStatusEnum,
  ExpertGetPaginatedPaymentsDiaryDto,
  ExpertGetPatientsResDto,
  ExpertSettings,
  GetChatRoomsResDto,
  GetPatientNutritonPlanResDto,
  Patient,
  PatientDiary,
  PatientRecommendationCardsGetResDto,
} from 'api/generated';
import moment from 'moment';
import { LoadingStatus } from 'types';

import {
  changeEventAsync,
  changeExpertAsync,
  createAndAssignCustomCheckUpForPatient,
  createChatAsync,
  createEventAsync,
  createPaymentsDiaryAsync,
  deleteEventAsync,
  deleteMeAsync,
  deletePaymentsDiaryAsync,
  expertCurrentEmailAsync,
  expertNewCodeEmailAsync,
  expertNewCurrentEmailAsync,
  expertSetCheckUpAsync,
  findMeAsync,
  getAllRecommendationsForPatient,
  getAnalyzesAsync,
  getAnamnesByIdAsync,
  getAssignedCheckupsForPatiens,
  getChatAsync,
  getEventsAsync,
  getNutritionPlansAsync,
  getPatientByIdAsync,
  getPatientDiaryByPatientIdAsync,
  getPatientsAsync,
  getPaymentsDiaryAsync,
  patientInvitationAsync,
  prescribeNutritionsPlansAsync,
  sortPatientsAsync,
  sortPaymentsDiaryAsync,
  togglePaymentsDiaryAsync,
  toggleSwitch,
  updateNutritionsPlansByIdAsync,
} from './thunks';
import { Calendar, Event, ExpertStore } from './types';

const initialState: ExpertStore = {
  me: {} as Expert,
  chat: [],
  messages: {} as ChatsGetPaginatedMessagesResDto,
  payments: {} as ExpertGetPaginatedPaymentsDiaryDto,
  patients: {} as ExpertGetPatientsResDto,
  anamnes: {} as Anamnesis,
  activeChat: {} as GetChatRoomsResDto,
  loading: LoadingStatus.idle,
  filters: { signUpStatus: undefined, onlyNewRequests: undefined },
  activeExpertPlan: {} as GetPatientNutritonPlanResDto,
  expertPlans: [],
  activePatient: {} as Patient,
  activePatientRecommendations: {
    firstTypeCards: [],
    secondTypeCards: [],
    thirdTypeCards: [],
  } as PatientRecommendationCardsGetResDto,
  calendar: {} as Calendar,
  patientDiary: [],
  loadingPaymentsStatus: LoadingStatus.idle,
  nutritionPlanStatus: LoadingStatus.idle,
  updatingExpertEmailStatus: LoadingStatus.idle,
  deletingExpertStatus: LoadingStatus.idle,
  changeExpertStatus: LoadingStatus.idle,
  getExpertStatus: LoadingStatus.idle,
  getExpertPatients: LoadingStatus.idle,
  getPatientDiaryStatus: LoadingStatus.idle,
  loadingFilesStatus: LoadingStatus.idle,
  loadingDeleteEventStatus: LoadingStatus.idle,
  loadingCalendarEventStatus: LoadingStatus.idle,
  loadingCalendarStatus: LoadingStatus.idle,
  getExpertPatientStatus: LoadingStatus.idle,
  getExpertChatRoomsStatus: LoadingStatus.idle,
  loadingGetMessages: LoadingStatus.idle,
  loadingSendMessage: LoadingStatus.idle,
  patientInvitationStatus: LoadingStatus.idle,
  expertPlanStatus: LoadingStatus.idle,
  analyzesStatus: LoadingStatus.idle,
  loadingAnamnesStatus: LoadingStatus.idle,
  loadingPatientSortStatus: LoadingStatus.idle,
  isSearchingPatientsOnDashboard: false,
  error: null,
  currentRequestId: undefined,
  setCheckupForPatientStatus: LoadingStatus.idle,
  setCustomCheckupForPatientStatus: LoadingStatus.idle,
  getAllRecommendationsForPatientStatus: LoadingStatus.idle,
  assignedCheckupsForPatients: [] as CheckupResDto[],
  assignedCheckupsForPatientsStatus: LoadingStatus.idle,
  isFilterOnlyNewPatientsOnDashboard: false,
  expertSettings: {} as ExpertSettings,
};

const ExpertSlice = createSlice({
  initialState,
  name: 'expert',
  reducers: {
    setdate: (state, action) => {
      state.calendar.date = action.payload.date;
      if (action.payload.view) {
        state.calendar.view = action.payload.view;
      }
      if (action.payload.onNavigate) {
        state.calendar.onView = action.payload.onView;
        state.calendar.onNavigate = action.payload.onNavigate;
      }
    },
    unsetExpert: () => initialState,
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    saveExpert: (state, action) => {
      state.me = action.payload;
    },
    updateExpertDocumentStatus: (state, { payload }: PayloadAction<ExpertEducationDocumentStatusEnum>) => {
      state.me = { ...state.me, educationDocumentStatus: payload, educationDocumentsSentAt: new Date().toISOString() };
    },
    setAvatar: (state, actions) => {
      state.me.avatar = actions.payload;
    },
    activeChat: (state, actions) => {
      state.activeChat = actions.payload;
    },
    completeOnboarding: (state, action) => {
      state.me = action.payload;
    },
    deleteChat: (state, action) => {
      state.chat = state.chat.filter((elem) => elem.id !== action.payload);
    },
    clearUnreadMessages: (state, action) => {
      state.chat = state.chat.map((elem) => {
        if (elem.id === action.payload) {
          elem.unreadMessagesCount.expert = 0;
        }
        return elem;
      });
    },
    addMessage: (state, action) => {
      state?.messages?.data?.push(action.payload);
    },
    updateChatDate: (state, { payload }: PayloadAction<Partial<{ chatId: string }>>) => {
      state.activeChat.updatedAt = moment().toString();
    },
    changeUnreadMessages: (state, action) => {
      state.chat = state.chat.map((elem) => {
        if (elem.id === action.payload) {
          elem.unreadMessagesCount.expert = elem.unreadMessagesCount.expert + 1;
        }
        return elem;
      });
    },
    changeIsSearchingPatientsOnDashboard: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchingPatientsOnDashboard = payload;
    },
    changeIsFilterOnlyNewPatientsOnDashboard: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterOnlyNewPatientsOnDashboard = payload;
    },
    deletePaymentsDiary: (state, { payload }: PayloadAction<string>) => {
      state.payments.data = state.payments?.data.filter((payment) => payment.id !== payload);
    },
    sortChatRoomsAfterSendMessage: (state, { payload }: PayloadAction<string>) => {
      const activeChat = state.chat.filter((chat) => chat.id === payload);
      const chatRoomsWithoutActive = state.chat.filter((chat) => chat.id !== payload);
      state.chat = activeChat.concat(chatRoomsWithoutActive);
    },
  },
  extraReducers: {
    [toggleSwitch.type]: (state, { payload }) => {
      state.payments.data = state.payments?.data.map((payment) => {
        if (payment.id === payload) {
          payment.paid = !payment.paid;
        }
        return payment;
      });
    },
    [togglePaymentsDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.payments.data = state.payments?.data.map((payment) => {
        if (payment.id === payload) {
          payment.paid = !payment.paid;
        }
        return payment;
      });
    },
    [expertCurrentEmailAsync.fulfilled.type]: (state) => {
      state.changeExpertStatus = LoadingStatus.fulfilled;
    },
    [expertCurrentEmailAsync.pending.type]: (state) => {
      state.changeExpertStatus = LoadingStatus.pending;
    },
    [expertCurrentEmailAsync.rejected.type]: (state, { payload }) => {
      state.changeExpertStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [expertNewCurrentEmailAsync.fulfilled.type]: (state, { payload }: PayloadAction<Expert>) => {
      state.me = payload;
    },
    [expertNewCodeEmailAsync.fulfilled.type]: (state) => {
      state.changeExpertStatus = LoadingStatus.fulfilled;
    },
    [expertNewCodeEmailAsync.pending.type]: (state) => {
      state.changeExpertStatus = LoadingStatus.pending;
    },
    [expertNewCodeEmailAsync.rejected.type]: (state, { payload }) => {
      state.changeExpertStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [changeExpertAsync.fulfilled.type]: (state, { payload }: PayloadAction<Expert>) => {
      state.getExpertStatus = LoadingStatus.fulfilled;
      state.me = payload;
    },
    [changeExpertAsync.pending.type]: (state) => {
      state.getExpertStatus = LoadingStatus.pending;
    },
    [changeExpertAsync.rejected.type]: (state, { payload }) => {
      state.getExpertStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [deleteMeAsync.fulfilled.type]: (state) => {
      state.deletingExpertStatus = LoadingStatus.idle;
    },
    [deleteMeAsync.pending.type]: (state) => {
      if (state.deletingExpertStatus === LoadingStatus.idle) {
        state.deletingExpertStatus = LoadingStatus.pending;
      }
    },
    [deleteMeAsync.rejected.type]: (state, { payload }) => {
      state.deletingExpertStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [createPaymentsDiaryAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [createPaymentsDiaryAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [createPaymentsDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPaymentsDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertGetPaginatedPaymentsDiaryDto>) => {
      state.loadingPaymentsStatus = LoadingStatus.fulfilled;
      if (state.payments.page) {
        state.payments.count = payload.count;
        state.payments.page = payload.page;
        state.payments.data = state.payments.data.concat(payload.data);
      } else {
        state.payments = payload;
      }
    },
    [getPaymentsDiaryAsync.pending.type]: (state) => {
      if (state.loadingPaymentsStatus === LoadingStatus.idle) {
        state.loadingPaymentsStatus = LoadingStatus.pending;
      }
    },
    [getPaymentsDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loadingPaymentsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [sortPaymentsDiaryAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<ExpertGetPaginatedPaymentsDiaryDto>,
    ) => {
      state.loadingPaymentsStatus = LoadingStatus.fulfilled;
      state.payments = payload;
    },
    [sortPaymentsDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loadingPaymentsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [sortPaymentsDiaryAsync.pending.type]: (state) => {
      if (state.loadingPaymentsStatus === LoadingStatus.idle) {
        state.loadingPaymentsStatus = LoadingStatus.pending;
      }
    },
    [deletePaymentsDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.payments.data = state.payments?.data.filter((payment) => payment.id !== payload);
    },
    [deletePaymentsDiaryAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [deletePaymentsDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPatientsAsync.rejected.type]: (state, { payload }) => {
      state.getExpertPatients = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPatientsAsync.pending.type]: (state) => {
      if (state.getExpertPatients === LoadingStatus.idle) {
        state.getExpertPatients = LoadingStatus.pending;
      }
    },
    [getPatientsAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertGetPatientsResDto>) => {
      //TODO: check and fix after new api
      state.patients = payload;
      // if (state.patients.nextCursor && state.patients.data) {
      //   state.patients.data = getUniqueList(state.patients.data.concat(payload.data), 'id');
      //   state.patients.hasMore = payload.hasMore;
      //   state.patients.nextCursor = payload.nextCursor;
      // } else {
      //   state.patients = payload;
      // }
      state.getExpertPatients = LoadingStatus.fulfilled;
    },
    [sortPatientsAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertGetPatientsResDto>) => {
      state.loadingPatientSortStatus = LoadingStatus.fulfilled;
      state.getExpertPatients = LoadingStatus.fulfilled;
      state.patients = payload;
    },
    [sortPatientsAsync.pending.type]: (state) => {
      if (state.getExpertPatients === LoadingStatus.idle || state.loadingPatientSortStatus === LoadingStatus.idle) {
        state.getExpertPatients = LoadingStatus.pending;
        state.loadingPatientSortStatus = LoadingStatus.pending;
      }
    },
    [sortPatientsAsync.rejected.type]: (state, { payload }) => {
      state.getExpertPatients = LoadingStatus.idle;
      state.loadingPatientSortStatus = LoadingStatus.idle;
      if (payload?.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload?.error;
      }
    },
    [findMeAsync.fulfilled.type]: (state, { payload }: PayloadAction<Expert>) => {
      state.getExpertStatus = LoadingStatus.fulfilled;
      state.me = payload;
    },
    [findMeAsync.pending.type]: (state) => {
      if (state.getExpertStatus === LoadingStatus.idle) {
        state.getExpertStatus = LoadingStatus.pending;
      }
    },
    [findMeAsync.rejected.type]: (state, { payload }) => {
      state.getExpertStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [patientInvitationAsync.pending.type]: (state) => {
      if (state.patientInvitationStatus === LoadingStatus.idle) {
        state.patientInvitationStatus = LoadingStatus.pending;
      }
    },
    [patientInvitationAsync.fulfilled.type]: (state) => {
      state.patientInvitationStatus = LoadingStatus.fulfilled;
    },
    [patientInvitationAsync.rejected.type]: (state, { payload }) => {
      state.patientInvitationStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getEventsAsync.fulfilled.type]: (state, { payload }: PayloadAction<EventsGetEventResDto[]>) => {
      state.calendar.events = payload.map((event) => {
        // const endDate = moment(event.beginning).unix() + event.duration;
        return {
          id: event.id,
          title: event.patientName,
          event: event.eventType,
          start: moment(event.beginning).toDate(),
          timeZone: event.timezone,
          end: moment(event.beginning).add(event.duration, 's').toDate(),
          beginning: event.beginning,
          duration: event.duration,
          cost: event.cost,
          patientId: event.patientId,
        };
      });
      state.loadingCalendarStatus = LoadingStatus.fulfilled;
    },
    [getEventsAsync.pending.type]: (state) => {
      state.loadingCalendarStatus = LoadingStatus.pending;
    },
    [changeEventAsync.pending.type]: (state) => {
      state.loadingCalendarEventStatus = LoadingStatus.pending;
    },
    [changeEventAsync.fulfilled.type]: (state, { payload }: PayloadAction<EventsGetEventResDto>) => {
      state.loadingCalendarEventStatus = LoadingStatus.fulfilled;
      const newEvent: Event = {
        id: payload.id,
        title: payload.patientName,
        event: payload.eventType,
        start: moment(payload.beginning).toDate(),
        timeZone: payload.timezone,
        end: moment(payload.beginning).add(payload.duration, 's').toDate(),
        beginning: payload.beginning,
        duration: payload.duration,
        cost: payload.cost,
        patientId: payload.patientId,
      };
      state.calendar.events = state.calendar.events.map((event: Event) => {
        if (event.id === payload.id) {
          event = newEvent;
        }
        return event;
      });
    },
    [changeEventAsync.rejected.type]: (state, { payload }) => {
      state.loadingCalendarEventStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [deleteEventAsync.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.calendar.events = state.calendar.events.filter((elem) => elem.id !== payload);
      state.loadingDeleteEventStatus = LoadingStatus.fulfilled;
    },
    [deleteEventAsync.pending.type]: (state) => {
      state.loadingDeleteEventStatus = LoadingStatus.pending;
    },
    [createEventAsync.fulfilled.type]: (state, { payload }: PayloadAction<EventsGetEventResDto>) => {
      state.loadingCalendarEventStatus = LoadingStatus.fulfilled;
      const newEvent: Event = {
        id: payload.id,
        title: payload.patientName,
        event: payload.eventType,
        start: moment(payload.beginning).toDate(),
        timeZone: payload.timezone,
        end: moment(payload.beginning).add(payload.duration, 's').toDate(),
        beginning: payload.beginning,
        duration: payload.duration,
        cost: payload.cost,
        patientId: payload.patientId,
      };
      state.calendar.events.push(newEvent);
    },
    [createEventAsync.pending.type]: (state) => {
      state.loadingCalendarEventStatus = LoadingStatus.pending;
    },
    [createEventAsync.rejected.type]: (state, { payload }) => {
      state.loadingCalendarEventStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPatientByIdAsync.pending.type]: (state) => {
      state.getExpertPatientStatus = LoadingStatus.pending;
      state.loadingAnamnesStatus = LoadingStatus.pending;
    },
    [getPatientByIdAsync.fulfilled.type]: (state, { payload }: PayloadAction<Patient>) => {
      state.getExpertPatientStatus = LoadingStatus.fulfilled;
      state.loadingAnamnesStatus = LoadingStatus.fulfilled;
      state.activePatient = payload;
    },
    [getPatientByIdAsync.rejected.type]: (state, { payload }) => {
      state.getExpertPatientStatus = LoadingStatus.idle;
      state.loadingAnamnesStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPatientDiaryByPatientIdAsync.pending.type]: (state) => {
      state.getPatientDiaryStatus = LoadingStatus.pending;
    },
    [getPatientDiaryByPatientIdAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientDiary[]>) => {
      state.getPatientDiaryStatus = LoadingStatus.idle;
      state.patientDiary = payload;
    },
    [getPatientDiaryByPatientIdAsync.rejected.type]: (state, { payload }) => {
      state.getPatientDiaryStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAnalyzesAsync.fulfilled.type]: (state) => {
      state.analyzesStatus = LoadingStatus.fulfilled;
    },
    [getAnalyzesAsync.pending.type]: (state) => {
      state.analyzesStatus = LoadingStatus.pending;
    },

    [getAnamnesByIdAsync.fulfilled.type]: (state, { payload }: PayloadAction<Anamnesis>) => {
      state.loadingAnamnesStatus = LoadingStatus.fulfilled;
      state.anamnes = payload;
    },
    [getAnamnesByIdAsync.pending.type]: (state) => {
      state.loadingAnamnesStatus = LoadingStatus.pending;
    },
    [getAnamnesByIdAsync.rejected.type]: (state, { payload }) => {
      state.loadingAnamnesStatus = LoadingStatus.idle;
      state.anamnes = {} as Anamnesis;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getChatAsync.pending.type]: (state) => {
      state.getExpertChatRoomsStatus = LoadingStatus.pending;
    },
    [getChatAsync.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: Array<GetChatRoomsResDto>;
        withSearch: boolean;
      }>,
    ) => {
      state.getExpertChatRoomsStatus = LoadingStatus.idle;
      state.chat = payload.data;
    },
    [createChatAsync.fulfilled.type]: (state, { payload }: PayloadAction<GetChatRoomsResDto>) => {
      const newChat = [payload];
      const oldChatRooms = state.chat;
      state.chat = newChat.concat(oldChatRooms);
    },
    // [submitPromoCodeAsync.fulfilled.type]: (state, { payload }: PayloadAction<void>) => {
    //   state.me = payload;
    // },
    [getNutritionPlansAsync.fulfilled.type]: (state, { payload }: PayloadAction<GetPatientNutritonPlanResDto[]>) => {
      state.nutritionPlanStatus = LoadingStatus.fulfilled;
      state.expertPlans = payload.map((elem) => {
        if (elem.id === state.activeExpertPlan.nutritionPlanId) {
          return state.activeExpertPlan;
        } else {
          return elem;
        }
      });
    },
    [getNutritionPlansAsync.pending.type]: (state) => {
      state.nutritionPlanStatus = LoadingStatus.pending;
    },
    [getNutritionPlansAsync.rejected.type]: (state, payload) => {
      state.nutritionPlanStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [prescribeNutritionsPlansAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<GetPatientNutritonPlanResDto>,
    ) => {
      state.expertPlanStatus = LoadingStatus.fulfilled;
      state.expertPlans = state.expertPlans.map((plan) => {
        if (plan.id === payload.nutritionPlanId) {
          return payload;
        } else {
          return plan;
        }
      });
    },
    [prescribeNutritionsPlansAsync.pending.type]: (state) => {
      state.expertPlanStatus = LoadingStatus.pending;
    },
    [prescribeNutritionsPlansAsync.rejected.type]: (state, { payload }) => {
      state.expertPlanStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updateNutritionsPlansByIdAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<GetPatientNutritonPlanResDto>,
    ) => {
      state.expertPlans = state.expertPlans.map((plan) => {
        if (plan.id === payload.id) {
          plan = payload;
        }
        return plan;
      });
    },
    [expertSetCheckUpAsync.fulfilled.type]: (state) => {
      state.setCheckupForPatientStatus = LoadingStatus.fulfilled;
    },
    [expertSetCheckUpAsync.pending.type]: (state) => {
      state.setCheckupForPatientStatus = LoadingStatus.pending;
    },
    [expertSetCheckUpAsync.rejected.type]: (state, { payload }) => {
      state.setCheckupForPatientStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [createAndAssignCustomCheckUpForPatient.fulfilled.type]: (state) => {
      state.setCustomCheckupForPatientStatus = LoadingStatus.fulfilled;
    },
    [createAndAssignCustomCheckUpForPatient.pending.type]: (state) => {
      state.setCustomCheckupForPatientStatus = LoadingStatus.pending;
    },
    [createAndAssignCustomCheckUpForPatient.rejected.type]: (state, { payload }) => {
      state.setCustomCheckupForPatientStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAllRecommendationsForPatient.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PatientRecommendationCardsGetResDto>,
    ) => {
      state.getAllRecommendationsForPatientStatus = LoadingStatus.fulfilled;
      state.activePatientRecommendations = payload;
    },
    [getAllRecommendationsForPatient.pending.type]: (state) => {
      state.getAllRecommendationsForPatientStatus = LoadingStatus.pending;
    },
    [getAllRecommendationsForPatient.rejected.type]: (state, { payload }) => {
      state.getAllRecommendationsForPatientStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAssignedCheckupsForPatiens.fulfilled.type]: (state, { payload }: PayloadAction<CheckupResDto[]>) => {
      state.assignedCheckupsForPatients = payload;
      state.assignedCheckupsForPatientsStatus = LoadingStatus.idle;
    },
    [getAssignedCheckupsForPatiens.pending.type]: (state) => {
      if (state.assignedCheckupsForPatientsStatus === LoadingStatus.idle) {
        state.assignedCheckupsForPatientsStatus = LoadingStatus.pending;
      }
    },
    [getAssignedCheckupsForPatiens.rejected.type]: (state, { payload }) => {
      state.assignedCheckupsForPatientsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const actions = {
  ...ExpertSlice.actions,
};

export const { reducer } = ExpertSlice;
