import { Analysis } from 'api/generated';
import { FileIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectActivePatient } from 'features/expert/selectors';
import moment from 'moment';
import { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';

import styles from './styles.module.scss';

type AnalysisCardProps = {
  analysis: Analysis;
  fromHistory?: boolean;
};

export const AnalysisCard: FC<AnalysisCardProps> = ({ analysis, fromHistory }) => {
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'patient' });

  const { date, analysisKindId } = analysis;
  const formattedDate =
    i18n.language === 'ru'
      ? moment(date).locale('ru').format('DD MMMM, YYYY')
      : moment(date).locale('en-gb').format('DD MMMM, YYYY');

  const patientData = useSelector(selectActivePatient);
  const analysisKinds = useAppSelector((state) => state.analyzes.analysisKinds);
  const analysisKindLabel = analysisKindId
    ? analysisKinds.find((ak) => ak.id === analysisKindId)?.label
    : t('individualAnalisis');

  return (
    <Link
      to={{
        pathname: CommonRoutes.ANALYSIS(analysis.id),
        state: { fromHistory, activePatientId: patientData.id },
      }}
      className={classnames('flexbox', 'justify-start', styles['analysis-card'])}>
      <div className={classnames('flexbox', 'justify-center', 'align-center', styles.icon)}>
        <FileIcon />
      </div>
      <div className={styles.content}>
        <p className={classnames('headline', styles['content-date'])}>{formattedDate}</p>
        <Dotdotdot tagName="p" clamp={1} className={classnames('body', 'body_medium', styles['content-age'])}>
          {analysisKindLabel}
        </Dotdotdot>
      </div>
    </Link>
  );
};
