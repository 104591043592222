import { SubscriptionDtoStatusEnum } from 'api/generated';
import { selectActivePromocode } from 'features/promocodes/selectors';
import { selectSubscription } from 'features/subscriptions/selectors';
import { useAppSelector } from 'store/reducers';

export const useUserAccess = (): boolean => {
  const subscription = useAppSelector(selectSubscription);
  const activePromocode = useAppSelector(selectActivePromocode);

  const hasActivePromocode = !!activePromocode;

  const hasActiveSubscription =
    subscription.status === SubscriptionDtoStatusEnum.Active ||
    subscription.status === SubscriptionDtoStatusEnum.CanceledActive ||
    subscription.status === SubscriptionDtoStatusEnum.PromocodeActive;

  const hasUserAccess = hasActivePromocode || hasActiveSubscription;

  return hasUserAccess;
};
