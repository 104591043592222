import { PatientGenderEnum } from 'api/generated';

import { RolesEnum } from '../features/auth/types';
import { RootState } from '../store/types';

type NormFieldGenderPostfix = 'Male' | 'Female';

const normFieldGenderPostfixMapping: Record<PatientGenderEnum, NormFieldGenderPostfix> = {
  [PatientGenderEnum.Male]: 'Male',
  [PatientGenderEnum.Female]: 'Female',
};
type LowerFields = 'lowerNormFemale' | 'lowerNormMale';
type UpperFields = 'upperNormFemale' | 'upperNormMale';

type NormFields = {
  lowerNormField: LowerFields;
  upperNormField: UpperFields;
};

export const getNormFields = (userGender: PatientGenderEnum): NormFields => {
  const normFieldGenderPostfix = normFieldGenderPostfixMapping[userGender];
  const normFields = {
    lowerNormField: `lowerNorm${normFieldGenderPostfix}` as unknown as LowerFields,
    upperNormField: `upperNorm${normFieldGenderPostfix}` as unknown as UpperFields,
  };

  return normFields;
};

export const userByRoleMapping: Record<RolesEnum, keyof Pick<RootState, 'patient' | 'expert'>> = {
  [RolesEnum.PATIENT]: 'patient',
  [RolesEnum.EXPERT]: 'expert',
};
