import useWindowWidth from 'hooks/useWindowWidth';
import { FC, RefObject } from 'react';
import { Link } from 'react-router-dom';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { CommonRoutes, PatientRoutes } from 'types/routes';
import { LanguageSelector } from 'UIcomponents/LanguageSelector';

import styles from './styles.module.scss';

type UserProfileMenuProps = {
  forwardedRef: RefObject<HTMLElement>;
  isActive?: boolean;
};

export const UserProfileMenu: FC<UserProfileMenuProps> = ({ forwardedRef, isActive = false }) => {
  const dispatch = useAppDispatch();
  const width = useWindowWidth();

  const handleSignOut = () => {
    dispatch(actions.authActions.signOut());
  };

  return (
    <div ref={forwardedRef as React.RefObject<HTMLDivElement>} className={styles['main-menu']}>
      {isActive && (
        <>
          <Link to={CommonRoutes.CHECK_UPS} className={styles['menu-item']}>
            Чек-апы
          </Link>
          <Link to={width <= 760 ? PatientRoutes.SETTINGS : PatientRoutes.PROFILE} className={styles['menu-item']}>
            Профиль
          </Link>
        </>
      )}
      <LanguageSelector />
      <span
        className={styles['menu-item']}
        onClick={handleSignOut}
        onKeyDown={handleSignOut}
        role="button"
        tabIndex={0}>
        Выйти
      </span>
    </div>
  );
};
