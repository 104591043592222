/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BiogeekExpertPatientSignUpDto,
  BiogeekHealthPatientSignUpDto,
  ExpertAuthResDto,
  ExpertForgotPasswordDto,
  ExpertRestorePasswordDto,
  ExpertSignInDto,
  ExpertSignUpDto,
  ExpertSignUpVerificationDto,
  PatientAuthResDto,
  PatientSignInDto,
  PatientSignUpVerificationDto,
} from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SignInValidationErrors } from './types';

export const patientSignInAsync = createAsyncThunk<
  PatientAuthResDto,
  PatientSignInDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('patients/sign-in', async (userData: PatientSignInDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSignIn(userData, { params: { l: language } });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientSignUpHashAsync = createAsyncThunk<
  PatientAuthResDto,
  BiogeekExpertPatientSignUpDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('patients/sign-up', async (userData: BiogeekExpertPatientSignUpDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSignUpPatient(userData, { params: { l: language } });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientSignUpAsync = createAsyncThunk<
  PatientAuthResDto,
  BiogeekHealthPatientSignUpDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('patients/sign-up', async (userData: BiogeekHealthPatientSignUpDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSignUp(userData, { params: { l: language } });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientGetVerificationCodeAsync = createAsyncThunk<
  string,
  PatientSignUpVerificationDto,
  ExtraParamsThunkType<SignInValidationErrors>
>(
  '/patients/sign-up/verification',
  async (userData: PatientSignUpVerificationDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerSendSignUpVerificationCode(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertSignInAsync = createAsyncThunk<
  ExpertAuthResDto,
  ExpertSignInDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('/experts/sign-in', async (userData: ExpertSignInDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSignIn(userData, { params: { l: language } });

    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertSignUpAsync = createAsyncThunk<
  ExpertAuthResDto,
  ExpertSignUpDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('/experts/sign-up', async (userData: ExpertSignUpDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSignUp(userData, { params: { l: language } });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertGetVerificationCodeAsync = createAsyncThunk<
  string,
  ExpertSignUpVerificationDto,
  ExtraParamsThunkType<SignInValidationErrors>
>(
  '/experts/sign-up/verification',
  async (userData: ExpertSignUpVerificationDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsApi.expertsControllerSendSignUpVerificationCode(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertSendRestorePasswordLinkAsync = createAsyncThunk<
  void,
  ExpertForgotPasswordDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('auth/sendRestorePasswordLink', async (userData: ExpertForgotPasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerSendRestorePasswordLink(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const expertRestorePasswordAsync = createAsyncThunk<
  void,
  ExpertRestorePasswordDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('auth/restorePassword', async (userData: ExpertRestorePasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerRestorePassword(userData, { params: { l: language } });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
