import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgPrinter(props: React.SVGProps<SVGSVGElement>) {
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);

  return (
    <svg ref={ref} width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6a2 2 0 00-2 2v7a2 2 0 002 2h3v4a1 1 0 001 1h6a1 1 0 001-1v-4h3a2 2 0 002-2V8a2 2 0 00-2-2H5zm12.5 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14 14h-4v6h4v-6z"
        fill="#262626"
      />
      <rect opacity={0.3} x={8} y={2} width={8} height={2} rx={1} fill="#262626" />
    </svg>
  );
}

export default SvgPrinter;
