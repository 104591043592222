import * as React from 'react';

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="#D9E5FF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" />
      <rect x={6} y={11} width={9} height={2} rx={1} fill={props.color ? props.color : '#A1A7FF'} />
      <rect x={6} y={15} width={5} height={2} rx={1} fill={props.color ? props.color : '#A1A7FF'} />
    </svg>
  );
}

export default SvgFile;
