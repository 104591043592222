import { ArrowIcon, CalendarIcon } from 'assets/svg';
import classnames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useWindowWidth from 'hooks/useWindowWidth';
import moment from 'moment';
import { CSSProperties, FC, useRef, useState } from 'react';
import { Modifier } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'UIcomponents';

import styles from './styles.module.scss';

type DatePickerDropdownProps = {
  selectedDay: Date;
  setSelectedDay: (date: Date) => void;
  customStyles?: CSSProperties;
  required?: boolean;
  disabled?: boolean;
  leftCalendarPosition?: boolean;
  variant?: 'small' | 'max-content';
  icon?: 'arrow' | 'calendar';
  error?: 'error' | 'warning';
  disabledDays?: Modifier | Modifier[];
  bigHeightMobile?: boolean;
};

export const DatePickerDropdown: FC<DatePickerDropdownProps> = ({
  selectedDay,
  setSelectedDay,
  customStyles,
  required = false,
  disabled = false,
  leftCalendarPosition = false,
  variant = 'small',
  icon = 'arrow',
  error,
  disabledDays,
  bigHeightMobile = false,
}) => {
  const { i18n } = useTranslation();

  const [show, setShow] = useState<boolean>(false);
  const windowWidth = useWindowWidth();

  const ref = useRef(null);

  const handleClickOutside = () => {
    setShow(false);
  };

  const formattedDate =
    i18n.language === 'ru'
      ? moment(selectedDay)
          .locale('ru')
          .format(windowWidth < 760 ? 'D MMM, YYYY' : 'D MMMM, YYYY')
          .replace('.', '')
      : moment(selectedDay)
          .locale('en-gb')
          .format(windowWidth < 760 ? 'D MMM, YYYY' : 'D MMMM, YYYY')
          .replace('.', '');

  useOnClickOutside(ref, handleClickOutside);
  return (
    <div
      className={classnames(styles.dropdown, {
        [styles.dropdown_max]: variant === 'max-content',
      })}
      ref={ref}
      style={customStyles}>
      <div
        className={classnames(styles.dropdown__wrapper, {
          [styles.dropdown__wrapper_max]: variant === 'max-content',
          [styles[`dropdown__wrapper_${error}`]]: error,
          [styles['dropdown__wrapper_big']]: bigHeightMobile,
        })}
        onClick={() => setShow(!show)}
        role="button"
        tabIndex={0}
        onKeyUp={() => setShow(!show)}>
        <input
          className={styles.dropdown__input}
          required={required}
          value={formattedDate}
          disabled={disabled}
          spellCheck="false"
          type="text"
          readOnly
        />
        <div
          className={classnames(styles.dropdown__icon, {
            [styles.dropdown__icon_show]: show && icon === 'arrow',
          })}>
          {icon === 'arrow' ? <ArrowIcon /> : <CalendarIcon />}
        </div>
      </div>
      {show && !disabled && (
        <div
          className={classnames(styles.dropdown__body, {
            [styles.dropdown__body_left]: leftCalendarPosition,
          })}>
          <DatePicker
            selectedDay={new Date(selectedDay)}
            setSelectedDay={setSelectedDay}
            disabledDays={disabledDays}
            onCloseDatePicker={handleClickOutside}
          />
        </div>
      )}
    </div>
  );
};
