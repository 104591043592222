import classnames from 'classnames';
import { FC } from 'react';

import styles from '../../styles.module.scss';

type Props = {
  title: string;
  tabName: string;
  withoutBackground?: boolean;
};

export const Tab: FC<Props> = ({ children, tabName, withoutBackground = false }) => {
  return (
    <div
      className={classnames(styles['tab-content'], styles[tabName], {
        [styles['tab-content_no-background']]: withoutBackground,
      })}>
      {children}
    </div>
  );
};
