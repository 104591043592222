import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import EmptyUser from 'assets/svg/EmptyUser';
import classNames from 'classnames';
import { selectExpert } from 'features/expert/selectors';
import { useToggle } from 'hooks/useToggle';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';

import { AddPatientForm } from '../AddPatientForm';
import styles from './styles.module.scss';

interface EmptyStateProps {
  hasAccess: boolean;
}

export const EmptyState: FC<EmptyStateProps> = ({ hasAccess = true }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.emptyState' });
  const { isOpened, onOpenToggle, onCloseToggle } = useToggle();

  const me = useAppSelector(selectExpert);
  const { educationDocumentStatus } = me;

  const isApproved = educationDocumentStatus === ExpertEducationDocumentStatusEnum.Approved;

  return (
    <>
      <div className={styles['empty-state__wraper']}>
        <div className={styles['empty-state']}>
          <div className={styles['empty-state__content']}>
            <div className={styles['empty-state__icon']}>
              <EmptyUser />
            </div>
            <div className={styles['empty-state__title']}>
              <h6>{t('emptyList')}</h6>
            </div>
            <div
              className={classNames(
                styles['empty-state__subtitle'],
                styles.subhead,
                styles['empty-state__subtitle_grey'],
              )}>
              {isApproved ? (
                <p className={styles['empty-state__text']}>{t('addNewPatient')}</p>
              ) : (
                <p className={styles['empty-state__text']}>{t('waitingModeration')}</p>
              )}
            </div>
            <ButtonDefault
              text={t('addPatient')}
              onClick={onOpenToggle}
              isDisabled={!hasAccess}
              customStyles={{ maxWidth: '258px' }}
            />
          </div>
        </div>
      </div>
      {isOpened && <AddPatientForm onClose={onCloseToggle} isVisible={true} />}
    </>
  );
};
