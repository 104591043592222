import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChatsGetPaginatedMessagesResDto,
  ChatsSendMessageDto,
  DocumentUploadSignedUrlDto,
  FileUploadSignedUrlResDto,
  MessageAttachment,
} from 'api/generated';
import { notify } from 'services/notificationService';
import { ExtraParamsThunkType } from 'store/types';

import { actions } from './slice';
import { ChatValidationsErrors } from './types';

export const clearUnreadMessagesAsync = createAsyncThunk<string, string, ExtraParamsThunkType<ChatValidationsErrors>>(
  '/chat/clearUnread',
  async (messageDto: string, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.chatsControllerClearUnreadMessagesCount(messageDto);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const addMessagesAsync = createAsyncThunk<
  ChatsGetPaginatedMessagesResDto,
  GetMessagesDto,
  ExtraParamsThunkType<ChatValidationsErrors>
>('/chat/addMessages', async (messageDto: GetMessagesDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { id, limit, cursor } = messageDto;
    const { data } = await api.ChatApi.chatsControllerGetMessages(id, limit, cursor);
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface GetMessagesDto {
  id: string;
  limit: string;
  cursor?: string;
  sortDirection?: 'ASC' | 'DESC';
}

export const getMessagesAsync = createAsyncThunk<
  ChatsGetPaginatedMessagesResDto,
  GetMessagesDto,
  ExtraParamsThunkType<ChatValidationsErrors>
>('/chat/getMessage', async (messageDto: GetMessagesDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { id, limit, cursor } = messageDto;
    const { data } = await api.ChatApi.chatsControllerGetMessages(id, limit, cursor);
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface SendMessage {
  id: string;
  message: ChatsSendMessageDto;
}

export const sendMessageAsync = createAsyncThunk<void, SendMessage, ExtraParamsThunkType<ChatValidationsErrors>>(
  '/chat/sendMessage',
  async (messageDto: SendMessage, { dispatch, extra: { api }, rejectWithValue }) => {
    try {
      const { id, message } = messageDto;
      const { data } = await api.ChatApi.chatsControllerSendMessage(id, message);
      return data;
    } catch (err) {
      if (err?.response.data?.statusCode == 404) {
        dispatch(actions.clearChat());
        notify('error', 'Чат был удален экспертом');
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createChatSignedUrlAsync = createAsyncThunk<
  FileUploadSignedUrlResDto,
  DocumentUploadSignedUrlDto,
  ExtraParamsThunkType<ChatValidationsErrors>
>('/chats/signed-url-to-upload', async (documents: DocumentUploadSignedUrlDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.chatsControllerGetAttachmentsSignedUrlToUpload(documents);
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAttachmentsAsync = createAsyncThunk<
  Array<MessageAttachment>,
  string,
  ExtraParamsThunkType<ChatValidationsErrors>
>('/chats/attachments', async (id: string, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.chatsControllerGetAttachments(id);
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
