import { Check } from 'assets/svg';
import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';

import styles from './styles.module.scss';

interface CustomCheckboxProps {
  name: string;
  title: string;
  control: Control<any>;
  customOnBlur?: any;
}
export const CustomCheckbox: FC<CustomCheckboxProps> = ({ name, control, title, customOnBlur }) => {
  const {
    field: { ref, ...checkboxProps },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <label className={styles.container}>
        <input
          type="checkbox"
          ref={ref}
          checked={checkboxProps.value as boolean}
          {...checkboxProps}
          onBlur={(e) => {
            checkboxProps.onBlur();
            customOnBlur(checkboxProps.name);
          }}
        />
        <span className={styles.checkmark}>
          <Check viewBox="0 0 24 24" width={16} height={16} pathFill="var(--white)" className={styles.check} />
        </span>
        {title}
      </label>
    </>
  );
};
