import { GetChatRoomsResDto } from 'api/generated';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActiveChat } from 'features/chat/selectors';
import { actions } from 'features/chat/slice';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { DEFAULT_AVATAR } from 'types/user';
import stringCircumcision from 'utils/stringCircumcision';

import styles from './styles.module.scss';

interface ItemListProps {
  chat: GetChatRoomsResDto;
}
export const ItemList: FC<ItemListProps> = ({ chat }) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const activeChat = useAppSelector(selectActiveChat);
  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);

  const isExpert = role === RolesEnum.EXPERT;

  const toggleActive = () => {
    dispatch(actions.activeChat(chat));
    setIsActive(true);
  };

  useEffect(() => {
    if (chat.id !== activeChat.id) {
      setIsActive(false);
    }
  }, [activeChat]);

  return (
    <button className={classnames(styles['letter'], isActive && styles['letter__active'])} onClick={toggleActive}>
      <div className={classnames(styles['letter__content'])}>
        {
          <div
            className={styles['letter__circle']}
            style={{
              background: `center / cover no-repeat url(${chat?.avatar || DEFAULT_AVATAR})`,
            }}></div>
        }
        <div className={classnames(styles['letter__info'])}>
          <div className={classnames('flexbox', 'align-center', 'justify-between', styles['letter__container'])}>
            <p className={styles['letter__info-title']}>{chat.name}</p>
            {isExpert ? (
              <>
                {chat.unreadMessagesCount.expert !== 0 && (
                  <div className={styles['letter__notification']}>{chat.unreadMessagesCount.expert}</div>
                )}
              </>
            ) : (
              <>
                {chat.unreadMessagesCount.patient !== 0 && (
                  <div className={styles['letter__notification']}>{chat.unreadMessagesCount.patient}</div>
                )}
              </>
            )}
          </div>
          <div className={classnames('flexbox', 'align-center', 'justify-between', styles['letter__container'])}>
            {chat.lastMessage?.text && (
              <>
                <p className={classnames(styles['letter__info-subtitle'], styles.subhead)}>
                  {stringCircumcision(String(chat.lastMessage.text))}
                </p>
                <p className={styles['letter__info-date']}>{moment(chat.lastMessage.createdAt).format('HH:mm')}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </button>
  );
};
