import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextArea } from 'UIcomponents';

import { NutritionPlanFormValues } from '../NutritionPlanItem/NutritionPlanItem';
import styles from './style.module.scss';

interface ExpectationsBlockInterface {
  text: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<NutritionPlanFormValues, object>;
  isEditMode?: boolean;
}

export const ExpectationsBlock: FC<ExpectationsBlockInterface> = ({ text, control, isEditMode = false }) => {
  return (
    <>
      {isEditMode ? (
        <div className={styles.waiting}>
          <h6 className={styles['waiting__title']}>Что ожидать?</h6>
          <Controller
            name={'additionalInfo'}
            // defaultValue={text}
            rules={{
              required: 'Обязательное поле',
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea value={value} onChange={onChange} disabled={!isEditMode} />
            )}
          />
        </div>
      ) : (
        <div className={styles.waiting}>
          <h6 className={styles['waiting__title']}>Что ожидать?</h6>
          <div
            className={styles['waiting__text']}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
      )}
    </>
  );
};
