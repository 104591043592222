import { CalendarArrowLeft, CalendarArrowRight, EditIcon } from 'assets/svg';
import classnames from 'classnames';
import { BaseModal } from 'components';
import { actions } from 'features/expert';
import { selectCalendar, selectExpert } from 'features/expert/selectors';
import useWindowWidth from 'hooks/useWindowWidth';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ToolbarProps, View } from 'react-big-calendar';
import { Modifier } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';

import styles from '../../styles.module.scss';
import { DatePickerCalendar } from '../DatePickerCalendar';
import { NewEventModal } from '../NewEventModal';

export const Toolbar: React.ComponentType<ToolbarProps> = ({ onNavigate, ...props }: ToolbarProps) => {
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'calendar' });

  const dispatch = useAppDispatch();
  const calendarProps = useSelector(selectCalendar);
  const me = useSelector(selectExpert);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [isOpenNewEventModal, setIsOpenNewEventModal] = useState(false);
  const width = useWindowWidth();

  const toggleDatePicker = () => {
    setIsOpenDatePicker((state) => !state);
  };

  const toggleNewEventModal = () => {
    setIsOpenNewEventModal((state) => !state);
  };

  const disabledDays: Modifier | Modifier[] = {
    before: new Date(me.createdAt),
    after: new Date(new Date().setMonth(new Date().getMonth() + 4)),
  };

  useEffect(() => {
    dispatch(actions.setdate({ date: props.date, onNavigate: onNavigate, onView: props.onView, view: 'month' }));
  }, []);

  useEffect(() => {
    dispatch(actions.setdate({ date: props.date }));
  }, [props.date]);

  const getTitleDate = (date: Date, view?: View): string | undefined => {
    if (view === 'month')
      return i18n.language === 'ru'
        ? moment(date).locale('ru').format('MMMM YYYY')
        : moment(date).locale('en-gb').format('MMMM YYYY');

    if (view === 'week') {
      const monthYearDate =
        i18n.language === 'ru'
          ? moment(date).locale('ru').format('D MMMM YYYY').split(' ')
          : moment(date).locale('en-gb').format('D MMMM YYYY').split(' ');

      monthYearDate.shift();

      return i18n.language === 'ru'
        ? moment(date).locale('ru').startOf('week').toDate().getDate() +
            '-' +
            moment(date).locale('ru').endOf('week').toDate().getDate() +
            ' ' +
            monthYearDate.join(' ')
        : moment(date).locale('en-gb').startOf('week').toDate().getDate() +
            '-' +
            moment(date).locale('en-gb').endOf('week').toDate().getDate() +
            ' ' +
            monthYearDate.join(' ');
    }

    if (view === 'day')
      return i18n.language === 'ru'
        ? moment(date).locale('ru').format('D MMMM YYYY')
        : moment(date).locale('en-gb').format('D MMMM YYYY');
  };

  const titleDate = getTitleDate(calendarProps.date, calendarProps.view);
  const titleCalendarePropsDateFormat = moment(calendarProps.date).format('DD MMMM YYYY');

  return (
    <div className={styles.toolbar__container}>
      {width < 968 && (
        <div className={styles.toolbar__navigation}>
          <div className={styles.toolbar__date}>
            <button className={styles['toolbar__title-button']} onClick={toggleDatePicker}>
              <h4 className={styles.toolbar__title}>{titleDate ? titleDate : titleCalendarePropsDateFormat}</h4>
            </button>
            <div className={styles.toolbar__arrows}>
              <button className={styles.toolbar__arrow} onClick={() => onNavigate('PREV')}>
                <CalendarArrowLeft />
              </button>
              <button className={styles.toolbar__arrow} onClick={() => onNavigate('NEXT')}>
                <CalendarArrowRight />
              </button>
            </div>
          </div>
          <div>
            {calendarProps.view === 'day' &&
              moment(new Date()).format('YYYY MM DD') <= moment(props.date).format('YYYY MM DD') && (
                <ButtonDefault
                  variant={'secondary'}
                  onClick={toggleNewEventModal}
                  customStyles={{
                    minWidth: '38px',
                    maxWidth: '38px',
                    height: '38px',
                    padding: 0,
                  }}>
                  <EditIcon style={{ width: '16px' }} />
                </ButtonDefault>
              )}

            {isOpenDatePicker && (
              <BaseModal
                onClose={toggleDatePicker}
                isVisible
                isNotVisibleCross
                withoutBackground
                customStyles={{ padding: 0, width: 'max-content' }}>
                <div className={styles['toolbar__calendar']}>
                  <DatePickerCalendar
                    calendarProps={calendarProps}
                    onCloseDatePicker={() => console.log('close')}
                    selectedDay={new Date(calendarProps.date)}
                    disabledDays={disabledDays}
                  />
                </div>
              </BaseModal>
            )}
            {isOpenNewEventModal && calendarProps.view === 'day' && (
              <NewEventModal
                isChange={true}
                id=""
                start={new Date(props.date).toISOString()}
                event={undefined}
                onClose={toggleNewEventModal}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles['toolbar__navigation-buttons']}>
        <button
          className={classnames(styles.toolbar, props.view === 'day' && styles.toolbar__active)}
          onClick={() => {
            props.onView('day');
            dispatch(actions.setdate({ view: 'day', date: props.date }));
          }}>
          {t('day')}
        </button>
        <button
          className={classnames(styles.toolbar, props.view === 'week' && styles.toolbar__active)}
          onClick={() => {
            props.onView('week');
            dispatch(actions.setdate({ view: 'week', date: props.date }));
          }}>
          {t('week')}
        </button>
        <button
          className={classnames(styles.toolbar, props.view === 'month' && styles.toolbar__active)}
          onClick={() => {
            props.onView('month');
            dispatch(actions.setdate({ view: 'month', date: props.date }));
          }}>
          {t('month')}
        </button>
      </div>
    </div>
  );
};
