import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCreateCustomCheckupDto } from 'api/generated';
import classNames from 'classnames';
import { BaseModal } from 'components/BaseModal';
import { selectSetCheckupForPatientStatus } from 'features/expert/selectors';
import { createAndAssignCustomCheckUpForPatient } from 'features/expert/thunks';
import { PatientDropdownProps } from 'pages/CheckUps/CheckUps';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ExpertRoutes } from 'types/routes';
import { ButtonDefault, DropdownDefault, FormLabel, Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

type SetPatientCheckupModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  patientsDropdown: PatientDropdownProps[];
  checkupName: string;
  biomarkerIds: string[];
};

interface FormValues {
  patientId: string;
}

export const SetCustomEditedCheckupModal: FC<SetPatientCheckupModalProps> = ({
  isModalOpened,
  onCloseModal,
  patientsDropdown,
  checkupName,
  biomarkerIds,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const history = useHistory();
  const dispatch = useAppDispatch();

  const isSetCheckUpLoading = useAppSelector(selectSetCheckupForPatientStatus) === LoadingStatus.pending;

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    const checkup: ExpertCreateCustomCheckupDto = {
      name: checkupName,
      biomarkersIds: biomarkerIds,
      patientId: data.patientId,
    };

    dispatch(createAndAssignCustomCheckUpForPatient(checkup))
      .then(unwrapResult)
      .then(() => {
        notify('success', t('checkUpAssign'));
        onCloseModal();
        history.push(`${ExpertRoutes.PATIENT_PROFILE(data.patientId)}/analyzes`);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  return (
    <BaseModal title={t('checkUpAssignModalTitle') as string} isVisible={isModalOpened} onClose={onCloseModal}>
      <form className={styles.modal__form} onSubmit={handleSubmit(onSubmit)}>
        {patientsDropdown ? (
          <div className={styles.modal__inner}>
            <FormLabel text={t('patient')} />
            <Controller
              name="patientId"
              control={control}
              render={({ field: { onChange } }) => (
                <DropdownDefault
                  options={patientsDropdown}
                  placeholder={t('selectPatient') as string}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  isSearchable={false}
                  variant="max-content"
                />
              )}
            />
          </div>
        ) : (
          <div className={classNames('flexbox', 'align-center', 'justify-center')} style={{ height: '70px' }}>
            <Spinner variant="secondary" radius={30} />
          </div>
        )}
        <ButtonDefault
          text={t('assign') as string}
          variant="primary"
          type="submit"
          customStyles={{ margin: 0 }}
          containerClassNames={styles.modal__button}
          isLoading={isSetCheckUpLoading}
          isDisabled={!isDirty}
        />
      </form>
    </BaseModal>
  );
};
