import { RepliedMessage } from 'api/generated';
import { CloseIcon } from 'assets/svg';
import classnames from 'classnames';
import moment from 'moment';
import { FC } from 'react';

import styles from './styles.module.scss';

interface RepliedMessageProps extends RepliedMessage {
  getCurrentMessage?: (id: string) => void;
  hasChat: boolean;
  responseDelete?: () => void;
}

export const Response: FC<RepliedMessageProps> = ({
  createdAt,
  sender,
  text,
  getCurrentMessage,
  id,
  hasChat,
  responseDelete,
}) => {
  return (
    <div className={classnames(styles['letter'], hasChat && styles['letter__has-chat'])}>
      <div className={classnames(styles['letter__content'])}>
        <div className={classnames(styles['letter__info'], styles['letter__current'])}>
          <div className={classnames('flexbox', 'align-center', styles['letter__container'])}>
            <p className={styles['letter__info-title']}>{sender.name}</p>
            <p className={styles['letter__info-date']}>{moment(createdAt).format('HH:mm')}</p>
          </div>
          <p className={classnames(styles['letter__info-subtitle'], styles.subhead)}>{text}</p>
          {/*{files &&*/}
          {/*  files.map((elem: any, index: number) => <CurrentFile key={index} File={elem.files} inChat={true} />)}*/}
        </div>
      </div>
      {!hasChat && responseDelete && (
        <button className={styles['letter__button-delete']} onClick={() => responseDelete()}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};
