import { ChangeEmailSection, ChangePasswordSection } from './components';
import styles from './styles.module.scss';

export const PatientSettingsTab = () => {
  return (
    <div style={{ height: '100%' }}>
      <ChangeEmailSection />
      <hr className={styles.separator} />
      <ChangePasswordSection />
    </div>
  );
};
