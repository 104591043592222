import { actions as analyzesActions } from 'features/analyzes/slice';
import { actions as authActions } from 'features/auth/slice';
import { actions as biomarkersActions } from 'features/biomarkers/slice';
import { actions as checkupsActions } from 'features/checkups/slice';
import { actions as nutrutionistsActions } from 'features/expert/slice';
import { actions as expertOnbordingActions } from 'features/expertOnbording/slice';
import { actions as patientsActions } from 'features/patient/slice';
import { actions as patientOnboardingActions } from 'features/patientOnboarding/slice';
import { actions as subscriptionsActions } from 'features/subscriptions/slice';

export default {
  authActions,
  patientsActions,
  analyzesActions,
  biomarkersActions,
  nutrutionistsActions,
  expertOnbordingActions,
  patientOnboardingActions,
  subscriptionsActions,
  checkupsActions,
};
