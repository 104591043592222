import classNames from 'classnames';
import useWindowWidth from 'hooks/useWindowWidth';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { CommonRoutes } from 'types/routes';
import { ArrowBack, HeaderTerms, LinkBack, TermsSidebar } from 'UIcomponents';

import styles from './styles.module.scss';

function checkSidebarVisibility(pathname: string, windowWidth: number): boolean {
  return (pathname.split(CommonRoutes.FAQ).filter(Boolean).length === 0 && windowWidth <= 768) || windowWidth > 768;
}

interface IProps {
  children: ReactNode;
  title: string;
  lastUpdate?: string;
}

const TermsLayout: FC<IProps> = ({ children, title, lastUpdate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq' });

  const { pathname } = useLocation();
  const windowWidth = useWindowWidth();
  const isSidebarVisible = checkSidebarVisibility(pathname, windowWidth);

  return (
    <div className={styles.root}>
      <HeaderTerms />
      <div className={styles.container}>
        {windowWidth <= 768 && <LinkBack pathname={isSidebarVisible ? CommonRoutes.PROFILE : CommonRoutes.FAQ} />}
        <div className={styles.heading}>
          <div className={styles.title__wrapper}>
            {windowWidth > 768 && <ArrowBack pathname={isSidebarVisible ? CommonRoutes.PROFILE : CommonRoutes.FAQ} />}
            <h2 className={styles.title}>{title}</h2>
          </div>
          <p className={classNames('subhead', 'subhead_regular', styles['last-update'])}>
            {t('lastUpdate')}: {lastUpdate}
          </p>
        </div>
        <article className={styles['terms-wrapper']}>
          {isSidebarVisible && <TermsSidebar />}
          <main className={styles.content}>{children}</main>
        </article>
      </div>
    </div>
  );
};

export default TermsLayout;
