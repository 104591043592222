import { CloseIconCircle } from 'assets/svg';
import classNames from 'classnames';
import { FormBiomarkerItemField } from 'features/biomarkers/types';
import { FC } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import { Control } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.scss';

type FormValues = {
  biomarkers: FormBiomarkerItemField[];
  analysisName: string;
  [key: string]: any;
};

type FormBiomarkerItemProps = {
  index: number;
  field: FormBiomarkerItemField;
  remove: (index?: number | number[] | undefined) => void;
  control: Control<FormValues>;
};

export const FormBiomarkerItem: FC<FormBiomarkerItemProps> = ({ index, field, remove }) => {
  const screenClass = useScreenClass();

  return (
    <>
      <Row
        className={classNames('body', 'body_medium', styles['biomarkers-item'])}
        style={{ minHeight: ['sm', 'xs'].includes(screenClass) ? '0' : '52px' }}>
        <Col
          md={6}
          sm={5}
          xs={5}
          className={classNames('flexbox', 'align-start')}
          style={{
            margin: 'auto',
            paddingLeft: 0,
            paddingRight: ['sm', 'xs'].includes(screenClass) ? '12px' : '32px',
          }}>
          <div
            data-tip={`${field.label} (${field.name})`}
            className={classNames('flexbox', 'justify-between', styles['biomarkers-item__title'])}>
            <div className={classNames(styles['biomarkers-item__title-text'])}>{`${field.label}`}</div>
            <button
              type="button"
              onClick={() => remove(index)}
              className={classNames(styles['biomarkers-item__delete-btn'])}>
              {<CloseIconCircle fill="var(--red)" />}
            </button>
          </div>
        </Col>

        <Col md={3} sm={4} xs={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className={styles.biomarkers__content}>{field.name}</div>
        </Col>

        <Col md={3} sm={3} xs={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className={styles.biomarkers__content}>{field.unit?.name}</div>
        </Col>
      </Row>

      <ReactTooltip delayShow={500} place="right" />
    </>
  );
};
