import {
  selectActivePatientRecommendations,
  selectGetAllRecommendationsForPatientStatus,
} from 'features/expert/selectors';
import { getAllRecommendationsForPatient } from 'features/expert/thunks';
import { RecommendationsList } from 'pages/RecommendationsList';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { Spinner } from 'UIcomponents';

import { EmptyRecommendations } from './components';
import styles from './styles.module.scss';

export const RecommendationTab: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const id = pathname.split('/')[2];

  const isRecommendationsLoading =
    useAppSelector(selectGetAllRecommendationsForPatientStatus) === LoadingStatus.pending;

  const { firstTypeCards, secondTypeCards, thirdTypeCards } = useAppSelector(selectActivePatientRecommendations);

  const hasEmptyRecommendationsList =
    !isRecommendationsLoading &&
    firstTypeCards.length === 0 &&
    secondTypeCards.length === 0 &&
    thirdTypeCards.length === 0;

  useEffect(() => {
    dispatch(getAllRecommendationsForPatient(id)).catch((error) => {
      console.warn({ error });
    });
  }, []);

  return (
    <>
      {isRecommendationsLoading ? (
        <div className={styles.waiting}>
          <Spinner color="var(--dark-blue)" variant="primary" radius={30} />
        </div>
      ) : (
        <>
          {hasEmptyRecommendationsList ? (
            <EmptyRecommendations />
          ) : (
            <RecommendationsList
              firstTypeCards={firstTypeCards}
              secondTypeCards={secondTypeCards}
              thirdTypeCards={thirdTypeCards}
              showTooltip={false}
              textSize="big"
            />
          )}
        </>
      )}
    </>
  );
};
