import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCreatePatientDto } from 'api/generated';
import { BaseModal } from 'components';
import { patientInvitationAsync, sortPatientsAsync } from 'features/expert/thunks';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { RootState } from 'store/types';
import { LoadingStatus } from 'types';
import { AllowedMasks } from 'types/Input';
import { CommonRoutes } from 'types/routes';
import { FormLabel, MaskedInput } from 'UIcomponents';
import { InputDefault } from 'UIcomponents';
import { FormError } from 'UIcomponents';
import { ButtonDefault } from 'UIcomponents';
import { DropdownDefault } from 'UIcomponents';
import { DatePickerDropdown } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { hasNumbers, hasOnlyLettersAndSpaces } from 'utils/formsValidators';
import { patterns } from 'utils/patterns';
import { outOfSideDate } from 'utils/time';

import styles from './styles.module.scss';

interface FormProps {
  onClose: () => void;
  isVisible: boolean;
}

interface FormValues {
  email: string;
  name: string;
  birthday: Date;
  gender: string;
  phone?: string;
  date: string;
}

enum Genders {
  Male = 'male',
  Female = 'female',
}

export const AddPatientForm: FC<FormProps> = ({ onClose, isVisible }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.addPatientForm' });

  const [currentGender, setCurrentGender] = useState<Genders>(Genders.Male);
  const patientInvitationStatus = useSelector((rootState: RootState) => rootState.expert.patientInvitationStatus);
  const isLoading = patientInvitationStatus === LoadingStatus.pending;
  const { push } = useHistory();

  const options = {
    [Genders.Male]: {
      label: t('gendersMale'),
      value: Genders.Male,
    },
    [Genders.Female]: {
      label: t('gendersFemale'),
      value: Genders.Female,
    },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const onSubmit = async (data: ExpertCreatePatientDto) => {
    await dispatch(
      patientInvitationAsync({
        name: data.name,
        birthday: data.birthday,
        phone: data.phone ? data.phone : undefined,
        gender: data.gender,
        email: data.email,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notify('success', t('patientAdded'));
        onClose();
        reset();
        push(CommonRoutes.DASHBOARD);
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: check and fix after new api
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
          }),
        );
      })
      .catch((error) => {
        notify('error', error?.message);
      });

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_PATIENT_ADDED);
  };

  useEffect(() => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ADD_PATIENT_MODAL_OPENED);
  }, []);

  return (
    <BaseModal onClose={onClose} isVisible={isVisible} customStyles={{ maxWidth: 852 }}>
      <div className={styles['form-patient__field-container']}>
        <h2 className={styles['form-patient__header']}>{t('addPatient')}</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['form-patient__field-container']}>
          <div className={styles['form-patient__field']}>
            <FormLabel text={t('labelFullName')} />
            <Controller
              name="name"
              rules={{
                validate: (value) => {
                  if (hasNumbers(value)) {
                    return t('validateHasNumbers') as string;
                  }
                  if (!hasOnlyLettersAndSpaces(value)) {
                    return t('validateHasOnlyLettersAndSpaces') as string;
                  }
                },
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={onChange}
                  value={value}
                  placeholder={t('placeholderFullName') as string}
                  error={errors.name && 'error'}
                />
              )}
            />
            {errors.name?.message && <FormError type="error" text={errors.name.message} />}
          </div>
          <div className={styles['form-patient__field']}>
            <FormLabel text={t('labelBirthdate')} />
            <Controller
              name="birthday"
              control={control}
              defaultValue={outOfSideDate(18)}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { onChange, value } }) => (
                <DatePickerDropdown
                  icon="calendar"
                  error={errors.date && 'error'}
                  setSelectedDay={onChange}
                  selectedDay={value}
                  disabledDays={{ after: outOfSideDate(18) }}
                  variant="max-content"
                />
              )}
            />
          </div>
        </div>
        <div className={styles['form-patient__field-container']}>
          <div className={styles['form-patient__field']}>
            <FormLabel text={t('genderLabel')} />
            <Controller
              name="gender"
              defaultValue={'male'}
              control={control}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { onChange } }) => (
                <DropdownDefault
                  options={[options[Genders.Male], options[Genders.Female]]}
                  onChange={(e) => {
                    onChange(e.value);
                    setCurrentGender(e.value);
                  }}
                  isSearchable={false}
                  className={styles['dropdown__control']}
                  selected={options[currentGender]}
                  variant={'max-content'}
                />
              )}
            />
          </div>
          <div className={styles['form-patient__field']}>
            <FormLabel text={t('email')} />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.email,
                  message: t('emailPattern') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={(e) => {
                    const value = e.target.value;
                    const trimValue = value.trim();
                    e.target.value = trimValue;
                    onChange(e);
                  }}
                  placeholder={t('enterEmail') as string}
                  value={value}
                  error={errors.email && 'error'}
                />
              )}
            />
            {errors.email?.message && <FormError type="error" text={errors.email.message} />}
          </div>
        </div>
        <div className={styles['form-patient__field-container']}>
          <div className={styles['form-patient__field']}>
            <FormLabel text={t('phoneNumberLabel')} />
            <Controller
              name="phone"
              control={control}
              rules={{
                pattern: {
                  value: patterns.phone,
                  message: t('phoneNumberValidate') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <MaskedInput
                  mask={AllowedMasks.phone}
                  onChange={onChange}
                  value={value}
                  placeholder={t('phoneNumberPlaceholder') as string}
                  error={errors.phone && 'error'}
                />
              )}
            />
            {errors.phone?.message && <FormError type="error" text={errors.phone.message} />}
          </div>
        </div>
        <div className={styles['form-patient__button']}>
          <ButtonDefault
            text={t('buttonSave')}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </div>
      </form>
    </BaseModal>
  );
};
