import { unwrapResult } from '@reduxjs/toolkit';
import {
  GetPaymentUrlDtoPaymentPlatformEnum,
  GetPaymentUrlDtoSubscriptionPlanEnum,
  SubscriptionConstantsDto,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
  SubscriptionPriceDto,
  UpdateSubscriptionDtoSubscriptionDurationEnum,
} from 'api/generated';
import { CooperationStateEnum } from 'components/BaseTable/components/Cell/Cell';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectExpert, selectExpertPatients } from 'features/expert/selectors';
import { findMeAsync as findExpertAsync } from 'features/expert/thunks';
import { selectPatientData } from 'features/patient/selectors';
import { findMeAsync as findPatientAsync } from 'features/patient/thunks';
import { selectSubscription, selectSubscriptionsConstants } from 'features/subscriptions/selectors';
import { getPaymentUrlAsync, updateSubscriptionAsync } from 'features/subscriptions/thunks';
import { useToggle } from 'hooks';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { SubscriptionCardExpert } from 'UIcomponents';
import { ModalDowngradeSubscription } from 'UIcomponents/SubscriptionCardExpert/components/ModalDowngradeSubscription';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import config from '../../config';
import styles from './styles.module.scss';

type SubscriptionExpertsProps = {
  isActiveYearSubscriptionsTab: boolean;
};

type SubscriptionTuple = [SubscriptionDtoSubscriptionPlanEnum, SubscriptionConstantsDto | undefined];

export const SubscriptionExperts: FC<SubscriptionExpertsProps> = ({ isActiveYearSubscriptionsTab }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const expert = useAppSelector(selectExpert);
  const patient = useAppSelector(selectPatientData);
  const role = useAppSelector(selectRole);
  const subscription = useAppSelector(selectSubscription);
  const subscriptionConstants = useSelector(selectSubscriptionsConstants);

  const { isOpened: isModalDowngradeOpened, onToggle: toggleModalDowngrade } = useToggle();

  const yearSubcriptions: SubscriptionTuple[] = [
    [SubscriptionDtoSubscriptionPlanEnum.HighYear, subscriptionConstants.high_year],
    [SubscriptionDtoSubscriptionPlanEnum.MediumYear, subscriptionConstants.medium_year],
    [SubscriptionDtoSubscriptionPlanEnum.LowYear, subscriptionConstants.low_year],
  ];

  const monthSubcriptions: SubscriptionTuple[] = [
    [SubscriptionDtoSubscriptionPlanEnum.HighMonth, subscriptionConstants.high_month],
    [SubscriptionDtoSubscriptionPlanEnum.MediumMonth, subscriptionConstants.medium_month],
    [SubscriptionDtoSubscriptionPlanEnum.LowMonth, subscriptionConstants.low_month],
  ];

  const subscriptionMapping = isActiveYearSubscriptionsTab ? yearSubcriptions : monthSubcriptions;

  const { subscriptionPlan, status } = subscription;

  const hasPaidSubscription =
    !!subscriptionPlan &&
    (status === SubscriptionDtoStatusEnum.Active || status === SubscriptionDtoStatusEnum.CanceledActive);

  const isExpert = role === RolesEnum.EXPERT;
  const AccountId = isExpert ? expert.id : patient.id;

  const patients = useAppSelector(selectExpertPatients);

  const totalPatientsWithCooperation = patients.data.filter((patient) => {
    const signUpStatusState =
      patient.signUpStatus === 'sent' ? CooperationStateEnum.RequestFromExpert : CooperationStateEnum.Cooperation;

    const cooperationState = patient.isNewRequest ? CooperationStateEnum.RequestFromPatient : signUpStatusState;
    return cooperationState === CooperationStateEnum.Cooperation && patient;
  });

  const updateSubscription = (subscriptionPlan: SubscriptionDtoSubscriptionPlanEnum) => {
    batch(async () => {
      await dispatch(
        updateSubscriptionAsync({
          subscriptionDuration: subscriptionPlan as unknown as UpdateSubscriptionDtoSubscriptionDurationEnum,
        }),
      )
        .then(unwrapResult)
        .then((res) => {
          if (res.hasPendingUpdate) {
            notify('error', t('notifyError'), true);
          } else {
            notify('success', t('notifySuccess'));
          }
        })
        .catch((error) => {
          console.warn(error);
          notify('error', error.message);
        });

      isExpert
        ? await dispatch(findExpertAsync()).catch((error) => {
            console.warn({ error });
          })
        : await dispatch(findPatientAsync()).catch((error) => {
            console.warn({ error });
          });
    });
  };

  const paySubscriptionByStripe = (subscriptionPlan: SubscriptionDtoSubscriptionPlanEnum) => {
    dispatch(
      getPaymentUrlAsync({
        cancelUrl: `${window.location.origin}${CommonRoutes.DASHBOARD}`,
        successUrl: `${window.location.origin}${CommonRoutes.DASHBOARD}`,
        subscriptionPlan: subscriptionPlan as unknown as GetPaymentUrlDtoSubscriptionPlanEnum,
        paymentPlatform: GetPaymentUrlDtoPaymentPlatformEnum.Stripe,
      }),
    )
      .then(unwrapResult)
      .then((result) => {
        window.location.assign(result.redirectUrl);
      })
      .catch((error) => notify('error', error.message));
  };

  const paySubscriptionByCloudPayments = (plan: SubscriptionDtoSubscriptionPlanEnum, amount: number) => {
    const widget = new window.cp.CloudPayments({ language: 'ru-RU' });

    widget.charge(
      {
        amount,
        description: 'Biogeek Subscription',
        publicId: config.CP_PUBLIC_ID,
        currency: 'RUB',
        accountId: AccountId,
        skin: 'mini',
        requireEmail: true,
        data: {
          recurrent: {
            selectedPlan: plan,
          },
        },
      },
      () => {
        //Metrics
        analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_SUBSCRIPTION_BOUGHT);

        history.push(CommonRoutes.DASHBOARD);
        window.location.reload();
      },
      (reason) => {
        if (reason && reason !== 'User has cancelled') {
          notify('error', reason);
        }
      },
    );
  };

  const withPatientLimitCheck =
    <TRestArgs extends unknown[], TReturn>(
      callback: (plan: SubscriptionDtoSubscriptionPlanEnum, ...args: TRestArgs) => TReturn,
    ) =>
    (plan: SubscriptionDtoSubscriptionPlanEnum, ...restArgs: TRestArgs) => {
      const targetSubscriptionConstant = subscriptionConstants[plan];
      const patientLimit = targetSubscriptionConstant?.patientLimit ?? 0;
      const isPatientLimitExceeded = totalPatientsWithCooperation.length > patientLimit;

      if (isPatientLimitExceeded) {
        toggleModalDowngrade();
        return;
      }

      callback(plan, ...restArgs);
    };

  const handleCheckoutClick = withPatientLimitCheck(
    (plan: SubscriptionDtoSubscriptionPlanEnum, platform: GetPaymentUrlDtoPaymentPlatformEnum) => {
      switch (platform) {
        case GetPaymentUrlDtoPaymentPlatformEnum.Stripe: {
          paySubscriptionByStripe(plan);
          break;
        }
        case GetPaymentUrlDtoPaymentPlatformEnum.CloudPayments: {
          const price = subscriptionConstants[plan]?.price as SubscriptionPriceDto;
          paySubscriptionByCloudPayments(plan, price.rub);
          break;
        }
      }
    },
  );

  const handleUpdateClick = withPatientLimitCheck((plan: SubscriptionDtoSubscriptionPlanEnum) => {
    updateSubscription(plan);
  });

  useEffect(() => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERTS_SUBSCRIPTIONS_PAGE_VIEWED);
  }, []);

  return (
    <div className={styles.subscriptions}>
      {subscriptionMapping.map(
        ([plan, subscription], index) =>
          subscription && (
            <SubscriptionCardExpert
              key={index}
              price={subscription.price}
              patientLimit={subscription.patientLimit ?? 0}
              period={subscription.period}
              plan={plan}
              onCheckoutClick={handleCheckoutClick}
              onUpdateClick={handleUpdateClick}
              shouldUpdate={hasPaidSubscription}
            />
          ),
      )}
      <ModalDowngradeSubscription isOpened={isModalDowngradeOpened} onClose={toggleModalDowngrade} />
    </div>
  );
};
