import { PatientResDto } from 'api/generated';
import { CloseIcon, UserIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectIsFilterOnlyNewPatientsOnDashboard } from 'features/expert/selectors';
import { acceptRequestForCooperation, declineRequestForCooperation, sortPatientsAsync } from 'features/expert/thunks';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';
import stringCircumcision from 'utils/stringCircumcision';

import styles from '../../styles.module.scss';

type CellProps = {
  patient: PatientResDto;
};

export enum CooperationStateEnum {
  RequestFromPatient,
  RequestFromExpert,
  Cooperation,
}

const Cell: FC<CellProps> = ({ patient }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.patientCard' });

  const history = useHistory();
  const dispatch = useAppDispatch();

  const linkToPatientProfile = () => {
    !patient.isNewRequest && history.push(`/patient-profile/${patient.id}`, patient.id);
  };

  const [isAcceptLoading, setAcceptLoading] = useState(false);
  const [isDeclineLoading, setDeclineLoading] = useState(false);
  const isPatientsOnlyNewFilter = useAppSelector(selectIsFilterOnlyNewPatientsOnDashboard);

  const onAcceptRequestClick = () => {
    setAcceptLoading(true);
    dispatch(acceptRequestForCooperation(patient.id))
      .unwrap()
      .then(() => notify('success', t('requestAccept')))
      .then(() =>
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: need pagination
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
            onlyNewRequests: isPatientsOnlyNewFilter,
          }),
        ),
      )
      .catch((error) => {
        const errorText = error.message || t('error');
        notify('error', errorText);
      })
      .finally(() => setAcceptLoading(false));
  };

  const onDeclineRequestClick = () => {
    setDeclineLoading(true);
    dispatch(declineRequestForCooperation(patient.id))
      .unwrap()
      .then(() => notify('success', t('requestDenied')))
      .then(() =>
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: check and fix after new api
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
            onlyNewRequests: isPatientsOnlyNewFilter,
          }),
        ),
      )
      .catch((error) => {
        const errorText = error.message || t('error');
        notify('error', errorText);
      })
      .finally(() => setDeclineLoading(false));
  };

  const signUpStatusState =
    patient.signUpStatus === 'sent' ? CooperationStateEnum.RequestFromExpert : CooperationStateEnum.Cooperation;

  const cooperationState = patient.isNewRequest ? CooperationStateEnum.RequestFromPatient : signUpStatusState;

  const cooperationStateMapping = {
    [CooperationStateEnum.Cooperation]: (
      <>
        <div className={patient.signUpStatus === 'sent' ? styles['cell__dot_grey'] : styles['cell__dot']} />
        <p className={patient.signUpStatus === 'sent' ? styles['header__text_grey'] : styles['header__text']}>
          {patient.signUpStatus === 'sent'
            ? t('requestSent')
            : moment(patient.cooperatedWithExpertAt).format('DD.MM.YYYY')}
        </p>
      </>
    ),
    [CooperationStateEnum.RequestFromPatient]: (
      <>
        <div className={styles.requests__buttons}>
          <ButtonDefault
            text={t('accept')}
            variant="secondary"
            onClick={onAcceptRequestClick}
            customStyles={{ minWidth: 'unset', marginRight: '12px', maxWidth: '132px' }}
            isLoading={isAcceptLoading}
          />
          <ButtonDefault
            variant="secondary"
            onClick={onDeclineRequestClick}
            containerClassNames={styles.decline__button}
            isLoading={isDeclineLoading}>
            <CloseIcon />
          </ButtonDefault>
        </div>
      </>
    ),
    [CooperationStateEnum.RequestFromExpert]: (
      <>
        <div className={styles['cell__dot_grey']} />
        <p className={styles['header__text_grey']}>{t('requestSent')}</p>
      </>
    ),
  };

  const renderCooperationState = (state: CooperationStateEnum) => {
    return cooperationStateMapping[state];
  };

  return (
    <div className={styles['cell__container']}>
      <div className={'flexbox'}>
        <div className={classnames(styles['header__name'], styles['header_padding'], styles['cell'])}>
          {patient.avatar ? (
            <button
              style={{ background: `center / cover no-repeat url(${patient.avatar})` }}
              className={classnames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
              tabIndex={-1}
              onClick={linkToPatientProfile}></button>
          ) : (
            <button
              style={{ background: `center / cover no-repeat url(${patient.avatar})` }}
              className={classnames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
              tabIndex={-1}
              onClick={linkToPatientProfile}>
              {<UserIcon fill={'var(--dark-grey)'} />}
            </button>
          )}
          <button className={styles['header__button']} onClick={linkToPatientProfile}>
            <p
              className={classnames(
                'ml-2',
                patient.signUpStatus === 'sent' ? styles['header__text_grey'] : styles['header__text'],
              )}>
              {stringCircumcision(String(patient.name))}
            </p>
          </button>
        </div>
        <div className={classnames(styles['header__birthday'], styles['header_padding'], styles['cell'])}>
          <p className={patient.signUpStatus === 'sent' ? styles['header__text_grey'] : styles['header__text']}>
            {moment(patient.birthday).format('DD.MM.YYYY')}
          </p>
        </div>
        <div className={classnames(styles['header__phone'], styles['header_padding'], styles['cell'])}>
          <p className={patient.signUpStatus === 'sent' ? styles['header__text_grey'] : styles['header__text']}>
            {patient.phone}
          </p>
        </div>
        <div className={classnames(styles['header__mail'], styles['header_padding'], styles['cell'])}>
          <p className={patient.signUpStatus === 'sent' ? styles['header__text_grey'] : styles['header__text']}>
            {stringCircumcision(String(patient.email))}
          </p>
        </div>
        <div className={classnames(styles['header__date'], styles['header_padding'], styles['cell'])}>
          {renderCooperationState(cooperationState)}
        </div>
      </div>
    </div>
  );
};

export default Cell;
