import { ExpertGenderEnum, ExpertUpdateExpertDtoGenderEnum } from 'api/generated';
import classNames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { selectExpert } from 'features/expert/selectors';
import { changeExpertAsync } from 'features/expert/thunks';
import plural from 'plural-ru';
import { FC } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import {
  ButtonDefault,
  DatePickerDropdown,
  DropdownDefault,
  FormError,
  FormLabel,
  InputDefault,
  NumberMaskedInput,
  TextArea,
} from 'UIcomponents';
import { hasNumbers, hasOnlyLettersAndSpaces } from 'utils/formsValidators';
import { patterns } from 'utils/patterns';
import { trimString } from 'utils/textHelper';

import { DownloadProfilePicture } from './DownloadProfilePicture';
import styles from './styles.module.scss';

interface FormValues {
  name: string;
  birthday: Date;
  gender: { label: string; value: ExpertUpdateExpertDtoGenderEnum | ExpertGenderEnum };
  specialization: string;
  jobExperience: number;
  shortInfo: string;
  avatar: string;
}

export const ExpertProfileTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.expertProfileTab' });

  const experienceMask = (value: number | string): string => {
    return `## ${plural(Number(value), t('year'), t('years'), t('yearPlural'))}`;
  };

  const dispatch = useAppDispatch();
  const expert = useSelector(selectExpert);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: expert.name,
      birthday: new Date(expert.birthday || new Date()),
      gender: {
        label: expert.gender === 'male' ? (t('gendersMale') as string) : (t('gendersFemale') as string),
        value: expert.gender,
      },
      specialization: expert.spesialization || '',
      jobExperience: expert.jobExperience || 0,
      shortInfo: expert.shortInfo || '',
      avatar: expert.avatar,
    },
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = (data: FormValues) => {
    const { hiddenForPatients } = expert;

    dispatch(
      changeExpertAsync({
        avatar: data.avatar,
        name: data.name,
        birthday: String(data.birthday.toISOString()),
        spesialization: data.specialization,
        jobExperience: Number(data.jobExperience),
        gender: data.gender?.value as ExpertUpdateExpertDtoGenderEnum,
        shortInfo: data.shortInfo,
        hiddenForPatients: hiddenForPatients,
      }),
    ).then(() => notify('success', t('notifySucces')));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <h4 className={styles['expert-info-form__title']}>{t('myData')}</h4>
      <DownloadProfilePicture role={RolesEnum.EXPERT} setValue={setValue} />
      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <FormLabel text={t('placeholderFullname')} />
          <Controller
            name="name"
            rules={{
              required: t('required') as string,
              validate: (value) => {
                if (!value.trim()) {
                  return t('required') as string;
                }
                if (hasNumbers(value)) {
                  return t('hasNumbers') as string;
                }
                if (!hasOnlyLettersAndSpaces(value)) {
                  return t('hasOnlyLettersAndSpaces') as string;
                }
              },
              pattern: {
                value: patterns.name,
                message: t('patternsName') as string,
              },
              minLength: {
                value: 4,
                message: t('minLength') as string,
              },
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                placeholder={t('placeholderFullname') as string}
                maxLength={40}
                onBlur={() => onChange(trimString(value))}
                error={errors.name && 'error'}
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.name?.message && <FormError type="error" text={errors.name.message} />}
        </div>

        <div className={classNames(styles['expert-info-form__input'])}>
          <FormLabel text={t('labelBirthdate') as string} />

          <Controller
            name="birthday"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePickerDropdown
                selectedDay={new Date(value)}
                setSelectedDay={(value) => onChange(value)}
                variant="max-content"
                icon="calendar"
              />
            )}
          />
          {errors.birthday?.message && <FormError type="error" text={errors.birthday.message} />}
        </div>

        <div className={classNames(styles['expert-info-form__input'])}>
          <FormLabel text={t('labelGender') as string} />

          <Controller
            name="gender"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownDefault
                error={errors.gender && 'error'}
                onChange={onChange}
                noOptionsMessage=""
                selected={
                  value.value === 'male'
                    ? { label: t('gendersMale') as string, value: 'male' }
                    : { label: t('gendersFemale') as string, value: 'female' }
                }
                isSearchable={false}
                options={[
                  { label: t('gendersMale') as string, value: 'male' },
                  { label: t('gendersFemale') as string, value: 'female' },
                ]}
              />
            )}
          />
        </div>
      </div>

      <hr className={classNames(styles['expert-info-form__styled-hr'])} />
      <div
        style={{ marginBottom: '9px' }}
        className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <FormLabel text={t('specialization') as string} />

          <Controller
            name="specialization"
            rules={{
              required: t('required') as string,
              validate: (value) => {
                return !!value.trim() || (t('required') as string);
              },
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                placeholder={t('specialization') as string}
                maxLength={40}
                error={errors.specialization && 'error'}
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.specialization?.message && <FormError type="error" text={errors.specialization.message} />}
        </div>

        <div className={classNames(styles['expert-info-form__input'])}>
          <FormLabel text={t('jobExperience') as string} />

          <Controller
            name="jobExperience"
            rules={{ required: t('required') as string }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberMaskedInput
                format={experienceMask(value)}
                placeholder={t('placeholderJobExperience') as string}
                onChange={onChange}
                value={value || ''}
                error={errors.jobExperience && 'error'}
                allowNegative={false}
              />
            )}
          />
          {errors.jobExperience?.message && <FormError type="error" text={errors.jobExperience.message} />}
        </div>
      </div>
      <div className={classNames(styles['expert-info-form__input'])}>
        <FormLabel text={t('labelShortInfo') as string} />
        <Controller
          name="shortInfo"
          rules={{
            required: t('required') as string,
            validate: (value) => {
              return !!value.trim() || (t('required') as string);
            },
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextArea
              placeholder={t('placeholderShortInfo') as string}
              maxLength={300}
              error={errors.shortInfo && 'error'}
              onChange={onChange}
              value={value || ''}
              customClassName={styles['textarea']}
            />
          )}
        />
        {errors.shortInfo?.message && <FormError type="error" text={errors.shortInfo.message} />}
      </div>

      <div className={classNames('flexbox', 'justify-end', styles['expert-info-form__buttons'])}>
        <ButtonDefault
          onClick={handleSubmit(onSubmit)}
          text={t('action')}
          type={'button'}
          isDisabled={!isDirty}
          customStyles={{ width: '200px', margin: 0 }}
        />
      </div>

      {/* <RemoveAccountModal isModalOpened={isModalOpened} onCloseModal={handleCloseModal} /> */}
    </form>
  );
};
