import classNames from 'classnames';
import { BaseModal } from 'components';
import { getAssignedCheckupsForPatiens, unsetCheckupsForPatiens } from 'features/expert/thunks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents/ButtonDefault';

import styles from './styles.module.scss';

type UnsetCheckupModalProps = {
  checkUpId: string;
  patientId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const UnsetCheckupModal: FC<UnsetCheckupModalProps> = ({ checkUpId, patientId, isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.analysisHistoryTab' });

  const dispatch = useAppDispatch();

  const [isUnsetLoading, setIsUnsetLoading] = useState(false);

  const handleUnsetCheckUp = async () => {
    setIsUnsetLoading(true);
    await dispatch(unsetCheckupsForPatiens({ id: patientId, checkupId: checkUpId }))
      .then(() => {
        notify('success', t('checkupDeleted'));
        onClose();
        patientId &&
          dispatch(getAssignedCheckupsForPatiens(patientId)).catch((error) => {
            console.warn({ error });
          });
      })
      .catch((error) => {
        console.warn({ error });
      })
      .finally(() => setIsUnsetLoading(false));
  };

  return (
    <BaseModal
      title={t('unsetModalTitle') as string}
      isVisible={isOpen}
      onClose={onClose}
      customStyles={{ textAlign: 'center' }}>
      <div className={classNames('body body_medium', styles['content'])}>{t('unsetModalContent')}</div>
      <div className={classNames('flexbox', 'justify-between', styles.buttons)}>
        <ButtonDefault text={t('cancel')} variant="secondary" onClick={onClose} customStyles={{ margin: 0 }} />
        <ButtonDefault
          text={t('unset')}
          customStyles={{ backgroundColor: 'var(--red)', margin: 0 }}
          onClick={handleUnsetCheckUp}
          isLoading={isUnsetLoading}
        />
      </div>
    </BaseModal>
  );
};
