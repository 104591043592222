import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'types';

import { completeOnboardingAsync } from './thunks';
import { OnboardingStore } from './types';

const initialState: OnboardingStore = {
  name: '',
  birthDay: undefined,
  gender: undefined,
  specialization: '',
  about: '',
  expirience: 0,
  loading: LoadingStatus.idle,
  error: null,
};

export type OnboardingState = typeof initialState;

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    expertUpdateAction: (state, action: PayloadAction<Partial<OnboardingState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {
    [completeOnboardingAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [completeOnboardingAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [completeOnboardingAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const { reducer, actions } = onboardingSlice;
