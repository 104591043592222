import * as React from 'react';

interface SvgCheckProps extends React.SVGProps<SVGSVGElement> {
  pathFill?: string;
}

function SvgCheck({ pathFill, ...props }: SvgCheckProps) {
  return (
    <svg width={24} height={24} fill={pathFill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414 4.95 4.95z" fill={pathFill} />
    </svg>
  );
}

export default SvgCheck;
