import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgPdf(props: React.SVGProps<SVGSVGElement>) {
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);

  return (
    <svg ref={ref} width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.469 6.797H2.53c-1.163 0-2.11.946-2.11 2.11v8.437c0 1.163.947 2.11 2.11 2.11H21.47c1.163 0 2.11-.947 2.11-2.11V8.906c0-1.163-.947-2.11-2.11-2.11zM5.988 14.219l-.775.003v1.621a.703.703 0 01-1.406 0l-.01-5.34a.703.703 0 01.703-.706h1.488c1.235 0 2.24.992 2.24 2.21 0 1.22-1.005 2.212-2.24 2.212zm6.036 2.158c-.419.007-1.466.011-1.51.012h-.003a.703.703 0 01-.704-.701l-.01-5.187a.703.703 0 01.703-.704h1.469c1.673 0 2.797 1.324 2.797 3.296 0 1.875-1.153 3.256-2.742 3.284zm7.205-4.032a.703.703 0 010 1.406h-1.276v1.999a.703.703 0 01-1.406 0v-5.304c0-.389.315-.703.703-.703h2.15a.703.703 0 010 1.406h-1.447v1.196h1.276z"
        fill="#262626"
      />
      <path
        d="M11.968 11.203h-.764l.007 3.776c.293-.002.606-.005.788-.008.94-.017 1.36-.955 1.36-1.878 0-.444-.1-1.89-1.39-1.89zM5.988 11.203h-.783l.002.805.003.808.778-.003c.452 0 .834-.369.834-.805 0-.436-.382-.805-.834-.805zM20.74 5.39a3.509 3.509 0 00-.751-1.212l-2.92-3.081A3.53 3.53 0 0014.516 0h-9.36c-1.164 0-2.11.946-2.11 2.11v3.28H20.74zM3.047 20.86v1.03c0 1.164.946 2.11 2.11 2.11h13.687c1.163 0 2.11-.946 2.11-2.11v-1.03H3.046z"
        fill="#262626"
      />
    </svg>
  );
}

export default SvgPdf;
