import { http } from 'services/http';

import {
  AnalyzesApi,
  AnalyzesParsingApi,
  BiomarkersApi,
  ChatsApi,
  ContactFormApi,
  DefaultApi,
  ExpertsAnalysisKindsApi,
  ExpertsAnalyzesApi,
  ExpertsApi,
  ExpertsChatsApi,
  ExpertsCheckupsApi,
  ExpertsEventsApi,
  ExpertsPatientAnamnesisApi,
  ExpertsPatientDiaryApi,
  ExpertsPatientNutritionPlansApi,
  ExpertsPatientRecommendationsApi,
  ExpertsPatientsApi,
  ExpertsPaymentsDiaryApi,
  ExpertsSettingsApi,
  PatientAnalyzesApi,
  PatientAnalyzesOpenAiApi,
  PatientsAnalysisKindsApi,
  PatientsAnamnesisApi,
  PatientsApi,
  PatientsChatsApi,
  PatientsCheckupsApi,
  PatientsExpertsApi,
  PatientsNutritionPlanApi,
  PatientsPatientDiaryApi,
  PatientsRecommendationsApi,
  PatientsSettingsApi,
  PromocodesApi,
  RequestsApi,
  SubscriptionsApi,
  UnitsApi,
} from './generated';

export const api = {
  DefaultApi: new DefaultApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientApi: new PatientsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  AnalyzesApi: new AnalyzesApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientAnalyzesApi: new PatientAnalyzesApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  BiomarkersApi: new BiomarkersApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  // CloudPaymentsApi: new CloudPaymentsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsApi: new ExpertsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  SubscriptionsApi: new SubscriptionsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ChatApi: new ChatsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  UnitsApi: new UnitsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PromocodesApi: new PromocodesApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  RequestsApi: new RequestsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsAnalysisKindsApi: new PatientsAnalysisKindsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsPatientDiaryApi: new PatientsPatientDiaryApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsRecommendationsApi: new PatientsRecommendationsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsAnamnesisApi: new PatientsAnamnesisApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsChatsApi: new PatientsChatsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsNutritionPlanApi: new PatientsNutritionPlanApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsCheckupsApi: new PatientsCheckupsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientsExpertsApi: new PatientsExpertsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientSettingsApi: new PatientsSettingsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsEventsApi: new ExpertsEventsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientDiaryApi: new ExpertsPatientDiaryApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientsApi: new ExpertsPatientsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsCheckupsApi: new ExpertsCheckupsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientNutritionPlansApi: new ExpertsPatientNutritionPlansApi(
    undefined,
    process.env.REACT_APP_API_URL,
    http.axios,
  ),
  ExpertsChatsApi: new ExpertsChatsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPaymentsDiaryApi: new ExpertsPaymentsDiaryApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientAnalyzesApi: new ExpertsAnalyzesApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientAnamnesisApi: new ExpertsPatientAnamnesisApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsPatientRecommendationsApi: new ExpertsPatientRecommendationsApi(
    undefined,
    process.env.REACT_APP_API_URL,
    http.axios,
  ),
  AnalyzesParsingApi: new AnalyzesParsingApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsAnalysisKindsApi: new ExpertsAnalysisKindsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ContactFormApi: new ContactFormApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  ExpertsSettingsApi: new ExpertsSettingsApi(undefined, process.env.REACT_APP_API_URL, http.axios),
  PatientAnalyzesOpenAiApi: new PatientAnalyzesOpenAiApi(undefined, process.env.REACT_APP_API_URL, http.axios),
};
