import classNames from 'classnames';
import { FC } from 'react';

import styles from './styles.module.scss';

type FormErrorProps = {
  type: 'warning' | 'error';
  text: string;
  position?: 'initial' | 'absolute';
  hideResponsive?: boolean;
};

export const FormError: FC<FormErrorProps> = ({
  type = 'error',
  text,
  position = 'initial',
  hideResponsive = false,
}) => {
  return (
    <div
      className={classNames(styles['form-error'], hideResponsive && styles['responsive-form-error'])}
      style={{ position: position }}>
      <p className={`footnote footnote_meduim ${styles[`form-error__message_${type}`]}`}>{text}</p>
    </div>
  );
};
