import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { PdfIcon, PictureIcon } from 'assets/svg';
import Axios from 'axios';
import classNames from 'classnames';
import { ContactForm } from 'components';
import { actions } from 'features/expert';
import { selectEducationDocuments, selectExpertDocumentStatus, selectFilesStatus } from 'features/expert/selectors';
import { createSignedUrlAsync, loadArrayUrlsAsync } from 'features/expert/thunks';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { MESSAGES } from 'types/messages';
import { ButtonDefault, HeaderOnboarding, SuccessModal } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { v4 } from 'uuid';

import { Document } from './components/Document';
import styles from './styles.module.scss';

interface FileTypes {
  files: File;
  id: string;
}

export const ProofEducation: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const [isOpenModal, setIsModalOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadedFiles, setLoadedFiles] = useState<Array<FileTypes>>([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const areFilesLoading = useAppSelector(selectFilesStatus) === LoadingStatus.pending;

  const documentsList = useAppSelector(selectEducationDocuments);
  const documentsStatus = useAppSelector(selectExpertDocumentStatus);

  const deleteFile = (id: string) => {
    const NewFiles = loadedFiles.filter((item: FileTypes) => {
      return item.id !== String(id);
    });
    setLoadedFiles(NewFiles);
  };

  const sendUrls = (urls: Array<string>) => {
    const newUrls = urls.map((elem) => {
      const shortUrl = new URL(elem);
      return `${shortUrl.origin}${shortUrl.pathname}`;
    });
    dispatch(loadArrayUrlsAsync({ documents: newUrls }))
      .then(() => {
        dispatch(actions.updateExpertDocumentStatus(ExpertEducationDocumentStatusEnum.Checking));
        setIsModalOpen(true);
      })
      .catch(() => {
        notify('error', MESSAGES.error);
      });
  };

  const createSignedUrls = () => {
    const arrayUrls: Array<string> = [];
    setIsDisabled(true);
    Promise.all(
      loadedFiles.map(async (file) => {
        await dispatch(createSignedUrlAsync({ filename: file.files.name }))
          .then(unwrapResult)
          .then((res) => {
            arrayUrls.push(res.signedUrl);
            Axios.put(res.signedUrl, file.files);
          })
          .catch((error) => {
            console.log(error);
            notify('error', MESSAGES.error);
          });
      }),
    ).then(() => {
      sendUrls(arrayUrls);
    });

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_ONBOARDING_DOCUMENTS_ENTERED);
  };

  const linkToDashboard = () => {
    history.push('/dashboard');
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const newFiles = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsText(files[i]);
        if (i < 5) {
          newFiles.push({ id: v4(), files: files[i] });
        }
      }
      e.target.value = '';
      setLoadedFiles([...loadedFiles, ...newFiles]);
    }
  };

  const renderTextByDocumentStatus = (docStatus: ExpertEducationDocumentStatusEnum) => {
    switch (docStatus) {
      case ExpertEducationDocumentStatusEnum.NotSent:
        return <span>{t('docsNotSent')}</span>;
      case ExpertEducationDocumentStatusEnum.Declined:
        return (
          <span>
            {t('docsNotSent')}{' '}
            <button className={styles['text-button']} onClick={() => setModalOpened(true)}>
              {t('docsContact')}
            </button>
            .
          </span>
        );
    }
  };

  return (
    <>
      <div className={styles.root}>
        <HeaderOnboarding />
        <div className={styles.content}>
          <h2 className={styles.title}>{t('confirmEducaion')}</h2>
          <p className={`${styles.subtitle} body body_medium`}>{renderTextByDocumentStatus(documentsStatus)}</p>
          <div className={classNames(styles['cards-container'], 'mb-2')}>
            <div className={styles.card}>
              <>
                <input
                  type="file"
                  className={styles.card__input}
                  multiple
                  maxLength={5}
                  accept="application/pdf,.jpg,.jpeg,.png"
                  disabled={loadedFiles.length === 5 || documentsList?.length > 4}
                  onChange={handleChangeFile}
                />
                <div className="flexbox">
                  <div className={classNames(styles['card__icon'], 'mr-1')}>
                    <PdfIcon />
                  </div>
                  <div className={styles['card__icon']}>
                    <PictureIcon />
                  </div>
                </div>
                <h5 className={classNames(styles['force-wrap'], styles['card__title'])}>{t('importDocs')}</h5>
              </>
            </div>
          </div>
          {documentsList?.length > 4 && <div className={styles['more-than-five']}>{t('lititFiles')}</div>}
          <div>
            <div className={styles['document__container']}>
              {loadedFiles &&
                loadedFiles.map((file, index) => (
                  <Document
                    id={file.id}
                    deleteFile={deleteFile}
                    size={file.files.size}
                    name={file.files.name}
                    key={index}
                  />
                ))}
            </div>
          </div>
          <ButtonDefault
            onClick={createSignedUrls}
            text={t('action')}
            type={'button'}
            isLoading={areFilesLoading}
            isDisabled={loadedFiles.length === 0 || isDisabled}
            customStyles={{ width: '200px', margin: 0 }}
          />
          {isOpenModal && (
            <SuccessModal
              onCloseModal={() => {
                console.log;
              }}
              isModalOpened={isOpenModal}
              successText={t('docsSuccessText')}
              isNotVisibleCross={true}
              description={t('docsWaiting') as string}
              submitFunction={linkToDashboard}
            />
          )}
        </div>
      </div>
      <ContactForm visible={modalOpened} setModalOpened={() => setModalOpened(false)} />
    </>
  );
};
