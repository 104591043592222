import classNames from 'classnames';
import { selectAnamnesisStatus, selectPatientDiaryStatus } from 'features/patient/selectors';
import { NutritionPlanItem } from 'pages/PatientProfile/components/NutritionPlanItem';
import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoadingStatus } from 'types';
import { PatientRoutes } from 'types/routes';
import { Spinner } from 'UIcomponents';

import styles from '../../styles.module.scss';
import { TabTitle } from './TabTitle';

type TabsWrapperProps = {
  children: ReactElement[];
};

const RouteMap: Record<string, number> = {
  [PatientRoutes.PROFILE]: 0,
  [PatientRoutes.PROFILE_SETTINGS]: 1,
  [PatientRoutes.ANAMNESIS]: 2,
  [PatientRoutes.NUTRITION_DIARY_OTHER]: 3,
  [PatientRoutes.NUTRITION_DIARY_STRESS]: 3,
  [PatientRoutes.NUTRITION_DIARY_ENERGY]: 3,
  [PatientRoutes.NUTRITION_DIARY_WORKOUT]: 3,
  [PatientRoutes.NUTRITION_DIARY_NUTRITION]: 3,
  [PatientRoutes.NUTRITION_PLAN]: 4,
  [PatientRoutes.RECOMMENDATIONS]: 5,
  [PatientRoutes.USER_SUBSCRIPTIONS]: 6,
  [PatientRoutes.NOTIFICATIONS]: 7,
};

export const TabsWrapper: FC<TabsWrapperProps> = ({ children }) => {
  const location = useLocation();

  const isStatusCreateLoading = useSelector(selectAnamnesisStatus) === LoadingStatus.pending;
  const isStatusDiaryLoading = useSelector(selectPatientDiaryStatus) === LoadingStatus.pending;

  const isLoading = isStatusCreateLoading || isStatusDiaryLoading;

  if (isLoading) {
    return (
      <div className="main-spinner-wrapper">
        <Spinner radius={20} color="var(--background-primary)" />
      </div>
    );
  }

  return (
    <>
      <div className={classNames('flexbox', 'direction-column', styles['patient-profile-tabs'])}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            isActive={index === RouteMap[location.pathname]}
          />
        ))}
      </div>
      <Switch>
        <Redirect from="/" to={PatientRoutes.PROFILE} exact />
        <Route exact path={PatientRoutes.PROFILE}>
          {children[0]}
        </Route>
        <Route path={PatientRoutes.PROFILE_SETTINGS}>{children[1]}</Route>
        <Route path={PatientRoutes.ANAMNESIS}>{children[2]}</Route>
        <Route path={PatientRoutes.NUTRITION_DIARY}>{children[3]}</Route>
        <Route path={PatientRoutes.NUTRITION_PLAN}>{children[4]}</Route>
        <Route path={PatientRoutes.NUTRITION_PLAN} exact>
          {children[3]}
        </Route>
        <Route path={PatientRoutes.NUTRITION_PLAN_ID()} component={NutritionPlanItem} />
        <Route path={PatientRoutes.RECOMMENDATIONS}>{children[5]}</Route>
        <Route path={PatientRoutes.USER_SUBSCRIPTIONS}>{children[6]}</Route>
        <Route path={PatientRoutes.NOTIFICATIONS}>{children[7]}</Route>
        <Redirect to={PatientRoutes.PROFILE} exact />
      </Switch>
    </>
  );
};
