import useWindowWidth from 'hooks/useWindowWidth';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, CartesianGrid, Cell, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from './components';
import styles from './styles.module.scss';

interface ChartProps<T> {
  data: { name: string; marker: number; isInAnalysis?: boolean; [key: string]: any }[];
  handleChartBarClick: (data: T, index: number) => void;
  upperDomainValue: number;
  activeIndex: number;
  norm: number[];
  unit: string;
  isDemo?: boolean;
}

export const Chart = <T,>({
  data,
  handleChartBarClick,
  upperDomainValue,
  activeIndex,
  norm,
  unit,
  isDemo = false,
}: ChartProps<T>): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'chart' });

  const windowWidth = useWindowWidth();

  const BarWidth = windowWidth <= 760 ? 20 : 44,
    BarGap = windowWidth <= 760 ? 24 : 48,
    BarRadius = windowWidth <= 760 ? 4 : 8,
    XAxisTickMargin = windowWidth <= 760 ? 12 : 24,
    XAxisWidth = windowWidth <= 760 ? 15 : 80,
    TickFontSize = windowWidth <= 760 ? 12 : 14,
    YAxisFontSize = windowWidth <= 760 ? 12 : 14,
    YAxisTickMargin = windowWidth <= 760 ? 20 : 10,
    ChartHeight = 200;

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(chartContainerRef.current?.clientWidth as number);

  useEffect(() => {
    const width = data.length * BarWidth + (data.length + 1) * BarGap;
    const chartContainerWidth = chartContainerRef.current?.offsetWidth as number;

    setChartWidth(chartContainerWidth > width ? chartContainerWidth : width);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, data]);

  const isTooltipVisible = !!data[0]?.marker || false;

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: 290, overflowX: 'auto' }}>
      <ComposedChart data={data} width={chartWidth} height={250} className={styles['composed-chart']}>
        <CartesianGrid strokeDasharray="3" vertical={false} stroke="#DBDBDB" />
        <XAxis
          key={chartWidth}
          dataKey="name"
          scale="auto"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={
            data.length === 0
              ? false
              : { fontSize: TickFontSize, fontWeight: 500, color: 'var(--black)', width: XAxisWidth }
          }
          tickMargin={XAxisTickMargin}
          height={40}
          interval={0}
        />

        {!isDemo && (
          <Tooltip
            cursor={false}
            content={<CustomTooltip norm={norm} unit={unit} isTooltipVisible={isTooltipVisible} />}
          />
        )}

        {data.length > 0 && (
          <rect
            width={chartWidth}
            fill="#EEFAF6"
            stroke="#EEFAF6"
            fillOpacity="0.6"
            style={{ transition: 'all .6s' }}
            height={(norm[1] - norm[0]) * (ChartHeight / upperDomainValue)}
            x={0}
            y={205 - norm[1] * (ChartHeight / upperDomainValue)}></rect>
        )}
        {data.length === 0 && (
          <g>
            <foreignObject x={0} y={0} width="100%" height="200px">
              <div className={styles['empty-message']}>
                <p className={styles['empty-message__text']}>{t('empty')}</p>
              </div>
            </foreignObject>
          </g>
        )}

        <Bar dataKey="marker" maxBarSize={BarWidth} radius={BarRadius} onClick={handleChartBarClick}>
          {data.map((entry, index) => {
            let isNorm = false;
            if ((entry.marker < norm[0] && entry.isInAnalysis) || (entry.marker > norm[1] && entry.isInAnalysis)) {
              isNorm = false;
            } else isNorm = true;

            let fillColor = '';
            if (index === activeIndex) {
              fillColor = '#646EFA';
            } else if (!isNorm) {
              fillColor = 'var(--red)';
            } else {
              fillColor = '#D9E5FF';
            }
            if (isDemo) {
              fillColor = '#F5F8FF';
            }
            if (entry.marker === 0) {
              return (
                <Cell
                  cursor="pointer"
                  style={{ paddingTop: 100 }}
                  fill={fillColor}
                  key={`cell-${index}`}
                  height={entry.isInAnalysis ? -1 : -4}
                />
              );
            }
            return <Cell cursor="pointer" fill={fillColor} key={`cell-${index}`} />;
          })}
        </Bar>
        <rect width="60" height="100%" fill="white" />
        <YAxis
          dataKey="marker"
          domain={isDemo ? [110, 150] : [0, upperDomainValue]}
          tickCount={10}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: YAxisFontSize, fontWeight: 500, color: 'var(--black)' }}
          tickMargin={YAxisTickMargin}
        />
      </ComposedChart>
    </div>
  );
};
