import classNames from 'classnames';
import { ContactForm } from 'components';
import { useToggle } from 'hooks';
import termsStyles from 'layouts/TermsLayout/styles.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const DisclaimerOfLiability: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq.disclaimerOfLiability' });

  const { isOpened: isContactModalOpen, onToggle: toggleContactModal } = useToggle();

  return (
    <>
      <h5 className={termsStyles.subhead}>{t('title')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph1')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph2')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead1')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph3')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead2')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph4')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead3')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph5')}</p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph6')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead4')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph7')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead5')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph8')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead6')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph9')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead7')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>
        {t('paragraph10')}
        <button className={termsStyles['text-button']} onClick={toggleContactModal}>
          {t('contactForm')}
        </button>
        .
      </p>
      <ContactForm visible={isContactModalOpen} setModalOpened={toggleContactModal} />
    </>
  );
};
