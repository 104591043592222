import classnames from 'classnames';
import { CSSProperties, FC } from 'react';
import { Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

type WaitingProps = {
  spinnerColor?: string | undefined;
  spinnerSecondaryColor?: string | undefined;
  spinnerRadius?: number | undefined;
  spinnerVariant?: 'primary' | 'secondary' | undefined;
  customStyles?: CSSProperties;
  containerClassName?: string;
};

export const Waiting: FC<WaitingProps> = ({
  spinnerColor,
  spinnerSecondaryColor,
  spinnerRadius,
  spinnerVariant,
  customStyles,
  containerClassName,
}) => {
  return (
    <div className={classnames(styles.waiting, containerClassName)} style={{ ...customStyles }}>
      <Spinner
        color={spinnerColor}
        secondaryColor={spinnerSecondaryColor}
        radius={spinnerRadius}
        variant={spinnerVariant}
      />
    </div>
  );
};
