import { CloseIcon } from 'assets/svg';
import classnames from 'classnames';
import { CSSProperties, FC, useEffect } from 'react';

import styles from './styles.module.scss';

type BaseModalProps = {
  isVisible?: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  customStyles?: CSSProperties;
  isNotVisibleCross?: boolean;
  children?: React.ReactNode;
  withoutBackground?: boolean;
};

export const BaseModal: FC<BaseModalProps> = ({
  title,
  description,
  isVisible = false,
  onClose,
  children,
  customStyles,
  isNotVisibleCross,
  withoutBackground,
}) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isVisible) {
        e.preventDefault();
        onClose();
      }
    };

    document.addEventListener('keydown', close);
    return () => {
      document.removeEventListener('keydown', close);
    };
  });

  useEffect(() => {
    if (isVisible) document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={classnames(styles['base-modal'], withoutBackground && styles['without-background'])}
      onClick={onClose}
      role="button"
      tabIndex={-1}
      onKeyUp={onClose}>
      <div
        style={customStyles}
        className={styles['base-modal__body']}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        onKeyUp={(e) => e.stopPropagation()}>
        {!isNotVisibleCross && <CloseIcon className={styles['base-modal__close-icon']} onClick={onClose} />}
        <h2 className={styles['base-modal__title']}>{title}</h2>
        {description && <p className={`body body_medium ${styles['base-modal__description']}`}>{description}</p>}
        {children}
      </div>
    </div>
  );
};
