import * as React from 'react';

interface SvgCheckProps extends React.SVGProps<SVGSVGElement> {
  pathFill?: string;
}

function Pencil(props: SvgCheckProps) {
  const { pathFill = '#404040', ...restProps } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M14.116 6.009l-9.904 9.915a.395.395 0 00-.103.18l-1.098 4.412A.392.392 0 003.39 21a.39.39 0 00.095-.011l4.406-1.1a.389.389 0 00.18-.102l9.906-9.915-3.86-3.863zM20.43 4.657l-1.103-1.104c-.737-.738-2.021-.737-2.757 0l-1.35 1.352 3.859 3.863 1.35-1.352A1.94 1.94 0 0021 6.036a1.94 1.94 0 00-.57-1.38z"
        fill={pathFill}
      />
    </svg>
  );
}

export default Pencil;
