import { FirstTypeCardsPatientGetResDto, SecondTypeCardsPatientGetResDto } from 'api/generated';
import { ErrorIcon, HorizontalArrowIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import moment from 'moment';
import { CardType } from 'pages/Recommendation/Recommendation';
import { CSSProperties, FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { ExpertRoutes, PatientRoutes } from 'types/routes';

import styles from './styles.module.scss';

type RecommendationCardProps = {
  recommendation: SecondTypeCardsPatientGetResDto | FirstTypeCardsPatientGetResDto;
  linkMoreText?: string;
  tooltipText?: string;
  type?: 'blue' | 'light';
  showTooltip?: boolean;
  textSize?: 'big' | 'small';
  heightSize?: 'big' | 'small';
  customStyles?: CSSProperties;
};

export const RecommendationCard: FC<RecommendationCardProps> = ({
  recommendation,
  linkMoreText,
  tooltipText,
  type = 'blue',
  showTooltip = false,
  textSize = 'small',
  heightSize = 'small',
  customStyles,
}) => {
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'patientProfile.recommendationTab' });
  const { t: tRecommendationCard } = useTranslation('translation', { keyPrefix: 'recommendationCard' });

  const { label, description, createdAt, updatedAt } = recommendation;

  const linkToShowRecommendation: { id?: string; type?: CardType } = {};
  if ('firstTypeCardId' in recommendation) {
    linkToShowRecommendation.id = recommendation.firstTypeCardId;
    linkToShowRecommendation.type = 'first';
  } else if ('secondTypeCardId' in recommendation) {
    linkToShowRecommendation.id = recommendation.secondTypeCardId;
    linkToShowRecommendation.type = 'second';
  }
  const svgFillMapping = {
    ['blue']: 'var(--white)',
    ['light']: 'var(--svg-grey)',
  };

  const isUpdated = !(createdAt === updatedAt);
  const date = createdAt === updatedAt ? createdAt : updatedAt;
  const formattedDate =
    i18n.language === 'ru'
      ? moment(date).locale('ru').format('DD MMMM, YYYY')
      : moment(date).locale('en-gb').format('DD MMMM, YYYY');

  const role = useAppSelector(selectRole);
  const isExpert = role === RolesEnum.EXPERT;
  const { id: patientId } = useParams<{ id: string }>();

  return (
    <div
      style={customStyles}
      className={classnames(styles.card, {
        [styles.card_blue]: type === 'blue',
        [styles.card_light]: type === 'light',
        [styles.card_small]: textSize === 'small',
        [styles.card_big]: textSize === 'big',
        [styles['card_small-height']]: heightSize === 'small',
        [styles['card_big-height']]: heightSize === 'big',
      })}>
      <div className={classnames('flexbox', 'justify-between')}>
        <Dotdotdot
          tagName="p"
          useNativeClamp
          clamp={2}
          className={classnames(styles.title, {
            [styles.title_small]: textSize === 'small',
            [styles.title_big]: textSize === 'big',
          })}>
          {label}
        </Dotdotdot>
        {showTooltip && (
          <div
            data-for="main"
            data-tip={tooltipText ? tooltipText : tRecommendationCard('tooltipDefault')}
            data-iscapture="true">
            <ErrorIcon fill={svgFillMapping[type]} />
          </div>
        )}
      </div>

      <Dotdotdot
        clamp={2}
        useNativeClamp
        tagName="p"
        className={classnames(styles.content, {
          [styles.content_small]: textSize === 'small',
          [styles.content_big]: textSize === 'big',
        })}>
        {description}
      </Dotdotdot>

      <div className={classnames(styles.date_container)}>
        <span className={classnames(styles.date)}>{isUpdated ? t('updated') : t('created')}&nbsp;</span>
        <span className={classnames(styles.date)}>{formattedDate}</span>
      </div>

      <div className={styles['link-more']}>
        <Link
          to={{
            pathname: isExpert
              ? ExpertRoutes.PATIENT_RECOMMENDATION(patientId, linkToShowRecommendation.id)
              : PatientRoutes.RECOMMENDATION(linkToShowRecommendation.id),
            state: { type: linkToShowRecommendation.type },
          }}
          className={classnames(styles['link-more__text'], {
            [styles['link-more__text_blue']]: type === 'blue',
            [styles['link-more__text_light']]: type === 'light',
          })}>
          {linkMoreText ? linkMoreText : tRecommendationCard('learnMore')}
          <HorizontalArrowIcon />
        </Link>
      </div>
    </div>
  );
};
