import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonRoutes } from 'types/routes';

import { TermsSidebarLink } from './components';
import styles from './styles.module.scss';

interface TermsSidebarProps {
  children?: ReactElement;
}

export const TermsSidebar: FC<TermsSidebarProps> = ({ children }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq' });

  return (
    <aside className={styles.sidebar}>
      <TermsSidebarLink route={CommonRoutes.TERMS_OF_SERVICE} title={t('terms')} />
      <TermsSidebarLink route={CommonRoutes.PRIVACY_POLICY} title={t('policy')} />
      <TermsSidebarLink route={CommonRoutes.DISCLAIMER_OF_LIABILITY} title={t('disclaimer')} />
      {children}
    </aside>
  );
};
