import { BiomarkerGetResDto, PatientGenderEnum } from 'api/generated';
import classNames from 'classnames';
import { selectPatientGender } from 'features/patient/selectors';
import { FC } from 'react';
import { Col, Hidden, Row, useScreenClass, Visible } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.scss';

export type BiomarkerItemProps = {
  biomarker: BiomarkerGetResDto;
  value?: number | null;
  previousValue?: number | null;
  hasPreviousAnalysis?: boolean;
};

export const BiomarkerItem: FC<BiomarkerItemProps> = ({
  biomarker,
  value,
  previousValue,
  hasPreviousAnalysis = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'analysis' });

  const userGender = useSelector(selectPatientGender);
  const lowerNorm = userGender === PatientGenderEnum.Female ? biomarker.lowerNormFemale : biomarker.lowerNormMale;
  const upperNorm = userGender === PatientGenderEnum.Female ? biomarker.upperNormFemale : biomarker.upperNormMale;
  const screenClass = useScreenClass();

  const outOfNorm = () => {
    if (value) {
      return value < lowerNorm || value > upperNorm;
    }
  };

  return (
    <>
      <Row justify={'between'} className={classNames('body', 'body_medium', styles['biomarkers-item'])}>
        <Col
          md={4}
          sm={4}
          xs={4}
          className={classNames()}
          style={{ margin: 'auto 0', paddingLeft: 0, paddingRight: '16px' }}>
          <div
            data-tip={`${biomarker.label} (${biomarker.name})`}
            className={classNames(styles['biomarkers-item__title'])}>{`${biomarker.label} (${biomarker.name})`}</div>
        </Col>

        {hasPreviousAnalysis && (
          <Col md={2} sm={2} xs={4} style={{ padding: 0 }}>
            <div
              className={classNames(
                'flexbox',
                'justify-center',
                'body',
                'body_medium',
                styles['biomarkers-item__previous-value-wrapper'],
              )}>
              <div className={classNames(styles['biomarkers-item__value--value'])}>
                {typeof previousValue === 'number' ? previousValue : '-'}
              </div>
            </div>
          </Col>
        )}

        <Col md={2} sm={4} xs={4} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <div
            className={classNames(
              'flexbox',
              'justify-center',
              'body',
              'body_medium',
              styles['biomarkers-item__value-wrapper'],
            )}>
            <div
              className={classNames(styles['biomarkers-item__value--value'])}
              style={{ color: outOfNorm() ? 'var(--red)' : '' }}>
              {typeof value === 'number' ? value : '-'}
            </div>
          </div>
        </Col>
        <Hidden sm xs>
          <Col
            md={2}
            sm={0}
            xs={0}
            style={{ margin: 'auto 0', paddingLeft: 0, paddingRight: 0 }}
            className={classNames('flexbox', 'justify-center', styles['biomarkers-item__norm'])}>
            {`${Number(lowerNorm.toFixed(2))} - ${Number(upperNorm.toFixed(2))}`}
          </Col>
        </Hidden>

        <Col
          md={2}
          sm={2}
          xs={4}
          style={{
            margin: 'auto 0',
            paddingRight: 0,
            paddingLeft: ['sm', 'xs'].includes(screenClass) ? '16px' : '0px',
          }}
          className={classNames(styles['biomarkers-item__unit'])}
          data-tip={biomarker.unit?.name}>
          {biomarker.unit?.name}
        </Col>
      </Row>

      <Visible sm xs>
        <Row className={classNames('footnote', 'footnote_medium', styles['biomarkers-item__footnote'])}>
          <Col
            sm={4}
            xs={4}
            offset={{ sm: 6, xs: 8 }}
            style={{ paddingLeft: 0 }}
            className={classNames(styles['biomarkers-item__footnote-norm'])}>
            {`${t('normal')}: ${lowerNorm} - ${upperNorm}`}
          </Col>
        </Row>
      </Visible>

      <ReactTooltip delayShow={500} place="right" />
    </>
  );
};
