import { expertSignInAsync, expertSignUpAsync, patientSignInAsync, patientSignUpAsync } from 'features/auth/thunks';
import { AnyAction, Dispatch } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { http } from 'services/http';

import actions from './actions';

export const authMiddleware =
  () =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): AnyAction => {
    if (
      action.type === expertSignInAsync.fulfilled.type ||
      action.type === actions.authActions.signUp.type ||
      action.type === expertSignUpAsync.fulfilled.type ||
      action.type === patientSignInAsync.fulfilled.type ||
      action.type === patientSignUpAsync.fulfilled.type
    ) {
      http.setAuthorizationHeader(action.payload.accessToken);
    }

    if (action.type === REHYDRATE) {
      action?.payload?.token && http.setAuthorizationHeader(action?.payload?.token);
    }

    if (action.type === actions.authActions.signOut.type) {
      http.unsetAuthorizationHeader();
      next(actions.patientsActions.unsetPatients());
      next(actions.nutrutionistsActions.unsetExpert());
      next(actions.subscriptionsActions.unsetStore());
      next(actions.analyzesActions.unsetAnalyzes());
      next(actions.biomarkersActions.unsetBiomarkers());
    }

    return next(action);
  };
