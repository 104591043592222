import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Anamnesis,
  CheckupResDto,
  Expert,
  GetChatRoomsResDto,
  GetPatientNutritonPlanResDto,
  Patient,
  PatientDiary,
  PatientGetExpertsResDto,
  PatientRecommendationCardsGetResDto,
} from 'api/generated';
import { completeOnboardingPatientAsync } from 'features/patientOnboarding/thunks';
import { LoadingStatus } from 'types';

import {
  clearPatientDiaryAsync,
  createAnamnesAsync,
  createPatientDiaryAsync,
  deletePatientAsync,
  findMeAsync,
  getAnamnesAsync,
  getAssignedCheckups,
  getChatRoomAsync,
  getChatsPatientAsync,
  getEmailAsync,
  getExpertsAsync,
  getNutrionPlanAsync,
  getPatientDiaryAsync,
  getRecommendationsAsync,
  nutritionPlanSeenAsync,
  patientSetNewEmailAsync,
  searchExpertsAsync,
  sendCurrentEmailVerificationCodeAsync,
  sendNewEmailVerificationCodeAsync,
  sendVerifyCurrentEmailAsync,
  setNewEmailAsync,
  updateAnamnesAsync,
  updatePasswordAsync,
  updatePatientAsync,
  updatePatientDiaryAsync,
} from './thunks';
import { PatientStore } from './types';

const initialState: PatientStore = {
  me: {} as Patient,
  expert: {} as Expert,
  anamnes: {} as Anamnesis,
  nutrionPlan: {} as GetPatientNutritonPlanResDto,
  diary: [],
  recommendations: { firstTypeCards: [], secondTypeCards: [], thirdTypeCards: [] },
  recommendationsLoadingStatus: LoadingStatus.idle,
  loading: LoadingStatus.idle,
  updatingPatientEmailStatus: LoadingStatus.idle,
  deletingPatientStatus: LoadingStatus.idle,
  getPatientStatus: LoadingStatus.idle,
  getNutrionPlanStatus: LoadingStatus.idle,
  anamnesisStatus: LoadingStatus.idle,
  mutationAnamnesisStatus: LoadingStatus.idle,
  diaryStatus: LoadingStatus.idle,
  experts: [],
  expertsLoadingStatus: LoadingStatus.idle,
  expertsSearchStatus: LoadingStatus.idle,
  activeChat: {} as GetChatRoomsResDto,
  chatRooms: [],
  getPatientChatRoomsStatus: LoadingStatus.idle,
  assignedCheckups: [] as CheckupResDto[],
  assignedCheckupsStatus: LoadingStatus.idle,

  error: null,
  currentRequestId: undefined,
  roomChatLoading: LoadingStatus.idle,
};

const userSlice = createSlice({
  initialState,
  name: 'patient',
  reducers: {
    unsetPatients: () => initialState,
    savePatient: (state, action) => {
      state.me = action.payload;
    },
    sortChatRoomsAfterSendMessage: (state, { payload }: PayloadAction<string>) => {
      const activeChat = state.chatRooms.filter((chat) => chat.id === payload);
      const chatRoomsWithoutActive = state.chatRooms.filter((chat) => chat.id !== payload);
      state.chatRooms = activeChat.concat(chatRoomsWithoutActive);
    },
    updateExpertsCooperationsStatus: (state, action) => {
      const newExperts = [...state.experts];
      const index = state.experts.findIndex((element) => element.id === action.payload.id);
      newExperts[index] = action.payload;
      state.experts = [...newExperts];
    },
  },
  extraReducers: {
    [completeOnboardingPatientAsync.fulfilled.type]: (state, { payload }: PayloadAction<Patient>) => {
      state.me = payload;
    },
    [completeOnboardingPatientAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [completeOnboardingPatientAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getEmailAsync.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.me.email = payload;
      state.loading = LoadingStatus.idle;
    },
    [getEmailAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [getEmailAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updatePatientAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [updatePatientAsync.fulfilled.type]: (state, { payload }: PayloadAction<Patient>) => {
      state.me = payload;
      state.loading = LoadingStatus.idle;
    },
    [updatePatientAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [deletePatientAsync.pending.type]: (state) => {
      if (state.deletingPatientStatus === LoadingStatus.idle) {
        state.deletingPatientStatus = LoadingStatus.pending;
      }
    },
    [deletePatientAsync.fulfilled.type]: (state) => {
      state.deletingPatientStatus = LoadingStatus.idle;
    },
    [deletePatientAsync.rejected.type]: (state, { payload }) => {
      state.deletingPatientStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [findMeAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.getPatientStatus = LoadingStatus.pending;
      }
    },
    [findMeAsync.fulfilled.type]: (state, { payload }: PayloadAction<Patient>) => {
      const { expert, ...restPayload } = payload;
      state.me = restPayload;
      state.expert = expert as Expert;
      state.getPatientStatus = LoadingStatus.idle;
    },
    [findMeAsync.rejected.type]: (state, { payload }) => {
      state.getPatientStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [sendCurrentEmailVerificationCodeAsync.pending.type]: (state) => {
      if (state.updatingPatientEmailStatus === LoadingStatus.idle) {
        state.updatingPatientEmailStatus = LoadingStatus.pending;
      }
    },
    [sendCurrentEmailVerificationCodeAsync.fulfilled.type]: (state) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
    },
    [sendCurrentEmailVerificationCodeAsync.rejected.type]: (state, { payload }) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [sendVerifyCurrentEmailAsync.pending.type]: (state) => {
      if (state.updatingPatientEmailStatus === LoadingStatus.idle) {
        state.updatingPatientEmailStatus = LoadingStatus.pending;
      }
    },
    [sendVerifyCurrentEmailAsync.fulfilled.type]: (state) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
    },
    [sendVerifyCurrentEmailAsync.rejected.type]: (state, { payload }) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [sendNewEmailVerificationCodeAsync.pending.type]: (state) => {
      if (state.updatingPatientEmailStatus === LoadingStatus.idle) {
        state.updatingPatientEmailStatus = LoadingStatus.pending;
      }
    },
    [sendNewEmailVerificationCodeAsync.fulfilled.type]: (state) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
    },
    [sendNewEmailVerificationCodeAsync.rejected.type]: (state, { payload }) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [setNewEmailAsync.pending.type]: (state) => {
      if (state.updatingPatientEmailStatus === LoadingStatus.idle) {
        state.updatingPatientEmailStatus = LoadingStatus.pending;
      }
    },
    [setNewEmailAsync.fulfilled.type]: (state, { payload }) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
      state.me = payload;
    },
    [setNewEmailAsync.rejected.type]: (state, { payload }) => {
      state.updatingPatientEmailStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updatePasswordAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [updatePasswordAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [updatePasswordAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAnamnesAsync.pending.type]: (state) => {
      if (state.anamnesisStatus === LoadingStatus.idle) {
        state.anamnesisStatus = LoadingStatus.pending;
      }
    },
    [getAnamnesAsync.fulfilled.type]: (state, { payload }: PayloadAction<Anamnesis>) => {
      state.anamnes = payload;
      state.anamnesisStatus = LoadingStatus.idle;
    },
    [getAnamnesAsync.rejected.type]: (state, { payload }) => {
      state.anamnesisStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [createAnamnesAsync.pending.type]: (state) => {
      if (state.mutationAnamnesisStatus === LoadingStatus.idle) {
        state.mutationAnamnesisStatus = LoadingStatus.pending;
      }
    },
    [createAnamnesAsync.fulfilled.type]: (state, { payload }: PayloadAction<Anamnesis>) => {
      state.anamnes = payload;
      state.mutationAnamnesisStatus = LoadingStatus.idle;
    },
    [createAnamnesAsync.rejected.type]: (state, { payload }) => {
      state.mutationAnamnesisStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updateAnamnesAsync.pending.type]: (state) => {
      if (state.mutationAnamnesisStatus === LoadingStatus.idle) {
        state.mutationAnamnesisStatus = LoadingStatus.pending;
      }
    },
    [updateAnamnesAsync.fulfilled.type]: (state, { payload }: PayloadAction<Anamnesis>) => {
      state.mutationAnamnesisStatus = LoadingStatus.idle;
      state.anamnes = payload;
    },
    [updateAnamnesAsync.rejected.type]: (state, { payload }) => {
      state.mutationAnamnesisStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [createPatientDiaryAsync.pending.type]: (state) => {
      state.loading = LoadingStatus.pending;
    },
    [createPatientDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientDiary>) => {
      state.diary.push(payload);
      state.loading = LoadingStatus.idle;
    },
    [createPatientDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updatePatientDiaryAsync.pending.type]: (state) => {
      state.loading = LoadingStatus.pending;
    },
    [updatePatientDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientDiary>) => {
      state.diary = state.diary.map((obj) => (obj.id === payload.id ? { ...payload } : obj));
      state.loading = LoadingStatus.idle;
    },
    [updatePatientDiaryAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPatientDiaryAsync.pending.type]: (state) => {
      state.diaryStatus = LoadingStatus.pending;
    },
    [getPatientDiaryAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientDiary[]>) => {
      state.diary = payload;
      state.diaryStatus = LoadingStatus.idle;
    },
    [clearPatientDiaryAsync.pending.type]: (state) => {
      state.loading = LoadingStatus.pending;
    },
    [clearPatientDiaryAsync.fulfilled.type]: (state) => {
      state.diary = [];
    },
    [clearPatientDiaryAsync.rejected.type]: (state, { payload }) => {
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getNutrionPlanAsync.fulfilled.type]: (state, { payload }: PayloadAction<GetPatientNutritonPlanResDto>) => {
      state.getNutrionPlanStatus = LoadingStatus.fulfilled;
      state.nutrionPlan = payload;
    },
    [getNutrionPlanAsync.rejected.type]: (state, { payload }) => {
      state.getNutrionPlanStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getNutrionPlanAsync.pending.type]: (state) => {
      state.getNutrionPlanStatus = LoadingStatus.pending;
    },
    [nutritionPlanSeenAsync.fulfilled.type]: (state) => {
      state.nutrionPlan.isNewlyPrescribed = false;
    },
    [getChatRoomAsync.fulfilled.type]: (state) => {
      state.roomChatLoading = LoadingStatus.fulfilled;
    },

    [getRecommendationsAsync.pending.type]: (state) => {
      if (state.recommendationsLoadingStatus === LoadingStatus.idle) {
        state.recommendationsLoadingStatus = LoadingStatus.pending;
      }
    },
    [getRecommendationsAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PatientRecommendationCardsGetResDto>,
    ) => {
      state.recommendations = payload;
      state.recommendationsLoadingStatus = LoadingStatus.idle;
    },
    [getRecommendationsAsync.rejected.type]: (state, { payload }) => {
      state.recommendationsLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getChatRoomAsync.fulfilled.type]: (state) => {
      state.roomChatLoading = LoadingStatus.fulfilled;
    },
    [getExpertsAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientGetExpertsResDto>) => {
      state.experts = payload.data;
      state.expertsLoadingStatus = LoadingStatus.idle;
    },
    [getExpertsAsync.pending.type]: (state) => {
      if (state.expertsLoadingStatus === LoadingStatus.idle) {
        state.expertsLoadingStatus = LoadingStatus.pending;
      }
    },
    [getExpertsAsync.rejected.type]: (state, { payload }) => {
      state.expertsLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [searchExpertsAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientGetExpertsResDto>) => {
      state.expertsSearchStatus = LoadingStatus.idle;
    },
    [searchExpertsAsync.pending.type]: (state) => {
      if (state.expertsSearchStatus === LoadingStatus.idle) {
        state.expertsSearchStatus = LoadingStatus.pending;
      }
    },
    [searchExpertsAsync.rejected.type]: (state, { payload }) => {
      state.expertsLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getChatsPatientAsync.fulfilled.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: Array<GetChatRoomsResDto>;
        withSearch: boolean;
      }>,
    ) => {
      state.getPatientChatRoomsStatus = LoadingStatus.idle;
      state.chatRooms = payload.data;
    },
    [getChatsPatientAsync.pending.type]: (state) => {
      if (state.getPatientChatRoomsStatus === LoadingStatus.idle) {
        state.getPatientChatRoomsStatus = LoadingStatus.pending;
      }
    },
    [getChatsPatientAsync.rejected.type]: (state, { payload }) => {
      state.getPatientChatRoomsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAssignedCheckups.fulfilled.type]: (state, { payload }: PayloadAction<CheckupResDto[]>) => {
      state.assignedCheckups = payload;
      state.assignedCheckupsStatus = LoadingStatus.idle;
    },
    [getAssignedCheckups.pending.type]: (state) => {
      if (state.assignedCheckupsStatus === LoadingStatus.idle) {
        state.assignedCheckupsStatus = LoadingStatus.pending;
      }
    },
    [getAssignedCheckups.rejected.type]: (state, { payload }) => {
      state.assignedCheckupsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [patientSetNewEmailAsync.fulfilled.type]: (state, { payload }: PayloadAction<Patient>) => {
      state.me = payload;
    },
    [patientSetNewEmailAsync.rejected.type]: (state, { payload }) => {
      state.assignedCheckupsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});
export const actions = {
  ...userSlice.actions,
  updatePatientAsync,
  deletePatientAsync,
};
export const { reducer } = userSlice;
