import { OnboardingLayout } from 'layouts';
import { FC } from 'react';

import { Steps } from './Steps';

export const PatientOnboarding: FC = () => {
  return (
    <OnboardingLayout>
      <Steps />
    </OnboardingLayout>
  );
};
