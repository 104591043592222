import { createAsyncThunk } from '@reduxjs/toolkit';
import { BiogeekExpertPatientCompleteOnboardingDto, Patient } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from './types';

export const completeOnboardingPatientAsync = createAsyncThunk<
  Patient,
  BiogeekExpertPatientCompleteOnboardingDto,
  ExtraParamsThunkType<SerializedError>
>(
  'onboarding/completeOnboardingPatient',
  async (userData: BiogeekExpertPatientCompleteOnboardingDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerBiogeekExpertPatientCompleteOnboarding(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
