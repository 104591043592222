import { ArrowRightIcon } from 'assets/svg';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

interface TermsSidebarLinkProps {
  route: string;
  title: string;
}

export const TermsSidebarLink: FC<TermsSidebarLinkProps> = ({ route, title }) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={route}
      className={classNames(
        {
          [styles['sidebar__link--active']]: pathname.includes(route),
        },
        styles['sidebar__link'],
      )}>
      <div className={styles['sidebar__link-text']}>
        <span>{title}</span>
        <ArrowRightIcon className={styles['sidebar__link-arrow']} />
      </div>
    </Link>
  );
};
