import { BaseModal } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExpertRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

interface LimitPatientModalProps {
  onClose: () => void;
  isVisible: boolean;
}

export const LimitPatientModal: FC<LimitPatientModalProps> = ({ onClose, isVisible }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.limitPatientModal' });

  const { push } = useHistory();

  const handleChangeSubscription = () => {
    push(ExpertRoutes.SUBSCRIPTIONS);
  };

  return (
    <BaseModal
      onClose={onClose}
      isVisible={isVisible}
      customStyles={{ maxWidth: 730, textAlign: 'center' }}
      title={t('title') as string}>
      <p className={styles.content}>{t('content')}</p>
      <ButtonDefault text={t('chooseSubscription')} onClick={handleChangeSubscription} />
    </BaseModal>
  );
};
