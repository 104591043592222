import { SortIcon } from 'assets/svg';
import classnames from 'classnames';
import { CSSProperties, FC } from 'react';

import styles from './styles.module.scss';

export type TableColumType = {
  label: string;
  hasSorting: boolean;
  aspect: number;
  sort?: string;
  isSort?: boolean;
};

interface TableHeaderProps {
  columns: TableColumType[];
  customStyles?: CSSProperties;
  sorting?: (sortName: string) => void;
}

export const TableHeader: FC<TableHeaderProps> = ({ columns, customStyles, sorting }) => {
  const totalAspect = columns.reduce((acc, curr) => acc + curr.aspect, 0);

  return (
    <div className={styles['table-header']} style={customStyles}>
      {columns.map((column, id) => (
        <div
          key={id}
          className={classnames(styles['table-header__cell'], 'justify-start')}
          style={{
            width: `calc(100%/ ${totalAspect} * ${column.aspect})`,
          }}>
          <p className="subhead subhead_medium">{column.label}</p>
          {column.hasSorting && (
            <button
              className={styles['table-header__icon']}
              onClick={() => {
                sorting && column.sort && sorting(column.sort);
              }}>
              <SortIcon />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};
