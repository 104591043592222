import * as React from 'react';

function SvgCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#25253E' } = props;

  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.998 5.586l4.95-4.95 1.414 1.414L8.412 7l4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95L.634 11.95 5.584 7 .634 2.05 2.048.636l4.95 4.95z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgCloseIcon;
