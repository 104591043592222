import * as React from 'react';

function SvgCalendarArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={8} height={5} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 4.667l-4-4h8l-4 4z" fill="#000" />
    </svg>
  );
}

export default SvgCalendarArrowDownIcon;
