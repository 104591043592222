import * as React from 'react';

function SvgEyeOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.52 5.934L1.393 2.808l1.415-1.415 19.8 19.8-1.416 1.414-3.31-3.31A10.949 10.949 0 0112 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 013.34-6.066zm10.237 10.238l-1.464-1.464a3 3 0 01-4-4.001L7.827 9.243a5 5 0 006.93 6.929zM7.974 3.76A10.99 10.99 0 0112 3c5.392 0 9.878 3.88 10.82 9a10.949 10.949 0 01-2.013 4.592l-3.86-3.86a5 5 0 00-5.68-5.68L7.974 3.761V3.76z"
        fill="#1F1E3D"
      />
    </svg>
  );
}

export default SvgEyeOff;
