import { RealTooltipIcon } from 'assets/svg';
import React, { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'UIcomponents';

import styles from './styles.module.scss';
export type LinkLocationState = 'expert' | 'patient';
type FormErrorProps = {
  text: string;
  link?: string;
  linkText?: string;
  toolTipText?: string;
  linkLocationState?: LinkLocationState;
  customTextStyles?: CSSProperties;
  customContainerStyles?: CSSProperties;
  id?: string | undefined;
};

export const FormLabel: FC<FormErrorProps> = ({
  text,
  link,
  linkText,
  toolTipText,
  customTextStyles,
  customContainerStyles,
  linkLocationState,
  id,
}) => {
  return (
    <div className={styles['form-label']} style={customContainerStyles}>
      <p className={`subhead subhead_regular ${styles['form-label__text']}`} style={customTextStyles}>
        {text}
      </p>
      {link && linkText && (
        <Link
          to={{ pathname: link, state: linkLocationState }}
          tabIndex={-1}
          className={`subhead ${styles['form-label__link']}`}>
          {linkText}
        </Link>
      )}
      {toolTipText && (
        <div data-for={id} data-tip={toolTipText} className={styles['form-label__tooltip']}>
          <RealTooltipIcon fill={'#8e8e8e'} />
        </div>
      )}
      <Tooltip id={id} />
    </div>
  );
};
