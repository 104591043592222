import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/index.scss';

import { selectRole, selectToken } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectEmail } from 'features/expert/selectors';
import { selectPatientEmail, selectPatientExpert } from 'features/patient/selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AppRouting } from 'routes';
import { analytics } from 'services/analytics';
import { getLanguageFromStorage, LanguagesEnum, setLanguageToStorage } from 'services/storageService';
import { useAppSelector } from 'store/reducers';

function App(): JSX.Element {
  const { i18n } = useTranslation();

  //Metrics
  const isAuth = useSelector(selectToken);
  const role = useSelector(selectRole);
  const patientEmail = useSelector(selectPatientEmail);
  const expertEmail = useSelector(selectEmail);
  const myExpert = useAppSelector(selectPatientExpert);

  useEffect(() => {
    const language = getLanguageFromStorage();

    if (language === null) {
      setLanguageToStorage(LanguagesEnum.ru);
      i18n.changeLanguage(LanguagesEnum.ru);
    }

    if (language === LanguagesEnum.en) {
      i18n.changeLanguage(LanguagesEnum.en);
    } else {
      i18n.changeLanguage(LanguagesEnum.ru);
      setLanguageToStorage(LanguagesEnum.ru);
    }

    //Metrics
    if (isAuth) {
      if (role === RolesEnum.PATIENT && patientEmail) {
        analytics.setUser(patientEmail);

        analytics.removeEvent('role', RolesEnum.EXPERT);

        analytics.setEvent('role', RolesEnum.PATIENT);

        analytics.setEvent('isAttachedToExpert', !!myExpert);
      }

      if (role === RolesEnum.EXPERT && expertEmail) {
        analytics.setUser(expertEmail);

        analytics.removeEvent('role', RolesEnum.PATIENT);

        analytics.setEvent('role', RolesEnum.EXPERT);
      }
    } else {
      analytics.reset();
    }
  }, [isAuth, patientEmail, expertEmail, RolesEnum.PATIENT, RolesEnum.EXPERT]);

  return (
    <>
      <AppRouting />
      <ToastContainer />
    </>
  );
}

export default App;
