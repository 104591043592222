import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertFullInfoResDto, ExpertResDto } from 'api/generated';
import { Chat, Verified } from 'assets/svg';
import { BaseModal } from 'components/BaseModal';
import { CancelCooperationModal } from 'components/CancelCooperationModal';
import { actions } from 'features/chat/slice';
import { createChatRoomWithExpert, getOneExpert } from 'features/patient/thunks';
import { useClientSize, useToggle } from 'hooks';
import plural from 'plural-ru';
import { FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { useAppDispatch } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault, Spinner, Tooltip } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import { CooperationButton } from './components/CooperationButton';
import styles from './styles.module.scss';

type FullInfoAboutExpertModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  myExpert: boolean;
  expertId: string;
  isRequestInPending?: boolean;
  setExpertsCallback?: React.Dispatch<SetStateAction<ExpertResDto[]>>;
  experts?: ExpertResDto[];
  index?: number;
};

export const FullInfoAboutExpertModal: FC<FullInfoAboutExpertModalProps> = ({
  isModalOpened,
  onCloseModal,
  myExpert,
  expertId,
  isRequestInPending,
  experts,
  setExpertsCallback,
  index,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.expertProfileTab.fullInfoAboutExpertModal' });

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const [expertData, setExpertData] = useState<ExpertFullInfoResDto>();

  const [isExpertLoading, setExpertLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { isOpened: isOpenCancelCooperationModal, onToggle: toggleCancelCooperationModal } = useToggle();

  const getExpertInfo = async () => {
    setExpertLoading(true);
    await dispatch(getOneExpert(expertId))
      .then(unwrapResult)
      .then((result) => setExpertData(result))
      .catch((error) => {
        console.warn({ error });
      })
      .finally(() => setExpertLoading(false));
  };

  useEffect(() => {
    getExpertInfo();
  }, [expertId, isRequestInPending]);

  const handleCancelCooperationClick = async () => {
    toggleCancelCooperationModal();
  };

  const handleChatButtonClick = async () => {
    setLoading(true);
    await dispatch(createChatRoomWithExpert({ expertId: expertId }))
      .then(unwrapResult)
      .then((res) => dispatch(actions.activeChat(res)))
      .then(() => history.push(CommonRoutes.MESSAGE))
      .catch((error) => {
        console.warn({ error });
      })
      .finally(() => setLoading(false));

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.EXPERT_INFORMATION_MESSENGER_PRESSED);
  };

  const isVerifiedExpert = expertData && expertData.isApproved;

  return (
    <>
      <BaseModal
        isVisible={isModalOpened}
        onClose={onCloseModal}
        customStyles={{
          maxWidth: '603px',
        }}>
        {expertData && !isExpertLoading ? (
          <div className={styles['expert-info-modal__container']}>
            <div className={styles['expert-info-modal__header']}>
              <div className={styles['expert-info-modal__avatar']}>
                <img src={expertData.avatar} alt={`${expertData.name}`} />
              </div>
              <div className={styles['expert-info-modal__username_container']}>
                <h4 className={styles['expert-info-modal__expert-name']}>{expertData.name}</h4>
                {isVerifiedExpert && <Verified data-tip data-for="verifiedTooltip" />}
                <Tooltip id="verifiedTooltip" place="top">
                  <span>{t('eul')}</span>
                </Tooltip>
              </div>
            </div>
            <div className={styles['expert-info-modal__info-wrapper']}>
              <div className={styles['expert-info-modal__info-item-container']}>
                <p className={styles['expert-info-modal__info-label']}>{t('age')}</p>
                <p className={styles['expert-info-modal__info-item']}>
                  {expertData.age}{' '}
                  {expertData.age && plural(Math.floor(expertData.age), t('year'), t('years'), t('yearPlural'))}
                </p>
              </div>
              <div className={styles['expert-info-modal__info-item-container']}>
                <p className={styles['expert-info-modal__info-label']}>{t('spesialization')}</p>
                <p className={styles['expert-info-modal__info-item']}>{expertData.spesialization}</p>
              </div>
              <div className={styles['expert-info-modal__info-item-container']}>
                <p className={styles['expert-info-modal__info-label']}>{t('jobExperience')}</p>
                <p className={styles['expert-info-modal__info-item']}>
                  {expertData.jobExperience}{' '}
                  {expertData.jobExperience &&
                    plural(Math.floor(expertData.jobExperience), t('year'), t('years'), t('yearPlural'))}
                </p>
              </div>
            </div>

            <div className={styles['expert-info-modal__short-info-container']}>
              <p className={styles['expert-info-modal__info-label']}>{t('about')}</p>
              <p className={styles['expert-info-modal__short-info']}>{expertData?.shortInfo}</p>
            </div>

            <div className={styles['expert-info-modal__actions-container']}>
              <CooperationButton
                expertId={expertData.id}
                isMyExpert={myExpert}
                isRequestInPending={expertData.requestInPending}
                handleCancelCooperationClickCallBack={() => handleCancelCooperationClick()}
                experts={experts}
                setExperts={setExpertsCallback}
                index={index}
              />

              <ButtonDefault
                variant="secondary"
                onClick={handleChatButtonClick}
                customStyles={{
                  maxWidth: isMobile ? '100%' : '52px',
                  minWidth: isMobile ? '100%' : '52px',
                  padding: isMobile ? '16px 32px' : '16px 22px',
                }}
                isLoading={isLoading}>
                <div className={styles['expert-info-modal__chat-icon']}>
                  <Chat />
                </div>
                {isMobile && <div className={styles['expert-info-modal__button-text']}>{t('button')}</div>}
              </ButtonDefault>
            </div>
          </div>
        ) : (
          <Spinner variant="secondary" radius={10} />
        )}
      </BaseModal>

      {expertData && !isExpertLoading && (
        <CancelCooperationModal
          expertId={expertData.id}
          isOpen={isOpenCancelCooperationModal}
          onClose={toggleCancelCooperationModal}
        />
      )}
    </>
  );
};
