import classnames from 'classnames';
import useWindowWidth from 'hooks/useWindowWidth';
import { FC, useEffect, useState } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { InputDefault } from 'UIcomponents';

import styles from './styles.module.scss';

export type BiomarkerRow = { name: string; intName: string; value: number; unit: string };

interface CheckUpFormProps {
  biomarkers: Array<Array<BiomarkerRow>>;
  description: string;
}

export const CheckUpForm: FC<CheckUpFormProps> = ({ biomarkers, description }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const tableParams: { label: string; aspect: number }[] = [
    { label: t('biomarker'), aspect: 2 },
    { label: t('internationalDesignation'), aspect: 1 },
    {
      label: t('units'),
      aspect: 1,
    },
  ];

  const defaultAspects = tableParams.reduce<number[]>((array, item) => {
    array.push(item.aspect);
    return array;
  }, []);

  const [aspects, setAspects] = useState(defaultAspects);
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 640) setAspects([3, 0, 1]);
    else setAspects(defaultAspects);
  }, [width]);

  return (
    <>
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
      <div className={styles.table}>
        {!!biomarkers[1] && <h4 className={styles.subtitle}>Общий анализ крови</h4>}
        <div className={styles.header}>
          {tableParams.map((item, id) => {
            return (
              <p
                key={id}
                className={`body body-medium ${styles.header__item}`}
                style={{ width: `calc(100% / 4 * ${aspects[id]})` }}>
                {width <= 640 && id === 1 ? '' : item.label}
              </p>
            );
          })}
        </div>
        <div className={styles.body}>
          {biomarkers[0].map((biomarker, id) => (
            <div key={id} className={styles.row}>
              <div
                className={styles.row__item}
                style={{ width: `calc(100% / 4 * ${aspects[0]})` }}
                data-tip={`${biomarker.name} (${biomarker.intName})`}>
                <InputDefault value={biomarker.name} disabled />
              </div>
              <div
                className={classnames(styles.row__item, styles['row__item_int-name'])}
                style={{ width: `calc(100% / 4 * ${aspects[1]})` }}>
                <p>{biomarker.intName}</p>
              </div>
              <div className={styles.row__item} style={{ width: `calc(100% / 4 * ${aspects[2]})` }}>
                <Dotdotdot tagName="p" useNativeClamp clamp={2}>
                  {biomarker.unit}
                </Dotdotdot>
              </div>
            </div>
          ))}
          {!!biomarkers[1] && (
            <>
              <h4 className={styles.subtitle}>Биохимия</h4>
              {biomarkers[1].map((biomarker) => (
                <div key={biomarker.name} className={styles.row}>
                  <div
                    className={styles.row__item}
                    style={{ width: `calc(100% / 4 * ${aspects[0]})` }}
                    data-tip={`${biomarker.name} (${biomarker.intName})`}>
                    <InputDefault value={biomarker.name} disabled />
                  </div>
                  <div
                    className={classnames(styles.row__item, styles['row__item_int-name'])}
                    style={{ width: `calc(100% / 4 * ${aspects[1]})` }}>
                    <p>{biomarker.intName}</p>
                  </div>
                  {/* <div className={styles.row__item} style={{ width: 'calc(100% / 5 * 1)' }}>
                    <InputDefault value={biomarker.value} disabled />
                  </div> */}
                  <div className={styles.row__item} style={{ width: `calc(100% / 4 * ${aspects[2]})` }}>
                    <p>{biomarker.unit}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <ReactTooltip delayShow={500} place="right" />
    </>
  );
};
