import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Expert, Promocode } from 'api/generated';
import { findMeAsync as findMeExpertAsync } from 'features/expert/thunks';
import { findMeAsync as findMePatientAsync } from 'features/patient/thunks';
import { LoadingStatus } from 'types';

import { applyPromocode } from './thunks';
import { PromocodesStore } from './types';

const initialState: PromocodesStore = {
  usedPromocodes: [],
  loading: LoadingStatus.idle,
};

const promocodesSlice = createSlice({
  initialState,
  name: 'promocodes',
  reducers: {
    unsetStore: () => initialState,
  },
  extraReducers: {
    [applyPromocode.pending.type]: (state) => {
      state.loading = LoadingStatus.pending;
    },
    [applyPromocode.fulfilled.type]: (state, { payload }: PayloadAction<Promocode>) => {
      state.loading = LoadingStatus.idle;
      state.usedPromocodes = [...state.usedPromocodes, payload];
    },
    [findMeExpertAsync.fulfilled.type]: (state, { payload }: PayloadAction<Expert>) => {
      if (payload.usedPromocodes) {
        state.usedPromocodes = payload.usedPromocodes;
      }
    },
    [findMePatientAsync.fulfilled.type]: (state, { payload }: PayloadAction<Expert>) => {
      if (payload.usedPromocodes) {
        state.usedPromocodes = payload.usedPromocodes;
      }
    },
  },
});

export const { reducer } = promocodesSlice;
