import { selectNutritionsPlans } from 'features/expert/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { MiniPlan } from '../MiniPlan';

const Plans: FC = () => {
  const nutritionPlans = useSelector(selectNutritionsPlans);
  return (
    <>
      {nutritionPlans.map((plan) => (
        <MiniPlan key={plan.id} {...plan} />
      ))}
    </>
  );
};

export default Plans;
