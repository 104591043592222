import { BiomarkerGetResDto } from 'api/generated';
import classNames from 'classnames';
import { FC } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { DropdownDefault } from 'UIcomponents';

import styles from './styles.module.scss';

interface SearchBiomarkerValues {
  value: string;
}

export type BiomarkerItemProps = {
  biomarkers: BiomarkerGetResDto[];
  onChange: (data: SearchBiomarkerValues) => void;
};

export const SearchBiomarkerInput: FC<BiomarkerItemProps> = ({ biomarkers, onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const screenClass = useScreenClass();

  return (
    <>
      <Row
        className={classNames('body', 'body_medium', styles['biomarkers-item'])}
        style={{ minHeight: '52px', marginBottom: '34px' }}>
        <Col
          md={6}
          sm={5}
          xs={5}
          className={classNames('flexbox', 'align-start')}
          style={{ paddingLeft: 0, paddingRight: ['sm', 'xs'].includes(screenClass) ? '22px' : '32px' }}>
          <div className={classNames(styles['search-biomarkers-input'])}>
            <DropdownDefault
              type="input"
              placeholder={t('inputName') as string}
              options={biomarkers.map((biomarker) => {
                return { label: `${biomarker.label} (${biomarker.name})`, value: biomarker.id };
              })}
              onChange={onChange}
            />
          </div>
        </Col>

        <Col md={3} sm={0} xs={0} style={{ padding: '15px 0 0', display: 'flex', justifyContent: 'center' }}>
          -
        </Col>

        <Col md={3} sm={3} xs={4} style={{ padding: '15px 0 0', display: 'flex', justifyContent: 'center' }}>
          -
        </Col>
      </Row>
    </>
  );
};
