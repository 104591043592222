import { unwrapResult } from '@reduxjs/toolkit';
import { BiogeekExpertPatientCompleteOnboardingDtoGenderEnum, UpdatePatientDtoGenderEnum } from 'api/generated';
import { HorizontalArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { actions } from 'features/patientOnboarding';
import { selectOnboardingState } from 'features/patientOnboarding/selectors';
import { OnboardingState } from 'features/patientOnboarding/slice';
import { completeOnboardingPatientAsync } from 'features/patientOnboarding/thunks';
import { Step } from 'pages/PatientOnboarding';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { AllowedMasks } from 'types/Input';
import { CommonRoutes } from 'types/routes';
import {
  DatePickerDropdown,
  DropdownDefault,
  IconButton,
  InputDefault,
  MaskedInput,
  NumberMaskedInput,
} from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { hasNumbers, hasOnlyLettersAndSpaces } from 'utils/formsValidators';
import { patterns } from 'utils/patterns';
import { parsingSpecialCharacters, trimString } from 'utils/textHelper';
import { outOfSideDate } from 'utils/time';

import { selectPatientData } from '../../features/patient/selectors';
import { StepsNavigate } from './StepsNavigate';
import styles from './styles.module.scss';

export const Steps: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'onboarding' });

  const StepList = {
    name: {
      order: 0,
      label: t('stepFullname'),
      name: 'name',
    },
    birthDay: {
      order: 1,
      label: t('stepBirthday'),
      name: 'birthDay',
    },
    gender: {
      order: 2,
      label: t('stepGender'),
      name: 'gender',
    },
    weight: {
      order: 3,
      label: t('stepWeight'),
      name: 'weight',
    },
    height: {
      order: 4,
      label: t('stepHeight'),
      name: 'height',
    },
    phone: {
      order: 5,
      label: t('stepPhone'),
      name: 'phone',
    },
  };

  const GenderOptions = {
    male: { label: t('male'), value: UpdatePatientDtoGenderEnum.Male },
    female: { label: t('female'), value: UpdatePatientDtoGenderEnum.Female },
  };

  const [activeStep, setActiveStep] = useState(StepList.name.order);
  const dispatch = useAppDispatch();

  //Metrics
  const metricsTextMapper = {
    [0]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_NAME_ENTERED,

    [1]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_BIRTHDAY_ENTERED,

    [2]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_GENDER_ENTERED,

    [3]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_WEIGHT_ENTERED,

    [4]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_HEIGHT_ENTERED,

    [5]: CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_ONBOARDING_PHONE_ENTERED,
  }[activeStep];

  const updateAction = (data: OnboardingState, incrementStep = true) => {
    dispatch(actions.updateAction({ ...data }));

    if (incrementStep) {
      setActiveStep(activeStep + 1);
    }

    //Metrics
    metricsTextMapper && analytics.trackEvent(metricsTextMapper);
  };
  const handleClickPrevious = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };
  const onboardingValues = useSelector(selectOnboardingState);
  const patient = useSelector(selectPatientData);
  const [values, setValues] = useState(onboardingValues);

  const history = useHistory();
  const onSubmit = async (data: OnboardingState) => {
    await dispatch(
      completeOnboardingPatientAsync({
        name: values.name,
        birthday: new Date(values.birthDay).toISOString(),
        gender: values.gender as BiogeekExpertPatientCompleteOnboardingDtoGenderEnum,
        phone: data.phone ? data.phone : undefined,
        weight: values.weight,
        height: values.height,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notify('success', t('notifySuccess'));
        history.push(CommonRoutes.DASHBOARD);

        //Metrics
        analytics.trackEvent(metricsTextMapper ?? 'NOT FOUND EVENT');
      })
      .catch((error: Error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    if (onboardingValues) {
      setValues(onboardingValues);
    }
  }, [onboardingValues]);

  const onboardingPatietnState = useSelector(selectOnboardingState);

  return (
    <div className={classNames('container', styles['onboarding-container'])}>
      <div className={classNames(styles['previous-step'])}>
        {activeStep !== StepList.name.order && (
          <>
            <div className={classNames('flexbox', 'justify-start', 'align-center', styles['previous-step__btn'])}>
              <IconButton
                variant="secondary"
                icon={<HorizontalArrowIcon />}
                onClick={handleClickPrevious}
                customStyles={{ marginRight: '10px', transform: 'rotate(180deg)' }}
              />
              <p className={classNames('body_medium', styles['previous-step__title'])}>{t('previousStep')}</p>
            </div>

            <div
              className={classNames('flexbox', 'justify-start', 'align-center', styles['previous-step__btn--mobile'])}>
              <button
                onClick={handleClickPrevious}
                className={classNames('body_medium', styles['previous-step__title'])}>
                <HorizontalArrowIcon fill={'var(--dark-grey)'} />
                {t('previousStep')}
              </button>
            </div>
          </>
        )}
      </div>

      <StepsNavigate stepsCount={Object.keys(StepList).length} step={activeStep} />

      {activeStep === StepList.name.order && (
        <Step
          title={t('inputName')}
          inputLabel={StepList.name.label}
          inputName={StepList.name.name}
          showStepSubtitle={false}
          onSubmit={updateAction}
          render={(control, errors) => (
            <Controller
              name={StepList.name.name}
              rules={{
                required: t('required') as string,
                validate: (value) => {
                  if (!value.trim()) {
                    return t('required') as string;
                  }
                  if (hasNumbers(value)) {
                    return t('hasNumbers') as string;
                  }
                  if (!hasOnlyLettersAndSpaces(value)) {
                    const specialCharacters = parsingSpecialCharacters(value);
                    return `${t('hasOnlyLettersAndSpaces')} ${
                      specialCharacters?.length ? ': ' + specialCharacters : ''
                    }`;
                  }
                },
              }}
              defaultValue={patient.name ? patient.name : onboardingPatietnState.name}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  maxLength={40}
                  onChange={onChange}
                  onBlur={() => onChange(trimString(value))}
                  value={value || ''}
                  error={errors.name && 'error'}
                  placeholder={t('placeholderName') as string}
                  defaultValue={patient.name}
                />
              )}
            />
          )}
        />
      )}

      {activeStep === StepList.birthDay.order && (
        <Step
          title={t('birthday')}
          inputLabel={StepList.birthDay.label}
          inputName={StepList.birthDay.name}
          onSubmit={updateAction}
          render={(control) => (
            <Controller
              name={StepList.birthDay.name}
              rules={{ required: t('required') as string }}
              control={control}
              defaultValue={patient.birthday ? patient.birthday : onboardingPatietnState.birthDay}
              render={({ field: { onChange, value } }) => (
                <DatePickerDropdown
                  icon="calendar"
                  setSelectedDay={(value) => onChange(value.toISOString())}
                  selectedDay={new Date(value)}
                  variant="max-content"
                  disabledDays={{ after: outOfSideDate(18) }}
                  bigHeightMobile
                />
              )}
            />
          )}
        />
      )}

      {activeStep === StepList.gender.order && (
        <Step
          title={t('gender')}
          inputLabel={StepList.gender.label}
          inputName={StepList.gender.name}
          onSubmit={updateAction}
          render={(control, errors) => (
            <Controller
              name={StepList.gender.name}
              rules={{ required: t('required') as string }}
              control={control}
              defaultValue={patient.gender ? patient.gender : onboardingPatietnState.gender}
              render={({ field: { onChange, value } }) => {
                return (
                  <DropdownDefault
                    onChange={(option) => onChange(option.value)}
                    isSearchable={false}
                    selected={value && GenderOptions[value as BiogeekExpertPatientCompleteOnboardingDtoGenderEnum]}
                    options={[GenderOptions.male, GenderOptions.female]}
                    type="onboarding-input"
                    error={errors[StepList.gender.name] && 'error'}
                    bigHeightMobile
                  />
                );
              }}
            />
          )}
        />
      )}

      {activeStep === StepList.weight.order && (
        <Step
          title={t('weight')}
          inputLabel={StepList.weight.label}
          inputName={StepList.weight.name}
          onSubmit={updateAction}
          render={(control, errors) => (
            <Controller
              name={StepList.weight.name}
              rules={{ required: t('required') as string }}
              control={control}
              defaultValue={values.weight}
              render={({ field: { onChange, value } }) => {
                const suffix = t('suffix');
                const maxLength = 3 + suffix.length;
                return (
                  <NumberMaskedInput
                    maxLength={maxLength}
                    suffix={suffix}
                    onChange={(value) => {
                      updateAction({ ...values, ...{ weight: value } }, false);
                      return onChange(value);
                    }}
                    value={value || ''}
                    error={errors.weight && 'error'}
                    allowNegative={false}
                    decimalScale={0}
                    componentClassNames={classNames(styles['number-masked-input'])}
                  />
                );
              }}
            />
          )}
        />
      )}

      {activeStep === StepList.height.order && (
        <Step
          title={t('height')}
          inputLabel={StepList.height.label}
          inputName={StepList.height.name}
          onSubmit={updateAction}
          submitButtonText={t('next') as string}
          render={(control, errors) => (
            <Controller
              name={StepList.height.name}
              rules={{ required: t('required') as string }}
              control={control}
              defaultValue={values.height}
              render={({ field: { onChange, value } }) => {
                const suffix = t('suffixSm');
                const maxLength = 3 + suffix.length;
                return (
                  <NumberMaskedInput
                    maxLength={maxLength}
                    suffix={suffix}
                    onChange={(value) => {
                      updateAction({ ...values, ...{ height: value } }, false);
                      return onChange(value);
                    }}
                    decimalScale={0}
                    value={value || ''}
                    error={errors.height && 'error'}
                    allowNegative={false}
                    componentClassNames={classNames(styles['number-masked-input'])}
                  />
                );
              }}
            />
          )}
        />
      )}

      {activeStep === StepList.phone.order && (
        <Step
          title={t('enterPhoneNumber')}
          inputLabel={StepList.phone.label}
          inputName={StepList.phone.name}
          onSubmit={onSubmit}
          submitButtonText={t('start') as string}
          render={(control, errors) => (
            <Controller
              name={StepList.phone.name}
              control={control}
              rules={{
                pattern: {
                  value: patterns.phone,
                  message: t('phonePattern'),
                },
              }}
              defaultValue={patient.phone}
              render={({ field: { onChange, value } }) => {
                return (
                  <MaskedInput
                    mask={AllowedMasks.phone}
                    onChange={onChange}
                    value={value}
                    placeholder={t('placeholderPhone') as string}
                    error={errors[StepList.phone.name] && 'error'}
                  />
                );
              }}
            />
          )}
        />
      )}
    </div>
  );
};
