import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Analysis, SearchAnalyzesResDto } from 'api/generated';
import { LoadingStatus } from 'types';
import { getUniqueList } from 'utils/getUniqueList';

import {
  createAnalysisAsync,
  createAnalysisExpertAsync,
  deleteAnalysis,
  deleteExpertAnalysis,
  getAnalysisAsync,
  getAnalysisPdfWithoutPreviousAsync,
  getAnalysisPdfWithPreviousAsync,
  getAnalysisWithPreviousAsync,
  getManyAnalysisKinds,
  getManyAnalysisKindsExperts,
  searchAnalyzesAsync,
  updateAnalysisAsync,
  updateExpertAnalysisAsync,
} from './thunks';
import { AnalyzesStore } from './types';

const initialState: AnalyzesStore = {
  analyzes: [],
  analysisKinds: [],
  loading: LoadingStatus.idle,
  analyzesHistory: [],
  biologicalAgeAnalyzes: [],
  totalAnalyzesAmount: 0,
  selectedAnalysisId: null,
  lastAnalysisId: null,
  analyzesSearchStatus: LoadingStatus.idle,
  downloadingAnalysisPdfStatus: LoadingStatus.idle,
  deleteAnalysisLoadingStatus: LoadingStatus.idle,
  printingAnalysisPdfStatus: LoadingStatus.idle,
  recommendationsCalculationStatus: LoadingStatus.idle,
  analysisKindsStatus: LoadingStatus.idle,
  error: null,
  currentRequestId: undefined,
};
const analyzesSlice = createSlice({
  initialState,
  name: 'analyzes',
  reducers: {
    setSelectedAnalysisId: (state, action: PayloadAction<string>) => {
      state.selectedAnalysisId = action.payload;
    },
    setLastAnalysisId: (state, action: PayloadAction<string>) => {
      state.lastAnalysisId = action.payload;
    },
    unsetAnalyzes: () => initialState,
  },
  extraReducers: {
    [searchAnalyzesAsync.pending.type]: (state) => {
      if (state.analyzesSearchStatus === LoadingStatus.idle) {
        state.analyzesSearchStatus = LoadingStatus.pending;
      }
    },
    [searchAnalyzesAsync.fulfilled.type]: (state, { payload }: PayloadAction<SearchAnalyzesResDto>) => {
      state.analyzesSearchStatus = LoadingStatus.idle;
      if (payload) {
        state.analyzes = getUniqueList([...state.analyzes, ...payload.data], 'id');

        if (payload.data.length >= state.analyzes.length) {
          state.biologicalAgeAnalyzes = payload.data
            .filter((analysis: Analysis) => analysis?.analysisKind?.name === 'biological-age')
            .slice(-3);
        }
        if (!state.totalAnalyzesAmount) {
          state.totalAnalyzesAmount = payload.total;
          state.analyzesHistory = payload.data.slice(-7);
        }
      }
    },
    [searchAnalyzesAsync.rejected.type]: (state, { payload }) => {
      state.analyzesSearchStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [createAnalysisAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [createAnalysisAsync.fulfilled.type]: (state, { payload }: PayloadAction<Analysis>) => {
      state.analyzes = [...state.analyzes, payload];
      state.analyzes = [...state.analyzes].sort(
        (analysis, nextAnalysis) => new Date(analysis.date).valueOf() - new Date(nextAnalysis.date).valueOf(),
      );

      state.analyzesHistory =
        state.analyzesHistory.length > 6
          ? [...state.analyzesHistory, payload].slice(1)
          : [...state.analyzesHistory, payload];

      state.analyzesHistory = [...state.analyzesHistory].sort(
        (analysis, nextAnalysis) => new Date(analysis.date).valueOf() - new Date(nextAnalysis.date).valueOf(),
      );

      state.loading = LoadingStatus.idle;
    },
    [createAnalysisAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAnalysisAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [getAnalysisAsync.fulfilled.type]: (state, { payload }: PayloadAction<Analysis>) => {
      // state.analyzes.push(payload);
      state.loading = LoadingStatus.idle;
    },
    [getAnalysisAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    // [expertGetAnalysisAsync.pending.type]: (state) => {
    //   state.loading = LoadingStatus.pending;
    // },
    // [expertGetAnalysisAsync.fulfilled.type]: (state, { payload }) => {
    //   state.analyzes.push
    // },
    [updateAnalysisAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [updateAnalysisAsync.fulfilled.type]: (state, { payload }: PayloadAction<Analysis>) => {
      state.analyzes = [payload];
      state.loading = LoadingStatus.idle;
    },
    [updateAnalysisAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAnalysisWithPreviousAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [getAnalysisWithPreviousAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [getAnalysisWithPreviousAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getAnalysisPdfWithPreviousAsync.pending.type]: (state) => {
      if (state.downloadingAnalysisPdfStatus === LoadingStatus.idle) {
        state.downloadingAnalysisPdfStatus = LoadingStatus.pending;
      }
    },
    [getAnalysisPdfWithPreviousAsync.fulfilled.type]: (state) => {
      state.downloadingAnalysisPdfStatus = LoadingStatus.idle;
    },
    [getAnalysisPdfWithPreviousAsync.rejected.type]: (state, { payload }) => {
      state.downloadingAnalysisPdfStatus = LoadingStatus.rejected;
    },

    [getAnalysisPdfWithoutPreviousAsync.pending.type]: (state) => {
      if (state.downloadingAnalysisPdfStatus === LoadingStatus.idle) {
        state.downloadingAnalysisPdfStatus = LoadingStatus.pending;
      }
    },
    [getAnalysisPdfWithoutPreviousAsync.fulfilled.type]: (state) => {
      state.downloadingAnalysisPdfStatus = LoadingStatus.idle;
    },
    [getAnalysisPdfWithoutPreviousAsync.rejected.type]: (state) => {
      state.downloadingAnalysisPdfStatus = LoadingStatus.rejected;
    },

    [getManyAnalysisKinds.rejected.type]: (state, { payload }) => {
      state.analysisKindsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getManyAnalysisKinds.fulfilled.type]: (state, { payload }) => {
      state.analysisKinds = payload;

      state.analysisKindsStatus = LoadingStatus.idle;
    },
    [getManyAnalysisKindsExperts.rejected.type]: (state, { payload }) => {
      state.analysisKindsStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getManyAnalysisKindsExperts.fulfilled.type]: (state, { payload }) => {
      state.analysisKinds = payload;

      state.analysisKindsStatus = LoadingStatus.idle;
    },
    [deleteAnalysis.pending.type]: (state) => {
      state.deleteAnalysisLoadingStatus = LoadingStatus.pending;
    },
    [deleteAnalysis.rejected.type]: (state, { payload }) => {
      state.deleteAnalysisLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [deleteAnalysis.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.analyzes = state.analyzes.filter((analysis) => analysis.id !== payload);
      state.analyzesHistory = state.analyzesHistory.filter((analysis) => analysis.id !== payload);
      state.totalAnalyzesAmount = state.totalAnalyzesAmount - 1;
      state.deleteAnalysisLoadingStatus = LoadingStatus.idle;
    },
    [createAnalysisExpertAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [createAnalysisExpertAsync.rejected.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [createAnalysisExpertAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [updateExpertAnalysisAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [updateExpertAnalysisAsync.rejected.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [updateExpertAnalysisAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },

    [deleteExpertAnalysis.pending.type]: (state) => {
      if (state.deleteAnalysisLoadingStatus === LoadingStatus.idle) {
        state.deleteAnalysisLoadingStatus = LoadingStatus.pending;
      }
    },
    [deleteExpertAnalysis.rejected.type]: (state) => {
      state.deleteAnalysisLoadingStatus = LoadingStatus.idle;
    },
    [deleteExpertAnalysis.fulfilled.type]: (state) => {
      state.deleteAnalysisLoadingStatus = LoadingStatus.idle;
    },
  },
});
export const actions = {
  ...analyzesSlice.actions,
};
export const { reducer } = analyzesSlice;
