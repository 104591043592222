import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  const { fill = 'var(--black)' } = props;
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);
  return (
    <svg ref={ref} width={12} height={7} fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.537 9.256a.845.845 0 011.212 0 .888.888 0 010 1.238l-5.143 5.25a.845.845 0 01-1.185.026l-5.143-4.813a.888.888 0 01-.053-1.236.845.845 0 011.211-.054l4.538 4.247 4.563-4.658z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgArrow;
