import { FoldersIcon } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const EmptyRecommendations: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.recommendationTab' });

  return (
    <div className={styles.empty}>
      <div className={styles.empty__content}>
        <FoldersIcon className={styles.empty__icon} fill="var(--background-primary-light)" />
        <p className={`body body_medium ${styles['empty__subtitle']}`}>{t('emptyState')}</p>
      </div>
    </div>
  );
};
