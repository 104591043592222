import {
  Anamnesis,
  CommonInfoFamilyStatusEnum,
  DiseasesCheckEndocrinologicalProblemsEnum,
  DiseasesCheckLowEnergyTimeEnum,
  DiseasesCheckMemoryConditionEnum,
  DiseasesCheckProductsPortabilityEnum,
  Habits,
  HealthChecksCardiopalmusEnum,
  HealthChecksEmotionsControlDifficultyEnum,
  LevelLevelEnum,
  PhysicalActivityLevelEnum,
  StressAmountLevelEnum,
} from 'api/generated';
import classNames from 'classnames';
import { selectActivePatient } from 'features/expert/selectors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Placeholder } from './Placeholder';
import styles from './styles.module.scss';

export const AnamnesisReadonlyTab: FC<{ anamnes: Anamnesis }> = ({ anamnes }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.questionnaireTab' });
  const { t: tAnamnesisTab } = useTranslation('translation', { keyPrefix: 'patient.anamnesisTab' });

  const marriedStatusMapping = {
    [CommonInfoFamilyStatusEnum.Married]: tAnamnesisTab('familyOptionsSingle'),
    [CommonInfoFamilyStatusEnum.Single]: tAnamnesisTab('single'),
    [CommonInfoFamilyStatusEnum.Divorced]: tAnamnesisTab('divorced'),
  };

  const levelMapping = {
    [LevelLevelEnum.Good]: tAnamnesisTab('levelMappingGood'),
    [LevelLevelEnum.Bad]: tAnamnesisTab('levelMappingBad'),
    [LevelLevelEnum.Medium]: tAnamnesisTab('levelMappingMedium'),
    [LevelLevelEnum.Other]: '',
  };
  const healthChecksEmotionsMapping = {
    [HealthChecksEmotionsControlDifficultyEnum.Medium]: tAnamnesisTab('healthChecksEmotionsMedium'),
    [HealthChecksEmotionsControlDifficultyEnum.Difficult]: tAnamnesisTab('healthChecksEmotionsDifficult'),
    [HealthChecksEmotionsControlDifficultyEnum.Easy]: tAnamnesisTab('healthChecksEmotionsEasy'),
  };

  const stressMapping = {
    [StressAmountLevelEnum.Medium]: tAnamnesisTab('stressMedium'),
    [StressAmountLevelEnum.Low]: tAnamnesisTab('stressLow'),
    [StressAmountLevelEnum.High]: tAnamnesisTab('stressHigh'),
    [StressAmountLevelEnum.Other]: '',
  };

  const productsMapping = {
    [DiseasesCheckProductsPortabilityEnum.Good]: tAnamnesisTab('productsGood'),
    [DiseasesCheckProductsPortabilityEnum.Bad]: tAnamnesisTab('productsBad'),
    [DiseasesCheckProductsPortabilityEnum.NotPaidAttention]: tAnamnesisTab('productsNotPaidAttention'),
  };

  const energyMapping = {
    [DiseasesCheckLowEnergyTimeEnum.Morning]: tAnamnesisTab('energyMorning'),
    [DiseasesCheckLowEnergyTimeEnum.Dinner]: tAnamnesisTab('energyDinner'),
    [DiseasesCheckLowEnergyTimeEnum.Afternoon]: tAnamnesisTab('energyAfternoon'),
    [DiseasesCheckLowEnergyTimeEnum.AllDay]: tAnamnesisTab('energyAllDay'),
  };

  const activityMapping = {
    [PhysicalActivityLevelEnum.High]: tAnamnesisTab('activityHigh'),
    [PhysicalActivityLevelEnum.Medium]: tAnamnesisTab('activityMedium'),
    [PhysicalActivityLevelEnum.Low]: tAnamnesisTab('activityLow'),
    [PhysicalActivityLevelEnum.Other]: '',
  };

  enum HabbitsEnum {
    sugar = 'sugar',
    coffee = 'coffee',
    alcohol = 'alcohol',
    smoking = 'smoking',
  }

  const habbitsMapping = {
    [HabbitsEnum.sugar]: tAnamnesisTab('habbitsSugar'),
    [HabbitsEnum.coffee]: tAnamnesisTab('habbitsCoffee'),
    [HabbitsEnum.alcohol]: tAnamnesisTab('habbitsAlcohol'),
    [HabbitsEnum.smoking]: tAnamnesisTab('habbitsSmoking'),
  };

  const endocrinologicalProblemsMapping = {
    [DiseasesCheckEndocrinologicalProblemsEnum.Yes]: tAnamnesisTab('endocrinologicalYes'),
    [DiseasesCheckEndocrinologicalProblemsEnum.No]: tAnamnesisTab('endocrinologicalNo'),
    [DiseasesCheckEndocrinologicalProblemsEnum.Unknown]: tAnamnesisTab('endocrinologicalUnknown'),
  };

  const memoryConditionMapping = {
    [DiseasesCheckMemoryConditionEnum.Bad]: tAnamnesisTab('memoryConditionBad'),
    [DiseasesCheckMemoryConditionEnum.Medium]: tAnamnesisTab('memoryConditionMedium'),
    [DiseasesCheckMemoryConditionEnum.Good]: tAnamnesisTab('memoryConditionGood'),
  };

  const cardiopalmusMapping = {
    [HealthChecksCardiopalmusEnum.No]: tAnamnesisTab('cardiopalmusNo'),
    [HealthChecksCardiopalmusEnum.Sometimes]: tAnamnesisTab('cardiopalmusSometimes'),
    [HealthChecksCardiopalmusEnum.WithoutLoad]: tAnamnesisTab('cardiopalmusWithoutLoad'),
  };

  const hadHabbits = (habbits: Habits) => {
    const result: Array<string> = [];
    for (const key in habbits) {
      if (habbits[key as HabbitsEnum]) {
        result.push(habbitsMapping[key as HabbitsEnum]);
      }
    }
    return result.join(',  ');
  };

  const patient = useSelector(selectActivePatient);
  const { address, familyStatus, hasChildren, hasOccupationalHealth, phoneNumber, profession } = anamnes?.commonInfo;
  const {
    pulse,
    weight,
    waist,
    height,
    howResolvedTroubles,
    emotionsControlDifficulty,
    shortTasksDescription,
    stressAmount,
    breathSystem,
    excretorySystem,
    cardiopalmus,
    bloodPressure,
    digestionLevel,
  } = anamnes.healthChecks;
  const { anamnesis, complaints, lifeChangeTrigger, lifeSatisfaction, readyToDoForHealthSupport } =
    anamnes.healthQuestions;
  const isRegular = anamnes?.cycle?.isRegular;
  const duration = anamnes?.cycle?.duration;
  const pmsSymptoms = anamnes?.cycle?.PMSSymptoms;
  const cycleComment = anamnes?.cycle?.commentText;
  const {
    useHealthCheckGadgets,
    productsPortability,
    allergy,
    endocrinologicalProblems,
    hairNailsSkinCondition,
    haveDiet,
    haveLivedInPatmosphericPollutionAreas,
    havingHabitsAndAddictions,
    lowEnergyTime,
    memoryCondition,
    physicalActivityLevel,
    relativesDiseases,
    respiratoryDiseaseIncidence,
    sleepQuality,
    vitaminsAndDietarySupplements,
  } = anamnes.diseasesCheck;

  const actualAnamnes = {
    commonInfo: [
      {
        title: t('labelFullName'),
        body: patient.name,
      },
      {
        title: t('labelEmail'),
        body: patient.email,
      },
      {
        title: t('labelAddress'),
        body: address,
      },
      {
        title: t('labelPhoneNumber'),
        body: phoneNumber,
      },
      {
        title: t('labelProfession'),
        body: profession,
      },
      {
        title: t('labelMarriedStatus'),
        body: marriedStatusMapping[familyStatus],
      },
      {
        title: t('labelOccupationalHealth'),
        body: hasOccupationalHealth ? t('yes') : t('no'),
      },
      {
        title: t('labelChildren'),
        body: hasChildren ? t('thereIs') : t('no'),
      },
    ],
    healthChecks: [
      {
        title: t('labelBloodPressure'),
        body: bloodPressure,
      },
      {
        title: t('labelPulse'),
        body: pulse,
      },
      {
        title: t('labelHeight'),
        body: height,
      },
      {
        title: t('labelWeight'),
        body: weight,
      },
      {
        title: t('labelWaist'),
        body: waist,
      },
    ],
    tasks: shortTasksDescription,
    tries: howResolvedTroubles,
    digestiveLevel:
      digestionLevel.level === LevelLevelEnum.Other ? digestionLevel.commentText : levelMapping[digestionLevel.level],
    stressLevel:
      stressAmount.level === StressAmountLevelEnum.Other ? stressAmount.commentText : stressMapping[stressAmount.level],
    respiratorySystem:
      breathSystem.level === LevelLevelEnum.Other
        ? breathSystem.commentText
        : levelMapping[breathSystem.level as LevelLevelEnum],
    emotionControl: healthChecksEmotionsMapping[emotionsControlDifficulty],
    tachycardia: cardiopalmusMapping[cardiopalmus],
    excretion: levelMapping[excretorySystem as LevelLevelEnum],
    cycle: {
      length: undefined,
      regularity: undefined,
      PMS: undefined,
    },
    extraInfo: [
      {
        title: t('products'),
        body: productsMapping[productsPortability as DiseasesCheckProductsPortabilityEnum],
      },
      {
        title: t('vitaminsAndDietarySupplements'),
        body: vitaminsAndDietarySupplements ? t('yes') : t('no'),
      },
      {
        title: t('allergy'),
        body: allergy ? t('yes') : t('no'),
      },
      {
        title: t('respiratoryDiseaseIncidence'),
        body: levelMapping[respiratoryDiseaseIncidence as LevelLevelEnum],
      },
      {
        title: t('relativesDiseases'),
        body: relativesDiseases,
      },
      {
        title: t('endocrinologicalProblems'),
        body: endocrinologicalProblemsMapping[endocrinologicalProblems],
      },
      {
        title: t('sleepQuality'),
        body: levelMapping[sleepQuality as LevelLevelEnum],
      },
      {
        title: t('physicalActivityLevel'),
        body:
          physicalActivityLevel.level === PhysicalActivityLevelEnum.Other
            ? physicalActivityLevel.commentText
            : activityMapping[physicalActivityLevel.level],
      },
      {
        title: t('havingHabitsAndAddictions'),
        body: hadHabbits(havingHabitsAndAddictions),
      },
      {
        title: t('lowEnergyTime'),
        body: energyMapping[lowEnergyTime],
      },
      {
        title: t('haveLivedInPatmosphericPollutionAreas'),
        body: haveLivedInPatmosphericPollutionAreas ? t('yes') : t('no'),
      },
      {
        title: t('hairNailsSkinCondition'),
        body: hairNailsSkinCondition,
      },
      {
        title: t('haveDiet'),
        body: haveDiet ? t('yes') : t('no'),
      },
      {
        title: t('useHealthCheckGadgets'),
        body: useHealthCheckGadgets ? t('yes') : t('no'),
      },
      {
        title: t('memoryCondition'),
        body: memoryConditionMapping[memoryCondition],
      },
    ],
    other: [
      {
        title: t('lifeChangeTrigger'),
        body: lifeChangeTrigger,
      },
      {
        title: t('lifeSatisfaction'),
        body: lifeSatisfaction,
      },
      {
        title: t('readyToDoForHealthSupport'),
        body: readyToDoForHealthSupport,
      },
      {
        title: t('anamnesis'),
        body: anamnesis,
      },
      {
        title: t('complaints'),
        body: complaints,
      },
    ],
  };

  return (
    <div className={classNames(styles['anamnesis'])}>
      <h3 className={classNames(styles['anamnesis__heading'])}>{t('heading')}</h3>
      <div>
        <h4 className={classNames(styles['anamnesis__subheading'])}>{t('mainInfo')}</h4>
        <div className={classNames(styles['anamnesis__block'])}>
          {actualAnamnes.commonInfo.map((placeholder, index) => (
            <Placeholder key={index} title={placeholder.title} body={placeholder.body as string} />
          ))}
        </div>
      </div>
      <div>
        <h4 className={classNames(styles['anamnesis__subheading'])}>{t('physicalState')}</h4>
        <div className={classNames(styles['anamnesis__flex-block'])}>
          {actualAnamnes.healthChecks.map((placeholder, index) => {
            if (index === 0) {
              return (
                <Placeholder
                  key={index}
                  title={placeholder.title}
                  body={`${placeholder.body.toString().slice(0, 3)}/${placeholder.body.toString().slice(3)}`}
                />
              );
            }
            while (index < 4) {
              return <Placeholder key={index} title={placeholder.title} body={placeholder.body} />;
            }
            return (
              <Placeholder
                customClassName={classNames(styles['long-placeholder'])}
                key={index}
                title={placeholder.title}
                body={placeholder.body}
              />
            );
          })}
        </div>
        <div className={classNames(styles['anamnesis__block'])}>
          <Placeholder title={t('descriptionMainTasks')} body={actualAnamnes.tasks} />
          <Placeholder title={t('solveProblems')} body={actualAnamnes.tries} />
          <Placeholder title={t('digestion')} body={String(actualAnamnes.digestiveLevel)} />
          <Placeholder title={t('stress')} body={String(actualAnamnes.stressLevel)} />
          <Placeholder title={t('respiratorySystem')} body={String(actualAnamnes.respiratorySystem)} />
          <Placeholder title={t('emotionsAndAnxiety')} body={actualAnamnes.emotionControl} />
          <Placeholder title={t('cardiopalmus')} body={actualAnamnes.tachycardia} />
          <Placeholder title={t('urination')} body={actualAnamnes.excretion} />
        </div>
      </div>
      {patient.gender === 'female' && (
        <div>
          <h4 className={classNames(styles['anamnesis__subheading'])}>{t('menstruation')}</h4>
          <div className={classNames(styles['anamnesis__block'])}>
            <Placeholder title={t('duration')} body={String(duration)} />
            <Placeholder title={t('regular')} body={isRegular ? 'Да' : 'Нет'} />
            <Placeholder title={t('pms')} body={pmsSymptoms ? 'Есть' : 'Нет'} />
            <Placeholder title={t('comment')} body={cycleComment ? cycleComment : ''} />
          </div>
        </div>
      )}
      <div>
        <h4 className={classNames(styles['anamnesis__subheading'])}>{t('additionalInfo')}</h4>
        <div className={classNames(styles['anamnesis__block'])}>
          {actualAnamnes.extraInfo.map((placeholder, index) => (
            <Placeholder key={index} title={placeholder.title} body={placeholder.body as string} />
          ))}
        </div>
      </div>
      <div>
        <h4 className={classNames(styles['anamnesis__subheading'])}>{t('other')}</h4>
        <div className={classNames(styles['anamnesis__block'])}>
          {actualAnamnes.other.map((placeholder, index) => (
            <Placeholder key={index} title={placeholder.title} body={placeholder.body} />
          ))}
        </div>
      </div>
      <div></div>
    </div>
  );
};
