import classNames from 'classnames';
import { FC } from 'react';

import styles from './styles.module.scss';

type StepsNavigateProps = {
  stepsCount: number;
  step: number;
};
type TStepsNavigate = FC<StepsNavigateProps>;

export const StepsNavigate: TStepsNavigate = ({ stepsCount, step }) => {
  return (
    <div className={classNames('flexbox', 'justify-start', 'onboarding-steps-navigate')}>
      {Array.from(Array(stepsCount)).map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(
              styles['onboarding-steps-navigate__step'],
              styles[
                index <= step
                  ? 'onboarding-steps-navigate__step-complete'
                  : 'onboarding-steps-navigate__step-incomplete'
              ],
            )}
          />
        );
      })}
    </div>
  );
};
