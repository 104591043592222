import classNames from 'classnames';
import { FC } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const TableHeader: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'analysis' });

  return (
    <>
      <Row className={classNames('body', 'body_medium', styles.biomarkers__header)}>
        <Col
          md={6}
          sm={5}
          xs={5}
          style={{ padding: '0', color: 'var(--dark-grey)', display: 'flex', alignItems: 'center' }}>
          {t('biomarker')}
        </Col>
        <Col
          md={3}
          sm={4}
          xs={3}
          style={{
            padding: '0',
            textAlign: 'center',
            color: 'var(--dark-grey)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {t('internationalDesignation')}
        </Col>
        <Col
          md={3}
          sm={4}
          xs={3}
          style={{
            padding: '0',
            textAlign: 'center',
            color: 'var(--dark-grey)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {t('units')}
        </Col>
      </Row>
    </>
  );
};
