import { LoupeIcon } from 'assets/svg';
import classnames from 'classnames';
import { actions } from 'features/expert/slice';
import { sortPatientsAsync } from 'features/expert/thunks';
import { useDebouncedSearch } from 'hooks/useDebouncedCallback';
import React, { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { DropdownDefault } from 'UIcomponents';

import { InputDefault } from '../InputDefault';
import styles from './Search.module.scss';

interface OptionTypes {
  label: string;
  value: {
    signUpStatus: 'sent' | 'approved' | undefined;
    onlyNewRequests: boolean | undefined;
  };
}

type SearchProps = {
  customStyles?: CSSProperties;
  isPatientListEmpty: boolean;
};

export const Search: FC<SearchProps> = ({ customStyles, isPatientListEmpty }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.search' });

  const [search, setSearch] = useState<string>('');
  const filterValue = useAppSelector((state) => state.expert.filters);

  const options: Array<OptionTypes> = [
    { label: t('allPatients'), value: { signUpStatus: undefined, onlyNewRequests: undefined } },
    { label: t('approvedPatients'), value: { signUpStatus: 'approved', onlyNewRequests: undefined } },
    { label: t('unconfirmedPatients'), value: { signUpStatus: 'sent', onlyNewRequests: undefined } },
    { label: t('newRequests'), value: { signUpStatus: undefined, onlyNewRequests: true } },
  ];

  const filterOption = options.findIndex((option) => option.value === filterValue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (search?.length) {
      dispatch(actions.changeIsSearchingPatientsOnDashboard(true));
    } else if (search?.length === 0) {
      setTimeout(() => {
        dispatch(actions.changeIsSearchingPatientsOnDashboard(false));
      }, 1000);
    }
  }, [search]);

  const sortPatients = (filter: string): void => {
    dispatch(sortPatientsAsync({ page: 1, limit: 10000, filter })); //TODO: need paginations
  };

  const debounced = useDebouncedSearch(sortPatients, 600);

  const filterPatients = (signUpStatus: 'sent' | 'approved' | undefined, onlyNewRequests: boolean | undefined) => {
    if (onlyNewRequests !== undefined) {
      dispatch(actions.changeIsFilterOnlyNewPatientsOnDashboard(onlyNewRequests));
    } else {
      dispatch(actions.changeIsFilterOnlyNewPatientsOnDashboard(false));
    }
    dispatch(actions.changeIsSearchingPatientsOnDashboard(true));
    dispatch(
      sortPatientsAsync({
        page: 1,
        limit: 10000, //TODO: need paginations
        sortField: 'name' as 'name' | 'birthday' | 'createdAt',
        signUpStatus: signUpStatus,
        sortDirection: 'DESC',
        onlyNewRequests: onlyNewRequests,
      }),
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounced.current(event.target.value);
  };

  const onSubmit = (e: React.MouseEvent<SVGSVGElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(sortPatientsAsync({ page: 1, limit: 10000, filter: search })); //TODO: need paginations
  };

  return (
    <>
      <div className={styles.search__container} style={customStyles}>
        <div className={classnames('flexbox', styles['search__box'])}>
          <div className={styles.search}>
            <form onSubmit={onSubmit}>
              <LoupeIcon className={styles.search__icon} onClick={onSubmit} />
              <InputDefault
                placeholder={t('placeholder') as string}
                customStyles={{ paddingLeft: '44px', minWidth: '366px' }}
                onChange={handleChange}
                value={search}
                disabled={isPatientListEmpty}
              />
            </form>
          </div>
        </div>
        <div className={styles.filters}>
          <DropdownDefault
            placeholder={t('placeholderDropdown') as string}
            options={options}
            selected={options[filterOption]}
            onChange={(value) => {
              filterPatients(value.value.signUpStatus, value.value.onlyNewRequests);
              dispatch(actions.setFilters(value.value));
            }}
            isSearchable={false}
            disabled={isPatientListEmpty}
          />
        </div>
      </div>
      <div>
        <h4 className={styles['search__title_mobile']}>{t('listPatient')}</h4>
        <div className={classnames('flexbox', styles['search__box_mobile'])}>
          <div className={styles.search}>
            <form>
              <LoupeIcon className={styles.search__icon} onClick={onSubmit} />
              <InputDefault
                placeholder={t('placeholder') as string}
                customStyles={{ paddingLeft: '44px', minWidth: '264px' }}
                onChange={handleChange}
                value={search}
                disabled={isPatientListEmpty}
              />
            </form>
          </div>
        </div>
        <div className={styles['filters-mobile']}>
          <DropdownDefault
            options={options}
            selected={options[filterOption]}
            onChange={(value) => {
              filterPatients(value.value.signUpStatus, value.value.onlyNewRequests);
              dispatch(actions.setFilters(value.value));
            }}
            isSearchable={false}
            disabled={isPatientListEmpty}
          />
        </div>
      </div>
    </>
  );
};
