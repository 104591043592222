import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgCheckCircle(props: React.SVGProps<SVGSVGElement>) {
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);
  return (
    <svg
      ref={ref}
      width={148}
      height={148}
      fill="none"
      viewBox="0 0 148 148"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M135.667 68.326v5.673A61.67 61.67 0 0191.485 133.1a61.666 61.666 0 117.613-115.464"
        stroke="#59CDA4"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.667 24.667L74 86.395l-18.5-18.5"
        stroke="#59CDA4"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckCircle;
