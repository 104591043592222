import * as React from 'react';

function Vector(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_11009_52506)">
        <path
          d="M5.00488 10.9999H15.595L12.295 7.70994C12.1067 7.52164 12.0009 7.26624 12.0009 6.99994C12.0009 6.73364 12.1067 6.47825 12.295 6.28994C12.4833 6.10164 12.7387 5.99585 13.005 5.99585C13.2713 5.99585 13.5267 6.10164 13.715 6.28994L18.715 11.2899C18.806 11.385 18.8774 11.4972 18.925 11.6199C19.025 11.8634 19.025 12.1365 18.925 12.3799C18.8774 12.5027 18.806 12.6148 18.715 12.7099L13.715 17.7099C13.622 17.8037 13.5114 17.8781 13.3896 17.9288C13.2677 17.9796 13.137 18.0057 13.005 18.0057C12.873 18.0057 12.7423 17.9796 12.6204 17.9288C12.4986 17.8781 12.3879 17.8037 12.295 17.7099C12.2013 17.617 12.1269 17.5064 12.0761 17.3845C12.0253 17.2627 11.9992 17.132 11.9992 16.9999C11.9992 16.8679 12.0253 16.7372 12.0761 16.6154C12.1269 16.4935 12.2013 16.3829 12.295 16.2899L15.595 12.9999H5.00488C4.73967 12.9999 4.48531 12.8946 4.29778 12.707C4.11024 12.5195 4.00488 12.2652 4.00488 11.9999C4.00488 11.7347 4.11024 11.4804 4.29778 11.2928C4.48531 11.1053 4.73967 10.9999 5.00488 10.9999Z"
          fill="#262626"
        />
      </g>
      <defs>
        <clipPath id="clip0_11009_52506">
          <rect width={24} height={24} fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Vector;
