import * as React from 'react';

function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={13} height={12} fill="none">
      <path
        d="M11.83 5.167H3.006l2.75-2.742a.837.837 0 10-1.183-1.183L.405 5.408a.833.833 0 00-.175.275.833.833 0 000 .634c.04.102.1.195.175.275l4.167 4.166a.833.833 0 001.183 0 .832.832 0 000-1.183l-2.75-2.742h8.826a.833.833 0 000-1.666z"
        fill="#8E8E8E"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
