import { DotsIcon, UserIcon } from 'assets/svg';
import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FC, useRef, useState } from 'react';
import { UserProfileMenu } from 'UIcomponents';

import styles from './styles.module.scss';

type UserProfileCardProps = {
  name?: string;
  isActive?: boolean;
};

export const UserProfileCard: FC<UserProfileCardProps> = ({ name = 'Unknown', isActive = false }) => {
  const ref = useRef(null);
  const [mainMenuIsOpen, setMainMenuIsOpen] = useState(false);
  const handleClick = () => {
    setMainMenuIsOpen(!mainMenuIsOpen);
  };
  const handleClickOutside = () => {
    setMainMenuIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={classNames('flexbox', 'justify-end', 'align-center')}>
      {isActive && (
        <>
          <div className={classNames(styles['profile-name'])}>{name}</div>
          <div
            className={classNames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
            role="button"
            tabIndex={-1}
            onClick={handleClick}
            onKeyDown={handleClick}
            onTouchEnd={handleClick}>
            {<UserIcon fill={'var(--dark-grey)'} />}
          </div>
        </>
      )}
      <div className={classNames('flexbox', styles['main-menu-button'])}>
        <DotsIcon onClick={handleClick} className={styles['main-menu-button__dots']} />
        {mainMenuIsOpen && <UserProfileMenu forwardedRef={ref} isActive={isActive} />}
      </div>
    </div>
  );
};
