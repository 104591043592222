import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'types';

import { RedirectUrlDto, SubscriptionsConstantsResponseDto } from '../../api/generated/api';
import { SubscriptionDto } from './../../api/generated/api';
import {
  cancelSubscriptionAsync,
  getCustomerPortalLinkAsync,
  getPaymentUrlAsync,
  getSubscriptionsConstantsAsync,
  renewSubscriptionAsync,
  retreiveSubscriptionAsync,
  updateSubscriptionAsync,
} from './thunks';
import { SubscriptionStore } from './types';

const initialState: SubscriptionStore = {
  subscription: {} as SubscriptionDto,
  subscriptionsConstants: {} as SubscriptionsConstantsResponseDto,
  customerPortalLink: '',
  getSubscriptionLoading: LoadingStatus.idle,
  getSubscriptionsConstantsLoading: LoadingStatus.idle,
  getSessionLinkLoading: LoadingStatus.idle,
  getCustomerPortalLinkLoading: LoadingStatus.idle,
  updateSubscriptionLoading: LoadingStatus.idle,
  cancelSubscriptionLoading: LoadingStatus.idle,
  renewSubscriptionLoading: LoadingStatus.idle,
  error: null,
  currentRequestId: undefined,
};
const subscriptionsSlice = createSlice({
  initialState,
  name: 'sibscriptions',
  reducers: {
    unsetStore: () => initialState,
  },
  extraReducers: {
    [retreiveSubscriptionAsync.pending.type]: (state) => {
      if (state.getSubscriptionLoading === LoadingStatus.idle) {
        state.getSubscriptionLoading = LoadingStatus.pending;
      }
    },
    [retreiveSubscriptionAsync.fulfilled.type]: (state, { payload }: PayloadAction<SubscriptionDto>) => {
      state.getSubscriptionLoading = LoadingStatus.idle;
      if (payload) {
        state.subscription = payload;
      }
    },
    [retreiveSubscriptionAsync.rejected.type]: (state, { payload }) => {
      state.getSubscriptionLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    // [getSubscriptionAsync.pending.type]: (state) => {
    //   if (state.getSubscriptionLoading === LoadingStatus.idle) {
    //     state.getSubscriptionLoading = LoadingStatus.pending;
    //   }
    // },
    // [getSubscriptionAsync.fulfilled.type]: (state, { payload }) => {
    //   state.getSubscriptionLoading = LoadingStatus.idle;
    //   if (payload) {
    //     state.subscription = payload;
    //   }
    // },
    // [getSubscriptionAsync.rejected.type]: (state, { payload }) => {
    //   state.getSubscriptionLoading = LoadingStatus.idle;
    //   if (payload.message && payload.statusCode) {
    //     state.error = { message: payload.message, statusCode: payload.statusCode };
    //   } else {
    //     state.error = payload.error;
    //   }
    // },
    [getSubscriptionsConstantsAsync.pending.type]: (state) => {
      if (state.getSubscriptionsConstantsLoading === LoadingStatus.idle) {
        state.getSubscriptionsConstantsLoading = LoadingStatus.pending;
      }
    },
    [getSubscriptionsConstantsAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<SubscriptionsConstantsResponseDto>,
    ) => {
      state.getSubscriptionsConstantsLoading = LoadingStatus.idle;
      if (payload) {
        state.subscriptionsConstants = payload;
      }
    },
    [getSubscriptionsConstantsAsync.rejected.type]: (state, { payload }) => {
      state.getSubscriptionsConstantsLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [updateSubscriptionAsync.pending.type]: (state) => {
      if (state.updateSubscriptionLoading === LoadingStatus.idle) {
        state.updateSubscriptionLoading = LoadingStatus.pending;
      }
    },
    [updateSubscriptionAsync.fulfilled.type]: (state, { payload }: PayloadAction<SubscriptionDto>) => {
      state.updateSubscriptionLoading = LoadingStatus.idle;
      if (payload) {
        state.subscription = payload;
      }
    },
    [updateSubscriptionAsync.rejected.type]: (state, { payload }) => {
      state.updateSubscriptionLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [cancelSubscriptionAsync.pending.type]: (state) => {
      if (state.cancelSubscriptionLoading === LoadingStatus.idle) {
        state.cancelSubscriptionLoading = LoadingStatus.pending;
      }
    },
    [cancelSubscriptionAsync.fulfilled.type]: (state, { payload }: PayloadAction<SubscriptionDto>) => {
      state.cancelSubscriptionLoading = LoadingStatus.idle;
      if (payload) {
        state.subscription = payload;
      }
    },
    [cancelSubscriptionAsync.rejected.type]: (state, { payload }) => {
      state.cancelSubscriptionLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [renewSubscriptionAsync.pending.type]: (state) => {
      if (state.renewSubscriptionLoading === LoadingStatus.idle) {
        state.renewSubscriptionLoading = LoadingStatus.pending;
      }
    },
    [renewSubscriptionAsync.fulfilled.type]: (state, { payload }: PayloadAction<SubscriptionDto>) => {
      state.renewSubscriptionLoading = LoadingStatus.idle;
      if (payload) {
        state.subscription = payload;
      }
    },
    [renewSubscriptionAsync.rejected.type]: (state, { payload }) => {
      state.renewSubscriptionLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getPaymentUrlAsync.pending.type]: (state) => {
      if (state.getSessionLinkLoading === LoadingStatus.idle) {
        state.getSessionLinkLoading = LoadingStatus.pending;
      }
    },
    [getPaymentUrlAsync.fulfilled.type]: (state) => {
      state.getSessionLinkLoading = LoadingStatus.idle;
    },
    [getPaymentUrlAsync.rejected.type]: (state, { payload }) => {
      state.getSessionLinkLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [getCustomerPortalLinkAsync.pending.type]: (state) => {
      if (state.getCustomerPortalLinkLoading === LoadingStatus.idle) {
        state.getCustomerPortalLinkLoading = LoadingStatus.pending;
      }
    },
    [getCustomerPortalLinkAsync.fulfilled.type]: (state, { payload }: PayloadAction<RedirectUrlDto>) => {
      state.customerPortalLink = payload.redirectUrl;
      state.getCustomerPortalLinkLoading = LoadingStatus.idle;
    },
    [getCustomerPortalLinkAsync.rejected.type]: (state, { payload }) => {
      state.getCustomerPortalLinkLoading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    // updateSubscriptionAsync,
  },
});
export const actions = {
  ...subscriptionsSlice.actions,
};
export const { reducer } = subscriptionsSlice;
