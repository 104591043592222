import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCreateCustomCheckupDto } from 'api/generated';
import { PlusIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { FormBiomarkerItemField } from 'features/biomarkers/types';
import { selectActivePatient, selectSetCustomCheckupForPatientStatus } from 'features/expert/selectors';
import { createAndAssignCustomCheckUpForPatient } from 'features/expert/thunks';
import { useClientSize } from 'hooks';
import { LayoutWithBackOptions } from 'layouts';
import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { ButtonDefault, FormError, FormLabel, InputDefault } from 'UIcomponents';

import { FormBiomarkerItem, SearchBiomarkerInput } from './components';
import styles from './styles.module.scss';

export type FormValues = {
  biomarkers: FormBiomarkerItemField[];
  analysisName: string;
  [key: string]: any;
};

export const CreateCustomCheckUp: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const history = useHistory();
  const dispatch = useAppDispatch();

  const allBiomarkers = useAppSelector(selectBiomarkers);
  const patient = useAppSelector(selectActivePatient);
  const isSetCustomCheckupLoading = useAppSelector(selectSetCustomCheckupForPatientStatus) === LoadingStatus.pending;

  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'biomarkers',
    keyName: 'key',
  });

  const analysisName = watch('analysisName');

  const [isSearchInputVisible, setSearchInputVisible] = useState(false);

  const hasEmptyCustomCheckup = fields.length === 0;
  const isAssignDisable = hasEmptyCustomCheckup || analysisName === undefined;

  const handleAssignCustomAnalysis = () => {
    const biomarkerIds = fields.map((biomarker) => biomarker.id);

    const checkup: ExpertCreateCustomCheckupDto = {
      name: analysisName,
      biomarkersIds: biomarkerIds,
      patientId: patient.id,
    };

    dispatch(createAndAssignCustomCheckUpForPatient(checkup))
      .then(unwrapResult)
      .then(() => {
        notify('success', t('analysisAssign'));
        history.push(`${ExpertRoutes.PATIENT_PROFILE(patient.id)}/analyzes`);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const onChangeSearchInput = (data: { value: string }) => {
    const newCustomBiomarker = allBiomarkers.find((biomarker) => biomarker.id === data.value);
    if (newCustomBiomarker) {
      append(newCustomBiomarker);
      setSearchInputVisible(false);
    }
  };

  const handleGoback = () => {
    history.goBack();
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const isAllBiomarkersSelected = fields.length === allBiomarkers.length;

  if (!patient.id) return <Redirect push to={CommonRoutes.DASHBOARD} />;

  return (
    <LayoutWithBackOptions
      backOptions={{
        text: analysisName === undefined ? t('newAnalysis') : analysisName,
        backFunction: handleGoback,
      }}
      additionalNode={{
        node: (
          <div className={styles.header__buttons}>
            <ButtonDefault
              text={t('assignAnalysis')}
              variant="primary"
              type="submit"
              onClick={handleAssignCustomAnalysis}
              customStyles={{ marginRight: 24, minWidth: isMobile ? 'none' : 220, whiteSpace: 'nowrap' }}
              containerClassNames={styles.button}
              isLoading={isSetCustomCheckupLoading}
              isDisabled={isAssignDisable}
            />
          </div>
        ),
        position: 'above-title',
      }}>
      <>
        <div className={styles.container}>
          <form>
            <div className={styles.input__analysis_name}>
              <FormLabel text={t('analysisName')} />
              <Controller
                name="analysisName"
                control={control}
                rules={{
                  required: t('required') as string,
                }}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    onChange={onChange}
                    value={value}
                    error={errors.analysisName && 'error'}
                    placeholder={t('inputName') as string}
                  />
                )}
              />
              {errors.analysisName?.message && <FormError type="error" text={errors.analysisName.message} />}
            </div>

            <hr className={styles.separator} />

            <Container fluid className={styles.biomarkers__container}>
              <Row className={classNames('body', 'body_medium', styles.biomarkers__header)}>
                <Col
                  md={6}
                  sm={5}
                  xs={5}
                  style={{ padding: '0', color: 'var(--dark-grey)', display: 'flex', alignItems: 'center' }}>
                  {t('biomarker')}
                </Col>
                <Col
                  md={3}
                  sm={4}
                  xs={3}
                  style={{
                    padding: '0',
                    textAlign: 'center',
                    color: 'var(--dark-grey)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {t('internationalDesignation')}
                </Col>
                <Col
                  md={3}
                  sm={4}
                  xs={3}
                  style={{
                    padding: '0',
                    textAlign: 'center',
                    color: 'var(--dark-grey)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {t('units')}
                </Col>
              </Row>

              {fields.map((field, index) => (
                <FormBiomarkerItem key={field.key} index={index} control={control} field={field} remove={remove} />
              ))}

              {isSearchInputVisible && (
                <SearchBiomarkerInput
                  biomarkers={allBiomarkers.filter((biomarker) => {
                    const field = fields.find((bioField) => biomarker.id === bioField.id);
                    return biomarker.id !== field?.id;
                  })}
                  onChange={onChangeSearchInput}
                />
              )}
            </Container>
            {!isAllBiomarkersSelected && (
              <button type="button" onClick={() => setSearchInputVisible(true)} className={styles.add__biomarker}>
                <div className={classNames(styles.add__biomarker__icon)}>
                  <PlusIcon fill={'var(--background-secondary-dark)'} />
                </div>
                <div className={classNames(styles.add__biomarker__text)}>{t('addBiomarker')}</div>
              </button>
            )}
          </form>
        </div>
      </>
    </LayoutWithBackOptions>
  );
};
