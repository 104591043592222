import * as React from 'react';
import useWithViewbox from '../../hooks/useWithViewbox';

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#8E8E8E' } = props;

  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);
  return (
    <svg ref={ref} width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.857 11.634c1.324 0 2.47-.475 3.406-1.411.937-.937 1.411-2.082 1.411-3.406 0-1.323-.474-2.47-1.41-3.406C14.325 2.475 13.18 2 11.856 2c-1.323 0-2.47.475-3.406 1.411-.936.937-1.411 2.082-1.411 3.406 0 1.324.475 2.47 1.411 3.406.937.936 2.083 1.411 3.406 1.411zM20.286 17.379c-.027-.39-.081-.815-.162-1.264a9.964 9.964 0 00-.31-1.27 6.284 6.284 0 00-.523-1.185 4.474 4.474 0 00-.788-1.027 3.473 3.473 0 00-1.131-.71 3.91 3.91 0 00-1.444-.262c-.205 0-.402.084-.783.332-.235.153-.51.33-.816.526-.262.167-.617.323-1.055.465a4.19 4.19 0 01-1.29.208c-.43 0-.864-.07-1.292-.208-.438-.142-.793-.298-1.054-.465-.304-.194-.578-.371-.817-.526-.38-.248-.578-.332-.782-.332-.52 0-1.006.088-1.444.261a3.47 3.47 0 00-1.132.711 4.47 4.47 0 00-.787 1.027c-.218.382-.393.78-.523 1.185-.125.39-.23.818-.31 1.27-.08.449-.135.874-.162 1.264-.027.383-.04.78-.04 1.181 0 1.044.332 1.89.986 2.513.646.615 1.502.927 2.542.927h9.63c1.04 0 1.895-.312 2.541-.927.655-.623.987-1.468.987-2.513 0-.403-.014-.8-.04-1.181z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgUser;
