import { PatientResDto } from 'api/generated';
import { CloseIcon } from 'assets/svg';
import classNames from 'classnames';
import { CooperationStateEnum } from 'components/BaseTable/components/Cell/Cell';
import { acceptRequestForCooperation, declineRequestForCooperation, sortPatientsAsync } from 'features/expert/thunks';
import { useClientSize } from 'hooks';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { DEFAULT_AVATAR } from 'types/user';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type PatientCardProps = {
  patient: PatientResDto;
};

export const PatientCard: FC<PatientCardProps> = ({ patient }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardExpert.patientCard' });

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const linkToPatient = () => {
    isMobile
      ? history.push(`/patient-settings/${patient.id}`, patient.id)
      : history.push(`/patient-profile/${patient.id}`, patient.id);
  };

  const [isAcceptLoading, setAcceptLoading] = useState(false);
  const [isDeclineLoading, setDeclineLoading] = useState(false);

  const onAcceptRequestClick = () => {
    setAcceptLoading(true);
    dispatch(acceptRequestForCooperation(patient.id))
      .unwrap()
      .then(() => notify('success', t('requestAccept')))
      .then(() =>
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: need pagination
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
          }),
        ),
      )
      .catch((error) => {
        const errorText = error.message || t('error');
        notify('error', errorText);
      })
      .finally(() => setAcceptLoading(false));
  };

  const onDeclineRequestClick = () => {
    setDeclineLoading(true);
    dispatch(declineRequestForCooperation(patient.id))
      .unwrap()
      .then(() => notify('success', t('requestDenied')))
      .then(() =>
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: need pagination
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
          }),
        ),
      )
      .catch((error) => {
        const errorText = error.message || t('error');
        notify('error', errorText);
      })
      .finally(() => setDeclineLoading(false));
  };

  const signUpStatusState =
    patient.signUpStatus === 'sent' ? CooperationStateEnum.RequestFromExpert : CooperationStateEnum.Cooperation;

  const cooperationState = patient.isNewRequest ? CooperationStateEnum.RequestFromPatient : signUpStatusState;

  const cooperationStateMapping = {
    [CooperationStateEnum.Cooperation]: (
      <>
        <p className={classNames(styles.subhead, styles['patient-card__grey-text'])}>{t('startCooperaion')}</p>
        <div className={classNames('flexbox', 'align-center')}>
          <div className={styles.cell__dot} />
          <p>{moment(patient.cooperatedWithExpertAt).format('DD.MM.YYYY')}</p>
        </div>
      </>
    ),
    [CooperationStateEnum.RequestFromPatient]: (
      <>
        <div className={styles.requests__buttons}>
          <ButtonDefault
            variant="secondary"
            onClick={onDeclineRequestClick}
            containerClassNames={styles.decline__button}
            isLoading={isDeclineLoading}>
            <CloseIcon />
          </ButtonDefault>
          <ButtonDefault
            text={t('accept')}
            variant="secondary"
            onClick={onAcceptRequestClick}
            customStyles={{ minWidth: 'unset', marginRight: '12px', maxWidth: '100%' }}
            isLoading={isAcceptLoading}
          />
        </div>
      </>
    ),
    [CooperationStateEnum.RequestFromExpert]: (
      <>
        <p className={classNames(styles.subhead, styles['patient-card__grey-text'])}>{t('startCooperaion')}</p>
        <div className={classNames('flexbox', 'align-center')}>
          <div className={styles.cell__dot_grey} />
          <p className={styles.text_grey}>{t('requestSent')}</p>
        </div>
      </>
    ),
  };

  const renderCooperationState = (state: CooperationStateEnum) => {
    return cooperationStateMapping[state];
  };

  return (
    <div className={styles['patient-card']}>
      <div className={styles['patient-card__content']}>
        <div className={styles['patient-card__content__item']}>
          <button className={styles['patient-card__link']} onClick={linkToPatient}>
            <div className={styles['patient-card__user']}>
              <div
                className={styles['patient-card__user-icon']}
                style={{
                  background: `center / cover no-repeat url(${patient.avatar || DEFAULT_AVATAR})`,
                }}
              />

              <div className={styles['patient-card__user-name']}>
                <p>{patient.name}</p>
                <p className={classNames(styles.subhead, styles['patient-card__date-text'])}>
                  {moment(patient.birthday).format('DD.MM.YYYY')}
                </p>
              </div>
            </div>
          </button>
          <div className={classNames('flexbox', 'direction-column', styles['patient-card__column'])}>
            <p className={classNames(styles.subhead, styles['patient-card__grey-text'])}>{t('phoneNumber')}</p>
            <p>{patient.phone}</p>
          </div>
        </div>
        <div className={styles['patient-card__content__item']}>
          <div className={classNames('flexbox', 'direction-column', styles['patient-card__column'])}>
            <p className={classNames(styles.subhead, styles['patient-card__grey-text'])}>Email</p>
            <p>{patient.email}</p>
          </div>
          <div className={classNames('flexbox', 'direction-column')}>{renderCooperationState(cooperationState)}</div>
        </div>
      </div>
    </div>
  );
};
