import { PatientGenderEnum, UnitsOptionDto } from 'api/generated';
import { CloseIconCircle } from 'assets/svg';
import classNames from 'classnames';
import { FormBiomarkerItemField } from 'features/biomarkers/types';
import numbro from 'numbro';
import { FC, memo, useEffect, useMemo } from 'react';
import { Col, Hidden, Row, useScreenClass } from 'react-grid-system';
import { Control, Controller, UseFormTrigger, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useAppSelector } from 'store/reducers';
import { RootState } from 'store/types';
import { DropdownDefault, FormError, NumberMaskedInput } from 'UIcomponents';
import { OptionProps } from 'UIcomponents/DropdownDefault';
import { getNormFields } from 'utils/patient';

import { FormValues } from '../../AnalysisForm';
import styles from '../styles.module.scss';

type FormBiomarkerItemProps = {
  index: number;
  field: FormBiomarkerItemField;
  err: any;
  remove: (index?: number | number[] | undefined) => void;
  control: Control<FormValues>;
  gender: PatientGenderEnum;
  trigger: UseFormTrigger<FormValues>;
  deleteBtn?: boolean;
};

const FormBiomarkerItem: FC<FormBiomarkerItemProps> = ({
  index,
  field,
  err,
  remove,
  control,
  gender,
  trigger,
  deleteBtn = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'createAnalysis' });

  const screenClass = useScreenClass();
  const unitsOptions = useAppSelector((rootState: RootState) => rootState.biomarkers.unitsCoefficients);

  const biomarkerUnitOptions = useMemo(() => {
    const biomarkerUnit = unitsOptions.find((item) => item.biomarkers.includes(field.name)) as UnitsOptionDto;
    return biomarkerUnit?.compareUnits.reduce<Array<OptionProps<number>>>((array, current, id) => {
      array.push({
        label: current,
        value: id,
        coeffValue: biomarkerUnit.coeffs[id],
        isUseNumberMoles: biomarkerUnit.useNumberMoles[id],
      });
      return array;
    }, []);
  }, [field]);

  const coeffWatch: { label: string; value: number; coeffValue: number; isUseNumberMoles: boolean } = useWatch({
    name: `coeff:${field.id}`,
    control,
  });

  const norms = useMemo(() => {
    return {
      lowerNorm: field[getNormFields(gender).lowerNormField],
      upperNorm: field[getNormFields(gender).upperNormField],
    };
  }, [field, gender]);

  const calculatedFields = useMemo(() => {
    const isConvertValues = field?.unit?.name !== coeffWatch?.label && coeffWatch?.isUseNumberMoles;
    const coeffValue = coeffWatch?.coeffValue || 1;
    return {
      lowerNorm: +numbro(
        isConvertValues ? (norms.lowerNorm / coeffValue) * field.numberMoles : norms.lowerNorm * coeffValue,
      ).format({ trimMantissa: false, mantissa: 4 }),
      upperNorm: +numbro(
        isConvertValues ? (norms.upperNorm / coeffValue) * field.numberMoles : norms.upperNorm * coeffValue,
      ).format({ trimMantissa: false, mantissa: 4 }),
      lowerInvalid: +numbro(
        isConvertValues
          ? (field.lowerNormInvalidValue / coeffValue) * field.numberMoles
          : field.lowerNormInvalidValue * coeffValue,
      ).format({
        trimMantissa: false,
        mantissa: 4,
      }),
      upperInvalid: +numbro(
        isConvertValues
          ? (field.upperNormInvalidValue / coeffValue) * field.numberMoles
          : field.upperNormInvalidValue * coeffValue,
      ).format({
        trimMantissa: false,
        mantissa: 4,
      }),
    };
  }, [
    coeffWatch?.coeffValue,
    coeffWatch?.isUseNumberMoles,
    coeffWatch?.label,
    field.lowerNormInvalidValue,
    field.numberMoles,
    field?.unit?.name,
    field.upperNormInvalidValue,
    norms.lowerNorm,
    norms.upperNorm,
  ]);

  useEffect(() => {
    if (
      field?.value &&
      (field?.value > calculatedFields.upperInvalid || field?.value < calculatedFields.lowerInvalid)
    ) {
      trigger();
    }
  }, [coeffWatch?.coeffValue]);

  return (
    <>
      <Row
        className={classNames('body', 'body_medium', styles['biomarkers-item'])}
        style={{ minHeight: ['sm', 'xs'].includes(screenClass) ? '0' : '52px' }}>
        <Col
          md={4}
          sm={5}
          xs={5}
          className={classNames('flexbox', 'align-start')}
          style={{
            margin: 'auto',
            paddingLeft: 0,
            paddingRight: ['sm', 'xs'].includes(screenClass) ? '12px' : '32px',
          }}>
          <div
            data-tip={`${field.label} (${field.name})`}
            className={classNames('flexbox', 'justify-between', styles['biomarkers-item__title'])}>
            <div className={classNames(styles['biomarkers-item__title-text'])}>{`${field.label}`}</div>
            {deleteBtn && (
              <button
                type="button"
                onClick={() => remove(index)}
                className={classNames(styles['biomarkers-item__delete-btn'])}>
                {<CloseIconCircle fill="var(--red)" />}
              </button>
            )}
          </div>
        </Col>
        <Col
          md={2.5}
          sm={4}
          xs={3}
          style={{ paddingLeft: 0, paddingRight: ['sm', 'xs'].includes(screenClass) ? '16px' : '32px' }}>
          <div className={classNames(styles['biomarkers-item__input'])}>
            <Controller
              rules={{
                min: { value: calculatedFields.lowerInvalid, message: t('invalidValue') },
                max: { value: calculatedFields.upperInvalid, message: t('invalidValue') },
              }}
              name={`biomarkers.${index}.value`}
              control={control}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <NumberMaskedInput
                      allowNegative={false}
                      placeholder={calculatedFields.lowerNorm.toString()}
                      onChange={onChange}
                      value={value}
                      error={
                        (err?.value && 'error') ||
                        ((value || value === 0) &&
                          (value < calculatedFields.lowerNorm || value > calculatedFields.upperNorm) &&
                          'warning')
                      }
                    />
                    {value !== null &&
                    value !== undefined &&
                    err?.value &&
                    (value < calculatedFields.lowerNorm || value > calculatedFields.upperNorm) ? (
                      <FormError
                        hideResponsive={true}
                        type={'error'}
                        text={err?.value?.message}
                        position={'absolute'}
                      />
                    ) : (
                      value !== null &&
                      value !== undefined &&
                      !err?.value &&
                      (value < calculatedFields.lowerNorm || value > calculatedFields.upperNorm) && (
                        <FormError
                          hideResponsive={true}
                          type={'warning'}
                          text={t('outsideNormal')}
                          position={'absolute'}
                        />
                      )
                    )}
                  </>
                );
              }}
            />
          </div>
        </Col>
        <Col md={2} sm={0} xs={0} className={classNames('flexbox', 'align-center', styles['biomarkers-item__norm'])}>
          <Hidden sm xs>
            {calculatedFields.lowerNorm > 0.1
              ? `${Number(calculatedFields.lowerNorm.toFixed(2))} - ${Number(calculatedFields.upperNorm.toFixed(2))}`
              : `${Number(calculatedFields.lowerNorm.toFixed(4))} - ${Number(calculatedFields.upperNorm.toFixed(4))}`}
          </Hidden>
        </Col>
        <Col
          md={3.5}
          sm={3}
          xs={4}
          className={classNames('flexbox', 'align-start', styles['biomarkers-item__unit'])}
          style={{ paddingLeft: 0, paddingRight: ['sm', 'xs'].includes(screenClass) ? '16px' : '32px' }}>
          <Controller
            name={`coeff:${field.id}`}
            defaultValue={biomarkerUnitOptions?.[0]}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownDefault
                options={biomarkerUnitOptions}
                selected={value}
                onChange={(value) => {
                  onChange(value);
                  trigger();
                }}
                isSearchable={false}
                disabled={biomarkerUnitOptions?.length === 1}
                bigHeightMobile
              />
            )}
          />
        </Col>
      </Row>

      <ReactTooltip delayShow={500} place="right" />
    </>
  );
};

export default memo(FormBiomarkerItem);
