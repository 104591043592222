import { Crown, Vector } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { PatientRoutes } from 'types/routes';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './styles.module.scss';

export const SubscribeAnalysisHistoryCard: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const history = useHistory();

  const handleGoToSubscriptions = () => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.PRESS_BUY_SUBSCRIPTION, {
      from: 'analysis',
    });

    history.push(PatientRoutes.SUBSCRIPTIONS);
  };

  return (
    <div
      className={styles['subscribe-card__link']}
      onClick={handleGoToSubscriptions}
      onKeyDown={handleGoToSubscriptions}
      tabIndex={0}
      role="button">
      <div className={styles['subscribe-card__container']}>
        <div className={styles['subscribe-card__icon']}>
          <Crown />
        </div>
        <p className={styles['subscribe-card__content']}>{t('buySubscriptionToAddAnalisys')}</p>
        <div className={styles['subscribe-card__button']}>
          <Vector className={styles['subscribe-card__button-icon']} />
        </div>
      </div>
    </div>
  );
};
