import { configureStore } from '@reduxjs/toolkit';
import { api } from 'api/index';
import logger from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { authMiddleware } from './middlewares';
import extractedReducer from './reducers';

const store = configureStore({
  reducer: extractedReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
      logger,
    }).concat(authMiddleware);
  },
});

const persistor = persistStore(store);

export { store, persistor };
