import { RepliedMessage, Sender } from 'api/generated';
import { ResponseIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import moment from 'moment';
import { FC } from 'react';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { DEFAULT_AVATAR } from 'types/user';

import { CurrentFile } from '../CurrentFile';
import { Response } from '../Response';
import styles from './styles.module.scss';

interface Attachments {
  filename: string;
  URL: string;
  createdAt: string;
}

interface LetterProps {
  id: string;
  createdAt: string;
  updatedAt: string;
  text: string;
  current: boolean;
  attachments: Array<Attachments>;
  repliedMessage: RepliedMessage;
  getCurrentMessage?: (id: string) => void;
  handleCloseResponse: () => void;
  sender: Sender;
}

export const Letter: FC<LetterProps> = ({
  createdAt,
  sender,
  id,
  text,
  current,
  attachments,
  getCurrentMessage,
  repliedMessage,
  handleCloseResponse,
}) => {
  const role = useSelector(selectRole);
  const companion = role !== (sender?.kind as unknown as RolesEnum);

  const avatar = sender.avatar ? sender.avatar : DEFAULT_AVATAR;

  return (
    <div className={classnames(styles['letter'], !companion && styles['letter__right'])}>
      <div className={classnames(styles['letter__content'])}>
        <div className={styles['letter__circle']} style={{ background: `center / cover no-repeat url(${avatar})` }} />

        <div className={classnames(styles['letter__info'])}>
          <div className={classnames('flexbox', 'align-center', styles['letter__container'])}>
            <p className={styles['letter__info-title']}>{sender?.name}</p>
            <p className={styles['letter__info-date']}>{moment(createdAt).format('HH:mm')}</p>
          </div>
          {repliedMessage && <Response {...repliedMessage} hasChat={true} responseDelete={handleCloseResponse} />}
          <p className={classnames(styles['letter__info-subtitle'], styles.subhead)}>
            <Linkify>{text}</Linkify>
          </p>
          {attachments && attachments.map((elem, index: number) => <CurrentFile key={index} {...elem} inChat={true} />)}
        </div>
      </div>
      {!current && (
        <button className={styles['response']} onClick={() => getCurrentMessage && getCurrentMessage(id)}>
          <ResponseIcon />
        </button>
      )}
    </div>
  );
};
