import { HorizontalArrowIcon } from 'assets/svg';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

type ArrowBackProps = {
  pathname: string;
  allowGoBack?: boolean;
  backFunction?: () => void;
};

export const ArrowBack: FC<ArrowBackProps> = ({ pathname, allowGoBack = false, backFunction }) => {
  const history = useHistory();
  return (
    <button
      className={styles['arrow-back']}
      onClick={backFunction ? backFunction : () => (allowGoBack ? history.goBack() : history.push(pathname))}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (backFunction) {
            backFunction();
          } else {
            allowGoBack ? history.goBack() : history.push(pathname);
          }
        }
      }}>
      <HorizontalArrowIcon />
    </button>
  );
};
