import { unwrapResult } from '@reduxjs/toolkit';
import { SendMessageToBiogeekSupportDto } from 'api/generated';
import { BaseModal } from 'components';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { sendContactFormExpertAsync, sendContactFormPatientAsync } from 'features/default/thunks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { ButtonDefault, FormError, FormLabel, InputDefault, TextArea } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

type Props = {
  visible: boolean;
  setModalOpened: () => void;
};

export const ContactForm: React.FC<Props> = ({ visible, setModalOpened }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.contactForm' });

  const dispatch = useAppDispatch();
  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SendMessageToBiogeekSupportDto>({
    defaultValues: {
      email: '',
      text: '',
    },
  });

  const onSubmit = async (data: SendMessageToBiogeekSupportDto): Promise<void> => {
    try {
      const result = isPatient
        ? await dispatch(sendContactFormPatientAsync(data))
        : await dispatch(sendContactFormExpertAsync(data));
      unwrapResult(result);
      notify('success', t('notifySuccess'));
      setModalOpened();
      reset();
    } catch (e: any) {
      notify('error', e.message);
    }
  };
  return (
    <BaseModal isVisible={visible} onClose={setModalOpened} title={t('title') as string}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper}>
          <div>
            <FormLabel text={t('emailLabel')} />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.email,
                  message: t('emailPattern') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={(e) => {
                    const value = e.target.value;
                    const trimValue = value.trim();
                    e.target.value = trimValue;
                    onChange(e);
                  }}
                  value={value}
                  error={errors.email && 'error'}
                />
              )}
            />
            {errors.email?.message && <FormError type="error" text={errors.email.message} />}
          </div>
          <div>
            <FormLabel text={t('infoLabel')} />
            <Controller
              name="text"
              control={control}
              render={({ field: { onChange, value } }) => <TextArea value={value} onChange={onChange} minRows={4} />}
            />
          </div>
          <ButtonDefault text={t('sent')} type="submit" isLoading={isSubmitting} />
        </div>
      </form>
    </BaseModal>
  );
};
