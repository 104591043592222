import * as React from 'react';

const CalendarArrowLeft = () => (
  <svg width={10} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.039 14c.455.446.455 1.17 0 1.616a1.184 1.184 0 0 1-1.65 0l-6.38-6.808a1.126 1.126 0 0 1-.035-1.58L7.39.37A1.184 1.184 0 0 1 9.04.3c.475.427.507 1.15.071 1.615l-5.662 6.05L9.038 14Z"
      fill="#262626"
    />
  </svg>
);

export default CalendarArrowLeft;
