import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgHorizontalArrow(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#262626' } = props;

  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);
  return (
    <svg ref={ref} width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.005 11h10.59l-3.3-3.29a1.004 1.004 0 111.42-1.42l5 5c.091.095.162.207.21.33a1 1 0 010 .76 1 1 0 01-.21.33l-5 5a1.002 1.002 0 01-1.639-.325 1 1 0 01.219-1.095l3.3-3.29H5.005a1 1 0 010-2z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgHorizontalArrow;
