import { Patient } from 'api/generated';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ExpertRoutes } from 'types/routes';

import styles from '../../styles.module.scss';
import { NutritionPlanItem } from '../NutritionPlanItem';
import { TabTitle } from './TabTitle';

type Props = {
  children: ReactElement[];
  patient: Patient;
  id: string;
};

export const TabsWrapper: FC<Props> = ({ children, patient, id }) => {
  const location = useLocation();

  const RouteMap: Record<string, number> = {
    [ExpertRoutes.PATIENT_PROFILE(patient.id)]: 0,
    [ExpertRoutes.PATIENT_RECOMMENDATIONS(patient.id)]: 1,
    [ExpertRoutes.PATIENT_ANAMNESIS(patient.id)]: 2,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY(patient.id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY_NUTRITION(id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY_OTHER(id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY_STRESS(id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY_WORKOUT(id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_DIARY_ENERGY(id)]: 3,
    [ExpertRoutes.PATIENT_NUTRITION_PLAN(patient.id)]: 4,
    [ExpertRoutes.PATIENT_ANALYZES(patient.id)]: 5,
  };

  return (
    <>
      <div className={classNames('flexbox', 'direction-column', styles['patient-profile-tabs'])}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            id={patient.id}
            title={item.props.title}
            index={index}
            isActive={index === RouteMap[location.pathname]}
          />
        ))}
      </div>
      <Switch>
        <Route exact path={ExpertRoutes.PATIENT_PROFILE()}>
          {children[0]}
        </Route>
        <Route path={ExpertRoutes.PATIENT_RECOMMENDATIONS()}>{children[1]}</Route>

        <Route path={ExpertRoutes.PATIENT_ANAMNESIS()}>{children[2]}</Route>
        <Route path={ExpertRoutes.PATIENT_NUTRITION_DIARY()}>{children[3]}</Route>
        <Route path={ExpertRoutes.PATIENT_NUTRITION_PLAN()} exact>
          {children[4]}
        </Route>
        <Route path={ExpertRoutes.PATIENT_NUTRITION_PLAN_ID()} component={NutritionPlanItem} />
        <Route path={ExpertRoutes.PATIENT_ANALYZES()}>{children[5]}</Route>
      </Switch>
    </>
  );
};
