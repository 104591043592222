import { Crown } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { PatientRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents/ButtonDefault';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './styles.module.scss';

export const SubscribeBiometricCard: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const { push } = useHistory();

  const handleSubcribeClick = () => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.PRESS_BUY_SUBSCRIPTION, {
      from: 'main page',
    });

    push(PatientRoutes.SUBSCRIPTIONS);
  };

  return (
    <div className={styles.card__container}>
      <div className={styles.card__content}>
        <Crown className={styles.card__icon} />
        <p className={styles.card__text}>{t('buySubscription')}</p>
      </div>
      <ButtonDefault
        variant="subscribe-secondary"
        customStyles={{ maxHeight: 38, borderRadius: 12, fontSize: 14 }}
        onClick={handleSubcribeClick}>
        {t('buy')}
      </ButtonDefault>
    </div>
  );
};
