import { CloseIconCircle } from 'assets/svg';
import classnames from 'classnames';
import { TableColumType, TableHeader } from 'components';
import { useAlertBeforeUnload } from 'hooks/useAlertBeforeUnload';
import styles from 'layouts/PatientDiaryLayout/styles.module.scss';
import React, { useContext } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonDefault, FormError, InputDefault, TextArea } from 'UIcomponents';

import Context from '../Context';

type Props = {
  isInputsDisabled?: boolean;
};

export const NutritionTab: React.FC<Props> = ({ isInputsDisabled = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary' });

  const isResponsive = useContext(Context);

  const {
    control,
    formState: { errors, isDirty },
  } = useFormContext();
  const { fields, remove, append } = useFieldArray({ control, name: 'nutrition.eatings' });

  useAlertBeforeUnload(isDirty);

  const columns: TableColumType[] = [
    { label: t('meal'), hasSorting: false, aspect: 1 },
    { label: t('timeToMeal'), hasSorting: false, aspect: 1 },
    { label: t('productList'), hasSorting: false, aspect: 2 },
  ];

  const totalAspect = columns.reduce((acc, curr) => acc + curr.aspect, 0);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.table__header}>
          <TableHeader columns={columns} />
        </div>

        <div className={styles.table__body}>
          {fields.map((item, index: number) => (
            <div key={item.id} className={styles.table__row}>
              {isResponsive && (
                <div className={styles.table__row_header}>
                  <p className={classnames('heading')}>{t('meal')}</p>
                  {fields.length !== 1 && !isInputsDisabled && (
                    <button className={styles['table__close-icon']} onClick={() => remove(index)}>
                      <CloseIconCircle fill="var(--dark-grey)" />
                    </button>
                  )}
                </div>
              )}
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[0].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>{t('name')}</p>
                <Controller
                  name={`nutrition.eatings.${index}.name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      value={value}
                      onChange={onChange}
                      placeholder={t('name') as string}
                      error={errors?.[`nutrition.eatings[${index}].name`] && 'error'}
                      disabled={isInputsDisabled}
                    />
                  )}
                />
                {errors?.[`nutrition.eatings[${index}].name`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`nutrition.eatings[${index}].name`]?.message}
                  />
                )}
              </div>
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[1].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>{t('time')}</p>
                <Controller
                  name={`nutrition.eatings.${index}.time`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputDefault
                        value={value}
                        onChange={onChange}
                        placeholder={t('time') as string}
                        error={errors?.[`nutrition.eatings[${index}].time`] && 'error'}
                        disabled={isInputsDisabled}
                      />
                    </>
                  )}
                />
                {errors?.[`nutrition.eatings[${index}].time`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`nutrition.eatings[${index}].time`]?.message}
                  />
                )}
              </div>
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[2].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>
                  {t('productList')}
                </p>
                <Controller
                  name={`nutrition.eatings.${index}.products`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={!isResponsive ? classnames('flexbox', 'align-center') : undefined}>
                      {!isResponsive ? (
                        <InputDefault
                          placeholder={t('product') as string}
                          value={value}
                          onChange={onChange}
                          error={errors?.[`nutrition.eatings[${index}].products`] && 'error'}
                          disabled={isInputsDisabled}
                        />
                      ) : (
                        <TextArea
                          value={value}
                          onChange={onChange}
                          minRows={4}
                          placeholder={t('product') as string}
                          error={errors?.[`nutrition.eatings[${index}].products`] && 'error'}
                          disabled={isInputsDisabled}
                        />
                      )}
                      {fields.length !== 1 && !isResponsive && !isInputsDisabled && (
                        <button className={styles['table__close-icon']} onClick={() => remove(index)}>
                          <CloseIconCircle fill="var(--dark-grey)" />
                        </button>
                      )}
                    </div>
                  )}
                />
                {errors?.[`nutrition.eatings[${index}].products`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`nutrition.eatings[${index}].products`]?.message}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {fields.length < 10 && !isInputsDisabled && (
          <div className={styles.table__button}>
            <ButtonDefault
              text={t('addNew') as string}
              variant="secondary"
              customStyles={{ width: 152 }}
              onClick={() => append({ name: '', time: '', products: '' })}
            />
          </div>
        )}
      </div>

      <div className={styles['text-areas']}>
        <div className={styles['text-area']}>
          <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
            {t('waterRegime')} <span className={styles['text-area__title_grey']}>({t('waterRegimeCaption')})</span>
          </p>
          <Controller
            name="nutrition.waterRegime"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                value={value}
                onChange={onChange}
                minRows={4}
                error={errors?.['nutrition.waterRegime'] && 'error'}
                disabled={isInputsDisabled}
              />
            )}
          />
          {errors?.['nutrition.waterRegime'] && (
            <FormError type="error" position="absolute" text={errors?.['nutrition.waterRegime']?.message} />
          )}
        </div>

        <div className={styles['text-area']}>
          <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
            {t('supplements')}&nbsp;
            <span className={styles['text-area__title_grey']}>({t('supplementsCaption')})</span>
          </p>
          <Controller
            name="nutrition.drugsAndSupplements"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                value={value}
                onChange={onChange}
                minRows={4}
                error={errors?.['nutrition.drugsAndSupplements'] && 'error'}
                disabled={isInputsDisabled}
              />
            )}
          />
          {errors?.['nutrition.drugsAndSupplements'] && (
            <FormError type="error" position="absolute" text={errors?.['nutrition.drugsAndSupplements']?.message} />
          )}
        </div>
      </div>
    </>
  );
};
