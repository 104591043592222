import { selectPatientRecommendations, selectPatientRecommendationsLoadingStatus } from 'features/patient/selectors';
import { selectGetSubscriptionLoadingState, selectSubscription } from 'features/subscriptions/selectors';
import { useUserAccess } from 'hooks/useUserAccess';
import { RecommendationsList } from 'pages';
import { EmptyRecommendationsCard } from 'pages/DashboardPatient/components';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

export const PatientRecommendationsTab: FC = () => {
  const isRecommendationsLoading = useSelector(selectPatientRecommendationsLoadingStatus) === LoadingStatus.pending;
  const isSubscriptionLoading = useAppSelector(selectGetSubscriptionLoadingState) === LoadingStatus.pending;
  const { firstTypeCards, secondTypeCards, thirdTypeCards } = useAppSelector(selectPatientRecommendations);
  const { status: subscriptionStatus } = useAppSelector(selectSubscription);

  const isLoading = isRecommendationsLoading || isSubscriptionLoading;

  const hasRecommendationsList = firstTypeCards.length > 0 || secondTypeCards.length > 0 || thirdTypeCards.length > 0;

  const hasEmptyRecommendationsList =
    !isLoading && firstTypeCards.length === 0 && secondTypeCards.length === 0 && thirdTypeCards.length === 0;

  const hasAccess = useUserAccess();

  const hasRecommendationsAndSubscription = !isLoading && hasRecommendationsList && hasAccess;
  const hasRecommendationWithoutSubscription = !isLoading && hasRecommendationsList && !hasAccess;

  return (
    <>
      <h4 className={styles['user-info-form__title']}>Лабораторные анализы</h4>

      {isLoading ? (
        <div className={styles['user-recommendations-tab__waiting']}>
          <Spinner color="var(--dark-grey)" variant="primary" radius={30} />
        </div>
      ) : (
        <>
          {hasEmptyRecommendationsList && (
            <EmptyRecommendationsCard hasRecomendation={false} hasSubscription={hasAccess} />
          )}

          {hasRecommendationsAndSubscription && (
            <RecommendationsList
              firstTypeCards={firstTypeCards}
              secondTypeCards={secondTypeCards}
              thirdTypeCards={thirdTypeCards}
              showTooltip={false}
              textSize="big"
            />
          )}

          {hasRecommendationWithoutSubscription && (
            <RecommendationsList
              firstTypeCards={firstTypeCards.slice(0, 1)}
              secondTypeCards={[]}
              thirdTypeCards={[]}
              showTooltip={false}
              textSize="big"
            />
          )}
        </>
      )}
    </>
  );
};
