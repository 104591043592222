import { EditIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectEmail } from 'features/expert/selectors';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonDefault, FormLabel, InputDefault } from 'UIcomponents';

import styles from './styles.module.scss';
import { UpdateEmailSteps } from './UpdateEmailSteps';

export const MailAddressSection: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.profileSettingsTab' });

  const width = useWindowWidth();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const activeEmail = useSelector(selectEmail);

  const handleClick = () => {
    setModalOpened(true);
  };
  const handleCloseModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      <h4 className={styles['mail-address-section__title']}>
        {t('email')}
        <span className={styles['mail-address-section__title_mobile']}>{t('password')}</span>
      </h4>
      <h5 className={styles['mail-address-section__mobile-subtitle']}>{t('changeEmail')}</h5>
      <FormLabel text={t('email') as string} />
      <div className={classNames('flexbox', 'justify-start', 'align-center', styles['mail-address-section__form'])}>
        <InputDefault disabled={true} value={activeEmail} customStyles={{ width: width > 760 ? '414px' : '262px' }} />
        <ButtonDefault
          containerClassNames={styles['mail-address-section__button']}
          onClick={handleClick}
          text={width > 580 ? (t('email') as string) : undefined}
          variant="secondary"
          customStyles={{ backgroundColor: 'var(--light-grey)' }}>
          {width < 580 && <EditIcon width={14} height={14} />}
        </ButtonDefault>
        <UpdateEmailSteps modalOpened={modalOpened} onCloseModal={handleCloseModal} />
      </div>
    </>
  );
};
