import classnames from 'classnames';
import { FooterMobile, HeaderDefault } from 'components';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { ReactElement } from 'react';
import { CommonRoutes } from 'types/routes';
import { ArrowBack, LinkBack } from 'UIcomponents';

import styles from './LayoutWithBackOptions.module.scss';

export interface BackOptions {
  text: string;
  url?: string;
  allowGoBack?: boolean;
  backFunction?: () => void;
}

interface LayoutWithBackOptionsProps {
  children: React.ReactNode;
  backOptions: BackOptions;
  additionalNode?: { node: ReactElement; position: 'under-title' | 'above-title' };
}

const LayoutWithBackOptions: React.FC<LayoutWithBackOptionsProps> = ({ children, backOptions, additionalNode }) => {
  const width = useWindowWidth();

  const { url, text, allowGoBack = false, backFunction } = backOptions;
  const urlToRedirect = url ? url : CommonRoutes.DASHBOARD;
  return (
    <div className={styles.root}>
      <HeaderDefault />
      <div className={styles['content-container']}>
        <div
          className={classnames(styles['content-back-options'], {
            [styles[`content-back-options_${additionalNode?.position}`]]: additionalNode !== undefined,
          })}>
          {width <= 760 ? (
            <LinkBack
              pathname={urlToRedirect}
              allowGoBack={allowGoBack}
              backFunction={backFunction}
              customStyles={{ marginBottom: additionalNode?.position === 'above-title' ? 'unset' : '' }}
            />
          ) : (
            <ArrowBack pathname={urlToRedirect} allowGoBack={allowGoBack} backFunction={backFunction} />
          )}
          <h2
            className={classnames(styles['content-back-options__back-text'], {
              [styles[`content-back-options__back-text_${additionalNode?.position}`]]: additionalNode !== undefined,
            })}>
            {text}
          </h2>
          {additionalNode && (
            <div
              className={classnames(styles['content__additional'], {
                [styles[`content__additional_${additionalNode?.position}`]]: additionalNode !== undefined,
              })}>
              {additionalNode.node}
            </div>
          )}
        </div>
        {children}
      </div>
      {width <= 760 && <FooterMobile />}
    </div>
  );
};

export default LayoutWithBackOptions;
