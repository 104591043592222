import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoadingStatus } from 'features/auth/selectors';
import { expertRestorePasswordAsync } from 'features/auth/thunks';
import { patientRestorePasswordAsync } from 'features/patient/thunks';
import { AuthLayout } from 'layouts';
import queryString from 'query-string';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault, FormError, FormLabel, PasswordInput } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface FormValues {
  hash: string;
  newPassword: string;
  newPasswordConfirm: string;
}

type PathnameRole = 'patient' | 'expert' | undefined;

export const RestorePassword: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { hash } = queryString.parse(location.search);
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const role = location.pathname.split('/').pop() as PathnameRole;

  const isPatient = role === 'patient';

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    switch (role) {
      case 'expert': {
        await dispatch(expertRestorePasswordAsync({ ...data, hash: hash as string }))
          .then(unwrapResult)
          .then(() => {
            history.push(CommonRoutes.FORGOT_PASSWORD_CHANGED, { success: true });
          })
          .catch((error) => {
            notify('error', error.message);
          });
        break;
      }
      case 'patient': {
        await dispatch(patientRestorePasswordAsync({ ...data, hash: hash as string }))
          .then(unwrapResult)
          .then(() => {
            history.push(CommonRoutes.FORGOT_PASSWORD_CHANGED, { success: true });
          })
          .catch((error) => {
            notify('error', error.message);
          });
        break;
      }
    }
  };

  if (!hash) {
    return <Redirect to={CommonRoutes.ROOT} />;
  }

  return (
    <AuthLayout title={t('createNewPassword') as string} isPatient={isPatient}>
      <div className={styles['forgot-password-new']}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['forgot-password-new__field']}>
            <FormLabel text={t('inputPassword')} />
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.password,
                  message: t('passwordPattern'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput onChange={onChange} value={value} error={errors.newPasswordConfirm && true} />
              )}
            />
            {errors.newPassword?.message && <FormError type="error" text={errors.newPassword.message} />}
          </div>
          <div className={styles['forgot-password-new__field']}>
            <FormLabel text={t('confirmPassword')} />
            <Controller
              name="newPasswordConfirm"
              control={control}
              rules={{
                required: t('required') as string,
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { newPassword } = getValues();
                    return newPassword === value || (t('matchesPreviousPassword') as string);
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput onChange={onChange} value={value} error={errors.newPasswordConfirm && true} />
              )}
            />

            {errors.newPasswordConfirm?.message && <FormError type="error" text={errors.newPasswordConfirm.message} />}
          </div>
          <ButtonDefault
            text={t('save')}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </form>
      </div>
    </AuthLayout>
  );
};
