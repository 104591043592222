import { unwrapResult } from '@reduxjs/toolkit';
import { GetChatRoomsResDto } from 'api/generated';
import { ArrowLeftIcon, HorizontalArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import { CancelCooperationModal } from 'components';
import { actions } from 'features/chat/slice';
import {
  cancelCooperationRequestWithExpert,
  getOneExpert,
  sendCooperationRequestWithExpert,
} from 'features/patient/thunks';
import { useClientSize } from 'hooks';
import { useToggle } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type HeaderProps = {
  isOpenChatRoom: boolean;
  isPatient: boolean;
  activeChatRoomExpertId: string;
  myExpertId: string | null;
  activeChatExpertName: string | null;
  isOpenExpertInfoAside?: boolean;
  toggleMobileAsideExpertInfo?: () => void;
};

enum CooperationStateEnum {
  hasAttemptCooperation,
  hasSendRequest,
  hasCancelCooperation,
  empty,
}

export const Header: FC<HeaderProps> = ({
  isOpenChatRoom,
  isPatient,
  activeChatRoomExpertId,
  myExpertId,
  activeChatExpertName,
  isOpenExpertInfoAside,
  toggleMobileAsideExpertInfo,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'message' });

  const dispatch = useAppDispatch();

  const [isSentRequestLoading, setIsSentRequestLoading] = useState(false);
  const [isGetExpertInfoLoading, setExpertInfoLoading] = useState(true);

  const { isOpened: isOpenCancelCooperationModal, onToggle: toggleCancelCooperationModal } = useToggle();

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const hasMobileGoBackButton = isMobile && isOpenChatRoom;
  const hasCooperationButton = isOpenChatRoom && isPatient && !isGetExpertInfoLoading && !isOpenExpertInfoAside;
  const isChatWithMyExpert = myExpertId === activeChatRoomExpertId;

  const handleSendRequestClick = async () => {
    setIsSentRequestLoading(true);
    await dispatch(sendCooperationRequestWithExpert(activeChatRoomExpertId))
      .then(() => {
        notify('success', t('requestSent'));
      })
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => setIsSentRequestLoading(false));
    isPatient && getExpertInfo();
  };

  const handleCancelCooperationClick = async () => {
    toggleCancelCooperationModal();
  };

  const [isSentRequest, setSentRequest] = useState<boolean | null>(null);

  const handleCancelRequestClick = async () => {
    setIsSentRequestLoading(true);

    await dispatch(cancelCooperationRequestWithExpert(activeChatRoomExpertId))
      .then(() => setSentRequest(false))
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => setIsSentRequestLoading(false));
  };

  const getExpertInfo = async () => {
    await dispatch(getOneExpert(activeChatRoomExpertId))
      .then(unwrapResult)
      .then((result) => setSentRequest(result.requestInPending))
      .catch((error) => {
        console.warn({ error });
      });
  };

  useEffect(() => {
    isPatient && getExpertInfo().finally(() => setExpertInfoLoading(false));
  }, [activeChatRoomExpertId]);

  const hasAttemptCooperationState =
    myExpertId === null ? CooperationStateEnum.hasAttemptCooperation : CooperationStateEnum.empty;

  const myExpertCooperaionState = isChatWithMyExpert
    ? CooperationStateEnum.hasCancelCooperation
    : hasAttemptCooperationState;

  const cooperationState = isSentRequest ? CooperationStateEnum.hasSendRequest : myExpertCooperaionState;

  const hasCancelCooperationModal = isOpenCancelCooperationModal && myExpertId;

  const buttonCooperationMapping = {
    [CooperationStateEnum.hasAttemptCooperation]: (
      <ButtonDefault
        type="submit"
        text={t('cooperate')}
        variant="primary"
        customStyles={{ maxWidth: 'fit-content' }}
        isLoading={isSentRequestLoading}
        onClick={handleSendRequestClick}>
        {t('cooperate')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.hasSendRequest]: (
      <ButtonDefault
        type="submit"
        text={t('requestSent')}
        variant="message"
        isLoading={isSentRequestLoading}
        onClick={handleCancelRequestClick}
        customStyles={{ maxWidth: 'fit-content' }}>
        {t('requestSent')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.hasCancelCooperation]: (
      <ButtonDefault
        type="submit"
        text={t('cancelCooperation')}
        variant="square"
        customStyles={{
          maxWidth: 'fit-content',
          padding: isMobile ? '12px 16px' : '16px 32px',
          fontSize: isMobile ? '12px' : '16px',
          color: 'var(--secondary-black)',
        }}
        isLoading={false}
        onClick={handleCancelCooperationClick}>
        {t('cancelCooperation')}
      </ButtonDefault>
    ),
    [CooperationStateEnum.empty]: <></>,
  };

  const renderCooperationButton = (state: CooperationStateEnum) => {
    return buttonCooperationMapping[state];
  };

  const handleGoBackClick = () => {
    isOpenExpertInfoAside
      ? toggleMobileAsideExpertInfo && toggleMobileAsideExpertInfo()
      : dispatch(actions.activeChat({} as GetChatRoomsResDto));
  };

  const isDesktopPatientChatRoomOpened = isOpenChatRoom && isPatient && !isMobile;
  const isMobileHeading = isMobile && !isOpenChatRoom;

  return (
    <div className={styles.container}>
      {hasMobileGoBackButton && (
        <button className={classNames(styles.button__back)} onClick={handleGoBackClick}>
          <>
            <ArrowLeftIcon />
            <p className={styles.anchor__back}>{t('goBack')}</p>
          </>
        </button>
      )}
      <div className={styles.container__title}>
        {isDesktopPatientChatRoomOpened && (
          <button
            className={styles.arrow__back}
            onClick={handleGoBackClick}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                handleGoBackClick();
              }
            }}>
            <HorizontalArrowIcon />
          </button>
        )}
        {!isMobile && (
          <h2 className={styles.title}>{isOpenChatRoom && isPatient ? activeChatExpertName : t('messages')}</h2>
        )}
        {isMobileHeading && <h2 className={styles.title}>{t('messages')}</h2>}
      </div>

      {hasCooperationButton && renderCooperationButton(cooperationState)}

      {hasCancelCooperationModal && (
        <CancelCooperationModal
          expertId={myExpertId}
          isOpen={isOpenCancelCooperationModal}
          onClose={toggleCancelCooperationModal}
        />
      )}
    </div>
  );
};
