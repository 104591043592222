import { CSSObject } from '@emotion/serialize';
import classnames from 'classnames';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { GroupBase, MenuPlacement, OptionProps as SelectOptionProps, StylesConfig } from 'react-select';

import { DropdownIndicator } from './components';
import styles from './styles.module.scss';

export type OptionProps<T> = { label: string; value: T; [key: string]: any };

type DropdownDefaultProps<T> = {
  options: Array<OptionProps<T>>;
  selected?: OptionProps<T>;
  placeholder?: string;
  variant?: 'small' | 'max-content';
  required?: boolean;
  disabled?: boolean;
  noOptionsMessage?: string;
  hideSelectedOptions?: boolean;
  closeMenuOnSelect?: boolean;
  isSearchable?: boolean;
  menuPlacement?: MenuPlacement;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  isClearable?: boolean;
  onChange?: (value: OptionProps<T>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  error?: 'error' | 'warning';
  type?: 'dropdown' | 'input' | 'onboarding-input';
  bigHeightMobile?: boolean;
  className?: string;
};

export const DropdownDefault = <T extends unknown>({
  options,
  selected,
  placeholder,
  variant = 'max-content',
  noOptionsMessage = 'Не найдено',
  required = false,
  disabled = false,
  isClearable = false,
  hideSelectedOptions = false,
  closeMenuOnSelect = true,
  isSearchable = true,
  menuPlacement = 'auto',
  minMenuHeight = 140,
  maxMenuHeight = 300,
  onChange,
  onFocus,
  onBlur,
  error,
  type = 'dropdown',
  bigHeightMobile = false,
  className,
}: DropdownDefaultProps<T>): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui' });

  const [isActive, setActive] = useState<boolean>(false);

  const handleChange = useCallback(
    (values) => {
      if (onChange) {
        onChange(values);
      }
    },
    [onChange],
  );

  let inputColor = '';

  if (error === 'error') {
    inputColor = 'var(--red)';
  } else if (error === 'warning') {
    inputColor = 'var(--yellow)';
  } else {
    inputColor = 'var(--light-grey)';
  }

  const fontStyle = {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    letterSpacing: '-0.01em',
  };
  const responsiveFontStyle = { fontSize: 14, fontWeight: 500, lineHeight: '18px', fontFamily: 'Inter' };

  const isCaretVisible = isActive ? (!selected ? true : false) : false;

  const customStyles: Partial<StylesConfig<OptionProps<T>, false, GroupBase<OptionProps<T>>>> = {
    control: (provided: CSSObject) => ({
      ...provided,
      flexWrap: 'nowrap',
      borderRadius: '16px',
      borderColor: inputColor,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: 15,
      height: 52,
      outline: 'none',
      caretColor: isCaretVisible ? 'default' : 'transparent',
      boxShadow: 'none',
      width: variant === 'max-content' ? '100%' : 'inherit',
      background: 'var(--light-grey)',
      '&:hover': {
        borderColor: 'none',
      },
      '&:focus-within': {
        borderColor: 'var(--grey)',
      },
      '@media(max-width: 760px)': {
        height: 46,
        minHeight: bigHeightMobile ? 46 : 42,
        padding: bigHeightMobile ? 12 : 10,
        borderRadius: bigHeightMobile ? 14 : 12,
      },
    }),
    container: (provided: CSSObject) => ({
      ...provided,
      width: variant === 'max-content' ? '100%' : '212px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    input: () => ({
      ...fontStyle,
      color: 'var(--secondary-black)',
      '@media(max-width: 760px)': {
        ...responsiveFontStyle,
      },
    }),
    placeholder: (provided: CSSObject) => ({
      ...provided,
      ...fontStyle,
      display: isActive ? 'none' : 'block',
      marginTop: 17,
      color: error ? inputColor : 'var(--dark-grey)',
      '@media(max-width: 760px)': {
        ...responsiveFontStyle,
      },
    }),
    singleValue: (provided: CSSObject) => ({
      ...provided,
      ...fontStyle,
      color: 'var(--secondary-black)',
      margin: 0,
      marginTop: 17,
      '@media(max-width: 760px)': {
        ...responsiveFontStyle,
      },
    }),
    valueContainer: () => ({
      padding: 0,
      ...fontStyle,
      overflow: 'hidden',
      marginRight: 4,
    }),
    dropdownIndicator: () => ({
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({}),
    menu: (provided: CSSObject) => ({
      ...provided,
      width: '100%',
      borderRadius: '16px',
      background: 'var(--light-grey)',
      overflow: 'hidden',
      marginTop: 4,
      outline: 'none',
      boxShadow: '0px 0px 10px #F1F1F1',
      zIndex: 1000,
    }),
    menuList: (provided: CSSObject) => ({
      ...provided,
      padding: 0,
      background: 'var(--light-grey)',
      margin: 0,
    }),
    option: (provided: CSSObject, state: SelectOptionProps<OptionProps<T>, false, GroupBase<OptionProps<T>>>) => ({
      ...provided,
      ...fontStyle,
      padding: '16px 20px',
      margin: 0,
      backgroundColor: state.isSelected ? 'var(--light-grey)' : 'var(--white)',
      color: 'var(--secondary-black)',
      wordBreak: 'break-word',
      '&:hover': {
        cursor: 'pointer',
        background: 'var(--light-grey)',
      },
      '&:focus': {
        background: 'var(--light-grey)',
      },
      '@media(max-width: 760px)': {
        ...responsiveFontStyle,
        padding: '10px 16px',
      },
    }),
  };
  return (
    <Select
      isClearable={isClearable}
      value={selected}
      className={classnames(styles['dropdown-container'], className)}
      classNamePrefix="dropdown"
      styles={customStyles}
      options={options}
      placeholder={placeholder ? placeholder : t('select')}
      noOptionsMessage={() => noOptionsMessage}
      tabIndex={0}
      isDisabled={disabled}
      hideSelectedOptions={hideSelectedOptions}
      onMenuOpen={() => setActive(true)}
      onMenuClose={() => setActive(false)}
      closeMenuOnSelect={closeMenuOnSelect}
      onChange={(values) => handleChange(values)}
      menuPlacement={menuPlacement}
      onBlur={onBlur}
      onFocus={onFocus}
      defaultValue={selected}
      minMenuHeight={minMenuHeight}
      maxMenuHeight={maxMenuHeight}
      isSearchable={isSearchable}
      menuPosition="absolute"
      components={{
        // eslint-disable-next-line react/display-name
        DropdownIndicator: (props) => {
          return disabled || type === 'input' ? null : <DropdownIndicator error={error} active={isActive} {...props} />;
        },
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'var(--grey)',
        },
      })}
      // required={required}
    />
  );
};
