import { unwrapResult } from '@reduxjs/toolkit';
import { UserIcon } from 'assets/svg';
import Axios from 'axios';
import classNames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { selectExpertAvatarUrl } from 'features/expert/selectors';
import { getExpertAvatarSignedUrlAsync } from 'features/expert/thunks';
import { selectPatientAvatarUrl } from 'features/patient/selectors';
import { getAvatarSignedUrlAsync } from 'features/patient/thunks';
import React, { ChangeEvent, FC, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { MESSAGES } from 'types/messages';
import { ButtonDefault } from 'UIcomponents';
import { v4 } from 'uuid';

import styles from './styles.module.scss';

interface FileTypes {
  files: File;
  id: string;
}

interface DownloadProfilePictureProps {
  role: RolesEnum;
  setValue?: UseFormSetValue<any>;
}

const avatarSelectorMapping: any = {
  [RolesEnum.EXPERT]: selectExpertAvatarUrl,
  [RolesEnum.PATIENT]: selectPatientAvatarUrl,
};

export const DownloadProfilePicture: FC<DownloadProfilePictureProps> = ({ role, setValue }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.expertProfileTab' });

  const [imgUrl, setImgUrl] = useState<string | ArrayBuffer | null>();
  const dispatch = useAppDispatch();
  const avatar = useSelector(avatarSelectorMapping[role]);
  const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0].size < 1048577) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      createSignedUrl({ id: v4(), files: files[0] });
      reader.onloadend = () => {
        setImgUrl(() => reader.result);
      };
      e.target.value = '';
      setIsSelectedPhoto(true);
    } else {
      notify('error', t('avatarMaxSize'));
      e.target.value = '';
    }
  };

  const createSignedUrl = (files: FileTypes) => {
    let url: string;
    switch (role) {
      case RolesEnum.EXPERT: {
        return dispatch(getExpertAvatarSignedUrlAsync({ filename: files.files.name }))
          .then(unwrapResult)
          .then((res) => {
            url = res.signedUrl;
            Axios.put(res.signedUrl, files.files);
            sendUrl(url);
          })
          .catch(() => {
            notify('error', MESSAGES.error);
          });
      }
      case RolesEnum.PATIENT: {
        return dispatch(getAvatarSignedUrlAsync({ filename: files.files.name }))
          .then(unwrapResult)
          .then((res) => {
            url = res.signedUrl;
            Axios.put(res.signedUrl, files.files);
            sendUrl(url);
          })
          .catch(() => {
            notify('error', MESSAGES.error);
          });
      }
    }
  };

  const sendUrl = (url: string) => {
    const newUrl = new URL(url);
    const shortUrl = `${newUrl.origin}${newUrl.pathname}`;
    switch (role) {
      case RolesEnum.EXPERT: {
        setValue && setValue('avatar', shortUrl, { shouldDirty: true });
        break;
      }
      case RolesEnum.PATIENT: {
        setValue && setValue('avatar', shortUrl, { shouldDirty: true });
        break;
      }
    }
  };

  return (
    <div className={classNames('flexbox', 'align-center', 'justify-between', styles['load-picture'])}>
      <div className={classNames('flexbox', 'align-center', styles['load-picture__user-container'])}>
        <p className={classNames(styles['load-picture__title'])}>{t('photoProfile')}</p>
        <div className={classNames('flexbox', 'justify-center', 'align-center', styles['load-picture__user-icon'])}>
          <div className={styles['expert-info-form__container-input']}>
            <input
              type="file"
              className={styles['expert-info-form__input-photo']}
              accept=".jpg,.jpeg,.png"
              onChange={handleChangeFile}
            />

            <button type={'button'} className={styles['avatar-button']}>
              {avatar || isSelectedPhoto ? (
                <div
                  className={classNames(styles['load-picture__img'])}
                  style={{ backgroundImage: `url(${isSelectedPhoto ? (imgUrl as string) : avatar})` }}
                />
              ) : (
                <UserIcon fill={'var(--dark-grey)'} className={classNames(styles['load-picture__svg'])} />
              )}
            </button>
          </div>
        </div>
        <p className={classNames(styles['load-picture__load-title'])}>{t('photoMax')}</p>
      </div>
      <div className={styles['expert-info-form__container-input']}>
        <>
          <input
            type="file"
            className={styles['expert-info-form__input-photo']}
            accept=".jpg,.jpeg,.png"
            onChange={handleChangeFile}
          />
          <ButtonDefault
            text={t('upload')}
            type={'button'}
            variant={'secondary'}
            containerClassNames={styles.mobileButton}
          />
        </>
      </div>
    </div>
  );
};
