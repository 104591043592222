const IFRAME_ID = 'print-iframe';

export const printPdfBlob = (blob: Blob): Promise<void> =>
  new Promise((resolve) => {
    const previousIframe = document.getElementById(IFRAME_ID);
    if (previousIframe) {
      document.body.removeChild(previousIframe);
    }

    const fileUrl = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');

    iframe.setAttribute('id', IFRAME_ID);
    iframe.setAttribute('type', 'application/pdf');
    iframe.setAttribute('style', 'display: none;');
    iframe.setAttribute('src', fileUrl);

    iframe.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      iframe.contentWindow!.focus();

      // For safari https://github.com/mozilla/pdf.js/issues/7597
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        iframe.contentWindow!.print();
        resolve();
      }, 1000);
    };

    document.body.appendChild(iframe);
  });
