import * as React from 'react';

function TelegramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16ZM13.0667 23.3334L13.3389 19.2549L13.3387 19.2548L20.7579 12.5594C21.0836 12.2704 20.6868 12.1295 20.2546 12.3916L11.0982 18.1683L7.14312 16.9339C6.289 16.6723 6.28287 16.0854 7.33488 15.6635L22.7469 9.72066C23.4508 9.40109 24.1302 9.88973 23.8615 10.9671L21.2368 23.3355C21.0535 24.2144 20.5225 24.4246 19.7867 24.0186L15.7885 21.0647L13.8667 22.9334C13.8606 22.9393 13.8546 22.9452 13.8486 22.951C13.6336 23.1603 13.4558 23.3334 13.0667 23.3334Z"
        fill="#646EFA"
      />
    </svg>
  );
}

export default TelegramIcon;
