import { SubscriptionDtoStatusEnum } from 'api/generated/api';
import moment from 'moment';

import { Promocode } from './../api/generated/api';

type SubscriptionActionStates = {
  allowedToUpdate: boolean;
  allowedToCancel: boolean;
  allowedToRenew: boolean;
  allowedToCheckout: boolean;
};

export const getIsSubscriptionActive = (status: SubscriptionDtoStatusEnum): boolean => {
  return (
    status === SubscriptionDtoStatusEnum.Active ||
    status === SubscriptionDtoStatusEnum.CanceledActive ||
    status === SubscriptionDtoStatusEnum.PromocodeActive
  );
};

export const getIsSubscriptionExpired = (status: SubscriptionDtoStatusEnum): boolean => {
  return (
    status === SubscriptionDtoStatusEnum.Expired ||
    status === SubscriptionDtoStatusEnum.PromocodeExpired ||
    status === SubscriptionDtoStatusEnum.CanceledExpired
  );
};

export const getActivePromocode = (usedPromocodes: Promocode[]): Promocode | undefined => {
  return usedPromocodes.find((promocode) => moment(promocode.expirationDate).isAfter(moment(Date.now())));
};

export const getSubscriptionActionStates = (
  status: SubscriptionDtoStatusEnum | null | undefined,
): SubscriptionActionStates => ({
  allowedToUpdate: status === SubscriptionDtoStatusEnum.Active,
  allowedToCancel: status === SubscriptionDtoStatusEnum.Active,
  allowedToRenew: status === SubscriptionDtoStatusEnum.CanceledActive,
  allowedToCheckout: [
    null,
    undefined,
    SubscriptionDtoStatusEnum.Expired,
    SubscriptionDtoStatusEnum.CanceledExpired,
    SubscriptionDtoStatusEnum.PromocodeActive,
    SubscriptionDtoStatusEnum.PromocodeExpired,
    SubscriptionDtoStatusEnum.PastDue,
  ].includes(status),
});
