import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { patientSetNewEmailAsync, patientVerificationCodeCurrentAsync } from 'features/patient/thunks';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { AllowedMasks } from 'types/Input';
import { FormError, FormLabel, MaskedInput, Spinner, Timer } from 'UIcomponents';

import styles from '../../../../styles.module.scss';
import { EmailSendingFormValues } from '../UpdatePatientEmailSteps/UpdatePatientEmailSteps';

interface FormValues {
  verificationCode: string;
}

interface CurrentEmailVerificationFormValues {
  verificationCode: string;
}

interface NewEmailVerificationFormValues {
  email: string;
  verificationCode: string;
}

type VerificationStepProps = {
  type: 'current' | 'new';
  currentEmail?: string;
  newEmail: string;
  nextStep: () => void;
  onCloseModal: () => void;
  setCurrentEmail: (data: EmailSendingFormValues) => void;
  setNewEmail: (data: EmailSendingFormValues) => void;
};

export const VerificationStep: FC<VerificationStepProps> = ({
  type,
  currentEmail = '',
  newEmail,
  onCloseModal,
  setCurrentEmail,
  nextStep,
  setNewEmail,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.patientSettingsTab' });

  const dispatch = useAppDispatch();
  const [isOpenTimer, setIsOpenTimer] = useState(true);

  const toggleTimer = () => {
    setIsOpenTimer(!isOpenTimer);
  };
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<FormValues>();
  const isLoading = false;
  const verificationCodeWatch = watch('verificationCode');

  const onSubmit = (data: CurrentEmailVerificationFormValues | NewEmailVerificationFormValues) => {
    type === 'current'
      ? verifyCurrentEmail(data)
      : verifyNewEmail({ ...data, ...{ email: newEmail } } as NewEmailVerificationFormValues);
  };

  const resetVerificationCode = () => {
    if (type === 'current') {
      setCurrentEmail({ email: currentEmail });
      toggleTimer();
    } else {
      setNewEmail({ email: newEmail });
      toggleTimer();
    }
  };

  const verifyCurrentEmail = (data: CurrentEmailVerificationFormValues) => {
    dispatch(patientVerificationCodeCurrentAsync(data))
      .then(unwrapResult)
      .then(nextStep)
      .catch(() => {
        setError('verificationCode', {
          type: 'server',
          message: t('wrongCode') as string,
        });
      });
  };

  const verifyNewEmail = (data: NewEmailVerificationFormValues) => {
    dispatch(patientSetNewEmailAsync({ email: newEmail, verificationCode: data.verificationCode }))
      .then(unwrapResult)
      .then(() => {
        notify('success', t('notifySuccess'));
        nextStep;
        onCloseModal();
      })
      .catch(() => {
        setError('verificationCode', {
          type: 'server',
          message: t('wrongCode') as string,
        });
      });
  };

  useEffect(() => {
    if (verificationCodeWatch && verificationCodeWatch.length === 4) {
      onSubmit({ verificationCode: verificationCodeWatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCodeWatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['update-email-step']}>
        <div className={classNames('body_medium', styles['update-email-step__text'])}>
          {t('verifEmailCheck')}{' '}
          <span className={styles['update-email-step__text-email']}>
            {type === 'current' ? currentEmail : newEmail}
          </span>
        </div>
        <div className={classNames('subhead_regular', styles['update-email-step__label'])}>
          <FormLabel text={t('verifCode')} />
        </div>

        <Controller
          name="verificationCode"
          control={control}
          rules={{
            required: t('required') as string,
          }}
          render={({ field: { onChange, value } }) =>
            isLoading ? (
              <div className={styles['update-email-step__spinner']}>
                <Spinner variant="secondary" />
              </div>
            ) : (
              <MaskedInput
                mask={AllowedMasks.verificationCode}
                onChange={onChange}
                value={value}
                placeholder={t('verifCode') as string}
              />
            )
          }
        />

        {errors.verificationCode?.message && <FormError type="error" text={errors.verificationCode.message} />}
      </div>
      {isOpenTimer ? (
        <Timer onClose={toggleTimer} />
      ) : (
        <button
          type="button"
          onClick={resetVerificationCode}
          className={classNames('body body_medium', styles['update-email-step__repeat-code-btn'])}>
          {t('resendCode')}
        </button>
      )}
    </form>
  );
};
