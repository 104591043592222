import { Chart } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MappedAnalyzsis } from 'types/analyzes';

import styles from '../../styles.module.scss';
import { EmptyChartMock } from './EmptyChartMock';

export const EmptyChart: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const dataMock: MappedAnalyzsis[] = EmptyChartMock;

  return (
    <div className={styles.indicators}>
      <div className={styles.indicators__header}>
        <h4 className={styles.indicators__title}>{t('indicators')}</h4>
        <div className={styles.indicators__selectors}>
          <p className={`subhead subhead_medium ${styles['indicators-chart-header__norm']}`}>
            {t('indicatorsContent')}
          </p>
        </div>
      </div>
      <div className={styles['indicators__chart']}>
        <div className={styles['indicators-chart-main']}>
          <Chart
            isDemo={true}
            activeIndex={0}
            data={dataMock}
            handleChartBarClick={() => console.log('click')}
            norm={[0, 0]}
            unit=""
            upperDomainValue={100}
          />
        </div>
      </div>
    </div>
  );
};
