import classnames from 'classnames';
import { OnboardingState } from 'features/patientOnboarding/slice';
import { FC, ReactElement } from 'react';
import { Control, DeepMap, FieldError, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonDefault, FormError, FormLabel } from 'UIcomponents';

import styles from './styles.module.scss';

type StepProps = {
  title: string;
  inputLabel: string;
  inputName: string;
  submitButtonText?: string;
  showStepSubtitle?: boolean;
  onSubmit: (data: OnboardingState) => void;
  render: (control: Control, errors: DeepMap<FieldValues, FieldError>) => ReactElement;
};
type TStep = FC<StepProps>;

export const Step: TStep = ({
  title,
  inputLabel,
  render,
  onSubmit,
  inputName,
  submitButtonText,
  showStepSubtitle = true,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'onboarding' });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['onboarding-step']}>
        <h2 className={styles['onboarding-step__title']}>{title}</h2>
        {showStepSubtitle && (
          <div className={classnames('body body_medium', styles['onboarding-step__subtitle'])}>{t('helpConnect')}</div>
        )}
        <div className={classnames('subhead_regular', styles['onboarding-step__input-label'])}>
          {<FormLabel text={inputLabel} />}
        </div>
        <div className={styles['onboarding-step__input']}>
          {render(control, errors)}
          <FormError type="error" text={errors[inputName]?.message} position="initial" />
        </div>
      </div>

      <ButtonDefault
        containerClassNames={classnames(styles['onboarding-step__btn'])}
        text={submitButtonText ? submitButtonText : t('next')}
        type="submit"
      />
    </form>
  );
};
