import * as React from 'react';

function Youtube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#646EFA" />
      <path
        d="M24.6133 11.9361C24.5086 11.5625 24.3046 11.2242 24.0229 10.9573C23.7334 10.6821 23.3784 10.4853 22.9917 10.3853C21.5442 10.0038 15.745 10.0038 15.745 10.0038C13.3274 9.97632 10.9103 10.0973 8.50753 10.3661C8.12076 10.4734 7.7665 10.6746 7.47626 10.9519C7.19108 11.2262 6.98451 11.5646 6.87661 11.9353C6.61739 13.3318 6.49145 14.7497 6.50048 16.1699C6.49123 17.5889 6.61686 19.0063 6.87661 20.4045C6.9822 20.7737 7.18799 21.1105 7.47395 21.3826C7.7599 21.6546 8.11599 21.8512 8.50753 21.9552C9.97429 22.336 15.745 22.336 15.745 22.336C18.1657 22.3635 20.5858 22.2425 22.9917 21.9737C23.3784 21.8738 23.7334 21.677 24.0229 21.4018C24.3045 21.1349 24.5083 20.7966 24.6126 20.423C24.8785 19.0271 25.0079 17.6086 24.9987 16.1876C25.0187 14.7606 24.8896 13.3355 24.6133 11.9353V11.9361ZM13.9013 18.809V13.5316L18.7263 16.1707L13.9013 18.809Z"
        fill="white"
      />
    </svg>
  );
}

export default Youtube;
