import { Analysis } from 'api/generated';
import { selectPatientData } from 'features/patient/selectors';
import plural from 'plural-ru';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Waiting } from 'UIcomponents';

import styles from '../../styles.module.scss';

type AgeCardProps = {
  lastAnalyzes?: Array<Analysis>;
  loading: boolean;
};

export const AgeCard: FC<AgeCardProps> = ({ loading }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const patient = useSelector(selectPatientData);

  const getAge = () => {
    if (patient?.biologicalAge) {
      return Math.floor(patient.biologicalAge as number);
    } else {
      return patient.age;
    }
  };

  const age = getAge();

  return (
    <div className={styles['dashboard-age-card']}>
      {loading ? (
        <Waiting spinnerColor="var(--background-primary)" spinnerRadius={20} />
      ) : (
        <>
          <div className={styles['dashboard-age-card__info']}>
            <p className={styles['dashboard-age-card__title']}>
              {patient?.biologicalAge ? t('biologicalAge') : t('currentAge')}
            </p>
            {age && (
              <h4 className={styles['dashboard-age-card__age']}>
                {age} {plural(age, t('year'), t('years'), t('yearPlural'))}
              </h4>
            )}
          </div>
        </>
      )}
    </div>
  );
};
