export enum AnalyzesNormFilter {
  all = 'all',
  inNormal = 'in-normal',
  notInNormal = 'not-in-normal',
}

export enum SortDirectionFilter {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AnalysisKindLabel {
  'general' = 'Анализ крови',
  'chemistry' = 'Биохимический анализ крови',
  'biological-age' = 'Биологический возраст',
  'custom' = 'Пользовательский анализ',
}

export type MappedAnalyzsis = {
  name: string;
  marker: number;
  analysisId: string;
  isInAnalysis: boolean;
};
