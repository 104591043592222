import { CheckUpCard } from 'components';
import { selectCheckups } from 'features/checkups/selectors';
import { selectActivePatient } from 'features/expert/selectors';
import { LayoutWithBackOptions } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

export type PatientDropdownProps = {
  label: string;
  value: string;
};

export const AssignCheckUp: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const history = useHistory();

  const checkups = useAppSelector(selectCheckups);
  const patient = useAppSelector(selectActivePatient);

  const handleCreateCustomCheckupClick = () => {
    history.push(ExpertRoutes.CREATE_CUSTOM_CHECKUP);
  };

  if (!patient.id) return <Redirect push to={CommonRoutes.DASHBOARD} />;

  return (
    <LayoutWithBackOptions
      backOptions={{ text: t('analyzes'), backFunction: () => history.goBack() }}
      additionalNode={{
        node: (
          <>
            <ButtonDefault
              text={t('createMyCheckup')}
              variant="secondary"
              onClick={handleCreateCustomCheckupClick}
              containerClassNames="flexbox-inline justify-center align-center"
            />
          </>
        ),
        position: 'above-title',
      }}>
      <div className={styles['cards-container']}>
        {checkups.map((checkup) => (
          <CheckUpCard
            key={checkup.id}
            checkupId={checkup.id}
            title={checkup.name}
            link={CommonRoutes.CHECK_UP(checkup.id)}
            img={checkup.avatar}
            isAssignCheckUpFromPatientProfile={true}
          />
        ))}
      </div>
    </LayoutWithBackOptions>
  );
};
