import {
  Analysis,
  AnalysisKindGetResDto,
  BiomarkerGetResDto,
  CreatePatientAnalysisDto,
  UpdatePatientAnalysisBiomarkersDto,
  UpdatePatientAnalysisDto,
} from 'api/generated';
import { PlusIcon } from 'assets/svg';
import classNames from 'classnames';
import { selectLoading } from 'features/analyzes/selectors';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { FormBiomarkerItemField } from 'features/biomarkers/types';
import { selectActivePatient } from 'features/expert/selectors';
import { selectPatientData } from 'features/patient/selectors';
import { useAlertBeforeUnload } from 'hooks/useAlertBeforeUnload';
import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ensure } from 'types';
import { ButtonDefault, DatePickerDropdown, DropdownDefault, FormLabel } from 'UIcomponents';
import { OptionProps } from 'UIcomponents/DropdownDefault';

import { FormBiomarkerItem, SearchBiomarkerInput } from './components';
import styles from './styles.module.scss';

export interface SearchBiomarkerValues {
  value: string;
}

interface AnalysisKindOption {
  label: string;
  value: string | undefined;
  biomarkersIds?: string[];
}

type LocationState = {
  parsedBiomarkers: Array<{ biomarkerId: string; value: number | null }>;
};

export type FormValues = {
  biomarkers: FormBiomarkerItemField[];
  date: Date;
  analysisKindId: string | undefined;
  [key: string]: any;
};

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type Props = {
  analysis?: Analysis;
  onSubmitCreate?: (data: CreatePatientAnalysisDto) => void;
  onSubmitUpdate?: (data: UpdatePatientAnalysisDto) => void;
};

type AnalysisFormProps = RequireAtLeastOne<Props, 'onSubmitCreate' | 'onSubmitUpdate'>;

export const AnalysisForm: React.FC<AnalysisFormProps> = ({ analysis, onSubmitCreate, onSubmitUpdate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'createAnalysis' });

  const analysisKindsEntities = useAppSelector((state) => state.analyzes.analysisKinds);
  const analysisKindsChoices = [
    { label: t('manualInput'), value: undefined },
    ...analysisKindsEntities.map((ak: AnalysisKindGetResDto) => ({
      label: ak.label,
      value: ak.id,
      biomarkersIds: ak.biomarkersIds,
    })),
  ];

  const CUSTOM_ANALYSIS_KIND: AnalysisKindOption = {
    value: undefined,
    label: t('addBiomarkesManual'),
  };

  const initialAnalysisKind =
    analysis && analysis.analysisKindId
      ? ensure<AnalysisKindOption>(analysisKindsChoices.find((ak) => ak.value === analysis.analysisKindId))
      : CUSTOM_ANALYSIS_KIND;

  const [currentAnalysisKind, setCurrentAnalysisKind] = useState<AnalysisKindOption>(initialAnalysisKind);
  const [searchInputVisible, setSearchInputVisible] = useState<boolean>(false);
  const isAnalysisCustom = !currentAnalysisKind.value;
  const allBiomarkers = useSelector(selectBiomarkers);

  const { state } = useLocation<LocationState>();
  const [customBiomarkers, setCustomBiomarkers] = useState<FormBiomarkerItemField[]>([]);
  const screenClass = useScreenClass();

  const patient = useAppSelector(selectPatientData);
  const activePatientData = useSelector(selectActivePatient);
  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;

  const { birthday: patientBirthday, gender: patientGender } = patient;

  const gender = isPatient ? patientGender : activePatientData.gender;

  const loadingStatus = useSelector(selectLoading);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const form = useForm<FormValues>({
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    getValues,
    clearErrors,
  } = form;
  const { fields, remove, replace, append } = useFieldArray({
    control,
    name: 'biomarkers',
    keyName: 'key',
  });

  const onChangeSearchInput = (data: { value: string }) => {
    const newCustomBiomarker = allBiomarkers.find((biomarker) => biomarker.id === data.value);

    if (newCustomBiomarker) {
      append(newCustomBiomarker);
      setCustomBiomarkers((prev) => [...prev, newCustomBiomarker]);
      setSearchInputVisible(false);
    }
  };

  const isAllBiomarkersSelected = fields.length === allBiomarkers.length;

  useAlertBeforeUnload(!!getValues().biomarkers?.length && isDirty);

  const onChangeAnalysisKind = (option: AnalysisKindOption): void => {
    setCurrentAnalysisKind(option);
    clearErrors();

    if (!option.value) {
      replace(customBiomarkers);
      return;
    }

    let biomarkers = allBiomarkers.filter(
      (biomarker: BiomarkerGetResDto) => option.value && biomarker.analysisKindsIds.includes(option.value),
    );

    if (customBiomarkers.length > 0 && !option.value) {
      const customBiomarkersDictionary = keyBy(customBiomarkers, 'id');
      biomarkers = biomarkers.reduce((acc: any[], biomarker) => {
        const customBiomarker = customBiomarkersDictionary[biomarker.id];

        if (!customBiomarker) return acc;
        return [
          ...acc,
          {
            ...biomarker,
            value: customBiomarker.value,
          },
        ];
      }, []);

      replace(biomarkers);
    }
    replace(biomarkers);
  };

  const replaceBiomarkers = (
    analysisBiomarkers: { biomarkerId: string; value: number | null }[],
  ): FormBiomarkerItemField[] => {
    const analysisBiomarkersDictionary = keyBy(analysisBiomarkers, 'biomarkerId');
    const biomarkers = allBiomarkers.reduce((acc: FormBiomarkerItemField[], biomarker) => {
      const analysisBiomarker = analysisBiomarkersDictionary[biomarker.id];
      if (!analysisBiomarker) return acc;

      return [
        ...acc,
        {
          ...biomarker,
          value: analysisBiomarker.value,
        },
      ];
    }, []);
    replace(biomarkers);
    return biomarkers;
  };

  useEffect(() => {
    if (state) {
      const biomarkers = replaceBiomarkers(state.parsedBiomarkers);
      setCustomBiomarkers(biomarkers);
    }

    if (analysis && analysis.patientAnalysisBiomarkers) {
      replaceBiomarkers(
        analysis.patientAnalysisBiomarkers as unknown as { biomarkerId: string; value: number | null }[],
      );
    }
  }, []);

  const onSubmit = (data: FormValues): void => {
    if (analysis && analysis.patientAnalysisBiomarkers && onSubmitUpdate) {
      const updateAnalysisDto: UpdatePatientAnalysisDto = {
        date: data.date.toString(),
        patientAnalysisBiomarkers: [],
      };

      updateAnalysisDto['patientAnalysisBiomarkers'] = data.biomarkers.reduce(
        (acc: UpdatePatientAnalysisBiomarkersDto[], biomarker) => {
          let value = biomarker?.value;
          const coeffOption: OptionProps<number> = getValues()[`coeff:${biomarker.id}`];
          const coeff: number = coeffOption?.coeffValue || 1;

          if (getValues()[`coeff:${biomarker.id}`].label !== biomarker.unit?.name) {
            if (getValues()[`coeff:${biomarker.id}`].isUseNumberMoles && value) {
              value = (value / biomarker.numberMoles) * coeff;
            } else if (value) {
              value = +(value / coeff);
            }
          }

          return [
            ...acc,
            {
              biomarkerId: biomarker.id,
              value: typeof value === 'number' ? +value.toFixed(5) : undefined,
              patientId: isPatient ? patient.id : activePatientData.id,
            },
          ];
        },
        [],
      );

      onSubmitUpdate(updateAnalysisDto);
      return;
    }

    if (!analysis && onSubmitCreate) {
      const createAnalysisDto: CreatePatientAnalysisDto = {
        analysisKindId: data.analysisKindId,
        date: data.date.toString(),
        patientAnalysisBiomarkers: [],
      };

      createAnalysisDto['patientAnalysisBiomarkers'] = data.biomarkers.map((biomarker: FormBiomarkerItemField) => {
        let value = biomarker?.value;
        const coeffOption: OptionProps<number> = getValues()[`coeff:${biomarker.id}`];
        const coeff: number = coeffOption?.coeffValue || 1;

        if (getValues()[`coeff:${biomarker.id}`].label !== biomarker.unit?.name) {
          if (getValues()[`coeff:${biomarker.id}`].isUseNumberMoles && value) {
            value = (value / biomarker.numberMoles) * coeff;
          } else if (value) {
            value = +(value / coeff);
          }
        }

        return {
          biomarkerId: biomarker.id,
          patientId: isPatient ? patient.id : activePatientData.id,
          value: typeof value === 'number' ? +value.toFixed(5) : undefined,
        };
      });

      return onSubmitCreate(createAnalysisDto);
    }
  };

  return (
    <div className={classNames('container', styles['manually-import-container'])}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classNames('flexbox', 'justify-between', styles['manually-import-header'])}>
          <div className={classNames(styles['manually-import-header__input'])}>
            <FormLabel text={t('analysisType')} />
            <Controller
              name="analysisKindId"
              control={control}
              render={({ field: { onChange } }) => (
                <DropdownDefault
                  disabled={!!analysis}
                  isSearchable={false}
                  error={errors.kind && 'error'}
                  onChange={(option) => {
                    onChange(option.value);
                    onChangeAnalysisKind(option);
                  }}
                  selected={currentAnalysisKind}
                  options={[
                    { label: t('manualInput'), value: undefined },
                    ...analysisKindsEntities.map((ak: AnalysisKindGetResDto) => ({
                      label: ak.label,
                      value: ak.id,
                      biomarkersIds: ak.biomarkersIds,
                    })),
                  ]}
                  bigHeightMobile
                />
              )}
            />
          </div>

          <div className={classNames(styles['manually-import-header__input'])}>
            <FormLabel text={t('analysisDate')} />

            <Controller
              name="date"
              control={control}
              defaultValue={analysis ? (analysis?.date as unknown as Date) : new Date()}
              render={({ field: { onChange, value } }) => (
                <DatePickerDropdown
                  icon="calendar"
                  error={errors.date && 'error'}
                  setSelectedDay={onChange}
                  selectedDay={value as unknown as Date}
                  variant="max-content"
                  bigHeightMobile
                  disabledDays={{ before: new Date(patientBirthday as string), after: new Date() }}
                />
              )}
            />
          </div>
        </div>
        <Container fluid className={classNames(styles['manually-import-biomarkers'])}>
          <Row className={classNames('body', 'body_medium', styles['biomarkers__header'])}>
            <Col md={4} sm={5} xs={5} style={{ padding: '0' }}>
              {t('biomarker')}
            </Col>
            <Col md={2.5} sm={4} xs={3} style={{ padding: '0' }}>
              {t('value')}
            </Col>
            <Col md={2} sm={0} xs={0} style={{ padding: '0' }}>
              <Hidden sm xs>
                {t('normalValue')}
              </Hidden>
            </Col>
            <Col md={2} sm={2} xs={2} style={{ padding: '0' }}>
              <Hidden sm xs>
                {t('units')}
              </Hidden>
            </Col>
          </Row>
          {fields.map((field, index) => (
            <FormBiomarkerItem
              key={field.key}
              index={index}
              err={errors.biomarkers?.[index]}
              control={control}
              field={field}
              gender={gender}
              remove={remove}
              trigger={trigger}
              deleteBtn={
                (isAnalysisCustom && !analysis) ||
                (getValues().biomarkers.length !== 1 && !!analysis && isAnalysisCustom)
              }
            />
          ))}

          {isAnalysisCustom && searchInputVisible && (
            <SearchBiomarkerInput
              biomarkers={allBiomarkers.filter((biomarker) => {
                const field = fields.find((bioField) => biomarker.id === bioField.id);
                return biomarker.id !== field?.id;
              })}
              onChange={onChangeSearchInput}
            />
          )}
        </Container>

        {isAnalysisCustom && !searchInputVisible && !isAllBiomarkersSelected && (
          <button
            type="button"
            onClick={() => setSearchInputVisible(true)}
            className={classNames('flexbox', 'align-center', styles['add-button'])}>
            <div className={classNames(styles['add-button__icon'])}>
              <PlusIcon fill={'var(--background-secondary-dark)'} />
            </div>
            <div className={classNames(styles['add-button__text'])}>{t('addNewBiomarker')}</div>
          </button>
        )}
        <ButtonDefault
          isLoading={isLoading}
          isDisabled={(isAnalysisCustom && fields.length === 0) || isLoading || Object.values(errors).length !== 0}
          type="submit"
          text={t('save')}
          customStyles={{ width: ['sm', 'xs'].includes(screenClass) ? '100%' : '244px' }}
        />
      </form>
    </div>
  );
};
