import { useEffect } from 'react';

export const useAlertBeforeUnload = (isHasChanged: boolean) => {
  useEffect(() => {
    const unloadCallback = (event: any) => {
      if (isHasChanged) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [isHasChanged]);
};
