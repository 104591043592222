import { unwrapResult } from '@reduxjs/toolkit';
import { GetPatientNutritonPlanResDto } from 'api/generated';
import { PlanIcon } from 'assets/svg';
import { selectActiveNutrtionPlanStatus, selectPatientId } from 'features/expert/selectors';
import { getNutritionPlansAsync, prescribeNutritionsPlansAsync } from 'features/expert/thunks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { ExpertRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

interface LocationProps {
  patientId: string;
  id: string;
}

const MiniPlan: FC<GetPatientNutritonPlanResDto> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  const history = useHistory();
  const params = useParams<LocationProps>();
  const dispatch = useAppDispatch();
  const patientId = useAppSelector(selectPatientId);
  const activeNutritionPlan = useAppSelector(selectActiveNutrtionPlanStatus);

  const createNutritionPlan = () => {
    dispatch(
      prescribeNutritionsPlansAsync({
        patientId: patientId,
        patientNutritionPlanId: props.id,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getNutritionPlansAsync(patientId)).catch((error) => {
          console.log(error);
        });
        notify('success', t('notifySuccess'));
      })
      .catch((err) => {
        notify('error', err.message);
        console.warn(err);
      });
  };

  const linkToPlan = () => {
    history.push(ExpertRoutes.PATIENT_NUTRITION_PLAN_ID(params.id, props.id), {
      nutritionPlanId: props.id,
      editable: true,
    });
  };
  return (
    <div className={styles['mini-plan']}>
      <div className={styles['mini-plan__info']}>
        <div className={styles['mini-plan__circle']}>
          <PlanIcon />
        </div>
        <h6>{props.title}</h6>
      </div>
      <div className={styles['mini-plan__button-box']}>
        <div className={styles['mini-plan__button']}>
          <ButtonDefault
            customStyles={{ minWidth: '135px' }}
            onClick={createNutritionPlan}
            text={t('appoint')}
            isDisabled={props.isActive}
          />
        </div>
        <div className={styles['mini-plan__button']}>
          <ButtonDefault
            customStyles={{ minWidth: '135px' }}
            text={t('detail')}
            variant={'secondary'}
            onClick={linkToPlan}
          />
        </div>
      </div>
    </div>
  );
};

export default MiniPlan;
