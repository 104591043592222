import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCreatePaymentsDiaryDto } from 'api/generated';
import { BaseModal } from 'components';
import { createPaymentsDiaryAsync, sortPaymentsDiaryAsync } from 'features/expert/thunks';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import {
  ButtonDefault,
  DatePickerDropdown,
  DropdownDefault,
  FormError,
  FormLabel,
  NumberMaskedInput,
} from 'UIcomponents';

import styles from './styles.module.scss';

type CancelSubscriptionModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  openSuccessModal: () => void;
  patients: Array<{ label: string; value: string }>;
};

export const NewPaymentModal: FC<CancelSubscriptionModalProps> = ({ isModalOpened, onCloseModal, patients }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.newPaymentModal' });

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ExpertCreatePaymentsDiaryDto>();

  const onSubmit = async (values: ExpertCreatePaymentsDiaryDto) => {
    if (values) {
      await dispatch(
        createPaymentsDiaryAsync({ amount: values.amount, patientId: values.patientId, date: values.date }),
      )
        .then(() =>
          dispatch(
            sortPaymentsDiaryAsync({
              page: String(1),
              sort: 'date',
              sortDirection: 'DESC',
              limit: '20',
            }),
          ),
        )
        .then(unwrapResult)
        .catch((error) => {
          console.log(error);
          notify('error', error.message);
        })
        .then(onCloseModal)
        .then(() => reset());
    }
  };

  return (
    <BaseModal
      title={t('title') as string}
      isVisible={isModalOpened}
      onClose={() => {
        onCloseModal();
      }}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
        <div className={styles.form__fields}>
          <div className={styles.form__field}>
            <FormLabel text={t('patientLabel')} />
            <Controller
              name="patientId"
              control={control}
              defaultValue={patients[0].value}
              render={({ field: { onChange, value } }) => (
                <DropdownDefault
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  selected={patients.filter((patient) => patient.value === value)[0]}
                  isSearchable={false}
                  options={patients}
                />
              )}
            />
          </div>
          <div className={styles.form__field}>
            <FormLabel text={t('patientDateLabel')} />
            <Controller
              name="date"
              control={control}
              defaultValue={new Date().toISOString()}
              render={({ field: { onChange, value } }) => (
                <DatePickerDropdown
                  selectedDay={value as unknown as Date}
                  setSelectedDay={(value) => onChange(value.toISOString())}
                  variant="max-content"
                  icon="calendar"
                />
              )}
            />
            {errors.date?.message && <FormError type="error" text={errors.date.message} />}
          </div>
          <div className={styles.form__field}>
            <FormLabel text={t('amountLabel')} />
            <Controller
              name="amount"
              control={control}
              rules={{
                required: t('required') as string,
                validate: (value) => {
                  if (value < 1) {
                    return t('validate') as string;
                  }
                },
              }}
              render={({ field: { onChange, value } }) => (
                <NumberMaskedInput
                  onChange={onChange}
                  maxLength={10}
                  value={value}
                  allowNegative={false}
                  error={errors.amount && 'error'}
                />
              )}
            />
            {errors.amount?.message && <FormError type="error" text={errors.amount.message} />}
          </div>
        </div>
        <ButtonDefault type="submit" text={t('save')} containerClassNames={styles['form__button']} />
      </form>
    </BaseModal>
  );
};
