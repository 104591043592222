import * as React from 'react';

function SvgArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={7} height={12} fill="none" {...props}>
      <path
        fill="#8E8E8E"
        d="M.721 1.5a.845.845 0 010-1.212.888.888 0 011.238 0l4.785 5.106a.845.845 0 01.026 1.185l-4.812 5.143a.888.888 0 01-1.237.053.845.845 0 01-.054-1.211l4.247-4.538L.72 1.5z"
      />
    </svg>
  );
}

export default SvgArrowRightIcon;
