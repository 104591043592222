import { AsyncThunkAction, SerializedError, unwrapResult } from '@reduxjs/toolkit';
import {
  Analysis,
  BiomarkerGetResDto,
  FirstTypeCardsPatientGetResDto,
  SecondTypeCardsPatientGetResDto,
  ThirdTypeCardsPatientGetResDto,
} from 'api/generated';
import classnames from 'classnames';
import { Chart } from 'components';
import { selectAllAnalyzes } from 'features/analyzes/selectors';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import { findAllBiomarkersAsync } from 'features/biomarkers/thunks';
import { selectActivePatient } from 'features/expert/selectors';
import {
  getAnalyzesAsync,
  getFirstTypeRecommendationByCardIdAsync,
  GetRecommendationDto,
  getSecondTypeRecommendationByCardIdAsync,
  getThirdTypeRecommendationByCardIdAsync,
} from 'features/expert/thunks';
import { LayoutWithBackOptions } from 'layouts';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { ExtraParamsThunkType } from 'store/types';
import { SortDirectionFilter } from 'types/analyzes';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { DatePickerDropdown, RecommendationScale, Waiting } from 'UIcomponents';

import styles from './styles.module.scss';

type MappedAnalysis = { name: string; marker: number; analysisId: string; date: string };

export type CardType = 'first' | 'second' | 'third';

type LocationState = {
  score: number;
  type: CardType;
  missingBiomarkers: string[];
};

const getRecommendationFetchFunction = (data: GetRecommendationDto, type: CardType) => {
  const recommendationCardByTypeMapping: Record<
    typeof type,
    AsyncThunkAction<
      FirstTypeCardsPatientGetResDto | SecondTypeCardsPatientGetResDto | ThirdTypeCardsPatientGetResDto,
      GetRecommendationDto,
      ExtraParamsThunkType<SerializedError>
    >
  > = {
    ['first']: getFirstTypeRecommendationByCardIdAsync(data),
    ['second']: getSecondTypeRecommendationByCardIdAsync(data),
    ['third']: getThirdTypeRecommendationByCardIdAsync(data),
  };
  return recommendationCardByTypeMapping[type];
};

const DEFAULT_MAX_VALUE = 100;

export const PatientProfileRecommendationCard: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'recommendation' });

  const { state } = useLocation<LocationState>();
  const { id: patientId, recommendationCardId: recommendationCardId } =
    useParams<{ id: string; recommendationCardId: string }>();

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const type = state?.type || 'first';

  const storeAnalyzes = useSelector(selectAllAnalyzes);
  const biomarkers = useSelector(selectBiomarkers);
  const [analyzes, setAnalyzes] = useState<Analysis[]>(storeAnalyzes);

  const [recommendation, setRecommendation] = useState<
    FirstTypeCardsPatientGetResDto | SecondTypeCardsPatientGetResDto | ThirdTypeCardsPatientGetResDto
  >();

  const patient = useAppSelector(selectActivePatient);
  const gender = patient.gender;

  const [norm, setNorm] = useState([0, 0]);
  const [unit, setUnit] = useState('%');
  const [data, setData] = useState<MappedAnalysis[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState(new Date());

  const [maxValue, setMaxValue] = useState(DEFAULT_MAX_VALUE);
  const [activeIndex, setActiveIndex] = useState(analyzes.length - 1);

  const getRecommendation = async (data: GetRecommendationDto) => {
    await dispatch(getRecommendationFetchFunction(data, type))
      .then(unwrapResult)
      .then((result) => setRecommendation(result))
      .catch(() => {
        notify('error', t('recommendationNotFound'));
        push(ExpertRoutes.PATIENT_PROFILE());
      });
  };

  const getAnalyzes = async (startDate?: string, endDate?: string) => {
    await dispatch(
      getAnalyzesAsync({ id: patientId, sortDirection: SortDirectionFilter.ASC, sortBy: 'date', startDate, endDate }),
    )
      .then(unwrapResult)
      .then((result) => {
        setAnalyzes(result.data);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const getBiomarkers = async () => {
    await dispatch(findAllBiomarkersAsync())
      .then(unwrapResult)
      .catch((error) => {
        console.warn({ error });
      });
  };

  const mapAnalyzes = (biomarkerId: string) => {
    return analyzes.reduce<MappedAnalysis[]>((array, analysis) => {
      const marker = analysis.patientAnalysisBiomarkers?.find((biomarker) => biomarker.biomarkerId === biomarkerId);
      if (marker?.value) {
        array.push({
          date: analysis.date,
          name: moment(analysis.date).format('D MMM Y'),
          marker: marker?.value || 0,
          analysisId: analysis.id,
        });
      }

      return array;
    }, []);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    getAnalyzes(date.toISOString(), endDate.toISOString());
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    getAnalyzes((startDate as Date).toISOString(), date.toISOString());
  };

  const handleAnalysisBarClick = (barData: Partial<MappedAnalysis>, index: number) => {
    setActiveIndex(index);
    push(CommonRoutes.ANALYSIS(barData.analysisId), { allowGoBack: true });
  };

  const calculateNorm = (biomarker: BiomarkerGetResDto) => {
    return [
      gender === 'male' ? biomarker.lowerNormMale : biomarker.lowerNormFemale,
      gender === 'male' ? biomarker.upperNormMale : biomarker.upperNormFemale,
    ];
  };

  const upperDomainValue = useMemo(() => {
    return Math.ceil(Math.max(maxValue, norm[1]) + (Math.max(maxValue, norm[1]) / DEFAULT_MAX_VALUE) * 20);
  }, [maxValue, norm]);

  useEffect(() => {
    const data: GetRecommendationDto = {
      id: patientId,
      cardId: recommendationCardId,
    };

    getRecommendation(data);
    getAnalyzes();
    getBiomarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (biomarkers && recommendation && type === 'first' && 'biomarkerId' in recommendation) {
      const biomarker = biomarkers.find((biomarker) => biomarker.id === recommendation.biomarkerId);

      setNorm(calculateNorm(biomarker as BiomarkerGetResDto));
      setUnit(biomarker?.unit?.name as string);

      const newChartData = mapAnalyzes(biomarker?.id as string);
      setData(newChartData);
      setMaxValue(Math.max(...newChartData.map((item) => item.marker)));

      if (!startDate && newChartData.length > 0) {
        setStartDate(new Date(newChartData[0].date));
        setEndDate(new Date(newChartData[newChartData.length - 1].date));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyzes, recommendation]);

  return (
    <LayoutWithBackOptions backOptions={{ text: recommendation?.label || '', allowGoBack: true }}>
      {recommendation ? (
        <div className={styles.recommendation}>
          <article>
            <section className={styles.recommendation__important}>
              <h4 className={styles['recommendation__important-title']}>{t('recommendationTitle')}</h4>
              <p className={styles['recommendation__important-text']}>{t('recommendationText')}</p>
            </section>
            {state?.missingBiomarkers && state?.missingBiomarkers.length > 0 && (
              <section id="missing-iomarkers" style={{ marginTop: 40 }} className={styles.recommendation__important}>
                <p className={classnames('headline')} style={{ marginBottom: 10 }}>
                  {t('attention')}
                </p>
                <p className={styles['recommendation__important-text']}>{t('additionalBiomarkers')} </p>

                <ul style={{ paddingLeft: 20 }}>
                  {state.missingBiomarkers.map((biomarker, id) => (
                    <li key={id} style={{ listStyleType: 'disc' }}>
                      {biomarker}
                    </li>
                  ))}
                </ul>
              </section>
            )}

            {state?.score && (
              <section id="scale" style={{ marginTop: 40 }} className={styles.recommendation__scale}>
                <p className={classnames('headline', styles['recommendation__scale-text'])}>{t('riskScale')}</p>
                <RecommendationScale score={state.score} customStyles={{ maxWidth: 300 }} />
              </section>
            )}

            <section id="content" style={{ marginTop: 24 }}>
              <div
                className={styles.recommendation__content}
                dangerouslySetInnerHTML={{ __html: recommendation?.content }}></div>
            </section>
          </article>
          {type === 'first' && (
            <div className={styles['chart-wrapper']}>
              <h4>{t('indicator')}</h4>

              <div className={styles.chart__selectors}>
                <div className={styles['chart__date-selectors']}>
                  <p className={`body body_medium ${styles['chart__range-title']}`}>{t('range')}:</p>
                  <div className={styles['chart__date-range']}>
                    <DatePickerDropdown
                      selectedDay={startDate as Date}
                      setSelectedDay={handleStartDateChange}
                      leftCalendarPosition
                    />
                    <span className={`body body_bold ${styles['chart__range-divider']}`}>&mdash;</span>
                    <DatePickerDropdown selectedDay={endDate} setSelectedDay={handleEndDateChange} />
                  </div>
                </div>
              </div>
              <div className={styles['chart']}>
                <div className={styles['chart-header']}>
                  <p className={`subhead subhead_medium ${styles['chart-header__norm']}`}>
                    {t('normForYou')} {norm[0]}-{norm[1]} {unit}
                  </p>
                  <div className={styles['chart-header__norm-zone']}>
                    <div className={styles['chart-header__norm-square']}></div>
                    <p className={`subhead subhead_medium ${styles['chart-header__norm-value']}`}>{t('normZone')}</p>
                  </div>
                </div>
                <div className={styles['chart-main']}>
                  <Chart
                    activeIndex={activeIndex}
                    data={data}
                    handleChartBarClick={handleAnalysisBarClick}
                    norm={norm}
                    unit={unit}
                    upperDomainValue={upperDomainValue}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Waiting
          spinnerColor="var(--dark-grey)"
          spinnerVariant="primary"
          spinnerRadius={50}
          customStyles={{ marginTop: 150 }}
        />
      )}
    </LayoutWithBackOptions>
  );
};
