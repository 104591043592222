import * as React from 'react';

function PlanIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
      <path
        d="M6.834 2.333h9.284a1.5 1.5 0 01.968.355l5.716 4.835a1.5 1.5 0 01.532 1.145V23.43c0 2.09-.024 2.236-2.167 2.236H6.834c-2.143 0-2.167-.147-2.167-2.236V4.57c0-2.09.024-2.237 2.167-2.237z"
        fill="#FFF1B8"
      />
      <rect x={7} y={12.833} width={10.5} height={2.333} rx={1} fill="#FAB300" />
      <rect x={7} y={17.5} width={5.833} height={2.333} rx={1} fill="#FAB300" />
    </svg>
  );
}

export default PlanIcon;
