import { LoadingStatus } from 'types';

export enum RolesEnum {
  PATIENT = 'PATIENT',
  EXPERT = 'EXPERT',
}

export interface AuthStore {
  token?: string | null;
  loading: LoadingStatus;
  error: string | null | undefined | SerializedError;
  currentRequestId: string | null | undefined;
  email: string | null;
  role: RolesEnum | null;
}

export interface SignInPayload {
  email: string;
  password: string;
}

export interface SignUpPayload {
  email: string;
  password: string;
}

export interface SignInValidationErrors {
  message: string;
  statusCode: number;
}

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  statusCode?: number;
}
