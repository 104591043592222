import { BiomarkerGetResDto } from 'api/generated';
import classNames from 'classnames';
import { FC } from 'react';
import { Col, Hidden, Row, useScreenClass } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { DropdownDefault, NumberMaskedInput } from 'UIcomponents';

import { SearchBiomarkerValues } from '../../AnalysisForm';
import styles from '../styles.module.scss';

export type BiomarkerItemProps = {
  biomarkers: BiomarkerGetResDto[];
  onChange: (data: SearchBiomarkerValues) => void;
};

const SearchBiomarkerInput: FC<BiomarkerItemProps> = ({ biomarkers, onChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'createAnalysis' });

  const screenClass = useScreenClass();

  return (
    <>
      <Row className={classNames('body', 'body_medium', styles['biomarkers-item'])} style={{ minHeight: '52px' }}>
        <Col
          md={4}
          sm={5}
          xs={5}
          className={classNames('flexbox', 'align-start')}
          style={{ paddingLeft: 0, paddingRight: ['sm', 'xs'].includes(screenClass) ? '22px' : '32px' }}>
          <div className={classNames(styles['search-biomarkers-input'])}>
            <DropdownDefault
              type="input"
              placeholder={t('inputName') as string}
              options={biomarkers.map((biomarker) => {
                return { label: `${biomarker.label} (${biomarker.name})`, value: biomarker.id };
              })}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col
          md={2.5}
          sm={4}
          xs={3}
          style={{ paddingLeft: 0, paddingRight: ['sm', 'xs'].includes(screenClass) ? '16px' : '32px' }}>
          <div className={classNames(styles['biomarkers-item__input'])}>
            <NumberMaskedInput placeholder="0" readOnly={true} />
          </div>
        </Col>
        <Col
          md={2}
          sm={0}
          xs={0}
          className={classNames('flexbox', 'align-start', styles['biomarkers-item__norm'])}
          style={{ padding: '15px 0 0' }}>
          <Hidden sm xs>
            -
          </Hidden>
        </Col>
        <Col
          md={3.5}
          sm={3}
          xs={4}
          className={classNames('flexbox', 'align-start', styles['biomarkers-item__unit'])}
          style={{ padding: '15px 0 0' }}>
          -
        </Col>
      </Row>
    </>
  );
};

export default SearchBiomarkerInput;
