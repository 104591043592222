import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { PatientDiaryDayEnum } from '../../api/generated';
import { ExpertStore } from './types';

export const selectExpertStore = (state: RootState): ExpertStore => state.expert;

export const selectExpert = createSelector(selectExpertStore, (expertStore) => expertStore.me);

export const selectExpertId = createSelector(selectExpertStore, (expertStore) => expertStore.me.id);

export const selectExpertDocumentStatus = createSelector(selectExpert, (expert) => expert.educationDocumentStatus);
export const selectLoading = createSelector(selectExpertStore, (expertStore) => expertStore.loading);

export const selectAnamnes = createSelector(selectExpertStore, (expertStore) => expertStore.anamnes);

export const selectEmail = createSelector(selectExpertStore, (expertStore) => expertStore.me.email);

export const selectCalendar = createSelector(selectExpertStore, (expertStore) => expertStore.calendar);

export const selectLoadingStatus = createSelector(selectExpertStore, (expertStore) => expertStore.getExpertStatus);

export const selectPatientLoadingStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.getExpertPatientStatus,
);

export const selectPatientId = createSelector(selectExpertStore, (expertStore) => expertStore.activePatient.id);

export const selectLoadingChange = createSelector(selectExpertStore, (expertStore) => expertStore.changeExpertStatus);

export const selectPayments = createSelector(selectExpertStore, (expertStore) => expertStore.payments);

export const selectExpertPatients = createSelector(selectExpertStore, (expertStore) => expertStore.patients);

export const selectExpertPatientsLoadingStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.getExpertPatients,
);

export const selectOnboarding = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.me.isOnboardingCompleted,
);

export const selectFilesStatus = createSelector(selectExpertStore, (expertStore) => expertStore.loadingFilesStatus);

export const selectCalendarEvents = createSelector(selectExpertStore, (expertStore) => expertStore.calendar.events);

export const selectCalendarEventsStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingCalendarStatus,
);

export const selectCalendarDeleteEventsStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingDeleteEventStatus,
);

export const selectEventsStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingCalendarEventStatus,
);

export const selectCalendarView = createSelector(selectExpertStore, (expertStore) => expertStore.calendar.view);

export const selectGetPatientDiaryStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.getPatientDiaryStatus,
);

export const selectPatientDiaryByDay = (day: PatientDiaryDayEnum) =>
  createSelector(selectExpertStore, (expertStore) => expertStore.patientDiary.find((page) => page.day === day));

export const selectChats = createSelector(selectExpertStore, (expertStore) => expertStore.chat);

export const selectActiveChat = createSelector(selectExpertStore, (expertStore) => expertStore.activeChat);

export const selectMessages = createSelector(selectExpertStore, (expertStore) => expertStore.messages);

export const selectLoadingMessage = createSelector(selectExpertStore, (expertStore) => expertStore.loadingSendMessage);

export const selectLoadingGetMessage = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingGetMessages,
);

export const selectAnalysesStatus = createSelector(selectExpertStore, (expertStore) => expertStore.analyzesStatus);

export const selectExpertAvatarUrl = createSelector(selectExpertStore, (expertStore) => expertStore.me.avatar);

export const selectNutritionsPlans = createSelector(selectExpertStore, (expertStore) => expertStore.expertPlans);

export const selectNutritionPlanStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.expertPlanStatus,
);

export const selectActiveNutrtionPlanStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.activeExpertPlan,
);

export const selectActivePatient = createSelector(selectExpertStore, (expertStore) => expertStore.activePatient);

export const selectFilters = createSelector(selectExpertStore, (expertStore) => expertStore.filters);

export const selectEducationDocuments = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.me.educationDocuments,
);
export const selectLoadingPaymentsStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingPaymentsStatus,
);

export const selectLoadingAnamnesStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingAnamnesStatus,
);

export const selectLoadingPatientSortStatus = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.loadingPatientSortStatus,
);

export const selectPatientById = (id: string) =>
  createSelector(selectExpertStore, (expertStore) => expertStore.patients.data.find((patient) => patient.id === id));

export const selectIsSearchingPatientsOnDashboard = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.isSearchingPatientsOnDashboard,
);

export const selectIsFilterOnlyNewPatientsOnDashboard = createSelector(
  selectExpertStore,
  (expertStore) => expertStore.isFilterOnlyNewPatientsOnDashboard,
);

export const selectExpertChatRoomsStatus = createSelector(selectExpertStore, (state) => state.getExpertChatRoomsStatus);

export const selectSetCheckupForPatientStatus = createSelector(
  selectExpertStore,
  (state) => state.setCheckupForPatientStatus,
);

export const selectSetCustomCheckupForPatientStatus = createSelector(
  selectExpertStore,
  (state) => state.setCustomCheckupForPatientStatus,
);

export const selectActivePatientRecommendations = createSelector(
  selectExpertStore,
  (state) => state.activePatientRecommendations,
);

export const selectGetAllRecommendationsForPatientStatus = createSelector(
  selectExpertStore,
  (state) => state.getAllRecommendationsForPatientStatus,
);

export const selectAssignedCheckupsForPatients = createSelector(
  selectExpertStore,
  (state) => state.assignedCheckupsForPatients,
);

export const selectAssignedCheckupsForPatientsStatus = createSelector(
  selectExpertStore,
  (state) => state.assignedCheckupsForPatientsStatus,
);

export const selectExpertSettings = createSelector(selectExpertStore, (state) => state.expertSettings);
