import * as React from 'react';

function SvgPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#35B185' } = props;

  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.167 5.167v-5h1.666v5h5v1.667h-5v5H5.166v-5h-5V5.167h5z" fill={fill} />
    </svg>
  );
}

export default SvgPlusIcon;
