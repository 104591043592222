import { GetChatRoomsResDto } from 'api/generated';
import { LoupeIcon, PlusIcon } from 'assets/svg';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectExpertChatRoomsStatus } from 'features/expert/selectors';
import { getChatAsync } from 'features/expert/thunks';
import { getChatsPatientAsync } from 'features/patient/thunks';
import { useDebouncedSearch } from 'hooks/useDebouncedCallback';
import { useToggle } from 'hooks/useToggle';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { MESSAGES } from 'types/messages';
import { InputDefault, Spinner } from 'UIcomponents';

import { NewMessageModal } from '../NewMessageModal';
import { ItemList } from './ItemList';
import styles from './styles.module.scss';

type FormValues = {
  body: string;
};

type ListMessagesProps = {
  chats: Array<GetChatRoomsResDto>;
  patients?: Array<{ label: string; value: string }> | null;
  onSearch: () => void;
};

export const ListMessages: FC<ListMessagesProps> = ({ chats, patients, onSearch }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'message' });

  const dispatch = useAppDispatch();

  const isChatLoading = useAppSelector(selectExpertChatRoomsStatus) === LoadingStatus.pending;
  const role = useAppSelector(selectRole);

  const { isOpened, onToggle } = useToggle();
  const { control, handleSubmit } = useForm<FormValues>();

  const isExpert = role === RolesEnum.EXPERT;

  const getChatRooms = (s: string): void => {
    isExpert
      ? dispatch(getChatAsync({ searchQuery: s })).catch(() => notify('error', MESSAGES.error))
      : dispatch(getChatsPatientAsync({ searchQuery: s })).catch((e) => {
          console.log(e);
        });
  };

  const getChatRoomsDebounced = useDebouncedSearch(getChatRooms, 600);

  const onSubmit = (data: FormValues) => {
    getChatRooms(data.body);
  };

  return (
    <div className={styles['list-message']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['form__container']}>
          <div className={styles['form']}>
            <Controller
              name="body"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  customStyles={{ paddingLeft: '44px' }}
                  onChange={(event) => {
                    onChange(event.target.value);
                    getChatRoomsDebounced.current(event.target.value);
                    onSearch();
                  }}
                  value={value}
                  placeholder={
                    isExpert ? (t('placeholderFindPatient') as string) : (t('placeholderFindExpert') as string)
                  }
                />
              )}
            />
            <button type="submit" className={styles.form__submit}>
              <LoupeIcon />
            </button>
            {isExpert && (
              <button onClick={onToggle} type={'button'} className={styles['form__add-chat']}>
                <PlusIcon fill="var(--dark-grey)" />
              </button>
            )}
          </div>
        </div>
      </form>
      <div className={styles['list-message__list']}>
        {isChatLoading ? (
          <Spinner radius={15} color="var(--background-primary)" variant="primary" />
        ) : (
          chats.map((chat) => <ItemList key={chat.id} chat={chat} />)
        )}
      </div>
      {isOpened && patients && <NewMessageModal patients={patients} onClose={onToggle} />}
    </div>
  );
};
