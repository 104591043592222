import useWithViewbox from 'hooks/useWithViewbox';
import * as React from 'react';

function SvgEditIcon(props: React.SVGProps<SVGSVGElement>) {
  const ref = React.useRef<SVGSVGElement>(null);

  useWithViewbox(ref);

  return (
    <svg ref={ref} width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.351 5.343L3.347 16.359a.44.44 0 00-.115.201l-1.22 4.901a.436.436 0 00.42.539c.036 0 .071-.004.106-.013l4.895-1.221a.432.432 0 00.201-.114L18.64 9.635 14.35 5.343zM21.366 3.84l-1.225-1.226c-.819-.82-2.246-.818-3.063 0l-1.5 1.502 4.288 4.293 1.5-1.502c.409-.409.634-.954.634-1.533 0-.58-.225-1.124-.634-1.533z"
        fill={props.fill ? props.fill : '#262626'}
      />
    </svg>
  );
}

export default SvgEditIcon;
