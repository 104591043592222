import { unwrapResult } from '@reduxjs/toolkit';
import {
  ExpertEducationDocumentStatusEnum,
  GetCustomerPortalUrlDtoPaymentPlatformEnum,
  SubscriptionDtoPaymentPlatformEnum,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
} from 'api/generated';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectExpert } from 'features/expert/selectors';
import { selectActivePromocode } from 'features/promocodes/selectors';
import {
  selectRenewSubscriptionLoadingStatus,
  selectSubscription,
  selectSubscriptionsConstants,
} from 'features/subscriptions/selectors';
import { getCustomerPortalLinkAsync, renewSubscriptionAsync } from 'features/subscriptions/thunks';
import { useToggle } from 'hooks';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { MESSAGES } from 'types/messages';
import { ExpertRoutes, PatientRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { getSubscriptionActionStates } from 'utils/subscriptions';

import { CancelSubscriptionModal } from './components';
import styles from './styles.module.scss';

export const UserSubscriptionTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.userSubscriptionTab' });

  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    isOpened: isCancelSubscriptionModalOpened,
    onCloseToggle: closeCancelSubscriptionModal,
    onToggle: toggleCancelSubscriptionModal,
  } = useToggle();

  const role = useAppSelector(selectRole);
  const subscriptionsConstants = useAppSelector(selectSubscriptionsConstants);
  const expert = useAppSelector(selectExpert);
  const subscription = useAppSelector(selectSubscription);
  const activePromocode = useAppSelector(selectActivePromocode);
  const subscriptionRenewingLoading = useAppSelector(selectRenewSubscriptionLoadingStatus) === LoadingStatus.pending;

  const hasSubscription = Object.keys(subscription).length !== 0;
  const hasActivePromocode = !!activePromocode;
  const { status, subscriptionPlan, paymentPlatform } = subscription;

  const getSubscriptionDuration = (subscription: SubscriptionDtoSubscriptionPlanEnum): string | undefined => {
    if (
      subscription === SubscriptionDtoSubscriptionPlanEnum.LowMonth ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.MediumMonth ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.HighMonth
    ) {
      return t('monthSubscription') as string;
    }
    if (
      subscription === SubscriptionDtoSubscriptionPlanEnum.LowYear ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.MediumYear ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.HighYear
    ) {
      return t('yearSubscription') as string;
    }
  };

  const getSubscriptionName = (subscription: SubscriptionDtoSubscriptionPlanEnum): string | undefined => {
    if (subscription === SubscriptionDtoSubscriptionPlanEnum.Month) {
      return t('monthPeriod') as string;
    }
    if (subscription === SubscriptionDtoSubscriptionPlanEnum.Year) {
      return t('yearPeriod') as string;
    }
    if (
      subscription === SubscriptionDtoSubscriptionPlanEnum.LowDay ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.LowMonth ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.LowYear
    ) {
      return t('lowSubscription') as string;
    }
    if (
      subscription === SubscriptionDtoSubscriptionPlanEnum.MediumDay ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.MediumMonth ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.MediumYear
    ) {
      return t('mediumSubscription') as string;
    }
    if (
      subscription === SubscriptionDtoSubscriptionPlanEnum.HighDay ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.HighMonth ||
      subscription === SubscriptionDtoSubscriptionPlanEnum.HighYear
    ) {
      return t('highSubscription') as string;
    }
  };

  const subscriptionPlanName = getSubscriptionName(subscriptionPlan);
  const subscriptionDuration = getSubscriptionDuration(subscriptionPlan);
  const isUsdPrice = paymentPlatform === SubscriptionDtoPaymentPlatformEnum.Stripe;
  const paymentSubscriptionConstant = subscriptionsConstants[subscription.subscriptionPlan];
  const isExpert = role === RolesEnum.EXPERT;
  const hasAccessByPromocode = status === SubscriptionDtoStatusEnum.PromocodeActive;
  const hasCanceledActiveSubscription = status === SubscriptionDtoStatusEnum.CanceledActive;
  const hasCanceledExpiredSubscription = status === SubscriptionDtoStatusEnum.CanceledExpired;
  const { allowedToRenew, allowedToUpdate, allowedToCancel, allowedToCheckout } = getSubscriptionActionStates(status);

  const handleChangeStripeCard = async () => {
    try {
      const res = await dispatch(
        getCustomerPortalLinkAsync({
          returnUrl: isExpert
            ? `${window.location.origin}${ExpertRoutes.SUBSCRIPTION}`
            : `${window.location.origin}${PatientRoutes.USER_SUBSCRIPTIONS}`,
          paymentPlatform: GetCustomerPortalUrlDtoPaymentPlatformEnum.Stripe,
        }),
      ).then(unwrapResult);
      window.location.href = res.redirectUrl;
    } catch (error) {
      notify('error', MESSAGES.error);
    }
  };

  const handleRenewSubscription = async () => {
    await dispatch(renewSubscriptionAsync()).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        notify('success', t('renewSubscription'));
      } else {
        notify('error', t('error'));
      }
    });
  };

  const expertSubscriptionDescriptionText =
    hasSubscription && !hasActivePromocode
      ? subscriptionPlanName
      : hasActivePromocode
      ? t('expertSubscriptionDescriptionText')
      : t('noSubscription'); //INFO: здесь количество килентов у эксперта с доступом по промокоду захардкожено 50ю, потому что на бэке это проморгали и решили просто давать минимальный доступ для эксперта
  const patientSubscriptionDescriptionText =
    hasSubscription && !hasActivePromocode
      ? subscriptionPlanName
      : hasActivePromocode
      ? t('accessByPromocode')
      : t('noSubscription');
  const subscriptionDescriptionText = isExpert ? expertSubscriptionDescriptionText : patientSubscriptionDescriptionText;

  const promocodeText = hasActivePromocode ? `${t('promocode')} ${activePromocode.name}` : '';
  const expertAccessText = hasSubscription && !hasActivePromocode ? subscriptionDuration : promocodeText;
  const patientAccessText = !hasSubscription && promocodeText;
  const accessText = isExpert ? expertAccessText : patientAccessText;

  const hasChangeCard = !hasAccessByPromocode && isUsdPrice && !hasCanceledExpiredSubscription;

  const priceText = isUsdPrice
    ? `${paymentSubscriptionConstant?.price.usd} $`
    : `${paymentSubscriptionConstant?.price.rub} ₽`;
  const priceByPeriodText = paymentSubscriptionConstant?.interval === 'Year' ? t('year') : t('month');

  const nextBillingText = hasCanceledActiveSubscription
    ? `${t('hasCanceledActiveSubscription')} ${moment(subscription.accessUntil).format('DD.MM.YYYY')}. ${t(
        'noAutomaticCharges',
      )}`
    : `${t('nextCharge')} ${moment(subscription.accessUntil).format('DD.MM.YYYY')}`;

  const billingText = hasCanceledExpiredSubscription ? t('hasCanceledExpiredSubscription') : nextBillingText;

  const hasExpertChecking = isExpert && expert.educationDocumentStatus === ExpertEducationDocumentStatusEnum.Checking;

  const handleGoToSubscriptions = () => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.PRESS_BUY_SUBSCRIPTION, {
      from: 'profile',
    });

    history.push(isExpert ? ExpertRoutes.SUBSCRIPTIONS : PatientRoutes.SUBSCRIPTIONS);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header_container}>
          <p className={styles.header}>{t('subscription')}</p>
          {hasChangeCard && (
            <button className={styles.change_card} onClick={handleChangeStripeCard}>
              {t('changeCard')}
            </button>
          )}
        </div>
        <div className={styles.content_container}>
          <div className={styles.content}>
            <p className={styles.description_text}>{subscriptionDescriptionText}</p>
            <p className={styles.access_text}>{accessText}</p>
            {hasExpertChecking && <p className={styles.access_text}>{t('hasExpertChecking')}</p>}
          </div>
          <div className={styles.info}>
            {hasSubscription && !hasActivePromocode ? (
              <>
                <div className={styles.price_container}>
                  <span className={styles.price}>{priceText}</span>
                  <p className={styles.info_text}>{priceByPeriodText}</p>
                </div>
                <p className={styles.info_text}>{billingText}</p>
              </>
            ) : (
              <>
                {hasActivePromocode && (
                  <>
                    <p className={styles.info_text}>
                      {t('accessPromocode')}
                      {moment(activePromocode.expirationDate).format('DD.MM.YYYY')}
                    </p>
                    <p className={styles.info_text}>{t('subscribeAtAnyTime')}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {allowedToRenew && (
            <ButtonDefault
              onClick={handleRenewSubscription}
              text={t('renew')}
              containerClassNames={styles.subscription__button}
              isLoading={subscriptionRenewingLoading}
            />
          )}
          {allowedToUpdate && (
            <ButtonDefault
              onClick={() => history.push(isExpert ? ExpertRoutes.SUBSCRIPTIONS : PatientRoutes.SUBSCRIPTIONS)}
              text={t('change')}
              containerClassNames={styles.subscription__button}
            />
          )}
          {allowedToCancel && (
            <ButtonDefault
              text={t('cancel')}
              containerClassNames={styles.subscription__button}
              variant="secondary"
              onClick={toggleCancelSubscriptionModal}
            />
          )}
          {allowedToCheckout && (
            <ButtonDefault
              onClick={handleGoToSubscriptions}
              text={t('buySubscription')}
              containerClassNames={styles.subscription__button}
              disabled={isExpert ? hasExpertChecking : false}
            />
          )}
        </div>

        <div className={styles.notion}>{t('paymentNotion')}</div>
      </div>

      <CancelSubscriptionModal
        isModalOpened={isCancelSubscriptionModalOpened}
        onCloseModal={closeCancelSubscriptionModal}
      />
    </>
  );
};
