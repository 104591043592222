import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertUpdatePasswordDto } from 'api/generated';
import classNames from 'classnames';
import { DeleteModal } from 'components/DeleteModal';
import { deleteMeAsync, expertChangePasswordAsync } from 'features/expert/thunks';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { MESSAGES } from 'types/messages';
import { ButtonDefault, FormError, FormLabel, PasswordInput } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

export const PasswordSection: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.profileSettingsTab' });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteMe = () => {
    dispatch(deleteMeAsync())
      .then(unwrapResult)
      .then(toggleModal)
      .then(() => {
        dispatch(actions.authActions.signOut());
        notify('success', MESSAGES.account.deleted);
      })
      .catch(() => notify('error', MESSAGES.errorTryLater));
  };

  const onSubmit = (data: ExpertUpdatePasswordDto) => {
    dispatch(expertChangePasswordAsync(data))
      .then(unwrapResult)
      .then((res) => {
        notify('success', String(res));
        reset();
      })
      .catch((e) => {
        setError('currentPassword', { message: e.message });
      });
  };

  return (
    <>
      <h5 className={styles['password-section__title']}>{t('changePassword')}</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classNames(styles['password-section__inputs'])}>
          <div className={classNames(styles['password-section__input'])}>
            <div className={classNames('subhead_regular')}>
              <FormLabel text={t('labelCurrentPassword')} linkText={t('labelForgotPassword') as string} />
            </div>

            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  error={errors.currentPassword && 'error'}
                  customClassName={styles['password-section__custom-input']}
                />
              )}
            />

            {errors.currentPassword?.message && <FormError type="error" text={errors.currentPassword.message} />}
          </div>

          <div className={classNames(styles['password-section__input'])}>
            <div className={classNames('subhead_regular')}>
              <FormLabel text={t('newPassword') as string} />
            </div>

            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.password,
                  message: t('passwordPattern') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  error={(errors.newPassword || errors.newPasswordConfirm?.type === 'matchesPreviousPassword') && true}
                  customClassName={styles['password-section__custom-input']}
                />
              )}
            />

            {errors.newPassword?.message && <FormError type="error" text={errors.newPassword.message} />}
          </div>

          <div className={classNames(styles['password-section__input'])}>
            <div className={classNames('subhead_regular')}>
              <FormLabel text={t('confirmNewPassword') as string} />
            </div>

            <Controller
              name="confirmedNewPassword"
              control={control}
              rules={{
                required: t('required') as string,
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { newPassword } = getValues();
                    return newPassword === value || (t('matchesPreviousPassword') as string);
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  error={errors.confirmedNewPassword && true}
                  customClassName={styles['password-section__custom-input']}
                />
              )}
            />

            {errors.confirmedNewPassword?.message && (
              <FormError type="error" text={errors.confirmedNewPassword.message} />
            )}
          </div>
        </div>
        <div className={classNames('flexbox', 'justify-between', styles['password-section__button-container'])}>
          <ButtonDefault
            containerClassNames={styles['password-section__button']}
            type="submit"
            isDisabled={!formState.isValid}
            text={t('changePasswordAction')}
          />
          <ButtonDefault
            containerClassNames={styles['password-section__button']}
            text={t('deleteAcc')}
            onClick={toggleModal}
            variant="square"
          />
        </div>
        {isModalOpen && (
          <DeleteModal
            onClose={toggleModal}
            onDelete={deleteMe}
            title={t('modalTitleDeleteAcc') as string}
            subtitle={t('modalContentDeleteAcc') as string}
            isVisible={true}
          />
        )}
      </form>
    </>
  );
};
