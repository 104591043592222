import { UserIcon } from 'assets/svg';
import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { FC, useRef, useState } from 'react';

import styles from './styles.module.scss';

type UserAvatarProps = {
  name?: string;
  isIconRight?: boolean;
  avatar?: string;
};

export const UserAvatar: FC<UserAvatarProps> = ({ name = 'Unknown', isIconRight = false, avatar }) => {
  const ref = useRef(null);
  const [mainMenuIsOpen, setMainMenuIsOpen] = useState(false);
  const handleClick = () => {
    setMainMenuIsOpen(!mainMenuIsOpen);
  };
  const handleClickOutside = () => {
    setMainMenuIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={styles.avatar__wrapper}>
      {isIconRight && <div className={classNames(styles['profile-name'])}>{name}</div>}
      {avatar ? (
        <button
          style={{ background: `center / cover no-repeat url(${avatar})` }}
          className={classNames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
          tabIndex={-1}
          onClick={handleClick}></button>
      ) : (
        <button
          style={{ background: `center / cover no-repeat url(${avatar})` }}
          className={classNames('flexbox', 'justify-center', 'align-center', styles['avatar'])}
          tabIndex={-1}
          onClick={handleClick}>
          {<UserIcon fill={'var(--dark-grey)'} />}
        </button>
      )}

      {!isIconRight && <div className={classNames(styles['profile-name'])}>{name}</div>}
    </div>
  );
};
