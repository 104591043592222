import { Plans } from 'pages/PatientProfile/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const PatientNutritionPlansTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  return (
    <div className={styles['nutrition-plan']}>
      <div className={styles['nutrition-plan__header']}>
        <h4>{t('heading')}</h4>
      </div>
      <div className={styles['nutrition-plan__body']}>
        <Plans />
      </div>
    </div>
  );
};
