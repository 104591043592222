import classNames from 'classnames';
import { ContactForm } from 'components';
import termsStyles from 'layouts/TermsLayout/styles.module.scss';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TermsOfService: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq.termsOfService' });

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  return (
    <>
      <h5 className={termsStyles.subhead}>{t('title')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>
        {t('paragraph1')} <a href="https://biogeek.app">biogeek.health</a> {t('paragraph2')}
      </p>
      <h5 className={termsStyles.subhead}>{t('subhead1')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph3')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead2')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph4')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead3')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph5')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead4')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph6')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead5')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph7')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead6')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph8')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead7')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph9')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead8')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph10')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead9')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph11')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead10')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph12')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead11')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph13')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead12')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph14')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead13')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>
        {t('paragraph15')}{' '}
        <button className={termsStyles['text-button']} onClick={() => setModalOpened(true)}>
          {t('contactForm')}
        </button>
        .
      </p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('lastEdit')}</p>
      <ContactForm visible={modalOpened} setModalOpened={() => setModalOpened(false)} />
    </>
  );
};
