import * as React from 'react';

function Verified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.35288 3.54876C9.17705 2.60093 10.3933 2 11.75 2C13.1066 2 14.3228 2.60087 15.147 3.54861C16.4001 3.46106 17.6852 3.89616 18.6446 4.85557C19.604 5.81498 20.0391 7.10009 19.9516 8.35319C20.8992 9.17735 21.5 10.3935 21.5 11.75C21.5 13.1068 20.899 14.3231 19.9511 15.1473C20.0384 16.4002 19.6033 17.685 18.6441 18.6443C17.6848 19.6035 16.4 20.0386 15.1471 19.9513C14.3229 20.8991 13.1067 21.5 11.75 21.5C10.3934 21.5 9.1772 20.8991 8.35304 19.9514C7.09992 20.039 5.8148 19.6039 4.85537 18.6445C3.89596 17.685 3.46086 16.3999 3.54841 15.1468C2.60079 14.3226 2 13.1065 2 11.75C2 10.3934 2.60085 9.17723 3.54855 8.35306C3.4611 7.10005 3.89621 5.81507 4.85554 4.85574C5.81488 3.8964 7.09987 3.46129 8.35288 3.54876ZM15.3603 9.93593C15.6011 9.59887 15.523 9.13046 15.1859 8.8897C14.8489 8.64894 14.3805 8.72701 14.1397 9.06407L10.9043 13.5936L9.28033 11.9697C8.98744 11.6768 8.51256 11.6768 8.21967 11.9697C7.92678 12.2626 7.92678 12.7374 8.21967 13.0303L10.4697 15.2803C10.6256 15.4362 10.8421 15.5156 11.0619 15.4974C11.2816 15.4793 11.4822 15.3653 11.6103 15.1859L15.3603 9.93593Z"
        fill="#327EF0"
      />
    </svg>
  );
}

export default Verified;
