import NutritionPlanIcon from 'assets/svg/NutritionPlan';
import { selectGetNutrionPlanStatus, selectGetNutritionPlan } from 'features/patient/selectors';
import { NutritionPlanItem } from 'pages/PatientProfile/components/NutritionPlanItem';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingStatus } from 'types';
import { Spinner } from 'UIcomponents';

import styles from './styles.module.scss';

export const PatientNutritionPlanTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  const plan = useSelector(selectGetNutritionPlan);
  const planLoadingStatus = useSelector(selectGetNutrionPlanStatus);

  return (
    <>
      {Object.keys(plan).length !== 0 ? (
        <NutritionPlanItem readonly={true} nutritionPlan={plan} />
      ) : (
        <>
          {planLoadingStatus === LoadingStatus.pending ? (
            <div className={styles['user-nutrition-plan-tab__waiting']}>
              <Spinner color="var(--dark-grey)" variant="primary" radius={30} />
            </div>
          ) : (
            <>
              <div className={styles['empty-state__container']}>
                <div className={styles['empty-state']}>
                  <NutritionPlanIcon />
                  <p className={styles['empty-state__title']}>{t('emptyHeading')}</p>
                  <p className={styles['empty-state__subtitle']}>{t('emptySubheading')}</p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
