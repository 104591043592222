import classnames from 'classnames';
import React, { CSSProperties } from 'react';
import Switch from 'react-switch';

import styles from './styles.module.scss';

type Props = {
  id?: string;
  defaultValue: boolean;
  labelOptions?: { checkedText: string; uncheckedText: string; isTogglerRight?: boolean };
  onColor?: string;
  offColor?: string;
  onChange: () => void;
  customStyles?: CSSProperties;
};
export const SwitchButton: React.FC<Props> = ({
  defaultValue,
  labelOptions,
  onColor = '#646EFA',
  offColor = '#DBDBDB',
  customStyles,
  onChange,
  id,
}) => {
  return (
    <div className={styles.switch__wrapper} style={customStyles}>
      {labelOptions && labelOptions.isTogglerRight && (
        <p className={classnames('body', 'body-medium', styles.switch__label)} style={{ marginRight: 14 }}>
          {defaultValue ? labelOptions.checkedText : labelOptions.uncheckedText}
        </p>
      )}
      <Switch
        checked={defaultValue}
        onChange={onChange}
        onColor={onColor}
        offColor={offColor}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow={'0 0 0 0 #3bf'}
        width={38}
        height={24}
        handleDiameter={20}
      />
      {labelOptions && !labelOptions.isTogglerRight && (
        <p className={classnames('body', 'body-medium', styles.switch__label)} style={{ marginLeft: 14 }}>
          {defaultValue ? labelOptions.checkedText : labelOptions.uncheckedText}
        </p>
      )}
    </div>
  );
};
