import * as React from 'react';

function Twitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#646EFA" />
      <path
        d="M24.9995 10.7112C24.3469 11.0004 23.6459 11.1957 22.9089 11.2841C23.6693 10.829 24.2383 10.1129 24.5095 9.26925C23.795 9.69362 23.0131 9.99233 22.1977 10.1524C21.6494 9.56691 20.9231 9.17885 20.1316 9.04845C19.3401 8.91805 18.5277 9.05261 17.8205 9.43123C17.1133 9.80986 16.5509 10.4114 16.2206 11.1424C15.8903 11.8734 15.8106 12.693 15.9938 13.4739C14.5462 13.4012 13.13 13.025 11.8372 12.3695C10.5444 11.7141 9.40379 10.7942 8.48952 9.6694C8.1769 10.2087 7.99715 10.8339 7.99715 11.4998C7.9968 12.0992 8.14442 12.6895 8.4269 13.2182C8.70938 13.7469 9.118 14.1977 9.6165 14.5306C9.03837 14.5122 8.47301 14.356 7.96745 14.0749V14.1218C7.96739 14.9626 8.25821 15.7774 8.79055 16.4281C9.3229 17.0789 10.064 17.5254 10.8881 17.6919C10.3518 17.837 9.78948 17.8584 9.2437 17.7544C9.47621 18.4778 9.92911 19.1104 10.539 19.5636C11.1489 20.0168 11.8852 20.268 12.645 20.2819C11.3553 21.2943 9.76256 21.8435 8.12298 21.8411C7.83254 21.8412 7.54235 21.8242 7.25391 21.7903C8.91817 22.8603 10.8555 23.4282 12.8341 23.426C19.5319 23.426 23.1934 17.8787 23.1934 13.0675C23.1934 12.9112 23.1895 12.7534 23.1824 12.597C23.8946 12.082 24.5094 11.4442 24.9979 10.7135L24.9995 10.7112Z"
        fill="white"
      />
    </svg>
  );
}

export default Twitter;
