import * as React from 'react';

function SvgErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill={props.fill}
      />
      <rect x={10.8} y={6} width={2.4} height={9.6} rx={1.2} fill={props.fill} />
      <rect x={10.8} y={16.8} width={2.4} height={2.4} rx={1.2} fill={props.fill} />
    </svg>
  );
}

export default SvgErrorIcon;
