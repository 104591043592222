import { ArrowIcon, CalendarArrowDownIcon } from 'assets/svg';
import classnames from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';

import styles from '../../styles.module.scss';

export interface CustomDropdownIndicatorProps<T>
  extends PropsWithChildren<
    DropdownIndicatorProps<
      {
        label: string;
        value: T;
        [key: string]: unknown;
      },
      false,
      GroupBase<{
        label: string;
        value: T;
        [key: string]: unknown;
      }>
    >
  > {
  active: boolean;
  icon?: 'calendar' | 'dropdown';
  error?: 'error' | 'warning';
}

const DropdownIndicator = <T extends unknown>({
  active,
  icon = 'dropdown',
  error,
  ...rest
}: CustomDropdownIndicatorProps<T>): ReactElement => {
  return (
    <components.DropdownIndicator {...rest}>
      <div
        className={classnames(styles.dropdown__icon, {
          [styles.dropdown__icon_active]: active,
          [styles[`dropdown__icon_${error}`]]: error,
        })}>
        {icon === 'dropdown' && <ArrowIcon fill="var(--dark-grey)" />}{' '}
        {icon === 'calendar' && <CalendarArrowDownIcon fill="var(--black)" />}
      </div>
    </components.DropdownIndicator>
  );
};

DropdownIndicator.displayName = 'DropdownIndicator';

export default DropdownIndicator;
