import * as React from 'react';

function EmptyMessageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.03C0 9.494 8.42 0 20.04 0 31.4 0 40 9.314 40 19.97 40 32.328 29.92 40 20 40c-3.28 0-6.92-.881-9.84-2.604-1.02-.62-1.88-1.081-2.98-.721l-4.04 1.202c-1.02.32-1.94-.48-1.64-1.563l1.34-4.486c.22-.621.18-1.282-.14-1.803C.98 26.86 0 23.395 0 20.03zm17.4 0a2.584 2.584 0 002.56 2.584c1.42 0 2.56-1.162 2.56-2.564a2.554 2.554 0 00-2.56-2.564c-1.4-.02-2.56 1.142-2.56 2.544zm9.22.02a2.567 2.567 0 002.56 2.564c1.42 0 2.56-1.162 2.56-2.564a2.554 2.554 0 00-2.56-2.564 2.554 2.554 0 00-2.56 2.564zm-15.88 2.564a2.58 2.58 0 01-2.56-2.564 2.554 2.554 0 012.56-2.564c1.42 0 2.56 1.142 2.56 2.564 0 1.402-1.14 2.544-2.56 2.564z"
        fill="#DADADA"
      />
    </svg>
  );
}

export default EmptyMessageIcon;
