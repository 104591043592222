import { Calendar, EmailIcon, HistoryIcon } from 'assets/svg';
import RoundedPlusIcon from 'assets/svg/RoundedPlusIcon';
import classnames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { useToggle } from 'hooks/useToggle';
import { useUserAccess } from 'hooks/useUserAccess';
import { AddPatientForm } from 'pages/DashboardExpert/components/AddPatientForm';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from 'store/types';
import { CommonRoutes, ExpertRoutes } from 'types/routes';

import styles from './styles.module.scss';

export const FooterMobile: FC = () => {
  const { isOpened, onOpenToggle, onCloseToggle } = useToggle();
  const role = useSelector((rootState: RootState) => rootState.auth.role);
  const isPatient = role === RolesEnum.PATIENT;
  const hasAccess = useUserAccess();
  const hasExpertAccess = hasAccess && !isPatient;

  const routes = useHistory();

  const handleClick = () => {
    switch (role) {
      case RolesEnum.EXPERT:
        onOpenToggle();
        break;
      case RolesEnum.PATIENT:
        routes.push(CommonRoutes.NEW_ANALYSIS);
        break;
    }
  };

  const isMessageActive = routes.location.pathname === CommonRoutes.MESSAGE;
  const isCalendarActive = routes.location.pathname === ExpertRoutes.CALENDAR;
  const isAnalyzesActive = routes.location.pathname === CommonRoutes.ANALYZES_HISTORY;

  return (
    <>
      <div className={styles['footer-mobile']}>
        <Link to={CommonRoutes.MESSAGE}>
          <EmailIcon fill={isMessageActive ? 'var(--secondary-black)' : 'var(--dark-grey)'} />
        </Link>

        {isPatient ? (
          <button onClick={handleClick} className={classnames(styles['footer-mobile__button'])}>
            <RoundedPlusIcon />
          </button>
        ) : (
          <button
            onClick={handleClick}
            disabled={!hasExpertAccess}
            className={classnames(styles['footer-mobile__button'], {
              [styles['footer-mobile__button_disabled_expert']]: !hasExpertAccess,
            })}>
            <RoundedPlusIcon />
          </button>
        )}

        {role === RolesEnum.EXPERT ? (
          <Link to={ExpertRoutes.CALENDAR}>
            <Calendar fill={isCalendarActive ? 'var(--secondary-black)' : 'var(--dark-grey)'} />
          </Link>
        ) : (
          <Link to={CommonRoutes.ANALYZES_HISTORY}>
            <HistoryIcon fill={isAnalyzesActive ? 'var(--secondary-black)' : 'var(--dark-grey)'} />
          </Link>
        )}
      </div>
      {isOpened && <AddPatientForm onClose={onCloseToggle} isVisible={isOpened} />}
    </>
  );
};
