import classNames from 'classnames';
import { CSSProperties, FC, useMemo } from 'react';

import styles from './styles.module.scss';

type SpinnerProps = {
  radius?: number;
  color?: string;
  secondaryColor?: string;
  variant?: 'primary' | 'secondary' | 'delete' | 'message' | 'square' | 'subscribe-primary' | 'subscribe-secondary';
};

export const Spinner: FC<SpinnerProps> = ({ radius, color, secondaryColor, variant = 'primary' }) => {
  const inlineCss = useMemo(() => {
    const cssProperties: CSSProperties = {};

    if (radius) {
      cssProperties.width = radius * 2;
      cssProperties.height = radius * 2;
    }

    if (color) {
      cssProperties.borderLeftColor = color;
    }

    if (secondaryColor) {
      cssProperties.borderTopColor = secondaryColor;
      cssProperties.borderRightColor = secondaryColor;
      cssProperties.borderBottomColor = secondaryColor;
    }

    return cssProperties;
  }, [radius, color, secondaryColor]);

  return <div className={classNames(styles.loader, styles['loader-' + variant])} style={inlineCss}></div>;
};
