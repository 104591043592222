import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'types';
import { outOfSideDate } from 'utils/time';

import { completeOnboardingPatientAsync } from './thunks';
import { OnboardingStore } from './types';

const initialState: OnboardingStore = {
  name: '',
  birthDay: String(outOfSideDate(18)),
  weight: null as unknown as number,
  height: null as unknown as number,
  phone: '',
  loading: LoadingStatus.idle,
  error: null,
};

export type OnboardingState = typeof initialState;

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    updateAction: (state, action: PayloadAction<Partial<OnboardingState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {
    [completeOnboardingPatientAsync.pending.type]: (state) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
      }
    },
    [completeOnboardingPatientAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
    },
    [completeOnboardingPatientAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const { reducer, actions } = onboardingSlice;
