import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatsGetPaginatedMessagesResDto, Expert, GetChatRoomsResDto, MessageAttachment } from 'api/generated';

import { LoadingStatus } from '../../types';
import { addMessagesAsync, getAttachmentsAsync, getMessagesAsync, sendMessageAsync } from './thunks';
import { ChatStore } from './types';

const initialState: ChatStore = {
  messages: {} as ChatsGetPaginatedMessagesResDto,
  activeChat: {} as GetChatRoomsResDto,
  expert: {} as Expert,
  attachments: [],
  loadingGetMessages: LoadingStatus.idle,
  loadingSendMessage: LoadingStatus.idle,
  error: null,
};
const chatSlice = createSlice({
  initialState,
  name: 'chat',
  reducers: {
    activeChat: (state, actions) => {
      state.activeChat = actions.payload;
    },
    setExpert: (state, actions) => {
      state.expert = actions.payload;
    },
    addMessage: (state, action) => {
      state?.messages?.data?.push(action.payload);
    },
    changeUnreadMessagesPatient: (state) => {
      state.activeChat.unreadMessagesCount.patient = 0;
    },
    changeUnreadMessagesExpert: (state) => {
      state.activeChat.unreadMessagesCount.expert = 0;
    },
    clearChat: () => {
      return initialState;
    },
  },
  extraReducers: {
    [addMessagesAsync.fulfilled.type]: (state, { payload }: PayloadAction<ChatsGetPaginatedMessagesResDto>) => {
      state.loadingGetMessages = LoadingStatus.fulfilled;
      if (state.messages.nextCursor) {
        state.messages.data.unshift(...payload.data.reverse());
        state.messages.nextCursor = payload.nextCursor;
        state.messages.hasMore = payload.hasMore;
        state.messages.count = payload.count;
      }
    },
    [getMessagesAsync.fulfilled.type]: (state, { payload }: PayloadAction<ChatsGetPaginatedMessagesResDto>) => {
      state.loadingGetMessages = LoadingStatus.fulfilled;
      state.messages.data = payload.data.reverse();
      state.messages.nextCursor = payload.nextCursor;
      state.messages.hasMore = payload.hasMore;
      state.messages.count = payload.count;
      state.messages = payload;
    },
    [getMessagesAsync.pending.type]: (state) => {
      state.loadingGetMessages = LoadingStatus.pending;
    },
    [getMessagesAsync.rejected.type]: (state) => {
      state.loadingGetMessages = LoadingStatus.idle;
    },
    [sendMessageAsync.pending.type]: (state) => {
      state.loadingSendMessage = LoadingStatus.idle;
    },
    [sendMessageAsync.pending.type]: (state) => {
      state.loadingSendMessage = LoadingStatus.pending;
    },
    [sendMessageAsync.fulfilled.type]: (state) => {
      state.loadingSendMessage = LoadingStatus.fulfilled;
    },

    [getAttachmentsAsync.fulfilled.type]: (state, { payload }: PayloadAction<MessageAttachment[]>) => {
      state.attachments = payload;
    },
  },
});
export const actions = {
  ...chatSlice.actions,
};
export const { reducer } = chatSlice;
