import {
  FirstTypeCardsPatientGetResDto,
  SecondTypeCardsPatientGetResDto,
  ThirdTypeCardsPatientGetResDto,
} from 'api/generated';
import { FC } from 'react';
import { DysfunctionRecommendationCard, RecommendationCard } from 'UIcomponents';

import styles from './styles.module.scss';

type RecommendationsListProps = {
  firstTypeCards: Array<FirstTypeCardsPatientGetResDto>;
  secondTypeCards: Array<SecondTypeCardsPatientGetResDto>;
  thirdTypeCards: Array<ThirdTypeCardsPatientGetResDto>;
  showTooltip?: boolean;
  textSize?: 'big' | 'small';
};

export const RecommendationsList: FC<RecommendationsListProps> = ({
  firstTypeCards,
  secondTypeCards,
  thirdTypeCards,
  showTooltip = false,
  textSize = 'small',
}) => {
  return (
    <>
      {secondTypeCards.length > 0 &&
        secondTypeCards.map((secondTypeCard) => (
          <div key={secondTypeCard.secondTypeCardId} className={styles['recommendations-list__card']}>
            <RecommendationCard
              recommendation={secondTypeCard}
              type="blue"
              showTooltip={showTooltip}
              textSize={textSize}
              heightSize="big"
            />
          </div>
        ))}
      {thirdTypeCards.length > 0 &&
        thirdTypeCards.map((thirdTypeCard) => (
          <div key={thirdTypeCard.thirdTypeCardId} className={styles['recommendations-list__card']}>
            <DysfunctionRecommendationCard
              recommendation={thirdTypeCard}
              showTooltip={showTooltip}
              textSize={textSize}
              score={thirdTypeCard.scale}
              heightSize="big"
              missingBiomarkers={thirdTypeCard.missingBiomarkers}
            />
          </div>
        ))}
      {firstTypeCards.length > 0 &&
        firstTypeCards.map((firstTypeCard) => (
          <div key={firstTypeCard.firstTypeCardId} className={styles['recommendations-list__card']}>
            <RecommendationCard
              recommendation={firstTypeCard}
              type="light"
              showTooltip={showTooltip}
              textSize={textSize}
              heightSize="big"
            />
          </div>
        ))}
    </>
  );
};
