import * as React from 'react';

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.015C0.5 4.74712 4.71 0 10.52 0C16.2 0 20.5 4.65699 20.5 9.98498C20.5 16.1642 15.46 20 10.5 20C8.86 20 7.04 19.5593 5.58 18.698C5.07 18.3876 4.64 18.1572 4.09 18.3375L2.07 18.9384C1.56 19.0986 1.1 18.698 1.25 18.1572L1.92 15.9139C2.03 15.6034 2.01 15.2729 1.85 15.0125C0.99 13.4301 0.5 11.6975 0.5 10.015ZM9.2 10.015C9.2 10.7261 9.77 11.2969 10.48 11.307C11.19 11.307 11.76 10.7261 11.76 10.025C11.76 9.31397 11.19 8.74311 10.48 8.74311C9.78 8.7331 9.2 9.31397 9.2 10.015ZM13.81 10.025C13.81 10.7261 14.38 11.307 15.09 11.307C15.8 11.307 16.37 10.7261 16.37 10.025C16.37 9.31397 15.8 8.74311 15.09 8.74311C14.38 8.74311 13.81 9.31397 13.81 10.025ZM5.87 11.307C5.17 11.307 4.59 10.7261 4.59 10.025C4.59 9.31397 5.16 8.74311 5.87 8.74311C6.58 8.74311 7.15 9.31397 7.15 10.025C7.15 10.7261 6.58 11.2969 5.87 11.307Z"
        fill="#404040"
      />
    </svg>
  );
}

export default SvgChat;
