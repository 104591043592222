import classnames from 'classnames';
import { TableColumType, UserAvatar } from 'components';
import { actions } from 'features/expert/slice';
import { deletePaymentsDiaryAsync, togglePaymentsDiaryAsync, toggleSwitch } from 'features/expert/thunks';
import moment from 'moment';
import { FC } from 'react';
import { useAppDispatch } from 'store/reducers';
import { FormLabel, SwitchButton } from 'UIcomponents';
interface ResponsivePaymentItemProps {
  sorting: (sortName: 'name' | 'date' | 'amount' | 'paid', sortDirection: 'ASC' | 'DESC') => void;
  data: { id: string; patientName: string; date: string; amount: number; paid: boolean };
  columns: TableColumType[];
}

import { CloseIcon } from 'assets/svg';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const ResponsivePaymentItem: FC<ResponsivePaymentItemProps> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.paymentsDiaryTab' });

  const { patientName, date, amount, paid, id } = data;
  const dispatch = useAppDispatch();
  const toggle = () => {
    dispatch(togglePaymentsDiaryAsync(id));
    dispatch(toggleSwitch(id));
  };

  const deletePayment = () => {
    dispatch(deletePaymentsDiaryAsync(id));
    dispatch(actions.deletePaymentsDiary(id));
  };

  return (
    <div className={styles['payment-item']}>
      <div className={classnames(styles['payment-item__field'], styles['payment-item__header'], 'mb-2')}>
        <UserAvatar name={patientName} />
        <button className={styles.table__button_delete_mobile} onClick={deletePayment}>
          <CloseIcon />
        </button>
      </div>
      <div className={classnames(styles['payment-item__field'])}>
        <FormLabel text={t('date')} customTextStyles={{ color: 'var(--dark-grey)' }} />
        <p className="body body-medium">{moment(date).format('DD.MM.YYYY')}</p>
      </div>
      <div className={classnames(styles['payment-item__field'])}>
        <FormLabel text={t('sum')} customTextStyles={{ color: 'var(--dark-grey)' }} />
        <p className="body body-medium">{amount} </p>
      </div>

      <div className={classnames(styles['payment-item__field_last'], 'mt-32')}>
        <FormLabel
          text={t('status')}
          customTextStyles={{ color: 'var(--dark-grey)', marginBottom: '0px' }}
          customContainerStyles={{ marginBottom: '0px' }}
        />
        <SwitchButton
          id={id}
          defaultValue={paid}
          onChange={toggle}
          labelOptions={{ checkedText: t('paid'), uncheckedText: t('notPaid') }}
          onColor="#35B185"
        />
      </div>
    </div>
  );
};
