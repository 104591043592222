import { unwrapResult } from '@reduxjs/toolkit';
import {
  PatientCreatePatientDiaryDto,
  PatientDiary,
  PatientDiaryDayEnum,
  PatientUpdatePatientDiaryDto,
} from 'api/generated';
import { selectPatientDiary, selectPatientDiaryPageByDay } from 'features/patient/selectors';
import { clearPatientDiaryAsync, createPatientDiaryAsync, updatePatientDiaryAsync } from 'features/patient/thunks';
import { PatientDiaryLayout } from 'layouts';
import { FormValues, patientDiaryDefaultValues } from 'layouts/PatientDiaryLayout/PatientDiaryLayout';
import { EnergyAndSleepTab, NutritionTab, OtherTab, StressTab, WorkoutTab } from 'layouts/PatientDiaryLayout/tabs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { PatientRoutes } from 'types/routes';
import * as yup from 'yup';

export const UserProfilePatientDiaryTab: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary' });

  const tabs = [
    { route: PatientRoutes.NUTRITION_DIARY_NUTRITION, label: t('tabs.nutrition') },
    { route: PatientRoutes.NUTRITION_DIARY_WORKOUT, label: t('tabs.workout') },
    { route: PatientRoutes.NUTRITION_DIARY_ENERGY, label: t('tabs.energy') },
    { route: PatientRoutes.NUTRITION_DIARY_STRESS, label: t('tabs.stress') },
    { route: PatientRoutes.NUTRITION_DIARY_OTHER, label: t('tabs.other') },
  ];

  const errorRoutesMapping: Record<string, string> = {
    ['nutrition']: PatientRoutes.NUTRITION_DIARY_NUTRITION,
    ['workout']: PatientRoutes.NUTRITION_DIARY_WORKOUT,
    ['energyAndSleep']: PatientRoutes.NUTRITION_DIARY_ENERGY,
    ['stress']: PatientRoutes.NUTRITION_DIARY_STRESS,
  };

  const validationSchema = yup.object({
    nutrition: yup.object({
      waterRegime: yup.string().required(t('required') as string),
      drugsAndSupplements: yup.string().required(t('required') as string),
      eatings: yup.array().of(
        yup.object({
          name: yup.string().required(t('required') as string),
          time: yup.string().required(t('required') as string),
          products: yup.string().required(t('required') as string),
        }),
      ),
    }),
    workout: yup.object({
      trainings: yup.array().of(
        yup.object({
          trainingType: yup.string().required(t('required') as string),
          time: yup.string().required(t('required') as string),
          duration: yup.string().required(t('required') as string),
          commentary: yup.string().required(t('required') as string),
        }),
      ),
      stepsPerDay: yup.string().required(t('required') as string),
    }),
    energyAndSleep: yup.object({
      energyLevel: yup.string().required(t('required') as string),
      sleep: yup.string().required(t('required') as string),
    }),
    stress: yup.object({
      stressSituation: yup.string().required(t('required') as string),
      stressLevel: yup.string().required(t('required') as string),
    }),
  });

  const history = useHistory();

  const resolver = async (data: FormValues) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors) {
      const parsedErrors = errors?.inner?.reduce((allErrors: any, currentError: any) => {
        return {
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? 'validation',
            message: currentError.message,
          },
        };
      }, {});
      const errorRoutesNames: string[] = Array.from(
        new Set(Object.keys(parsedErrors).map((str) => str.replace(/\..*/, ''))),
      );

      history.push(errorRoutesMapping[errorRoutesNames[0]] + history.location.search);
      return {
        values: {},
        errors: parsedErrors,
      };
    }
  };

  const dispatch = useAppDispatch();
  const patientDiary = useAppSelector(selectPatientDiary);

  const form = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver,
  });
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleClearDiaryPages = async (): Promise<void> => {
    if (patientDiary.length === 0 && isDirty) {
      return reset(patientDiaryDefaultValues);
    }

    try {
      const result = await dispatch(clearPatientDiaryAsync());
      const unwrap = unwrapResult(result);
      reset(patientDiaryDefaultValues);
      notify('success', unwrap);
    } catch (error) {
      notify('error', error?.message);
    }
  };

  const onSubmit = async (data: FormValues, diaryPage: PatientDiary, day: PatientDiaryDayEnum): Promise<void> => {
    if (!diaryPage) {
      try {
        const result = await dispatch(
          createPatientDiaryAsync({ diary: data, day } as {
            diary: PatientCreatePatientDiaryDto;
            day: PatientDiaryDayEnum;
          }),
        );
        unwrapResult(result);
        notify('success', t('notifySuccessCreate'));
      } catch (error) {
        notify('error', error?.message);
      }
    } else {
      try {
        const result = await dispatch(
          updatePatientDiaryAsync({ id: diaryPage.id, diary: data } as {
            id: string;
            diary: PatientUpdatePatientDiaryDto;
          }),
        );
        unwrapResult(result);
        notify('success', t('notifySuccessUpdate'));
      } catch (error) {
        notify('error', error?.message);
      }
    }
  };

  return (
    <PatientDiaryLayout
      resolver={resolver}
      selector={selectPatientDiaryPageByDay}
      tabRoutes={tabs}
      form={form}
      handleClearDiaryPages={handleClearDiaryPages}
      onSubmitProps={onSubmit}>
      <Switch>
        <Route exact path={`${PatientRoutes.NUTRITION_DIARY_NUTRITION}`} component={NutritionTab} />
        <Route exact path={`${PatientRoutes.NUTRITION_DIARY_WORKOUT}`} component={WorkoutTab} />
        <Route exact path={`${PatientRoutes.NUTRITION_DIARY_ENERGY}`} component={EnergyAndSleepTab} />
        <Route exact path={`${PatientRoutes.NUTRITION_DIARY_STRESS}`} component={StressTab} />
        <Route exact path={`${PatientRoutes.NUTRITION_DIARY_OTHER}`} component={OtherTab} />
        <Redirect to={`${PatientRoutes.NUTRITION_DIARY_NUTRITION}`} exact />
      </Switch>
    </PatientDiaryLayout>
  );
};
