import classnames from 'classnames';
import { ChangeEvent, CSSProperties, FC, FocusEvent } from 'react';

import styles from './styles.module.scss';

export type InputDefaultProps = {
  placeholder?: string;
  value?: string | number;
  required?: boolean;
  type?: 'text' | 'number' | 'file';
  error?: 'error' | 'warning';
  min?: number;
  pattern?: string;
  max?: number;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  defaultValue?: string;
  customStyles?: CSSProperties;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  className?: string;
};

export const InputDefault: FC<InputDefaultProps> = ({
  placeholder,
  defaultValue,
  type = 'text',
  required = false,
  disabled = false,
  value = '',
  error,
  min,
  max,
  pattern,
  readOnly,
  maxLength,
  customStyles,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onKeyDown,
  onPaste,
  className,
}) => {
  return (
    <input
      className={classnames(
        styles.input,
        {
          [styles[`input_${error}`]]: error,
        },
        className,
      )}
      placeholder={placeholder}
      required={required}
      pattern={pattern}
      type={type}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onPaste={onPaste}
      min={min}
      max={max}
      disabled={disabled}
      readOnly={readOnly}
      maxLength={maxLength}
      style={customStyles}
      spellCheck="false"
    />
  );
};
