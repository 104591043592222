import { FC } from 'react';

import styles from './styles.module.scss';

interface CheckUpsFormsWrapperProps {
  children?: JSX.Element;
}

export const CheckUpsFormsWrapper: FC<CheckUpsFormsWrapperProps> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};
