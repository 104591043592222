import { unwrapResult } from '@reduxjs/toolkit';
import { Expert } from 'api/generated';
import { Chat, Verified } from 'assets/svg';
import classNames from 'classnames';
import { FullInfoAboutExpertModal } from 'components';
import { actions } from 'features/chat/slice';
import { createChatRoomWithExpert } from 'features/patient/thunks';
import { useClientSize, useToggle } from 'hooks';
import plural from 'plural-ru';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault, Tooltip } from 'UIcomponents';

import styles from '../../styles.module.scss';

type MyExpertProps = {
  expert: Expert;
};

const MyExpert: FC<MyExpertProps> = ({ expert }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const [isLoading, setLoading] = useState(false);

  const { isOpened: isExpertInfoModalOpen, onToggle: toggleExpertInfoModal } = useToggle();

  const handleClick = async (evt: React.MouseEvent<Element, MouseEvent>) => {
    evt.stopPropagation();
    setLoading(true);
    await dispatch(createChatRoomWithExpert({ expertId: expert.id }))
      .then(unwrapResult)
      .then((res) => dispatch(actions.activeChat(res)))
      .then(() => history.push(CommonRoutes.MESSAGE))
      .catch((error) => {
        console.warn({ error });
      })
      .finally(() => setLoading(false));
  };

  const isVerifiedExpert = expert && expert.isApproved;

  return (
    <>
      <div
        className={classNames(
          styles['my-expert__container'],
          isExpertInfoModalOpen && styles['my-expert__container_info-modal-opened'],
        )}>
        <div
          className={styles['my-expert__inner']}
          onClick={toggleExpertInfoModal}
          role="button"
          tabIndex={0}
          onKeyUp={toggleExpertInfoModal}>
          <div className={styles['my-expert__header']}>
            <div className={styles['my-expert__avatar']}>
              <img src={expert.avatar} alt={`${expert.name}`} />
            </div>
            <div className={styles['my-expert__expert-info']}>
              <div className={styles['my-expert__username_container']}>
                <div className={styles['my-expert__username_name']}>{expert.name}</div>
                {isVerifiedExpert && <Verified data-tip data-for="verifiedTooltip" />}
                <Tooltip id="verifiedTooltip" place="top">
                  <span>{t('verifiedTooltip')}</span>
                </Tooltip>
              </div>
              {expert.age && (
                <div className={styles['my-expert__age']}>
                  {expert.age} {plural(expert.age, t('year'), t('years'), t('yearPlural'))}
                </div>
              )}
            </div>
          </div>
          <div className={styles['my-expert__body']}>
            <div className={styles['my-expert__expert-about']}>
              <div className={styles['my-expert__about-card']}>
                <p className={styles['my-expert__card-header']}>{t('specialization')}</p>
                <p className={styles['my-expert__card-content']}>{expert.spesialization}</p>
              </div>
              <div className={styles['my-expert__about-card']}>
                <div className={styles['my-expert__card-header']}>{t('expirience')}</div>
                <div className={styles['my-expert__card-content']}>
                  {expert.jobExperience}{' '}
                  {expert.jobExperience &&
                    plural(Math.floor(expert.jobExperience), t('year'), t('years'), t('yearPlural'))}
                </div>
              </div>
            </div>
            <ButtonDefault
              variant="secondary"
              onClick={(evt) => handleClick(evt)}
              customStyles={{ maxWidth: isMobile ? '100%' : '188px' }}
              isLoading={isLoading}>
              <div className={styles['my-expert__chat-icon']}>
                <Chat />
              </div>
              <div className={styles['my-expert__chat-anchor']}>{t('chat')}</div>
            </ButtonDefault>
          </div>
        </div>
      </div>

      <FullInfoAboutExpertModal
        isModalOpened={isExpertInfoModalOpen}
        onCloseModal={toggleExpertInfoModal}
        myExpert={true}
        expertId={expert.id}
      />
    </>
  );
};

export default MyExpert;
