import * as React from 'react';

function SvgHistoryIcon(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#8E8E8E' } = props;

  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.44 2a10 10 0 00-6.88 2.77V3a1 1 0 00-2 0v4.5a1 1 0 001 1h4.5a1 1 0 000-2h-2.4A8 8 0 1111.44 20a1 1 0 100 2 10 10 0 000-20zm0 6a1 1 0 00-1 1v3a1 1 0 001 1h2a1 1 0 000-2h-1V9a1 1 0 00-1-1z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgHistoryIcon;
