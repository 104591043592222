import { Subscription } from 'components';
import { selectSubscription } from 'features/subscriptions/selectors';
import { MainLayout } from 'layouts';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';
import { SuccessModal } from 'UIcomponents/SuccessModal';
import { getIsSubscriptionActive } from 'utils/subscriptions';

import { EnterPromocodeModal } from './components';
import styles from './styles.module.scss';

export const NewSubscription: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const { status } = useAppSelector(selectSubscription);

  const [promoCodeModalOpened, setPromoCodeModalOpened] = useState(false);
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const isActive = getIsSubscriptionActive(status);

  const history = useHistory();

  return (
    <MainLayout>
      <div className={styles['subscriptions-container']}>
        <div className={styles['subscriptions-container__header']}>
          <h2 className={styles['subscriptions-container__title']}>{t('selectSubscription')}</h2>
          {!isActive && (
            <ButtonDefault
              containerClassNames={styles['subscriptions-container__button']}
              text={t('inputPromo')}
              variant="secondary"
              onClick={() => setPromoCodeModalOpened(true)}
            />
          )}
        </div>
        <Subscription />
      </div>

      <EnterPromocodeModal
        isModalOpened={promoCodeModalOpened}
        onCloseModal={() => setPromoCodeModalOpened(false)}
        openSuccessModal={() => setSuccessModalOpened(true)}
      />

      <SuccessModal
        successText={t('promocodeActivated')}
        isModalOpened={successModalOpened}
        submitFunction={() => history.push(CommonRoutes.DASHBOARD)}
        onCloseModal={() => {
          setSuccessModalOpened(false);
          history.push(CommonRoutes.DASHBOARD);
        }}
      />
    </MainLayout>
  );
};
