import { unwrapResult } from '@reduxjs/toolkit';
import { EmptyMessageIcon } from 'assets/svg';
import ClipIcon from 'assets/svg/ClipIcon';
import DotsIcon from 'assets/svg/DotsIcon';
import MessageIcon from 'assets/svg/MessageIcon';
import Axios from 'axios';
import classnames from 'classnames';
import config from 'config';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import {
  selectActiveChat,
  selectLoadingGetMessage,
  selectLoadingMessage,
  selectMessages,
} from 'features/chat/selectors';
import { actions } from 'features/chat/slice';
import {
  addMessagesAsync,
  clearUnreadMessagesAsync,
  createChatSignedUrlAsync,
  getMessagesAsync,
  sendMessageAsync,
} from 'features/chat/thunks';
import { selectExpert } from 'features/expert/selectors';
import { actions as ExpertActions } from 'features/expert/slice';
import { selectPatientId, selectRoomChatLoadingStatus } from 'features/patient/selectors';
import { actions as PatientActions } from 'features/patient/slice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useWindowWidth from 'hooks/useWindowWidth';
import moment from 'moment';
import { ChangeEvent, FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { MESSAGES } from 'types/messages';
import { ExpertRoutes } from 'types/routes';
import { DEFAULT_AVATAR } from 'types/user';
import { ButtonDefault, Spinner, TextArea, UnreadMessage } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { v4 } from 'uuid';

import { ChatsGetMessageResDto } from '../../../../api/generated';
import stringCircumcision from '../../../../utils/stringCircumcision';
import { DropZone } from '../DropZone';
import { CurrentFile } from './components/CurrentFile';
import { Letter } from './components/Letter';
import { LetterMobile } from './components/LetterMobile';
import { Menu } from './components/Menu';
import { Response } from './components/Response';
import { ScrollToBottom } from './components/scrollToBottom';
import styles from './styles.module.scss';

interface FileTypes {
  files: File;
  id: string;
}

export interface FormValues {
  text: string;
  file: File;
}

interface Attachments {
  filename: string;
  URL: string;
  createdAt: string;
}

interface ChatProps {
  isOpenExpert?: boolean;
  toggleMobileAsideExpertInfo?: () => void;
}

const Chat: FC<ChatProps> = ({ toggleMobileAsideExpertInfo }) => {
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'message' });

  const createMessageList = (data: ChatsGetMessageResDto[] | undefined) => {
    const messagesList: Array<string | ChatsGetMessageResDto> = [];
    let prevValue: ChatsGetMessageResDto;
    if (data?.length) {
      data.forEach((mes, index, acc) => {
        const formattedDate =
          i18n.language === 'ru'
            ? moment(mes.createdAt).locale('ru').format('DD MMMM YYYY')
            : moment(mes.createdAt).locale('en-gb').format('DD MMMM YYYY');
        if (index === 0) {
          messagesList.push(formattedDate, mes);
          prevValue = mes;
        } else {
          if (moment(mes.createdAt).format('DD MMMM YYYY') > moment(prevValue.createdAt).format('DD MMMM YYYY')) {
            messagesList.push(formattedDate, mes);
            prevValue = mes;
          } else {
            messagesList.push(mes);
            prevValue = mes;
          }
        }
      });
    }
    return messagesList;
  };

  const ref = useRef(null);
  const expert = useSelector(selectExpert);
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const role = useSelector(selectRole);
  const virtuoss = useRef() as MutableRefObject<any>;
  const statusGetMessages = useSelector(selectLoadingGetMessage);
  const [loadedFiles, setLoadedFiles] = useState<Array<FileTypes>>([]);
  const activeChat = useSelector(selectActiveChat);
  const [repliedid, setRepliedId] = useState('');
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [messagesList, setMessagesList] = useState<Array<string | ChatsGetMessageResDto>>();

  const isLoadingGetMessages = statusGetMessages === LoadingStatus.pending;
  const mes = useSelector(selectMessages);
  const sendLoadingStastus = useSelector(selectLoadingMessage);
  const [currentMessage, setCurrentMessage] = useState<any>();
  const width = useWindowWidth();
  const [isUnread, setIsUnread] = useState(false);
  const patientid = useSelector(selectPatientId);
  const [isScrollButton, setisScrollButton] = useState(false);
  const sendLoading = sendLoadingStastus === LoadingStatus.pending;
  const [isPreloadingMessageSpin, setIsPreloadingMessageSpin] = useState(true);
  const roomChatLoading = useSelector(selectRoomChatLoadingStatus);

  const { control, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
  });

  const updateFile = (newFile: File) => {
    if (loadedFiles && loadedFiles.length > 4) {
      setIsDrag(false);
      return;
    }
    loadedFiles && setLoadedFiles([...loadedFiles, { files: newFile, id: v4() }]);
    loadedFiles && setValue('file', [...loadedFiles, newFile]);
    setIsDrag(false);
  };

  const toggleMenu = () => {
    setIsOpenMenu((state) => !state);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const newFiles = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsText(files[i]);
        if (i < 5) {
          if (loadedFiles?.length + newFiles?.length < 5) newFiles.push({ id: v4(), files: files[i] });
        }
      }
      e.target.value = '';
      setLoadedFiles([...loadedFiles, ...newFiles]);
    }
  };

  const sendUrls = async (urls: Array<string>, names: Array<string>, text?: string) => {
    const newUrls = urls.map((elem) => {
      const shortUrl = new URL(elem);
      return `${shortUrl.origin}${shortUrl.pathname}`;
    });
    const attachments: Array<Attachments> = [];
    for (let i = 0; i < urls.length; i++) {
      attachments.push({
        filename: names[i],
        URL: newUrls[i],
        createdAt: String(new Date().toISOString()),
      });
    }
    text ? sendMessage(text, attachments) : sendMessage('', attachments);
  };

  const sendMessage = (text: string, arrayUrls?: Array<Attachments>) => {
    dispatch(
      sendMessageAsync({
        id: activeChat.id,
        message: repliedid
          ? { text: text, attachments: arrayUrls, repliedMessageId: repliedid }
          : { text: text, attachments: arrayUrls },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(clearUnreadMessagesAsync(activeChat.id));
        setIsUnread(false);
      });
  };

  const createSignedUrls = (text?: string) => {
    const arrayUrls: Array<string> = [];
    const arrayNames: Array<string> = [];
    Promise.all(
      loadedFiles.map(async (file) => {
        await arrayNames.push(file.files.name);
        await dispatch(createChatSignedUrlAsync({ filename: file.files.name }))
          .then(unwrapResult)
          .then((res) => {
            arrayUrls.push(res.signedUrl);
            Axios.put(res.signedUrl, file.files);
          })
          .catch((error) => {
            console.error(error);
            notify('error', MESSAGES.error);
          });
      }),
    )
      .then(() => {
        sendUrls(arrayUrls, arrayNames, text);
      })
      .then(() => {
        setCurrentMessage(undefined);
        setLoadedFiles([]);
        setRepliedId('');
        setValue('text', '');
        reset();
      });
  };

  const onSubmit = async (data: FormValues) => {
    if (loadedFiles.length > 0) {
      createSignedUrls(data.text);
    } else {
      if (data.text) {
        dispatch(
          sendMessageAsync({
            id: activeChat.id,
            message: repliedid ? { text: data.text.trim(), repliedMessageId: repliedid } : { text: data.text.trim() },
          }),
        )
          .then(unwrapResult)
          .then(() => {
            dispatch(clearUnreadMessagesAsync(activeChat.id));
            if (role === 'PATIENT') {
              dispatch(ExpertActions.clearUnreadMessages(activeChat.id));
              dispatch(PatientActions.sortChatRoomsAfterSendMessage(activeChat.id));
            } else {
              dispatch(ExpertActions.clearUnreadMessages(activeChat.id));
              dispatch(ExpertActions.sortChatRoomsAfterSendMessage(activeChat.id));
            }
            setIsUnread(false);
          });
        setCurrentMessage(undefined);
        setValue('text', '');
        setRepliedId('');
        reset();

        //Metrics
        analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.MESSENGER_MESSAGE_SENT);
      }
    }
  };
  const handleClickOutside = () => {
    setIsOpenMenu(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const deleteFile = (id: number | string) => {
    const NewFiles = loadedFiles.filter((item: FileTypes) => {
      return item.id !== String(id);
    });
    setLoadedFiles(NewFiles);
  };

  const getCurrentMessage = (id: string) => {
    setRepliedId(id);
    const currentMessage = mes?.data?.find(function (item) {
      return item.id === id;
    });
    setCurrentMessage(currentMessage);
  };

  const handleCloseResponse = () => {
    setRepliedId('');
    setCurrentMessage(undefined);
  };

  function handleDrag(ev: React.DragEvent<HTMLDivElement>): void {
    setIsDrag(true);
  }

  function handleDragEnd(ev: React.DragEvent<HTMLDivElement>): void {
    setIsDrag(false);
  }

  const scrollBottom = () => {
    virtuoss.current?.scrollToIndex({ index: mes?.data?.length, behavior: 'smooth' });
    setisScrollButton(false);
  };

  const deleteScrollButton = () => {
    setisScrollButton(false);
  };

  const loadMore = () => {
    if (mes?.data?.length > 20) {
      dispatch(addMessagesAsync({ id: activeChat.id, limit: '30', cursor: mes.nextCursor }))
        .then(unwrapResult)
        .then((res) => {
          virtuoss.current?.scrollToIndex({ index: res.count });
          setisScrollButton(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    let eventSource: EventSource;
    if (role === 'PATIENT') {
      eventSource = new EventSource(`${config.API_URL}/chats/${patientid}/connect`);
    } else {
      eventSource = new EventSource(`${config.API_URL}/chats/${expert.id}/connect`);
    }

    eventSource.onerror = function (event) {
      console.error(event);
    };

    eventSource.onmessage = async function (event) {
      const message = JSON.parse(event.data);
      await dispatch(actions.addMessage(message.message));
      await dispatch(ExpertActions.updateChatDate({ chatId: activeChat.id }));
      if (message?.message?.sender?.kind !== role && mes?.data?.length > 30) {
        virtuoss.current?.scrollToIndex({ index: 1000000, behavior: 'smooth' });
      } else {
        virtuoss.current?.scrollToIndex({ index: 1000000, behavior: 'smooth' });
      }
    };
    return () => {
      eventSource.close();
    };
  }, [activeChat.id]);

  useEffect(() => {
    dispatch(getMessagesAsync({ id: activeChat.id, limit: '30' }))
      .then(unwrapResult)
      .then(() => {
        if (activeChat.unreadMessagesCount.expert !== 0) {
          setIsUnread(true);
        }
      });
  }, [activeChat]);

  useEffect(() => {
    if (
      statusGetMessages === LoadingStatus.fulfilled ||
      statusGetMessages === LoadingStatus.rejected ||
      (!activeChat?.id && role === RolesEnum.EXPERT) ||
      (role === RolesEnum.PATIENT && roomChatLoading === LoadingStatus.fulfilled)
    ) {
      setIsPreloadingMessageSpin(false);
    }
  }, [statusGetMessages, roomChatLoading]);

  useEffect(() => {
    setMessagesList(createMessageList(mes?.data));
  }, [mes?.data]);

  const handleExpertClick = () => {
    toggleMobileAsideExpertInfo && toggleMobileAsideExpertInfo();
  };

  return (
    <>
      <>
        {activeChat?.id ? (
          <div className={styles.chat} draggable={false} onDragEnter={handleDrag} onDragLeave={handleDragEnd}>
            <div className={styles.chat__header}>
              <button
                className={styles['chat-mobile__button_profile']}
                onClick={() => {
                  role === RolesEnum.EXPERT && push(ExpertRoutes.PATIENT_PROFILE(activeChat.patientId));
                }}>
                <div className={classnames('flexbox', 'direction-column', 'align-center', 'justify-center')}>
                  {width > 768 ? (
                    <>
                      {role === RolesEnum.EXPERT ? (
                        <Link
                          className={styles['chat__text-message_title']}
                          to={{
                            pathname: ExpertRoutes.PATIENT_PROFILE(activeChat.patientId),
                          }}>
                          {activeChat?.name}
                        </Link>
                      ) : (
                        <p className={styles['chat__text-message_title']}>{activeChat?.name}</p>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={styles['chat-mobile']}>
                        <div
                          className={styles['chat-mobile__circle']}
                          style={{
                            background: `center / cover no-repeat url(${
                              activeChat?.avatar ? activeChat?.avatar : DEFAULT_AVATAR
                            })`,
                          }}></div>
                        <div className={styles['chat-mobile__info']}>
                          {role === RolesEnum.EXPERT ? (
                            <Link
                              className={styles['chat-mobile__info-title']}
                              to={{
                                pathname: ExpertRoutes.PATIENT_PROFILE(activeChat.patientId),
                              }}>
                              {activeChat?.name}
                            </Link>
                          ) : (
                            <div
                              onClick={handleExpertClick}
                              role="button"
                              tabIndex={-1}
                              onKeyDown={handleExpertClick}
                              onTouchEnd={handleExpertClick}>
                              <p className={styles['chat-mobile__info-title']}>
                                {stringCircumcision(activeChat?.name)}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </button>
              <button className={styles.chat__more} onClick={toggleMenu}>
                <DotsIcon />
              </button>
              {isOpenMenu && <Menu close={setIsOpenMenu} boolean={isOpenMenu} chatId={activeChat.id} />}
            </div>
            {isPreloadingMessageSpin || isLoadingGetMessages ? (
              <div className={styles['loader']}>
                <Spinner variant="secondary" radius={20} color="var(--background-primary)" />
              </div>
            ) : (
              <>
                {isDrag ? (
                  <DropZone onChange={updateFile} />
                ) : (
                  <div className={styles.chat__content}>
                    {mes?.data?.length ? (
                      <Virtuoso
                        ref={virtuoss}
                        firstItemIndex={mes?.data?.length - 1}
                        initialTopMostItemIndex={mes?.data?.length - 1}
                        data={messagesList}
                        endReached={deleteScrollButton}
                        startReached={loadMore}
                        itemContent={(index, user) => {
                          return (
                            <>
                              {typeof user === 'string' ? (
                                <div className={styles['chat__messages-date-separator']}>{user}</div>
                              ) : (
                                <>
                                  {width > 1000 ? (
                                    <>
                                      {isUnread &&
                                        index ===
                                          mes?.data?.length * 2 -
                                            (role === 'PATIENT'
                                              ? activeChat?.unreadMessagesCount.patient - 1
                                              : activeChat?.unreadMessagesCount.expert) -
                                            1 && <UnreadMessage />}
                                      <Letter
                                        key={index}
                                        {...user}
                                        current={false}
                                        getCurrentMessage={getCurrentMessage}
                                        handleCloseResponse={handleCloseResponse}
                                      />
                                    </>
                                  ) : (
                                    <LetterMobile key={index} {...user} getCurrentMessage={getCurrentMessage} />
                                  )}
                                </>
                              )}
                            </>
                          );
                        }}
                      />
                    ) : (
                      <div className={styles['chat__empty-chat']}>
                        <p className={styles['chat__text-message_body']}>{t('emptyMessages')}</p>
                      </div>
                    )}
                    {currentMessage && (
                      <Response {...currentMessage} hasChat={false} responseDelete={handleCloseResponse} />
                    )}

                    <div className={styles.chat__submitted}>
                      <button className={styles.chat__clip}>
                        <ClipIcon />
                      </button>
                      <>
                        <Controller
                          name="file"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <input
                              type={'file'}
                              disabled={loadedFiles && loadedFiles.length < 5 ? false : true}
                              onChange={handleChangeFile}
                              multiple
                              style={{ cursor: loadedFiles?.length < 5 ? 'pointer' : 'default' }}
                              accept="application/pdf,.jpg,.jpeg,.png"
                              className={styles['chat-mobile__input_file']}
                            />
                          )}
                        />
                      </>
                      <Controller
                        name="text"
                        control={control}
                        defaultValue={''}
                        render={({ field: { onChange, value } }) => (
                          <TextArea
                            customStyles={{ marginRight: '20px', paddingLeft: '52px', width: '100%' }}
                            onChange={onChange}
                            onKeyDown={handleSubmit(onSubmit)}
                            value={value}
                            maxRows={4}
                            placeholder={t('typeMessage') as string}
                          />
                        )}
                      />
                      <ButtonDefault
                        text={''}
                        onClick={handleSubmit(onSubmit)}
                        isLoading={sendLoading}
                        variant={'message'}
                        customStyles={{ padding: 0, minWidth: '52px', width: '52px', height: '52px' }}>
                        <MessageIcon />
                      </ButtonDefault>
                      {isScrollButton && <ScrollToBottom onClick={scrollBottom} />}
                    </div>
                    {loadedFiles.map((elem: FileTypes) => (
                      <CurrentFile
                        key={elem.id}
                        File={elem.files}
                        filename={elem.files.name}
                        deletes={deleteFile}
                        index={elem.id}
                        inChat={false}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={classnames(styles['chat'], styles['chat__empty'])}>
            <div className={styles['empty-message__container']}>
              <EmptyMessageIcon />
              <p className={styles['empty-message__text']}>{t('selectChat')}</p>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Chat;
