import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertGetPatientsResDto } from 'api/generated';
import CheckupsImage from 'assets/images/checkUps.png';
import { CheckUpCard } from 'components';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectCheckups } from 'features/checkups/selectors';
import { getPatientsAsync } from 'features/expert/thunks';
import { LayoutWithBackOptions } from 'layouts';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analytics } from 'services/analytics';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './styles.module.scss';

export type PatientDropdownProps = {
  label: string;
  value: string;
};

export const CheckUps: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'checkUps' });

  const isRuLanguage = i18n.language === 'ru';

  const dispatch = useAppDispatch();

  const checkups = useAppSelector(selectCheckups);

  const role = useAppSelector(selectRole);
  const isExpert = role === RolesEnum.EXPERT;

  const [patients, setPatients] = useState<Array<PatientDropdownProps>>();

  const createPatientsDropdownArray = (patients: ExpertGetPatientsResDto) => {
    const result = patients.data
      .filter((patient) => patient.cooperatedWithExpertAt !== null)
      .map((patient) => {
        return { label: patient.name, value: patient.id };
      });
    setPatients(result);
  };

  const getPatients = async () => {
    await dispatch(
      getPatientsAsync({
        page: 1,
        limit: 100000,
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    )
      .then(unwrapResult)
      .then((result) => createPatientsDropdownArray(result));
  };

  useEffect(() => {
    if (isExpert) {
      getPatients();
    }

    //Metrics
    isExpert
      ? analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_CHECKUPS_PAGE_VIEWED)
      : analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_CHECKUPS_PAGE_VIEWED);
  }, [isExpert]);

  return (
    <LayoutWithBackOptions backOptions={{ text: t('checkUps'), url: CommonRoutes.DASHBOARD }}>
      {isRuLanguage && <img src={CheckupsImage} alt="Чекап" width="932px" />}
      <p className={`${styles.subtitle} body body_medium`}>{t('descriptionCheckUps')}</p>
      <div className={styles['cards-container']}>
        {isExpert
          ? checkups.map((checkup) => (
              <CheckUpCard
                key={checkup.id}
                checkupId={checkup.id}
                title={checkup.name}
                link={CommonRoutes.CHECK_UP(checkup.id)}
                img={checkup.avatar}
                patients={patients}
              />
            ))
          : checkups.map((checkup) => (
              <CheckUpCard
                key={checkup.id}
                checkupId={checkup.id}
                title={checkup.name}
                link={CommonRoutes.CHECK_UP(checkup.id)}
                img={checkup.avatar}
              />
            ))}
      </div>
    </LayoutWithBackOptions>
  );
};
