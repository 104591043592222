import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { BaseModal } from 'components';
import { selectCancelSubscriptionLoadingStatus } from 'features/subscriptions/selectors';
import { cancelSubscriptionAsync } from 'features/subscriptions/thunks';
import { useClientSize } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type CancelSubscriptionModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
};

export const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({ isModalOpened, onCloseModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.userSubscriptionTab' });

  const dispatch = useAppDispatch();
  const cancelSubscription = async () => {
    await dispatch(cancelSubscriptionAsync())
      .then(unwrapResult)
      .then(() => {
        notify('success', t('notifySuccess'));
      })
      .catch((error) => {
        notify('error', error.message);
      })
      .finally(() => onCloseModal());
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const subscriptionCancellingLoading = useAppSelector(selectCancelSubscriptionLoadingStatus) === LoadingStatus.pending;

  return (
    <BaseModal
      title={t('cancelSubscription') as string}
      isVisible={isModalOpened}
      onClose={onCloseModal}
      customStyles={isMobile ? { padding: '60px 24px' } : { padding: '60px 100px' }}>
      <div className={classNames('body body_medium', styles['cancel-subscription-modal__text'])}>
        {t('confirmCancelSubscription')}
      </div>

      <div className={classNames('flexbox', 'justify-between')}>
        <ButtonDefault
          text={t('close')}
          variant="secondary"
          onClick={onCloseModal}
          customStyles={{ margin: '0px 12px 0px 0px' }}
          containerClassNames={styles['cancel-subscription-modal__button']}
        />
        <ButtonDefault
          text={t('cancel')}
          customStyles={{ backgroundColor: 'var(--red)' }}
          onClick={cancelSubscription}
          containerClassNames={styles['cancel-subscription-modal__button']}
          isLoading={subscriptionCancellingLoading}
        />
      </div>
    </BaseModal>
  );
};
