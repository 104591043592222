import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertFullInfoResDto } from 'api/generated';
import { Verified } from 'assets/svg';
import classnames from 'classnames';
import { getOneExpert } from 'features/patient/thunks';
import plural from 'plural-ru';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/reducers';
import { DEFAULT_AVATAR } from 'types/user';
import { Spinner, Tooltip } from 'UIcomponents';

import styles from './styles.module.scss';

type ExpertAsideComponentProps = {
  expertId: string;
};

export const ExpertAsideComponent: FC<ExpertAsideComponentProps> = ({ expertId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.expertProfileTab.fullInfoAboutExpertModal' });
  const dispatch = useAppDispatch();

  const [expert, setExpert] = useState<ExpertFullInfoResDto>();
  const [isExpertLoading, setExpertLoading] = useState(false);

  const getExpertInfo = async () => {
    setExpertLoading(true);
    await dispatch(getOneExpert(expertId))
      .then(unwrapResult)
      .then((result) => setExpert(result))
      .catch((error) => {
        console.warn({ error });
      })
      .finally(() => setExpertLoading(false));
  };

  useEffect(() => {
    getExpertInfo();
  }, [expertId]);

  const isVerifiedExpert = expert && expert.isApproved;

  return (
    <div className={styles.expert}>
      {isExpertLoading ? (
        <div className={styles.expert__spinner}>
          <Spinner radius={20} color="var(--background-primary)" />
        </div>
      ) : (
        <>
          {expert && (
            <>
              <div className={styles.expert__info}>
                <div
                  className={styles['expert__info__info-avatar']}
                  style={{
                    background: `center / cover no-repeat url(${expert.avatar || DEFAULT_AVATAR})`,
                  }}
                />
                <div className={styles.expert__username_container}>
                  <p className={classnames(styles['expert__info__info-name'], styles.subhead)}>{expert.name}</p>
                  {isVerifiedExpert && <Verified data-tip data-for="verifiedTooltip" />}
                  <Tooltip id="verifiedTooltip" place="top">
                    <span>{t('eul')}</span>
                  </Tooltip>
                </div>
                {expert.age && (
                  <p className={classnames(styles['expert__info__info-year'], styles.subhead)}>
                    {expert.age} {plural(expert.age, t('year'), t('years'), t('yearPlural'))}
                  </p>
                )}
              </div>
              <div className={styles.expert__description}>
                <div className={styles.expert__paragraph}>
                  <p className={classnames(styles.subhead, styles['expert__text_grey'])}>{t('spesialization')}</p>
                  <p className={classnames(styles['expert__spesialization'], styles.subhead)}>
                    {expert.spesialization}
                  </p>
                </div>
                <div className={styles.expert__paragraph}>
                  <p className={classnames(styles.subhead, styles['expert__text_grey'])}>{t('jobExperience')} </p>
                  {expert.jobExperience && (
                    <p className={classnames(styles.subhead)}>
                      {expert.jobExperience} {plural(expert.jobExperience, t('year'), t('years'), t('yearPlural'))}
                    </p>
                  )}
                </div>
                <div className={styles.expert__paragraph}>
                  <p className={classnames(styles.subhead, styles['expert__text_grey'])}>{t('about')}</p>
                  <p className={classnames(styles.subhead, styles.expert__about)}>{expert.shortInfo}</p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
