import {
  PromocodePeriodEnum,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
  UpdateSubscriptionDtoSubscriptionDurationEnum,
} from 'api/generated';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActivePromocode, selectPromocodes } from 'features/promocodes/selectors';
import { selectSubscription, selectSubscriptionsConstants } from 'features/subscriptions/selectors';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/reducers';
import { SubscriptionPlan } from 'types';
import { getIsSubscriptionExpired } from 'utils/subscriptions';

import { SubscriptionDtoPaymentPlatformEnum } from './../api/generated/api';
import { SubscriptionsConstantsWithDay } from './../components/Subscription/Subscription';

const promocodePeriodMapping = {
  [PromocodePeriodEnum.Day]: 'День',
  [PromocodePeriodEnum.Month]: 'Месяц',
  [PromocodePeriodEnum.ThreeMonths]: 'Три месяца',
  [PromocodePeriodEnum.SixMonths]: 'Шесть месяцев',
  [PromocodePeriodEnum.Year]: 'Год',
};

interface SubscriptionData {
  amountRub: number;
  amountUsd: number;
  name: string;
  nextBillingDate: string | null;
  period: string;
  status: SubscriptionDtoStatusEnum;
  platform?: SubscriptionDtoPaymentPlatformEnum;
}

export const getAccessStatus = (
  hasPromocodes: boolean,
  hasSubsription: boolean,
  hasActivePromocode: boolean,
  status: SubscriptionDtoStatusEnum,
): SubscriptionDtoStatusEnum => {
  if (hasPromocodes) {
    if (hasActivePromocode) {
      return SubscriptionDtoStatusEnum.PromocodeActive;
    } else {
      if (hasSubsription) {
        return status;
      } else {
        return SubscriptionDtoStatusEnum.PromocodeExpired;
      }
    }
  } else {
    if (hasSubsription) {
      return status;
    } else return SubscriptionDtoStatusEnum.CanceledExpired;
  }
};

const getHasAccessToAnalyzes = (
  hasPromocodes: boolean,
  hasSubsription: boolean,
  hasActivePromocode: boolean,
  status: SubscriptionDtoStatusEnum,
) => {
  if (hasPromocodes) {
    if (hasActivePromocode) {
      return true;
    } else return false;
  } else {
    if (hasSubsription) {
      return !getIsSubscriptionExpired(status);
    } else return false;
  }
};
/* TODO: надо это отрефакторить, по сути этот хук только для статуса используется, он был выпилен в других местах, так логика подписок изменилась */
export const useSubscriptionData = (
  subscriptionDuration?: UpdateSubscriptionDtoSubscriptionDurationEnum,
): { subscriptionData: SubscriptionData; hasAccessToAnalyzes: boolean } => {
  const subscription = useAppSelector(selectSubscription);
  const { subscriptionPlan, accessUntil, status, paymentPlatform } = subscription;
  const subscriptionsConstants = useAppSelector(selectSubscriptionsConstants);
  const activePromocode = useAppSelector(selectActivePromocode);

  const promocodes = useAppSelector(selectPromocodes);
  const hasPromocodes = !!promocodes.length;
  const hasSubscription = !isEmpty(subscription);

  const hasActivePromocode = !!activePromocode;

  const accessStatus = getAccessStatus(hasPromocodes, hasSubscription, hasActivePromocode, status);

  const hasAccessToAnalyzes = getHasAccessToAnalyzes(hasPromocodes, hasSubscription, hasActivePromocode, status);

  const role = useAppSelector(selectRole);

  const isExpert = role === RolesEnum.EXPERT;

  const dayPriceRub: number = isExpert ? 100 : 111;
  const dayPriceUsd: number = isExpert ? 10 : 111;

  const subscriptionConstantsWithDay: SubscriptionsConstantsWithDay = {
    ...subscriptionsConstants,
    day: {
      price: { rub: dayPriceRub, usd: dayPriceUsd },
      description: 'Тест Дня',
      interval: 'Day',
      period: 1,
    },
  };

  const initialData = {
    amountRub: subscriptionConstantsWithDay[
      (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum) || subscriptionDuration
    ]?.price.rub as number,
    amountUsd: subscriptionConstantsWithDay[
      (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum) || subscriptionDuration
    ]?.price.usd as number,
    //name: SubscriptionPlanName[subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum],
    name: 'todo',
    nextBillingDate: accessUntil,
    status: accessStatus,
    period:
      (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum as unknown as SubscriptionPlan) ===
      SubscriptionPlan.YEARLY
        ? 'год'
        : 'месяц',
    platform: paymentPlatform,
  };

  const [subscriptionData, setSubscriptionData] = useState<any>(initialData);
  useEffect(() => {
    if (hasPromocodes) {
      if (hasActivePromocode) {
        setSubscriptionData({
          platform: '',
          amountRub: 0,
          amountUsd: 0,
          status: accessStatus,
          nextBillingDate: activePromocode.expirationDate,
          period: promocodePeriodMapping[activePromocode.period],
          name: '',
        });
        return;
      } else {
        setSubscriptionData({
          amountRub: subscriptionConstantsWithDay[
            subscriptionDuration || (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum)
          ]?.price.rub as number,
          amountUsd: subscriptionConstantsWithDay[
            subscriptionDuration || (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum)
          ]?.price.usd as number,
          // name: SubscriptionPlanName[subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum],
          name: 'todo',
          nextBillingDate: accessUntil,
          period:
            (subscriptionPlan as SubscriptionDtoSubscriptionPlanEnum as unknown as SubscriptionPlan) ===
            SubscriptionPlan.YEARLY
              ? 'год'
              : 'месяц',
          status: accessStatus,
          platform: subscription.paymentPlatform,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessStatus, accessUntil, subscriptionPlan, subscriptionDuration, hasActivePromocode]);

  return { subscriptionData, hasAccessToAnalyzes };
};
