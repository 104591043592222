export enum StorageKeys {
  BIOGEEk_LANGUAGE = 'biogeek_language',
}

export const getLanguageFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(`${StorageKeys.BIOGEEk_LANGUAGE}`);
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const setLanguageToStorage = (payload: string) => {
  return localStorage.setItem(`${StorageKeys.BIOGEEk_LANGUAGE}`, JSON.stringify(payload));
};

export const resetLanguageStorage = () => {
  return localStorage.removeItem(`${StorageKeys.BIOGEEk_LANGUAGE}`);
};

export enum LanguagesEnum {
  ru = 'ru',
  en = 'en',
}
