import {
  GetPaymentUrlDtoPaymentPlatformEnum,
  SubscriptionDtoStatusEnum,
  SubscriptionDtoSubscriptionPlanEnum,
  SubscriptionPriceDto,
} from 'api/generated';
import { Check } from 'assets/svg';
import { selectSubscription } from 'features/subscriptions/selectors';
import { LanguageType } from 'i18n';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { analytics } from 'services/analytics';
import { useAppSelector } from 'store/reducers';
import { ButtonDefault } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

import styles from './SubscriptionCardExpert.module.scss';

type SubscriptionCardExpertProps = {
  price: SubscriptionPriceDto;
  patientLimit: number;
  period: number;
  plan: SubscriptionDtoSubscriptionPlanEnum;
  onCheckoutClick: (plan: SubscriptionDtoSubscriptionPlanEnum, platform: GetPaymentUrlDtoPaymentPlatformEnum) => void;
  onUpdateClick: (plan: SubscriptionDtoSubscriptionPlanEnum) => void;
  shouldUpdate: boolean;
};

export const SubscriptionCardExpert: FC<SubscriptionCardExpertProps> = ({
  price,
  patientLimit,
  period,
  plan,
  onCheckoutClick,
  onUpdateClick,
  shouldUpdate,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const isYearSubscriptionPeriod = period === 12;
  const yearPriceByMonthRub = Math.floor(price.rub / 12);
  const yearPriceByMonthUsd = Math.floor(price.usd / 12);

  const expertBaseFeatures: string[] = [
    t('expertFeatures.publishProfile'),
    t('expertFeatures.createProtocols'),
    t('expertFeatures.saveMessaging'),
    t('expertFeatures.diary'),
    t('expertFeatures.analyzes'),
    t('expertFeatures.calendar'),
    t('expertFeatures.preanalyticsOfLaboratoryData'),
  ];

  const expertLimitPatient = `${t('patientLimit')} ${patientLimit} ${t('clients')}`;
  const expertFeatures = [expertLimitPatient, ...expertBaseFeatures];

  const subscription = useAppSelector(selectSubscription);

  const { subscriptionPlan, status } = subscription;

  const hasSubscription = Object.keys(subscription).length !== 0;

  const isSubscriptionExpired =
    status === SubscriptionDtoStatusEnum.Expired || status === SubscriptionDtoStatusEnum.CanceledExpired;
  const isSubscriptionActive = hasSubscription && subscriptionPlan === plan && !isSubscriptionExpired;

  // TODO: use price in dollars
  const getYearText = () => {
    return {
      [LanguageType.EN]: `$${yearPriceByMonthUsd}  ${t('inMonth')}`,
      [LanguageType.RU]: `${yearPriceByMonthRub} ₽ ${t('inMonth')}`,
    }[i18n.language];
  };

  const handleClickButtonRuPayment = (
    plan: SubscriptionDtoSubscriptionPlanEnum,
    platform: GetPaymentUrlDtoPaymentPlatformEnum,
  ) => {
    //Metrics
    //Year
    if (plan === SubscriptionDtoSubscriptionPlanEnum.HighYear) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'year',
        subscriptionTier: 'high',
      });
    }

    if (plan === SubscriptionDtoSubscriptionPlanEnum.MediumYear) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'year',
        subscriptionTier: 'medium',
      });
    }

    if (plan === SubscriptionDtoSubscriptionPlanEnum.LowYear) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'year',
        subscriptionTier: 'low',
      });
    }

    //Month
    if (plan === SubscriptionDtoSubscriptionPlanEnum.HighMonth) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'month',
        subscriptionTier: 'high',
      });
    }

    if (plan === SubscriptionDtoSubscriptionPlanEnum.MediumMonth) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'month',
        subscriptionTier: 'medium',
      });
    }

    if (plan === SubscriptionDtoSubscriptionPlanEnum.LowMonth) {
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_BUY_SUBSCRIPTION_PRESSED, {
        subscriptionType: 'month',
        subscriptionTier: 'low',
      });
    }

    onCheckoutClick(plan, platform);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.header_container}>
          <span className={styles.title}>
            {patientLimit} {t('clients')}
          </span>
          <span className={styles.subtitle}>{isYearSubscriptionPeriod ? t('yearSubs') : t('monthSubs')}</span>
        </div>
        <div className={styles.price_container}>
          {isYearSubscriptionPeriod ? (
            <div className={styles.price_inner}>
              <span className={styles.price}>
                {i18n.language === LanguageType.RU ? `${price.rub} ₽` : `$${price.usd}`}
              </span>
              <span className={styles.priceLabel}>{t('inYear')}</span>
            </div>
          ) : (
            <span className={styles.price}>
              {i18n.language === LanguageType.RU ? `${price.rub} ₽` : `$${price.usd}`}
            </span>
          )}
          <span className={styles.priceLabel}>{isYearSubscriptionPeriod ? getYearText() : t('inMonth')}</span>
        </div>
      </div>
      <ul className={styles.features}>
        {expertFeatures.map((feature, index) => (
          <li key={index} className={styles.feature}>
            {feature}
          </li>
        ))}
      </ul>
      {isSubscriptionActive ? (
        <div className={styles.active_subscriptions}>
          <div className={styles.active_container}>
            <Check width={20} height={20} pathFill="var(--background-secondary)" />
            <span className={styles.active_heading}>{t('subscriptionActivated')}</span>
          </div>
          <span className={styles.active_subheading}>
            {t('nextPayment')} {moment(subscription.accessUntil).format('DD.MM.YYYY')}
          </span>
        </div>
      ) : (
        <div className={styles.actions}>
          {shouldUpdate ? (
            <ButtonDefault type="button" text={t('change')} variant="secondary" onClick={() => onUpdateClick(plan)} />
          ) : (
            <>
              {i18n.language === LanguageType.RU && (
                <ButtonDefault
                  type="button"
                  text={t('paymentButtonRu')}
                  variant="secondary"
                  onClick={() => handleClickButtonRuPayment(plan, GetPaymentUrlDtoPaymentPlatformEnum.CloudPayments)}
                />
              )}
              <ButtonDefault
                type="button"
                text={i18n.language === LanguageType.RU ? t('paymentButton') : t('pay')}
                variant="secondary"
                onClick={() => onCheckoutClick(plan, GetPaymentUrlDtoPaymentPlatformEnum.Stripe)}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
