import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { selectLoadingStatus } from 'features/auth/selectors';
import {
  expertGetVerificationCodeAsync,
  patientGetVerificationCodeAsync,
  patientSignUpHashAsync,
} from 'features/auth/thunks';
import { selectPatientEmail, selectUpdatingPatientStatus } from 'features/patient/selectors';
import { actions } from 'features/patient/slice';
import { getEmailAsync } from 'features/patient/thunks';
import queryString from 'query-string';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppSelector } from 'store/reducers';
import { AppDispatch } from 'store/types';
import { LoadingStatus } from 'types';
import { CommonRoutes, UnauthorizedRoutes } from 'types/routes';
import { ButtonDefault, Checkbox, FormError, FormLabel, InputDefault, PasswordInput, Spinner } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  readAgreement: boolean;
}

type SignUpFormProps = {
  isExpert: boolean;
};

export const SignUpForm: FC<SignUpFormProps> = ({ isExpert }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const history = useHistory();
  const { hash } = queryString.parse(location.search);

  const patientEmail = useSelector(selectPatientEmail);
  const loadingStatus = useSelector(selectLoadingStatus);
  const isPatientLoading = useAppSelector(selectUpdatingPatientStatus) === LoadingStatus.pending;

  const dispatch: AppDispatch = useDispatch();

  const isLoading = loadingStatus === LoadingStatus.pending;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    trigger,
  } = useForm<FormValues>({ mode: 'onChange', reValidateMode: 'onChange' });

  const getEmail = async (hash: string) => {
    dispatch(getEmailAsync(hash))
      .then(unwrapResult)
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const getVerificationCode = async (data: FormValues) => {
    await dispatch(
      isExpert
        ? expertGetVerificationCodeAsync({ email: data.email })
        : patientGetVerificationCodeAsync({ email: data.email }),
    )
      .then(unwrapResult)
      .then(() => {
        history.push(UnauthorizedRoutes.EMAIL_VERIFICATION, data);
      })
      .catch((error) => {
        if (error.message[0] === 'Пользователь с таким email адресом уже существует') {
          setError('email', {
            type: 'backendError',
            message: error.message,
          });
        } else notify('error', error.message);
      });
  };

  const patientSignUpHash = async (data: FormValues) => {
    await dispatch(
      patientSignUpHashAsync({
        password: data.password,
        passwordConfirm: data.passwordConfirm,
        signUpHash: hash as string,
      }),
    )
      .then(unwrapResult)
      .then((patient) => {
        dispatch(actions.savePatient(patient.patient));
        history.push(CommonRoutes.DASHBOARD, data);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    hash && getEmail(hash as string);
  }, []);

  const onSubmit = async (data: FormValues) => {
    !hash ? getVerificationCode(data) : patientSignUpHash(data);

    //Metrics
    !isExpert
      ? analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.CLIENT_SIGN_UP_PRESSED)
      : analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.EXPERT.EXPERT_SIGN_UP_PRESSED);
  };

  const isSignUpByInvitation = !!hash;

  if (isPatientLoading) {
    return (
      <div className="main-spinner-wrapper">
        <Spinner radius={20} color="var(--background-primary)" />
      </div>
    );
  }

  return (
    <div className={styles['sign-up']}>
      {isSignUpByInvitation && (
        <span className={`body body_medium ${styles['sign-up__subtitle']}`}>
          {patientEmail ? t('signUpByInvitation') : t('signUpByInvitationInactive')}
        </span>
      )}
      {!hash && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles['sign-up__form']}>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('email')} />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('required') as string,
                maxLength: {
                  value: 100,
                  message: t('emailPattern') as string,
                },
                pattern: {
                  value: patterns.email,
                  message: t('emailPattern') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={(e) => {
                    const value = e.target.value;
                    const trimValue = value.trim();
                    e.target.value = trimValue;
                    onChange(e);
                  }}
                  value={value}
                  error={errors.email && 'error'}
                  placeholder="example@email.com"
                />
              )}
            />
            {errors.email?.message && <FormError type="error" text={errors.email.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('createPassword')} />
            <Controller
              name="password"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.password,
                  message: t('passwordPattern') as string,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={(e) => {
                    const { passwordConfirm } = getValues();

                    onChange(e.target.value);
                    passwordConfirm !== undefined && trigger('passwordConfirm');
                  }}
                  value={value}
                  error={!!errors.password || errors.passwordConfirm?.type === 'matchesPreviousPassword'}
                  placeholder="••••••"
                />
              )}
            />
            {errors.password?.message && <FormError type="error" text={errors.password.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('confirmPassword')} />
            <Controller
              name="passwordConfirm"
              control={control}
              rules={{
                required: t('required') as string,
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || (t('matchesPreviousPassword') as string);
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  error={!!errors.passwordConfirm}
                  placeholder="••••••"
                />
              )}
            />
            {errors.passwordConfirm?.message && <FormError type="error" text={errors.passwordConfirm.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <Controller
              name="readAgreement"
              control={control}
              rules={{ required: t('required') as string }}
              render={({ field: { value, onChange } }) => (
                <div className={styles['sign-up__terms']}>
                  <Checkbox checked={value} onChange={onChange} error={errors.readAgreement && true} />
                  <p
                    className={classNames('subhead', 'subhead_medium', styles['sign-up__terms-text'], {
                      [styles['sign-up__terms-text--error']]: errors.readAgreement && true,
                    })}>
                    {t('readAgreement')}{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.TERMS_OF_SERVICE}>
                      {t('terms')}
                    </Link>
                    ,{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.PRIVACY_POLICY}>
                      {t('policy')}
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.DISCLAIMER_OF_LIABILITY}>
                      {t('disclaimer')}
                    </Link>
                  </p>
                </div>
              )}
            />
          </div>
          <ButtonDefault
            text={t('signUp')}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </form>
      )}

      {hash && patientEmail && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles['sign-up__form']}>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('email')} />
            <Controller
              name="email"
              control={control}
              defaultValue={patientEmail}
              rules={{
                required: t('required') as string,
                maxLength: {
                  value: 100,
                  message: t('emailPattern'),
                },
                pattern: {
                  value: patterns.email,
                  message: t('emailPattern'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={onChange}
                  disabled={true}
                  value={value}
                  error={errors.email && 'error'}
                  placeholder="example@email.com"
                />
              )}
            />
            {errors.email?.message && <FormError type="error" text={errors.email.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('createPassword')} />
            <Controller
              name="password"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.password,
                  message: t('passwordPattern'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={(e) => {
                    const { passwordConfirm } = getValues();

                    onChange(e.target.value);
                    passwordConfirm !== undefined && trigger('passwordConfirm');
                  }}
                  value={value}
                  error={!!errors.password || errors.passwordConfirm?.type === 'matchesPreviousPassword'}
                  placeholder="••••••"
                />
              )}
            />
            {errors.password?.message && <FormError type="error" text={errors.password.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <FormLabel text={t('confirmPassword')} />
            <Controller
              name="passwordConfirm"
              control={control}
              rules={{
                required: t('required') as string,
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || (t('matchesPreviousPassword') as string);
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  error={!!errors.passwordConfirm}
                  placeholder="••••••"
                />
              )}
            />
            {errors.passwordConfirm?.message && <FormError type="error" text={errors.passwordConfirm.message} />}
          </div>
          <div className={styles['sign-up__field']}>
            <Controller
              name="readAgreement"
              control={control}
              rules={{ required: t('required') as string }}
              render={({ field: { value, onChange } }) => (
                <div className={styles['sign-up__terms']}>
                  <Checkbox checked={value} onChange={onChange} error={errors.readAgreement && true} />
                  <p
                    className={classNames('subhead', 'subhead_medium', styles['sign-up__terms-text'], {
                      [styles['sign-up__terms-text--error']]: errors.readAgreement && true,
                    })}>
                    {t('readAgreement')}{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.TERMS_OF_SERVICE}>
                      {t('terms')}
                    </Link>
                    ,{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.PRIVACY_POLICY}>
                      {t('policy')}
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link className={styles['sign-up__terms-link']} to={CommonRoutes.DISCLAIMER_OF_LIABILITY}>
                      {t('disclaimer')}
                    </Link>
                  </p>
                </div>
              )}
            />
          </div>
          <ButtonDefault
            text={t('signUp')}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </form>
      )}
      {!isSignUpByInvitation && (
        <div className={styles['sign-up__footer']}>
          <span className={`body body_medium ${styles['sign-up__subtitle']}`}>{t('oldUser')}</span>
          <Link to={UnauthorizedRoutes.SIGN_IN} className={`body body_bold ${styles['sign-up__link']}`}>
            {t('signIn')}
          </Link>
        </div>
      )}
    </div>
  );
};
