export const UnauthorizedRoutes = {
  ROOT: '/',
  AUTH: '/auth',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  SIGN_UP_PATIENT: '/auth/patient',
  EMAIL_VERIFICATION: '/auth/sign-up/verification',
};

export const CommonRoutes = {
  DASHBOARD: '/dashboard',
  ROOT: '/',
  ONBOARDING: '/onboarding',
  PROFILE: '/profile',
  MESSAGE: '/message',
  ANALYZES_HISTORY: '/analysis-history',
  PASSWORD_RESET: '/forgot-password/password-reset',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_NEW: '/restore-password',
  FORGOT_PASSWORD_CHANGED: '/restore-password/changed',
  FAQ: '/faq',
  CONTACT_FORM: '/contact-form',
  TERMS_OF_SERVICE: '/faq/terms-of-service',
  PRIVACY_POLICY: '/faq/privacy-policy',
  DISCLAIMER_OF_LIABILITY: '/faq/disclaimer_of_liability',
  ANALYSIS: (id = ':id'): string => `/analysis/${id}`,
  CHECK_UPS: '/check-ups',
  CHECK_UP: (id = ':id'): string => `/check-ups/${id}`,
  NEW_ANALYSIS: '/new-analysis',
  NEW_ANALYSIS_IMPORT_MANUALLY: '/new-analysis/manually',
};

export const ExpertRoutes = {
  PROFILE: '/expert-profile',
  PROFILE_SETTINGS: '/expert-settings',
  SETTINGS: '/expert-profile/settings',
  PAYMENTS: '/expert-profile/payments',
  CARDS: '/expert-profile/my-cards',
  SUBSCRIPTION: '/expert-profile/subscriptions',
  SUBSCRIPTIONS: '/subscriptions',
  NOTIFICATIONS: '/expert-profile/notifications',
  CALENDAR: '/calendar',
  PROOF_EDUCATION: '/proof-education',
  DOWNLOAD_PROOF: '/download-proof',
  SUCCES_PROOF: '/succes-proof',
  ASSIGN_CHECKUP: '/assign-checkup',
  CREATE_CUSTOM_CHECKUP: '/create-custom-checkup',

  //Nutrition Patient Routes
  PATIENT_PROFILE: (id = ':id'): string => `/patient-profile/${id}`,
  PATIENT_ANAMNESIS: (id = ':id'): string => `/patient-profile/${id}/anamnesis`,
  PATIENT_NUTRITION_DIARY: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary`,
  PATIENT_NUTRITION_DIARY_NUTRITION: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary/nutrition`,
  PATIENT_NUTRITION_DIARY_WORKOUT: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary/workout`,
  PATIENT_NUTRITION_DIARY_ENERGY: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary/energy`,
  PATIENT_NUTRITION_DIARY_STRESS: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary/stress`,
  PATIENT_NUTRITION_DIARY_OTHER: (id = ':id'): string => `/patient-profile/${id}/nutrition_diary/other`,
  PATEINT_DIET_RECOMMENDATIONS: '/patient-profile/diet-recommendations',
  PATIENT_NUTRITION_PLAN: (id = ':id'): string => `/patient-profile/${id}/nutrition-plan`,
  PATIENT_NUTRITION_PLAN_ID: (patientId = ':patientId', id = ':id'): string =>
    `/patient-profile/${patientId}/nutrition-plan/${id}`,
  PATIENT_ANALYZES: (id = ':id'): string => `/patient-profile/${id}/analyzes`,
  PATIENT_SETTINGS: (id = ':id'): string => `/patient-settings/${id}`,
  PATIENT_RECOMMENDATIONS: (id = ':id'): string => `/patient-profile/${id}/recommendations`,
  PATIENT_RECOMMENDATION: (id = ':id', recommendationCardId = ':recommendationCardId'): string =>
    `/${id}/recommendation/${recommendationCardId}`,
  PATIENT_PLAN: '/patient-plan',
  EDIT_ANALYSIS: (id = ':id'): string => `/analysis/${id}/edit`,
};

export const PatientRoutes = {
  ONBOARDING_PATIENT: '/onboarding-patient',
  ANALYSIS_PROCESSING: '/analysis-processing',
  EXPERTS: '/experts',
  SUBSCRIPTIONS: '/subscriptions',

  RECOMMENDATION: (id = ':id'): string => `/recommendations/${id}`,
  PLAN: (id = ':id'): string => `/plan/${id}`,
  PATIENT_PROFILE: (id = ':id'): string => `/patient-profile/${id}`,
  PROFILE: '/user-profile',
  PROFILE_SETTINGS: '/user-profile/settings',
  ANAMNESIS: '/user-profile/anamnesis',
  NUTRITION_DIARY: '/user-profile/nutrition_diary',
  NUTRITION_DIARY_NUTRITION: '/user-profile/nutrition_diary/nutrition',
  NUTRITION_DIARY_WORKOUT: '/user-profile/nutrition_diary/workout',
  NUTRITION_DIARY_ENERGY: '/user-profile/nutrition_diary/energy',
  NUTRITION_DIARY_STRESS: '/user-profile/nutrition_diary/stress',
  NUTRITION_DIARY_OTHER: '/user-profile/nutrition_diary/other',
  NUTRITION_PLAN: '/user-profile/nutrition-plan',
  NUTRITION_PLAN_ID: (id = ':id'): string => `/user-profile/nutrition-plan/${id}`,
  USER_SUBSCRIPTIONS: '/user-profile/subscriptions',
  SETTINGS: '/user-settings',
  RECOMMENDATIONS: '/user-profile/recommendations',
  NOTIFICATIONS: '/user-profile/notifications',
  EDIT_ANALYSIS: (id = ':id'): string => `/analysis/${id}/edit`,
};
