import classnames from 'classnames';
import { ChangeEvent, CSSProperties, FC, FocusEvent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import styles from './styles.module.scss';

export interface InputDefaultProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  value: string;
  required?: boolean;
  type?: 'text';
  error?: 'error' | 'warning';
  customStyles?: CSSProperties;
  placeholder?: string;
  min?: number;
  max?: number;
  minRows?: number;
  maxRows?: number;
  isBig?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  customClassName?: string;
}

export const TextArea: FC<InputDefaultProps> = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  rows = 2,
  error,
  minRows,
  maxRows = 10,
  customStyles,
  placeholder,
  customClassName,
  isBig,
  onKeyDown,
}) => {
  return (
    <>
      {disabled ? (
        <div className={classnames(styles['text-area__wrapper'], customClassName)}>
          <span style={customStyles} className={styles['span']}>
            {value}
          </span>
        </div>
      ) : (
        <TextareaAutosize
          style={{ ...customStyles, height: 52 }}
          minRows={minRows}
          maxRows={maxRows}
          rows={rows}
          className={classnames(styles['text-area'], customClassName, isBig && styles['text-area__big'], {
            [styles[`text-area_${error}`]]: error,
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault();
              onKeyDown && onKeyDown(e);
            }
          }}
        />
      )}
    </>
  );
};
