import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { BaseModal } from 'components';
import { selectDeleteAnalysisStatus } from 'features/analyzes/selectors';
import { deleteAnalysis, deleteExpertAnalysis } from 'features/analyzes/thunks';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActivePatient } from 'features/expert/selectors';
import { findMeAsync, getRecommendationsAsync } from 'features/patient/thunks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppSelector } from 'store/reducers';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ExpertRoutes, PatientRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type RemoveAccountModalProps = {
  isModalOpened: boolean;
  onCloseModal: () => void;
  analysisId: string;
};

export const DeleteAnalysisModal: FC<RemoveAccountModalProps> = ({ isModalOpened, onCloseModal, analysisId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'editAnalysis' });

  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;
  const activePatientData = useSelector(selectActivePatient);
  const deleteAnalysisLoading = useAppSelector(selectDeleteAnalysisStatus) === LoadingStatus.pending;
  const history = useHistory();

  const handleClick = async () => {
    if (isPatient) {
      await dispatch(deleteAnalysis(analysisId))
        .then(unwrapResult)
        .then(() => {
          batch(() => {
            dispatch(getRecommendationsAsync());
            dispatch(findMeAsync());
          });
          notify('success', t('notifySuccessDelete'));
          onCloseModal();
          history.push(PatientRoutes.RECOMMENDATIONS);
        })
        .catch((error) => {
          notify('error', error.message);
        });
    } else {
      await dispatch(deleteExpertAnalysis({ patientId: activePatientData.id, analysisId: analysisId }));
      notify('success', t('notifySuccessDelete'));
      onCloseModal();
      history.push(ExpertRoutes.PATIENT_RECOMMENDATIONS(activePatientData.id));
    }
  };

  return (
    <BaseModal title={t('deleteModalTitle') as string} isVisible={isModalOpened} onClose={onCloseModal}>
      <div className={classNames('body body_medium', styles['remove-account__text'])}>{t('confirmDelete')}</div>
      <div className={classNames('flexbox', 'justify-between')}>
        <ButtonDefault
          text={t('cancel')}
          variant="secondary"
          onClick={onCloseModal}
          customStyles={{ margin: 0 }}
          containerClassNames={styles['remove-account__button']}
        />
        <ButtonDefault
          text={t('delete')}
          onClick={handleClick}
          isLoading={deleteAnalysisLoading}
          customStyles={{ backgroundColor: 'var(--red)', margin: 0 }}
          containerClassNames={styles['remove-account__button']}
        />
      </div>
    </BaseModal>
  );
};
