import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Checkup, CheckupResDto } from 'api/generated';
import { LoadingStatus } from 'types';

import {
  getExpertCheckupsAsync,
  getOneExpertCheckupAsync,
  getOnePatientCheckupAsync,
  getPatientCheckupsAsync,
} from './thunks';
import { CheckupsStore } from './types';

const initialState: CheckupsStore = {
  error: null,
  checkups: [] as CheckupResDto[],
  checkupsLoadingStatus: LoadingStatus.idle,
  checkup: {} as Checkup,
  checkupLoadingStatus: LoadingStatus.idle,
};

const CheckupsSlice = createSlice({
  initialState,
  name: 'checkups',
  reducers: {},
  extraReducers: {
    [getPatientCheckupsAsync.fulfilled.type]: (state, { payload }: PayloadAction<CheckupResDto[]>) => {
      state.checkupsLoadingStatus = LoadingStatus.idle;
      state.checkups = payload;
    },
    [getPatientCheckupsAsync.pending.type]: (state) => {
      if (state.checkupsLoadingStatus === LoadingStatus.idle) {
        state.checkupsLoadingStatus = LoadingStatus.pending;
      }
    },
    [getPatientCheckupsAsync.rejected.type]: (state, { payload }) => {
      state.checkupsLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getOnePatientCheckupAsync.fulfilled.type]: (state, { payload }: PayloadAction<Checkup>) => {
      state.checkupLoadingStatus = LoadingStatus.idle;
      state.checkup = payload;
    },
    [getOnePatientCheckupAsync.pending.type]: (state) => {
      if (state.checkupLoadingStatus === LoadingStatus.idle) {
        state.checkupLoadingStatus = LoadingStatus.pending;
      }
    },
    [getOnePatientCheckupAsync.rejected.type]: (state, { payload }) => {
      state.checkupLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getExpertCheckupsAsync.fulfilled.type]: (state, { payload }: PayloadAction<CheckupResDto[]>) => {
      state.checkupsLoadingStatus = LoadingStatus.idle;
      state.checkups = payload;
    },
    [getExpertCheckupsAsync.pending.type]: (state) => {
      if (state.checkupsLoadingStatus === LoadingStatus.idle) {
        state.checkupsLoadingStatus = LoadingStatus.pending;
      }
    },
    [getExpertCheckupsAsync.rejected.type]: (state, { payload }) => {
      state.checkupsLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [getOneExpertCheckupAsync.fulfilled.type]: (state, { payload }: PayloadAction<Checkup>) => {
      state.checkupLoadingStatus = LoadingStatus.idle;
      state.checkup = payload;
    },
    [getOneExpertCheckupAsync.pending.type]: (state) => {
      if (state.checkupLoadingStatus === LoadingStatus.idle) {
        state.checkupLoadingStatus = LoadingStatus.pending;
      }
    },
    [getOneExpertCheckupAsync.rejected.type]: (state, { payload }) => {
      state.checkupLoadingStatus = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const actions = {
  ...CheckupsSlice.actions,
};

export const { reducer } = CheckupsSlice;
