import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpertAuthResDto, PatientAuthResDto } from 'api/generated';
import { patientSendRestorePasswordLinkAsync } from 'features/patient/thunks';

import { LoadingStatus } from '../../types';
import {
  expertGetVerificationCodeAsync,
  expertSendRestorePasswordLinkAsync,
  expertSignInAsync,
  expertSignUpAsync,
  patientGetVerificationCodeAsync,
  patientSignInAsync,
  patientSignUpAsync,
} from './thunks';
import { RolesEnum } from './types';
import { AuthStore } from './types';

const initialState: AuthStore = {
  token: null,
  loading: LoadingStatus.idle,
  error: null,
  currentRequestId: undefined,
  email: null,
  role: null,
};
const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    signUp: (state, action) => {
      state.token = action.payload;
    },
    signIn: (state, action) => {
      state.token = action.payload;
    },
    signOut: () => initialState,
  },
  extraReducers: {
    [expertSignInAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [expertSignInAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertAuthResDto>) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
      state.token = payload.accessToken;
      state.role = RolesEnum.EXPERT;
    },
    [patientSignInAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientAuthResDto>) => {
      state.loading = LoadingStatus.idle;
      state.token = payload.accessToken;
      state.role = RolesEnum.PATIENT;
    },
    [patientSignInAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [patientSignInAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [patientSignUpAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [patientSignUpAsync.fulfilled.type]: (state, { payload }: PayloadAction<PatientAuthResDto>) => {
      state.loading = LoadingStatus.idle;
      state.token = payload.accessToken;
      state.role = RolesEnum.PATIENT;
    },
    [patientSignUpAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [expertSignInAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [expertSignUpAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [expertSignUpAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertAuthResDto>) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
      state.token = payload.accessToken;
      state.role = RolesEnum.EXPERT;
    },
    [expertSignUpAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [expertGetVerificationCodeAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [expertGetVerificationCodeAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
    },
    [expertGetVerificationCodeAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [patientGetVerificationCodeAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [patientGetVerificationCodeAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
    },
    [patientGetVerificationCodeAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [expertSendRestorePasswordLinkAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [expertSendRestorePasswordLinkAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
    },
    [expertSendRestorePasswordLinkAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
    [patientSendRestorePasswordLinkAsync.pending.type]: (state, { meta }) => {
      if (state.loading === LoadingStatus.idle) {
        state.loading = LoadingStatus.pending;
        state.currentRequestId = meta.requestId;
      }
    },
    [patientSendRestorePasswordLinkAsync.fulfilled.type]: (state) => {
      state.loading = LoadingStatus.idle;
      state.currentRequestId = undefined;
    },
    [patientSendRestorePasswordLinkAsync.rejected.type]: (state, { payload }) => {
      state.loading = LoadingStatus.idle;
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, statusCode: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});
export const actions = {
  ...authSlice.actions,
};
export const { reducer } = authSlice;
