import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoadingStatus } from 'features/auth/selectors';
import { expertSendRestorePasswordLinkAsync } from 'features/auth/thunks';
import { patientSendRestorePasswordLinkAsync } from 'features/patient/thunks';
import { AuthLayout } from 'layouts';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { UnauthorizedRoutes } from 'types/routes';
import { ButtonDefault, FormError, FormLabel, InputDefault } from 'UIcomponents';
import { LinkLocationState } from 'UIcomponents/FormLabel/FormLabel';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface FormValues {
  email: string;
}

export const ForgotPassword: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const history = useHistory();
  const { state } = useLocation<LinkLocationState>();

  const onSubmit = async (data: FormValues) => {
    if (state === 'expert') {
      return await dispatch(expertSendRestorePasswordLinkAsync(data))
        .then(unwrapResult)
        .then(() => {
          notify('success', t('passwordSuccess'));
        })
        .catch((error) => {
          notify('error', error.message);
        })
        .finally(() => reset({}));
    }
    if (state === 'patient') {
      return await dispatch(patientSendRestorePasswordLinkAsync(data))
        .then(unwrapResult)
        .then(() => {
          notify('success', t('passwordSuccess'));
        })
        .catch((error) => {
          notify('error', error.message);
        })
        .finally(() => reset({}));
    }
  };

  if (!state) {
    return <Redirect to={UnauthorizedRoutes.SIGN_IN} />;
  }
  return (
    <AuthLayout
      title={t('passwordRecovery') as string}
      backOptions={{ onClick: history.goBack, text: t('goBack') as string }}>
      <div className={styles['forgot-password']}>
        <div className={styles['forgot-password__header']}>
          <span className={`body body_medium ${styles['forgot-password__subtitle']}`}>
            {t('passwordRecoveryInstruction1')}
            <br /> {t('passwordRecoveryInstruction2')}
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['forgot-password__field']}>
            <FormLabel text={t('email')} />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.email,
                  message: t('emailPattern'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputDefault
                  onChange={(e) => {
                    const value = e.target.value;
                    const trimValue = value.trim();
                    e.target.value = trimValue;
                    onChange(e);
                  }}
                  value={value}
                  error={errors.email && 'error'}
                />
              )}
            />
            {errors.email?.message && <FormError type="error" text={errors.email.message} />}
          </div>
          <ButtonDefault
            text={t('resetPassword')}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </form>
      </div>
    </AuthLayout>
  );
};
