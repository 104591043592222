import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { BiomarkersStore } from './types';

export const selectBiomarkersStore = (state: RootState): BiomarkersStore => state.biomarkers;

export const selectBiomarkersLoading = createSelector(
  selectBiomarkersStore,
  (biomarkersStore) => biomarkersStore.loading,
);
export const selectCheckUpsLoading = createSelector(
  selectBiomarkersStore,
  (biomarkersStore) => biomarkersStore.checkUpsLoading,
);
export const selectBiomarkers = createSelector(selectBiomarkersStore, (biomarkersStore) => biomarkersStore.biomarkers);

export const selectUnitsCoefficients = createSelector(
  selectBiomarkersStore,
  (biomarkersStore) => biomarkersStore.unitsCoefficients,
);
