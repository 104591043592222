import classnames from 'classnames';
import styles from 'layouts/PatientDiaryLayout/styles.module.scss';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'UIcomponents';

type Props = {
  isInputsDisabled?: boolean;
};

export const OtherTab: React.FC<Props> = ({ isInputsDisabled = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary.otherTab' });

  const { control } = useFormContext();
  return (
    <div className={styles['simple-section']}>
      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
          {t('otherInfo')} <span className={styles['text-area__title_grey']}>({t('caption')})</span>
        </p>
        <Controller
          control={control}
          name={'other.other'}
          render={({ field: { onChange, value } }) => (
            <TextArea value={value} onChange={onChange} minRows={4} disabled={isInputsDisabled} />
          )}
        />
      </div>

      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
          {t('selfPleasure')} <span className={styles['text-area__title_grey']}>({t('selfPleasureCaption')})</span>
        </p>
        <Controller
          control={control}
          name={'other.selfPleasure'}
          render={({ field: { onChange, value } }) => (
            <TextArea value={value} onChange={onChange} minRows={4} disabled={isInputsDisabled} />
          )}
        />
      </div>
    </div>
  );
};
