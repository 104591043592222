import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationRu from './locales/ru/translation.json';

export enum LanguageType {
  EN = 'en',
  RU = 'ru',
}

const resources = {
  [LanguageType.EN]: {
    translation: translationEn,
  },
  [LanguageType.RU]: {
    translation: translationRu,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: LanguageType.RU,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
