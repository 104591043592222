import * as React from 'react';

const CalendarHeaderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 2h-2.25v-.75a.75.75 0 1 0-1.5 0V2h-7.5v-.75a.75.75 0 0 0-1.5 0V2H1.5A1.501 1.501 0 0 0 0 3.5v15A1.501 1.501 0 0 0 1.5 20h15a1.501 1.501 0 0 0 1.5-1.5v-15A1.502 1.502 0 0 0 16.5 2Zm-3.61 9.545L8.514 15.67a.75.75 0 0 1-1.03-.001l-2.376-2.25a.751.751 0 1 1 1.032-1.09L8 14.093l3.86-3.638a.75.75 0 1 1 1.028 1.091ZM16.5 6.5h-15v-3h2.25v.75a.75.75 0 0 0 1.5 0V3.5h7.5v.75a.75.75 0 1 0 1.5 0V3.5h2.25v3Z"
      fill="#8E8E8E"
    />
  </svg>
);

export default CalendarHeaderIcon;
