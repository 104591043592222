import { HorizontalArrowIcon } from 'assets/svg';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

type LinkBackProps = {
  pathname: string;
  allowGoBack?: boolean;
  customStyles?: CSSProperties;
  backFunction?: () => void;
};

export const LinkBack: FC<LinkBackProps> = ({ pathname, allowGoBack = false, customStyles, backFunction }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui' });

  const history = useHistory();

  return (
    <button
      onClick={backFunction ? backFunction : () => (allowGoBack ? history.goBack() : history.push(pathname))}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (backFunction) {
            backFunction();
          } else {
            allowGoBack ? history.goBack() : history.push(pathname);
          }
        }
      }}
      className={styles['link-back']}
      style={customStyles}>
      <HorizontalArrowIcon />
      <p className={classNames('body', 'body_medium')}>{t('goBack')}</p>
    </button>
  );
};
