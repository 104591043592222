import { ExpertGetPaymentsDiaryDto } from 'api/generated';
import { CloseIcon } from 'assets/svg';
import { TableColumType, UserAvatar } from 'components';
import { actions } from 'features/expert/slice';
import { deletePaymentsDiaryAsync, togglePaymentsDiaryAsync, toggleSwitch } from 'features/expert/thunks';
import moment from 'moment';
import { FC } from 'react';
import { useAppDispatch } from 'store/reducers';
import { SwitchButton } from 'UIcomponents';

interface TableRowProps {
  sorting: (sortName: 'name' | 'date' | 'amount' | 'paid', sortDirection: 'ASC' | 'DESC') => void;
  data: ExpertGetPaymentsDiaryDto;
  columns: TableColumType[];
}

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const TableRow: FC<TableRowProps> = ({ data, columns, sorting }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.paymentsDiaryTab' });

  const { patientName, date, amount, paid, id, patientAvatar } = data;
  const dispatch = useAppDispatch();

  const toggle = () => {
    dispatch(togglePaymentsDiaryAsync(id));
    dispatch(toggleSwitch(id));
  };

  const deletePayment = () => {
    dispatch(deletePaymentsDiaryAsync(id));
    dispatch(actions.deletePaymentsDiary(id));
  };

  const totalAspect = columns.reduce((acc, curr) => acc + curr.aspect, 0);

  return (
    <div className={styles.table__row}>
      <div className={styles.table__cell} style={{ width: `calc(100% / ${totalAspect} * ${columns[0].aspect})` }}>
        <UserAvatar name={patientName} avatar={patientAvatar} />
      </div>
      <div className={styles.table__cell} style={{ width: `calc(100% / ${totalAspect} * ${columns[1].aspect})` }}>
        <p className="body body-medium">{moment(date).format('DD.MM.YYYY')}</p>
      </div>
      <div className={styles.table__cell} style={{ width: `calc(100% / ${totalAspect} * ${columns[2].aspect})` }}>
        <p className="body body-medium" style={{ color: '#404040' }}>
          {amount}
        </p>
      </div>
      <div className={styles.table__cell} style={{ width: `calc(100% / ${totalAspect} * ${columns[3].aspect})` }}>
        <SwitchButton
          id={id}
          defaultValue={paid}
          onChange={toggle}
          labelOptions={{ checkedText: t('paid'), uncheckedText: t('notPaid') }}
          onColor="#35B185"
        />
      </div>
      <button className={styles.table__button_delete} onClick={deletePayment}>
        <CloseIcon />
      </button>
    </div>
  );
};
