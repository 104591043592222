import { unwrapResult } from '@reduxjs/toolkit';
import { UpdatePatientAnalysisDto } from 'api/generated';
import { CloseIcon } from 'assets/svg';
import { AnalysisForm } from 'components/AnalysisForm';
import { selectAnalysisById } from 'features/analyzes/selectors';
import { updateAnalysisAsync, updateExpertAnalysisAsync } from 'features/analyzes/thunks';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActivePatient } from 'features/expert/selectors';
import { getAnalysisExpertIdAsync } from 'features/expert/thunks';
import { findMeAsync, getRecommendationsAsync } from 'features/patient/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { LayoutWithBackOptions } from 'layouts';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault, IconButton, Spinner } from 'UIcomponents';

import { DeleteAnalysisModal } from './components';

export const EditAnalysis: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'editAnalysis' });

  const [modalOpened, setModalOpened] = useState(false);
  const { id: analysisId } = useParams<{ id: string }>();

  const analysis = useSelector(selectAnalysisById(analysisId));

  const [analysisData, setAnalysis] = useState(analysis);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<{ fromHistory?: boolean }>();
  const width = useWindowWidth();

  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;
  const activePatientData = useSelector(selectActivePatient);

  const onSubmit = async (data: UpdatePatientAnalysisDto): Promise<void> => {
    const parsedData = {
      id: analysisId,
      analysisData: {
        date: data.date.toString(),
        patientAnalysisBiomarkers: data.patientAnalysisBiomarkers,
      },
    };

    if (isPatient) {
      await dispatch(updateAnalysisAsync(parsedData))
        .then(unwrapResult)
        .then(() => {
          batch(() => {
            dispatch(getRecommendationsAsync());
            dispatch(findMeAsync());
          });

          notify('success', t('notifySuccess'));
          history.push(CommonRoutes.ANALYSIS(analysisId));
        })
        .catch((error) => {
          notify('error', error.message);
        });
    } else {
      await dispatch(
        updateExpertAnalysisAsync({ patientId: activePatientData.id, analysisId: analysisId, analysisData: data }),
      );
      notify('success', t('notifySuccess') as string);
      history.push(CommonRoutes.ANALYSIS(analysisId));
    }
  };

  const getAnalyzesExpertById = async () => {
    await dispatch(getAnalysisExpertIdAsync(analysisId))
      .then(unwrapResult)
      .then((result) => {
        setAnalysis(result.currentAnalysis);
      });
  };

  useEffect(() => {
    if (!isPatient) {
      getAnalyzesExpertById();
    }
  }, []);

  return (
    <LayoutWithBackOptions
      additionalNode={{
        node:
          width > 600 ? (
            <ButtonDefault text={t('delete')} onClick={() => setModalOpened(true)} variant="delete" />
          ) : (
            <IconButton
              variant="secondary"
              icon={<CloseIcon />}
              onClick={() => setModalOpened(true)}
              containerClassNames="flexbox-inline justify-center align-center"
            />
          ),
        position: 'above-title',
      }}
      backOptions={{
        text: t('editAnalysis'),
        backFunction: () =>
          history.push(CommonRoutes.ANALYSIS(analysisId), {
            fromEdit: true,
            fromHistory: location.state?.fromHistory,
          }),
      }}>
      {analysisData ? <AnalysisForm analysis={analysisData} onSubmitUpdate={onSubmit} /> : <Spinner />}
      <DeleteAnalysisModal
        isModalOpened={modalOpened}
        onCloseModal={() => setModalOpened(false)}
        analysisId={analysisId}
      />
    </LayoutWithBackOptions>
  );
};
