import { LogoBiogeekExpert, LogoBiogeekPatient } from 'assets/svg';
import classNames from 'classnames';
import { selectToken } from 'features/auth/selectors';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonDefault, LanguageDropdown } from 'UIcomponents';

import styles from './styles.module.scss';

type HeaderDefaultProps = {
  withNewAnalysisButton?: boolean;
  isPatient?: boolean;
};

export const HeaderDefault: FC<HeaderDefaultProps> = ({ withNewAnalysisButton = true, isPatient = true }) => {
  const isAuth = useSelector(selectToken);

  return (
    <header className={classNames('flexbox', 'justify-between', 'align-center', styles.header)}>
      <div className={classNames('flexbox', 'justify-start', 'align-center', styles.brand)}>
        <div className={classNames('flexbox', styles.logo)}>
          <Link to="/">{isPatient ? <LogoBiogeekPatient /> : <LogoBiogeekExpert />}</Link>
        </div>
      </div>
      <div className={classNames('flexbox', styles['header-content'])}>
        {isAuth && withNewAnalysisButton && (
          <ButtonDefault text="Добавить анализ" customStyles={{ width: '200px', marginRight: '60px' }} />
        )}
      </div>
      <LanguageDropdown />
    </header>
  );
};
