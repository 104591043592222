import { FileIcon } from 'assets/svg';
import classNames from 'classnames';
import { EmptyTabsContent } from 'components';
import { BaseModal } from 'components/BaseModal';
import { selectAttachments } from 'features/chat/selectors';
import { useClientSize } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { File } from '../File';
import styles from './styles.module.scss';

interface FilesModalProps {
  onClose: () => void;
}

export const FilesModal: FC<FilesModalProps> = ({ onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'filesModal' });
  const attachments = useSelector(selectAttachments);

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <BaseModal
      customStyles={{
        marginTop: '120px',
        marginBottom: '120px',
        paddingTop: isMobile ? '24px' : '60px',
      }}
      title={t('historyFiles') as string}
      onClose={onClose}
      isVisible={true}>
      <div className={classNames(styles['files-modal'], attachments?.length < 1 && styles['files-modal__empty'])}>
        {attachments?.length > 0 ? (
          attachments.map((files, index) => <File key={index} {...files} />)
        ) : (
          <EmptyTabsContent
            icon={<FileIcon fill="#DADADA" color="#8E8E8E" width="40" height="40" />}
            text={t('emptyHistory')}
            className={styles['empty-container']}
          />
        )}
      </div>
    </BaseModal>
  );
};
