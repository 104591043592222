import classNames from 'classnames';
import { selectisNewlyPrescribedPlan } from 'features/patient/selectors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PatientRoutes } from 'types/routes';

import styles from '../../styles.module.scss';

type TabTitleProps = {
  title: string;
  index: number;
  isActive: boolean;
};

const RouteMap = [
  PatientRoutes.PROFILE,
  PatientRoutes.PROFILE_SETTINGS,
  PatientRoutes.ANAMNESIS,
  PatientRoutes.NUTRITION_DIARY,
  PatientRoutes.NUTRITION_PLAN,
  PatientRoutes.RECOMMENDATIONS,
  PatientRoutes.USER_SUBSCRIPTIONS,
  PatientRoutes.NOTIFICATIONS,
];

export const TabTitle: FC<TabTitleProps> = ({ title, index, isActive }) => {
  const isNewlyPrescribedPlan = useSelector(selectisNewlyPrescribedPlan);
  const { t } = useTranslation('translation', { keyPrefix: 'patient.settingsTabs' });

  return (
    <Link
      to={RouteMap[index]}
      className={classNames(
        styles['patient-profile-tabs__tab'],
        isActive && styles['patient-profile-tabs__tab--active'],
      )}>
      <div className={styles['tab-content__container']}>
        {title}
        {isNewlyPrescribedPlan && title === t('nutrition') && <div className={styles.notify} />}
      </div>
    </Link>
  );
};
