import { PatientRecommendationCardsGetResDto } from 'api/generated';
import classnames from 'classnames';
import { CSSProperties, FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { BiometricExampleCard, RecommendationCard, SubscribeBiometricCard } from 'UIcomponents';

import styles from '../../styles.module.scss';

interface EmptyRecommendationsCardProps {
  customStyles?: CSSProperties;
  hasSubscription?: boolean;
  hasRecomendation?: boolean;
  recommendations?: PatientRecommendationCardsGetResDto;
}

export const EmptyRecommendationsCard: FC<EmptyRecommendationsCardProps> = ({
  customStyles,
  hasSubscription = false,
  hasRecomendation = false,
  recommendations,
}) => {
  const firstTypeCards = recommendations?.firstTypeCards;
  return (
    <>
      <ReactTooltip id="main" className={styles.tooltip} arrowColor="transparent" place="right" />

      <div className={styles['dashboard__attentions']} style={customStyles}>
        {hasRecomendation ? (
          <div
            className={classnames(
              styles['dashboard-recommendations__card'],
              styles['dashboard-recommendations__card-single-item'],
            )}>
            {firstTypeCards && <RecommendationCard recommendation={firstTypeCards[0]} type="light" showTooltip />}
          </div>
        ) : (
          <BiometricExampleCard />
        )}
        {!hasSubscription && <SubscribeBiometricCard />}
      </div>
    </>
  );
};
