import * as React from 'react';

function DocumentCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.83317 2.33325H16.1172C16.4719 2.33325 16.8151 2.45894 17.0859 2.68799L22.8019 7.52275C23.1388 7.80776 23.3332 8.22669 23.3332 8.66801V23.4305C23.3332 25.5194 23.3093 25.6666 21.1665 25.6666H6.83317C4.69036 25.6666 4.6665 25.5194 4.6665 23.4305V4.56936C4.6665 2.4804 4.69036 2.33325 6.83317 2.33325Z"
        fill="#D9E5FF"
      />
      <rect x="7" y="12.8333" width="10.5" height="2.33333" rx="1" fill="#A1A7FF" />
      <rect x="7" y="17.5" width="5.83333" height="2.33333" rx="1" fill="#A1A7FF" />
    </svg>
  );
}

export default DocumentCheck;
