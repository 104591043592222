import React, { CSSProperties, FC } from 'react';
import InputMask from 'react-input-mask';
import { AllowedMasks } from 'types/Input';
import { InputDefault } from 'UIcomponents';
import { InputDefaultProps } from 'UIcomponents/InputDefault';

interface MaskedFieldProps extends InputDefaultProps {
  mask: AllowedMasks | string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  customStyles?: CSSProperties;
  formatChars?: Record<string, string>;
}

export const MaskedInput: FC<MaskedFieldProps> = (props) => {
  const {
    mask,
    maskChar = '',
    alwaysShowMask = false,
    disabled,
    onChange,
    onFocus,
    onBlur,
    value,
    readOnly,
    formatChars,
    ...rest
  } = props;
  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      readOnly={readOnly}
      formatChars={formatChars}>
      {() => <InputDefault disabled={disabled} {...rest} />}
    </InputMask>
  );
};
