import * as React from 'react';

function Eng(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_15532_402278)">
        <g clip-path="url(#clip1_15532_402278)">
          <path d="M-2.56152 0.418518V15.5838H24.038V0.418518H-2.56152Z" fill="#012169" />
          <path
            d="M-2.56152 0.418518L24.038 15.5838L-2.56152 0.418518ZM24.038 0.418518L-2.56152 15.5824Z"
            fill="black"
          />
          <path
            d="M23.4443 16.9401L10.7389 9.69562L-1.96647 16.9401L-3.15527 14.2248L7.76445 7.99979L-3.15527 1.77479L-1.96647 -0.940491L10.7389 6.30395L23.4443 -0.940491L24.6331 1.77479L13.7134 7.99979L24.6331 14.2248L23.4443 16.9401Z"
            fill="white"
          />
          <path
            d="M-2.56152 0.418518L24.038 15.5838L-2.56152 0.418518ZM24.038 0.418518L-2.56152 15.5824Z"
            fill="black"
          />
          <path
            d="M23.6415 16.4871L10.7389 9.12881L-2.16385 16.4871L-2.95801 14.6774L8.7571 7.99965L-2.95801 1.32187L-2.16385 -0.487854L10.7389 6.87048L23.6415 -0.487854L24.4357 1.32187L12.7206 7.99965L24.4357 14.6774L23.6415 16.4871Z"
            fill="#C8102E"
          />
          <path d="M10.7383 0.418518V15.5838V0.418518ZM-2.56152 8.00046H24.038Z" fill="black" />
          <path
            d="M12.9551 15.5824H8.52142V10.5282H-2.56152V5.47268H8.52142V0.418518H12.9551V5.47268H24.038V10.5282H12.9551V15.5824Z"
            fill="white"
          />
          <path d="M10.7383 0.418518V15.5838V0.418518ZM-2.56152 8.00046H24.038Z" fill="black" />
          <path
            d="M12.0683 15.5824H9.40815V9.51713H-2.56152V6.4838H9.40815V0.418518H12.0683V6.4838H24.038V9.51713H12.0683V15.5824Z"
            fill="#C8102E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_15532_402278">
          <rect y="0.5" width="21" height="15" rx="2" fill="white" />
        </clipPath>
        <clipPath id="clip1_15532_402278">
          <rect width="21" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Eng;
