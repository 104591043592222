import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertResDto } from 'api/generated';
import { Expert, FullInfoAboutExpertModal } from 'components';
import { selectExpertsSearchStatus } from 'features/patient/selectors';
import { searchExpertsAsync } from 'features/patient/thunks';
import { useToggle } from 'hooks';
import { LayoutWithBackOptions } from 'layouts';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VirtuosoGrid, VirtuosoHandle } from 'react-virtuoso';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { Spinner } from 'UIcomponents';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';
import { getRandomInt } from 'utils/getRandomInt';

import styles from './styles.module.scss';

type PaginationState = {
  page: number;
  pageCount: number;
};

export const Experts: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const dispatch = useAppDispatch();
  const ref = useRef<VirtuosoHandle>(null);

  const expertsSearchStatus = useAppSelector(selectExpertsSearchStatus);

  const [experts, setExperts] = useState<Array<ExpertResDto>>([]);
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, pageCount: 0 });

  const randomSeedForShuffle = useMemo(() => getRandomInt(99), []);

  const { isOpened: isExpertInfoModalOpen, onToggle: toggleExpertInfoModal } = useToggle();

  const [clickedExpertData, setClickedExpertData] = useState({ id: '', isRequestInPending: false, index: 0 });

  const handleExpertCardClick = (id: string, isRequestInPending: boolean, index: number) => {
    setClickedExpertData({ id: id, isRequestInPending: isRequestInPending, index: index });
    toggleExpertInfoModal();

    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.EXPERT_INFORMATION_VIEWED);
  };

  const getExperts = async (newPage: number) => {
    await dispatch(searchExpertsAsync({ page: newPage, limit: 12, seedForShuffle: randomSeedForShuffle }))
      .then(unwrapResult)
      .then((result) => {
        const { page, pageCount, data } = result;
        setExperts(newPage === 1 ? data : experts.concat(data));
        setPagination({ page, pageCount });
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const isLoading = expertsSearchStatus === LoadingStatus.pending && experts.length === 0;

  useEffect(() => {
    getExperts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBottomStateChange = (atBottom: boolean) => {
    if (atBottom && pagination.page < pagination.pageCount) {
      setPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }));
      getExperts(pagination.page + 1);
    }
  };

  useEffect(() => {
    //Metrics
    analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.EXPERTS_PAGE_VIEWED);
  }, []);

  return (
    <LayoutWithBackOptions
      backOptions={{
        text: t('experts'),
      }}>
      {isLoading ? (
        <div className={styles.waiting}>
          <Spinner color="var(--background-primary)" variant="primary" radius={50} />
        </div>
      ) : (
        <VirtuosoGrid
          ref={ref}
          overscan={720}
          style={{
            height: 720,
          }}
          totalCount={experts.length}
          listClassName={styles.experts__listing}
          itemContent={(index) => (
            <Expert
              key={index}
              expert={experts[index]}
              onClickCallBack={() => handleExpertCardClick(experts[index].id, experts[index].requestInPending, index)}
              isFullInfoModalOpened={clickedExpertData.id === experts[index].id ? isExpertInfoModalOpen : false}
            />
          )}
          atBottomStateChange={(atBottom) => handleBottomStateChange(atBottom)}
        />
      )}
      {isExpertInfoModalOpen && (
        <FullInfoAboutExpertModal
          myExpert={false}
          isModalOpened={isExpertInfoModalOpen}
          onCloseModal={toggleExpertInfoModal}
          expertId={clickedExpertData.id}
          isRequestInPending={clickedExpertData.isRequestInPending}
          setExpertsCallback={setExperts}
          experts={experts}
          index={clickedExpertData.index}
        />
      )}
    </LayoutWithBackOptions>
  );
};
