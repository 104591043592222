import { unwrapResult } from '@reduxjs/toolkit';
import { CreatePatientAnalysisDto } from 'api/generated';
import { AnalysisForm } from 'components/AnalysisForm/AnalysisForm';
import { actions } from 'features/analyzes';
import { createAnalysisAsync, createAnalysisExpertAsync, searchAnalyzesAsync } from 'features/analyzes/thunks';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActivePatient } from 'features/expert/selectors';
import { findMeAsync, getRecommendationsAsync } from 'features/patient/thunks';
import { LayoutWithBackOptions } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { analytics } from 'services/analytics';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { SortDirectionFilter } from 'types/analyzes';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { CONSTANTS_AMPLITUDE } from 'utils/constantsAmplitude';

export const CreateAnalysis: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'createAnalysis' });

  const dispatch = useAppDispatch();

  const history = useHistory();

  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;
  const activePatientData = useSelector(selectActivePatient);

  const onSubmit = async (data: CreatePatientAnalysisDto): Promise<void> => {
    if (isPatient) {
      await dispatch(createAnalysisAsync(data))
        .then(unwrapResult)
        .then((data) => {
          batch(() => {
            dispatch(actions.setSelectedAnalysisId(data.id));
            dispatch(actions.setLastAnalysisId(data.id));
            dispatch(getRecommendationsAsync());
            dispatch(findMeAsync());
          });

          history.push(CommonRoutes.ANALYSIS(data.id));
          notify('success', t('notifySuccess'));
        })
        .catch((error) => {
          notify('error', error.message === 'Forbidden' ? t('buySubscription') : error.message);
        });

      await dispatch(searchAnalyzesAsync({ sortDirection: SortDirectionFilter.ASC, sortBy: 'date' })).catch((error) => {
        notify('error', error.message);
      });

      //Metrics
      analytics.trackEvent(CONSTANTS_AMPLITUDE.EVENT_NAMES.CLIENT.MANUAL_ANALYSIS_SUBMITTED);
    } else {
      await dispatch(createAnalysisExpertAsync({ id: activePatientData.id, userData: data }));
      history.push(ExpertRoutes.PATIENT_RECOMMENDATIONS(activePatientData.id));
      notify('success', t('notifySuccess'));
    }
  };

  return (
    <LayoutWithBackOptions backOptions={{ text: t('title'), url: CommonRoutes.NEW_ANALYSIS }}>
      <AnalysisForm onSubmitCreate={onSubmit} />
    </LayoutWithBackOptions>
  );
};
