import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertCreatePatientDtoGenderEnum, Patient } from 'api/generated';
import classNames from 'classnames';
import { patientInvitationAsync, sortPatientsAsync } from 'features/expert/thunks';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ButtonDefault, FormLabel, InputDefault, Spinner } from 'UIcomponents';

import TopUserInfoBlock from './components/TopUserInfoBlock';
import styles from './styles.module.scss';

type PatientInfoTabProps = {
  patient: Patient;
  isLoading: LoadingStatus;
};

export const PatientInfoTab: FC<PatientInfoTabProps> = ({ patient, isLoading }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientInfoTab' });
  const dispatch = useAppDispatch();

  if (isLoading === 'pending' && isEmpty(patient)) {
    return (
      <div className={styles['user-info-form__loading']}>
        <Spinner radius={20} variant="secondary" color="var(--background-primary)" />
      </div>
    );
  }

  const onSubmit = async () => {
    await dispatch(
      patientInvitationAsync({
        name: patient.name,
        birthday: patient.birthday as string,
        phone: patient.phone ? patient.phone : undefined,
        gender:
          patient.gender === 'male' ? ExpertCreatePatientDtoGenderEnum.Male : ExpertCreatePatientDtoGenderEnum.Female,
        email: patient.email,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notify('success', t('patientAdded'));
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: check and fix after new api
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
          }),
        );
      })
      .catch((error) => {
        notify('error', error?.message);
      });
  };

  return (
    <>
      <TopUserInfoBlock patient={patient} />
      <form style={{ height: '100%' }}>
        <>
          <div className={classNames('flexbox', 'justify-between', styles['user-info-form'])}>
            <div className={classNames(styles['user-info-form__input'])}>
              <FormLabel text={t('labelFullName')} />
              <InputDefault value={patient.name || ''} disabled />
            </div>

            <div className={classNames(styles['user-info-form__input'])}>
              <FormLabel text={t('labelBirthdate')} />
              <InputDefault value={moment(patient.birthday).format('DD MMMM YYYY') || ''} disabled />
            </div>

            <div className={classNames(styles['user-info-form__input'])}>
              <FormLabel text={t('labelGender')} />
              <InputDefault value={patient.gender === 'male' ? 'Мужской' : 'Женский' || ''} disabled />
            </div>

            <div className={classNames(styles['user-info-form__input'])}>
              <FormLabel text={t('labelEmail')} />
              <InputDefault value={patient.email || ''} disabled />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <ButtonDefault
                  text={t('repeatedRequest')}
                  customStyles={{ maxWidth: 200, padding: '16px 17.5px', alignSelf: 'end', marginTop: 10 }}
                  onClick={onSubmit}
                  disabled={patient.isOnboardingCompleted}
                />
              </div>
            </div>

            <div className={classNames(styles['user-info-form__input'])}>
              <FormLabel text={t('labelPhone')} />
              <InputDefault value={patient.phone || ''} disabled />
            </div>
          </div>
        </>
      </form>
    </>
  );
};
