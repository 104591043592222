export const downloadBlob = (blob: Blob, filename: string): void => {
  const fileUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  anchor.setAttribute('style', 'display: none');
  anchor.href = fileUrl;
  anchor.download = filename;
  anchor.click();
  window.URL.revokeObjectURL(fileUrl);

  document.body.removeChild(anchor);
};
