import { selectTotalAnalyzesAmount } from 'features/analyzes/selectors';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';

import styles from './styles.module.scss';

export const BiometricExampleCard: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const totalAnalyzesAmount = useAppSelector(selectTotalAnalyzesAmount);
  const hasAnalyzes = totalAnalyzesAmount > 0;

  return (
    <div className={styles.card__container}>
      <div className={styles.card__content}>
        <div className={styles.card__header}>{t('cards')}</div>
        <p className={styles.card__body}>{t('cardsDescription')}</p>
      </div>
      <Link className={styles.card__link} to={CommonRoutes.NEW_ANALYSIS}>
        {hasAnalyzes ? t('loadMore') : t('loadFirst')}
      </Link>
    </div>
  );
};
