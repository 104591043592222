import {
  TrainingDurationEnum,
  TrainingTimeEnum,
  TrainingTrainingTypeEnum,
  WorkoutStepsPerDayEnum,
} from 'api/generated';
import { CloseIconCircle } from 'assets/svg';
import classnames from 'classnames';
import { TableColumType, TableHeader } from 'components';
import styles from 'layouts/PatientDiaryLayout/styles.module.scss';
import React, { useContext } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropdownOptions } from 'types';
import { ButtonDefault, DropdownDefault, FormError, InputDefault, TextArea } from 'UIcomponents';

import Context from '../Context';

type Props = {
  isInputsDisabled?: boolean;
};

export const WorkoutTab: React.FC<Props> = ({ isInputsDisabled = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary.workoutTab' });

  const isResponsive = useContext(Context);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, remove, append } = useFieldArray({ control, name: 'workout.trainings' });

  const trainingTypeOptions: DropdownOptions<TrainingTrainingTypeEnum>[] = [
    { label: t('cardio'), value: TrainingTrainingTypeEnum.Cardio },
    { label: t('yoga'), value: TrainingTrainingTypeEnum.Yoga },
    { label: t('stretching'), value: TrainingTrainingTypeEnum.Stretching },
    { label: t('strength'), value: TrainingTrainingTypeEnum.Strength },
    { label: t('tabata'), value: TrainingTrainingTypeEnum.Tabata },
  ];

  const trainingTimeOptions: DropdownOptions<TrainingTimeEnum>[] = [
    { label: t('beforeBreakfast'), value: TrainingTimeEnum.BeforeBreakfast },
    { label: t('afterBreakfast'), value: TrainingTimeEnum.AfterBreakfast },
    { label: t('afterLunch'), value: TrainingTimeEnum.AfterLunch },
    { label: t('afterDinner'), value: TrainingTimeEnum.AfterDinner },
  ];

  const trainingDurationOptions: DropdownOptions<TrainingDurationEnum>[] = [
    { label: `15-20 ${t('minutes')}`, value: TrainingDurationEnum.Twenty },
    { label: `30 ${t('minutes')}`, value: TrainingDurationEnum.HalfAnHour },
    { label: `45 ${t('minutes')}`, value: TrainingDurationEnum.FortyFive },
    { label: `1 ${t('hour')}`, value: TrainingDurationEnum.Hour },
    { label: t('moreThanAnHour'), value: TrainingDurationEnum.MoreThanHour },
  ];

  const stepsPerDayOptions: DropdownOptions<WorkoutStepsPerDayEnum>[] = [
    { label: t('steps5000'), value: WorkoutStepsPerDayEnum._5000 },
    { label: t('steps50008000'), value: WorkoutStepsPerDayEnum._50008000 },
    { label: t('steps800012000'), value: WorkoutStepsPerDayEnum._800012000 },
    { label: t('steps12000'), value: WorkoutStepsPerDayEnum._12000 },
  ];

  const columns: TableColumType[] = [
    { label: t('workoutType'), hasSorting: false, aspect: 0.6 },
    { label: t('workoutTime'), hasSorting: false, aspect: 0.7 },
    { label: t('workoutDuration'), hasSorting: false, aspect: 0.6 },
    { label: t('comments'), hasSorting: false, aspect: 1.2 },
  ];
  const totalAspect = columns.reduce((acc, curr) => acc + curr.aspect, 0);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.table__header}>
          <TableHeader columns={columns} />
        </div>

        <div className={styles.table__body}>
          {fields.map((item, index: number) => (
            <div key={item.id} className={styles.table__row}>
              {isResponsive && (
                <div className={styles.table__row_header}>
                  <p className={classnames('heading')}>{t('meal')}</p>
                  {fields.length !== 1 && (
                    <button className={styles['table__close-icon']} onClick={() => remove(index)}>
                      <CloseIconCircle fill="var(--dark-grey)" />
                    </button>
                  )}
                </div>
              )}
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[0].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>
                  {t('workoutType')}
                </p>
                <Controller
                  name={`workout.trainings.${index}.trainingType`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const index = trainingTypeOptions.findIndex((elem) => elem.value === value);
                    return (
                      <DropdownDefault
                        options={trainingTypeOptions}
                        selected={trainingTypeOptions[index]}
                        noOptionsMessage=""
                        isSearchable={false}
                        onChange={({ value }) => onChange(value)}
                        placeholder={t('name') as string}
                        disabled={isInputsDisabled}
                      />
                    );
                  }}
                />
                {errors?.[`workout.trainings[${index}].trainingType`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`workout.trainings[${index}].trainingType`]?.message}
                  />
                )}
              </div>
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[1].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>
                  {t('workoutTime')}
                </p>
                <Controller
                  name={`workout.trainings.${index}.time`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const index = trainingTimeOptions.findIndex((elem) => elem.value === value);
                    return (
                      <DropdownDefault
                        onChange={({ value }) => onChange(value)}
                        options={trainingTimeOptions}
                        selected={trainingTimeOptions[index]}
                        placeholder={t('time') as string}
                        noOptionsMessage=""
                        isSearchable={false}
                        disabled={isInputsDisabled}
                      />
                    );
                  }}
                />
                {errors?.[`workout.trainings[${index}].time`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`workout.trainings[${index}].time`]?.message}
                  />
                )}
              </div>
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[2].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>
                  {t('duration')}
                </p>
                <Controller
                  name={`workout.trainings.${index}.duration`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const index = trainingDurationOptions.findIndex((elem) => elem.value == value);
                    return (
                      <DropdownDefault
                        selected={trainingDurationOptions[index]}
                        options={trainingDurationOptions}
                        onChange={({ value }) => onChange(value)}
                        isSearchable={false}
                        noOptionsMessage=""
                        disabled={isInputsDisabled}
                      />
                    );
                  }}
                />
                {errors?.[`workout.trainings[${index}].duration`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`workout.trainings[${index}].duration`]?.message}
                  />
                )}
              </div>
              <div
                className={styles.table__cell}
                style={{
                  width: !isResponsive ? `calc(100% / ${totalAspect} * ${columns[3].aspect})` : '100%',
                }}>
                <p className={classnames('subhead', 'subhead-regular', styles['table__cell_subhead'])}>
                  {t('comments')}
                </p>
                <Controller
                  name={`workout.trainings.${index}.commentary`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={!isResponsive ? classnames('flexbox', 'align-center') : undefined}>
                      {!isResponsive ? (
                        <InputDefault
                          value={value}
                          onChange={onChange}
                          error={errors?.[`workout.trainings[${index}].commentary`] && 'error'}
                          disabled={isInputsDisabled}
                        />
                      ) : (
                        <TextArea
                          value={value}
                          onChange={onChange}
                          minRows={3}
                          error={errors?.[`workout.trainings[${index}].commentary`] && 'error'}
                          disabled={isInputsDisabled}
                        />
                      )}
                      {fields.length !== 1 && !isResponsive && (
                        <button className={styles['table__close-icon']} onClick={() => remove(index)}>
                          <CloseIconCircle fill="var(--dark-grey)" />
                        </button>
                      )}
                    </div>
                  )}
                />
                {errors?.[`workout.trainings[${index}].commentary`] && (
                  <FormError
                    type="error"
                    position="absolute"
                    text={errors?.[`workout.trainings[${index}].commentary`]?.message}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {fields.length < 10 && !isInputsDisabled && (
          <div className={styles.table__button}>
            <ButtonDefault
              text={t('add')}
              variant="secondary"
              customStyles={{ width: 152 }}
              onClick={() =>
                append({
                  trainingType: TrainingTrainingTypeEnum.Cardio,
                  time: TrainingTimeEnum.BeforeBreakfast,
                  duration: 1000,
                  commentary: '',
                })
              }
            />
          </div>
        )}

        <div className={styles['text-areas']}>
          <div style={{ width: '100%', maxWidth: '414px' }}>
            <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>{t('stepsPerDay')}</p>
            <Controller
              name={'workout.stepsPerDay'}
              control={control}
              render={({ field: { onChange, value } }) => {
                const index = stepsPerDayOptions.findIndex((elem) => elem.value === value);
                return (
                  <DropdownDefault
                    options={stepsPerDayOptions}
                    onChange={({ value }) => onChange(value)}
                    selected={stepsPerDayOptions[index]}
                    isSearchable={false}
                    noOptionsMessage=""
                    disabled={isInputsDisabled}
                    menuPlacement="top"
                  />
                );
              }}
            />
            {errors?.['workout.stepsPerDay'] && (
              <FormError type="error" text={errors?.['workout.stepsPerDay']?.message} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
