import '../../customCalendarStyles.css';

import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCalendarView } from '../../../../features/expert/selectors';
import styles from '../../styles.module.scss';

interface NewEventProps {
  begining: number;
  start: string;
  isChange: boolean;
  cost?: string;
  timeZone?: string;
  event?: string;
  patient: string;
  id: string;
}

interface EventWrapperProps {
  event: any;
  children: any;
  patientOptions: Array<{ label: string; value: string }>;
  setModalProps: (modalProps: NewEventProps) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
}

export const EventWrapper: React.ComponentType<EventWrapperProps> = ({
  event,
  children,
  setIsModalOpen,
  setModalProps,
  isModalOpen,
}: EventWrapperProps) => {
  const { title } = children.props;

  const actualView = useSelector(selectCalendarView);

  const getBeginningEvent = (startDate: Date, endDate: Date) => {
    return moment(endDate).unix() - moment(startDate).unix();
  };

  const toggleModal = () => {
    const begining = getBeginningEvent(event.start, event.end);

    setModalProps({
      start: event.start,
      begining: begining,
      isChange: false,
      cost: event.cost,
      timeZone: event.timeZone,
      id: event.id,
      patient: event.patientId,
      event: event.event,
    });
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <button onClick={toggleModal} className={styles.calendars__button}>
        <div
          title={title}
          className={classnames(
            'rbc-event',
            actualView === 'month' ? styles[`rbc-event--${event.event}__dot`] : styles[`rbc-event--${event.event}`],
          )}
          style={children.props.style}>
          <div className={classnames('flex')}>{actualView !== 'month' && children.props.children}</div>
        </div>
      </button>
    </>
  );
};
