export enum AllowedMasks {
  phone = '+9999999999999',
  date = '##.##.####',
  card = '#### #### #### ####',
  verificationCode = '9999',
  weight = '### кг',
  time = '99:99',
  height = '### см',
  bloodPressure = '###/###',
  experience = '##',
}

export const timeMask = (inputValue: string | number): string => {
  return `1${Number(inputValue) > 1 ? '2' : '9'}:39`;
};

export const timeFormatChars = {
  '1': '[0-2]',
  '2': '[0-3]',
  '3': '[0-5]',
  '9': '[0-9]',
};
