import { selectToken } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';

import { ExpertRouting } from './ExpertRouting';
import { PatientRouting } from './PatientRouting';
import { UnauthorizedRouting } from './UnauthorizedRouting';

export const AppRouting = (): JSX.Element => {
  const isAuth = useSelector(selectToken);
  const role = useSelector((state: RootState) => state.auth.role);

  if (isAuth) {
    switch (role) {
      case RolesEnum.EXPERT:
        return <ExpertRouting />;
      case RolesEnum.PATIENT:
        return <PatientRouting />;
    }
  }

  return <UnauthorizedRouting />;
};
