/* tslint:disable */
/* eslint-disable */
/**
 * NestJS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminAuthResDto
 */
export interface AdminAuthResDto {
    /**
     * 
     * @type {string}
     * @memberof AdminAuthResDto
     */
    'accessToken': string;
    /**
     * 
     * @type {Admin}
     * @memberof AdminAuthResDto
     */
    'admin': Admin;
}
/**
 * 
 * @export
 * @interface AdminCreateCheckupDto
 */
export interface AdminCreateCheckupDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCheckupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCheckupDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateCheckupDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateCheckupDto
     */
    'biomarkers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdminGetCheckupsResDto
 */
export interface AdminGetCheckupsResDto {
    /**
     * 
     * @type {Array<Checkup>}
     * @memberof AdminGetCheckupsResDto
     */
    'data': Array<Checkup>;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCheckupsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCheckupsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCheckupsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetCheckupsResDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface AdminGetPaginatedChatMessages
 */
export interface AdminGetPaginatedChatMessages {
    /**
     * 
     * @type {Array<ChatsGetMessageResDto>}
     * @memberof AdminGetPaginatedChatMessages
     */
    'data': Array<ChatsGetMessageResDto>;
}
/**
 * 
 * @export
 * @interface AdminGetPaginatedChats
 */
export interface AdminGetPaginatedChats {
    /**
     * 
     * @type {number}
     * @memberof AdminGetPaginatedChats
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetPaginatedChats
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetPaginatedChats
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdminGetPaginatedChats
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<Chat>}
     * @memberof AdminGetPaginatedChats
     */
    'data': Array<Chat>;
}
/**
 * 
 * @export
 * @interface AdminSignInDto
 */
export interface AdminSignInDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSignInDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminUpdateCheckupDto
 */
export interface AdminUpdateCheckupDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCheckupDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCheckupDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateCheckupDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateCheckupDto
     */
    'biomarkers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Analysis
 */
export interface Analysis {
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'date': string;
    /**
     * 
     * @type {Array<PatientAnalysisBiomarkers>}
     * @memberof Analysis
     */
    'patientAnalysisBiomarkers'?: Array<PatientAnalysisBiomarkers>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Analysis
     */
    'patientAnalysisBiomarkersIds'?: Array<string>;
    /**
     * 
     * @type {Patient}
     * @memberof Analysis
     */
    'patient'?: Patient;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'patientId': string;
    /**
     * 
     * @type {number}
     * @memberof Analysis
     */
    'biologicalAge'?: number | null;
    /**
     * 
     * @type {AnalysisKindEntity}
     * @memberof Analysis
     */
    'analysisKind'?: AnalysisKindEntity;
    /**
     * 
     * @type {string}
     * @memberof Analysis
     */
    'analysisKindId': string;
}
/**
 * 
 * @export
 * @interface AnalysisKindContentEntity
 */
export interface AnalysisKindContentEntity {
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindContentEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindContentEntity
     */
    'analysisKindId': string;
    /**
     * 
     * @type {AnalysisKindEntity}
     * @memberof AnalysisKindContentEntity
     */
    'analysisKind'?: AnalysisKindEntity;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindContentEntity
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindContentEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface AnalysisKindEntity
 */
export interface AnalysisKindEntity {
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindEntity
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindEntity
     */
    'label': string;
    /**
     * 
     * @type {Array<AnalysisKindContentEntity>}
     * @memberof AnalysisKindEntity
     */
    'contents'?: Array<AnalysisKindContentEntity>;
}
/**
 * 
 * @export
 * @interface AnalysisKindGetResDto
 */
export interface AnalysisKindGetResDto {
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindGetResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindGetResDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindGetResDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisKindGetResDto
     */
    'biomarkersIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AnalysisKindsBiomarkersCreateDto
 */
export interface AnalysisKindsBiomarkersCreateDto {
    /**
     * 
     * @type {string}
     * @memberof AnalysisKindsBiomarkersCreateDto
     */
    'analysisKindId': string;
}
/**
 * 
 * @export
 * @interface AnalyzesUploadSignedUrlDto
 */
export interface AnalyzesUploadSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof AnalyzesUploadSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface Anamnesis
 */
export interface Anamnesis {
    /**
     * 
     * @type {string}
     * @memberof Anamnesis
     */
    'id': string;
    /**
     * 
     * @type {CommonInfo}
     * @memberof Anamnesis
     */
    'commonInfo': CommonInfo;
    /**
     * 
     * @type {HealthChecks}
     * @memberof Anamnesis
     */
    'healthChecks': HealthChecks;
    /**
     * 
     * @type {Cycle}
     * @memberof Anamnesis
     */
    'cycle': Cycle;
    /**
     * 
     * @type {DiseasesCheck}
     * @memberof Anamnesis
     */
    'diseasesCheck': DiseasesCheck;
    /**
     * 
     * @type {HealthQuestions}
     * @memberof Anamnesis
     */
    'healthQuestions': HealthQuestions;
    /**
     * 
     * @type {boolean}
     * @memberof Anamnesis
     */
    'isAnamnesisUnread': boolean;
    /**
     * 
     * @type {Patient}
     * @memberof Anamnesis
     */
    'patient': Patient;
    /**
     * 
     * @type {string}
     * @memberof Anamnesis
     */
    'patientId': string;
}
/**
 * 
 * @export
 * @interface AtemptsCounter
 */
export interface AtemptsCounter {
    /**
     * 
     * @type {number}
     * @memberof AtemptsCounter
     */
    'current': number;
    /**
     * 
     * @type {number}
     * @memberof AtemptsCounter
     */
    'limit': number;
}
/**
 * 
 * @export
 * @interface AttachmentsSignedUrlDto
 */
export interface AttachmentsSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentsSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface BiogeekExpertPatientCompleteOnboardingDto
 */
export interface BiogeekExpertPatientCompleteOnboardingDto {
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'gender': BiogeekExpertPatientCompleteOnboardingDtoGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientCompleteOnboardingDto
     */
    'phone'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BiogeekExpertPatientCompleteOnboardingDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface BiogeekExpertPatientSignUpDto
 */
export interface BiogeekExpertPatientSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientSignUpDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientSignUpDto
     */
    'passwordConfirm': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekExpertPatientSignUpDto
     */
    'signUpHash': string;
}
/**
 * 
 * @export
 * @interface BiogeekHealthPatientSignUpDto
 */
export interface BiogeekHealthPatientSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof BiogeekHealthPatientSignUpDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekHealthPatientSignUpDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekHealthPatientSignUpDto
     */
    'passwordConfirm': string;
    /**
     * 
     * @type {string}
     * @memberof BiogeekHealthPatientSignUpDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface Biomarker
 */
export interface Biomarker {
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'name': string;
    /**
     * 
     * @type {Array<BiomarkerContent>}
     * @memberof Biomarker
     */
    'contents': Array<BiomarkerContent>;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'numberMoles': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'lowerNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'upperNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'lowerNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'upperNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'lowerNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'upperNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'lowerNormInvalidValue': number;
    /**
     * 
     * @type {number}
     * @memberof Biomarker
     */
    'upperNormInvalidValue': number;
    /**
     * 
     * @type {Unit}
     * @memberof Biomarker
     */
    'unit'?: Unit;
    /**
     * 
     * @type {string}
     * @memberof Biomarker
     */
    'unitId'?: string;
    /**
     * 
     * @type {Array<PatientAnalysisBiomarkers>}
     * @memberof Biomarker
     */
    'patientAnalysisBiomarkers'?: Array<PatientAnalysisBiomarkers>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Biomarker
     */
    'analysisKinds': Array<BiomarkerAnalysisKindsEnum>;
    /**
     * 
     * @type {FirstTypeCard}
     * @memberof Biomarker
     */
    'firstTypeCard': FirstTypeCard;
}

/**
    * @export
    * @enum {string}
    */
export enum BiomarkerAnalysisKindsEnum {
    General = 'general',
    Chemistry = 'chemistry',
    BiologicalAge = 'biological-age',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface BiomarkerContent
 */
export interface BiomarkerContent {
    /**
     * 
     * @type {string}
     * @memberof BiomarkerContent
     */
    'id': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof BiomarkerContent
     */
    'biomarker'?: Biomarker;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerContent
     */
    'biomarkerId': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerContent
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerContent
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerContent
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface BiomarkerGetResDto
 */
export interface BiomarkerGetResDto {
    /**
     * 
     * @type {Array<UnitCoefficients>}
     * @memberof BiomarkerGetResDto
     */
    'unitCoefficients': Array<UnitCoefficients>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BiomarkerGetResDto
     */
    'analysisKindsIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'name': string;
    /**
     * 
     * @type {Array<BiomarkerContent>}
     * @memberof BiomarkerGetResDto
     */
    'contents': Array<BiomarkerContent>;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'numberMoles': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'lowerNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'upperNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'lowerNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'upperNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'lowerNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'upperNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'lowerNormInvalidValue': number;
    /**
     * 
     * @type {number}
     * @memberof BiomarkerGetResDto
     */
    'upperNormInvalidValue': number;
    /**
     * 
     * @type {Unit}
     * @memberof BiomarkerGetResDto
     */
    'unit'?: Unit;
    /**
     * 
     * @type {string}
     * @memberof BiomarkerGetResDto
     */
    'unitId'?: string;
    /**
     * 
     * @type {Array<PatientAnalysisBiomarkers>}
     * @memberof BiomarkerGetResDto
     */
    'patientAnalysisBiomarkers'?: Array<PatientAnalysisBiomarkers>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BiomarkerGetResDto
     */
    'analysisKinds': Array<BiomarkerGetResDtoAnalysisKindsEnum>;
    /**
     * 
     * @type {FirstTypeCard}
     * @memberof BiomarkerGetResDto
     */
    'firstTypeCard': FirstTypeCard;
}

/**
    * @export
    * @enum {string}
    */
export enum BiomarkerGetResDtoAnalysisKindsEnum {
    General = 'general',
    Chemistry = 'chemistry',
    BiologicalAge = 'biological-age',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'updatedAt': string;
    /**
     * 
     * @type {ChatLastMessage}
     * @memberof Chat
     */
    'lastMessage': ChatLastMessage;
    /**
     * 
     * @type {ChatUnreadMessagesCount}
     * @memberof Chat
     */
    'unreadMessagesCount': ChatUnreadMessagesCount;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'expertId': string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    'patientId'?: string;
}
/**
 * 
 * @export
 * @interface ChatLastMessage
 */
export interface ChatLastMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'text': string;
    /**
     * 
     * @type {Array<MessageAttachment>}
     * @memberof ChatLastMessage
     */
    'attachments': Array<MessageAttachment>;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'kind': ChatLastMessageKindEnum;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'senderId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'repliedMessageId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'chatId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ChatLastMessage
     */
    'updatedAt': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChatLastMessageKindEnum {
    Expert = 'EXPERT',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface ChatUnreadMessagesCount
 */
export interface ChatUnreadMessagesCount {
    /**
     * 
     * @type {number}
     * @memberof ChatUnreadMessagesCount
     */
    'patient': number;
    /**
     * 
     * @type {number}
     * @memberof ChatUnreadMessagesCount
     */
    'expert': number;
}
/**
 * 
 * @export
 * @interface ChatsGetMessageResDto
 */
export interface ChatsGetMessageResDto {
    /**
     * 
     * @type {string}
     * @memberof ChatsGetMessageResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatsGetMessageResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ChatsGetMessageResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ChatsGetMessageResDto
     */
    'text': string;
    /**
     * 
     * @type {Array<MessageAttachment>}
     * @memberof ChatsGetMessageResDto
     */
    'attachments': Array<MessageAttachment>;
    /**
     * 
     * @type {RepliedMessage}
     * @memberof ChatsGetMessageResDto
     */
    'repliedMessage': RepliedMessage;
    /**
     * 
     * @type {Sender}
     * @memberof ChatsGetMessageResDto
     */
    'sender': Sender;
}
/**
 * 
 * @export
 * @interface ChatsGetPaginatedMessagesResDto
 */
export interface ChatsGetPaginatedMessagesResDto {
    /**
     * 
     * @type {Array<ChatsGetMessageResDto>}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'data': Array<ChatsGetMessageResDto>;
    /**
     * 
     * @type {number}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'nextCursor': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatsGetPaginatedMessagesResDto
     */
    'hasMore': boolean;
}
/**
 * 
 * @export
 * @interface ChatsSendMessageDto
 */
export interface ChatsSendMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ChatsSendMessageDto
     */
    'text'?: string;
    /**
     * 
     * @type {Array<MessageAttachment>}
     * @memberof ChatsSendMessageDto
     */
    'attachments'?: Array<MessageAttachment>;
    /**
     * 
     * @type {string}
     * @memberof ChatsSendMessageDto
     */
    'repliedMessageId'?: string;
}
/**
 * 
 * @export
 * @interface Checkup
 */
export interface Checkup {
    /**
     * 
     * @type {string}
     * @memberof Checkup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Checkup
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Checkup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Checkup
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Checkup
     */
    'avatar': string;
    /**
     * 
     * @type {boolean}
     * @memberof Checkup
     */
    'isPersonalCheckup': boolean;
    /**
     * 
     * @type {Array<Biomarker>}
     * @memberof Checkup
     */
    'biomarkers': Array<Biomarker>;
}
/**
 * 
 * @export
 * @interface CheckupResDto
 */
export interface CheckupResDto {
    /**
     * 
     * @type {string}
     * @memberof CheckupResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CheckupResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CheckupResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CheckupResDto
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface CheckupUploadSignedUrlDto
 */
export interface CheckupUploadSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof CheckupUploadSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface CommonInfo
 */
export interface CommonInfo {
    /**
     * 
     * @type {string}
     * @memberof CommonInfo
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CommonInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommonInfo
     */
    'profession': string;
    /**
     * 
     * @type {string}
     * @memberof CommonInfo
     */
    'familyStatus': CommonInfoFamilyStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CommonInfo
     */
    'hasOccupationalHealth': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommonInfo
     */
    'hasChildren'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CommonInfoFamilyStatusEnum {
    Single = 'single',
    Married = 'married',
    Divorced = 'divorced'
}

/**
 * 
 * @export
 * @interface CreateAnalysisKindDto
 */
export interface CreateAnalysisKindDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAnalysisKindDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnalysisKindDto
     */
    'label': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAnalysisKindDto
     */
    'biomarkersIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateBiomarkerDto
 */
export interface CreateBiomarkerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBiomarkerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBiomarkerDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBiomarkerDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBiomarkerDto
     */
    'unitId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'lowerNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'upperNormMale': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'lowerNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'upperNormFemale': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'lowerNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'upperNormConventional': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'lowerNormInvalidValue': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'upperNormInvalidValue': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBiomarkerDto
     */
    'numberMoles'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBiomarkerDto
     */
    'analysisKinds'?: Array<CreateBiomarkerDtoAnalysisKindsEnum>;
    /**
     * 
     * @type {FirstTypeCardDto}
     * @memberof CreateBiomarkerDto
     */
    'ftcValues'?: FirstTypeCardDto;
    /**
     * 
     * @type {Array<AnalysisKindsBiomarkersCreateDto>}
     * @memberof CreateBiomarkerDto
     */
    'analysisKindsBiomarkers'?: Array<AnalysisKindsBiomarkersCreateDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateBiomarkerDtoAnalysisKindsEnum {
    General = 'general',
    Chemistry = 'chemistry',
    BiologicalAge = 'biological-age',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface CreateNutritionPlanDto
 */
export interface CreateNutritionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNutritionPlanDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNutritionPlanDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNutritionPlanDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {IncludeProducts}
     * @memberof CreateNutritionPlanDto
     */
    'includeProducts': IncludeProducts;
    /**
     * 
     * @type {ExcludeProducts}
     * @memberof CreateNutritionPlanDto
     */
    'excludeProducts': ExcludeProducts;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateNutritionPlanDto
     */
    'goals': Array<string>;
}
/**
 * 
 * @export
 * @interface CreatePatientAnalysisBiomarkersDto
 */
export interface CreatePatientAnalysisBiomarkersDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAnalysisBiomarkersDto
     */
    'patientId': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePatientAnalysisBiomarkersDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAnalysisBiomarkersDto
     */
    'biomarkerId': string;
}
/**
 * 
 * @export
 * @interface CreatePatientAnalysisDto
 */
export interface CreatePatientAnalysisDto {
    /**
     * 
     * @type {Array<CreatePatientAnalysisBiomarkersDto>}
     * @memberof CreatePatientAnalysisDto
     */
    'patientAnalysisBiomarkers': Array<CreatePatientAnalysisBiomarkersDto>;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAnalysisDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAnalysisDto
     */
    'analysisKindId'?: string;
}
/**
 * 
 * @export
 * @interface CreateSecondTypeCardBiomarkersDto
 */
export interface CreateSecondTypeCardBiomarkersDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSecondTypeCardBiomarkersDto
     */
    'biomarkerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSecondTypeCardBiomarkersDto
     */
    'outsideNormZone': CreateSecondTypeCardBiomarkersDtoOutsideNormZoneEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSecondTypeCardBiomarkersDto
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSecondTypeCardBiomarkersDto
     */
    'femaleNormField': number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSecondTypeCardBiomarkersDtoOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface CreateSecondTypeCardDto
 */
export interface CreateSecondTypeCardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSecondTypeCardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSecondTypeCardDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSecondTypeCardDto
     */
    'content': string;
    /**
     * 
     * @type {Array<CreateSecondTypeCardBiomarkersDto>}
     * @memberof CreateSecondTypeCardDto
     */
    'stcBiomarkers': Array<CreateSecondTypeCardBiomarkersDto>;
}
/**
 * 
 * @export
 * @interface CreateThirdTypeCardDto
 */
export interface CreateThirdTypeCardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateThirdTypeCardDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateThirdTypeCardDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ThirdTypeCardBiomarkerDto>}
     * @memberof CreateThirdTypeCardDto
     */
    'ttcBiomarkers': Array<ThirdTypeCardBiomarkerDto>;
    /**
     * 
     * @type {Array<ThirdTypeCardRatiosDto>}
     * @memberof CreateThirdTypeCardDto
     */
    'ratios'?: Array<ThirdTypeCardRatiosDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateThirdTypeCardDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CreateThirdTypeCardDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateThirdTypeCardDto
     */
    'dysfunctionType': CreateThirdTypeCardDtoDysfunctionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateThirdTypeCardDtoDysfunctionTypeEnum {
    Dysfunction = 'dysfunction',
    NutrientStatus = 'nutrient-status'
}

/**
 * 
 * @export
 * @interface CreateUnitDto
 */
export interface CreateUnitDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUnitDto
     */
    'name': string;
    /**
     * 
     * @type {Array<UnitsCoefficientsDto>}
     * @memberof CreateUnitDto
     */
    'unitCoefficients'?: Array<UnitsCoefficientsDto>;
    /**
     * 
     * @type {Array<UnitsCoefficientsDto>}
     * @memberof CreateUnitDto
     */
    'coefficientsDisable'?: Array<UnitsCoefficientsDto>;
}
/**
 * 
 * @export
 * @interface Cycle
 */
export interface Cycle {
    /**
     * 
     * @type {number}
     * @memberof Cycle
     */
    'duration': number;
    /**
     * 
     * @type {boolean}
     * @memberof Cycle
     */
    'isRegular': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Cycle
     */
    'leaveComment': boolean;
    /**
     * 
     * @type {string}
     * @memberof Cycle
     */
    'commentText'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Cycle
     */
    'PMSSymptoms': boolean;
}
/**
 * 
 * @export
 * @interface DiaryOther
 */
export interface DiaryOther {
    /**
     * 
     * @type {string}
     * @memberof DiaryOther
     */
    'other': string;
    /**
     * 
     * @type {string}
     * @memberof DiaryOther
     */
    'selfPleasure': string;
}
/**
 * 
 * @export
 * @interface DiaryStress
 */
export interface DiaryStress {
    /**
     * 
     * @type {string}
     * @memberof DiaryStress
     */
    'stressLevel': DiaryStressStressLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof DiaryStress
     */
    'stressSituation': string;
}

/**
    * @export
    * @enum {string}
    */
export enum DiaryStressStressLevelEnum {
    Stable = 'STABLE',
    Medium = 'MEDIUM',
    High = 'HIGH'
}

/**
 * 
 * @export
 * @interface DiseasesCheck
 */
export interface DiseasesCheck {
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'productsPortability': DiseasesCheckProductsPortabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'respiratoryDiseaseIncidence': string;
    /**
     * 
     * @type {boolean}
     * @memberof DiseasesCheck
     */
    'allergy': boolean;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'endocrinologicalProblems': DiseasesCheckEndocrinologicalProblemsEnum;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'relativesDiseases': string;
    /**
     * 
     * @type {boolean}
     * @memberof DiseasesCheck
     */
    'vitaminsAndDietarySupplements': boolean;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'sleepQuality': string;
    /**
     * 
     * @type {PhysicalActivity}
     * @memberof DiseasesCheck
     */
    'physicalActivityLevel': PhysicalActivity;
    /**
     * 
     * @type {Habits}
     * @memberof DiseasesCheck
     */
    'havingHabitsAndAddictions': Habits;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'lowEnergyTime': DiseasesCheckLowEnergyTimeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DiseasesCheck
     */
    'haveLivedInPatmosphericPollutionAreas': boolean;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'hairNailsSkinCondition': string;
    /**
     * 
     * @type {boolean}
     * @memberof DiseasesCheck
     */
    'haveDiet': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiseasesCheck
     */
    'useHealthCheckGadgets'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiseasesCheck
     */
    'memoryCondition': DiseasesCheckMemoryConditionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DiseasesCheckProductsPortabilityEnum {
    Good = 'good',
    Bad = 'bad',
    NotPaidAttention = 'not-paid-attention'
}
/**
    * @export
    * @enum {string}
    */
export enum DiseasesCheckEndocrinologicalProblemsEnum {
    Yes = 'yes',
    No = 'no',
    Unknown = 'unknown'
}
/**
    * @export
    * @enum {string}
    */
export enum DiseasesCheckLowEnergyTimeEnum {
    Morning = 'morning',
    Dinner = 'dinner',
    Afternoon = 'afternoon',
    AllDay = 'all-day'
}
/**
    * @export
    * @enum {string}
    */
export enum DiseasesCheckMemoryConditionEnum {
    Good = 'good',
    Medium = 'medium',
    Bad = 'bad'
}

/**
 * 
 * @export
 * @interface DocumentUploadSignedUrlDto
 */
export interface DocumentUploadSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface Eating
 */
export interface Eating {
    /**
     * 
     * @type {string}
     * @memberof Eating
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Eating
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof Eating
     */
    'products': string;
}
/**
 * 
 * @export
 * @interface EnergyAndSleep
 */
export interface EnergyAndSleep {
    /**
     * 
     * @type {string}
     * @memberof EnergyAndSleep
     */
    'energyLevel': EnergyAndSleepEnergyLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof EnergyAndSleep
     */
    'sleep': string;
}

/**
    * @export
    * @enum {string}
    */
export enum EnergyAndSleepEnergyLevelEnum {
    MorningLow = 'MORNING_LOW',
    AfternoonLow = 'AFTERNOON_LOW',
    EveningLow = 'EVENING_LOW',
    Stable = 'STABLE'
}

/**
 * 
 * @export
 * @interface EventsGetEventResDto
 */
export interface EventsGetEventResDto {
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'patientName': string;
    /**
     * 
     * @type {number}
     * @memberof EventsGetEventResDto
     */
    'duration': number;
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'beginning': string;
    /**
     * 
     * @type {number}
     * @memberof EventsGetEventResDto
     */
    'cost': number;
    /**
     * 
     * @type {string}
     * @memberof EventsGetEventResDto
     */
    'eventType': EventsGetEventResDtoEventTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsGetEventResDtoEventTypeEnum {
    Call = 'CALL',
    Videocall = 'VIDEOCALL',
    Meeting = 'MEETING'
}

/**
 * 
 * @export
 * @interface ExcludeProducts
 */
export interface ExcludeProducts {
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'vegetables': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'fruits': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'animalProtein': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'vegetableProtein': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'nutsAndSeeds': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'oils': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'cereals': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'milkProducts': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'drinks': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'nutritionalSupplements': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'sweets': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'sweeteners': string;
    /**
     * 
     * @type {string}
     * @memberof ExcludeProducts
     */
    'condiments': string;
}
/**
 * 
 * @export
 * @interface Expert
 */
export interface Expert {
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'birthday': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'gender': ExpertGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'spesialization': string;
    /**
     * 
     * @type {number}
     * @memberof Expert
     */
    'jobExperience': number | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'shortInfo': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Expert
     */
    'educationDocuments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'educationDocumentStatus': ExpertEducationDocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'educationDocumentsSentAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'isOnboardingCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'role': ExpertRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'hiddenForPatients': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'isApproved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'isShadowBanned': boolean;
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof Expert
     */
    'usedPromocodes': Array<Promocode>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof Expert
     */
    'subscriptions'?: Array<Subscription>;
    /**
     * 
     * @type {ExpertSettings}
     * @memberof Expert
     */
    'expertSettings'?: ExpertSettings;
    /**
     * 
     * @type {number}
     * @memberof Expert
     */
    'age': number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertGenderEnum {
    Female = 'female',
    Male = 'male'
}
/**
    * @export
    * @enum {string}
    */
export enum ExpertEducationDocumentStatusEnum {
    NotSent = 'NOT_SENT',
    Checking = 'CHECKING',
    Approved = 'APPROVED',
    Declined = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ExpertRoleEnum {
    Expert = 'EXPERT',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface ExpertAuthResDto
 */
export interface ExpertAuthResDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertAuthResDto
     */
    'accessToken': string;
    /**
     * 
     * @type {Expert}
     * @memberof ExpertAuthResDto
     */
    'expert': Expert;
}
/**
 * 
 * @export
 * @interface ExpertCompleteOnboardingDto
 */
export interface ExpertCompleteOnboardingDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertCompleteOnboardingDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCompleteOnboardingDto
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCompleteOnboardingDto
     */
    'gender': ExpertCompleteOnboardingDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertCompleteOnboardingDto
     */
    'spesialization': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertCompleteOnboardingDto
     */
    'jobExperience': number;
    /**
     * 
     * @type {string}
     * @memberof ExpertCompleteOnboardingDto
     */
    'shortInfo': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertCompleteOnboardingDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface ExpertCreateChatRoomDto
 */
export interface ExpertCreateChatRoomDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateChatRoomDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateChatRoomDto
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ExpertCreateCustomCheckupDto
 */
export interface ExpertCreateCustomCheckupDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateCustomCheckupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateCustomCheckupDto
     */
    'patientId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertCreateCustomCheckupDto
     */
    'biomarkersIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ExpertCreateEventDto
 */
export interface ExpertCreateEventDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateEventDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateEventDto
     */
    'eventType': ExpertCreateEventDtoEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateEventDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreateEventDto
     */
    'beginning': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertCreateEventDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertCreateEventDto
     */
    'cost': number;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertCreateEventDtoEventTypeEnum {
    Call = 'CALL',
    Videocall = 'VIDEOCALL',
    Meeting = 'MEETING'
}

/**
 * 
 * @export
 * @interface ExpertCreatePatientDto
 */
export interface ExpertCreatePatientDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePatientDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePatientDto
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePatientDto
     */
    'gender': ExpertCreatePatientDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePatientDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePatientDto
     */
    'phone'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertCreatePatientDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface ExpertCreatePaymentsDiaryDto
 */
export interface ExpertCreatePaymentsDiaryDto {
    /**
     * 
     * @type {number}
     * @memberof ExpertCreatePaymentsDiaryDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePaymentsDiaryDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertCreatePaymentsDiaryDto
     */
    'patientId': string;
}
/**
 * 
 * @export
 * @interface ExpertEditEventDto
 */
export interface ExpertEditEventDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertEditEventDto
     */
    'eventType': ExpertEditEventDtoEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertEditEventDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertEditEventDto
     */
    'beginning': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertEditEventDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertEditEventDto
     */
    'cost': number;
    /**
     * 
     * @type {string}
     * @memberof ExpertEditEventDto
     */
    'patientId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertEditEventDtoEventTypeEnum {
    Call = 'CALL',
    Videocall = 'VIDEOCALL',
    Meeting = 'MEETING'
}

/**
 * 
 * @export
 * @interface ExpertEmailCodeDto
 */
export interface ExpertEmailCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertEmailCodeDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ExpertForgotPasswordDto
 */
export interface ExpertForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertForgotPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ExpertFullInfoResDto
 */
export interface ExpertFullInfoResDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'birthday': string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'gender': ExpertFullInfoResDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'spesialization': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertFullInfoResDto
     */
    'jobExperience': number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'shortInfo': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertFullInfoResDto
     */
    'educationDocuments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'educationDocumentStatus': ExpertFullInfoResDtoEducationDocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'educationDocumentsSentAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertFullInfoResDto
     */
    'isOnboardingCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpertFullInfoResDto
     */
    'role': ExpertFullInfoResDtoRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertFullInfoResDto
     */
    'hiddenForPatients': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertFullInfoResDto
     */
    'isApproved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertFullInfoResDto
     */
    'isShadowBanned': boolean;
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof ExpertFullInfoResDto
     */
    'usedPromocodes': Array<Promocode>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof ExpertFullInfoResDto
     */
    'subscriptions'?: Array<Subscription>;
    /**
     * 
     * @type {ExpertSettings}
     * @memberof ExpertFullInfoResDto
     */
    'expertSettings'?: ExpertSettings;
    /**
     * 
     * @type {number}
     * @memberof ExpertFullInfoResDto
     */
    'age': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertFullInfoResDto
     */
    'requestInPending': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertFullInfoResDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}
/**
    * @export
    * @enum {string}
    */
export enum ExpertFullInfoResDtoEducationDocumentStatusEnum {
    NotSent = 'NOT_SENT',
    Checking = 'CHECKING',
    Approved = 'APPROVED',
    Declined = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ExpertFullInfoResDtoRoleEnum {
    Expert = 'EXPERT',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface ExpertGetPaginatedPaymentsDiaryDto
 */
export interface ExpertGetPaginatedPaymentsDiaryDto {
    /**
     * 
     * @type {Array<ExpertGetPaymentsDiaryDto>}
     * @memberof ExpertGetPaginatedPaymentsDiaryDto
     */
    'data': Array<ExpertGetPaymentsDiaryDto>;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPaginatedPaymentsDiaryDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPaginatedPaymentsDiaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPaginatedPaymentsDiaryDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPaginatedPaymentsDiaryDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface ExpertGetPatientsResDto
 */
export interface ExpertGetPatientsResDto {
    /**
     * 
     * @type {Array<PatientResDto>}
     * @memberof ExpertGetPatientsResDto
     */
    'data': Array<PatientResDto>;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPatientsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPatientsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPatientsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPatientsResDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface ExpertGetPaymentsDiaryDto
 */
export interface ExpertGetPaymentsDiaryDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'paid': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'patientName': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'patientAvatar': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertGetPaymentsDiaryDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ExpertLoadAvatarImageDto
 */
export interface ExpertLoadAvatarImageDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertLoadAvatarImageDto
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface ExpertLoadEducationDocumentsDto
 */
export interface ExpertLoadEducationDocumentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertLoadEducationDocumentsDto
     */
    'documents': Array<string>;
}
/**
 * 
 * @export
 * @interface ExpertResDto
 */
export interface ExpertResDto {
    /**
     * 
     * @type {boolean}
     * @memberof ExpertResDto
     */
    'requestInPending': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExpertResDto
     */
    'age': number;
    /**
     * 
     * @type {string}
     * @memberof ExpertResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertResDto
     */
    'spesialization': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertResDto
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof ExpertResDto
     */
    'jobExperience': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertResDto
     */
    'isApproved': boolean;
}
/**
 * 
 * @export
 * @interface ExpertRestorePasswordDto
 */
export interface ExpertRestorePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertRestorePasswordDto
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertRestorePasswordDto
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertRestorePasswordDto
     */
    'newPasswordConfirm': string;
}
/**
 * 
 * @export
 * @interface ExpertSetNewEmailDto
 */
export interface ExpertSetNewEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertSetNewEmailDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSetNewEmailDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface ExpertSettings
 */
export interface ExpertSettings {
    /**
     * 
     * @type {string}
     * @memberof ExpertSettings
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSettings
     */
    'expertId': string;
    /**
     * 
     * @type {Expert}
     * @memberof ExpertSettings
     */
    'expert': Expert;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'cooperationNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'stopCooperationNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'newAnalysisNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'anamnesisUpdateNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'anamnesisCreatedNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'patientUpdateDiaryNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertSettings
     */
    'newMessageInChatNotification': boolean;
}
/**
 * 
 * @export
 * @interface ExpertSignInDto
 */
export interface ExpertSignInDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertSignInDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ExpertSignUpDto
 */
export interface ExpertSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertSignUpDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSignUpDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSignUpDto
     */
    'passwordConfirm': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertSignUpDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface ExpertSignUpVerificationDto
 */
export interface ExpertSignUpVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertSignUpVerificationDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ExpertUpdateExpertDto
 */
export interface ExpertUpdateExpertDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'gender'?: ExpertUpdateExpertDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'spesialization'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertUpdateExpertDto
     */
    'jobExperience'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdateExpertDto
     */
    'shortInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateExpertDto
     */
    'hiddenForPatients': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertUpdateExpertDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface ExpertUpdatePasswordDto
 */
export interface ExpertUpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdatePasswordDto
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdatePasswordDto
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ExpertUpdatePasswordDto
     */
    'confirmedNewPassword': string;
}
/**
 * 
 * @export
 * @interface ExpertUpdateSettingsDto
 */
export interface ExpertUpdateSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'cooperationNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'stopCooperationNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'newAnalysisNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'anamnesisUpdateNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'patientUpdateDiaryNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertUpdateSettingsDto
     */
    'newMessageInChatNotification': boolean;
}
/**
 * 
 * @export
 * @interface ExpertVerifyCurrentEmailDto
 */
export interface ExpertVerifyCurrentEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertVerifyCurrentEmailDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface ExportCheckUpsPdfDto
 */
export interface ExportCheckUpsPdfDto {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ExportCheckUpsPdfDto
     */
    'biomarkerNames': Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof ExportCheckUpsPdfDto
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface FileUploadSignedUrlResDto
 */
export interface FileUploadSignedUrlResDto {
    /**
     * 
     * @type {string}
     * @memberof FileUploadSignedUrlResDto
     */
    'fileKey': string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadSignedUrlResDto
     */
    'signedUrl': string;
}
/**
 * 
 * @export
 * @interface FindOneAnalysisWithPreviousResDto
 */
export interface FindOneAnalysisWithPreviousResDto {
    /**
     * 
     * @type {Analysis}
     * @memberof FindOneAnalysisWithPreviousResDto
     */
    'currentAnalysis': Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof FindOneAnalysisWithPreviousResDto
     */
    'previousAnalysis'?: Analysis;
}
/**
 * 
 * @export
 * @interface FirstTypeCard
 */
export interface FirstTypeCard {
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'lowerLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'greaterLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'lowerDescription': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'greaterDescription': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'lowerContent': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'greaterContent': string;
    /**
     * 
     * @type {Array<FirstTypeCardContent>}
     * @memberof FirstTypeCard
     */
    'contents': Array<FirstTypeCardContent>;
    /**
     * 
     * @type {Biomarker}
     * @memberof FirstTypeCard
     */
    'biomarker'?: Biomarker;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCard
     */
    'biomarkerId': string;
    /**
     * 
     * @type {Array<FirstTypeCardsPatient>}
     * @memberof FirstTypeCard
     */
    'ftcPatients'?: Array<FirstTypeCardsPatient>;
}
/**
 * 
 * @export
 * @interface FirstTypeCardContent
 */
export interface FirstTypeCardContent {
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'lowerLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'greaterLabel': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'lowerDescription': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'greaterDescription': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'lowerContent': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'greaterContent': string;
    /**
     * 
     * @type {FirstTypeCard}
     * @memberof FirstTypeCardContent
     */
    'firstTypeCard': FirstTypeCard;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardContent
     */
    'firstTypeCardId': string;
}
/**
 * 
 * @export
 * @interface FirstTypeCardDto
 */
export interface FirstTypeCardDto {
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'lowerLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'greaterLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'greaterContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'lowerContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'greaterDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardDto
     */
    'lowerDescription'?: string;
}
/**
 * 
 * @export
 * @interface FirstTypeCardsPatient
 */
export interface FirstTypeCardsPatient {
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatient
     */
    'outsideNormZone': FirstTypeCardsPatientOutsideNormZoneEnum;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatient
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatient
     */
    'firstTypeCardId': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatient
     */
    'patientId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum FirstTypeCardsPatientOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface FirstTypeCardsPatientGetResDto
 */
export interface FirstTypeCardsPatientGetResDto {
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'firstTypeCardId': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'outsideNormZone': FirstTypeCardsPatientGetResDtoOutsideNormZoneEnum;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'biomarkerId': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof FirstTypeCardsPatientGetResDto
     */
    'content': string;
}

/**
    * @export
    * @enum {string}
    */
export enum FirstTypeCardsPatientGetResDtoOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface GetChatRoomsResDto
 */
export interface GetChatRoomsResDto {
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'expertId': string;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {ChatLastMessage}
     * @memberof GetChatRoomsResDto
     */
    'lastMessage': ChatLastMessage;
    /**
     * 
     * @type {ChatUnreadMessagesCount}
     * @memberof GetChatRoomsResDto
     */
    'unreadMessagesCount': ChatUnreadMessagesCount;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetChatRoomsResDto
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface GetCustomerPortalUrlDto
 */
export interface GetCustomerPortalUrlDto {
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPortalUrlDto
     */
    'returnUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerPortalUrlDto
     */
    'paymentPlatform': GetCustomerPortalUrlDtoPaymentPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetCustomerPortalUrlDtoPaymentPlatformEnum {
    Stripe = 'STRIPE',
    CloudPayments = 'CLOUD_PAYMENTS'
}

/**
 * 
 * @export
 * @interface GetPatientNutritonPlanResDto
 */
export interface GetPatientNutritonPlanResDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetPatientNutritonPlanResDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPatientNutritonPlanResDto
     */
    'isNewlyPrescribed': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'nutritionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {ExcludeProducts}
     * @memberof GetPatientNutritonPlanResDto
     */
    'excludeProducts': ExcludeProducts;
    /**
     * 
     * @type {IncludeProducts}
     * @memberof GetPatientNutritonPlanResDto
     */
    'includeProducts': IncludeProducts;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetPatientNutritonPlanResDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPatientNutritonPlanResDto
     */
    'goals': Array<string>;
}
/**
 * 
 * @export
 * @interface GetPaymentUrlDto
 */
export interface GetPaymentUrlDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentUrlDto
     */
    'subscriptionPlan': GetPaymentUrlDtoSubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentUrlDto
     */
    'successUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentUrlDto
     */
    'cancelUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentUrlDto
     */
    'paymentPlatform': GetPaymentUrlDtoPaymentPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPaymentUrlDtoSubscriptionPlanEnum {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    HighYear = 'high_year',
    MediumYear = 'medium_year',
    LowYear = 'low_year',
    HighMonth = 'high_month',
    MediumMonth = 'medium_month',
    LowMonth = 'low_month',
    HighDay = 'high_day',
    MediumDay = 'medium_day',
    LowDay = 'low_day'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPaymentUrlDtoPaymentPlatformEnum {
    Stripe = 'STRIPE',
    CloudPayments = 'CLOUD_PAYMENTS'
}

/**
 * 
 * @export
 * @interface Habits
 */
export interface Habits {
    /**
     * 
     * @type {boolean}
     * @memberof Habits
     */
    'coffee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Habits
     */
    'sugar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Habits
     */
    'smoking'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Habits
     */
    'alcohol'?: boolean;
}
/**
 * 
 * @export
 * @interface HealthChecks
 */
export interface HealthChecks {
    /**
     * 
     * @type {number}
     * @memberof HealthChecks
     */
    'bloodPressure': number;
    /**
     * 
     * @type {number}
     * @memberof HealthChecks
     */
    'pulse': number;
    /**
     * 
     * @type {number}
     * @memberof HealthChecks
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof HealthChecks
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof HealthChecks
     */
    'waist': number;
    /**
     * 
     * @type {string}
     * @memberof HealthChecks
     */
    'shortTasksDescription': string;
    /**
     * 
     * @type {string}
     * @memberof HealthChecks
     */
    'howResolvedTroubles': string;
    /**
     * 
     * @type {Level}
     * @memberof HealthChecks
     */
    'digestionLevel': Level;
    /**
     * 
     * @type {StressAmount}
     * @memberof HealthChecks
     */
    'stressAmount': StressAmount;
    /**
     * 
     * @type {Level}
     * @memberof HealthChecks
     */
    'breathSystem': Level;
    /**
     * 
     * @type {string}
     * @memberof HealthChecks
     */
    'emotionsControlDifficulty': HealthChecksEmotionsControlDifficultyEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthChecks
     */
    'cardiopalmus': HealthChecksCardiopalmusEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthChecks
     */
    'excretorySystem': string;
}

/**
    * @export
    * @enum {string}
    */
export enum HealthChecksEmotionsControlDifficultyEnum {
    Difficult = 'difficult',
    Medium = 'medium',
    Easy = 'easy'
}
/**
    * @export
    * @enum {string}
    */
export enum HealthChecksCardiopalmusEnum {
    No = 'no',
    Sometimes = 'sometimes',
    WithoutLoad = 'withoutLoad'
}

/**
 * 
 * @export
 * @interface HealthQuestions
 */
export interface HealthQuestions {
    /**
     * 
     * @type {string}
     * @memberof HealthQuestions
     */
    'lifeChangeTrigger': string;
    /**
     * 
     * @type {string}
     * @memberof HealthQuestions
     */
    'lifeSatisfaction': string;
    /**
     * 
     * @type {string}
     * @memberof HealthQuestions
     */
    'readyToDoForHealthSupport': string;
    /**
     * 
     * @type {string}
     * @memberof HealthQuestions
     */
    'anamnesis': string;
    /**
     * 
     * @type {string}
     * @memberof HealthQuestions
     */
    'complaints': string;
}
/**
 * 
 * @export
 * @interface IncludeProducts
 */
export interface IncludeProducts {
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'vegetables': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'fruits': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'animalProtein': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'vegetableProtein': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'nutsAndSeeds': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'oils': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'cereals': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'milkProducts': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'drinks': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'nutritionalSupplements': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'sweets': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'sweeteners': string;
    /**
     * 
     * @type {string}
     * @memberof IncludeProducts
     */
    'condiments': string;
}
/**
 * 
 * @export
 * @interface Level
 */
export interface Level {
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    'level': LevelLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    'commentText'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LevelLevelEnum {
    Good = 'good',
    Medium = 'medium',
    Bad = 'bad',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface MessageAttachment
 */
export interface MessageAttachment {
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    'URL': string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface Nutrition
 */
export interface Nutrition {
    /**
     * 
     * @type {Array<Eating>}
     * @memberof Nutrition
     */
    'eatings': Array<Eating>;
    /**
     * 
     * @type {string}
     * @memberof Nutrition
     */
    'waterRegime': string;
    /**
     * 
     * @type {string}
     * @memberof Nutrition
     */
    'drugsAndSupplements': string;
}
/**
 * 
 * @export
 * @interface NutritionPlan
 */
export interface NutritionPlan {
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'id': string;
    /**
     * 
     * @type {Array<NutritionPlanContent>}
     * @memberof NutritionPlan
     */
    'contents': Array<NutritionPlanContent>;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NutritionPlan
     */
    'goals': Array<string>;
    /**
     * 
     * @type {ExcludeProducts}
     * @memberof NutritionPlan
     */
    'excludeProducts': ExcludeProducts;
    /**
     * 
     * @type {IncludeProducts}
     * @memberof NutritionPlan
     */
    'includeProducts': IncludeProducts;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlan
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NutritionPlanContent
 */
export interface NutritionPlanContent {
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'nutritionPlanId': string;
    /**
     * 
     * @type {NutritionPlan}
     * @memberof NutritionPlanContent
     */
    'nutritionPlan'?: NutritionPlan;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'title': string;
    /**
     * 
     * @type {IncludeProducts}
     * @memberof NutritionPlanContent
     */
    'includeProducts': IncludeProducts;
    /**
     * 
     * @type {ExcludeProducts}
     * @memberof NutritionPlanContent
     */
    'excludeProducts': ExcludeProducts;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionPlanContent
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NutritionPlanContent
     */
    'goals': Array<string>;
}
/**
 * 
 * @export
 * @interface ParsePdfOrImageResDto
 */
export interface ParsePdfOrImageResDto {
    /**
     * 
     * @type {string}
     * @memberof ParsePdfOrImageResDto
     */
    'biomarkerName': string | null;
    /**
     * 
     * @type {number}
     * @memberof ParsePdfOrImageResDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ParsePdfOrImageResDtoV2
 */
export interface ParsePdfOrImageResDtoV2 {
    /**
     * 
     * @type {string}
     * @memberof ParsePdfOrImageResDtoV2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParsePdfOrImageResDtoV2
     */
    'biomarkerName': string | null;
    /**
     * 
     * @type {number}
     * @memberof ParsePdfOrImageResDtoV2
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'weight': number | null;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'height': number | null;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'biologicalAge': number | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'role': PatientRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'signUpStatus': PatientSignUpStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'cooperatedWithExpertAt': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'signUpHash': string;
    /**
     * 
     * @type {PatientDemoMode}
     * @memberof Patient
     */
    'demoMode': PatientDemoMode | null;
    /**
     * 
     * @type {PatientParsingAnalysisCounter}
     * @memberof Patient
     */
    'parsingAnalyzesCounter': PatientParsingAnalysisCounter;
    /**
     * 
     * @type {Array<PatientAnalysisBiomarkers>}
     * @memberof Patient
     */
    'patientAnalysisBiomarkers'?: Array<PatientAnalysisBiomarkers>;
    /**
     * 
     * @type {Expert}
     * @memberof Patient
     */
    'expert'?: Expert;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'expertId': string;
    /**
     * 
     * @type {Array<SecondTypeCardsPatient>}
     * @memberof Patient
     */
    'stcPatients'?: Array<SecondTypeCardsPatient>;
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof Patient
     */
    'usedPromocodes'?: Array<Promocode>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof Patient
     */
    'subscriptions'?: Array<Subscription>;
    /**
     * 
     * @type {PatientSettings}
     * @memberof Patient
     */
    'patientSettings'?: PatientSettings;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'age': number | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'birthday': string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'gender': PatientGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'phone': string;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    'isOnboardingCompleted': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientRoleEnum {
    Expert = 'EXPERT',
    Patient = 'PATIENT'
}
/**
    * @export
    * @enum {string}
    */
export enum PatientSignUpStatusEnum {
    Sent = 'sent',
    Approved = 'approved'
}
/**
    * @export
    * @enum {string}
    */
export enum PatientGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface PatientAnalysisBiomarkers
 */
export interface PatientAnalysisBiomarkers {
    /**
     * 
     * @type {string}
     * @memberof PatientAnalysisBiomarkers
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalysisBiomarkers
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PatientAnalysisBiomarkers
     */
    'value': number | null;
    /**
     * 
     * @type {Patient}
     * @memberof PatientAnalysisBiomarkers
     */
    'patient': Patient;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalysisBiomarkers
     */
    'patientId': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof PatientAnalysisBiomarkers
     */
    'biomarker'?: Biomarker;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalysisBiomarkers
     */
    'biomarkerId': string;
    /**
     * 
     * @type {Analysis}
     * @memberof PatientAnalysisBiomarkers
     */
    'analysis'?: Analysis;
    /**
     * 
     * @type {string}
     * @memberof PatientAnalysisBiomarkers
     */
    'analysisId': string;
}
/**
 * 
 * @export
 * @interface PatientAuthResDto
 */
export interface PatientAuthResDto {
    /**
     * 
     * @type {string}
     * @memberof PatientAuthResDto
     */
    'accessToken': string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientAuthResDto
     */
    'patient': Patient;
}
/**
 * 
 * @export
 * @interface PatientCreateAnamnesisDto
 */
export interface PatientCreateAnamnesisDto {
    /**
     * 
     * @type {CommonInfo}
     * @memberof PatientCreateAnamnesisDto
     */
    'commonInfo': CommonInfo;
    /**
     * 
     * @type {HealthChecks}
     * @memberof PatientCreateAnamnesisDto
     */
    'healthChecks': HealthChecks;
    /**
     * 
     * @type {Cycle}
     * @memberof PatientCreateAnamnesisDto
     */
    'cycle': Cycle;
    /**
     * 
     * @type {DiseasesCheck}
     * @memberof PatientCreateAnamnesisDto
     */
    'diseasesCheck': DiseasesCheck;
    /**
     * 
     * @type {HealthQuestions}
     * @memberof PatientCreateAnamnesisDto
     */
    'healthQuestions': HealthQuestions;
}
/**
 * 
 * @export
 * @interface PatientCreateChatDto
 */
export interface PatientCreateChatDto {
    /**
     * 
     * @type {string}
     * @memberof PatientCreateChatDto
     */
    'expertId': string;
}
/**
 * 
 * @export
 * @interface PatientCreatePatientDiaryDto
 */
export interface PatientCreatePatientDiaryDto {
    /**
     * 
     * @type {Nutrition}
     * @memberof PatientCreatePatientDiaryDto
     */
    'nutrition': Nutrition;
    /**
     * 
     * @type {Workout}
     * @memberof PatientCreatePatientDiaryDto
     */
    'workout': Workout;
    /**
     * 
     * @type {EnergyAndSleep}
     * @memberof PatientCreatePatientDiaryDto
     */
    'energyAndSleep': EnergyAndSleep;
    /**
     * 
     * @type {DiaryStress}
     * @memberof PatientCreatePatientDiaryDto
     */
    'stress': DiaryStress;
    /**
     * 
     * @type {DiaryOther}
     * @memberof PatientCreatePatientDiaryDto
     */
    'other': DiaryOther;
}
/**
 * 
 * @export
 * @interface PatientDemoMode
 */
export interface PatientDemoMode {
    /**
     * 
     * @type {number}
     * @memberof PatientDemoMode
     */
    'toCreateAnalysis': number;
    /**
     * 
     * @type {number}
     * @memberof PatientDemoMode
     */
    'toDownloadAnalysisPdf': number;
}
/**
 * 
 * @export
 * @interface PatientDiary
 */
export interface PatientDiary {
    /**
     * 
     * @type {string}
     * @memberof PatientDiary
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDiary
     */
    'patientId': string;
    /**
     * 
     * @type {Nutrition}
     * @memberof PatientDiary
     */
    'nutrition': Nutrition;
    /**
     * 
     * @type {Workout}
     * @memberof PatientDiary
     */
    'workout': Workout;
    /**
     * 
     * @type {EnergyAndSleep}
     * @memberof PatientDiary
     */
    'energyAndSleep': EnergyAndSleep;
    /**
     * 
     * @type {DiaryStress}
     * @memberof PatientDiary
     */
    'stress': DiaryStress;
    /**
     * 
     * @type {DiaryOther}
     * @memberof PatientDiary
     */
    'other': DiaryOther;
    /**
     * 
     * @type {string}
     * @memberof PatientDiary
     */
    'day': PatientDiaryDayEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientDiaryDayEnum {
    _1 = 'DAY_1',
    _2 = 'DAY_2',
    _3 = 'DAY_3',
    _4 = 'DAY_4',
    _5 = 'DAY_5',
    _6 = 'DAY_6',
    _7 = 'DAY_7'
}

/**
 * 
 * @export
 * @interface PatientEmailCodeDto
 */
export interface PatientEmailCodeDto {
    /**
     * 
     * @type {string}
     * @memberof PatientEmailCodeDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PatientForgotPasswordDto
 */
export interface PatientForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof PatientForgotPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PatientGetExpertsResDto
 */
export interface PatientGetExpertsResDto {
    /**
     * 
     * @type {Array<ExpertResDto>}
     * @memberof PatientGetExpertsResDto
     */
    'data': Array<ExpertResDto>;
    /**
     * 
     * @type {number}
     * @memberof PatientGetExpertsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof PatientGetExpertsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PatientGetExpertsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PatientGetExpertsResDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface PatientParsingAnalysisCounter
 */
export interface PatientParsingAnalysisCounter {
    /**
     * 
     * @type {AtemptsCounter}
     * @memberof PatientParsingAnalysisCounter
     */
    'pdf': AtemptsCounter;
    /**
     * 
     * @type {AtemptsCounter}
     * @memberof PatientParsingAnalysisCounter
     */
    'image': AtemptsCounter;
}
/**
 * 
 * @export
 * @interface PatientRecommendationCardsGetResDto
 */
export interface PatientRecommendationCardsGetResDto {
    /**
     * 
     * @type {Array<FirstTypeCardsPatientGetResDto>}
     * @memberof PatientRecommendationCardsGetResDto
     */
    'firstTypeCards': Array<FirstTypeCardsPatientGetResDto>;
    /**
     * 
     * @type {Array<SecondTypeCardsPatientGetResDto>}
     * @memberof PatientRecommendationCardsGetResDto
     */
    'secondTypeCards': Array<SecondTypeCardsPatientGetResDto>;
    /**
     * 
     * @type {Array<ThirdTypeCardsPatientGetResDto>}
     * @memberof PatientRecommendationCardsGetResDto
     */
    'thirdTypeCards': Array<ThirdTypeCardsPatientGetResDto>;
}
/**
 * 
 * @export
 * @interface PatientResDto
 */
export interface PatientResDto {
    /**
     * 
     * @type {boolean}
     * @memberof PatientResDto
     */
    'isNewRequest': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'birthday': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'cooperatedWithExpertAt': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResDto
     */
    'signUpStatus': PatientResDtoSignUpStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientResDtoSignUpStatusEnum {
    Sent = 'sent',
    Approved = 'approved'
}

/**
 * 
 * @export
 * @interface PatientRestorePasswordDto
 */
export interface PatientRestorePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof PatientRestorePasswordDto
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRestorePasswordDto
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRestorePasswordDto
     */
    'newPasswordConfirm': string;
}
/**
 * 
 * @export
 * @interface PatientSetNewEmailDto
 */
export interface PatientSetNewEmailDto {
    /**
     * 
     * @type {string}
     * @memberof PatientSetNewEmailDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSetNewEmailDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface PatientSettings
 */
export interface PatientSettings {
    /**
     * 
     * @type {string}
     * @memberof PatientSettings
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSettings
     */
    'patientId': string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientSettings
     */
    'patient': Patient;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSettings
     */
    'newMessageInChatNotification': boolean;
}
/**
 * 
 * @export
 * @interface PatientSignInDto
 */
export interface PatientSignInDto {
    /**
     * 
     * @type {string}
     * @memberof PatientSignInDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PatientSignUpVerificationDto
 */
export interface PatientSignUpVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof PatientSignUpVerificationDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PatientUpdatePasswordDto
 */
export interface PatientUpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof PatientUpdatePasswordDto
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdatePasswordDto
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdatePasswordDto
     */
    'confirmedNewPassword': string;
}
/**
 * 
 * @export
 * @interface PatientUpdatePatientDiaryDto
 */
export interface PatientUpdatePatientDiaryDto {
    /**
     * 
     * @type {Nutrition}
     * @memberof PatientUpdatePatientDiaryDto
     */
    'nutrition': Nutrition;
    /**
     * 
     * @type {Workout}
     * @memberof PatientUpdatePatientDiaryDto
     */
    'workout': Workout;
    /**
     * 
     * @type {EnergyAndSleep}
     * @memberof PatientUpdatePatientDiaryDto
     */
    'energyAndSleep': EnergyAndSleep;
    /**
     * 
     * @type {DiaryStress}
     * @memberof PatientUpdatePatientDiaryDto
     */
    'stress': DiaryStress;
    /**
     * 
     * @type {DiaryOther}
     * @memberof PatientUpdatePatientDiaryDto
     */
    'other': DiaryOther;
}
/**
 * 
 * @export
 * @interface PatientUpdateSettingsDto
 */
export interface PatientUpdateSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PatientUpdateSettingsDto
     */
    'newMessageInChatNotification': boolean;
}
/**
 * 
 * @export
 * @interface PatientVerifyCurrentEmailDto
 */
export interface PatientVerifyCurrentEmailDto {
    /**
     * 
     * @type {string}
     * @memberof PatientVerifyCurrentEmailDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface PhotoUploadSignedUrlDto
 */
export interface PhotoUploadSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof PhotoUploadSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface PhysicalActivity
 */
export interface PhysicalActivity {
    /**
     * 
     * @type {string}
     * @memberof PhysicalActivity
     */
    'level': PhysicalActivityLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof PhysicalActivity
     */
    'commentText'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PhysicalActivityLevelEnum {
    High = 'high',
    Medium = 'medium',
    Low = 'low',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface PrescribePatientNutritionPlanDto
 */
export interface PrescribePatientNutritionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof PrescribePatientNutritionPlanDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof PrescribePatientNutritionPlanDto
     */
    'patientNutritionPlanId': string;
}
/**
 * 
 * @export
 * @interface Products
 */
export interface Products {
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'vegetables': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'fruits': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'animalProtein': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'vegetableProtein': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'nutsAndSeeds': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'oils': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'cereals': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'milkProducts': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'drinks': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'nutritionalSupplements': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'sweets': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'sweeteners': string;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    'condiments': string;
}
/**
 * 
 * @export
 * @interface Promocode
 */
export interface Promocode {
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'period': PromocodePeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'expertId': string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    'patientId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum PromocodePeriodEnum {
    Day = 'DAY',
    Month = 'MONTH',
    ThreeMonths = 'THREE_MONTHS',
    SixMonths = 'SIX_MONTHS',
    Year = 'YEAR'
}

/**
 * 
 * @export
 * @interface PromocodeGenerateDto
 */
export interface PromocodeGenerateDto {
    /**
     * 
     * @type {number}
     * @memberof PromocodeGenerateDto
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PromocodeGenerateDto
     */
    'period': PromocodeGenerateDtoPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PromocodeGenerateDtoPeriodEnum {
    Day = 'DAY',
    Month = 'MONTH',
    ThreeMonths = 'THREE_MONTHS',
    SixMonths = 'SIX_MONTHS',
    Year = 'YEAR'
}

/**
 * 
 * @export
 * @interface RedirectUrlDto
 */
export interface RedirectUrlDto {
    /**
     * 
     * @type {string}
     * @memberof RedirectUrlDto
     */
    'redirectUrl': string;
}
/**
 * 
 * @export
 * @interface RepliedMessage
 */
export interface RepliedMessage {
    /**
     * 
     * @type {string}
     * @memberof RepliedMessage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RepliedMessage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RepliedMessage
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof RepliedMessage
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepliedMessage
     */
    'attachments': Array<string>;
    /**
     * 
     * @type {Sender}
     * @memberof RepliedMessage
     */
    'sender': Sender;
}
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'expertId': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SearchAnalyzesResDto
 */
export interface SearchAnalyzesResDto {
    /**
     * 
     * @type {Array<Analysis>}
     * @memberof SearchAnalyzesResDto
     */
    'data': Array<Analysis>;
    /**
     * 
     * @type {number}
     * @memberof SearchAnalyzesResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchAnalyzesResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchAnalyzesResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchAnalyzesResDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface SearchBiomarkersResDto
 */
export interface SearchBiomarkersResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchBiomarkersResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchBiomarkersResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchBiomarkersResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchBiomarkersResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<BiomarkerGetResDto>}
     * @memberof SearchBiomarkersResDto
     */
    'data': Array<BiomarkerGetResDto>;
}
/**
 * 
 * @export
 * @interface SearchExpertsResDto
 */
export interface SearchExpertsResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchExpertsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchExpertsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchExpertsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchExpertsResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<Expert>}
     * @memberof SearchExpertsResDto
     */
    'data': Array<Expert>;
}
/**
 * 
 * @export
 * @interface SearchNutritionPlansResDto
 */
export interface SearchNutritionPlansResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchNutritionPlansResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchNutritionPlansResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchNutritionPlansResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchNutritionPlansResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<NutritionPlan>}
     * @memberof SearchNutritionPlansResDto
     */
    'data': Array<NutritionPlan>;
}
/**
 * 
 * @export
 * @interface SearchPaginatedAnalysisKindsResDto
 */
export interface SearchPaginatedAnalysisKindsResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchPaginatedAnalysisKindsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchPaginatedAnalysisKindsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchPaginatedAnalysisKindsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchPaginatedAnalysisKindsResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<AnalysisKindEntity>}
     * @memberof SearchPaginatedAnalysisKindsResDto
     */
    'data': Array<AnalysisKindEntity>;
}
/**
 * 
 * @export
 * @interface SearchSecondTypeCardsResDto
 */
export interface SearchSecondTypeCardsResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchSecondTypeCardsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchSecondTypeCardsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchSecondTypeCardsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchSecondTypeCardsResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<SecondTypeCard>}
     * @memberof SearchSecondTypeCardsResDto
     */
    'data': Array<SecondTypeCard>;
}
/**
 * 
 * @export
 * @interface SearchThirdTypeCardsResDto
 */
export interface SearchThirdTypeCardsResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchThirdTypeCardsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchThirdTypeCardsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchThirdTypeCardsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchThirdTypeCardsResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<ThirdTypeCard>}
     * @memberof SearchThirdTypeCardsResDto
     */
    'data': Array<ThirdTypeCard>;
}
/**
 * 
 * @export
 * @interface SearchUnitsResDto
 */
export interface SearchUnitsResDto {
    /**
     * 
     * @type {number}
     * @memberof SearchUnitsResDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchUnitsResDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SearchUnitsResDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchUnitsResDto
     */
    'pageCount': number;
    /**
     * 
     * @type {Array<Unit>}
     * @memberof SearchUnitsResDto
     */
    'data': Array<Unit>;
}
/**
 * 
 * @export
 * @interface SecondTypeCard
 */
export interface SecondTypeCard {
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<SecondTypeCardContent>}
     * @memberof SecondTypeCard
     */
    'contents': Array<SecondTypeCardContent>;
    /**
     * 
     * @type {Array<SecondTypeCardsBiomarker>}
     * @memberof SecondTypeCard
     */
    'stcBiomarkers'?: Array<SecondTypeCardsBiomarker>;
    /**
     * 
     * @type {Array<SecondTypeCardsPatient>}
     * @memberof SecondTypeCard
     */
    'stcPatients'?: Array<SecondTypeCardsPatient>;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCard
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface SecondTypeCardContent
 */
export interface SecondTypeCardContent {
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'secondTypeCardId': string;
    /**
     * 
     * @type {SecondTypeCard}
     * @memberof SecondTypeCardContent
     */
    'secondTypeCard'?: SecondTypeCard;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardContent
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface SecondTypeCardsBiomarker
 */
export interface SecondTypeCardsBiomarker {
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsBiomarker
     */
    'secondTypeCardId': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof SecondTypeCardsBiomarker
     */
    'biomarker'?: Biomarker;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsBiomarker
     */
    'biomarkerId': string;
    /**
     * 
     * @type {number}
     * @memberof SecondTypeCardsBiomarker
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof SecondTypeCardsBiomarker
     */
    'femaleNormField': number;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsBiomarker
     */
    'outsideNormZone': SecondTypeCardsBiomarkerOutsideNormZoneEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SecondTypeCardsBiomarkerOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface SecondTypeCardsPatient
 */
export interface SecondTypeCardsPatient {
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatient
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatient
     */
    'secondTypeCardId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatient
     */
    'patientId': string;
}
/**
 * 
 * @export
 * @interface SecondTypeCardsPatientGetResDto
 */
export interface SecondTypeCardsPatientGetResDto {
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'secondTypeCardId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof SecondTypeCardsPatientGetResDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface SendMessageToBiogeekSupportDto
 */
export interface SendMessageToBiogeekSupportDto {
    /**
     * 
     * @type {string}
     * @memberof SendMessageToBiogeekSupportDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SendMessageToBiogeekSupportDto
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface Sender
 */
export interface Sender {
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'kind': SenderKindEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SenderKindEnum {
    Patient = 'PATIENT',
    Expert = 'EXPERT'
}

/**
 * 
 * @export
 * @interface StressAmount
 */
export interface StressAmount {
    /**
     * 
     * @type {string}
     * @memberof StressAmount
     */
    'level': StressAmountLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof StressAmount
     */
    'commentText'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StressAmountLevelEnum {
    High = 'high',
    Medium = 'medium',
    Low = 'low',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'expertId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'accessUntil': string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'defaultPaymentMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'paymentPlatform': SubscriptionPaymentPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'paymentPlatformCustomerId': string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'subscriptionPlan': SubscriptionSubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'status': SubscriptionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    'hasPendingUpdate': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionPaymentPlatformEnum {
    Stripe = 'STRIPE',
    CloudPayments = 'CLOUD_PAYMENTS'
}
/**
    * @export
    * @enum {string}
    */
export enum SubscriptionSubscriptionPlanEnum {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    HighYear = 'high_year',
    MediumYear = 'medium_year',
    LowYear = 'low_year',
    HighMonth = 'high_month',
    MediumMonth = 'medium_month',
    LowMonth = 'low_month',
    HighDay = 'high_day',
    MediumDay = 'medium_day',
    LowDay = 'low_day'
}
/**
    * @export
    * @enum {string}
    */
export enum SubscriptionStatusEnum {
    Active = 'ACTIVE',
    CanceledExpired = 'CANCELED_EXPIRED',
    CanceledActive = 'CANCELED_ACTIVE',
    PromocodeActive = 'PROMOCODE_ACTIVE',
    PromocodeExpired = 'PROMOCODE_EXPIRED',
    Expired = 'EXPIRED',
    PastDue = 'PAST_DUE'
}

/**
 * 
 * @export
 * @interface SubscriptionConstantsDto
 */
export interface SubscriptionConstantsDto {
    /**
     * 
     * @type {SubscriptionPriceDto}
     * @memberof SubscriptionConstantsDto
     */
    'price': SubscriptionPriceDto;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionConstantsDto
     */
    'patientLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionConstantsDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionConstantsDto
     */
    'interval': string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionConstantsDto
     */
    'period': number;
}
/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'expertId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'patientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'accessUntil': string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'defaultPaymentMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'paymentPlatform': SubscriptionDtoPaymentPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'paymentPlatformCustomerId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'subscriptionPlan': SubscriptionDtoSubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'status': SubscriptionDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    'hasPendingUpdate': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionDtoPaymentPlatformEnum {
    Stripe = 'STRIPE',
    CloudPayments = 'CLOUD_PAYMENTS'
}
/**
    * @export
    * @enum {string}
    */
export enum SubscriptionDtoSubscriptionPlanEnum {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    HighYear = 'high_year',
    MediumYear = 'medium_year',
    LowYear = 'low_year',
    HighMonth = 'high_month',
    MediumMonth = 'medium_month',
    LowMonth = 'low_month',
    HighDay = 'high_day',
    MediumDay = 'medium_day',
    LowDay = 'low_day'
}
/**
    * @export
    * @enum {string}
    */
export enum SubscriptionDtoStatusEnum {
    Active = 'ACTIVE',
    CanceledExpired = 'CANCELED_EXPIRED',
    CanceledActive = 'CANCELED_ACTIVE',
    PromocodeActive = 'PROMOCODE_ACTIVE',
    PromocodeExpired = 'PROMOCODE_EXPIRED',
    Expired = 'EXPIRED',
    PastDue = 'PAST_DUE'
}

/**
 * 
 * @export
 * @interface SubscriptionPriceDto
 */
export interface SubscriptionPriceDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPriceDto
     */
    'rub': number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPriceDto
     */
    'usd': number;
}
/**
 * 
 * @export
 * @interface SubscriptionsConstantsResponseDto
 */
export interface SubscriptionsConstantsResponseDto {
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'year'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'month'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'day'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'high_year'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'medium_year'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'low_year'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'high_month'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'medium_month'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'low_month'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'high_day'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'medium_day'?: SubscriptionConstantsDto;
    /**
     * 
     * @type {SubscriptionConstantsDto}
     * @memberof SubscriptionsConstantsResponseDto
     */
    'low_day'?: SubscriptionConstantsDto;
}
/**
 * 
 * @export
 * @interface ThirdTypeCard
 */
export interface ThirdTypeCard {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'dysfunctionType': ThirdTypeCardDysfunctionTypeEnum;
    /**
     * 
     * @type {Array<ThirdTypeCardsBiomarkersRatio>}
     * @memberof ThirdTypeCard
     */
    'ratios': Array<ThirdTypeCardsBiomarkersRatio>;
    /**
     * 
     * @type {Array<ThirdTypeCardsBiomarker>}
     * @memberof ThirdTypeCard
     */
    'ttcBiomarkers'?: Array<ThirdTypeCardsBiomarker>;
    /**
     * 
     * @type {Array<ThirdTypeCardContent>}
     * @memberof ThirdTypeCard
     */
    'contents': Array<ThirdTypeCardContent>;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCard
     */
    'content': string;
    /**
     * 
     * @type {Array<ThirdTypeCardsPatient>}
     * @memberof ThirdTypeCard
     */
    'ttcPatients'?: Array<ThirdTypeCardsPatient>;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardDysfunctionTypeEnum {
    Dysfunction = 'dysfunction',
    NutrientStatus = 'nutrient-status'
}

/**
 * 
 * @export
 * @interface ThirdTypeCardBiomarkerDto
 */
export interface ThirdTypeCardBiomarkerDto {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardBiomarkerDto
     */
    'biomarkerId': string;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardBiomarkerDto
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardBiomarkerDto
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardBiomarkerDto
     */
    'femaleNormField': number;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardBiomarkerDto
     */
    'outsideNormZone': ThirdTypeCardBiomarkerDtoOutsideNormZoneEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardBiomarkerDtoOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface ThirdTypeCardContent
 */
export interface ThirdTypeCardContent {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'content': string;
    /**
     * 
     * @type {ThirdTypeCard}
     * @memberof ThirdTypeCardContent
     */
    'thirdTypeCard': ThirdTypeCard;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardContent
     */
    'thirdTypeCardId': string;
}
/**
 * 
 * @export
 * @interface ThirdTypeCardRatiosDto
 */
export interface ThirdTypeCardRatiosDto {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardRatiosDto
     */
    'dividendBiomarkerId': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardRatiosDto
     */
    'dividerBiomarkerId': string;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardRatiosDto
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardRatiosDto
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardRatiosDto
     */
    'femaleNormField': number;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardRatiosDto
     */
    'outsideNormZone': ThirdTypeCardRatiosDtoOutsideNormZoneEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardRatiosDtoOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface ThirdTypeCardsBiomarker
 */
export interface ThirdTypeCardsBiomarker {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarker
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarker
     */
    'thirdTypeCardId': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof ThirdTypeCardsBiomarker
     */
    'biomarker': Biomarker;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarker
     */
    'biomarkerId': string;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarker
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarker
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarker
     */
    'femaleNormField': number;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarker
     */
    'outsideNormZone': ThirdTypeCardsBiomarkerOutsideNormZoneEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardsBiomarkerOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface ThirdTypeCardsBiomarkersRatio
 */
export interface ThirdTypeCardsBiomarkersRatio {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'id': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'dividendBiomarker': Biomarker;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'dividendBiomarkerId': string;
    /**
     * 
     * @type {Biomarker}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'dividerBiomarker': Biomarker;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'dividerBiomarkerId': string;
    /**
     * 
     * @type {ThirdTypeCard}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'thirdTypeCard': ThirdTypeCard;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'maleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'femaleNormField': number;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsBiomarkersRatio
     */
    'outsideNormZone': ThirdTypeCardsBiomarkersRatioOutsideNormZoneEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardsBiomarkersRatioOutsideNormZoneEnum {
    Greater = 'greater',
    Lower = 'lower'
}

/**
 * 
 * @export
 * @interface ThirdTypeCardsPatient
 */
export interface ThirdTypeCardsPatient {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatient
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsPatient
     */
    'scale': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThirdTypeCardsPatient
     */
    'missingBiomarkers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatient
     */
    'thirdTypeCardId': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatient
     */
    'patientId': string;
}
/**
 * 
 * @export
 * @interface ThirdTypeCardsPatientGetResDto
 */
export interface ThirdTypeCardsPatientGetResDto {
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'thirdTypeCardId': string;
    /**
     * 
     * @type {number}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'scale': number;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'dysfunctionType': ThirdTypeCardsPatientGetResDtoDysfunctionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'missingBiomarkers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ThirdTypeCardsPatientGetResDto
     */
    'description': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ThirdTypeCardsPatientGetResDtoDysfunctionTypeEnum {
    Dysfunction = 'dysfunction',
    NutrientStatus = 'nutrient-status'
}

/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'trainingType': TrainingTrainingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'time': TrainingTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'duration': TrainingDurationEnum;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'commentary': string;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainingTrainingTypeEnum {
    Cardio = 'CARDIO',
    Tabata = 'TABATA',
    Strength = 'STRENGTH',
    Yoga = 'YOGA',
    Stretching = 'STRETCHING'
}
/**
    * @export
    * @enum {string}
    */
export enum TrainingTimeEnum {
    BeforeBreakfast = 'BEFORE_BREAKFAST',
    AfterBreakfast = 'AFTER_BREAKFAST',
    AfterLunch = 'AFTER_LUNCH',
    AfterDinner = 'AFTER_DINNER'
}
/**
    * @export
    * @enum {string}
    */
export enum TrainingDurationEnum {
    Twenty = 'TWENTY',
    HalfAnHour = 'HALF_AN_HOUR',
    FortyFive = 'FORTY_FIVE',
    Hour = 'HOUR',
    MoreThanHour = 'MORE_THAN_HOUR'
}

/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Unit
     */
    'coefficientsDisabled': boolean;
}
/**
 * 
 * @export
 * @interface UnitCoefficients
 */
export interface UnitCoefficients {
    /**
     * 
     * @type {string}
     * @memberof UnitCoefficients
     */
    'sourceUnitId': string;
    /**
     * 
     * @type {string}
     * @memberof UnitCoefficients
     */
    'compareUnitId': string;
    /**
     * 
     * @type {number}
     * @memberof UnitCoefficients
     */
    'coefficient': number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitCoefficients
     */
    'useNumberMoles': boolean;
}
/**
 * 
 * @export
 * @interface UnitsCoefficientsDto
 */
export interface UnitsCoefficientsDto {
    /**
     * 
     * @type {string}
     * @memberof UnitsCoefficientsDto
     */
    'compareUnitId': string;
    /**
     * 
     * @type {number}
     * @memberof UnitsCoefficientsDto
     */
    'coefficient': number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitsCoefficientsDto
     */
    'useNumberMoles': boolean;
}
/**
 * 
 * @export
 * @interface UnitsOptionDto
 */
export interface UnitsOptionDto {
    /**
     * 
     * @type {string}
     * @memberof UnitsOptionDto
     */
    'unit': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitsOptionDto
     */
    'biomarkers': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitsOptionDto
     */
    'compareUnits': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UnitsOptionDto
     */
    'coeffs': Array<number>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof UnitsOptionDto
     */
    'useNumberMoles': Array<boolean>;
}
/**
 * 
 * @export
 * @interface UpdateAnalysisKindDto
 */
export interface UpdateAnalysisKindDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnalysisKindDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnalysisKindDto
     */
    'label'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAnalysisKindDto
     */
    'biomarkersIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBiomarkerDto
 */
export interface UpdateBiomarkerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBiomarkerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBiomarkerDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBiomarkerDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBiomarkerDto
     */
    'unitId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'lowerNormMale'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'upperNormMale'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'lowerNormFemale'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'upperNormFemale'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'lowerNormConventional'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'upperNormConventional'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'lowerNormInvalidValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'upperNormInvalidValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBiomarkerDto
     */
    'numberMoles'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBiomarkerDto
     */
    'analysisKinds'?: Array<UpdateBiomarkerDtoAnalysisKindsEnum>;
    /**
     * 
     * @type {FirstTypeCardDto}
     * @memberof UpdateBiomarkerDto
     */
    'ftcValues'?: FirstTypeCardDto;
    /**
     * 
     * @type {Array<AnalysisKindsBiomarkersCreateDto>}
     * @memberof UpdateBiomarkerDto
     */
    'analysisKindsBiomarkers'?: Array<AnalysisKindsBiomarkersCreateDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateBiomarkerDtoAnalysisKindsEnum {
    General = 'general',
    Chemistry = 'chemistry',
    BiologicalAge = 'biological-age',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface UpdateExpertDto
 */
export interface UpdateExpertDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertDto
     */
    'educationDocumentStatus'?: UpdateExpertDtoEducationDocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertDto
     */
    'spesialization'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExpertDto
     */
    'isApproved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExpertDto
     */
    'isShadowBanned'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateExpertDtoEducationDocumentStatusEnum {
    NotSent = 'NOT_SENT',
    Checking = 'CHECKING',
    Approved = 'APPROVED',
    Declined = 'DECLINED'
}

/**
 * 
 * @export
 * @interface UpdateNutritionPlanDto
 */
export interface UpdateNutritionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNutritionPlanDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNutritionPlanDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNutritionPlanDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {IncludeProducts}
     * @memberof UpdateNutritionPlanDto
     */
    'includeProducts'?: IncludeProducts;
    /**
     * 
     * @type {ExcludeProducts}
     * @memberof UpdateNutritionPlanDto
     */
    'excludeProducts'?: ExcludeProducts;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNutritionPlanDto
     */
    'goals'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdatePatientAnalysisBiomarkersDto
 */
export interface UpdatePatientAnalysisBiomarkersDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientAnalysisBiomarkersDto
     */
    'patientId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientAnalysisBiomarkersDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientAnalysisBiomarkersDto
     */
    'biomarkerId': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientAnalysisDto
 */
export interface UpdatePatientAnalysisDto {
    /**
     * 
     * @type {Array<CreatePatientAnalysisBiomarkersDto>}
     * @memberof UpdatePatientAnalysisDto
     */
    'patientAnalysisBiomarkers': Array<CreatePatientAnalysisBiomarkersDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientAnalysisDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientDto
 */
export interface UpdatePatientDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDto
     */
    'gender'?: UpdatePatientDtoGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientDto
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDto
     */
    'avatar'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdatePatientDtoGenderEnum {
    Female = 'female',
    Male = 'male'
}

/**
 * 
 * @export
 * @interface UpdatePatientNutritionPlanDto
 */
export interface UpdatePatientNutritionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'patientNutritionPlanId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'goals'?: Array<string>;
    /**
     * 
     * @type {Products}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'includeProducts'?: Products;
    /**
     * 
     * @type {Products}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'excludeProducts'?: Products;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNutritionPlanDto
     */
    'additionalInfo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSecondTypeCardDto
 */
export interface UpdateSecondTypeCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondTypeCardDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondTypeCardDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondTypeCardDto
     */
    'content'?: string;
    /**
     * 
     * @type {Array<CreateSecondTypeCardBiomarkersDto>}
     * @memberof UpdateSecondTypeCardDto
     */
    'stcBiomarkers': Array<CreateSecondTypeCardBiomarkersDto>;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionDto
 */
export interface UpdateSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSubscriptionDto
     */
    'subscriptionDuration': UpdateSubscriptionDtoSubscriptionDurationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateSubscriptionDtoSubscriptionDurationEnum {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    HighYear = 'high_year',
    MediumYear = 'medium_year',
    LowYear = 'low_year',
    HighMonth = 'high_month',
    MediumMonth = 'medium_month',
    LowMonth = 'low_month',
    HighDay = 'high_day',
    MediumDay = 'medium_day',
    LowDay = 'low_day'
}

/**
 * 
 * @export
 * @interface UpdateThirdTypeCardDto
 */
export interface UpdateThirdTypeCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateThirdTypeCardDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateThirdTypeCardDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ThirdTypeCardBiomarkerDto>}
     * @memberof UpdateThirdTypeCardDto
     */
    'ttcBiomarkers'?: Array<ThirdTypeCardBiomarkerDto>;
    /**
     * 
     * @type {Array<ThirdTypeCardRatiosDto>}
     * @memberof UpdateThirdTypeCardDto
     */
    'ratios'?: Array<ThirdTypeCardRatiosDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdateThirdTypeCardDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateThirdTypeCardDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateThirdTypeCardDto
     */
    'dysfunctionType'?: UpdateThirdTypeCardDtoDysfunctionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateThirdTypeCardDtoDysfunctionTypeEnum {
    Dysfunction = 'dysfunction',
    NutrientStatus = 'nutrient-status'
}

/**
 * 
 * @export
 * @interface UpdateUnitDto
 */
export interface UpdateUnitDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUnitDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<UnitsCoefficientsDto>}
     * @memberof UpdateUnitDto
     */
    'unitCoefficients'?: Array<UnitsCoefficientsDto>;
    /**
     * 
     * @type {Array<UnitsCoefficientsDto>}
     * @memberof UpdateUnitDto
     */
    'coefficientsDisable'?: Array<UnitsCoefficientsDto>;
}
/**
 * 
 * @export
 * @interface UploadSignedUrlResDto
 */
export interface UploadSignedUrlResDto {
    /**
     * 
     * @type {string}
     * @memberof UploadSignedUrlResDto
     */
    'fileKey': string;
    /**
     * 
     * @type {string}
     * @memberof UploadSignedUrlResDto
     */
    'signedUrl': string;
}
/**
 * 
 * @export
 * @interface UsePromocodeDto
 */
export interface UsePromocodeDto {
    /**
     * 
     * @type {string}
     * @memberof UsePromocodeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Workout
 */
export interface Workout {
    /**
     * 
     * @type {Array<Training>}
     * @memberof Workout
     */
    'trainings': Array<Training>;
    /**
     * 
     * @type {string}
     * @memberof Workout
     */
    'stepsPerDay': WorkoutStepsPerDayEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkoutStepsPerDayEnum {
    _5000 = 'менее 5000 шагов',
    _50008000 = '5000-8000 шагов',
    _800012000 = '8000-12000 шагов',
    _12000 = 'более 12000 шагов'
}


/**
 * AdminsApi - axios parameter creator
 * @export
 */
export const AdminsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in admin by email and password and retrieve admin with bearer token
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn: async (adminSignInDto: AdminSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSignInDto' is not null or undefined
            assertParamExists('adminsControllerSignIn', 'adminSignInDto', adminSignInDto)
            const localVarPath = `/admins/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsApi - functional programming interface
 * @export
 */
export const AdminsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in admin by email and password and retrieve admin with bearer token
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerSignIn(adminSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsApi - factory interface
 * @export
 */
export const AdminsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerFindMe(options?: any): AxiosPromise<Admin> {
            return localVarFp.adminsControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in admin by email and password and retrieve admin with bearer token
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn(adminSignInDto: AdminSignInDto, options?: any): AxiosPromise<AdminAuthResDto> {
            return localVarFp.adminsControllerSignIn(adminSignInDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsApi - object-oriented interface
 * @export
 * @class AdminsApi
 * @extends {BaseAPI}
 */
export class AdminsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve current admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public adminsControllerFindMe(options?: AxiosRequestConfig) {
        return AdminsApiFp(this.configuration).adminsControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in admin by email and password and retrieve admin with bearer token
     * @param {AdminSignInDto} adminSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsApi
     */
    public adminsControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig) {
        return AdminsApiFp(this.configuration).adminsControllerSignIn(adminSignInDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsAnalysisKindsApi - axios parameter creator
 * @export
 */
export const AdminsAnalysisKindsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create analysis kind
         * @param {CreateAnalysisKindDto} createAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerCreateAnalysisKind: async (createAnalysisKindDto: CreateAnalysisKindDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnalysisKindDto' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerCreateAnalysisKind', 'createAnalysisKindDto', createAnalysisKindDto)
            const localVarPath = `/admins/analysis-kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnalysisKindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete analysis kind record by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerDeleteAnalysisKind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerDeleteAnalysisKind', 'id', id)
            const localVarPath = `/admins/analysis-kinds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one analysis kind by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerFindOneAnalysisKind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerFindOneAnalysisKind', 'id', id)
            const localVarPath = `/admins/analysis-kinds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated analysis kinds
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerGetAnalysisKinds: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerGetAnalysisKinds', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerGetAnalysisKinds', 'limit', limit)
            const localVarPath = `/admins/analysis-kinds/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many analysis kinds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerGetManyAnalysisKinds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/analysis-kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update analysis kind
         * @param {string} id 
         * @param {UpdateAnalysisKindDto} updateAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerUpdateAnalysisKind: async (id: string, updateAnalysisKindDto: UpdateAnalysisKindDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerUpdateAnalysisKind', 'id', id)
            // verify required parameter 'updateAnalysisKindDto' is not null or undefined
            assertParamExists('adminAnalysisKindsControllerUpdateAnalysisKind', 'updateAnalysisKindDto', updateAnalysisKindDto)
            const localVarPath = `/admins/analysis-kinds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnalysisKindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsAnalysisKindsApi - functional programming interface
 * @export
 */
export const AdminsAnalysisKindsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsAnalysisKindsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create analysis kind
         * @param {CreateAnalysisKindDto} createAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto: CreateAnalysisKindDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisKindGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete analysis kind record by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerDeleteAnalysisKind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerDeleteAnalysisKind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one analysis kind by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerFindOneAnalysisKind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisKindGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerFindOneAnalysisKind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated analysis kinds
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerGetAnalysisKinds(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPaginatedAnalysisKindsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerGetAnalysisKinds(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many analysis kinds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerGetManyAnalysisKinds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisKindGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerGetManyAnalysisKinds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update analysis kind
         * @param {string} id 
         * @param {UpdateAnalysisKindDto} updateAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAnalysisKindsControllerUpdateAnalysisKind(id: string, updateAnalysisKindDto: UpdateAnalysisKindDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisKindGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAnalysisKindsControllerUpdateAnalysisKind(id, updateAnalysisKindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsAnalysisKindsApi - factory interface
 * @export
 */
export const AdminsAnalysisKindsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsAnalysisKindsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create analysis kind
         * @param {CreateAnalysisKindDto} createAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto: CreateAnalysisKindDto, options?: any): AxiosPromise<AnalysisKindGetResDto> {
            return localVarFp.adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete analysis kind record by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerDeleteAnalysisKind(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminAnalysisKindsControllerDeleteAnalysisKind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one analysis kind by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerFindOneAnalysisKind(id: string, options?: any): AxiosPromise<AnalysisKindGetResDto> {
            return localVarFp.adminAnalysisKindsControllerFindOneAnalysisKind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated analysis kinds
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerGetAnalysisKinds(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchPaginatedAnalysisKindsResDto> {
            return localVarFp.adminAnalysisKindsControllerGetAnalysisKinds(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many analysis kinds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerGetManyAnalysisKinds(options?: any): AxiosPromise<Array<AnalysisKindGetResDto>> {
            return localVarFp.adminAnalysisKindsControllerGetManyAnalysisKinds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update analysis kind
         * @param {string} id 
         * @param {UpdateAnalysisKindDto} updateAnalysisKindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAnalysisKindsControllerUpdateAnalysisKind(id: string, updateAnalysisKindDto: UpdateAnalysisKindDto, options?: any): AxiosPromise<AnalysisKindGetResDto> {
            return localVarFp.adminAnalysisKindsControllerUpdateAnalysisKind(id, updateAnalysisKindDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsAnalysisKindsApi - object-oriented interface
 * @export
 * @class AdminsAnalysisKindsApi
 * @extends {BaseAPI}
 */
export class AdminsAnalysisKindsApi extends BaseAPI {
    /**
     * 
     * @summary Create analysis kind
     * @param {CreateAnalysisKindDto} createAnalysisKindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto: CreateAnalysisKindDto, options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerCreateAnalysisKind(createAnalysisKindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete analysis kind record by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerDeleteAnalysisKind(id: string, options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerDeleteAnalysisKind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one analysis kind by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerFindOneAnalysisKind(id: string, options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerFindOneAnalysisKind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated analysis kinds
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerGetAnalysisKinds(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerGetAnalysisKinds(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many analysis kinds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerGetManyAnalysisKinds(options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerGetManyAnalysisKinds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update analysis kind
     * @param {string} id 
     * @param {UpdateAnalysisKindDto} updateAnalysisKindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsAnalysisKindsApi
     */
    public adminAnalysisKindsControllerUpdateAnalysisKind(id: string, updateAnalysisKindDto: UpdateAnalysisKindDto, options?: AxiosRequestConfig) {
        return AdminsAnalysisKindsApiFp(this.configuration).adminAnalysisKindsControllerUpdateAnalysisKind(id, updateAnalysisKindDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsBiomarkersApi - axios parameter creator
 * @export
 */
export const AdminsBiomarkersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create biomarker
         * @param {CreateBiomarkerDto} createBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerCreateBiomarker: async (createBiomarkerDto: CreateBiomarkerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBiomarkerDto' is not null or undefined
            assertParamExists('adminBiomarkersControllerCreateBiomarker', 'createBiomarkerDto', createBiomarkerDto)
            const localVarPath = `/admins/biomarkers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBiomarkerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve biomarker by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerFindOneBiomarker: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBiomarkersControllerFindOneBiomarker', 'id', id)
            const localVarPath = `/admins/biomarkers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerGetAllBiomarkers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/biomarkers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated biomarkers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerGetPaginatedBiomarkers: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminBiomarkersControllerGetPaginatedBiomarkers', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminBiomarkersControllerGetPaginatedBiomarkers', 'limit', limit)
            const localVarPath = `/admins/paginated-biomarkers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove biomarker by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerRemoveBiomarker: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBiomarkersControllerRemoveBiomarker', 'id', id)
            const localVarPath = `/admins/biomarkers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update biomarker
         * @param {string} id 
         * @param {UpdateBiomarkerDto} updateBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerUpdateBiomarker: async (id: string, updateBiomarkerDto: UpdateBiomarkerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBiomarkersControllerUpdateBiomarker', 'id', id)
            // verify required parameter 'updateBiomarkerDto' is not null or undefined
            assertParamExists('adminBiomarkersControllerUpdateBiomarker', 'updateBiomarkerDto', updateBiomarkerDto)
            const localVarPath = `/admins/biomarkers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBiomarkerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsBiomarkersApi - functional programming interface
 * @export
 */
export const AdminsBiomarkersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsBiomarkersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create biomarker
         * @param {CreateBiomarkerDto} createBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerCreateBiomarker(createBiomarkerDto: CreateBiomarkerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Biomarker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerCreateBiomarker(createBiomarkerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve biomarker by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerFindOneBiomarker(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Biomarker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerFindOneBiomarker(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerGetAllBiomarkers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Biomarker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerGetAllBiomarkers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated biomarkers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerGetPaginatedBiomarkers(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchBiomarkersResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerGetPaginatedBiomarkers(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove biomarker by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerRemoveBiomarker(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerRemoveBiomarker(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update biomarker
         * @param {string} id 
         * @param {UpdateBiomarkerDto} updateBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBiomarkersControllerUpdateBiomarker(id: string, updateBiomarkerDto: UpdateBiomarkerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Biomarker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBiomarkersControllerUpdateBiomarker(id, updateBiomarkerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsBiomarkersApi - factory interface
 * @export
 */
export const AdminsBiomarkersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsBiomarkersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create biomarker
         * @param {CreateBiomarkerDto} createBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerCreateBiomarker(createBiomarkerDto: CreateBiomarkerDto, options?: any): AxiosPromise<Biomarker> {
            return localVarFp.adminBiomarkersControllerCreateBiomarker(createBiomarkerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve biomarker by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerFindOneBiomarker(id: string, options?: any): AxiosPromise<Biomarker> {
            return localVarFp.adminBiomarkersControllerFindOneBiomarker(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerGetAllBiomarkers(options?: any): AxiosPromise<Array<Biomarker>> {
            return localVarFp.adminBiomarkersControllerGetAllBiomarkers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated biomarkers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerGetPaginatedBiomarkers(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchBiomarkersResDto> {
            return localVarFp.adminBiomarkersControllerGetPaginatedBiomarkers(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove biomarker by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerRemoveBiomarker(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminBiomarkersControllerRemoveBiomarker(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update biomarker
         * @param {string} id 
         * @param {UpdateBiomarkerDto} updateBiomarkerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBiomarkersControllerUpdateBiomarker(id: string, updateBiomarkerDto: UpdateBiomarkerDto, options?: any): AxiosPromise<Biomarker> {
            return localVarFp.adminBiomarkersControllerUpdateBiomarker(id, updateBiomarkerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsBiomarkersApi - object-oriented interface
 * @export
 * @class AdminsBiomarkersApi
 * @extends {BaseAPI}
 */
export class AdminsBiomarkersApi extends BaseAPI {
    /**
     * 
     * @summary Create biomarker
     * @param {CreateBiomarkerDto} createBiomarkerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerCreateBiomarker(createBiomarkerDto: CreateBiomarkerDto, options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerCreateBiomarker(createBiomarkerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve biomarker by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerFindOneBiomarker(id: string, options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerFindOneBiomarker(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all biomarkers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerGetAllBiomarkers(options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerGetAllBiomarkers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated biomarkers
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerGetPaginatedBiomarkers(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerGetPaginatedBiomarkers(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove biomarker by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerRemoveBiomarker(id: string, options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerRemoveBiomarker(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update biomarker
     * @param {string} id 
     * @param {UpdateBiomarkerDto} updateBiomarkerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsBiomarkersApi
     */
    public adminBiomarkersControllerUpdateBiomarker(id: string, updateBiomarkerDto: UpdateBiomarkerDto, options?: AxiosRequestConfig) {
        return AdminsBiomarkersApiFp(this.configuration).adminBiomarkersControllerUpdateBiomarker(id, updateBiomarkerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsChatsApi - axios parameter creator
 * @export
 */
export const AdminsChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated chat messages
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChatsControllerFindChatMessages: async (id: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminChatsControllerFindChatMessages', 'id', id)
            const localVarPath = `/admins/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated chats
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChatsControllerSearchPaginatedChats: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminChatsControllerSearchPaginatedChats', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminChatsControllerSearchPaginatedChats', 'limit', limit)
            const localVarPath = `/admins/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsChatsApi - functional programming interface
 * @export
 */
export const AdminsChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get paginated chat messages
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminChatsControllerFindChatMessages(id: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetPaginatedChatMessages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminChatsControllerFindChatMessages(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated chats
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminChatsControllerSearchPaginatedChats(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetPaginatedChats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminChatsControllerSearchPaginatedChats(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsChatsApi - factory interface
 * @export
 */
export const AdminsChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get paginated chat messages
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChatsControllerFindChatMessages(id: string, filter?: string, options?: any): AxiosPromise<AdminGetPaginatedChatMessages> {
            return localVarFp.adminChatsControllerFindChatMessages(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated chats
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChatsControllerSearchPaginatedChats(page: number, limit: number, filter?: string, options?: any): AxiosPromise<AdminGetPaginatedChats> {
            return localVarFp.adminChatsControllerSearchPaginatedChats(page, limit, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsChatsApi - object-oriented interface
 * @export
 * @class AdminsChatsApi
 * @extends {BaseAPI}
 */
export class AdminsChatsApi extends BaseAPI {
    /**
     * 
     * @summary Get paginated chat messages
     * @param {string} id 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsChatsApi
     */
    public adminChatsControllerFindChatMessages(id: string, filter?: string, options?: AxiosRequestConfig) {
        return AdminsChatsApiFp(this.configuration).adminChatsControllerFindChatMessages(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated chats
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsChatsApi
     */
    public adminChatsControllerSearchPaginatedChats(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsChatsApiFp(this.configuration).adminChatsControllerSearchPaginatedChats(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsCheckupsApi - axios parameter creator
 * @export
 */
export const AdminsCheckupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create checkup
         * @param {AdminCreateCheckupDto} adminCreateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerCreateCheckup: async (adminCreateCheckupDto: AdminCreateCheckupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateCheckupDto' is not null or undefined
            assertParamExists('adminCheckupsControllerCreateCheckup', 'adminCreateCheckupDto', adminCreateCheckupDto)
            const localVarPath = `/admins/checkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateCheckupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerDeleteCheckup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCheckupsControllerDeleteCheckup', 'id', id)
            const localVarPath = `/admins/checkups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signed url to upload checkup image to s3 bucket
         * @param {CheckupUploadSignedUrlDto} checkupUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetCheckupImageSignedUrlToUpload: async (checkupUploadSignedUrlDto: CheckupUploadSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkupUploadSignedUrlDto' is not null or undefined
            assertParamExists('adminCheckupsControllerGetCheckupImageSignedUrlToUpload', 'checkupUploadSignedUrlDto', checkupUploadSignedUrlDto)
            const localVarPath = `/admins/checkups/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkupUploadSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all checkups with pagination
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetCheckups: async (page: number, limit: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminCheckupsControllerGetCheckups', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminCheckupsControllerGetCheckups', 'limit', limit)
            const localVarPath = `/admins/checkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetOneCheckup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCheckupsControllerGetOneCheckup', 'id', id)
            const localVarPath = `/admins/checkups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update checkup
         * @param {string} id 
         * @param {AdminUpdateCheckupDto} adminUpdateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerUpdateCheckup: async (id: string, adminUpdateCheckupDto: AdminUpdateCheckupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCheckupsControllerUpdateCheckup', 'id', id)
            // verify required parameter 'adminUpdateCheckupDto' is not null or undefined
            assertParamExists('adminCheckupsControllerUpdateCheckup', 'adminUpdateCheckupDto', adminUpdateCheckupDto)
            const localVarPath = `/admins/checkups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateCheckupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsCheckupsApi - functional programming interface
 * @export
 */
export const AdminsCheckupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsCheckupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create checkup
         * @param {AdminCreateCheckupDto} adminCreateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerCreateCheckup(adminCreateCheckupDto: AdminCreateCheckupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Checkup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerCreateCheckup(adminCreateCheckupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerDeleteCheckup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerDeleteCheckup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get signed url to upload checkup image to s3 bucket
         * @param {CheckupUploadSignedUrlDto} checkupUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto: CheckupUploadSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all checkups with pagination
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerGetCheckups(page: number, limit: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCheckupsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerGetCheckups(page, limit, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerGetOneCheckup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Checkup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerGetOneCheckup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update checkup
         * @param {string} id 
         * @param {AdminUpdateCheckupDto} adminUpdateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckupsControllerUpdateCheckup(id: string, adminUpdateCheckupDto: AdminUpdateCheckupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Checkup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckupsControllerUpdateCheckup(id, adminUpdateCheckupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsCheckupsApi - factory interface
 * @export
 */
export const AdminsCheckupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsCheckupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create checkup
         * @param {AdminCreateCheckupDto} adminCreateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerCreateCheckup(adminCreateCheckupDto: AdminCreateCheckupDto, options?: any): AxiosPromise<Checkup> {
            return localVarFp.adminCheckupsControllerCreateCheckup(adminCreateCheckupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerDeleteCheckup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminCheckupsControllerDeleteCheckup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signed url to upload checkup image to s3 bucket
         * @param {CheckupUploadSignedUrlDto} checkupUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto: CheckupUploadSignedUrlDto, options?: any): AxiosPromise<UploadSignedUrlResDto> {
            return localVarFp.adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all checkups with pagination
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetCheckups(page: number, limit: number, name?: string, options?: any): AxiosPromise<AdminGetCheckupsResDto> {
            return localVarFp.adminCheckupsControllerGetCheckups(page, limit, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerGetOneCheckup(id: string, options?: any): AxiosPromise<Checkup> {
            return localVarFp.adminCheckupsControllerGetOneCheckup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update checkup
         * @param {string} id 
         * @param {AdminUpdateCheckupDto} adminUpdateCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckupsControllerUpdateCheckup(id: string, adminUpdateCheckupDto: AdminUpdateCheckupDto, options?: any): AxiosPromise<Checkup> {
            return localVarFp.adminCheckupsControllerUpdateCheckup(id, adminUpdateCheckupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsCheckupsApi - object-oriented interface
 * @export
 * @class AdminsCheckupsApi
 * @extends {BaseAPI}
 */
export class AdminsCheckupsApi extends BaseAPI {
    /**
     * 
     * @summary Create checkup
     * @param {AdminCreateCheckupDto} adminCreateCheckupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerCreateCheckup(adminCreateCheckupDto: AdminCreateCheckupDto, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerCreateCheckup(adminCreateCheckupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete checkup
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerDeleteCheckup(id: string, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerDeleteCheckup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signed url to upload checkup image to s3 bucket
     * @param {CheckupUploadSignedUrlDto} checkupUploadSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto: CheckupUploadSignedUrlDto, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerGetCheckupImageSignedUrlToUpload(checkupUploadSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all checkups with pagination
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerGetCheckups(page: number, limit: number, name?: string, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerGetCheckups(page, limit, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one checkup
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerGetOneCheckup(id: string, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerGetOneCheckup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update checkup
     * @param {string} id 
     * @param {AdminUpdateCheckupDto} adminUpdateCheckupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsCheckupsApi
     */
    public adminCheckupsControllerUpdateCheckup(id: string, adminUpdateCheckupDto: AdminUpdateCheckupDto, options?: AxiosRequestConfig) {
        return AdminsCheckupsApiFp(this.configuration).adminCheckupsControllerUpdateCheckup(id, adminUpdateCheckupDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsExpertsApi - axios parameter creator
 * @export
 */
export const AdminsExpertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED'} [documentStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerGetExperts: async (page: number, limit: number, filter?: string, documentStatus?: 'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminExpertsControllerGetExperts', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminExpertsControllerGetExperts', 'limit', limit)
            const localVarPath = `/admins/experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (documentStatus !== undefined) {
                localVarQueryParameter['documentStatus'] = documentStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerGetOneExpert: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExpertsControllerGetOneExpert', 'id', id)
            const localVarPath = `/admins/experts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change expert education documents status
         * @param {string} id 
         * @param {UpdateExpertDto} updateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerUpdateExpert: async (id: string, updateExpertDto: UpdateExpertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExpertsControllerUpdateExpert', 'id', id)
            // verify required parameter 'updateExpertDto' is not null or undefined
            assertParamExists('adminExpertsControllerUpdateExpert', 'updateExpertDto', updateExpertDto)
            const localVarPath = `/admins/experts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsExpertsApi - functional programming interface
 * @export
 */
export const AdminsExpertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsExpertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED'} [documentStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExpertsControllerGetExperts(page: number, limit: number, filter?: string, documentStatus?: 'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchExpertsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExpertsControllerGetExperts(page, limit, filter, documentStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExpertsControllerGetOneExpert(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExpertsControllerGetOneExpert(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change expert education documents status
         * @param {string} id 
         * @param {UpdateExpertDto} updateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExpertsControllerUpdateExpert(id: string, updateExpertDto: UpdateExpertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExpertsControllerUpdateExpert(id, updateExpertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsExpertsApi - factory interface
 * @export
 */
export const AdminsExpertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsExpertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED'} [documentStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerGetExperts(page: number, limit: number, filter?: string, documentStatus?: 'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED', options?: any): AxiosPromise<SearchExpertsResDto> {
            return localVarFp.adminExpertsControllerGetExperts(page, limit, filter, documentStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerGetOneExpert(id: string, options?: any): AxiosPromise<Expert> {
            return localVarFp.adminExpertsControllerGetOneExpert(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change expert education documents status
         * @param {string} id 
         * @param {UpdateExpertDto} updateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExpertsControllerUpdateExpert(id: string, updateExpertDto: UpdateExpertDto, options?: any): AxiosPromise<Expert> {
            return localVarFp.adminExpertsControllerUpdateExpert(id, updateExpertDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsExpertsApi - object-oriented interface
 * @export
 * @class AdminsExpertsApi
 * @extends {BaseAPI}
 */
export class AdminsExpertsApi extends BaseAPI {
    /**
     * 
     * @summary Get all experts
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED'} [documentStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsExpertsApi
     */
    public adminExpertsControllerGetExperts(page: number, limit: number, filter?: string, documentStatus?: 'NOT_SENT' | 'CHECKING' | 'APPROVED' | 'DECLINED', options?: AxiosRequestConfig) {
        return AdminsExpertsApiFp(this.configuration).adminExpertsControllerGetExperts(page, limit, filter, documentStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one expert
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsExpertsApi
     */
    public adminExpertsControllerGetOneExpert(id: string, options?: AxiosRequestConfig) {
        return AdminsExpertsApiFp(this.configuration).adminExpertsControllerGetOneExpert(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change expert education documents status
     * @param {string} id 
     * @param {UpdateExpertDto} updateExpertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsExpertsApi
     */
    public adminExpertsControllerUpdateExpert(id: string, updateExpertDto: UpdateExpertDto, options?: AxiosRequestConfig) {
        return AdminsExpertsApiFp(this.configuration).adminExpertsControllerUpdateExpert(id, updateExpertDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsNutritionPlansApi - axios parameter creator
 * @export
 */
export const AdminsNutritionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create nutrition plan
         * @param {CreateNutritionPlanDto} createNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerCreateNutritionPlan: async (createNutritionPlanDto: CreateNutritionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNutritionPlanDto' is not null or undefined
            assertParamExists('adminNutritionPlansControllerCreateNutritionPlan', 'createNutritionPlanDto', createNutritionPlanDto)
            const localVarPath = `/admins/nutrition-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNutritionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerDeleteNutritionPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminNutritionPlansControllerDeleteNutritionPlan', 'id', id)
            const localVarPath = `/admins/nutrition-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerFindOneNutritionPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminNutritionPlansControllerFindOneNutritionPlan', 'id', id)
            const localVarPath = `/admins/nutrition-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated nutrition plans
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerSearchPaginatedNutritionPlans: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminNutritionPlansControllerSearchPaginatedNutritionPlans', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminNutritionPlansControllerSearchPaginatedNutritionPlans', 'limit', limit)
            const localVarPath = `/admins/nutrition-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update nutrition plan
         * @param {string} id 
         * @param {UpdateNutritionPlanDto} updateNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerUpdateNutritionPlan: async (id: string, updateNutritionPlanDto: UpdateNutritionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminNutritionPlansControllerUpdateNutritionPlan', 'id', id)
            // verify required parameter 'updateNutritionPlanDto' is not null or undefined
            assertParamExists('adminNutritionPlansControllerUpdateNutritionPlan', 'updateNutritionPlanDto', updateNutritionPlanDto)
            const localVarPath = `/admins/nutrition-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNutritionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsNutritionPlansApi - functional programming interface
 * @export
 */
export const AdminsNutritionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsNutritionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create nutrition plan
         * @param {CreateNutritionPlanDto} createNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto: CreateNutritionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNutritionPlansControllerDeleteNutritionPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNutritionPlansControllerDeleteNutritionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNutritionPlansControllerFindOneNutritionPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNutritionPlansControllerFindOneNutritionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated nutrition plans
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNutritionPlansControllerSearchPaginatedNutritionPlans(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchNutritionPlansResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNutritionPlansControllerSearchPaginatedNutritionPlans(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update nutrition plan
         * @param {string} id 
         * @param {UpdateNutritionPlanDto} updateNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNutritionPlansControllerUpdateNutritionPlan(id: string, updateNutritionPlanDto: UpdateNutritionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNutritionPlansControllerUpdateNutritionPlan(id, updateNutritionPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsNutritionPlansApi - factory interface
 * @export
 */
export const AdminsNutritionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsNutritionPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Create nutrition plan
         * @param {CreateNutritionPlanDto} createNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto: CreateNutritionPlanDto, options?: any): AxiosPromise<NutritionPlan> {
            return localVarFp.adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerDeleteNutritionPlan(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminNutritionPlansControllerDeleteNutritionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerFindOneNutritionPlan(id: string, options?: any): AxiosPromise<NutritionPlan> {
            return localVarFp.adminNutritionPlansControllerFindOneNutritionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated nutrition plans
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerSearchPaginatedNutritionPlans(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchNutritionPlansResDto> {
            return localVarFp.adminNutritionPlansControllerSearchPaginatedNutritionPlans(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update nutrition plan
         * @param {string} id 
         * @param {UpdateNutritionPlanDto} updateNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNutritionPlansControllerUpdateNutritionPlan(id: string, updateNutritionPlanDto: UpdateNutritionPlanDto, options?: any): AxiosPromise<NutritionPlan> {
            return localVarFp.adminNutritionPlansControllerUpdateNutritionPlan(id, updateNutritionPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsNutritionPlansApi - object-oriented interface
 * @export
 * @class AdminsNutritionPlansApi
 * @extends {BaseAPI}
 */
export class AdminsNutritionPlansApi extends BaseAPI {
    /**
     * 
     * @summary Create nutrition plan
     * @param {CreateNutritionPlanDto} createNutritionPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsNutritionPlansApi
     */
    public adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto: CreateNutritionPlanDto, options?: AxiosRequestConfig) {
        return AdminsNutritionPlansApiFp(this.configuration).adminNutritionPlansControllerCreateNutritionPlan(createNutritionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete nutrition plan
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsNutritionPlansApi
     */
    public adminNutritionPlansControllerDeleteNutritionPlan(id: string, options?: AxiosRequestConfig) {
        return AdminsNutritionPlansApiFp(this.configuration).adminNutritionPlansControllerDeleteNutritionPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one nutrition plan
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsNutritionPlansApi
     */
    public adminNutritionPlansControllerFindOneNutritionPlan(id: string, options?: AxiosRequestConfig) {
        return AdminsNutritionPlansApiFp(this.configuration).adminNutritionPlansControllerFindOneNutritionPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated nutrition plans
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsNutritionPlansApi
     */
    public adminNutritionPlansControllerSearchPaginatedNutritionPlans(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsNutritionPlansApiFp(this.configuration).adminNutritionPlansControllerSearchPaginatedNutritionPlans(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update nutrition plan
     * @param {string} id 
     * @param {UpdateNutritionPlanDto} updateNutritionPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsNutritionPlansApi
     */
    public adminNutritionPlansControllerUpdateNutritionPlan(id: string, updateNutritionPlanDto: UpdateNutritionPlanDto, options?: AxiosRequestConfig) {
        return AdminsNutritionPlansApiFp(this.configuration).adminNutritionPlansControllerUpdateNutritionPlan(id, updateNutritionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsPromocodesApi - axios parameter creator
 * @export
 */
export const AdminsPromocodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PromocodeGenerateDto} promocodeGenerateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodesControllerGeneratePromocode: async (promocodeGenerateDto: PromocodeGenerateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promocodeGenerateDto' is not null or undefined
            assertParamExists('adminPromocodesControllerGeneratePromocode', 'promocodeGenerateDto', promocodeGenerateDto)
            const localVarPath = `/admins/promocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promocodeGenerateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR'} [period] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodesControllerGetList: async (page: number, limit: number, period?: 'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR', filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminPromocodesControllerGetList', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminPromocodesControllerGetList', 'limit', limit)
            const localVarPath = `/admins/promocodes/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsPromocodesApi - functional programming interface
 * @export
 */
export const AdminsPromocodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsPromocodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PromocodeGenerateDto} promocodeGenerateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPromocodesControllerGeneratePromocode(promocodeGenerateDto: PromocodeGenerateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promocode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPromocodesControllerGeneratePromocode(promocodeGenerateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR'} [period] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPromocodesControllerGetList(page: number, limit: number, period?: 'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR', filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPromocodesControllerGetList(page, limit, period, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsPromocodesApi - factory interface
 * @export
 */
export const AdminsPromocodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsPromocodesApiFp(configuration)
    return {
        /**
         * 
         * @param {PromocodeGenerateDto} promocodeGenerateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodesControllerGeneratePromocode(promocodeGenerateDto: PromocodeGenerateDto, options?: any): AxiosPromise<Array<Promocode>> {
            return localVarFp.adminPromocodesControllerGeneratePromocode(promocodeGenerateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR'} [period] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodesControllerGetList(page: number, limit: number, period?: 'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR', filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPromocodesControllerGetList(page, limit, period, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsPromocodesApi - object-oriented interface
 * @export
 * @class AdminsPromocodesApi
 * @extends {BaseAPI}
 */
export class AdminsPromocodesApi extends BaseAPI {
    /**
     * 
     * @param {PromocodeGenerateDto} promocodeGenerateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsPromocodesApi
     */
    public adminPromocodesControllerGeneratePromocode(promocodeGenerateDto: PromocodeGenerateDto, options?: AxiosRequestConfig) {
        return AdminsPromocodesApiFp(this.configuration).adminPromocodesControllerGeneratePromocode(promocodeGenerateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR'} [period] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsPromocodesApi
     */
    public adminPromocodesControllerGetList(page: number, limit: number, period?: 'DAY' | 'MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'YEAR', filter?: string, options?: AxiosRequestConfig) {
        return AdminsPromocodesApiFp(this.configuration).adminPromocodesControllerGetList(page, limit, period, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsSecondTypeCardsApi - axios parameter creator
 * @export
 */
export const AdminsSecondTypeCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create second type card
         * @param {CreateSecondTypeCardDto} createSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerCreateSecondTypeCard: async (createSecondTypeCardDto: CreateSecondTypeCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSecondTypeCardDto' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerCreateSecondTypeCard', 'createSecondTypeCardDto', createSecondTypeCardDto)
            const localVarPath = `/admins/recommendation-cards/second-type-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSecondTypeCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve second type card by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerFindOneSecondTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerFindOneSecondTypeCard', 'id', id)
            const localVarPath = `/admins/recommendation-cards/second-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove second type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerRemoveSecondTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerRemoveSecondTypeCard', 'id', id)
            const localVarPath = `/admins/recommendation-cards/second-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated second type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerSearchSecondTypeCards: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerSearchSecondTypeCards', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerSearchSecondTypeCards', 'limit', limit)
            const localVarPath = `/admins/recommendation-cards/second-type-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update second type card content
         * @param {string} id 
         * @param {UpdateSecondTypeCardDto} updateSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerUpdateSecondTypeCard: async (id: string, updateSecondTypeCardDto: UpdateSecondTypeCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerUpdateSecondTypeCard', 'id', id)
            // verify required parameter 'updateSecondTypeCardDto' is not null or undefined
            assertParamExists('adminSecondTypeCardsControllerUpdateSecondTypeCard', 'updateSecondTypeCardDto', updateSecondTypeCardDto)
            const localVarPath = `/admins/recommendation-cards/second-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSecondTypeCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsSecondTypeCardsApi - functional programming interface
 * @export
 */
export const AdminsSecondTypeCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsSecondTypeCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create second type card
         * @param {CreateSecondTypeCardDto} createSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto: CreateSecondTypeCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve second type card by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSecondTypeCardsControllerFindOneSecondTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSecondTypeCardsControllerFindOneSecondTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove second type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSecondTypeCardsControllerRemoveSecondTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSecondTypeCardsControllerRemoveSecondTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated second type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSecondTypeCardsControllerSearchSecondTypeCards(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchSecondTypeCardsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSecondTypeCardsControllerSearchSecondTypeCards(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update second type card content
         * @param {string} id 
         * @param {UpdateSecondTypeCardDto} updateSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSecondTypeCardsControllerUpdateSecondTypeCard(id: string, updateSecondTypeCardDto: UpdateSecondTypeCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSecondTypeCardsControllerUpdateSecondTypeCard(id, updateSecondTypeCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsSecondTypeCardsApi - factory interface
 * @export
 */
export const AdminsSecondTypeCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsSecondTypeCardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create second type card
         * @param {CreateSecondTypeCardDto} createSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto: CreateSecondTypeCardDto, options?: any): AxiosPromise<SecondTypeCard> {
            return localVarFp.adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve second type card by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerFindOneSecondTypeCard(id: string, options?: any): AxiosPromise<SecondTypeCard> {
            return localVarFp.adminSecondTypeCardsControllerFindOneSecondTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove second type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerRemoveSecondTypeCard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminSecondTypeCardsControllerRemoveSecondTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated second type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerSearchSecondTypeCards(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchSecondTypeCardsResDto> {
            return localVarFp.adminSecondTypeCardsControllerSearchSecondTypeCards(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update second type card content
         * @param {string} id 
         * @param {UpdateSecondTypeCardDto} updateSecondTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSecondTypeCardsControllerUpdateSecondTypeCard(id: string, updateSecondTypeCardDto: UpdateSecondTypeCardDto, options?: any): AxiosPromise<SecondTypeCard> {
            return localVarFp.adminSecondTypeCardsControllerUpdateSecondTypeCard(id, updateSecondTypeCardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsSecondTypeCardsApi - object-oriented interface
 * @export
 * @class AdminsSecondTypeCardsApi
 * @extends {BaseAPI}
 */
export class AdminsSecondTypeCardsApi extends BaseAPI {
    /**
     * 
     * @summary Create second type card
     * @param {CreateSecondTypeCardDto} createSecondTypeCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsSecondTypeCardsApi
     */
    public adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto: CreateSecondTypeCardDto, options?: AxiosRequestConfig) {
        return AdminsSecondTypeCardsApiFp(this.configuration).adminSecondTypeCardsControllerCreateSecondTypeCard(createSecondTypeCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve second type card by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsSecondTypeCardsApi
     */
    public adminSecondTypeCardsControllerFindOneSecondTypeCard(id: string, options?: AxiosRequestConfig) {
        return AdminsSecondTypeCardsApiFp(this.configuration).adminSecondTypeCardsControllerFindOneSecondTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove second type card
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsSecondTypeCardsApi
     */
    public adminSecondTypeCardsControllerRemoveSecondTypeCard(id: string, options?: AxiosRequestConfig) {
        return AdminsSecondTypeCardsApiFp(this.configuration).adminSecondTypeCardsControllerRemoveSecondTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated second type cards
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsSecondTypeCardsApi
     */
    public adminSecondTypeCardsControllerSearchSecondTypeCards(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsSecondTypeCardsApiFp(this.configuration).adminSecondTypeCardsControllerSearchSecondTypeCards(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update second type card content
     * @param {string} id 
     * @param {UpdateSecondTypeCardDto} updateSecondTypeCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsSecondTypeCardsApi
     */
    public adminSecondTypeCardsControllerUpdateSecondTypeCard(id: string, updateSecondTypeCardDto: UpdateSecondTypeCardDto, options?: AxiosRequestConfig) {
        return AdminsSecondTypeCardsApiFp(this.configuration).adminSecondTypeCardsControllerUpdateSecondTypeCard(id, updateSecondTypeCardDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsThirdTypeCardsApi - axios parameter creator
 * @export
 */
export const AdminsThirdTypeCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create third type card as admin
         * @param {CreateThirdTypeCardDto} createThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerCreateThirdTypeCard: async (createThirdTypeCardDto: CreateThirdTypeCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createThirdTypeCardDto' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerCreateThirdTypeCard', 'createThirdTypeCardDto', createThirdTypeCardDto)
            const localVarPath = `/admins/recommendation-cards/third-type-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createThirdTypeCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerDeleteThirdTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerDeleteThirdTypeCard', 'id', id)
            const localVarPath = `/admins/recommendation-cards/third-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerFindOneThirdTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerFindOneThirdTypeCard', 'id', id)
            const localVarPath = `/admins/recommendation-cards/third-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated third type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerSearchThirdTypeCards: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerSearchThirdTypeCards', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerSearchThirdTypeCards', 'limit', limit)
            const localVarPath = `/admins/recommendation-cards/third-type-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update third type card
         * @param {string} id 
         * @param {UpdateThirdTypeCardDto} updateThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerUpdateThirdTypeCard: async (id: string, updateThirdTypeCardDto: UpdateThirdTypeCardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerUpdateThirdTypeCard', 'id', id)
            // verify required parameter 'updateThirdTypeCardDto' is not null or undefined
            assertParamExists('adminThirdTypeCardsControllerUpdateThirdTypeCard', 'updateThirdTypeCardDto', updateThirdTypeCardDto)
            const localVarPath = `/admins/recommendation-cards/third-type-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateThirdTypeCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsThirdTypeCardsApi - functional programming interface
 * @export
 */
export const AdminsThirdTypeCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsThirdTypeCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create third type card as admin
         * @param {CreateThirdTypeCardDto} createThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto: CreateThirdTypeCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminThirdTypeCardsControllerDeleteThirdTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminThirdTypeCardsControllerDeleteThirdTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminThirdTypeCardsControllerFindOneThirdTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminThirdTypeCardsControllerFindOneThirdTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated third type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminThirdTypeCardsControllerSearchThirdTypeCards(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchThirdTypeCardsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminThirdTypeCardsControllerSearchThirdTypeCards(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update third type card
         * @param {string} id 
         * @param {UpdateThirdTypeCardDto} updateThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminThirdTypeCardsControllerUpdateThirdTypeCard(id: string, updateThirdTypeCardDto: UpdateThirdTypeCardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdTypeCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminThirdTypeCardsControllerUpdateThirdTypeCard(id, updateThirdTypeCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsThirdTypeCardsApi - factory interface
 * @export
 */
export const AdminsThirdTypeCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsThirdTypeCardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create third type card as admin
         * @param {CreateThirdTypeCardDto} createThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto: CreateThirdTypeCardDto, options?: any): AxiosPromise<ThirdTypeCard> {
            return localVarFp.adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerDeleteThirdTypeCard(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminThirdTypeCardsControllerDeleteThirdTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one third type card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerFindOneThirdTypeCard(id: string, options?: any): AxiosPromise<ThirdTypeCard> {
            return localVarFp.adminThirdTypeCardsControllerFindOneThirdTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated third type cards
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerSearchThirdTypeCards(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchThirdTypeCardsResDto> {
            return localVarFp.adminThirdTypeCardsControllerSearchThirdTypeCards(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update third type card
         * @param {string} id 
         * @param {UpdateThirdTypeCardDto} updateThirdTypeCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminThirdTypeCardsControllerUpdateThirdTypeCard(id: string, updateThirdTypeCardDto: UpdateThirdTypeCardDto, options?: any): AxiosPromise<ThirdTypeCard> {
            return localVarFp.adminThirdTypeCardsControllerUpdateThirdTypeCard(id, updateThirdTypeCardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsThirdTypeCardsApi - object-oriented interface
 * @export
 * @class AdminsThirdTypeCardsApi
 * @extends {BaseAPI}
 */
export class AdminsThirdTypeCardsApi extends BaseAPI {
    /**
     * 
     * @summary Create third type card as admin
     * @param {CreateThirdTypeCardDto} createThirdTypeCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsThirdTypeCardsApi
     */
    public adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto: CreateThirdTypeCardDto, options?: AxiosRequestConfig) {
        return AdminsThirdTypeCardsApiFp(this.configuration).adminThirdTypeCardsControllerCreateThirdTypeCard(createThirdTypeCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete third type card
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsThirdTypeCardsApi
     */
    public adminThirdTypeCardsControllerDeleteThirdTypeCard(id: string, options?: AxiosRequestConfig) {
        return AdminsThirdTypeCardsApiFp(this.configuration).adminThirdTypeCardsControllerDeleteThirdTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one third type card
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsThirdTypeCardsApi
     */
    public adminThirdTypeCardsControllerFindOneThirdTypeCard(id: string, options?: AxiosRequestConfig) {
        return AdminsThirdTypeCardsApiFp(this.configuration).adminThirdTypeCardsControllerFindOneThirdTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated third type cards
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsThirdTypeCardsApi
     */
    public adminThirdTypeCardsControllerSearchThirdTypeCards(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsThirdTypeCardsApiFp(this.configuration).adminThirdTypeCardsControllerSearchThirdTypeCards(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update third type card
     * @param {string} id 
     * @param {UpdateThirdTypeCardDto} updateThirdTypeCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsThirdTypeCardsApi
     */
    public adminThirdTypeCardsControllerUpdateThirdTypeCard(id: string, updateThirdTypeCardDto: UpdateThirdTypeCardDto, options?: AxiosRequestConfig) {
        return AdminsThirdTypeCardsApiFp(this.configuration).adminThirdTypeCardsControllerUpdateThirdTypeCard(id, updateThirdTypeCardDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminsUnitsApi - axios parameter creator
 * @export
 */
export const AdminsUnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create unit
         * @param {CreateUnitDto} createUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerCreateUnit: async (createUnitDto: CreateUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUnitDto' is not null or undefined
            assertParamExists('adminUnitsControllerCreateUnit', 'createUnitDto', createUnitDto)
            const localVarPath = `/admins/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerDeleteUnit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUnitsControllerDeleteUnit', 'id', id)
            const localVarPath = `/admins/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerFindOneUnit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUnitsControllerFindOneUnit', 'id', id)
            const localVarPath = `/admins/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerGetAllUnits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admins/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated units
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerGetPaginatedUnits: async (page: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminUnitsControllerGetPaginatedUnits', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adminUnitsControllerGetPaginatedUnits', 'limit', limit)
            const localVarPath = `/admins/units/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update unit
         * @param {string} id 
         * @param {UpdateUnitDto} updateUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerUpdateUnit: async (id: string, updateUnitDto: UpdateUnitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUnitsControllerUpdateUnit', 'id', id)
            // verify required parameter 'updateUnitDto' is not null or undefined
            assertParamExists('adminUnitsControllerUpdateUnit', 'updateUnitDto', updateUnitDto)
            const localVarPath = `/admins/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUnitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminsUnitsApi - functional programming interface
 * @export
 */
export const AdminsUnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminsUnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create unit
         * @param {CreateUnitDto} createUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerCreateUnit(createUnitDto: CreateUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerCreateUnit(createUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerDeleteUnit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerDeleteUnit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerFindOneUnit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerFindOneUnit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerGetAllUnits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Unit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerGetAllUnits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated units
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerGetPaginatedUnits(page: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchUnitsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerGetPaginatedUnits(page, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update unit
         * @param {string} id 
         * @param {UpdateUnitDto} updateUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUnitsControllerUpdateUnit(id: string, updateUnitDto: UpdateUnitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUnitsControllerUpdateUnit(id, updateUnitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminsUnitsApi - factory interface
 * @export
 */
export const AdminsUnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminsUnitsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create unit
         * @param {CreateUnitDto} createUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerCreateUnit(createUnitDto: CreateUnitDto, options?: any): AxiosPromise<Unit> {
            return localVarFp.adminUnitsControllerCreateUnit(createUnitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerDeleteUnit(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.adminUnitsControllerDeleteUnit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one unit
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerFindOneUnit(id: string, options?: any): AxiosPromise<Unit> {
            return localVarFp.adminUnitsControllerFindOneUnit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerGetAllUnits(options?: any): AxiosPromise<Array<Unit>> {
            return localVarFp.adminUnitsControllerGetAllUnits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated units
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerGetPaginatedUnits(page: number, limit: number, filter?: string, options?: any): AxiosPromise<SearchUnitsResDto> {
            return localVarFp.adminUnitsControllerGetPaginatedUnits(page, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update unit
         * @param {string} id 
         * @param {UpdateUnitDto} updateUnitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUnitsControllerUpdateUnit(id: string, updateUnitDto: UpdateUnitDto, options?: any): AxiosPromise<Unit> {
            return localVarFp.adminUnitsControllerUpdateUnit(id, updateUnitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminsUnitsApi - object-oriented interface
 * @export
 * @class AdminsUnitsApi
 * @extends {BaseAPI}
 */
export class AdminsUnitsApi extends BaseAPI {
    /**
     * 
     * @summary Create unit
     * @param {CreateUnitDto} createUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerCreateUnit(createUnitDto: CreateUnitDto, options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerCreateUnit(createUnitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete unit
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerDeleteUnit(id: string, options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerDeleteUnit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one unit
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerFindOneUnit(id: string, options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerFindOneUnit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerGetAllUnits(options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerGetAllUnits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated units
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerGetPaginatedUnits(page: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerGetPaginatedUnits(page, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update unit
     * @param {string} id 
     * @param {UpdateUnitDto} updateUnitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminsUnitsApi
     */
    public adminUnitsControllerUpdateUnit(id: string, updateUnitDto: UpdateUnitDto, options?: AxiosRequestConfig) {
        return AdminsUnitsApiFp(this.configuration).adminUnitsControllerUpdateUnit(id, updateUnitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyzesApi - axios parameter creator
 * @export
 */
export const AnalyzesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download patient analysis as pdf
         * @param {string} patientId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPatientPdf: async (patientId: string, analysisId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('analysisPdfControllerDownloadPatientPdf', 'patientId', patientId)
            // verify required parameter 'analysisId' is not null or undefined
            assertParamExists('analysisPdfControllerDownloadPatientPdf', 'analysisId', analysisId)
            const localVarPath = `/analyzes/{patientId}/{analysisId}/patient-pdf-with-previous`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"analysisId"}}`, encodeURIComponent(String(analysisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download analysis as pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPdf: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisPdfControllerDownloadPdf', 'id', id)
            const localVarPath = `/analyzes/{id}/pdf-with-previous`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download analysis as pdf without previous values
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPdfWithoutPrevious: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysisPdfControllerDownloadPdfWithoutPrevious', 'id', id)
            const localVarPath = `/analyzes/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyzesApi - functional programming interface
 * @export
 */
export const AnalyzesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyzesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download patient analysis as pdf
         * @param {string} patientId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisPdfControllerDownloadPatientPdf(patientId: string, analysisId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisPdfControllerDownloadPatientPdf(patientId, analysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download analysis as pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisPdfControllerDownloadPdf(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisPdfControllerDownloadPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download analysis as pdf without previous values
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisPdfControllerDownloadPdfWithoutPrevious(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisPdfControllerDownloadPdfWithoutPrevious(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyzesApi - factory interface
 * @export
 */
export const AnalyzesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyzesApiFp(configuration)
    return {
        /**
         * 
         * @summary Download patient analysis as pdf
         * @param {string} patientId 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPatientPdf(patientId: string, analysisId: string, options?: any): AxiosPromise<any> {
            return localVarFp.analysisPdfControllerDownloadPatientPdf(patientId, analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download analysis as pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPdf(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.analysisPdfControllerDownloadPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download analysis as pdf without previous values
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisPdfControllerDownloadPdfWithoutPrevious(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.analysisPdfControllerDownloadPdfWithoutPrevious(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyzesApi - object-oriented interface
 * @export
 * @class AnalyzesApi
 * @extends {BaseAPI}
 */
export class AnalyzesApi extends BaseAPI {
    /**
     * 
     * @summary Download patient analysis as pdf
     * @param {string} patientId 
     * @param {string} analysisId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesApi
     */
    public analysisPdfControllerDownloadPatientPdf(patientId: string, analysisId: string, options?: AxiosRequestConfig) {
        return AnalyzesApiFp(this.configuration).analysisPdfControllerDownloadPatientPdf(patientId, analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download analysis as pdf
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesApi
     */
    public analysisPdfControllerDownloadPdf(id: string, options?: AxiosRequestConfig) {
        return AnalyzesApiFp(this.configuration).analysisPdfControllerDownloadPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download analysis as pdf without previous values
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesApi
     */
    public analysisPdfControllerDownloadPdfWithoutPrevious(id: string, options?: AxiosRequestConfig) {
        return AnalyzesApiFp(this.configuration).analysisPdfControllerDownloadPdfWithoutPrevious(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyzesParsingApi - axios parameter creator
 * @export
 */
export const AnalyzesParsingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Parse patient analysis as image file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisParserControllerParseAnalisisFromImage: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertAnalysisParserControllerParseAnalisisFromImage', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('expertAnalysisParserControllerParseAnalisisFromImage', 'file', file)
            const localVarPath = `/experts/analysis-parser/patients/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse patient analysis as pdf file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisParserControllerParseAnalisisFromPdf: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertAnalysisParserControllerParseAnalisisFromPdf', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('expertAnalysisParserControllerParseAnalisisFromPdf', 'file', file)
            const localVarPath = `/experts/analysis-parser/patients/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse analysis as image file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisParserControllerParseAnalisisFromImage: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('patientAnalysisParserControllerParseAnalisisFromImage', 'file', file)
            const localVarPath = `/analysis-parser/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse analysis as pdf file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisParserControllerParseAnalisisFromPdf: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('patientAnalysisParserControllerParseAnalisisFromPdf', 'file', file)
            const localVarPath = `/analysis-parser/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyzesParsingApi - functional programming interface
 * @export
 */
export const AnalyzesParsingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyzesParsingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Parse patient analysis as image file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertAnalysisParserControllerParseAnalisisFromImage(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsePdfOrImageResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertAnalysisParserControllerParseAnalisisFromImage(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse patient analysis as pdf file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertAnalysisParserControllerParseAnalisisFromPdf(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsePdfOrImageResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertAnalysisParserControllerParseAnalisisFromPdf(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse analysis as image file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalysisParserControllerParseAnalisisFromImage(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsePdfOrImageResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalysisParserControllerParseAnalisisFromImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse analysis as pdf file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalysisParserControllerParseAnalisisFromPdf(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsePdfOrImageResDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalysisParserControllerParseAnalisisFromPdf(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyzesParsingApi - factory interface
 * @export
 */
export const AnalyzesParsingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyzesParsingApiFp(configuration)
    return {
        /**
         * 
         * @summary Parse patient analysis as image file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisParserControllerParseAnalisisFromImage(id: string, file: any, options?: any): AxiosPromise<Array<ParsePdfOrImageResDto>> {
            return localVarFp.expertAnalysisParserControllerParseAnalisisFromImage(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse patient analysis as pdf file
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisParserControllerParseAnalisisFromPdf(id: string, file: any, options?: any): AxiosPromise<Array<ParsePdfOrImageResDto>> {
            return localVarFp.expertAnalysisParserControllerParseAnalisisFromPdf(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse analysis as image file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisParserControllerParseAnalisisFromImage(file: any, options?: any): AxiosPromise<Array<ParsePdfOrImageResDto>> {
            return localVarFp.patientAnalysisParserControllerParseAnalisisFromImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse analysis as pdf file
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisParserControllerParseAnalisisFromPdf(file: any, options?: any): AxiosPromise<Array<ParsePdfOrImageResDtoV2>> {
            return localVarFp.patientAnalysisParserControllerParseAnalisisFromPdf(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyzesParsingApi - object-oriented interface
 * @export
 * @class AnalyzesParsingApi
 * @extends {BaseAPI}
 */
export class AnalyzesParsingApi extends BaseAPI {
    /**
     * 
     * @summary Parse patient analysis as image file
     * @param {string} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesParsingApi
     */
    public expertAnalysisParserControllerParseAnalisisFromImage(id: string, file: any, options?: AxiosRequestConfig) {
        return AnalyzesParsingApiFp(this.configuration).expertAnalysisParserControllerParseAnalisisFromImage(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse patient analysis as pdf file
     * @param {string} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesParsingApi
     */
    public expertAnalysisParserControllerParseAnalisisFromPdf(id: string, file: any, options?: AxiosRequestConfig) {
        return AnalyzesParsingApiFp(this.configuration).expertAnalysisParserControllerParseAnalisisFromPdf(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse analysis as image file
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesParsingApi
     */
    public patientAnalysisParserControllerParseAnalisisFromImage(file: any, options?: AxiosRequestConfig) {
        return AnalyzesParsingApiFp(this.configuration).patientAnalysisParserControllerParseAnalisisFromImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse analysis as pdf file
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyzesParsingApi
     */
    public patientAnalysisParserControllerParseAnalisisFromPdf(file: any, options?: AxiosRequestConfig) {
        return AnalyzesParsingApiFp(this.configuration).patientAnalysisParserControllerParseAnalisisFromPdf(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BiomarkersApi - axios parameter creator
 * @export
 */
export const BiomarkersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download check-ups
         * @param {ExportCheckUpsPdfDto} exportCheckUpsPdfDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biomarkersControllerDownloadPdf: async (exportCheckUpsPdfDto: ExportCheckUpsPdfDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportCheckUpsPdfDto' is not null or undefined
            assertParamExists('biomarkersControllerDownloadPdf', 'exportCheckUpsPdfDto', exportCheckUpsPdfDto)
            const localVarPath = `/biomarkers/check-ups-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportCheckUpsPdfDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biomarkersControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/biomarkers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiomarkersApi - functional programming interface
 * @export
 */
export const BiomarkersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiomarkersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download check-ups
         * @param {ExportCheckUpsPdfDto} exportCheckUpsPdfDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biomarkersControllerDownloadPdf(exportCheckUpsPdfDto: ExportCheckUpsPdfDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biomarkersControllerDownloadPdf(exportCheckUpsPdfDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async biomarkersControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiomarkerGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.biomarkersControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiomarkersApi - factory interface
 * @export
 */
export const BiomarkersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiomarkersApiFp(configuration)
    return {
        /**
         * 
         * @summary Download check-ups
         * @param {ExportCheckUpsPdfDto} exportCheckUpsPdfDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biomarkersControllerDownloadPdf(exportCheckUpsPdfDto: ExportCheckUpsPdfDto, options?: any): AxiosPromise<any> {
            return localVarFp.biomarkersControllerDownloadPdf(exportCheckUpsPdfDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biomarkersControllerFindAll(options?: any): AxiosPromise<Array<BiomarkerGetResDto>> {
            return localVarFp.biomarkersControllerFindAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BiomarkersApi - object-oriented interface
 * @export
 * @class BiomarkersApi
 * @extends {BaseAPI}
 */
export class BiomarkersApi extends BaseAPI {
    /**
     * 
     * @summary Download check-ups
     * @param {ExportCheckUpsPdfDto} exportCheckUpsPdfDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiomarkersApi
     */
    public biomarkersControllerDownloadPdf(exportCheckUpsPdfDto: ExportCheckUpsPdfDto, options?: AxiosRequestConfig) {
        return BiomarkersApiFp(this.configuration).biomarkersControllerDownloadPdf(exportCheckUpsPdfDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all biomarkers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiomarkersApi
     */
    public biomarkersControllerFindAll(options?: AxiosRequestConfig) {
        return BiomarkersApiFp(this.configuration).biomarkersControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatsApi - axios parameter creator
 * @export
 */
export const ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clear unread messages count of chat room from one user side
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerClearUnreadMessagesCount: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsControllerClearUnreadMessagesCount', 'id', id)
            const localVarPath = `/chats/{id}/unread-messages-count`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attachments that belong to the chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetAttachments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsControllerGetAttachments', 'id', id)
            const localVarPath = `/chats/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signed url to upload any attachments in chat
         * @param {AttachmentsSignedUrlDto} attachmentsSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetAttachmentsSignedUrlToUpload: async (attachmentsSignedUrlDto: AttachmentsSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentsSignedUrlDto' is not null or undefined
            assertParamExists('chatsControllerGetAttachmentsSignedUrlToUpload', 'attachmentsSignedUrlDto', attachmentsSignedUrlDto)
            const localVarPath = `/chats/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentsSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get connection to current user related chat rooms
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetConnection: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('chatsControllerGetConnection', 'userId', userId)
            const localVarPath = `/chats/{userId}/connect`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get messages that belong to chat room
         * @param {string} id 
         * @param {string} limit 
         * @param {string} [cursor] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetMessages: async (id: string, limit: string, cursor?: string, sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsControllerGetMessages', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('chatsControllerGetMessages', 'limit', limit)
            const localVarPath = `/chats/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send new message in chat room
         * @param {string} id 
         * @param {ChatsSendMessageDto} chatsSendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerSendMessage: async (id: string, chatsSendMessageDto: ChatsSendMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatsControllerSendMessage', 'id', id)
            // verify required parameter 'chatsSendMessageDto' is not null or undefined
            assertParamExists('chatsControllerSendMessage', 'chatsSendMessageDto', chatsSendMessageDto)
            const localVarPath = `/chats/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatsSendMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatsApi - functional programming interface
 * @export
 */
export const ChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clear unread messages count of chat room from one user side
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerClearUnreadMessagesCount(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerClearUnreadMessagesCount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get attachments that belong to the chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetAttachments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetAttachments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get signed url to upload any attachments in chat
         * @param {AttachmentsSignedUrlDto} attachmentsSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto: AttachmentsSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get connection to current user related chat rooms
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetConnection(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetConnection(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get messages that belong to chat room
         * @param {string} id 
         * @param {string} limit 
         * @param {string} [cursor] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetMessages(id: string, limit: string, cursor?: string, sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatsGetPaginatedMessagesResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetMessages(id, limit, cursor, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send new message in chat room
         * @param {string} id 
         * @param {ChatsSendMessageDto} chatsSendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerSendMessage(id: string, chatsSendMessageDto: ChatsSendMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerSendMessage(id, chatsSendMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatsApi - factory interface
 * @export
 */
export const ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Clear unread messages count of chat room from one user side
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerClearUnreadMessagesCount(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.chatsControllerClearUnreadMessagesCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attachments that belong to the chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetAttachments(id: string, options?: any): AxiosPromise<Array<MessageAttachment>> {
            return localVarFp.chatsControllerGetAttachments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signed url to upload any attachments in chat
         * @param {AttachmentsSignedUrlDto} attachmentsSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto: AttachmentsSignedUrlDto, options?: any): AxiosPromise<FileUploadSignedUrlResDto> {
            return localVarFp.chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get connection to current user related chat rooms
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetConnection(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatsControllerGetConnection(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get messages that belong to chat room
         * @param {string} id 
         * @param {string} limit 
         * @param {string} [cursor] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetMessages(id: string, limit: string, cursor?: string, sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<ChatsGetPaginatedMessagesResDto> {
            return localVarFp.chatsControllerGetMessages(id, limit, cursor, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send new message in chat room
         * @param {string} id 
         * @param {ChatsSendMessageDto} chatsSendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerSendMessage(id: string, chatsSendMessageDto: ChatsSendMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.chatsControllerSendMessage(id, chatsSendMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatsApi - object-oriented interface
 * @export
 * @class ChatsApi
 * @extends {BaseAPI}
 */
export class ChatsApi extends BaseAPI {
    /**
     * 
     * @summary Clear unread messages count of chat room from one user side
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerClearUnreadMessagesCount(id: string, options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerClearUnreadMessagesCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attachments that belong to the chat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerGetAttachments(id: string, options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerGetAttachments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signed url to upload any attachments in chat
     * @param {AttachmentsSignedUrlDto} attachmentsSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto: AttachmentsSignedUrlDto, options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerGetAttachmentsSignedUrlToUpload(attachmentsSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get connection to current user related chat rooms
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerGetConnection(userId: string, options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerGetConnection(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get messages that belong to chat room
     * @param {string} id 
     * @param {string} limit 
     * @param {string} [cursor] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerGetMessages(id: string, limit: string, cursor?: string, sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerGetMessages(id, limit, cursor, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send new message in chat room
     * @param {string} id 
     * @param {ChatsSendMessageDto} chatsSendMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public chatsControllerSendMessage(id: string, chatsSendMessageDto: ChatsSendMessageDto, options?: AxiosRequestConfig) {
        return ChatsApiFp(this.configuration).chatsControllerSendMessage(id, chatsSendMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactFormApi - axios parameter creator
 * @export
 */
export const ContactFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send information from expert to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFormControllerSendMessageFromExpertContactForm: async (sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMessageToBiogeekSupportDto' is not null or undefined
            assertParamExists('contactFormControllerSendMessageFromExpertContactForm', 'sendMessageToBiogeekSupportDto', sendMessageToBiogeekSupportDto)
            const localVarPath = `/contact-form/expert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageToBiogeekSupportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send information from patient to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFormControllerSendMessageFromPatientContactForm: async (sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMessageToBiogeekSupportDto' is not null or undefined
            assertParamExists('contactFormControllerSendMessageFromPatientContactForm', 'sendMessageToBiogeekSupportDto', sendMessageToBiogeekSupportDto)
            const localVarPath = `/contact-form/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageToBiogeekSupportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormApi - functional programming interface
 * @export
 */
export const ContactFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send information from expert to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send information from patient to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormApi - factory interface
 * @export
 */
export const ContactFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormApiFp(configuration)
    return {
        /**
         * 
         * @summary Send information from expert to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: any): AxiosPromise<void> {
            return localVarFp.contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send information from patient to Biogeek support
         * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: any): AxiosPromise<void> {
            return localVarFp.contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormApi - object-oriented interface
 * @export
 * @class ContactFormApi
 * @extends {BaseAPI}
 */
export class ContactFormApi extends BaseAPI {
    /**
     * 
     * @summary Send information from expert to Biogeek support
     * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApi
     */
    public contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: AxiosRequestConfig) {
        return ContactFormApiFp(this.configuration).contactFormControllerSendMessageFromExpertContactForm(sendMessageToBiogeekSupportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send information from patient to Biogeek support
     * @param {SendMessageToBiogeekSupportDto} sendMessageToBiogeekSupportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApi
     */
    public contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto: SendMessageToBiogeekSupportDto, options?: AxiosRequestConfig) {
        return ContactFormApiFp(this.configuration).contactFormControllerSendMessageFromPatientContactForm(sendMessageToBiogeekSupportDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hello
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hello
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Hello
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Hello
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsApi - axios parameter creator
 * @export
 */
export const ExpertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete current expert onboarding
         * @param {ExpertCompleteOnboardingDto} expertCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerCompleteOnboarding: async (expertCompleteOnboardingDto: ExpertCompleteOnboardingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCompleteOnboardingDto' is not null or undefined
            assertParamExists('expertsControllerCompleteOnboarding', 'expertCompleteOnboardingDto', expertCompleteOnboardingDto)
            const localVarPath = `/experts/me/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCompleteOnboardingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerDeleteMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload education documents pdf files
         * @param {DocumentUploadSignedUrlDto} documentUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerGetDocumentSignedUrlToUpload: async (documentUploadSignedUrlDto: DocumentUploadSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentUploadSignedUrlDto' is not null or undefined
            assertParamExists('expertsControllerGetDocumentSignedUrlToUpload', 'documentUploadSignedUrlDto', documentUploadSignedUrlDto)
            const localVarPath = `/experts/signed-url-to-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload expert avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerGetPhotoSignedUrlToUpload: async (photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photoUploadSignedUrlDto' is not null or undefined
            assertParamExists('expertsControllerGetPhotoSignedUrlToUpload', 'photoUploadSignedUrlDto', photoUploadSignedUrlDto)
            const localVarPath = `/experts/signed-url-to-upload-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(photoUploadSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load avatar image url in database
         * @param {ExpertLoadAvatarImageDto} expertLoadAvatarImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerLoadAvatarImageURL: async (expertLoadAvatarImageDto: ExpertLoadAvatarImageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertLoadAvatarImageDto' is not null or undefined
            assertParamExists('expertsControllerLoadAvatarImageURL', 'expertLoadAvatarImageDto', expertLoadAvatarImageDto)
            const localVarPath = `/experts/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertLoadAvatarImageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load array of URLs as education documents to expert entity
         * @param {ExpertLoadEducationDocumentsDto} expertLoadEducationDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerLoadEducationDocumentsURLs: async (expertLoadEducationDocumentsDto: ExpertLoadEducationDocumentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertLoadEducationDocumentsDto' is not null or undefined
            assertParamExists('expertsControllerLoadEducationDocumentsURLs', 'expertLoadEducationDocumentsDto', expertLoadEducationDocumentsDto)
            const localVarPath = `/experts/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertLoadEducationDocumentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore current expert password
         * @param {ExpertRestorePasswordDto} expertRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerRestorePassword: async (expertRestorePasswordDto: ExpertRestorePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertRestorePasswordDto' is not null or undefined
            assertParamExists('expertsControllerRestorePassword', 'expertRestorePasswordDto', expertRestorePasswordDto)
            const localVarPath = `/experts/me/restore-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertRestorePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to verify current expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendCurrentEmailVerificationCode: async (expertEmailCodeDto: ExpertEmailCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertEmailCodeDto' is not null or undefined
            assertParamExists('expertsControllerSendCurrentEmailVerificationCode', 'expertEmailCodeDto', expertEmailCodeDto)
            const localVarPath = `/experts/me/email/current/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertEmailCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to new expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendNewEmailVerificationCode: async (expertEmailCodeDto: ExpertEmailCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertEmailCodeDto' is not null or undefined
            assertParamExists('expertsControllerSendNewEmailVerificationCode', 'expertEmailCodeDto', expertEmailCodeDto)
            const localVarPath = `/experts/me/email/new/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertEmailCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send restore password link to current expert email
         * @param {ExpertForgotPasswordDto} expertForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendRestorePasswordLink: async (expertForgotPasswordDto: ExpertForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertForgotPasswordDto' is not null or undefined
            assertParamExists('expertsControllerSendRestorePasswordLink', 'expertForgotPasswordDto', expertForgotPasswordDto)
            const localVarPath = `/experts/me/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {ExpertSignUpVerificationDto} expertSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendSignUpVerificationCode: async (expertSignUpVerificationDto: ExpertSignUpVerificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertSignUpVerificationDto' is not null or undefined
            assertParamExists('expertsControllerSendSignUpVerificationCode', 'expertSignUpVerificationDto', expertSignUpVerificationDto)
            const localVarPath = `/experts/sign-up/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertSignUpVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set new expert email
         * @param {ExpertSetNewEmailDto} expertSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSetNewEmail: async (expertSetNewEmailDto: ExpertSetNewEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertSetNewEmailDto' is not null or undefined
            assertParamExists('expertsControllerSetNewEmail', 'expertSetNewEmailDto', expertSetNewEmailDto)
            const localVarPath = `/experts/me/email/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertSetNewEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignInDto} expertSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSignIn: async (expertSignInDto: ExpertSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertSignInDto' is not null or undefined
            assertParamExists('expertsControllerSignIn', 'expertSignInDto', expertSignInDto)
            const localVarPath = `/experts/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignUpDto} expertSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSignUp: async (expertSignUpDto: ExpertSignUpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertSignUpDto' is not null or undefined
            assertParamExists('expertsControllerSignUp', 'expertSignUpDto', expertSignUpDto)
            const localVarPath = `/experts/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertSignUpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current expert
         * @param {ExpertUpdateExpertDto} expertUpdateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerUpdateMe: async (expertUpdateExpertDto: ExpertUpdateExpertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertUpdateExpertDto' is not null or undefined
            assertParamExists('expertsControllerUpdateMe', 'expertUpdateExpertDto', expertUpdateExpertDto)
            const localVarPath = `/experts/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertUpdateExpertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password of current expert
         * @param {ExpertUpdatePasswordDto} expertUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerUpdatePassword: async (expertUpdatePasswordDto: ExpertUpdatePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertUpdatePasswordDto' is not null or undefined
            assertParamExists('expertsControllerUpdatePassword', 'expertUpdatePasswordDto', expertUpdatePasswordDto)
            const localVarPath = `/experts/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertUpdatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {ExpertVerifyCurrentEmailDto} expertVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerVerifyCurrentEmail: async (expertVerifyCurrentEmailDto: ExpertVerifyCurrentEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertVerifyCurrentEmailDto' is not null or undefined
            assertParamExists('expertsControllerVerifyCurrentEmail', 'expertVerifyCurrentEmailDto', expertVerifyCurrentEmailDto)
            const localVarPath = `/experts/me/email/current/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertVerifyCurrentEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsApi - functional programming interface
 * @export
 */
export const ExpertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete current expert onboarding
         * @param {ExpertCompleteOnboardingDto} expertCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerCompleteOnboarding(expertCompleteOnboardingDto: ExpertCompleteOnboardingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerCompleteOnboarding(expertCompleteOnboardingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerDeleteMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerDeleteMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload education documents pdf files
         * @param {DocumentUploadSignedUrlDto} documentUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto: DocumentUploadSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload expert avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load avatar image url in database
         * @param {ExpertLoadAvatarImageDto} expertLoadAvatarImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto: ExpertLoadAvatarImageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load array of URLs as education documents to expert entity
         * @param {ExpertLoadEducationDocumentsDto} expertLoadEducationDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto: ExpertLoadEducationDocumentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore current expert password
         * @param {ExpertRestorePasswordDto} expertRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerRestorePassword(expertRestorePasswordDto: ExpertRestorePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerRestorePassword(expertRestorePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to verify current expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to new expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send restore password link to current expert email
         * @param {ExpertForgotPasswordDto} expertForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSendRestorePasswordLink(expertForgotPasswordDto: ExpertForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSendRestorePasswordLink(expertForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {ExpertSignUpVerificationDto} expertSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto: ExpertSignUpVerificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set new expert email
         * @param {ExpertSetNewEmailDto} expertSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSetNewEmail(expertSetNewEmailDto: ExpertSetNewEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSetNewEmail(expertSetNewEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignInDto} expertSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSignIn(expertSignInDto: ExpertSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSignIn(expertSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignUpDto} expertSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerSignUp(expertSignUpDto: ExpertSignUpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerSignUp(expertSignUpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current expert
         * @param {ExpertUpdateExpertDto} expertUpdateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerUpdateMe(expertUpdateExpertDto: ExpertUpdateExpertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerUpdateMe(expertUpdateExpertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update password of current expert
         * @param {ExpertUpdatePasswordDto} expertUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerUpdatePassword(expertUpdatePasswordDto: ExpertUpdatePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerUpdatePassword(expertUpdatePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {ExpertVerifyCurrentEmailDto} expertVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto: ExpertVerifyCurrentEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsApi - factory interface
 * @export
 */
export const ExpertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete current expert onboarding
         * @param {ExpertCompleteOnboardingDto} expertCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerCompleteOnboarding(expertCompleteOnboardingDto: ExpertCompleteOnboardingDto, options?: any): AxiosPromise<Expert> {
            return localVarFp.expertsControllerCompleteOnboarding(expertCompleteOnboardingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerDeleteMe(options?: any): AxiosPromise<void> {
            return localVarFp.expertsControllerDeleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerFindMe(options?: any): AxiosPromise<Expert> {
            return localVarFp.expertsControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload education documents pdf files
         * @param {DocumentUploadSignedUrlDto} documentUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto: DocumentUploadSignedUrlDto, options?: any): AxiosPromise<FileUploadSignedUrlResDto> {
            return localVarFp.expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload expert avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: any): AxiosPromise<FileUploadSignedUrlResDto> {
            return localVarFp.expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load avatar image url in database
         * @param {ExpertLoadAvatarImageDto} expertLoadAvatarImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto: ExpertLoadAvatarImageDto, options?: any): AxiosPromise<void> {
            return localVarFp.expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load array of URLs as education documents to expert entity
         * @param {ExpertLoadEducationDocumentsDto} expertLoadEducationDocumentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto: ExpertLoadEducationDocumentsDto, options?: any): AxiosPromise<string> {
            return localVarFp.expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore current expert password
         * @param {ExpertRestorePasswordDto} expertRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerRestorePassword(expertRestorePasswordDto: ExpertRestorePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.expertsControllerRestorePassword(expertRestorePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to verify current expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to new expert email
         * @param {ExpertEmailCodeDto} expertEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send restore password link to current expert email
         * @param {ExpertForgotPasswordDto} expertForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendRestorePasswordLink(expertForgotPasswordDto: ExpertForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.expertsControllerSendRestorePasswordLink(expertForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {ExpertSignUpVerificationDto} expertSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto: ExpertSignUpVerificationDto, options?: any): AxiosPromise<string> {
            return localVarFp.expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set new expert email
         * @param {ExpertSetNewEmailDto} expertSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSetNewEmail(expertSetNewEmailDto: ExpertSetNewEmailDto, options?: any): AxiosPromise<Expert> {
            return localVarFp.expertsControllerSetNewEmail(expertSetNewEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignInDto} expertSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSignIn(expertSignInDto: ExpertSignInDto, options?: any): AxiosPromise<ExpertAuthResDto> {
            return localVarFp.expertsControllerSignIn(expertSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up expert by email and password and retrieve expert with bearer token
         * @param {ExpertSignUpDto} expertSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerSignUp(expertSignUpDto: ExpertSignUpDto, options?: any): AxiosPromise<ExpertAuthResDto> {
            return localVarFp.expertsControllerSignUp(expertSignUpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current expert
         * @param {ExpertUpdateExpertDto} expertUpdateExpertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerUpdateMe(expertUpdateExpertDto: ExpertUpdateExpertDto, options?: any): AxiosPromise<Expert> {
            return localVarFp.expertsControllerUpdateMe(expertUpdateExpertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update password of current expert
         * @param {ExpertUpdatePasswordDto} expertUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerUpdatePassword(expertUpdatePasswordDto: ExpertUpdatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.expertsControllerUpdatePassword(expertUpdatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {ExpertVerifyCurrentEmailDto} expertVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto: ExpertVerifyCurrentEmailDto, options?: any): AxiosPromise<string> {
            return localVarFp.expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsApi - object-oriented interface
 * @export
 * @class ExpertsApi
 * @extends {BaseAPI}
 */
export class ExpertsApi extends BaseAPI {
    /**
     * 
     * @summary Complete current expert onboarding
     * @param {ExpertCompleteOnboardingDto} expertCompleteOnboardingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerCompleteOnboarding(expertCompleteOnboardingDto: ExpertCompleteOnboardingDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerCompleteOnboarding(expertCompleteOnboardingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete current expert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerDeleteMe(options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerDeleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current expert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerFindMe(options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload education documents pdf files
     * @param {DocumentUploadSignedUrlDto} documentUploadSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto: DocumentUploadSignedUrlDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerGetDocumentSignedUrlToUpload(documentUploadSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload expert avatar photo
     * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load avatar image url in database
     * @param {ExpertLoadAvatarImageDto} expertLoadAvatarImageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto: ExpertLoadAvatarImageDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerLoadAvatarImageURL(expertLoadAvatarImageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load array of URLs as education documents to expert entity
     * @param {ExpertLoadEducationDocumentsDto} expertLoadEducationDocumentsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto: ExpertLoadEducationDocumentsDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerLoadEducationDocumentsURLs(expertLoadEducationDocumentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore current expert password
     * @param {ExpertRestorePasswordDto} expertRestorePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerRestorePassword(expertRestorePasswordDto: ExpertRestorePasswordDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerRestorePassword(expertRestorePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to verify current expert email
     * @param {ExpertEmailCodeDto} expertEmailCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSendCurrentEmailVerificationCode(expertEmailCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to new expert email
     * @param {ExpertEmailCodeDto} expertEmailCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto: ExpertEmailCodeDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSendNewEmailVerificationCode(expertEmailCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send restore password link to current expert email
     * @param {ExpertForgotPasswordDto} expertForgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSendRestorePasswordLink(expertForgotPasswordDto: ExpertForgotPasswordDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSendRestorePasswordLink(expertForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to email
     * @param {ExpertSignUpVerificationDto} expertSignUpVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto: ExpertSignUpVerificationDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSendSignUpVerificationCode(expertSignUpVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set new expert email
     * @param {ExpertSetNewEmailDto} expertSetNewEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSetNewEmail(expertSetNewEmailDto: ExpertSetNewEmailDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSetNewEmail(expertSetNewEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in expert by email and password and retrieve expert with bearer token
     * @param {ExpertSignInDto} expertSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSignIn(expertSignInDto: ExpertSignInDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSignIn(expertSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up expert by email and password and retrieve expert with bearer token
     * @param {ExpertSignUpDto} expertSignUpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerSignUp(expertSignUpDto: ExpertSignUpDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerSignUp(expertSignUpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current expert
     * @param {ExpertUpdateExpertDto} expertUpdateExpertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerUpdateMe(expertUpdateExpertDto: ExpertUpdateExpertDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerUpdateMe(expertUpdateExpertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update password of current expert
     * @param {ExpertUpdatePasswordDto} expertUpdatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerUpdatePassword(expertUpdatePasswordDto: ExpertUpdatePasswordDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerUpdatePassword(expertUpdatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify current email to change
     * @param {ExpertVerifyCurrentEmailDto} expertVerifyCurrentEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto: ExpertVerifyCurrentEmailDto, options?: AxiosRequestConfig) {
        return ExpertsApiFp(this.configuration).expertsControllerVerifyCurrentEmail(expertVerifyCurrentEmailDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsAnalysisKindsApi - axios parameter creator
 * @export
 */
export const ExpertsAnalysisKindsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisKindsControllerGetAnalysisKinds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/analysis-kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsAnalysisKindsApi - functional programming interface
 * @export
 */
export const ExpertsAnalysisKindsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsAnalysisKindsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertAnalysisKindsControllerGetAnalysisKinds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisKindGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertAnalysisKindsControllerGetAnalysisKinds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsAnalysisKindsApi - factory interface
 * @export
 */
export const ExpertsAnalysisKindsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsAnalysisKindsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertAnalysisKindsControllerGetAnalysisKinds(options?: any): AxiosPromise<Array<AnalysisKindGetResDto>> {
            return localVarFp.expertAnalysisKindsControllerGetAnalysisKinds(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsAnalysisKindsApi - object-oriented interface
 * @export
 * @class ExpertsAnalysisKindsApi
 * @extends {BaseAPI}
 */
export class ExpertsAnalysisKindsApi extends BaseAPI {
    /**
     * 
     * @summary Get list of analysis kinds with their biomarkers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalysisKindsApi
     */
    public expertAnalysisKindsControllerGetAnalysisKinds(options?: AxiosRequestConfig) {
        return ExpertsAnalysisKindsApiFp(this.configuration).expertAnalysisKindsControllerGetAnalysisKinds(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsAnalyzesApi - axios parameter creator
 * @export
 */
export const ExpertsAnalyzesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one analysis for patient
         * @param {string} id 
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerCreate: async (id: string, createPatientAnalysisDto: CreatePatientAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerCreate', 'id', id)
            // verify required parameter 'createPatientAnalysisDto' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerCreate', 'createPatientAnalysisDto', createPatientAnalysisDto)
            const localVarPath = `/experts/patients/{id}/analyzes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientAnalysisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerDelete: async (id: string, analysisId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerDelete', 'id', id)
            // verify required parameter 'analysisId' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerDelete', 'analysisId', analysisId)
            const localVarPath = `/experts/patients/{id}/analyzes/{analysisId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"analysisId"}}`, encodeURIComponent(String(analysisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one patient analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerFindOneAnalysis: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerFindOneAnalysis', 'id', id)
            const localVarPath = `/experts/patients/analyzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated patient analysis by patientId
         * @param {string} id 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerSearchPatientAnalyzes: async (id: string, startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerSearchPatientAnalyzes', 'id', id)
            const localVarPath = `/experts/patients/{id}/analyzes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (normFilter !== undefined) {
                localVarQueryParameter['normFilter'] = normFilter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerUpdate: async (id: string, analysisId: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerUpdate', 'id', id)
            // verify required parameter 'analysisId' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerUpdate', 'analysisId', analysisId)
            // verify required parameter 'updatePatientAnalysisDto' is not null or undefined
            assertParamExists('expertPatientAnalyzesControllerUpdate', 'updatePatientAnalysisDto', updatePatientAnalysisDto)
            const localVarPath = `/experts/patients/{id}/analyzes/{analysisId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"analysisId"}}`, encodeURIComponent(String(analysisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientAnalysisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsAnalyzesApi - functional programming interface
 * @export
 */
export const ExpertsAnalyzesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsAnalyzesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one analysis for patient
         * @param {string} id 
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnalyzesControllerCreate(id: string, createPatientAnalysisDto: CreatePatientAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnalyzesControllerCreate(id, createPatientAnalysisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnalyzesControllerDelete(id: string, analysisId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnalyzesControllerDelete(id, analysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one patient analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnalyzesControllerFindOneAnalysis(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneAnalysisWithPreviousResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnalyzesControllerFindOneAnalysis(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated patient analysis by patientId
         * @param {string} id 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnalyzesControllerSearchPatientAnalyzes(id: string, startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAnalyzesResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnalyzesControllerSearchPatientAnalyzes(id, startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnalyzesControllerUpdate(id: string, analysisId: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnalyzesControllerUpdate(id, analysisId, updatePatientAnalysisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsAnalyzesApi - factory interface
 * @export
 */
export const ExpertsAnalyzesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsAnalyzesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one analysis for patient
         * @param {string} id 
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerCreate(id: string, createPatientAnalysisDto: CreatePatientAnalysisDto, options?: any): AxiosPromise<Analysis> {
            return localVarFp.expertPatientAnalyzesControllerCreate(id, createPatientAnalysisDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerDelete(id: string, analysisId: string, options?: any): AxiosPromise<void> {
            return localVarFp.expertPatientAnalyzesControllerDelete(id, analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one patient analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerFindOneAnalysis(id: string, options?: any): AxiosPromise<FindOneAnalysisWithPreviousResDto> {
            return localVarFp.expertPatientAnalyzesControllerFindOneAnalysis(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated patient analysis by patientId
         * @param {string} id 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerSearchPatientAnalyzes(id: string, startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: any): AxiosPromise<SearchAnalyzesResDto> {
            return localVarFp.expertPatientAnalyzesControllerSearchPatientAnalyzes(id, startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient analysis by id
         * @param {string} id 
         * @param {string} analysisId 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnalyzesControllerUpdate(id: string, analysisId: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: any): AxiosPromise<Analysis> {
            return localVarFp.expertPatientAnalyzesControllerUpdate(id, analysisId, updatePatientAnalysisDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsAnalyzesApi - object-oriented interface
 * @export
 * @class ExpertsAnalyzesApi
 * @extends {BaseAPI}
 */
export class ExpertsAnalyzesApi extends BaseAPI {
    /**
     * 
     * @summary Create one analysis for patient
     * @param {string} id 
     * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalyzesApi
     */
    public expertPatientAnalyzesControllerCreate(id: string, createPatientAnalysisDto: CreatePatientAnalysisDto, options?: AxiosRequestConfig) {
        return ExpertsAnalyzesApiFp(this.configuration).expertPatientAnalyzesControllerCreate(id, createPatientAnalysisDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete patient analysis by id
     * @param {string} id 
     * @param {string} analysisId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalyzesApi
     */
    public expertPatientAnalyzesControllerDelete(id: string, analysisId: string, options?: AxiosRequestConfig) {
        return ExpertsAnalyzesApiFp(this.configuration).expertPatientAnalyzesControllerDelete(id, analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one patient analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalyzesApi
     */
    public expertPatientAnalyzesControllerFindOneAnalysis(id: string, options?: AxiosRequestConfig) {
        return ExpertsAnalyzesApiFp(this.configuration).expertPatientAnalyzesControllerFindOneAnalysis(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated patient analysis by patientId
     * @param {string} id 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalyzesApi
     */
    public expertPatientAnalyzesControllerSearchPatientAnalyzes(id: string, startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ExpertsAnalyzesApiFp(this.configuration).expertPatientAnalyzesControllerSearchPatientAnalyzes(id, startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient analysis by id
     * @param {string} id 
     * @param {string} analysisId 
     * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsAnalyzesApi
     */
    public expertPatientAnalyzesControllerUpdate(id: string, analysisId: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: AxiosRequestConfig) {
        return ExpertsAnalyzesApiFp(this.configuration).expertPatientAnalyzesControllerUpdate(id, analysisId, updatePatientAnalysisDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsChatsApi - axios parameter creator
 * @export
 */
export const ExpertsChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create chat room with patient
         * @param {ExpertCreateChatRoomDto} expertCreateChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsChatsControllerCreateChatRoom: async (expertCreateChatRoomDto: ExpertCreateChatRoomDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCreateChatRoomDto' is not null or undefined
            assertParamExists('expertsChatsControllerCreateChatRoom', 'expertCreateChatRoomDto', expertCreateChatRoomDto)
            const localVarPath = `/experts/chat-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCreateChatRoomDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chat rooms that belong to current expert
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsChatsControllerGetChatRooms: async (searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/chat-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsChatsApi - functional programming interface
 * @export
 */
export const ExpertsChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create chat room with patient
         * @param {ExpertCreateChatRoomDto} expertCreateChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto: ExpertCreateChatRoomDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatRoomsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chat rooms that belong to current expert
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsChatsControllerGetChatRooms(searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChatRoomsResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsChatsControllerGetChatRooms(searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsChatsApi - factory interface
 * @export
 */
export const ExpertsChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create chat room with patient
         * @param {ExpertCreateChatRoomDto} expertCreateChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto: ExpertCreateChatRoomDto, options?: any): AxiosPromise<GetChatRoomsResDto> {
            return localVarFp.expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chat rooms that belong to current expert
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsChatsControllerGetChatRooms(searchQuery?: string, options?: any): AxiosPromise<Array<GetChatRoomsResDto>> {
            return localVarFp.expertsChatsControllerGetChatRooms(searchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsChatsApi - object-oriented interface
 * @export
 * @class ExpertsChatsApi
 * @extends {BaseAPI}
 */
export class ExpertsChatsApi extends BaseAPI {
    /**
     * 
     * @summary Create chat room with patient
     * @param {ExpertCreateChatRoomDto} expertCreateChatRoomDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsChatsApi
     */
    public expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto: ExpertCreateChatRoomDto, options?: AxiosRequestConfig) {
        return ExpertsChatsApiFp(this.configuration).expertsChatsControllerCreateChatRoom(expertCreateChatRoomDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chat rooms that belong to current expert
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsChatsApi
     */
    public expertsChatsControllerGetChatRooms(searchQuery?: string, options?: AxiosRequestConfig) {
        return ExpertsChatsApiFp(this.configuration).expertsChatsControllerGetChatRooms(searchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsCheckupsApi - axios parameter creator
 * @export
 */
export const ExpertsCheckupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerAssignCheckupToPatient: async (id: string, checkupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsCheckupsControllerAssignCheckupToPatient', 'id', id)
            // verify required parameter 'checkupId' is not null or undefined
            assertParamExists('expertsCheckupsControllerAssignCheckupToPatient', 'checkupId', checkupId)
            const localVarPath = `/experts/checkups/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (checkupId !== undefined) {
                localVarQueryParameter['checkupId'] = checkupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unset checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerCanselCheckupForPatient: async (id: string, checkupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsCheckupsControllerCanselCheckupForPatient', 'id', id)
            // verify required parameter 'checkupId' is not null or undefined
            assertParamExists('expertsCheckupsControllerCanselCheckupForPatient', 'checkupId', checkupId)
            const localVarPath = `/experts/checkups/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (checkupId !== undefined) {
                localVarQueryParameter['checkupId'] = checkupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create and assign custom checkup for patient
         * @param {ExpertCreateCustomCheckupDto} expertCreateCustomCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerCreateCustomCheckup: async (expertCreateCustomCheckupDto: ExpertCreateCustomCheckupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCreateCustomCheckupDto' is not null or undefined
            assertParamExists('expertsCheckupsControllerCreateCustomCheckup', 'expertCreateCustomCheckupDto', expertCreateCustomCheckupDto)
            const localVarPath = `/experts/checkups/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCreateCustomCheckupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindAllCheckups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/checkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindOnePatientCheckup: async (checkupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkupId' is not null or undefined
            assertParamExists('expertsCheckupsControllerFindOnePatientCheckup', 'checkupId', checkupId)
            const localVarPath = `/experts/checkups/{checkupId}`
                .replace(`{${"checkupId"}}`, encodeURIComponent(String(checkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get assigned checkups for patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindPatientAssignedCheckups: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsCheckupsControllerFindPatientAssignedCheckups', 'id', id)
            const localVarPath = `/experts/checkups/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsCheckupsApi - functional programming interface
 * @export
 */
export const ExpertsCheckupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsCheckupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerAssignCheckupToPatient(id: string, checkupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerAssignCheckupToPatient(id, checkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unset checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerCanselCheckupForPatient(id: string, checkupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerCanselCheckupForPatient(id, checkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create and assign custom checkup for patient
         * @param {ExpertCreateCustomCheckupDto} expertCreateCustomCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto: ExpertCreateCustomCheckupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckupResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerFindAllCheckups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckupResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerFindAllCheckups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerFindOnePatientCheckup(checkupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Checkup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerFindOnePatientCheckup(checkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get assigned checkups for patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsCheckupsControllerFindPatientAssignedCheckups(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckupResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsCheckupsControllerFindPatientAssignedCheckups(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsCheckupsApi - factory interface
 * @export
 */
export const ExpertsCheckupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsCheckupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Set checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerAssignCheckupToPatient(id: string, checkupId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.expertsCheckupsControllerAssignCheckupToPatient(id, checkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unset checkup to patient
         * @param {string} id 
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerCanselCheckupForPatient(id: string, checkupId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.expertsCheckupsControllerCanselCheckupForPatient(id, checkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create and assign custom checkup for patient
         * @param {ExpertCreateCustomCheckupDto} expertCreateCustomCheckupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto: ExpertCreateCustomCheckupDto, options?: any): AxiosPromise<Array<CheckupResDto>> {
            return localVarFp.expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindAllCheckups(options?: any): AxiosPromise<Array<CheckupResDto>> {
            return localVarFp.expertsCheckupsControllerFindAllCheckups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one checkup
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindOnePatientCheckup(checkupId: string, options?: any): AxiosPromise<Checkup> {
            return localVarFp.expertsCheckupsControllerFindOnePatientCheckup(checkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get assigned checkups for patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsCheckupsControllerFindPatientAssignedCheckups(id: string, options?: any): AxiosPromise<Array<CheckupResDto>> {
            return localVarFp.expertsCheckupsControllerFindPatientAssignedCheckups(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsCheckupsApi - object-oriented interface
 * @export
 * @class ExpertsCheckupsApi
 * @extends {BaseAPI}
 */
export class ExpertsCheckupsApi extends BaseAPI {
    /**
     * 
     * @summary Set checkup to patient
     * @param {string} id 
     * @param {string} checkupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerAssignCheckupToPatient(id: string, checkupId: string, options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerAssignCheckupToPatient(id, checkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unset checkup to patient
     * @param {string} id 
     * @param {string} checkupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerCanselCheckupForPatient(id: string, checkupId: string, options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerCanselCheckupForPatient(id, checkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create and assign custom checkup for patient
     * @param {ExpertCreateCustomCheckupDto} expertCreateCustomCheckupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto: ExpertCreateCustomCheckupDto, options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerCreateCustomCheckup(expertCreateCustomCheckupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all checkups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerFindAllCheckups(options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerFindAllCheckups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one checkup
     * @param {string} checkupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerFindOnePatientCheckup(checkupId: string, options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerFindOnePatientCheckup(checkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get assigned checkups for patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsCheckupsApi
     */
    public expertsCheckupsControllerFindPatientAssignedCheckups(id: string, options?: AxiosRequestConfig) {
        return ExpertsCheckupsApiFp(this.configuration).expertsCheckupsControllerFindPatientAssignedCheckups(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsEventsApi - axios parameter creator
 * @export
 */
export const ExpertsEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create event with patient
         * @param {ExpertCreateEventDto} expertCreateEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerCreateEvent: async (expertCreateEventDto: ExpertCreateEventDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCreateEventDto' is not null or undefined
            assertParamExists('expertsEventsControllerCreateEvent', 'expertCreateEventDto', expertCreateEventDto)
            const localVarPath = `/experts/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCreateEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerDeleteEvent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsEventsControllerDeleteEvent', 'id', id)
            const localVarPath = `/experts/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit event
         * @param {string} id 
         * @param {ExpertEditEventDto} expertEditEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerEditEvent: async (id: string, expertEditEventDto: ExpertEditEventDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsEventsControllerEditEvent', 'id', id)
            // verify required parameter 'expertEditEventDto' is not null or undefined
            assertParamExists('expertsEventsControllerEditEvent', 'expertEditEventDto', expertEditEventDto)
            const localVarPath = `/experts/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertEditEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get events related to current expert
         * @param {Array<'CALL' | 'VIDEOCALL' | 'MEETING'>} [eventType] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerGetEvents: async (eventType?: Array<'CALL' | 'VIDEOCALL' | 'MEETING'>, start?: string, end?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventType) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsEventsApi - functional programming interface
 * @export
 */
export const ExpertsEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create event with patient
         * @param {ExpertCreateEventDto} expertCreateEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsEventsControllerCreateEvent(expertCreateEventDto: ExpertCreateEventDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsGetEventResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsEventsControllerCreateEvent(expertCreateEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsEventsControllerDeleteEvent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsEventsControllerDeleteEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit event
         * @param {string} id 
         * @param {ExpertEditEventDto} expertEditEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsEventsControllerEditEvent(id: string, expertEditEventDto: ExpertEditEventDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsGetEventResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsEventsControllerEditEvent(id, expertEditEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get events related to current expert
         * @param {Array<'CALL' | 'VIDEOCALL' | 'MEETING'>} [eventType] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsEventsControllerGetEvents(eventType?: Array<'CALL' | 'VIDEOCALL' | 'MEETING'>, start?: string, end?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventsGetEventResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsEventsControllerGetEvents(eventType, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsEventsApi - factory interface
 * @export
 */
export const ExpertsEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsEventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create event with patient
         * @param {ExpertCreateEventDto} expertCreateEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerCreateEvent(expertCreateEventDto: ExpertCreateEventDto, options?: any): AxiosPromise<EventsGetEventResDto> {
            return localVarFp.expertsEventsControllerCreateEvent(expertCreateEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete event
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerDeleteEvent(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.expertsEventsControllerDeleteEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit event
         * @param {string} id 
         * @param {ExpertEditEventDto} expertEditEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerEditEvent(id: string, expertEditEventDto: ExpertEditEventDto, options?: any): AxiosPromise<EventsGetEventResDto> {
            return localVarFp.expertsEventsControllerEditEvent(id, expertEditEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get events related to current expert
         * @param {Array<'CALL' | 'VIDEOCALL' | 'MEETING'>} [eventType] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsEventsControllerGetEvents(eventType?: Array<'CALL' | 'VIDEOCALL' | 'MEETING'>, start?: string, end?: string, options?: any): AxiosPromise<Array<EventsGetEventResDto>> {
            return localVarFp.expertsEventsControllerGetEvents(eventType, start, end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsEventsApi - object-oriented interface
 * @export
 * @class ExpertsEventsApi
 * @extends {BaseAPI}
 */
export class ExpertsEventsApi extends BaseAPI {
    /**
     * 
     * @summary Create event with patient
     * @param {ExpertCreateEventDto} expertCreateEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsEventsApi
     */
    public expertsEventsControllerCreateEvent(expertCreateEventDto: ExpertCreateEventDto, options?: AxiosRequestConfig) {
        return ExpertsEventsApiFp(this.configuration).expertsEventsControllerCreateEvent(expertCreateEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete event
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsEventsApi
     */
    public expertsEventsControllerDeleteEvent(id: string, options?: AxiosRequestConfig) {
        return ExpertsEventsApiFp(this.configuration).expertsEventsControllerDeleteEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit event
     * @param {string} id 
     * @param {ExpertEditEventDto} expertEditEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsEventsApi
     */
    public expertsEventsControllerEditEvent(id: string, expertEditEventDto: ExpertEditEventDto, options?: AxiosRequestConfig) {
        return ExpertsEventsApiFp(this.configuration).expertsEventsControllerEditEvent(id, expertEditEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get events related to current expert
     * @param {Array<'CALL' | 'VIDEOCALL' | 'MEETING'>} [eventType] 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsEventsApi
     */
    public expertsEventsControllerGetEvents(eventType?: Array<'CALL' | 'VIDEOCALL' | 'MEETING'>, start?: string, end?: string, options?: AxiosRequestConfig) {
        return ExpertsEventsApiFp(this.configuration).expertsEventsControllerGetEvents(eventType, start, end, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPatientAnamnesisApi - axios parameter creator
 * @export
 */
export const ExpertsPatientAnamnesisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get patient anamnesis by patient Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnamnesisControllerGetAnamnesisByPatientId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnamnesisControllerGetAnamnesisByPatientId', 'id', id)
            const localVarPath = `/experts/patients/{id}/anamnesis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find by patientId and mark anamnesis read by expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnamnesisControllerReadAnamnesis: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientAnamnesisControllerReadAnamnesis', 'id', id)
            const localVarPath = `/experts/patients/{id}/anamnesis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPatientAnamnesisApi - functional programming interface
 * @export
 */
export const ExpertsPatientAnamnesisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPatientAnamnesisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get patient anamnesis by patient Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnamnesisControllerGetAnamnesisByPatientId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Anamnesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnamnesisControllerGetAnamnesisByPatientId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find by patientId and mark anamnesis read by expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientAnamnesisControllerReadAnamnesis(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientAnamnesisControllerReadAnamnesis(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPatientAnamnesisApi - factory interface
 * @export
 */
export const ExpertsPatientAnamnesisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPatientAnamnesisApiFp(configuration)
    return {
        /**
         * 
         * @summary Get patient anamnesis by patient Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnamnesisControllerGetAnamnesisByPatientId(id: string, options?: any): AxiosPromise<Anamnesis> {
            return localVarFp.expertPatientAnamnesisControllerGetAnamnesisByPatientId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find by patientId and mark anamnesis read by expert
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientAnamnesisControllerReadAnamnesis(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.expertPatientAnamnesisControllerReadAnamnesis(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPatientAnamnesisApi - object-oriented interface
 * @export
 * @class ExpertsPatientAnamnesisApi
 * @extends {BaseAPI}
 */
export class ExpertsPatientAnamnesisApi extends BaseAPI {
    /**
     * 
     * @summary Get patient anamnesis by patient Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientAnamnesisApi
     */
    public expertPatientAnamnesisControllerGetAnamnesisByPatientId(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientAnamnesisApiFp(this.configuration).expertPatientAnamnesisControllerGetAnamnesisByPatientId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find by patientId and mark anamnesis read by expert
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientAnamnesisApi
     */
    public expertPatientAnamnesisControllerReadAnamnesis(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientAnamnesisApiFp(this.configuration).expertPatientAnamnesisControllerReadAnamnesis(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPatientDiaryApi - axios parameter creator
 * @export
 */
export const ExpertsPatientDiaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve patient diary pages by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPatientDiaryControllerGetPatientDiary: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsPatientDiaryControllerGetPatientDiary', 'id', id)
            const localVarPath = `/experts/patient-diary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPatientDiaryApi - functional programming interface
 * @export
 */
export const ExpertsPatientDiaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPatientDiaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve patient diary pages by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsPatientDiaryControllerGetPatientDiary(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientDiary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsPatientDiaryControllerGetPatientDiary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPatientDiaryApi - factory interface
 * @export
 */
export const ExpertsPatientDiaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPatientDiaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve patient diary pages by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPatientDiaryControllerGetPatientDiary(id: string, options?: any): AxiosPromise<Array<PatientDiary>> {
            return localVarFp.expertsPatientDiaryControllerGetPatientDiary(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPatientDiaryApi - object-oriented interface
 * @export
 * @class ExpertsPatientDiaryApi
 * @extends {BaseAPI}
 */
export class ExpertsPatientDiaryApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve patient diary pages by patientId
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientDiaryApi
     */
    public expertsPatientDiaryControllerGetPatientDiary(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientDiaryApiFp(this.configuration).expertsPatientDiaryControllerGetPatientDiary(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPatientNutritionPlansApi - axios parameter creator
 * @export
 */
export const ExpertsPatientNutritionPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve patient nutrition plans by patient ID
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerGetAllPatientNutritionPlans: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('expertsNutritionPlansControllerGetAllPatientNutritionPlans', 'patientId', patientId)
            const localVarPath = `/experts/patient-nutrition-plans/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one patient nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerGetOnePatientNutritionPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertsNutritionPlansControllerGetOnePatientNutritionPlan', 'id', id)
            const localVarPath = `/experts/patient-nutrition-plans/find-one/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prescribe nutrition plan to patient
         * @param {PrescribePatientNutritionPlanDto} prescribePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerPrescribePatientNutritionPlan: async (prescribePatientNutritionPlanDto: PrescribePatientNutritionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescribePatientNutritionPlanDto' is not null or undefined
            assertParamExists('expertsNutritionPlansControllerPrescribePatientNutritionPlan', 'prescribePatientNutritionPlanDto', prescribePatientNutritionPlanDto)
            const localVarPath = `/experts/patient-nutrition-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prescribePatientNutritionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient nutrition plan
         * @param {string} nutritionPlanId 
         * @param {UpdatePatientNutritionPlanDto} updatePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerUpdatePatientNutritionPlan: async (nutritionPlanId: string, updatePatientNutritionPlanDto: UpdatePatientNutritionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionPlanId' is not null or undefined
            assertParamExists('expertsNutritionPlansControllerUpdatePatientNutritionPlan', 'nutritionPlanId', nutritionPlanId)
            // verify required parameter 'updatePatientNutritionPlanDto' is not null or undefined
            assertParamExists('expertsNutritionPlansControllerUpdatePatientNutritionPlan', 'updatePatientNutritionPlanDto', updatePatientNutritionPlanDto)
            const localVarPath = `/experts/patient-nutrition-plans/{nutritionPlanId}/update`
                .replace(`{${"nutritionPlanId"}}`, encodeURIComponent(String(nutritionPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientNutritionPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPatientNutritionPlansApi - functional programming interface
 * @export
 */
export const ExpertsPatientNutritionPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPatientNutritionPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve patient nutrition plans by patient ID
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPatientNutritonPlanResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one patient nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientNutritonPlanResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsNutritionPlansControllerGetOnePatientNutritionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Prescribe nutrition plan to patient
         * @param {PrescribePatientNutritionPlanDto} prescribePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto: PrescribePatientNutritionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientNutritonPlanResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient nutrition plan
         * @param {string} nutritionPlanId 
         * @param {UpdatePatientNutritionPlanDto} updatePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId: string, updatePatientNutritionPlanDto: UpdatePatientNutritionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientNutritonPlanResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId, updatePatientNutritionPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPatientNutritionPlansApi - factory interface
 * @export
 */
export const ExpertsPatientNutritionPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPatientNutritionPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve patient nutrition plans by patient ID
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId: string, options?: any): AxiosPromise<Array<GetPatientNutritonPlanResDto>> {
            return localVarFp.expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one patient nutrition plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: any): AxiosPromise<GetPatientNutritonPlanResDto> {
            return localVarFp.expertsNutritionPlansControllerGetOnePatientNutritionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prescribe nutrition plan to patient
         * @param {PrescribePatientNutritionPlanDto} prescribePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto: PrescribePatientNutritionPlanDto, options?: any): AxiosPromise<GetPatientNutritonPlanResDto> {
            return localVarFp.expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient nutrition plan
         * @param {string} nutritionPlanId 
         * @param {UpdatePatientNutritionPlanDto} updatePatientNutritionPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId: string, updatePatientNutritionPlanDto: UpdatePatientNutritionPlanDto, options?: any): AxiosPromise<GetPatientNutritonPlanResDto> {
            return localVarFp.expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId, updatePatientNutritionPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPatientNutritionPlansApi - object-oriented interface
 * @export
 * @class ExpertsPatientNutritionPlansApi
 * @extends {BaseAPI}
 */
export class ExpertsPatientNutritionPlansApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve patient nutrition plans by patient ID
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientNutritionPlansApi
     */
    public expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId: string, options?: AxiosRequestConfig) {
        return ExpertsPatientNutritionPlansApiFp(this.configuration).expertsNutritionPlansControllerGetAllPatientNutritionPlans(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one patient nutrition plan
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientNutritionPlansApi
     */
    public expertsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientNutritionPlansApiFp(this.configuration).expertsNutritionPlansControllerGetOnePatientNutritionPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prescribe nutrition plan to patient
     * @param {PrescribePatientNutritionPlanDto} prescribePatientNutritionPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientNutritionPlansApi
     */
    public expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto: PrescribePatientNutritionPlanDto, options?: AxiosRequestConfig) {
        return ExpertsPatientNutritionPlansApiFp(this.configuration).expertsNutritionPlansControllerPrescribePatientNutritionPlan(prescribePatientNutritionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient nutrition plan
     * @param {string} nutritionPlanId 
     * @param {UpdatePatientNutritionPlanDto} updatePatientNutritionPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientNutritionPlansApi
     */
    public expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId: string, updatePatientNutritionPlanDto: UpdatePatientNutritionPlanDto, options?: AxiosRequestConfig) {
        return ExpertsPatientNutritionPlansApiFp(this.configuration).expertsNutritionPlansControllerUpdatePatientNutritionPlan(nutritionPlanId, updatePatientNutritionPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPatientRecommendationsApi - axios parameter creator
 * @export
 */
export const ExpertsPatientRecommendationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetAllRecommendationCards: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetAllRecommendationCards', 'id', id)
            const localVarPath = `/experts/patients/{id}/recommendations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientFirstTypeCard: async (id: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientFirstTypeCard', 'id', id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientFirstTypeCard', 'cardId', cardId)
            const localVarPath = `/experts/patients/{id}/recommendations/first-type/{cardId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientSecondTypeCard: async (id: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientSecondTypeCard', 'id', id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientSecondTypeCard', 'cardId', cardId)
            const localVarPath = `/experts/patients/{id}/recommendations/second-type/{cardId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientThirdTypeCard: async (id: string, cardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientThirdTypeCard', 'id', id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('expertPatientRecommendationsControllerGetOnePatientThirdTypeCard', 'cardId', cardId)
            const localVarPath = `/experts/patients/{id}/recommendations/third-type/{cardId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPatientRecommendationsApi - functional programming interface
 * @export
 */
export const ExpertsPatientRecommendationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPatientRecommendationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientRecommendationsControllerGetAllRecommendationCards(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecommendationCardsGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientRecommendationsControllerGetAllRecommendationCards(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirstTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id: string, cardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPatientRecommendationsApi - factory interface
 * @export
 */
export const ExpertsPatientRecommendationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPatientRecommendationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetAllRecommendationCards(id: string, options?: any): AxiosPromise<PatientRecommendationCardsGetResDto> {
            return localVarFp.expertPatientRecommendationsControllerGetAllRecommendationCards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id: string, cardId: string, options?: any): AxiosPromise<FirstTypeCardsPatientGetResDto> {
            return localVarFp.expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id: string, cardId: string, options?: any): AxiosPromise<SecondTypeCardsPatientGetResDto> {
            return localVarFp.expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id: string, cardId: string, options?: any): AxiosPromise<ThirdTypeCardsPatientGetResDto> {
            return localVarFp.expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id, cardId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPatientRecommendationsApi - object-oriented interface
 * @export
 * @class ExpertsPatientRecommendationsApi
 * @extends {BaseAPI}
 */
export class ExpertsPatientRecommendationsApi extends BaseAPI {
    /**
     * 
     * @summary Get all recommendation cards that belong to the patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientRecommendationsApi
     */
    public expertPatientRecommendationsControllerGetAllRecommendationCards(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientRecommendationsApiFp(this.configuration).expertPatientRecommendationsControllerGetAllRecommendationCards(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one first type card that belong to the patient
     * @param {string} id 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientRecommendationsApi
     */
    public expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id: string, cardId: string, options?: AxiosRequestConfig) {
        return ExpertsPatientRecommendationsApiFp(this.configuration).expertPatientRecommendationsControllerGetOnePatientFirstTypeCard(id, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one second type card that belong to the patient
     * @param {string} id 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientRecommendationsApi
     */
    public expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id: string, cardId: string, options?: AxiosRequestConfig) {
        return ExpertsPatientRecommendationsApiFp(this.configuration).expertPatientRecommendationsControllerGetOnePatientSecondTypeCard(id, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one third type card that belong to the patient
     * @param {string} id 
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientRecommendationsApi
     */
    public expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id: string, cardId: string, options?: AxiosRequestConfig) {
        return ExpertsPatientRecommendationsApiFp(this.configuration).expertPatientRecommendationsControllerGetOnePatientThirdTypeCard(id, cardId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPatientsApi - axios parameter creator
 * @export
 */
export const ExpertsPatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create patient and send referral link to email
         * @param {ExpertCreatePatientDto} expertCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerCreatePatient: async (expertCreatePatientDto: ExpertCreatePatientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCreatePatientDto' is not null or undefined
            assertParamExists('expertPatientsControllerCreatePatient', 'expertCreatePatientDto', expertCreatePatientDto)
            const localVarPath = `/experts/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCreatePatientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one patient by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerGetOnePatient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientsControllerGetOnePatient', 'id', id)
            const localVarPath = `/experts/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patients for current expert
         * @param {number} page 
         * @param {number} limit 
         * @param {'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt'} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'sent' | 'approved'} [signUpStatus] 
         * @param {boolean} [onlyNewRequests] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerGetPatients: async (page: number, limit: number, sortField?: 'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt', sortDirection?: 'ASC' | 'DESC', signUpStatus?: 'sent' | 'approved', onlyNewRequests?: boolean, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('expertPatientsControllerGetPatients', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('expertPatientsControllerGetPatients', 'limit', limit)
            const localVarPath = `/experts/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (signUpStatus !== undefined) {
                localVarQueryParameter['signUpStatus'] = signUpStatus;
            }

            if (onlyNewRequests !== undefined) {
                localVarQueryParameter['onlyNewRequests'] = onlyNewRequests;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop cooperation with patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerStopCooperation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertPatientsControllerStopCooperation', 'id', id)
            const localVarPath = `/experts/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPatientsApi - functional programming interface
 * @export
 */
export const ExpertsPatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create patient and send referral link to email
         * @param {ExpertCreatePatientDto} expertCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientsControllerCreatePatient(expertCreatePatientDto: ExpertCreatePatientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientsControllerCreatePatient(expertCreatePatientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one patient by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientsControllerGetOnePatient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientsControllerGetOnePatient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patients for current expert
         * @param {number} page 
         * @param {number} limit 
         * @param {'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt'} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'sent' | 'approved'} [signUpStatus] 
         * @param {boolean} [onlyNewRequests] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientsControllerGetPatients(page: number, limit: number, sortField?: 'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt', sortDirection?: 'ASC' | 'DESC', signUpStatus?: 'sent' | 'approved', onlyNewRequests?: boolean, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertGetPatientsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientsControllerGetPatients(page, limit, sortField, sortDirection, signUpStatus, onlyNewRequests, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop cooperation with patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertPatientsControllerStopCooperation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertPatientsControllerStopCooperation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPatientsApi - factory interface
 * @export
 */
export const ExpertsPatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPatientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create patient and send referral link to email
         * @param {ExpertCreatePatientDto} expertCreatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerCreatePatient(expertCreatePatientDto: ExpertCreatePatientDto, options?: any): AxiosPromise<Patient> {
            return localVarFp.expertPatientsControllerCreatePatient(expertCreatePatientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one patient by patientId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerGetOnePatient(id: string, options?: any): AxiosPromise<Patient> {
            return localVarFp.expertPatientsControllerGetOnePatient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patients for current expert
         * @param {number} page 
         * @param {number} limit 
         * @param {'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt'} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'sent' | 'approved'} [signUpStatus] 
         * @param {boolean} [onlyNewRequests] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerGetPatients(page: number, limit: number, sortField?: 'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt', sortDirection?: 'ASC' | 'DESC', signUpStatus?: 'sent' | 'approved', onlyNewRequests?: boolean, filter?: string, options?: any): AxiosPromise<ExpertGetPatientsResDto> {
            return localVarFp.expertPatientsControllerGetPatients(page, limit, sortField, sortDirection, signUpStatus, onlyNewRequests, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop cooperation with patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertPatientsControllerStopCooperation(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.expertPatientsControllerStopCooperation(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPatientsApi - object-oriented interface
 * @export
 * @class ExpertsPatientsApi
 * @extends {BaseAPI}
 */
export class ExpertsPatientsApi extends BaseAPI {
    /**
     * 
     * @summary Create patient and send referral link to email
     * @param {ExpertCreatePatientDto} expertCreatePatientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientsApi
     */
    public expertPatientsControllerCreatePatient(expertCreatePatientDto: ExpertCreatePatientDto, options?: AxiosRequestConfig) {
        return ExpertsPatientsApiFp(this.configuration).expertPatientsControllerCreatePatient(expertCreatePatientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one patient by patientId
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientsApi
     */
    public expertPatientsControllerGetOnePatient(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientsApiFp(this.configuration).expertPatientsControllerGetOnePatient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patients for current expert
     * @param {number} page 
     * @param {number} limit 
     * @param {'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt'} [sortField] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'sent' | 'approved'} [signUpStatus] 
     * @param {boolean} [onlyNewRequests] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientsApi
     */
    public expertPatientsControllerGetPatients(page: number, limit: number, sortField?: 'name' | 'birthday' | 'createdAt' | 'cooperatedWithExpertAt', sortDirection?: 'ASC' | 'DESC', signUpStatus?: 'sent' | 'approved', onlyNewRequests?: boolean, filter?: string, options?: AxiosRequestConfig) {
        return ExpertsPatientsApiFp(this.configuration).expertPatientsControllerGetPatients(page, limit, sortField, sortDirection, signUpStatus, onlyNewRequests, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop cooperation with patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPatientsApi
     */
    public expertPatientsControllerStopCooperation(id: string, options?: AxiosRequestConfig) {
        return ExpertsPatientsApiFp(this.configuration).expertPatientsControllerStopCooperation(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsPaymentsDiaryApi - axios parameter creator
 * @export
 */
export const ExpertsPaymentsDiaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create payment as Expert
         * @param {ExpertCreatePaymentsDiaryDto} expertCreatePaymentsDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerCreatePayment: async (expertCreatePaymentsDiaryDto: ExpertCreatePaymentsDiaryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertCreatePaymentsDiaryDto' is not null or undefined
            assertParamExists('expertsPaymentsDiaryControllerCreatePayment', 'expertCreatePaymentsDiaryDto', expertCreatePaymentsDiaryDto)
            const localVarPath = `/experts/payments-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertCreatePaymentsDiaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete payment by paymentId
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerDeletePaymentByPaymentId: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('expertsPaymentsDiaryControllerDeletePaymentByPaymentId', 'paymentId', paymentId)
            const localVarPath = `/experts/payments-diary/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payments-diary by expertId
         * @param {string} page 
         * @param {string} limit 
         * @param {'name' | 'date' | 'amount' | 'paid'} [sort] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerGetPayments: async (page: string, limit: string, sort?: 'name' | 'date' | 'amount' | 'paid', sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('expertsPaymentsDiaryControllerGetPayments', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('expertsPaymentsDiaryControllerGetPayments', 'limit', limit)
            const localVarPath = `/experts/payments-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary toggle payment PaidStatus as Expert
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerTogglePaymentPaidStatus: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('expertsPaymentsDiaryControllerTogglePaymentPaidStatus', 'paymentId', paymentId)
            const localVarPath = `/experts/payments-diary/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsPaymentsDiaryApi - functional programming interface
 * @export
 */
export const ExpertsPaymentsDiaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsPaymentsDiaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create payment as Expert
         * @param {ExpertCreatePaymentsDiaryDto} expertCreatePaymentsDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto: ExpertCreatePaymentsDiaryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertGetPaymentsDiaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete payment by paymentId
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payments-diary by expertId
         * @param {string} page 
         * @param {string} limit 
         * @param {'name' | 'date' | 'amount' | 'paid'} [sort] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsPaymentsDiaryControllerGetPayments(page: string, limit: string, sort?: 'name' | 'date' | 'amount' | 'paid', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertGetPaginatedPaymentsDiaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsPaymentsDiaryControllerGetPayments(page, limit, sort, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary toggle payment PaidStatus as Expert
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsPaymentsDiaryApi - factory interface
 * @export
 */
export const ExpertsPaymentsDiaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsPaymentsDiaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Create payment as Expert
         * @param {ExpertCreatePaymentsDiaryDto} expertCreatePaymentsDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto: ExpertCreatePaymentsDiaryDto, options?: any): AxiosPromise<ExpertGetPaymentsDiaryDto> {
            return localVarFp.expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete payment by paymentId
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payments-diary by expertId
         * @param {string} page 
         * @param {string} limit 
         * @param {'name' | 'date' | 'amount' | 'paid'} [sort] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerGetPayments(page: string, limit: string, sort?: 'name' | 'date' | 'amount' | 'paid', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<ExpertGetPaginatedPaymentsDiaryDto> {
            return localVarFp.expertsPaymentsDiaryControllerGetPayments(page, limit, sort, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary toggle payment PaidStatus as Expert
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsPaymentsDiaryApi - object-oriented interface
 * @export
 * @class ExpertsPaymentsDiaryApi
 * @extends {BaseAPI}
 */
export class ExpertsPaymentsDiaryApi extends BaseAPI {
    /**
     * 
     * @summary Create payment as Expert
     * @param {ExpertCreatePaymentsDiaryDto} expertCreatePaymentsDiaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPaymentsDiaryApi
     */
    public expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto: ExpertCreatePaymentsDiaryDto, options?: AxiosRequestConfig) {
        return ExpertsPaymentsDiaryApiFp(this.configuration).expertsPaymentsDiaryControllerCreatePayment(expertCreatePaymentsDiaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete payment by paymentId
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPaymentsDiaryApi
     */
    public expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId: string, options?: AxiosRequestConfig) {
        return ExpertsPaymentsDiaryApiFp(this.configuration).expertsPaymentsDiaryControllerDeletePaymentByPaymentId(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payments-diary by expertId
     * @param {string} page 
     * @param {string} limit 
     * @param {'name' | 'date' | 'amount' | 'paid'} [sort] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPaymentsDiaryApi
     */
    public expertsPaymentsDiaryControllerGetPayments(page: string, limit: string, sort?: 'name' | 'date' | 'amount' | 'paid', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return ExpertsPaymentsDiaryApiFp(this.configuration).expertsPaymentsDiaryControllerGetPayments(page, limit, sort, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary toggle payment PaidStatus as Expert
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsPaymentsDiaryApi
     */
    public expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId: string, options?: AxiosRequestConfig) {
        return ExpertsPaymentsDiaryApiFp(this.configuration).expertsPaymentsDiaryControllerTogglePaymentPaidStatus(paymentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpertsSettingsApi - axios parameter creator
 * @export
 */
export const ExpertsSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get expert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSettingsControllerGetExpertSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/experts/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update expert settings
         * @param {ExpertUpdateSettingsDto} expertUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSettingsControllerUpdateExpertSettings: async (expertUpdateSettingsDto: ExpertUpdateSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertUpdateSettingsDto' is not null or undefined
            assertParamExists('expertSettingsControllerUpdateExpertSettings', 'expertUpdateSettingsDto', expertUpdateSettingsDto)
            const localVarPath = `/experts/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertUpdateSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsSettingsApi - functional programming interface
 * @export
 */
export const ExpertsSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertsSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get expert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertSettingsControllerGetExpertSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertSettingsControllerGetExpertSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update expert settings
         * @param {ExpertUpdateSettingsDto} expertUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto: ExpertUpdateSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpertsSettingsApi - factory interface
 * @export
 */
export const ExpertsSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertsSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get expert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSettingsControllerGetExpertSettings(options?: any): AxiosPromise<ExpertSettings> {
            return localVarFp.expertSettingsControllerGetExpertSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update expert settings
         * @param {ExpertUpdateSettingsDto} expertUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto: ExpertUpdateSettingsDto, options?: any): AxiosPromise<ExpertSettings> {
            return localVarFp.expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertsSettingsApi - object-oriented interface
 * @export
 * @class ExpertsSettingsApi
 * @extends {BaseAPI}
 */
export class ExpertsSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get expert settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsSettingsApi
     */
    public expertSettingsControllerGetExpertSettings(options?: AxiosRequestConfig) {
        return ExpertsSettingsApiFp(this.configuration).expertSettingsControllerGetExpertSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update expert settings
     * @param {ExpertUpdateSettingsDto} expertUpdateSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsSettingsApi
     */
    public expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto: ExpertUpdateSettingsDto, options?: AxiosRequestConfig) {
        return ExpertsSettingsApiFp(this.configuration).expertSettingsControllerUpdateExpertSettings(expertUpdateSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientAnalyzesApi - axios parameter creator
 * @export
 */
export const PatientAnalyzesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one analysis
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerCreate: async (createPatientAnalysisDto: CreatePatientAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPatientAnalysisDto' is not null or undefined
            assertParamExists('patientAnalyzesControllerCreate', 'createPatientAnalysisDto', createPatientAnalysisDto)
            const localVarPath = `/analyzes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientAnalysisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientAnalyzesControllerDelete', 'id', id)
            const localVarPath = `/analyzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientAnalyzesControllerFindOne', 'id', id)
            const localVarPath = `/analyzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerFindOneWithPrevious: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientAnalyzesControllerFindOneWithPrevious', 'id', id)
            const localVarPath = `/analyzes/{id}/with-previous`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload analysis photo
         * @param {AnalyzesUploadSignedUrlDto} analyzesUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerGetPhotoSignedUrlToUpload: async (analyzesUploadSignedUrlDto: AnalyzesUploadSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analyzesUploadSignedUrlDto' is not null or undefined
            assertParamExists('patientAnalyzesControllerGetPhotoSignedUrlToUpload', 'analyzesUploadSignedUrlDto', analyzesUploadSignedUrlDto)
            const localVarPath = `/analyzes/signed-url-to-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analyzesUploadSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search paginated analysis
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerSearch: async (startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyzes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (normFilter !== undefined) {
                localVarQueryParameter['normFilter'] = normFilter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update analysis by id
         * @param {string} id 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerUpdate: async (id: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientAnalyzesControllerUpdate', 'id', id)
            // verify required parameter 'updatePatientAnalysisDto' is not null or undefined
            assertParamExists('patientAnalyzesControllerUpdate', 'updatePatientAnalysisDto', updatePatientAnalysisDto)
            const localVarPath = `/analyzes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientAnalysisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientAnalyzesApi - functional programming interface
 * @export
 */
export const PatientAnalyzesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientAnalyzesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one analysis
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerCreate(createPatientAnalysisDto: CreatePatientAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerCreate(createPatientAnalysisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerFindOneWithPrevious(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneAnalysisWithPreviousResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerFindOneWithPrevious(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload analysis photo
         * @param {AnalyzesUploadSignedUrlDto} analyzesUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto: AnalyzesUploadSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search paginated analysis
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerSearch(startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAnalyzesResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerSearch(startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update analysis by id
         * @param {string} id 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalyzesControllerUpdate(id: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalyzesControllerUpdate(id, updatePatientAnalysisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientAnalyzesApi - factory interface
 * @export
 */
export const PatientAnalyzesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientAnalyzesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one analysis
         * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerCreate(createPatientAnalysisDto: CreatePatientAnalysisDto, options?: any): AxiosPromise<Analysis> {
            return localVarFp.patientAnalyzesControllerCreate(createPatientAnalysisDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.patientAnalyzesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerFindOne(id: string, options?: any): AxiosPromise<Analysis> {
            return localVarFp.patientAnalyzesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve analysis by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerFindOneWithPrevious(id: string, options?: any): AxiosPromise<FindOneAnalysisWithPreviousResDto> {
            return localVarFp.patientAnalyzesControllerFindOneWithPrevious(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload analysis photo
         * @param {AnalyzesUploadSignedUrlDto} analyzesUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto: AnalyzesUploadSignedUrlDto, options?: any): AxiosPromise<FileUploadSignedUrlResDto> {
            return localVarFp.patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search paginated analysis
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerSearch(startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: any): AxiosPromise<SearchAnalyzesResDto> {
            return localVarFp.patientAnalyzesControllerSearch(startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update analysis by id
         * @param {string} id 
         * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalyzesControllerUpdate(id: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: any): AxiosPromise<Analysis> {
            return localVarFp.patientAnalyzesControllerUpdate(id, updatePatientAnalysisDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientAnalyzesApi - object-oriented interface
 * @export
 * @class PatientAnalyzesApi
 * @extends {BaseAPI}
 */
export class PatientAnalyzesApi extends BaseAPI {
    /**
     * 
     * @summary Create one analysis
     * @param {CreatePatientAnalysisDto} createPatientAnalysisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerCreate(createPatientAnalysisDto: CreatePatientAnalysisDto, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerCreate(createPatientAnalysisDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete analysis by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerDelete(id: string, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve analysis by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve analysis by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerFindOneWithPrevious(id: string, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerFindOneWithPrevious(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload analysis photo
     * @param {AnalyzesUploadSignedUrlDto} analyzesUploadSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto: AnalyzesUploadSignedUrlDto, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerGetPhotoSignedUrlToUpload(analyzesUploadSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search paginated analysis
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {'date' | 'createdAt' | 'updatedAt'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'all' | 'in-normal' | 'not-in-normal'} [normFilter] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerSearch(startDate?: string, endDate?: string, sortBy?: 'date' | 'createdAt' | 'updatedAt', sortDirection?: 'ASC' | 'DESC', normFilter?: 'all' | 'in-normal' | 'not-in-normal', page?: number, limit?: number, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerSearch(startDate, endDate, sortBy, sortDirection, normFilter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update analysis by id
     * @param {string} id 
     * @param {UpdatePatientAnalysisDto} updatePatientAnalysisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesApi
     */
    public patientAnalyzesControllerUpdate(id: string, updatePatientAnalysisDto: UpdatePatientAnalysisDto, options?: AxiosRequestConfig) {
        return PatientAnalyzesApiFp(this.configuration).patientAnalyzesControllerUpdate(id, updatePatientAnalysisDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientAnalyzesOpenAiApi - axios parameter creator
 * @export
 */
export const PatientAnalyzesOpenAiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Parse analysis as pdf file(new)
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisOpenaiControllerParseAnalysisFromPdf: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('patientAnalysisOpenaiControllerParseAnalysisFromPdf', 'file', file)
            const localVarPath = `/analyzes-openai/parse/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientAnalyzesOpenAiApi - functional programming interface
 * @export
 */
export const PatientAnalyzesOpenAiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientAnalyzesOpenAiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Parse analysis as pdf file(new)
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAnalysisOpenaiControllerParseAnalysisFromPdf(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsePdfOrImageResDtoV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAnalysisOpenaiControllerParseAnalysisFromPdf(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientAnalyzesOpenAiApi - factory interface
 * @export
 */
export const PatientAnalyzesOpenAiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientAnalyzesOpenAiApiFp(configuration)
    return {
        /**
         * 
         * @summary Parse analysis as pdf file(new)
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAnalysisOpenaiControllerParseAnalysisFromPdf(file: any, options?: any): AxiosPromise<Array<ParsePdfOrImageResDtoV2>> {
            return localVarFp.patientAnalysisOpenaiControllerParseAnalysisFromPdf(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientAnalyzesOpenAiApi - object-oriented interface
 * @export
 * @class PatientAnalyzesOpenAiApi
 * @extends {BaseAPI}
 */
export class PatientAnalyzesOpenAiApi extends BaseAPI {
    /**
     * 
     * @summary Parse analysis as pdf file(new)
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAnalyzesOpenAiApi
     */
    public patientAnalysisOpenaiControllerParseAnalysisFromPdf(file: any, options?: AxiosRequestConfig) {
        return PatientAnalyzesOpenAiApiFp(this.configuration).patientAnalysisOpenaiControllerParseAnalysisFromPdf(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete current biogeek expert patient onboarding
         * @param {BiogeekExpertPatientCompleteOnboardingDto} biogeekExpertPatientCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerBiogeekExpertPatientCompleteOnboarding: async (biogeekExpertPatientCompleteOnboardingDto: BiogeekExpertPatientCompleteOnboardingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biogeekExpertPatientCompleteOnboardingDto' is not null or undefined
            assertParamExists('patientsControllerBiogeekExpertPatientCompleteOnboarding', 'biogeekExpertPatientCompleteOnboardingDto', biogeekExpertPatientCompleteOnboardingDto)
            const localVarPath = `/patients/biogeek-expert/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biogeekExpertPatientCompleteOnboardingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerDeleteMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} signUpHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerGetPatientEmail: async (signUpHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpHash' is not null or undefined
            assertParamExists('patientsControllerGetPatientEmail', 'signUpHash', signUpHash)
            const localVarPath = `/patients/email/{signUpHash}`
                .replace(`{${"signUpHash"}}`, encodeURIComponent(String(signUpHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload patient avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerGetPhotoSignedUrlToUpload: async (photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photoUploadSignedUrlDto' is not null or undefined
            assertParamExists('patientsControllerGetPhotoSignedUrlToUpload', 'photoUploadSignedUrlDto', photoUploadSignedUrlDto)
            const localVarPath = `/patients/signed-url-to-upload-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(photoUploadSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore current patient password
         * @param {PatientRestorePasswordDto} patientRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerRestorePassword: async (patientRestorePasswordDto: PatientRestorePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientRestorePasswordDto' is not null or undefined
            assertParamExists('patientsControllerRestorePassword', 'patientRestorePasswordDto', patientRestorePasswordDto)
            const localVarPath = `/patients/me/restore-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientRestorePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to verify current patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendCurrentEmailVerificationCode: async (patientEmailCodeDto: PatientEmailCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientEmailCodeDto' is not null or undefined
            assertParamExists('patientsControllerSendCurrentEmailVerificationCode', 'patientEmailCodeDto', patientEmailCodeDto)
            const localVarPath = `/patients/me/email/current/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientEmailCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to new patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendNewEmailVerificationCode: async (patientEmailCodeDto: PatientEmailCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientEmailCodeDto' is not null or undefined
            assertParamExists('patientsControllerSendNewEmailVerificationCode', 'patientEmailCodeDto', patientEmailCodeDto)
            const localVarPath = `/patients/me/email/new/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientEmailCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send restore password link to current patient email
         * @param {PatientForgotPasswordDto} patientForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendRestorePasswordLink: async (patientForgotPasswordDto: PatientForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientForgotPasswordDto' is not null or undefined
            assertParamExists('patientsControllerSendRestorePasswordLink', 'patientForgotPasswordDto', patientForgotPasswordDto)
            const localVarPath = `/patients/me/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {PatientSignUpVerificationDto} patientSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendSignUpVerificationCode: async (patientSignUpVerificationDto: PatientSignUpVerificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientSignUpVerificationDto' is not null or undefined
            assertParamExists('patientsControllerSendSignUpVerificationCode', 'patientSignUpVerificationDto', patientSignUpVerificationDto)
            const localVarPath = `/patients/sign-up/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSignUpVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set new patient email
         * @param {PatientSetNewEmailDto} patientSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSetNewEmail: async (patientSetNewEmailDto: PatientSetNewEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientSetNewEmailDto' is not null or undefined
            assertParamExists('patientsControllerSetNewEmail', 'patientSetNewEmailDto', patientSetNewEmailDto)
            const localVarPath = `/patients/me/email/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSetNewEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in patient by email and password and retrieve patient with bearer token
         * @param {PatientSignInDto} patientSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignIn: async (patientSignInDto: PatientSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientSignInDto' is not null or undefined
            assertParamExists('patientsControllerSignIn', 'patientSignInDto', patientSignInDto)
            const localVarPath = `/patients/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up patient by email and password and retrieve patient with bearer token
         * @param {BiogeekHealthPatientSignUpDto} biogeekHealthPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignUp: async (biogeekHealthPatientSignUpDto: BiogeekHealthPatientSignUpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biogeekHealthPatientSignUpDto' is not null or undefined
            assertParamExists('patientsControllerSignUp', 'biogeekHealthPatientSignUpDto', biogeekHealthPatientSignUpDto)
            const localVarPath = `/patients/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biogeekHealthPatientSignUpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Up patient with referral link
         * @param {BiogeekExpertPatientSignUpDto} biogeekExpertPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignUpPatient: async (biogeekExpertPatientSignUpDto: BiogeekExpertPatientSignUpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biogeekExpertPatientSignUpDto' is not null or undefined
            assertParamExists('patientsControllerSignUpPatient', 'biogeekExpertPatientSignUpDto', biogeekExpertPatientSignUpDto)
            const localVarPath = `/patients/biogeek-expert/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biogeekExpertPatientSignUpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current patient
         * @param {UpdatePatientDto} updatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerUpdateMe: async (updatePatientDto: UpdatePatientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientDto' is not null or undefined
            assertParamExists('patientsControllerUpdateMe', 'updatePatientDto', updatePatientDto)
            const localVarPath = `/patients/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update password of current patient
         * @param {PatientUpdatePasswordDto} patientUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerUpdatePassword: async (patientUpdatePasswordDto: PatientUpdatePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientUpdatePasswordDto' is not null or undefined
            assertParamExists('patientsControllerUpdatePassword', 'patientUpdatePasswordDto', patientUpdatePasswordDto)
            const localVarPath = `/patients/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {PatientVerifyCurrentEmailDto} patientVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerVerifyCurrentEmail: async (patientVerifyCurrentEmailDto: PatientVerifyCurrentEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientVerifyCurrentEmailDto' is not null or undefined
            assertParamExists('patientsControllerVerifyCurrentEmail', 'patientVerifyCurrentEmailDto', patientVerifyCurrentEmailDto)
            const localVarPath = `/patients/me/email/current/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientVerifyCurrentEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete current biogeek expert patient onboarding
         * @param {BiogeekExpertPatientCompleteOnboardingDto} biogeekExpertPatientCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto: BiogeekExpertPatientCompleteOnboardingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerDeleteMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerDeleteMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} signUpHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerGetPatientEmail(signUpHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerGetPatientEmail(signUpHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload patient avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadSignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore current patient password
         * @param {PatientRestorePasswordDto} patientRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerRestorePassword(patientRestorePasswordDto: PatientRestorePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerRestorePassword(patientRestorePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to verify current patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to new patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send restore password link to current patient email
         * @param {PatientForgotPasswordDto} patientForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSendRestorePasswordLink(patientForgotPasswordDto: PatientForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSendRestorePasswordLink(patientForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {PatientSignUpVerificationDto} patientSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto: PatientSignUpVerificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set new patient email
         * @param {PatientSetNewEmailDto} patientSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSetNewEmail(patientSetNewEmailDto: PatientSetNewEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSetNewEmail(patientSetNewEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in patient by email and password and retrieve patient with bearer token
         * @param {PatientSignInDto} patientSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSignIn(patientSignInDto: PatientSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSignIn(patientSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up patient by email and password and retrieve patient with bearer token
         * @param {BiogeekHealthPatientSignUpDto} biogeekHealthPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSignUp(biogeekHealthPatientSignUpDto: BiogeekHealthPatientSignUpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSignUp(biogeekHealthPatientSignUpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign Up patient with referral link
         * @param {BiogeekExpertPatientSignUpDto} biogeekExpertPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto: BiogeekExpertPatientSignUpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientAuthResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current patient
         * @param {UpdatePatientDto} updatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerUpdateMe(updatePatientDto: UpdatePatientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerUpdateMe(updatePatientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update password of current patient
         * @param {PatientUpdatePasswordDto} patientUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerUpdatePassword(patientUpdatePasswordDto: PatientUpdatePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerUpdatePassword(patientUpdatePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {PatientVerifyCurrentEmailDto} patientVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto: PatientVerifyCurrentEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete current biogeek expert patient onboarding
         * @param {BiogeekExpertPatientCompleteOnboardingDto} biogeekExpertPatientCompleteOnboardingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto: BiogeekExpertPatientCompleteOnboardingDto, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerDeleteMe(options?: any): AxiosPromise<void> {
            return localVarFp.patientsControllerDeleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerFindMe(options?: any): AxiosPromise<Patient> {
            return localVarFp.patientsControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} signUpHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerGetPatientEmail(signUpHash: string, options?: any): AxiosPromise<string> {
            return localVarFp.patientsControllerGetPatientEmail(signUpHash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload patient avatar photo
         * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: any): AxiosPromise<FileUploadSignedUrlResDto> {
            return localVarFp.patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore current patient password
         * @param {PatientRestorePasswordDto} patientRestorePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerRestorePassword(patientRestorePasswordDto: PatientRestorePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.patientsControllerRestorePassword(patientRestorePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to verify current patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to new patient email
         * @param {PatientEmailCodeDto} patientEmailCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send restore password link to current patient email
         * @param {PatientForgotPasswordDto} patientForgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendRestorePasswordLink(patientForgotPasswordDto: PatientForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.patientsControllerSendRestorePasswordLink(patientForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification code to email
         * @param {PatientSignUpVerificationDto} patientSignUpVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto: PatientSignUpVerificationDto, options?: any): AxiosPromise<string> {
            return localVarFp.patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set new patient email
         * @param {PatientSetNewEmailDto} patientSetNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSetNewEmail(patientSetNewEmailDto: PatientSetNewEmailDto, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientsControllerSetNewEmail(patientSetNewEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in patient by email and password and retrieve patient with bearer token
         * @param {PatientSignInDto} patientSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignIn(patientSignInDto: PatientSignInDto, options?: any): AxiosPromise<PatientAuthResDto> {
            return localVarFp.patientsControllerSignIn(patientSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up patient by email and password and retrieve patient with bearer token
         * @param {BiogeekHealthPatientSignUpDto} biogeekHealthPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignUp(biogeekHealthPatientSignUpDto: BiogeekHealthPatientSignUpDto, options?: any): AxiosPromise<PatientAuthResDto> {
            return localVarFp.patientsControllerSignUp(biogeekHealthPatientSignUpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign Up patient with referral link
         * @param {BiogeekExpertPatientSignUpDto} biogeekExpertPatientSignUpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto: BiogeekExpertPatientSignUpDto, options?: any): AxiosPromise<PatientAuthResDto> {
            return localVarFp.patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current patient
         * @param {UpdatePatientDto} updatePatientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerUpdateMe(updatePatientDto: UpdatePatientDto, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientsControllerUpdateMe(updatePatientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update password of current patient
         * @param {PatientUpdatePasswordDto} patientUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerUpdatePassword(patientUpdatePasswordDto: PatientUpdatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.patientsControllerUpdatePassword(patientUpdatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify current email to change
         * @param {PatientVerifyCurrentEmailDto} patientVerifyCurrentEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto: PatientVerifyCurrentEmailDto, options?: any): AxiosPromise<string> {
            return localVarFp.patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * 
     * @summary Complete current biogeek expert patient onboarding
     * @param {BiogeekExpertPatientCompleteOnboardingDto} biogeekExpertPatientCompleteOnboardingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto: BiogeekExpertPatientCompleteOnboardingDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerBiogeekExpertPatientCompleteOnboarding(biogeekExpertPatientCompleteOnboardingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete current patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerDeleteMe(options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerDeleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerFindMe(options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} signUpHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerGetPatientEmail(signUpHash: string, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerGetPatientEmail(signUpHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload patient avatar photo
     * @param {PhotoUploadSignedUrlDto} photoUploadSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore current patient password
     * @param {PatientRestorePasswordDto} patientRestorePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerRestorePassword(patientRestorePasswordDto: PatientRestorePasswordDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerRestorePassword(patientRestorePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to verify current patient email
     * @param {PatientEmailCodeDto} patientEmailCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSendCurrentEmailVerificationCode(patientEmailCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to new patient email
     * @param {PatientEmailCodeDto} patientEmailCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto: PatientEmailCodeDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSendNewEmailVerificationCode(patientEmailCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send restore password link to current patient email
     * @param {PatientForgotPasswordDto} patientForgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSendRestorePasswordLink(patientForgotPasswordDto: PatientForgotPasswordDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSendRestorePasswordLink(patientForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification code to email
     * @param {PatientSignUpVerificationDto} patientSignUpVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto: PatientSignUpVerificationDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSendSignUpVerificationCode(patientSignUpVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set new patient email
     * @param {PatientSetNewEmailDto} patientSetNewEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSetNewEmail(patientSetNewEmailDto: PatientSetNewEmailDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSetNewEmail(patientSetNewEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in patient by email and password and retrieve patient with bearer token
     * @param {PatientSignInDto} patientSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSignIn(patientSignInDto: PatientSignInDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSignIn(patientSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up patient by email and password and retrieve patient with bearer token
     * @param {BiogeekHealthPatientSignUpDto} biogeekHealthPatientSignUpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSignUp(biogeekHealthPatientSignUpDto: BiogeekHealthPatientSignUpDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSignUp(biogeekHealthPatientSignUpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign Up patient with referral link
     * @param {BiogeekExpertPatientSignUpDto} biogeekExpertPatientSignUpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto: BiogeekExpertPatientSignUpDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerSignUpPatient(biogeekExpertPatientSignUpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current patient
     * @param {UpdatePatientDto} updatePatientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerUpdateMe(updatePatientDto: UpdatePatientDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerUpdateMe(updatePatientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update password of current patient
     * @param {PatientUpdatePasswordDto} patientUpdatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerUpdatePassword(patientUpdatePasswordDto: PatientUpdatePasswordDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerUpdatePassword(patientUpdatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify current email to change
     * @param {PatientVerifyCurrentEmailDto} patientVerifyCurrentEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto: PatientVerifyCurrentEmailDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).patientsControllerVerifyCurrentEmail(patientVerifyCurrentEmailDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsAnalysisKindsApi - axios parameter creator
 * @export
 */
export const PatientsAnalysisKindsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnalysisKindsControllerGetAnalysisKinds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/analysis-kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsAnalysisKindsApi - functional programming interface
 * @export
 */
export const PatientsAnalysisKindsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsAnalysisKindsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsAnalysisKindsControllerGetAnalysisKinds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisKindGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsAnalysisKindsControllerGetAnalysisKinds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsAnalysisKindsApi - factory interface
 * @export
 */
export const PatientsAnalysisKindsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsAnalysisKindsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of analysis kinds with their biomarkers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnalysisKindsControllerGetAnalysisKinds(options?: any): AxiosPromise<Array<AnalysisKindGetResDto>> {
            return localVarFp.patientsAnalysisKindsControllerGetAnalysisKinds(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsAnalysisKindsApi - object-oriented interface
 * @export
 * @class PatientsAnalysisKindsApi
 * @extends {BaseAPI}
 */
export class PatientsAnalysisKindsApi extends BaseAPI {
    /**
     * 
     * @summary Get list of analysis kinds with their biomarkers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsAnalysisKindsApi
     */
    public patientsAnalysisKindsControllerGetAnalysisKinds(options?: AxiosRequestConfig) {
        return PatientsAnalysisKindsApiFp(this.configuration).patientsAnalysisKindsControllerGetAnalysisKinds(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsAnamnesisApi - axios parameter creator
 * @export
 */
export const PatientsAnamnesisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create anamnesis for patient
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerCreateAnamnesis: async (patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientCreateAnamnesisDto' is not null or undefined
            assertParamExists('patientsAnamnesisControllerCreateAnamnesis', 'patientCreateAnamnesisDto', patientCreateAnamnesisDto)
            const localVarPath = `/patients/anamnesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreateAnamnesisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient anamnesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerGetAnamnesis: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/anamnesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient\'s anamnesis
         * @param {string} anamnesisId 
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerUpdateAnamnesis: async (anamnesisId: string, patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'anamnesisId' is not null or undefined
            assertParamExists('patientsAnamnesisControllerUpdateAnamnesis', 'anamnesisId', anamnesisId)
            // verify required parameter 'patientCreateAnamnesisDto' is not null or undefined
            assertParamExists('patientsAnamnesisControllerUpdateAnamnesis', 'patientCreateAnamnesisDto', patientCreateAnamnesisDto)
            const localVarPath = `/patients/anamnesis/{anamnesisId}`
                .replace(`{${"anamnesisId"}}`, encodeURIComponent(String(anamnesisId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreateAnamnesisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsAnamnesisApi - functional programming interface
 * @export
 */
export const PatientsAnamnesisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsAnamnesisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create anamnesis for patient
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Anamnesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient anamnesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsAnamnesisControllerGetAnamnesis(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Anamnesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsAnamnesisControllerGetAnamnesis(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient\'s anamnesis
         * @param {string} anamnesisId 
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsAnamnesisControllerUpdateAnamnesis(anamnesisId: string, patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Anamnesis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsAnamnesisControllerUpdateAnamnesis(anamnesisId, patientCreateAnamnesisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsAnamnesisApi - factory interface
 * @export
 */
export const PatientsAnamnesisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsAnamnesisApiFp(configuration)
    return {
        /**
         * 
         * @summary Create anamnesis for patient
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: any): AxiosPromise<Anamnesis> {
            return localVarFp.patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient anamnesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerGetAnamnesis(options?: any): AxiosPromise<Anamnesis> {
            return localVarFp.patientsAnamnesisControllerGetAnamnesis(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient\'s anamnesis
         * @param {string} anamnesisId 
         * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsAnamnesisControllerUpdateAnamnesis(anamnesisId: string, patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: any): AxiosPromise<Anamnesis> {
            return localVarFp.patientsAnamnesisControllerUpdateAnamnesis(anamnesisId, patientCreateAnamnesisDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsAnamnesisApi - object-oriented interface
 * @export
 * @class PatientsAnamnesisApi
 * @extends {BaseAPI}
 */
export class PatientsAnamnesisApi extends BaseAPI {
    /**
     * 
     * @summary Create anamnesis for patient
     * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsAnamnesisApi
     */
    public patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: AxiosRequestConfig) {
        return PatientsAnamnesisApiFp(this.configuration).patientsAnamnesisControllerCreateAnamnesis(patientCreateAnamnesisDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient anamnesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsAnamnesisApi
     */
    public patientsAnamnesisControllerGetAnamnesis(options?: AxiosRequestConfig) {
        return PatientsAnamnesisApiFp(this.configuration).patientsAnamnesisControllerGetAnamnesis(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient\'s anamnesis
     * @param {string} anamnesisId 
     * @param {PatientCreateAnamnesisDto} patientCreateAnamnesisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsAnamnesisApi
     */
    public patientsAnamnesisControllerUpdateAnamnesis(anamnesisId: string, patientCreateAnamnesisDto: PatientCreateAnamnesisDto, options?: AxiosRequestConfig) {
        return PatientsAnamnesisApiFp(this.configuration).patientsAnamnesisControllerUpdateAnamnesis(anamnesisId, patientCreateAnamnesisDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsChatsApi - axios parameter creator
 * @export
 */
export const PatientsChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create chat room with expert
         * @param {PatientCreateChatDto} patientCreateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsChatsControllerCreateChatRoom: async (patientCreateChatDto: PatientCreateChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientCreateChatDto' is not null or undefined
            assertParamExists('patientsChatsControllerCreateChatRoom', 'patientCreateChatDto', patientCreateChatDto)
            const localVarPath = `/patients/chat-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreateChatDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient\'s chat room 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsChatsControllerGetChatRooms: async (searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/chat-rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsChatsApi - functional programming interface
 * @export
 */
export const PatientsChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create chat room with expert
         * @param {PatientCreateChatDto} patientCreateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsChatsControllerCreateChatRoom(patientCreateChatDto: PatientCreateChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatRoomsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsChatsControllerCreateChatRoom(patientCreateChatDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient\'s chat room 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsChatsControllerGetChatRooms(searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChatRoomsResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsChatsControllerGetChatRooms(searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsChatsApi - factory interface
 * @export
 */
export const PatientsChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create chat room with expert
         * @param {PatientCreateChatDto} patientCreateChatDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsChatsControllerCreateChatRoom(patientCreateChatDto: PatientCreateChatDto, options?: any): AxiosPromise<GetChatRoomsResDto> {
            return localVarFp.patientsChatsControllerCreateChatRoom(patientCreateChatDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient\'s chat room 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsChatsControllerGetChatRooms(searchQuery?: string, options?: any): AxiosPromise<Array<GetChatRoomsResDto>> {
            return localVarFp.patientsChatsControllerGetChatRooms(searchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsChatsApi - object-oriented interface
 * @export
 * @class PatientsChatsApi
 * @extends {BaseAPI}
 */
export class PatientsChatsApi extends BaseAPI {
    /**
     * 
     * @summary Create chat room with expert
     * @param {PatientCreateChatDto} patientCreateChatDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsChatsApi
     */
    public patientsChatsControllerCreateChatRoom(patientCreateChatDto: PatientCreateChatDto, options?: AxiosRequestConfig) {
        return PatientsChatsApiFp(this.configuration).patientsChatsControllerCreateChatRoom(patientCreateChatDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient\'s chat room 
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsChatsApi
     */
    public patientsChatsControllerGetChatRooms(searchQuery?: string, options?: AxiosRequestConfig) {
        return PatientsChatsApiFp(this.configuration).patientsChatsControllerGetChatRooms(searchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsCheckupsApi - axios parameter creator
 * @export
 */
export const PatientsCheckupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get assigned checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetAssignedCheckups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/assigned-checkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetCheckups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/checkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one checkup with biomarkers
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetOneCheckup: async (checkupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkupId' is not null or undefined
            assertParamExists('patientsCheckupsControllerGetOneCheckup', 'checkupId', checkupId)
            const localVarPath = `/patients/checkups/{checkupId}`
                .replace(`{${"checkupId"}}`, encodeURIComponent(String(checkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsCheckupsApi - functional programming interface
 * @export
 */
export const PatientsCheckupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsCheckupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get assigned checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsCheckupsControllerGetAssignedCheckups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckupResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsCheckupsControllerGetAssignedCheckups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsCheckupsControllerGetCheckups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckupResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsCheckupsControllerGetCheckups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one checkup with biomarkers
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsCheckupsControllerGetOneCheckup(checkupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Checkup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsCheckupsControllerGetOneCheckup(checkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsCheckupsApi - factory interface
 * @export
 */
export const PatientsCheckupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsCheckupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get assigned checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetAssignedCheckups(options?: any): AxiosPromise<Array<CheckupResDto>> {
            return localVarFp.patientsCheckupsControllerGetAssignedCheckups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checkups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetCheckups(options?: any): AxiosPromise<Array<CheckupResDto>> {
            return localVarFp.patientsCheckupsControllerGetCheckups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one checkup with biomarkers
         * @param {string} checkupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsCheckupsControllerGetOneCheckup(checkupId: string, options?: any): AxiosPromise<Checkup> {
            return localVarFp.patientsCheckupsControllerGetOneCheckup(checkupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsCheckupsApi - object-oriented interface
 * @export
 * @class PatientsCheckupsApi
 * @extends {BaseAPI}
 */
export class PatientsCheckupsApi extends BaseAPI {
    /**
     * 
     * @summary Get assigned checkups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsCheckupsApi
     */
    public patientsCheckupsControllerGetAssignedCheckups(options?: AxiosRequestConfig) {
        return PatientsCheckupsApiFp(this.configuration).patientsCheckupsControllerGetAssignedCheckups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checkups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsCheckupsApi
     */
    public patientsCheckupsControllerGetCheckups(options?: AxiosRequestConfig) {
        return PatientsCheckupsApiFp(this.configuration).patientsCheckupsControllerGetCheckups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one checkup with biomarkers
     * @param {string} checkupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsCheckupsApi
     */
    public patientsCheckupsControllerGetOneCheckup(checkupId: string, options?: AxiosRequestConfig) {
        return PatientsCheckupsApiFp(this.configuration).patientsCheckupsControllerGetOneCheckup(checkupId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsExpertsApi - axios parameter creator
 * @export
 */
export const PatientsExpertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {number} [seedForShuffle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerFindExperts: async (page: number, limit: number, filter?: string, seedForShuffle?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('patientsExpertsControllerFindExperts', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('patientsExpertsControllerFindExperts', 'limit', limit)
            const localVarPath = `/patients/experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (seedForShuffle !== undefined) {
                localVarQueryParameter['seedForShuffle'] = seedForShuffle;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerFindOneExpert: async (expertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('patientsExpertsControllerFindOneExpert', 'expertId', expertId)
            const localVarPath = `/patients/experts/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop cooperation with expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerStopCooperation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsExpertsApi - functional programming interface
 * @export
 */
export const PatientsExpertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsExpertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {number} [seedForShuffle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsExpertsControllerFindExperts(page: number, limit: number, filter?: string, seedForShuffle?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientGetExpertsResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsExpertsControllerFindExperts(page, limit, filter, seedForShuffle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsExpertsControllerFindOneExpert(expertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertFullInfoResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsExpertsControllerFindOneExpert(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop cooperation with expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsExpertsControllerStopCooperation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsExpertsControllerStopCooperation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsExpertsApi - factory interface
 * @export
 */
export const PatientsExpertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsExpertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list experts
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {number} [seedForShuffle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerFindExperts(page: number, limit: number, filter?: string, seedForShuffle?: number, options?: any): AxiosPromise<PatientGetExpertsResDto> {
            return localVarFp.patientsExpertsControllerFindExperts(page, limit, filter, seedForShuffle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerFindOneExpert(expertId: string, options?: any): AxiosPromise<ExpertFullInfoResDto> {
            return localVarFp.patientsExpertsControllerFindOneExpert(expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop cooperation with expert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsExpertsControllerStopCooperation(options?: any): AxiosPromise<boolean> {
            return localVarFp.patientsExpertsControllerStopCooperation(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsExpertsApi - object-oriented interface
 * @export
 * @class PatientsExpertsApi
 * @extends {BaseAPI}
 */
export class PatientsExpertsApi extends BaseAPI {
    /**
     * 
     * @summary Get list experts
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {number} [seedForShuffle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsExpertsApi
     */
    public patientsExpertsControllerFindExperts(page: number, limit: number, filter?: string, seedForShuffle?: number, options?: AxiosRequestConfig) {
        return PatientsExpertsApiFp(this.configuration).patientsExpertsControllerFindExperts(page, limit, filter, seedForShuffle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one expert
     * @param {string} expertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsExpertsApi
     */
    public patientsExpertsControllerFindOneExpert(expertId: string, options?: AxiosRequestConfig) {
        return PatientsExpertsApiFp(this.configuration).patientsExpertsControllerFindOneExpert(expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop cooperation with expert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsExpertsApi
     */
    public patientsExpertsControllerStopCooperation(options?: AxiosRequestConfig) {
        return PatientsExpertsApiFp(this.configuration).patientsExpertsControllerStopCooperation(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsNutritionPlanApi - axios parameter creator
 * @export
 */
export const PatientsNutritionPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve nutrition plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerFindPatientNutritionPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/nutrition-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one patient nutrition plan that belong to the current patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerGetOnePatientNutritionPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsNutritionPlansControllerGetOnePatientNutritionPlan', 'id', id)
            const localVarPath = `/patients/nutrition-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark nutrition plan as seen by patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen', 'id', id)
            const localVarPath = `/patients/nutrition-plan/{id}/seen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsNutritionPlanApi - functional programming interface
 * @export
 */
export const PatientsNutritionPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsNutritionPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve nutrition plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsNutritionPlansControllerFindPatientNutritionPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientNutritonPlanResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsNutritionPlansControllerFindPatientNutritionPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one patient nutrition plan that belong to the current patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientNutritonPlanResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsNutritionPlansControllerGetOnePatientNutritionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark nutrition plan as seen by patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsNutritionPlanApi - factory interface
 * @export
 */
export const PatientsNutritionPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsNutritionPlanApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve nutrition plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerFindPatientNutritionPlan(options?: any): AxiosPromise<GetPatientNutritonPlanResDto> {
            return localVarFp.patientsNutritionPlansControllerFindPatientNutritionPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one patient nutrition plan that belong to the current patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: any): AxiosPromise<GetPatientNutritonPlanResDto> {
            return localVarFp.patientsNutritionPlansControllerGetOnePatientNutritionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark nutrition plan as seen by patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsNutritionPlanApi - object-oriented interface
 * @export
 * @class PatientsNutritionPlanApi
 * @extends {BaseAPI}
 */
export class PatientsNutritionPlanApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve nutrition plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsNutritionPlanApi
     */
    public patientsNutritionPlansControllerFindPatientNutritionPlan(options?: AxiosRequestConfig) {
        return PatientsNutritionPlanApiFp(this.configuration).patientsNutritionPlansControllerFindPatientNutritionPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one patient nutrition plan that belong to the current patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsNutritionPlanApi
     */
    public patientsNutritionPlansControllerGetOnePatientNutritionPlan(id: string, options?: AxiosRequestConfig) {
        return PatientsNutritionPlanApiFp(this.configuration).patientsNutritionPlansControllerGetOnePatientNutritionPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark nutrition plan as seen by patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsNutritionPlanApi
     */
    public patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id: string, options?: AxiosRequestConfig) {
        return PatientsNutritionPlanApiFp(this.configuration).patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsPatientDiaryApi - axios parameter creator
 * @export
 */
export const PatientsPatientDiaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerClearPatientDiary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/patient-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create instance of diary records (by day) or rewrite it
         * @param {'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7'} day 
         * @param {PatientCreatePatientDiaryDto} patientCreatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerCreatePatientDiary: async (day: 'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7', patientCreatePatientDiaryDto: PatientCreatePatientDiaryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('patientsPatientDiaryControllerCreatePatientDiary', 'day', day)
            // verify required parameter 'patientCreatePatientDiaryDto' is not null or undefined
            assertParamExists('patientsPatientDiaryControllerCreatePatientDiary', 'patientCreatePatientDiaryDto', patientCreatePatientDiaryDto)
            const localVarPath = `/patients/patient-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (day !== undefined) {
                localVarQueryParameter['day'] = day;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCreatePatientDiaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerGetPatientDiary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/patient-diary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientUpdatePatientDiaryDto} patientUpdatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerUpdatePatientDiary: async (id: string, patientUpdatePatientDiaryDto: PatientUpdatePatientDiaryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsPatientDiaryControllerUpdatePatientDiary', 'id', id)
            // verify required parameter 'patientUpdatePatientDiaryDto' is not null or undefined
            assertParamExists('patientsPatientDiaryControllerUpdatePatientDiary', 'patientUpdatePatientDiaryDto', patientUpdatePatientDiaryDto)
            const localVarPath = `/patients/patient-diary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdatePatientDiaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsPatientDiaryApi - functional programming interface
 * @export
 */
export const PatientsPatientDiaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsPatientDiaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPatientDiaryControllerClearPatientDiary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPatientDiaryControllerClearPatientDiary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create instance of diary records (by day) or rewrite it
         * @param {'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7'} day 
         * @param {PatientCreatePatientDiaryDto} patientCreatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPatientDiaryControllerCreatePatientDiary(day: 'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7', patientCreatePatientDiaryDto: PatientCreatePatientDiaryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDiary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPatientDiaryControllerCreatePatientDiary(day, patientCreatePatientDiaryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPatientDiaryControllerGetPatientDiary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientDiary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPatientDiaryControllerGetPatientDiary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientUpdatePatientDiaryDto} patientUpdatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsPatientDiaryControllerUpdatePatientDiary(id: string, patientUpdatePatientDiaryDto: PatientUpdatePatientDiaryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDiary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsPatientDiaryControllerUpdatePatientDiary(id, patientUpdatePatientDiaryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsPatientDiaryApi - factory interface
 * @export
 */
export const PatientsPatientDiaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsPatientDiaryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerClearPatientDiary(options?: any): AxiosPromise<string> {
            return localVarFp.patientsPatientDiaryControllerClearPatientDiary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create instance of diary records (by day) or rewrite it
         * @param {'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7'} day 
         * @param {PatientCreatePatientDiaryDto} patientCreatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerCreatePatientDiary(day: 'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7', patientCreatePatientDiaryDto: PatientCreatePatientDiaryDto, options?: any): AxiosPromise<PatientDiary> {
            return localVarFp.patientsPatientDiaryControllerCreatePatientDiary(day, patientCreatePatientDiaryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerGetPatientDiary(options?: any): AxiosPromise<Array<PatientDiary>> {
            return localVarFp.patientsPatientDiaryControllerGetPatientDiary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatientUpdatePatientDiaryDto} patientUpdatePatientDiaryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsPatientDiaryControllerUpdatePatientDiary(id: string, patientUpdatePatientDiaryDto: PatientUpdatePatientDiaryDto, options?: any): AxiosPromise<PatientDiary> {
            return localVarFp.patientsPatientDiaryControllerUpdatePatientDiary(id, patientUpdatePatientDiaryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsPatientDiaryApi - object-oriented interface
 * @export
 * @class PatientsPatientDiaryApi
 * @extends {BaseAPI}
 */
export class PatientsPatientDiaryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsPatientDiaryApi
     */
    public patientsPatientDiaryControllerClearPatientDiary(options?: AxiosRequestConfig) {
        return PatientsPatientDiaryApiFp(this.configuration).patientsPatientDiaryControllerClearPatientDiary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create instance of diary records (by day) or rewrite it
     * @param {'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7'} day 
     * @param {PatientCreatePatientDiaryDto} patientCreatePatientDiaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsPatientDiaryApi
     */
    public patientsPatientDiaryControllerCreatePatientDiary(day: 'DAY_1' | 'DAY_2' | 'DAY_3' | 'DAY_4' | 'DAY_5' | 'DAY_6' | 'DAY_7', patientCreatePatientDiaryDto: PatientCreatePatientDiaryDto, options?: AxiosRequestConfig) {
        return PatientsPatientDiaryApiFp(this.configuration).patientsPatientDiaryControllerCreatePatientDiary(day, patientCreatePatientDiaryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsPatientDiaryApi
     */
    public patientsPatientDiaryControllerGetPatientDiary(options?: AxiosRequestConfig) {
        return PatientsPatientDiaryApiFp(this.configuration).patientsPatientDiaryControllerGetPatientDiary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatientUpdatePatientDiaryDto} patientUpdatePatientDiaryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsPatientDiaryApi
     */
    public patientsPatientDiaryControllerUpdatePatientDiary(id: string, patientUpdatePatientDiaryDto: PatientUpdatePatientDiaryDto, options?: AxiosRequestConfig) {
        return PatientsPatientDiaryApiFp(this.configuration).patientsPatientDiaryControllerUpdatePatientDiary(id, patientUpdatePatientDiaryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsRecommendationsApi - axios parameter creator
 * @export
 */
export const PatientsRecommendationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate patients recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerCalculateRecommendations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetAllRecommendationCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientFirstTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsRecommendationsControllerGetOnePatientFirstTypeCard', 'id', id)
            const localVarPath = `/patients/recommendations/first-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientSecondTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsRecommendationsControllerGetOnePatientSecondTypeCard', 'id', id)
            const localVarPath = `/patients/recommendations/second-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientThirdTypeCard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patientsRecommendationsControllerGetOnePatientThirdTypeCard', 'id', id)
            const localVarPath = `/patients/recommendations/third-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all second type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetPatientSecondTypeCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/recommendations/second-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all third type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetPatientThirdTypeCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/recommendations/third-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsRecommendationsApi - functional programming interface
 * @export
 */
export const PatientsRecommendationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsRecommendationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate patients recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerCalculateRecommendations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerCalculateRecommendations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetAllRecommendationCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecommendationCardsGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetAllRecommendationCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetOnePatientFirstTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirstTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetOnePatientFirstTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetOnePatientSecondTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetOnePatientSecondTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetOnePatientThirdTypeCard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdTypeCardsPatientGetResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetOnePatientThirdTypeCard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all second type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetPatientSecondTypeCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecondTypeCardsPatientGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetPatientSecondTypeCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all third type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientsRecommendationsControllerGetPatientThirdTypeCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThirdTypeCardsPatientGetResDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientsRecommendationsControllerGetPatientThirdTypeCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsRecommendationsApi - factory interface
 * @export
 */
export const PatientsRecommendationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsRecommendationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate patients recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerCalculateRecommendations(options?: any): AxiosPromise<void> {
            return localVarFp.patientsRecommendationsControllerCalculateRecommendations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all recommendation cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetAllRecommendationCards(options?: any): AxiosPromise<PatientRecommendationCardsGetResDto> {
            return localVarFp.patientsRecommendationsControllerGetAllRecommendationCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one first type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientFirstTypeCard(id: string, options?: any): AxiosPromise<FirstTypeCardsPatientGetResDto> {
            return localVarFp.patientsRecommendationsControllerGetOnePatientFirstTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one second type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientSecondTypeCard(id: string, options?: any): AxiosPromise<SecondTypeCardsPatientGetResDto> {
            return localVarFp.patientsRecommendationsControllerGetOnePatientSecondTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one third type card that belong to the patient
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetOnePatientThirdTypeCard(id: string, options?: any): AxiosPromise<ThirdTypeCardsPatientGetResDto> {
            return localVarFp.patientsRecommendationsControllerGetOnePatientThirdTypeCard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all second type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetPatientSecondTypeCards(options?: any): AxiosPromise<Array<SecondTypeCardsPatientGetResDto>> {
            return localVarFp.patientsRecommendationsControllerGetPatientSecondTypeCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all third type cards that belong to the patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientsRecommendationsControllerGetPatientThirdTypeCards(options?: any): AxiosPromise<Array<ThirdTypeCardsPatientGetResDto>> {
            return localVarFp.patientsRecommendationsControllerGetPatientThirdTypeCards(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsRecommendationsApi - object-oriented interface
 * @export
 * @class PatientsRecommendationsApi
 * @extends {BaseAPI}
 */
export class PatientsRecommendationsApi extends BaseAPI {
    /**
     * 
     * @summary Calculate patients recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerCalculateRecommendations(options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerCalculateRecommendations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all recommendation cards that belong to the patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetAllRecommendationCards(options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetAllRecommendationCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one first type card that belong to the patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetOnePatientFirstTypeCard(id: string, options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetOnePatientFirstTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one second type card that belong to the patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetOnePatientSecondTypeCard(id: string, options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetOnePatientSecondTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one third type card that belong to the patient
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetOnePatientThirdTypeCard(id: string, options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetOnePatientThirdTypeCard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all second type cards that belong to the patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetPatientSecondTypeCards(options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetPatientSecondTypeCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all third type cards that belong to the patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsRecommendationsApi
     */
    public patientsRecommendationsControllerGetPatientThirdTypeCards(options?: AxiosRequestConfig) {
        return PatientsRecommendationsApiFp(this.configuration).patientsRecommendationsControllerGetPatientThirdTypeCards(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsSettingsApi - axios parameter creator
 * @export
 */
export const PatientsSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get patient notifications settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSettingsControllerGetPatientSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient notifications settings
         * @param {PatientUpdateSettingsDto} patientUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSettingsControllerUpdatePatientSettings: async (patientUpdateSettingsDto: PatientUpdateSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientUpdateSettingsDto' is not null or undefined
            assertParamExists('patientSettingsControllerUpdatePatientSettings', 'patientUpdateSettingsDto', patientUpdateSettingsDto)
            const localVarPath = `/patient/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdateSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsSettingsApi - functional programming interface
 * @export
 */
export const PatientsSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get patient notifications settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientSettingsControllerGetPatientSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientSettingsControllerGetPatientSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient notifications settings
         * @param {PatientUpdateSettingsDto} patientUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto: PatientUpdateSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsSettingsApi - factory interface
 * @export
 */
export const PatientsSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get patient notifications settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSettingsControllerGetPatientSettings(options?: any): AxiosPromise<PatientSettings> {
            return localVarFp.patientSettingsControllerGetPatientSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient notifications settings
         * @param {PatientUpdateSettingsDto} patientUpdateSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto: PatientUpdateSettingsDto, options?: any): AxiosPromise<PatientSettings> {
            return localVarFp.patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsSettingsApi - object-oriented interface
 * @export
 * @class PatientsSettingsApi
 * @extends {BaseAPI}
 */
export class PatientsSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get patient notifications settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsSettingsApi
     */
    public patientSettingsControllerGetPatientSettings(options?: AxiosRequestConfig) {
        return PatientsSettingsApiFp(this.configuration).patientSettingsControllerGetPatientSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient notifications settings
     * @param {PatientUpdateSettingsDto} patientUpdateSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsSettingsApi
     */
    public patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto: PatientUpdateSettingsDto, options?: AxiosRequestConfig) {
        return PatientsSettingsApiFp(this.configuration).patientSettingsControllerUpdatePatientSettings(patientUpdateSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromocodesApi - axios parameter creator
 * @export
 */
export const PromocodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UsePromocodeDto} usePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodeControllerUse: async (usePromocodeDto: UsePromocodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usePromocodeDto' is not null or undefined
            assertParamExists('promocodeControllerUse', 'usePromocodeDto', usePromocodeDto)
            const localVarPath = `/promocodes/use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usePromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromocodesApi - functional programming interface
 * @export
 */
export const PromocodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromocodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UsePromocodeDto} usePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promocodeControllerUse(usePromocodeDto: UsePromocodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promocode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promocodeControllerUse(usePromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromocodesApi - factory interface
 * @export
 */
export const PromocodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromocodesApiFp(configuration)
    return {
        /**
         * 
         * @param {UsePromocodeDto} usePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodeControllerUse(usePromocodeDto: UsePromocodeDto, options?: any): AxiosPromise<Promocode> {
            return localVarFp.promocodeControllerUse(usePromocodeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromocodesApi - object-oriented interface
 * @export
 * @class PromocodesApi
 * @extends {BaseAPI}
 */
export class PromocodesApi extends BaseAPI {
    /**
     * 
     * @param {UsePromocodeDto} usePromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromocodesApi
     */
    public promocodeControllerUse(usePromocodeDto: UsePromocodeDto, options?: AxiosRequestConfig) {
        return PromocodesApiFp(this.configuration).promocodeControllerUse(usePromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Expert accept a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerExpertAcceptRequest: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('requestsControllerExpertAcceptRequest', 'patientId', patientId)
            const localVarPath = `/requests/experts/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Expert decline a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerExpertDeclineRequest: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('requestsControllerExpertDeclineRequest', 'patientId', patientId)
            const localVarPath = `/requests/experts/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient cancel a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerPatientCancelRequest: async (expertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('requestsControllerPatientCancelRequest', 'expertId', expertId)
            const localVarPath = `/requests/patients/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient send a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerPatientSendRequest: async (expertId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('requestsControllerPatientSendRequest', 'expertId', expertId)
            const localVarPath = `/requests/patients/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Expert accept a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerExpertAcceptRequest(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerExpertAcceptRequest(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Expert decline a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerExpertDeclineRequest(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerExpertDeclineRequest(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient cancel a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerPatientCancelRequest(expertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerPatientCancelRequest(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patient send a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerPatientSendRequest(expertId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Request>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerPatientSendRequest(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Expert accept a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerExpertAcceptRequest(patientId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.requestsControllerExpertAcceptRequest(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Expert decline a request for cooperation from patient
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerExpertDeclineRequest(patientId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.requestsControllerExpertDeclineRequest(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient cancel a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerPatientCancelRequest(expertId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.requestsControllerPatientCancelRequest(expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient send a request for cooperation to expert
         * @param {string} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerPatientSendRequest(expertId: string, options?: any): AxiosPromise<Request> {
            return localVarFp.requestsControllerPatientSendRequest(expertId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @summary Expert accept a request for cooperation from patient
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerExpertAcceptRequest(patientId: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerExpertAcceptRequest(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Expert decline a request for cooperation from patient
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerExpertDeclineRequest(patientId: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerExpertDeclineRequest(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient cancel a request for cooperation to expert
     * @param {string} expertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerPatientCancelRequest(expertId: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerPatientCancelRequest(expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient send a request for cooperation to expert
     * @param {string} expertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerPatientSendRequest(expertId: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerPatientSendRequest(expertId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add 100 BUCKS subscription to user (for stripe tests)
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerAddTestSub: async (getPaymentUrlDto: GetPaymentUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentUrlDto' is not null or undefined
            assertParamExists('subscriptionsControllerAddTestSub', 'getPaymentUrlDto', getPaymentUrlDto)
            const localVarPath = `/subscriptions/test-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPaymentUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerCancelSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checkout session
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetCheckoutSession: async (getPaymentUrlDto: GetPaymentUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentUrlDto' is not null or undefined
            assertParamExists('subscriptionsControllerGetCheckoutSession', 'getPaymentUrlDto', getPaymentUrlDto)
            const localVarPath = `/subscriptions/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPaymentUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer portal
         * @param {GetCustomerPortalUrlDto} getCustomerPortalUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetCustomerPortal: async (getCustomerPortalUrlDto: GetCustomerPortalUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCustomerPortalUrlDto' is not null or undefined
            assertParamExists('subscriptionsControllerGetCustomerPortal', 'getCustomerPortalUrlDto', getCustomerPortalUrlDto)
            const localVarPath = `/subscriptions/customer-portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCustomerPortalUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve subscriptions constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetSubscriptionsConstants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/subscriptions-constants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renew user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerRenewSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/renew-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerRetrieveSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/retreive-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user subscription
         * @param {UpdateSubscriptionDto} updateSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerUpdateSubscription: async (updateSubscriptionDto: UpdateSubscriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSubscriptionDto' is not null or undefined
            assertParamExists('subscriptionsControllerUpdateSubscription', 'updateSubscriptionDto', updateSubscriptionDto)
            const localVarPath = `/subscriptions/update-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add 100 BUCKS subscription to user (for stripe tests)
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerAddTestSub(getPaymentUrlDto: GetPaymentUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerAddTestSub(getPaymentUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerCancelSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerCancelSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checkout session
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerGetCheckoutSession(getPaymentUrlDto: GetPaymentUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerGetCheckoutSession(getPaymentUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer portal
         * @param {GetCustomerPortalUrlDto} getCustomerPortalUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto: GetCustomerPortalUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve subscriptions constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerGetSubscriptionsConstants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsConstantsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerGetSubscriptionsConstants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renew user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerRenewSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerRenewSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerRetrieveSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerRetrieveSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user subscription
         * @param {UpdateSubscriptionDto} updateSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsControllerUpdateSubscription(updateSubscriptionDto: UpdateSubscriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsControllerUpdateSubscription(updateSubscriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add 100 BUCKS subscription to user (for stripe tests)
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerAddTestSub(getPaymentUrlDto: GetPaymentUrlDto, options?: any): AxiosPromise<RedirectUrlDto> {
            return localVarFp.subscriptionsControllerAddTestSub(getPaymentUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerCancelSubscription(options?: any): AxiosPromise<SubscriptionDto> {
            return localVarFp.subscriptionsControllerCancelSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checkout session
         * @param {GetPaymentUrlDto} getPaymentUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetCheckoutSession(getPaymentUrlDto: GetPaymentUrlDto, options?: any): AxiosPromise<RedirectUrlDto> {
            return localVarFp.subscriptionsControllerGetCheckoutSession(getPaymentUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer portal
         * @param {GetCustomerPortalUrlDto} getCustomerPortalUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto: GetCustomerPortalUrlDto, options?: any): AxiosPromise<RedirectUrlDto> {
            return localVarFp.subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve subscriptions constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerGetSubscriptionsConstants(options?: any): AxiosPromise<SubscriptionsConstantsResponseDto> {
            return localVarFp.subscriptionsControllerGetSubscriptionsConstants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renew user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerRenewSubscription(options?: any): AxiosPromise<SubscriptionDto> {
            return localVarFp.subscriptionsControllerRenewSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current user subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerRetrieveSubscription(options?: any): AxiosPromise<SubscriptionDto> {
            return localVarFp.subscriptionsControllerRetrieveSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user subscription
         * @param {UpdateSubscriptionDto} updateSubscriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsControllerUpdateSubscription(updateSubscriptionDto: UpdateSubscriptionDto, options?: any): AxiosPromise<SubscriptionDto> {
            return localVarFp.subscriptionsControllerUpdateSubscription(updateSubscriptionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Add 100 BUCKS subscription to user (for stripe tests)
     * @param {GetPaymentUrlDto} getPaymentUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerAddTestSub(getPaymentUrlDto: GetPaymentUrlDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerAddTestSub(getPaymentUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel current user subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerCancelSubscription(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerCancelSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checkout session
     * @param {GetPaymentUrlDto} getPaymentUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerGetCheckoutSession(getPaymentUrlDto: GetPaymentUrlDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerGetCheckoutSession(getPaymentUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer portal
     * @param {GetCustomerPortalUrlDto} getCustomerPortalUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto: GetCustomerPortalUrlDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerGetCustomerPortal(getCustomerPortalUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve subscriptions constants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerGetSubscriptionsConstants(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerGetSubscriptionsConstants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renew user subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerRenewSubscription(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerRenewSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current user subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerRetrieveSubscription(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerRetrieveSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user subscription
     * @param {UpdateSubscriptionDto} updateSubscriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsControllerUpdateSubscription(updateSubscriptionDto: UpdateSubscriptionDto, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).subscriptionsControllerUpdateSubscription(updateSubscriptionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitsApi - axios parameter creator
 * @export
 */
export const UnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get coefficients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsControllerUnitsCoefficients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/coefficients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitsApi - functional programming interface
 * @export
 */
export const UnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get coefficients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitsControllerUnitsCoefficients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitsOptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitsControllerUnitsCoefficients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitsApi - factory interface
 * @export
 */
export const UnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get coefficients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsControllerUnitsCoefficients(options?: any): AxiosPromise<Array<UnitsOptionDto>> {
            return localVarFp.unitsControllerUnitsCoefficients(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitsApi - object-oriented interface
 * @export
 * @class UnitsApi
 * @extends {BaseAPI}
 */
export class UnitsApi extends BaseAPI {
    /**
     * 
     * @summary Get coefficients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public unitsControllerUnitsCoefficients(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).unitsControllerUnitsCoefficients(options).then((request) => request(this.axios, this.basePath));
    }
}


