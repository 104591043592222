import { Crown, Vector } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PatientRoutes } from 'types/routes';

import styles from './styles.module.scss';

type SubscribeBannerProps = {
  isAnalyzesHistory?: boolean;
};

export const SubscribeBanner: FC<SubscribeBannerProps> = ({ isAnalyzesHistory = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'newAnalysis' });

  const bannerText = isAnalyzesHistory ? t('isAnalyzesHistory') : t('isDemoMode');

  return (
    <Link to={PatientRoutes.SUBSCRIPTIONS}>
      <div className={styles.banner__container}>
        <div className={styles.banner__inner}>
          <div className={styles.banner__icon}>
            <Crown />
          </div>
          <p className={styles.banner__content}>{bannerText}</p>
        </div>
        <div className={styles.banner__button}>
          <Vector />
        </div>
      </div>
    </Link>
  );
};
