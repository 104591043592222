import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../../styles.module.scss';

type AsideHeaderProps = {
  header: string;
  hasLink: boolean;
  link?: string;
  isRequestsActive?: boolean;
};

export const AsideHeader: FC<AsideHeaderProps> = ({ header, hasLink, link, isRequestsActive = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  return (
    <div className={styles['aside-header']}>
      <h4>{header}</h4>
      {hasLink && (
        <Link
          className={styles['aside-show-all']}
          to={{
            pathname: link,
            state: { isRequestsActive: isRequestsActive },
          }}>
          {t('all')}
        </Link>
      )}
    </div>
  );
};
