import { Check } from 'assets/svg';
import classnames from 'classnames';
import { CSSProperties, FC, FocusEvent, KeyboardEvent, useCallback } from 'react';

import styles from './styles.module.scss';

interface CheckboxProps {
  checked: boolean;
  onChange: (e: boolean) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  error?: boolean;
  labelText?: string;
  customStyles?: CSSProperties;
}

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  onBlur,
  isDisabled,
  error,
  labelText,
  customStyles,
}) => {
  const handleCheck = useCallback(() => {
    if (isDisabled) return;
    return onChange(!checked);
  }, [checked, isDisabled, onChange]);

  const handleSpace = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === ' ') {
        handleCheck();
      }
    },
    [handleCheck],
  );

  return (
    <div
      className={styles['checkbox-wrapper']}
      style={customStyles}
      onClick={handleCheck}
      onKeyDown={handleSpace}
      onBlur={onBlur}
      tabIndex={0}
      role="checkbox"
      aria-checked={checked}>
      <input type="checkbox" checked={checked} onBlur={onBlur} tabIndex={-1} />
      <div
        className={classnames(styles.checkbox, {
          [styles['checkbox--error']]: error,
          [styles['checkbox--checked']]: checked,
          [styles['checkbox--disabled']]: isDisabled,
        })}>
        {checked && <Check viewBox="0 0 24 24" width={16} height={16} pathFill="var(--white)" />}
      </div>
      {labelText && <p className={styles.checkbox__label}>{labelText}</p>}
    </div>
  );
};
