import { BaseModal } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ButtonDefault } from 'UIcomponents/ButtonDefault';

import styles from './ModalDowngradeSubscription.module.scss';

type ModalDowngradeSubscription = {
  isOpened: boolean;
  onClose: () => void;
};

export const ModalDowngradeSubscription: FC<ModalDowngradeSubscription> = ({ isOpened, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions.modalDowngradeSubscription' });

  const history = useHistory();

  const handlePatientClick = () => {
    history.push('/');
  };

  return (
    <BaseModal customStyles={{ maxWidth: 722 }} isVisible={isOpened} onClose={onClose}>
      <p className={styles.heading}>{t('title')}</p>
      <p className={styles.content}>{t('content')}</p>
      <ButtonDefault onClick={handlePatientClick} variant="secondary">
        {t('button')}
      </ButtonDefault>
    </BaseModal>
  );
};
