import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParsePdfOrImageResDto, ParsePdfOrImageResDtoV2, SendMessageToBiogeekSupportDto } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from './types';

export const sendContactFormExpertAsync = createAsyncThunk<
  void,
  SendMessageToBiogeekSupportDto,
  ExtraParamsThunkType<SerializedError>
>('default/sendContactFormInfo', async (dto: SendMessageToBiogeekSupportDto, { extra: { api }, rejectWithValue }) => {
  try {
    await api.ContactFormApi.contactFormControllerSendMessageFromExpertContactForm(dto);
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sendContactFormPatientAsync = createAsyncThunk<
  void,
  SendMessageToBiogeekSupportDto,
  ExtraParamsThunkType<SerializedError>
>('default/sendContactFormInfo', async (dto: SendMessageToBiogeekSupportDto, { extra: { api }, rejectWithValue }) => {
  try {
    await api.ContactFormApi.contactFormControllerSendMessageFromPatientContactForm(dto);
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const parsePdfAsync = createAsyncThunk<ParsePdfOrImageResDto[], File, ExtraParamsThunkType<SerializedError>>(
  'default/parsePdf',
  async (file: File, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.AnalyzesParsingApi.patientAnalysisParserControllerParseAnalisisFromPdf(file, {
        params: { l: language },
      });
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const parsePdfOpenaiAsync = createAsyncThunk<
  ParsePdfOrImageResDtoV2[],
  File,
  ExtraParamsThunkType<SerializedError>
>('/analyzes-openai/parse/pdf', async (file: File, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientAnalyzesOpenAiApi.patientAnalysisOpenaiControllerParseAnalysisFromPdf(file, {
      params: { l: language },
    });
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const parsePdfExpertAsync = createAsyncThunk<
  ParsePdfOrImageResDto[],
  { id: string; file: File },
  ExtraParamsThunkType<SerializedError>
>('default/parseExpertPdf', async ({ id, file }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.AnalyzesParsingApi.expertAnalysisParserControllerParseAnalisisFromPdf(id, file, {
      params: { l: language },
    });
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const parseImageAsync = createAsyncThunk<ParsePdfOrImageResDto[], File, ExtraParamsThunkType<SerializedError>>(
  'default/parseImage',
  async (file: File, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.AnalyzesParsingApi.patientAnalysisParserControllerParseAnalisisFromImage(file, {
        params: { l: language },
      });
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const parseImageExpertAsync = createAsyncThunk<
  ParsePdfOrImageResDto[],
  { id: string; file: File },
  ExtraParamsThunkType<SerializedError>
>('default/parseExpertImage', async ({ id, file }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.AnalyzesParsingApi.expertAnalysisParserControllerParseAnalisisFromImage(id, file, {
      params: { l: language },
    });
    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
