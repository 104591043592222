import { unwrapResult } from '@reduxjs/toolkit';
import { ExcludeProducts, GetPatientNutritonPlanResDto, IncludeProducts } from 'api/generated';
import { Check, Pencil } from 'assets/svg';
import PlusIcon from 'assets/svg/PlusIcon';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectNutritionPlanStatus, selectPatientId } from 'features/expert/selectors';
import {
  getNutritionsPlansByIdAsync,
  prescribeNutritionsPlansAsync,
  updateNutritionsPlansByIdAsync,
} from 'features/expert/thunks';
import { nutritionPlanSeenAsync } from 'features/patient/thunks';
import useWindowWidth from 'hooks/useWindowWidth';
import { isEmpty } from 'lodash';
import { Goal } from 'pages/PatientProfile/components/Goal';
import { Diet } from 'pages/UserProfile/components';
import { FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { ButtonDefault, Spinner } from 'UIcomponents';

import { ExpectationsBlock } from '../ExpectationsBlock/ExpectationsBlock';
import { PlanHeader } from '../PlanHeader';
import TabsMini from '../TabsMini/TabsMini';
import styles from './styles.module.scss';

interface PlanLocationState {
  editable: boolean;
  nutritionPlanId: string;
}

export interface IncludeProductsForm {
  vegetables: string;
  fruits: string;
  animalProtein: string;
  vegetableProtein: string;
  nutsAndSeeds: string;
  oils: string;
  cereals: string;
  milkProducts: string;
  drinks: string;
  nutritionalSupplements: string;
  sweets: string;
  sweeteners: string;
  condiments: string;
}

export interface NutritionPlanFormValues {
  goals: Array<{ title: string }>;
  includeProducts: IncludeProductsForm;
  excludeProducts: ExcludeProducts;
  title: string;
  additionalInfo: string;
  description: string;
}

interface NutritionPlanProps {
  readonly?: boolean;
  nutritionPlan?: GetPatientNutritonPlanResDto;
}

export const NutritionPlanItem: FC<NutritionPlanProps> = ({ readonly, nutritionPlan }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.patientNutritionPlansTab' });

  const productNamesMapping: Record<keyof ExcludeProducts, string> = {
    oils: t('productNames.oils'),
    vegetables: t('productNames.vegetables'),
    fruits: t('productNames.fruits'),
    animalProtein: t('productNames.animalProtein'),
    vegetableProtein: t('productNames.vegetableProtein'),
    nutsAndSeeds: t('productNames.nutsAndSeeds'),
    cereals: t('productNames.cereals'),
    milkProducts: t('productNames.milkProducts'),
    drinks: t('productNames.drinks'),
    nutritionalSupplements: t('productNames.nutritionalSupplements'),
    sweets: t('productNames.sweets'),
    condiments: t('productNames.condiments'),
    sweeteners: t('productNames.sweeteners'),
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [active, setActive] = useState(true);
  const [activePlan, setActivePlan] = useState<GetPatientNutritonPlanResDto>(
    nutritionPlan || ({} as GetPatientNutritonPlanResDto),
  );

  const dispatch = useAppDispatch();
  const location = useLocation<PlanLocationState>();
  const state = location.state;

  const role = useAppSelector(selectRole);
  const patientId = useAppSelector(selectPatientId);
  const loadingStatus = useAppSelector(selectNutritionPlanStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;

  const width = useWindowWidth();

  const newGoals = activePlan?.goals?.map((goal) => {
    return { title: goal };
  });

  const toggleTabs = () => {
    setActive((prev) => !prev);
  };

  const toggleDisable = () => {
    setIsEditMode((prev) => !prev);
  };

  const cancelEdit = () => {
    reset();
    toggleDisable();
  };

  const onSubmit = (value: NutritionPlanFormValues) => {
    if (isEditMode && isDirty) {
      changeNutritionPlan(value);
      toggleDisable();
    } else {
      toggleDisable();
    }
  };

  const {
    control,
    // setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, errors },
  } = useForm<NutritionPlanFormValues>({
    defaultValues: {
      goals: newGoals,
      additionalInfo: activePlan.additionalInfo,
      title: activePlan.title,
      description: activePlan.description,
    },
  });

  useEffect(() => {
    switch (role) {
      case RolesEnum.EXPERT: {
        dispatch(getNutritionsPlansByIdAsync(location?.state?.nutritionPlanId))
          .then(unwrapResult)
          .then((res) => {
            const newGoals = res?.goals?.map((goal) => {
              return { title: goal };
            });

            reset({ ...res, goals: newGoals }, { keepDirty: false });
            setActivePlan(res);
          });
        break;
      }
      case RolesEnum.PATIENT: {
        if (activePlan?.isNewlyPrescribed) {
          dispatch(nutritionPlanSeenAsync(activePlan.id));
        }
      }
    }
  }, [role]);

  const values = getValues();

  const { fields, append, remove } = useFieldArray({
    name: 'goals',
    control,
  });

  const createNutritionPlan = () => {
    dispatch(
      prescribeNutritionsPlansAsync({
        patientId: patientId,
        patientNutritionPlanId: state?.nutritionPlanId,
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        setActivePlan((prevState) => ({ ...prevState, ...res }));
        notify('success', t('nutritionPlanPrescribed'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const changeNutritionPlan = (formValues: NutritionPlanFormValues) => {
    const arrayGoals = formValues.goals.map((goal) => {
      return goal.title;
    });

    dispatch(
      updateNutritionsPlansByIdAsync({
        nutritionPlanId: state?.nutritionPlanId,
        plan: {
          ...state,
          ...formValues,
          patientId: patientId,
          patientNutritionPlanId: state?.nutritionPlanId,
          goals: arrayGoals,
        },
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        const mappedGoals = res.goals.map((goal) => {
          return { title: goal };
        });
        reset({ ...res, goals: mappedGoals, title: res.title }, { keepDirty: false });
        setActivePlan(res);
        notify('success', t('nutritionPlanChanged'));
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  if (isEmpty(activePlan)) {
    return (
      <div className={styles['plan']}>
        <div className={styles['plan__waiting']}>
          <Spinner radius={20} color="var(--background-primary)" />
        </div>
      </div>
    );
  }

  return (
    <>
      {Object.keys(activePlan).length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles['plan']}>
          <div className={styles.plan__header}>
            <h4 className={styles.plan__title}>{t('nutritionPlans')}</h4>
            {!readonly && (
              <div className={styles.plan__button}>
                <div className={styles.plan__button_change}>
                  <ButtonDefault
                    customStyles={{
                      padding: 0,
                      minWidth: 52,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    variant="square">
                    {isEditMode ? <Check /> : <Pencil />}
                  </ButtonDefault>
                </div>
                {isEditMode ? (
                  <ButtonDefault
                    customStyles={{ width: 280 }}
                    variant="secondary"
                    text={t('cancelEdit')}
                    onClick={cancelEdit}
                  />
                ) : (
                  <ButtonDefault
                    customStyles={{ width: 280 }}
                    isDisabled={activePlan.isActive}
                    text={activePlan.isActive ? t('nutritionPlansAssigned') : t('nutritionPlansAssign')}
                    onClick={createNutritionPlan}
                  />
                )}
              </div>
            )}
          </div>

          <PlanHeader text={values?.description} title={values?.title} isEditMode={isEditMode} control={control} />
          {!readonly && (
            <div className={styles['plan__button_mobile']}>
              {isEditMode ? (
                <ButtonDefault
                  customStyles={{ minWidth: '230px' }}
                  text={t('cancelEdit')}
                  variant="secondary"
                  isLoading={isLoading}
                  type={'button'}
                  onClick={cancelEdit}
                />
              ) : (
                <ButtonDefault
                  customStyles={{ minWidth: '230px' }}
                  text={activePlan.isActive ? t('nutritionPlansAssigned') : t('nutritionPlansAssign')}
                  isDisabled={activePlan.isActive}
                  isLoading={isLoading}
                  type={'button'}
                  onClick={createNutritionPlan}
                />
              )}
              <ButtonDefault
                customStyles={{
                  width: '52px ',
                  minWidth: '52px',
                  padding: 0,
                  marginLeft: '20px',
                }}
                variant="square"
                onClick={handleSubmit(onSubmit)}>
                {isEditMode ? <Check /> : <Pencil />}
              </ButtonDefault>
            </div>
          )}
          <div>
            <h6 className={styles['goals__title']}>{t('dailyGoals')}</h6>
            <div>
              <div className={styles.goals}>
                {activePlan?.goals &&
                  fields.map((goal, index) => (
                    <>
                      <Controller
                        key={goal.id}
                        name={`goals.${index}.title`}
                        rules={{
                          required: t('required') as string,
                        }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Goal
                            key={goal.id}
                            value={value}
                            onChange={onChange}
                            disabled={!isEditMode}
                            remove={remove}
                            index={index}
                            error={errors?.goals?.length && errors?.goals[index] ? 'error' : undefined}
                          />
                        )}
                      />
                    </>
                  ))}
              </div>
              {isEditMode && (
                <ButtonDefault
                  customStyles={{
                    width: '52px ',
                    minWidth: '52px',
                    marginBottom: '20px',
                    padding: 0,
                  }}
                  onClick={() => append({ title: '' })}
                  variant={'secondary'}>
                  <PlusIcon fill={'black'} />
                </ButtonDefault>
              )}
            </div>
          </div>
          <div className={styles.restrictions}>
            <div className={styles.restrictions__container}>
              <div className={styles.restrictions__on}>
                <h6 className={styles['restrictions__on-text']}>{t('restrictionsOn')}</h6>
              </div>
              <div className={styles.restrictions__off}>
                <h6 className={styles['restrictions__off-text']}>{t('restrictionsOff')}</h6>
              </div>
            </div>
            {width <= 1210 && (
              <TabsMini
                leftTitle={t('restrictionsOn')}
                rightTitle={t('restrictionsOff')}
                active={active}
                toggleTabs={toggleTabs}
              />
            )}
            <div className={styles.diet}>
              <div className={styles.diet__left}>
                {width > 1210 ? (
                  <>
                    <div>
                      {Object.keys(activePlan?.includeProducts)?.map((item: string, index) => {
                        if (!activePlan.includeProducts[item as keyof IncludeProducts] && !isEditMode) return;
                        return (
                          <div key={index} className={styles['diet__block']}>
                            <p className={classnames(styles.subhead, styles['diet__title'])}>
                              {productNamesMapping[item as keyof IncludeProducts]}
                            </p>
                            <Controller
                              key={index}
                              name={`includeProducts.${item as keyof IncludeProductsForm}`}
                              defaultValue={activePlan.includeProducts[item as keyof IncludeProducts]}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Diet key={index} value={value} onChange={onChange} disabled={!isEditMode} />
                              )}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  active && (
                    <div className={styles.diet__left}>
                      {!isEmpty(activePlan) &&
                        Object.keys(activePlan?.includeProducts) &&
                        Object.keys(activePlan?.includeProducts)?.map((item, index) => {
                          if (!activePlan.includeProducts[item as keyof IncludeProducts] && !isEditMode) return;
                          return (
                            <div key={index} className={styles['diet__block']}>
                              <p className={classnames(styles.subhead, styles['diet__title'])}>
                                {productNamesMapping[item as keyof IncludeProducts]}
                              </p>
                              <Controller
                                key={index}
                                name={`includeProducts.${item as keyof IncludeProductsForm}`}
                                defaultValue={activePlan.includeProducts[item as keyof IncludeProducts]}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Diet key={index} value={value} onChange={onChange} disabled={!isEditMode} />
                                )}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )
                )}
              </div>
              <div className={styles.diet__right}>
                {width > 1210 ? (
                  <div>
                    {Object.keys(activePlan?.excludeProducts) &&
                      Object.keys(activePlan?.excludeProducts).map((item, index) => {
                        if (!activePlan.excludeProducts[item as keyof ExcludeProducts] && !isEditMode) return;
                        return (
                          <div key={index} className={styles['diet__block']}>
                            <p className={classnames(styles.subhead, styles['diet__title'])}>
                              {productNamesMapping[item as keyof ExcludeProducts]}
                            </p>
                            <Controller
                              name={`excludeProducts.${item as keyof ExcludeProducts}`}
                              defaultValue={activePlan.excludeProducts[item as keyof ExcludeProducts]}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Diet key={index} value={value} onChange={onChange} disabled={!isEditMode} />
                              )}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  !active && (
                    <div>
                      {Object.keys(activePlan?.excludeProducts) &&
                        Object.keys(activePlan?.excludeProducts).map((item, index) => {
                          if (!activePlan.excludeProducts[item as keyof ExcludeProducts] && !isEditMode) return;
                          return (
                            <div key={index} className={styles['diet__block']}>
                              <p className={classnames(styles.subhead, styles['diet__title'])}>
                                {productNamesMapping[item as keyof ExcludeProducts]}
                              </p>
                              <Controller
                                name={`excludeProducts.${item as keyof ExcludeProducts}`}
                                defaultValue={activePlan.excludeProducts[item as keyof ExcludeProducts]}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Diet key={index} value={value} onChange={onChange} disabled={!isEditMode} />
                                )}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          {activePlan.additionalInfo && (
            <ExpectationsBlock text={values.additionalInfo} control={control} isEditMode={isEditMode} />
          )}
        </form>
      )}
    </>
  );
};
