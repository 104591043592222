import {
  ExpertCreateEventDto,
  ExpertCreateEventDtoEventTypeEnum,
  ExpertEditEventDtoEventTypeEnum,
  ExpertGetPatientsResDto,
} from 'api/generated';
import classnames from 'classnames';
import { BaseModal } from 'components';
import { selectCalendarDeleteEventsStatus, selectEventsStatus, selectExpertPatients } from 'features/expert/selectors';
import { changeEventAsync, createEventAsync, deleteEventAsync } from 'features/expert/thunks';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import { LoadingStatus } from 'types';
import { timeFormatChars, timeMask } from 'types/Input';
import { ButtonDefault, DropdownDefault, FormError, FormLabel, MaskedInput, NumberMaskedInput } from 'UIcomponents';
import { patterns } from 'utils/patterns';

import styles from './styles.module.scss';

interface NewEventModalProps {
  onClose: () => void;
  isChange: boolean;
  start?: string;
  end?: string;
  begining?: number;
  timeZone?: string;
  patient?: string;
  event?: ExpertCreateEventDtoEventTypeEnum;
  cost?: string;
  id: string;
}

const createOptionsForDropdown = (patients: ExpertGetPatientsResDto) => {
  const options = patients.data.map((patient) => {
    return { label: patient.name, value: patient.id };
  });
  return options;
};

interface FormProps extends Omit<ExpertCreateEventDto, 'duration'> {
  duration: string;
}

export const NewEventModal: FC<NewEventModalProps> = ({
  onClose,
  isChange,
  start,
  id,
  cost,
  timeZone,
  patient,
  event,
  begining,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'calendar' });

  const patients = useSelector(selectExpertPatients);
  const dispatch = useAppDispatch();
  const loadingStatusDeleteEvent = useSelector(selectCalendarDeleteEventsStatus);
  const loadingStatus = useSelector(selectEventsStatus);

  const eventOptions = [
    { label: t('meeting'), value: ExpertCreateEventDtoEventTypeEnum.Meeting },
    { label: t('videocall'), value: ExpertCreateEventDtoEventTypeEnum.Videocall },
    { label: t('call'), value: ExpertCreateEventDtoEventTypeEnum.Call },
  ];

  const timeZonesOptions = [
    { label: `(GMT+12) ${t('kamchatka')}`, value: 'GMT+12' },
    { label: `(GMT+11) ${t('sakhalin')} `, value: 'GMT+11' },
    { label: `(GMT+10) ${t('magadan')} `, value: 'GMT+10' },
    { label: `(GMT+09) ${t('yakutsk')} `, value: 'GMT+09' },
    { label: `(GMT+08) ${t('irkutsk')} `, value: 'GMT+08' },
    { label: `(GMT+07) ${t('krasnoyarsk')}`, value: 'GMT+07' },
    { label: `(GMT+06) ${t('omsk')} `, value: 'GMT+06' },
    { label: `(GMT+05) ${t('ekaterinburg')} `, value: 'GMT+05' },
    { label: `(GMT+04) ${t('samara')}`, value: 'GMT+04' },
    { label: `(GMT+03) ${t('moscow')}`, value: 'GMT+03' },
    { label: `(GMT+02) ${t('kaliningrad')}`, value: 'GMT+02' },
    { label: `(GMT+01) ${t('paris')} `, value: 'GMT+01' },
    { label: `(GMT+00) ${t('london')}`, value: 'GMT+00' },
    { label: `(GMT-02) ${t('midAtlantic')} `, value: 'GMT-02' },
    { label: `(GMT-03) ${t('buenosAires')}`, value: 'GMT-03' },
    { label: `(GMT-04) ${t('atlantic')}`, value: 'GMT-04' },
    { label: `(GMT-05) ${t('newYork')}`, value: 'GMT-05' },
    { label: `(GMT-06) ${t('chicago')}`, value: 'GMT-06' },
    { label: `(GMT-07) ${t('denver')}`, value: 'GMT-07' },
    { label: `(GMT-08) ${t('losAngeles')}`, value: 'GMT-08' },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormProps>();

  const setHoursInDate = (time: string) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2, 4);
    return start && moment(new Date(start).setHours(Number(hours), Number(minutes))).toISOString();
  };

  const onSubmit = (data: ExpertCreateEventDto) => {
    const parsedDuration = data.duration.toString().split(':');
    const duration = Number(parsedDuration[0]) * 3600 + Number(parsedDuration[1]) * 60;
    const beginning = String(
      setHoursInDate(data.beginning === '00:00' ? '0' : String(data.beginning).replace(/[:]/g, '')),
    );

    if (isChange) {
      dispatch(
        createEventAsync({
          patientId: data.patientId,
          eventType: data.eventType,
          timezone: data.timezone,
          beginning: beginning,
          duration: Number(duration),
          cost: data.cost,
        }),
      )
        .then(() => {
          onClose();
        })
        .catch((e) => console.log(e));
    } else {
      dispatch(
        changeEventAsync({
          expertEditEventDto: {
            eventType: data.eventType as unknown as ExpertEditEventDtoEventTypeEnum,
            timezone: data.timezone,
            beginning: beginning,
            duration: Number(duration),
            cost: data.cost,
            patientId: data.patientId,
          },
          id: id,
        }),
      )
        .then(() => {
          onClose();
        })
        .catch((e) => console.log(e));
    }
  };

  const mapArrayOptions = (options: Array<{ label: string; value: string }>, value?: string, isValue?: boolean) => {
    let defaultValue;
    if (isValue) {
      defaultValue = options.filter((elem) => elem.value === value);
    } else {
      defaultValue = options.filter((elem) => elem.label === value);
    }
    return defaultValue[0];
  };

  const deleteEvent = () => {
    dispatch(deleteEventAsync(id))
      .then(() => {
        onClose();
      })
      .catch((e) => console.log(e));
  };

  const beginingTime = useMemo(() => {
    if (begining) {
      const hours = Math.floor(begining / 60 / 60);
      const minutes = Math.floor(begining / 60) - hours * 60;
      const formatedHours = hours > 9 ? hours : '0' + hours.toString();
      const formatedMinutes = minutes > 9 ? minutes : '0' + minutes.toString();
      return `${formatedHours}:${formatedMinutes}`;
    }
    return '00:00';
  }, [begining]);

  const patientsOptions = createOptionsForDropdown(patients);

  return (
    <BaseModal
      title={!isChange ? (t('changeEvent') as string) : (t('newEvent') as string)}
      onClose={onClose}
      isVisible={true}
      customStyles={{ maxWidth: 852 }}>
      <div className={styles['form__container']}>
        <div className={styles['form__fields']}>
          <div className={styles['form__field']}>
            <FormLabel text={t('patient')} />
            <Controller
              name="patientId"
              control={control}
              defaultValue={patient && patient}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <DropdownDefault
                    options={patientsOptions}
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    isSearchable={false}
                    error={errors.patientId && 'error'}
                    className={styles['dropdown__control']}
                    selected={patientsOptions.find((option) => option.value === value)}
                    variant={'max-content'}
                  />
                );
              }}
            />
            {errors.patientId?.message && <FormError type="error" text={errors.patientId.message} />}
          </div>
          <div className={styles['form__field']}>
            <FormLabel text={t('eventType')} />
            <Controller
              name="eventType"
              control={control}
              rules={{
                required: t('required') as string,
              }}
              defaultValue={event}
              render={({ field: { onChange, value } }) => (
                <DropdownDefault
                  options={eventOptions}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  isSearchable={false}
                  error={errors.eventType && 'error'}
                  className={styles['dropdown__control']}
                  selected={mapArrayOptions(eventOptions, value, true)}
                  variant={'max-content'}
                />
              )}
            />
            {errors.eventType?.message && <FormError type="error" text={errors.eventType.message} />}
          </div>
        </div>
        <div className={styles['form__fields']}>
          <div className={styles['form__field']}>
            <FormLabel text={t('timezone')} />
            <Controller
              name="timezone"
              control={control}
              defaultValue={timeZone ? timeZone : timeZonesOptions[0].value}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { onChange, value } }) => (
                <DropdownDefault
                  options={timeZonesOptions}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  isSearchable={false}
                  error={errors.timezone && 'error'}
                  className={styles['dropdown__control']}
                  selected={mapArrayOptions(timeZonesOptions, value, true)}
                  variant={'max-content'}
                />
              )}
            />
            {errors.timezone?.message && <FormError type="error" text={errors.timezone.message} />}
          </div>
          <div className={styles['form__field']}>
            <FormLabel text={t('startTime')} />
            <Controller
              name="beginning"
              control={control}
              defaultValue={moment(start).format('HH:mm') === '00:00' ? '00:00' : moment(start).format('HH:mm')}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.time,
                  message: t('patternTime'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <MaskedInput
                  onChange={onChange}
                  mask={timeMask(value)}
                  placeholder={'00:00'}
                  value={value}
                  error={errors.beginning && 'error'}
                  formatChars={timeFormatChars}
                />
              )}
            />
            {errors.beginning?.message && <FormError type="error" text={errors.beginning.message} />}
          </div>
        </div>
        <div className={styles['form__fields']}>
          <div className={styles['form__field']}>
            <FormLabel text={t('duration')} />
            <Controller
              name="duration"
              defaultValue={beginingTime}
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: patterns.time,
                  message: t('patternTime'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <MaskedInput
                  onChange={onChange}
                  mask={timeMask(value)}
                  placeholder={'00:00'}
                  value={value}
                  error={errors.duration && 'error'}
                  formatChars={timeFormatChars}
                />
              )}
            />
            {errors.duration?.message && <FormError type="error" text={errors.duration.message} />}
          </div>
          <div className={styles['form__field']}>
            <FormLabel text={t('cost')} />
            <Controller
              name="cost"
              control={control}
              defaultValue={Number(cost)}
              rules={{
                required: t('required') as string,
                validate: (value) => {
                  if (!value) {
                    return t('typeCost') as string;
                  }
                  if (value < 1) {
                    return t('validateCost') as string;
                  }
                },
              }}
              render={({ field: { onChange, value } }) => (
                <NumberMaskedInput
                  onChange={onChange}
                  placeholder={'0'}
                  maxLength={10}
                  value={value}
                  error={errors.cost && 'error'}
                />
              )}
            />
            {errors.cost?.message && <FormError type="error" text={errors.cost.message} />}
          </div>
        </div>
      </div>
      <div className={styles.button}>
        {isChange ? (
          <ButtonDefault
            text={t('save')}
            isLoading={loadingStatus === LoadingStatus.pending}
            onClick={handleSubmit(onSubmit)}
            containerClassNames={classnames(styles['form__button'], styles.save_button)}
          />
        ) : (
          <div className={styles.form__button__container}>
            <ButtonDefault
              type="submit"
              isLoading={loadingStatus === LoadingStatus.pending}
              isDisabled={!isDirty || loadingStatus === LoadingStatus.pending}
              onClick={handleSubmit(onSubmit)}
              text={t('change')}
              customStyles={{ minWidth: '100px', width: '100%', maxWidth: '200px', marginRight: '10px' }}
              containerClassNames={styles['form__button']}
            />
            <ButtonDefault
              onClick={deleteEvent}
              isLoading={loadingStatusDeleteEvent === LoadingStatus.pending}
              variant={'delete'}
              customStyles={{ minWidth: '100px', width: '100%', maxWidth: '200px' }}
              text={t('delete')}
              containerClassNames={styles['form__button']}
            />
          </div>
        )}
      </div>
    </BaseModal>
  );
};
