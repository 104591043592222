export enum UserGender {
  male = 'Мужской',
  female = 'Женский',
}

export enum LastLoginedType {
  PATIENT = 'PATIENT',
  EXPERT = 'EXPERT',
}

export const DEFAULT_AVATAR = 'https://storage.yandexcloud.net/grecha-pro/default-avatar.png';
