import { SubscriptionExperts } from 'components';
import { selectActivePromocode } from 'features/promocodes/selectors';
import { useToggle } from 'hooks';
import { MainLayout } from 'layouts';
import { TabsMini } from 'pages/PatientProfile/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';
import { SuccessModal } from 'UIcomponents/SuccessModal';

import { EnterPromocodeModal } from '../NewSubscription/components';
import styles from './styles.module.scss';

export const NewSubscriptionExperts: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' });

  const history = useHistory();

  const { isOpened: isActiveYearSubscriptionsTab, onToggle: toggleTabs } = useToggle(true);
  const { isOpened: isPromoCodeModalOpened, onToggle: togglePromoCodeModal } = useToggle();
  const { isOpened: isSuccessModalOpened, onToggle: toggleSuccessModal } = useToggle();

  const activePromocode = useAppSelector(selectActivePromocode);
  const hasActivePromocode = !!activePromocode;

  return (
    <MainLayout>
      <div className={styles['subscriptions-container']}>
        <div className={styles['subscriptions-container__header']}>
          <h2 className={styles['subscriptions-container__title']}>{t('selectSubscription')}</h2>
          {hasActivePromocode ? (
            <div className={styles.active_promocode_container}>
              <span className={styles.active_promocode}>
                {t('activatedPromocode')} {activePromocode.name}
              </span>
            </div>
          ) : (
            <ButtonDefault
              containerClassNames={styles['subscriptions-container__button']}
              text={t('inputPromo')}
              variant="secondary"
              onClick={togglePromoCodeModal}
            />
          )}
        </div>
        <TabsMini
          leftTitle={t('expertTabsYear')}
          rightTitle={t('expertTabsMonth')}
          active={isActiveYearSubscriptionsTab}
          toggleTabs={toggleTabs}
        />
        <SubscriptionExperts isActiveYearSubscriptionsTab={isActiveYearSubscriptionsTab} />
      </div>

      <EnterPromocodeModal
        isModalOpened={isPromoCodeModalOpened}
        onCloseModal={togglePromoCodeModal}
        openSuccessModal={toggleSuccessModal}
      />

      <SuccessModal
        successText={t('promocodeActivated')}
        isModalOpened={isSuccessModalOpened}
        submitFunction={() => history.push(CommonRoutes.DASHBOARD)}
        onCloseModal={toggleSuccessModal}
      />
    </MainLayout>
  );
};
