import { createAsyncThunk } from '@reduxjs/toolkit';
import { BiomarkerGetResDto, UnitsOptionDto } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';
import { downloadBlob } from 'utils/downloadBlob';

import { ExportCheckUpsPdfDto } from '../../api/generated';
import { SerializedError } from './types';

export const findAllBiomarkersAsync = createAsyncThunk<
  BiomarkerGetResDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>(
  'biomarkers/findAll',

  async (_, { extra: { api }, rejectWithValue }) => {
    const language = getLanguageFromStorage();

    try {
      const { data } = await api.BiomarkersApi.biomarkersControllerFindAll({ params: { l: language } });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const exportCheckUpsPdfAsync = createAsyncThunk<
  void,
  ExportCheckUpsPdfDto & { pdfFilename: string },
  ExtraParamsThunkType<SerializedError>
>('analyzes/exportAnalysisPdf', async ({ biomarkerNames, label, pdfFilename }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data: blob } = await api.BiomarkersApi.biomarkersControllerDownloadPdf(
      { biomarkerNames, label },
      {
        params: { l: language },
        responseType: 'blob',
      },
    );

    downloadBlob(blob, pdfFilename);
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getUnitsCoefficients = createAsyncThunk<
  UnitsOptionDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('units/coefficients', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.UnitsApi.unitsControllerUnitsCoefficients({ params: { l: language } });

    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
