import * as React from 'react';

function UserTableIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={42} height={42} fill="none" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={42} height={42} rx={16} fill="#F7F7F7" />
      <path
        d="M20.858 20.634c1.323 0 2.47-.475 3.405-1.411.937-.937 1.412-2.082 1.412-3.406 0-1.323-.475-2.47-1.412-3.406-.936-.936-2.082-1.411-3.405-1.411-1.324 0-2.47.475-3.406 1.411-.937.937-1.412 2.082-1.412 3.406 0 1.324.475 2.47 1.412 3.406.936.936 2.082 1.411 3.405 1.411zM29.286 26.379c-.027-.39-.081-.815-.162-1.264a9.964 9.964 0 00-.31-1.27 6.287 6.287 0 00-.523-1.185 4.474 4.474 0 00-.788-1.027 3.473 3.473 0 00-1.131-.71 3.91 3.91 0 00-1.444-.262c-.205 0-.402.084-.783.332-.235.153-.51.33-.816.526-.262.167-.617.323-1.055.465a4.19 4.19 0 01-1.29.208c-.43 0-.864-.07-1.292-.208-.438-.142-.793-.298-1.054-.465-.304-.194-.578-.371-.817-.526-.38-.249-.578-.332-.782-.332-.52 0-1.006.088-1.444.261-.44.174-.82.413-1.132.711-.297.285-.562.63-.787 1.027a6.293 6.293 0 00-.523 1.185c-.125.39-.23.818-.31 1.27-.08.448-.135.874-.162 1.264-.027.383-.04.78-.04 1.181 0 1.044.332 1.89.986 2.513.646.615 1.502.927 2.542.927h9.63c1.04 0 1.895-.312 2.541-.927.655-.623.987-1.468.987-2.513 0-.403-.014-.8-.04-1.181z"
        fill="#8E8E8E"
      />
    </svg>
  );
}

export default UserTableIcon;
