export const EmptyChartMock = [
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 136,
    name: '17 июн',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 138,
    name: '15 июл',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 139,
    name: '1 авг',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 139,
    name: '1 авг',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 148,
    name: '15 сент',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 139,
    name: '4 ноя',
  },
  {
    analysisId: '',
    isInAnalysis: true,
    marker: 146,
    name: '19 ноя',
  },
];
