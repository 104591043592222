import { ExpertCompleteOnboardingDtoGenderEnum } from 'api/generated';
import classnames from 'classnames';
import { selectOnboardingState } from 'features/expertOnbording/selectors';
import { OnboardingState } from 'features/expertOnbording/slice';
import useWindowWidth from 'hooks/useWindowWidth';
import plural from 'plural-ru';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ButtonDefault,
  DatePickerDropdown,
  DropdownDefault,
  FormError,
  FormLabel,
  InputDefault,
  NumberMaskedInput,
  TextArea,
} from 'UIcomponents';
import { hasNumbers, hasOnlyLettersAndSpaces } from 'utils/formsValidators';
import { patterns } from 'utils/patterns';
import { parsingSpecialCharacters, trimString } from 'utils/textHelper';
import { outOfSideDate } from 'utils/time';

import styles from './styles.module.scss';

interface StepProps {
  onSubmit: (data: OnboardingState) => void;
  buttonText: string;
  title: string;
  subtitle?: string;
  input: { name: string; label: string };
  type: string;
  isLoading?: boolean;
}

export type ExpertOnboardingFormValues = {
  name: string;
  birthDay: Date;
  gender?: ExpertCompleteOnboardingDtoGenderEnum;
  specialization: string;
  expirience: number;
  about: string;
};

export const Step: FC<StepProps> = ({
  onSubmit,
  buttonText,
  children,
  title,
  subtitle,
  input,
  type,
  isLoading = false,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ui.auth' });

  const experienceMask = (value: number | string): string => {
    return `## ${plural(Number(value), t('year'), t('years'), t('yearPlural'))}`;
  };

  const GenderOptions = {
    male: { label: t('male'), value: ExpertCompleteOnboardingDtoGenderEnum.Male },
    female: { label: t('female'), value: ExpertCompleteOnboardingDtoGenderEnum.Female },
  };

  const onboardingState = useSelector(selectOnboardingState);
  const width = useWindowWidth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ExpertOnboardingFormValues>({
    defaultValues: {
      name: onboardingState.name,
      birthDay: onboardingState.birthDay || outOfSideDate(18),
      gender: onboardingState.gender,
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['onboarding-step']}>
          <h2 className={styles['onboarding-step__title']}>{title}</h2>
          {subtitle && (
            <div className={classnames('body body_medium', styles['onboarding-step__subtitle'])}>{subtitle}</div>
          )}
          {type === 'nameStep' && (
            <>
              <div className={classnames('subhead_regular', styles['onboarding-step__input-label'])}>
                {<FormLabel text={input.label} />}
              </div>
              <div className={styles['onboarding-step__input']}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: t('required') as string,
                    validate: (value) => {
                      if (!value.trim()) {
                        return t('required') as string;
                      }
                      if (hasNumbers(value)) {
                        return t('hasNumbers') as string;
                      }
                      if (!hasOnlyLettersAndSpaces(value)) {
                        const specialCharacters = parsingSpecialCharacters(value);
                        return `${t('hasOnlyLettersAndSpaces')} ${
                          specialCharacters?.length ? ': ' + specialCharacters : ''
                        }`;
                      }
                      if (value.trim().length < 4) {
                        return t('hasMinLenght') as string;
                      }
                    },
                    pattern: {
                      value: patterns.name,
                      message: t('fullnamePattern') as string,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputDefault
                      onChange={onChange}
                      value={value}
                      error={errors?.name && 'error'}
                      onBlur={() => onChange(trimString(value))}
                      placeholder={input.label}
                    />
                  )}
                />
                {errors.name?.message && <FormError type="error" text={errors.name.message} />}
              </div>
            </>
          )}
          {type === 'stepDatepicker' && (
            <>
              <div className={classnames('subhead_regular', styles['onboarding-step__input-label'])}>
                {<FormLabel text={input.label} />}
              </div>
              <div className={styles['onboarding-step__input']}>
                <Controller
                  name="birthDay"
                  rules={{ required: t('required') as string }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerDropdown
                      icon="calendar"
                      setSelectedDay={onChange}
                      selectedDay={value}
                      variant="max-content"
                      disabledDays={{ after: outOfSideDate(18) }}
                      bigHeightMobile
                    />
                  )}
                />
                {errors.birthDay?.message && <FormError type="error" text={errors.birthDay.message} />}
              </div>
            </>
          )}
          {type === 'stepDropdown' && (
            <>
              <div className={classnames('subhead_regular', styles['onboarding-step__input-label'])}>
                {<FormLabel text={input.label} />}
              </div>
              <div className={styles['onboarding-step__input']}>
                <Controller
                  name="gender"
                  rules={{ required: t('required') as string }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownDefault
                      onChange={(option) => onChange(option.value)}
                      isSearchable={false}
                      selected={value && GenderOptions[value]}
                      options={[GenderOptions.male, GenderOptions.female]}
                      type="onboarding-input"
                      bigHeightMobile
                      error={errors?.gender && 'error'}
                    />
                  )}
                />
                {errors.gender?.message && <FormError type="error" text={errors.gender.message} />}
              </div>
            </>
          )}
          {type === 'stepSpec' && (
            <>
              <div className={styles['fields-container']}>
                <div className={styles['field']}>
                  <FormLabel text={t('specialization')} />
                  <Controller
                    name={'specialization'}
                    rules={{
                      required: t('required') as string,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputDefault
                          placeholder={t('specializationPlaceholder') as string}
                          onChange={onChange}
                          value={value || ''}
                          customStyles={{ maxWidth: width > 992 ? '372px' : '100%' }}
                          error={errors?.specialization && 'error'}
                        />
                      );
                    }}
                  />
                  {errors.specialization?.message && <FormError type="error" text={errors.specialization.message} />}
                </div>
                <div className={styles['field']}>
                  <FormLabel text={t('expirience')} />
                  <Controller
                    name={'expirience'}
                    control={control}
                    rules={{
                      required: t('required') as string,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <NumberMaskedInput
                        format={experienceMask(value)}
                        onChange={onChange}
                        placeholder={t('expiriencePlaceholder') as string}
                        value={value || ''}
                        error={errors.expirience && 'error'}
                        allowNegative={false}
                        componentClassNames={classnames(styles['number-masked-input'])}
                      />
                    )}
                  />
                  {errors.expirience?.message && <FormError type="error" text={errors.expirience.message} />}
                </div>
              </div>
              <div className={styles['field__textarea-box']}>
                <FormLabel text={t('about')} />
                <Controller
                  name={'about'}
                  rules={{
                    required: t('required') as string,
                    min: { value: 1, message: t('aboutPattern') },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextArea
                        value={value}
                        onChange={onChange}
                        placeholder={t('typeMessage') as string}
                        disabled={false}
                        minRows={5}
                        isBig={true}
                        error={errors.about && 'error'}
                      />
                    );
                  }}
                />
                {errors.about?.message && <FormError type="error" text={errors.about.message} />}
              </div>
            </>
          )}
        </div>
        {children}
        <ButtonDefault
          containerClassNames={classnames(styles['onboarding-step__btn'])}
          text={buttonText}
          isDisabled={isLoading}
          isLoading={isLoading}
          type="submit"
        />
      </form>
    </div>
  );
};
