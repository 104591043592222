import * as React from 'react';

function SvgPictureIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.25 18.531a3.744 3.744 0 01-3.543-2.55l-.035-.116a3.65 3.65 0 01-.173-1.084V7.963L.073 16.06a2.271 2.271 0 001.592 2.755l15.464 4.141c.193.05.386.074.576.074.996 0 1.906-.66 2.161-1.635l.9-2.865H6.25zM9 8.03c1.102 0 2-.896 2-2 0-1.102-.898-2-2-2-1.104 0-2.001.898-2.001 2 0 1.104.897 2 2 2z"
        fill="#646EFA"
      />
      <path
        d="M21.5 1.03H6.498a2.504 2.504 0 00-2.5 2.501v11c0 1.378 1.122 2.5 2.5 2.5h15c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zm-15.001 2h15a.5.5 0 01.5.501v7.1L18.84 6.943a1.791 1.791 0 00-1.34-.615 1.748 1.748 0 00-1.337.631l-3.714 4.458-1.21-1.207a1.755 1.755 0 00-2.48 0L6 12.971V3.53a.5.5 0 01.5-.5z"
        fill="#646EFA"
      />
    </svg>
  );
}

export default SvgPictureIcon;
