import { FoldersIcon, SearchIcon } from 'assets/svg';
import { useClientSize } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CommonRoutes } from 'types/routes';
import { ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

type EmptyAnalyzesHistoryProps = {
  isRequestsTabActive: boolean;
};

export const EmptyAnalyzesHistory: FC<EmptyAnalyzesHistoryProps> = ({ isRequestsTabActive }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const { push } = useHistory();

  const handleAddAnalysis = () => {
    push(CommonRoutes.NEW_ANALYSIS);
  };

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <div className={styles.empty}>
      {isRequestsTabActive ? (
        <div className={styles.empty__content}>
          <FoldersIcon className={styles.empty__icon} />
          <p className={`body body_medium ${styles.empty__subtitle}`}>{t('noRequests')}</p>
        </div>
      ) : (
        <div className={styles.empty__content}>
          <SearchIcon className={styles.empty__icon} fill="var(--background-primary-light)" />
          <p className={`body body_medium ${styles.empty__subtitle}`}>{t('analisisEmpty')}</p>
          <ButtonDefault
            text={t('addNewAnalyzes')}
            onClick={handleAddAnalysis}
            customStyles={{ width: isMobile ? 327 : 200 }}
          />
        </div>
      )}
    </div>
  );
};
