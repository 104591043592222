import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Anamnesis,
  CheckupResDto,
  ExpertFullInfoResDto,
  FileUploadSignedUrlResDto,
  FirstTypeCardsPatientGetResDto,
  GetChatRoomsResDto,
  GetPatientNutritonPlanResDto,
  Patient,
  PatientCreateAnamnesisDto,
  PatientCreateChatDto,
  PatientCreatePatientDiaryDto,
  PatientDiary,
  PatientDiaryDayEnum,
  PatientEmailCodeDto,
  PatientForgotPasswordDto,
  PatientGetExpertsResDto,
  PatientRecommendationCardsGetResDto,
  PatientRestorePasswordDto,
  PatientSetNewEmailDto,
  PatientSettings,
  PatientUpdatePasswordDto,
  PatientUpdatePatientDiaryDto,
  PatientUpdateSettingsDto,
  PatientVerifyCurrentEmailDto,
  PhotoUploadSignedUrlDto,
  SecondTypeCardsPatientGetResDto,
  ThirdTypeCardsPatientGetResDto,
  UpdatePatientDto,
} from 'api/generated';
import { SignInValidationErrors } from 'features/auth/types';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import {
  DeletePatientValidationErrors,
  FindMeValidationErrors,
  SerializedError,
  UpdatePatientValidationErrors,
} from './types';

export const getEmailAsync = createAsyncThunk<string, string, ExtraParamsThunkType<UpdatePatientValidationErrors>>(
  'patient/getEmailPatient',
  async (hash: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerGetPatientEmail(hash, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePatientAsync = createAsyncThunk<
  Patient,
  UpdatePatientDto,
  ExtraParamsThunkType<UpdatePatientValidationErrors>
>('patient/updatePatient', async (userData: UpdatePatientDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerUpdateMe(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const findMeAsync = createAsyncThunk<Patient, undefined, ExtraParamsThunkType<FindMeValidationErrors>>(
  'patient/findMe',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerFindMe({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePatientAsync = createAsyncThunk<
  void,
  Record<string, unknown>,
  ExtraParamsThunkType<DeletePatientValidationErrors>
>('patient/deletePatient', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerDeleteMe({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sendCurrentEmailVerificationCodeAsync = createAsyncThunk<
  string,
  PatientEmailCodeDto,
  ExtraParamsThunkType<SerializedError>
>(
  'patient/sendCurrentEmailVerificationCode',
  async (userData: PatientEmailCodeDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerSendCurrentEmailVerificationCode(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendVerifyCurrentEmailAsync = createAsyncThunk<
  string,
  PatientVerifyCurrentEmailDto,
  ExtraParamsThunkType<SerializedError>
>(
  'patient/sendVerifyCurrentEmail',
  async (userData: PatientVerifyCurrentEmailDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerVerifyCurrentEmail(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendNewEmailVerificationCodeAsync = createAsyncThunk<
  string,
  PatientEmailCodeDto,
  ExtraParamsThunkType<SerializedError>
>(
  'patient/sendNewEmailVerificationCode',
  async (userData: PatientEmailCodeDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerSendNewEmailVerificationCode(userData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const setNewEmailAsync = createAsyncThunk<Patient, PatientSetNewEmailDto, ExtraParamsThunkType<SerializedError>>(
  'patient/setNewEmail',
  async (patientData: PatientSetNewEmailDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerSetNewEmail(patientData, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePasswordAsync = createAsyncThunk<
  void,
  PatientUpdatePasswordDto,
  ExtraParamsThunkType<SerializedError>
>('patient/updatePassword', async (userData: PatientUpdatePasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerUpdatePassword(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getChatRoomAsync = createAsyncThunk<GetChatRoomsResDto[], void, ExtraParamsThunkType<SerializedError>>(
  '/patients/chat-rooms',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsChatsApi.patientsChatsControllerGetChatRooms(undefined, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAnamnesAsync = createAsyncThunk<Anamnesis, undefined, ExtraParamsThunkType<SerializedError>>(
  'patient/getAnamnes',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsAnamnesisApi.patientsAnamnesisControllerGetAnamnesis({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

interface UpdateAnamnesDto {
  anamnesisId: string;
  patientCreateAnamnesisDto: PatientCreateAnamnesisDto;
}

export const updateAnamnesAsync = createAsyncThunk<Anamnesis, UpdateAnamnesDto, ExtraParamsThunkType<SerializedError>>(
  'patient/updateAnamnes',
  async (dto: UpdateAnamnesDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { anamnesisId, patientCreateAnamnesisDto } = dto;
      const { data } = await api.PatientsAnamnesisApi.patientsAnamnesisControllerUpdateAnamnesis(
        anamnesisId,
        patientCreateAnamnesisDto,
        {
          params: { l: language },
        },
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createAnamnesAsync = createAsyncThunk<
  Anamnesis,
  PatientCreateAnamnesisDto,
  ExtraParamsThunkType<SerializedError>
>('patient/createAnamnes', async (anamnes: PatientCreateAnamnesisDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsAnamnesisApi.patientsAnamnesisControllerCreateAnamnesis(anamnes, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createPatientDiaryAsync = createAsyncThunk<
  PatientDiary,
  { diary: PatientCreatePatientDiaryDto; day: PatientDiaryDayEnum },
  ExtraParamsThunkType<SerializedError>
>('patient/createPatientDiary', async ({ diary, day }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsPatientDiaryApi.patientsPatientDiaryControllerCreatePatientDiary(day, diary, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updatePatientDiaryAsync = createAsyncThunk<
  PatientDiary,
  { id: string; diary: PatientUpdatePatientDiaryDto },
  ExtraParamsThunkType<SerializedError>
>('patient/updatePatientDiary', async ({ id, diary }, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsPatientDiaryApi.patientsPatientDiaryControllerUpdatePatientDiary(id, diary, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPatientDiaryAsync = createAsyncThunk<PatientDiary[], undefined, ExtraParamsThunkType<SerializedError>>(
  'patient/getPatientDiary',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsPatientDiaryApi.patientsPatientDiaryControllerGetPatientDiary({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const clearPatientDiaryAsync = createAsyncThunk<string, undefined, ExtraParamsThunkType<SerializedError>>(
  'patient/clearPatientDiary',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsPatientDiaryApi.patientsPatientDiaryControllerClearPatientDiary({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getNutrionPlanAsync = createAsyncThunk<
  GetPatientNutritonPlanResDto,
  void,
  ExtraParamsThunkType<SerializedError>
>('patient/seenNutritionPlan', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsNutritionPlanApi.patientsNutritionPlansControllerFindPatientNutritionPlan({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const nutritionPlanSeenAsync = createAsyncThunk<void, string, ExtraParamsThunkType<SerializedError>>(
  'patient/getNutrionPlan',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } =
        await api.PatientsNutritionPlanApi.patientsNutritionPlansControllerMarkPatientNutritionPlanAsSeen(id, {
          params: { l: language },
        });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRecommendationsAsync = createAsyncThunk<
  PatientRecommendationCardsGetResDto,
  undefined,
  ExtraParamsThunkType<SerializedError>
>('patient/recommendations', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsRecommendationsApi.patientsRecommendationsControllerGetAllRecommendationCards({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getFirstTypeCardByIdAsync = createAsyncThunk<
  FirstTypeCardsPatientGetResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('patient/first-type-card', async (firstTypeCardId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsRecommendationsApi.patientsRecommendationsControllerGetOnePatientFirstTypeCard(
      firstTypeCardId,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getSecondTypeCardByIdAsync = createAsyncThunk<
  SecondTypeCardsPatientGetResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('patient/second-type-card', async (secondTypeCardId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsRecommendationsApi.patientsRecommendationsControllerGetOnePatientSecondTypeCard(
      secondTypeCardId,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getThirdTypeCardByIdAsync = createAsyncThunk<
  ThirdTypeCardsPatientGetResDto,
  string,
  ExtraParamsThunkType<SerializedError>
>('patient/third-type-card', async (thirdTypeCardId: string, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsRecommendationsApi.patientsRecommendationsControllerGetOnePatientThirdTypeCard(
      thirdTypeCardId,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const calculatesRecommendationsAsync = createAsyncThunk<void, undefined, ExtraParamsThunkType<SerializedError>>(
  'patient/calculate-recommendations',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsRecommendationsApi.patientsRecommendationsControllerCalculateRecommendations({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const patientSendRestorePasswordLinkAsync = createAsyncThunk<
  void,
  PatientForgotPasswordDto,
  ExtraParamsThunkType<SerializedError>
>(
  'patient/sendRestorePasswordLink',
  async (patientForgotPasswordDto: PatientForgotPasswordDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerSendRestorePasswordLink(patientForgotPasswordDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const patientRestorePasswordAsync = createAsyncThunk<
  void,
  PatientRestorePasswordDto,
  ExtraParamsThunkType<SignInValidationErrors>
>('auth/restorePassword', async (userData: PatientRestorePasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerRestorePassword(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAvatarSignedUrlAsync = createAsyncThunk<
  FileUploadSignedUrlResDto,
  PhotoUploadSignedUrlDto,
  ExtraParamsThunkType<SerializedError>
>(
  '/patients/signed-url-to-upload',
  async (photoUploadSignedUrlDto: PhotoUploadSignedUrlDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientApi.patientsControllerGetPhotoSignedUrlToUpload(photoUploadSignedUrlDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

interface QueryExpertsDto {
  seedForShuffle: number;
  page: number;
  limit: number;
  options?: any;
}

export const getExpertsAsync = createAsyncThunk<
  PatientGetExpertsResDto,
  QueryExpertsDto,
  ExtraParamsThunkType<SerializedError>
>('patients/experts', async (query: QueryExpertsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, limit, options, seedForShuffle } = query;
    const { data } = await api.PatientsExpertsApi.patientsExpertsControllerFindExperts(
      page,
      limit,
      options,
      seedForShuffle,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const searchExpertsAsync = createAsyncThunk<
  PatientGetExpertsResDto,
  QueryExpertsDto,
  ExtraParamsThunkType<SerializedError>
>('patients/search-experts', async (query: QueryExpertsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { page, limit, options, seedForShuffle } = query;
    const { data } = await api.PatientsExpertsApi.patientsExpertsControllerFindExperts(
      page,
      limit,
      options,
      seedForShuffle,
      {
        params: { l: language },
      },
    );
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

interface GetChat {
  searchQuery?: string;
}

interface ExpertValidationsErrors {
  message: string;
  statusCode: number;
}

export const getChatsPatientAsync = createAsyncThunk<
  { data: Array<GetChatRoomsResDto>; withSearch: boolean },
  GetChat,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('patients/getChats', async (search: GetChat, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { searchQuery } = search;
    const { data } = await api.PatientsChatsApi.patientsChatsControllerGetChatRooms(searchQuery, {
      params: { l: language },
    });
    return { data, withSearch: !!searchQuery };
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sendCooperationRequestWithExpert = createAsyncThunk<void, string, ExtraParamsThunkType<SerializedError>>(
  'request/send-cooperation-request-with-expert',
  async (expertId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      await api.RequestsApi.requestsControllerPatientSendRequest(expertId, {
        params: { l: language },
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelCooperationRequestWithExpert = createAsyncThunk<void, string, ExtraParamsThunkType<SerializedError>>(
  'request/cancel-cooperation-request-with-expert',
  async (expertId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      await api.RequestsApi.requestsControllerPatientCancelRequest(expertId, {
        params: { l: language },
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const stopCooperationWithExpert = createAsyncThunk<boolean, undefined, ExtraParamsThunkType<SerializedError>>(
  'request/stop-cooperation-expert',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsExpertsApi.patientsExpertsControllerStopCooperation({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAssignedCheckups = createAsyncThunk<CheckupResDto[], undefined, ExtraParamsThunkType<SerializedError>>(
  'patient/get-assigned-checkups',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsCheckupsApi.patientsCheckupsControllerGetAssignedCheckups({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOneExpert = createAsyncThunk<ExpertFullInfoResDto, string, ExtraParamsThunkType<SerializedError>>(
  'patient/get-one-expert',
  async (expertId: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsExpertsApi.patientsExpertsControllerFindOneExpert(expertId, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const createChatRoomWithExpert = createAsyncThunk<
  GetChatRoomsResDto,
  PatientCreateChatDto,
  ExtraParamsThunkType<SerializedError>
>(
  'patient/create-chat-room-with-expert',
  async (patientCreateChatDto: PatientCreateChatDto, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsChatsApi.patientsChatsControllerCreateChatRoom(patientCreateChatDto, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendCurrentEmailVerificationCode = createAsyncThunk<
  string,
  PatientEmailCodeDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patients/email-current', async (userData: PatientEmailCodeDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSendCurrentEmailVerificationCode(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientVerificationCodeCurrentAsync = createAsyncThunk<
  string,
  PatientVerifyCurrentEmailDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patients/currentEmail', async (userData: PatientVerifyCurrentEmailDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerVerifyCurrentEmail(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const sendNewEmailVerificationCode = createAsyncThunk<
  string,
  PatientEmailCodeDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patients/newemail-verification', async (userData: PatientEmailCodeDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSendNewEmailVerificationCode(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientSetNewEmailAsync = createAsyncThunk<
  Patient,
  PatientSetNewEmailDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patient/set-new-email-current', async (userData: PatientSetNewEmailDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerSetNewEmail(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const patientChangePasswordAsync = createAsyncThunk<
  void,
  PatientUpdatePasswordDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patient/password-change', async (userData: PatientUpdatePasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientApi.patientsControllerUpdatePassword(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updatePatientSettings = createAsyncThunk<
  PatientSettings,
  PatientUpdateSettingsDto,
  ExtraParamsThunkType<ExpertValidationsErrors>
>('/patient/settings', async (patientSettings: PatientUpdateSettingsDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.PatientSettingsApi.patientSettingsControllerUpdatePatientSettings(patientSettings);

    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPatientSettings = createAsyncThunk<PatientSettings, void, ExtraParamsThunkType<SerializedError>>(
  'patient/settings',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientSettingsApi.patientSettingsControllerGetPatientSettings({
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
