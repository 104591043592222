import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommonRoutes } from 'types/routes';

import styles from './styles.module.scss';

export const TermsAndConditions = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.profileSettingsTab' });

  return (
    <p className={classNames('subhead', 'subhead_medium', styles['terms-text'])}>
      <Link className={styles['terms-link']} to={CommonRoutes.TERMS_OF_SERVICE}>
        {t('terms')}
      </Link>
      ,{' '}
      <Link className={styles['terms-link']} to={CommonRoutes.PRIVACY_POLICY}>
        {t('policy')}
      </Link>{' '}
      {t('and')}{' '}
      <Link className={styles['terms-link']} to={CommonRoutes.DISCLAIMER_OF_LIABILITY}>
        {t('disclaimer')}
      </Link>
    </p>
  );
};
