import { EnergyAndSleepEnergyLevelEnum } from 'api/generated';
import classnames from 'classnames';
import styles from 'layouts/PatientDiaryLayout/styles.module.scss';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropdownOptions } from 'types';
import { DropdownDefault, FormError, TextArea } from 'UIcomponents';

type Props = {
  isInputsDisabled?: boolean;
};

export const EnergyAndSleepTab: React.FC<Props> = ({ isInputsDisabled = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary.energyAndSleepTab' });

  const energyLevelOptions: DropdownOptions<EnergyAndSleepEnergyLevelEnum>[] = [
    { label: t('stable'), value: EnergyAndSleepEnergyLevelEnum.Stable },
    { label: t('morningLow'), value: EnergyAndSleepEnergyLevelEnum.MorningLow },
    { label: t('afternoonLow'), value: EnergyAndSleepEnergyLevelEnum.AfternoonLow },
    { label: t('eveningLow'), value: EnergyAndSleepEnergyLevelEnum.EveningLow },
  ];

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles['simple-section']}>
      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>{t('energyLvl')}</p>
        <Controller
          control={control}
          name={'energyAndSleep.energyLevel'}
          render={({ field: { onChange, value } }) => {
            const index = energyLevelOptions.findIndex((elem) => elem.value === value);
            return (
              <DropdownDefault
                options={energyLevelOptions}
                selected={energyLevelOptions[index]}
                onChange={({ value }) => {
                  onChange(value);
                }}
                isSearchable={false}
                disabled={isInputsDisabled}
              />
            );
          }}
        />
        {errors?.['energyAndSleep.energyLevel'] && (
          <FormError type="error" text={errors?.['energyAndSleep.energyLevel']?.message} />
        )}
      </div>

      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
          {t('sleepCaption')} <span className={styles['text-area__title_grey']}>({t('sleepCaption')})</span>
        </p>
        <Controller
          control={control}
          name={'energyAndSleep.sleep'}
          render={({ field: { onChange, value } }) => (
            <TextArea
              value={value}
              onChange={onChange}
              minRows={4}
              error={errors?.['energyAndSleep.sleep'] && 'error'}
              disabled={isInputsDisabled}
            />
          )}
        />
        {errors?.['energyAndSleep.sleep'] && (
          <FormError type="error" text={errors?.['energyAndSleep.sleep']?.message} />
        )}
      </div>
    </div>
  );
};
