import { EyeOffIcon, EyeOnIcon } from 'assets/svg';
import classnames from 'classnames';
import { ChangeEvent, CSSProperties, FC, useState } from 'react';

import styles from './styles.module.scss';

type PasswordInputProps = {
  placeholder?: string;
  value?: string | number;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  customStyles?: CSSProperties;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyUp?: () => void;
  onKeyDown?: () => void;
  name?: string;
  customClassName?: string;
  autoComplete?: 'on' | 'off';
};

export const PasswordInput: FC<PasswordInputProps> = ({
  placeholder,
  required = false,
  disabled = false,
  autoComplete = 'on',
  value = '',
  error,
  readOnly,
  maxLength,
  customStyles,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onKeyDown,
  customClassName,
}) => {
  const [isProtected, setIsProtected] = useState<boolean>(true);
  return (
    <div className={classnames(styles['password-input'], customClassName)} style={customStyles}>
      <div
        className={classnames(styles['password-input__wrapper'], {
          [styles['password-input__wrapper_error']]: error,
        })}>
        <input
          className={classnames(styles['password-input__input'], {
            [styles['password-input__input_error']]: error,
          })}
          placeholder={placeholder}
          required={required}
          type={isProtected ? 'password' : undefined}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          autoComplete={autoComplete}
          spellCheck="false"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
        <button
          tabIndex={-1}
          type="button"
          onClick={() => setIsProtected(!isProtected)}
          className={styles['password-input__icon']}>
          {isProtected ? <EyeOnIcon /> : <EyeOffIcon />}
        </button>
      </div>
    </div>
  );
};
