import { ChatBigIcon } from 'assets/svg';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDefault } from 'UIcomponents';

import { NewMessageModal } from '../NewMessageModal';
import styles from './styles.module.scss';

type EmptyMessageProps = {
  patients: Array<{ label: string; value: string }> | null;
  isExpert: boolean;
};

export const EmptyMessage: FC<EmptyMessageProps> = ({ patients, isExpert }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'message' });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const toggleModal = () => {
    setIsOpenModal((state) => !state);
  };

  const hasEmptyPatients = patients?.length === 0;

  const renderEmptyExpertMessagesList = () => {
    return hasEmptyPatients ? t('addNewPatient') : t('emptyMessage');
  };

  const isNewMessageModal = isOpenModal && patients;

  return (
    <div className={styles['empty-message']}>
      <div className={styles['empty-message__container']}>
        <ChatBigIcon />
        <p className={styles['empty-message__text']}>
          {isExpert ? renderEmptyExpertMessagesList() : t('emptyMessage')}
        </p>
        {isExpert && (
          <ButtonDefault
            onClick={toggleModal}
            isDisabled={hasEmptyPatients}
            customStyles={{ width: '258px' }}
            text={t('newMessage')}
            variant="primary"
          />
        )}
      </div>
      {isNewMessageModal && <NewMessageModal patients={patients} onClose={toggleModal} />}
    </div>
  );
};
