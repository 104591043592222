import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types';

import { CheckupsStore } from './types';

export const selectCheckupsStore = (state: RootState): CheckupsStore => state.checkups;

export const selectCheckups = createSelector(selectCheckupsStore, (state) => state.checkups);

export const selectCheckupsLoadingStatus = createSelector(selectCheckupsStore, (state) => state.checkupsLoadingStatus);

export const selectCheckup = createSelector(selectCheckupsStore, (state) => state.checkup);

export const selectCheckupLoadingStatus = createSelector(selectCheckupsStore, (state) => state.checkupLoadingStatus);
