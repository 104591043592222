import { DiaryStressStressLevelEnum } from 'api/generated';
import classnames from 'classnames';
import styles from 'layouts/PatientDiaryLayout/styles.module.scss';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropdownOptions } from 'types';
import { DropdownDefault, FormError, TextArea } from 'UIcomponents';

type Props = {
  isInputsDisabled?: boolean;
};

export const StressTab: React.FC<Props> = ({ isInputsDisabled = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary.stressTab' });

  const stressLevelOptions: DropdownOptions<DiaryStressStressLevelEnum>[] = [
    { label: t('stable'), value: DiaryStressStressLevelEnum.Stable },
    { label: t('medium'), value: DiaryStressStressLevelEnum.Medium },
    { label: t('high'), value: DiaryStressStressLevelEnum.High },
  ];

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className={styles['simple-section']}>
      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>{t('stressLvl')}</p>
        <Controller
          control={control}
          name={'stress.stressLevel'}
          render={({ field: { onChange, value } }) => {
            const index = stressLevelOptions.findIndex((elem) => elem.value === value);
            return (
              <DropdownDefault
                options={stressLevelOptions}
                onChange={({ value }) => onChange(value)}
                selected={stressLevelOptions[index]}
                isSearchable={false}
                disabled={isInputsDisabled}
              />
            );
          }}
        />
      </div>

      <div>
        <p className={classnames(styles['text-area__title'], 'subhead', 'subhead_regular')}>
          {t('situation')} <span className={styles['text-area__title_grey']}>({t('situationCaption')})</span>
        </p>
        <Controller
          control={control}
          name={'stress.stressSituation'}
          render={({ field: { onChange, value } }) => (
            <TextArea
              value={value}
              onChange={onChange}
              minRows={4}
              error={errors?.['stress.stressSituation'] && 'error'}
              disabled={isInputsDisabled}
            />
          )}
        />
        {errors?.['stress.stressSituation'] && (
          <FormError type="error" position="absolute" text={errors?.['stress.stressSituation']?.message} />
        )}
      </div>
    </div>
  );
};
