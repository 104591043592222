import { ErrorIcon, SuccessIcon } from 'assets/svg';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeOptions } from 'react-toastify';

import styles from './Notification.module.scss';

export type DefaultNotificationProps = {
  type: Extract<TypeOptions, 'success' | 'error'>;
  text: string;
};

export const DefaultNotification: FC<DefaultNotificationProps> = ({ type, text }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifyService' });

  const messageTextMapping = {
    success: t('success'),
    error: t('error'),
  };
  const messageText = messageTextMapping[type] || t('notification');

  return (
    <div className={classNames('flexbox', 'justify-start', styles.notification)}>
      <div className={classNames(styles['notification__icon'])}>
        {type === 'success' && <SuccessIcon />}
        {type === 'error' && <ErrorIcon fill="var(--red)" />}
      </div>
      <div className={classNames('flexbox', 'direction-column', styles['notification__content'])}>
        <h5 className={classNames(styles[`notification__content--${type}`])}>{messageText}</h5>
        <p className={classNames(styles['notification__content--text'])}>{text}</p>
      </div>
    </div>
  );
};
