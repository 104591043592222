import { LogoBiogeekExpert, LogoBiogeekPatient } from 'assets/svg';
import classNames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { LanguageDropdown } from 'UIcomponents/LanguageDropdown';

import styles from './styles.module.scss';

export const HeaderTerms: FC = () => {
  const role = useAppSelector(selectRole);
  const isPatient = role === RolesEnum.PATIENT;

  return (
    <header className={classNames('flexbox', 'justify-between', 'align-center', styles.header)}>
      <div className={classNames('flexbox', 'justify-between', 'align-center', styles.brand)}>
        <div className={classNames('flexbox', styles.logo)}>
          <Link to="/">{isPatient ? <LogoBiogeekPatient /> : <LogoBiogeekExpert />}</Link>
        </div>
      </div>
      <LanguageDropdown />
    </header>
  );
};
