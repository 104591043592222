import * as React from 'react';

function Instagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#646EFA" />
      <path
        d="M15.998 13.3322C14.529 13.3322 13.3302 14.531 13.3302 16C13.3302 17.469 14.529 18.6678 15.998 18.6678C17.467 18.6678 18.6658 17.469 18.6658 16C18.6658 14.531 17.467 13.3322 15.998 13.3322ZM23.9994 16C23.9994 14.8953 24.0094 13.8005 23.9474 12.6978C23.8853 11.4169 23.5931 10.2801 22.6565 9.3435C21.7179 8.40487 20.5831 8.11467 19.3022 8.05263C18.1975 7.99059 17.1028 8.0006 16 8.0006C14.8953 8.0006 13.8005 7.99059 12.6978 8.05263C11.4169 8.11467 10.2801 8.40687 9.3435 9.3435C8.40487 10.2821 8.11467 11.4169 8.05263 12.6978C7.99059 13.8025 8.0006 14.8973 8.0006 16C8.0006 17.1028 7.99059 18.1995 8.05263 19.3022C8.11467 20.5831 8.40687 21.7199 9.3435 22.6565C10.2821 23.5951 11.4169 23.8853 12.6978 23.9474C13.8025 24.0094 14.8973 23.9994 16 23.9994C17.1048 23.9994 18.1995 24.0094 19.3022 23.9474C20.5831 23.8853 21.7199 23.5931 22.6565 22.6565C23.5952 21.7179 23.8853 20.5831 23.9474 19.3022C24.0114 18.1995 23.9994 17.1048 23.9994 16ZM15.998 20.1048C13.7265 20.1048 11.8932 18.2715 11.8932 16C11.8932 13.7285 13.7265 11.8952 15.998 11.8952C18.2695 11.8952 20.1028 13.7285 20.1028 16C20.1028 18.2715 18.2695 20.1048 15.998 20.1048ZM20.2709 12.6858C19.7405 12.6858 19.3123 12.2575 19.3123 11.7271C19.3123 11.1968 19.7405 10.7685 20.2709 10.7685C20.8013 10.7685 21.2296 11.1968 21.2296 11.7271C21.2297 11.8531 21.205 11.9778 21.1569 12.0942C21.1088 12.2105 21.0382 12.3163 20.9491 12.4053C20.8601 12.4944 20.7543 12.565 20.6379 12.6131C20.5216 12.6612 20.3968 12.6859 20.2709 12.6858Z"
        fill="white"
      />
    </svg>
  );
}

export default Instagram;
