import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { PromocodesStore } from './types';

export const selectPromocodesStore = (state: RootState): PromocodesStore => state.promocodes;

export const selectPromocodes = createSelector(selectPromocodesStore, (promocodes) => promocodes.usedPromocodes);

export const selectUsePromocodeLoading = createSelector(selectPromocodesStore, (promocodes) => promocodes.loading);

export const selectActivePromocode = createSelector(selectPromocodesStore, (promocodes) =>
  promocodes.usedPromocodes.find((promocode) => moment(promocode.expirationDate).isAfter(moment(Date.now()))),
);
