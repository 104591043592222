import * as React from 'react';

function SvgCloseIconCircle(props: React.SVGProps<SVGSVGElement>) {
  const { fill = '#262626' } = props;

  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-11.414L7.172 5.757 5.757 7.172 8.586 10l-2.829 2.828 1.415 1.415L10 11.414l2.828 2.829 1.415-1.415L11.414 10l2.829-2.828-1.415-1.415L10 8.586z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgCloseIconCircle;
