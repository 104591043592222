import { RepliedMessage, Sender } from 'api/generated';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import moment from 'moment';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { CurrentFile } from '../CurrentFile';
import { Response } from '../Response';
import styles from './styles.module.scss';

interface FileTypes {
  files: File;
  id: string;
}

interface Attachments {
  filename: string;
  URL: string;
  createdAt: string;
}

interface LetterMobileProps {
  id: string;
  createdAt: string;
  updatedAt: string;
  text: string;
  attachments: Array<Attachments>;
  repliedMessage: RepliedMessage;
  getCurrentMessage?: (id: string) => void;
  sender: Sender;
}

export const LetterMobile: FC<LetterMobileProps> = ({ createdAt, sender, id, text, repliedMessage, attachments }) => {
  const role = useSelector(selectRole);
  const companion = role !== (sender?.kind as unknown as RolesEnum);
  return (
    <div className={styles['letter-mobile']}>
      <div className={companion ? styles['letter-mobile__position_right'] : styles['letter-mobile__position_left']}>
        <div className={companion ? styles['letter-mobile__right'] : styles['letter-mobile__container']}>
          <p className={classnames('mb-1')}>{text}</p>
          {repliedMessage && <Response {...repliedMessage} hasChat={true} />}
          {attachments && attachments.map((elem, index: number) => <CurrentFile key={index} {...elem} inChat={true} />)}
        </div>
        <p
          className={classnames(
            styles['letter-mobile__text_date'],
            companion ? styles['letter-mobile__float'] : styles['div'],
          )}>
          {moment(createdAt).format('HH:mm')}
        </p>
      </div>
    </div>
  );
};
