import { EmailVerification, FAQ, ForgotPassword, PasswordChanged, RestorePassword, SignIn, SignUp } from 'pages';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { CommonRoutes, UnauthorizedRoutes } from 'types/routes';

export const UnauthorizedRouting = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Redirect from="/auth" to={UnauthorizedRoutes.SIGN_IN} exact />
        <Route path={UnauthorizedRoutes.SIGN_IN} component={SignIn} />
        <Route exact path={UnauthorizedRoutes.SIGN_UP} component={SignUp} />
        <Route path={UnauthorizedRoutes.EMAIL_VERIFICATION} component={EmailVerification} />
        <Route path={CommonRoutes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={CommonRoutes.FORGOT_PASSWORD_NEW} component={RestorePassword} />
        <Route exact path={UnauthorizedRoutes.SIGN_UP_PATIENT} component={SignUp} />
        <Route path={CommonRoutes.FORGOT_PASSWORD_CHANGED} component={PasswordChanged} />
        <Route path={CommonRoutes.FAQ} component={FAQ} />
        <Redirect to={UnauthorizedRoutes.SIGN_IN} exact />
      </Switch>
    </Router>
  );
};
