import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { StopCooperationModal } from 'components';
import { stopCooperationWithPatient } from 'features/expert/thunks';
import { NewMessageModal } from 'pages/Message/components/NewMessageModal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ArrowBack, ButtonDefault } from 'UIcomponents';

import styles from './styles.module.scss';

interface TopActionBarProps {
  title: string;
  patientId: string;
  patientName: string;
}

const TopActionBar: FC<TopActionBarProps> = ({ title, patientId, patientName }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientProfile.topActionBar' });

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [isModalMessageOpen, setIsModalMessageOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const toggleMessageModal = () => {
    setIsModalMessageOpen(!isModalMessageOpen);
  };

  const toggleDeleteModal = () => {
    setIsModalDeleteOpen(!isModalDeleteOpen);
  };

  const handelStopCooperationClick = () => {
    dispatch(stopCooperationWithPatient(patientId))
      .then(unwrapResult)
      .then(() => {
        history.push('/dashboard');
        notify('success', t('notifyStopCooperation'));
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  return (
    <>
      <div className={classNames('flexbox', styles['top-bar-wrapper'])}>
        <div className={classNames('flexbox', styles['top-bar-left-block'])}>
          <ArrowBack pathname={'/dashboard'} />
          <div className={classNames(styles['top-bar-title'])}>{title}</div>
        </div>
        <div className={classNames(styles['top-bar-buttons-wrapper'], 'flexbox')}>
          <ButtonDefault text={t('writeMessage')} variant="secondary" onClick={toggleMessageModal} />
          <ButtonDefault
            text={t('stopCooperation')}
            variant="secondary"
            onClick={toggleDeleteModal}
            containerClassNames={styles['top__bar__button']}
          />
        </div>
      </div>
      {isModalMessageOpen && (
        <NewMessageModal patients={[{ label: patientName, value: patientId }]} onClose={toggleMessageModal} />
      )}
      {isModalDeleteOpen && (
        <StopCooperationModal
          onClose={toggleDeleteModal}
          onDelete={handelStopCooperationClick}
          title={t('stopCooperation') as string}
          subtitle={t('stopCooperationModalContent') as string}
        />
      )}
    </>
  );
};

export default TopActionBar;
