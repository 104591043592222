import { TermsAndConditions } from 'components';

import { SearchSettingSection } from './components';
import { MailAddressSection } from './MailAddressSection';
import { PasswordSection } from './PasswordSection';
import styles from './styles.module.scss';

export const ProfileSettingsTab = () => {
  return (
    <div style={{ height: '100%' }}>
      <MailAddressSection />
      <hr className={styles.separator} />
      <PasswordSection />
      <hr className={styles.separator} />
      <SearchSettingSection />
      <hr className={styles.separator} />
      <TermsAndConditions />
    </div>
  );
};
