import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ExpertRoutes } from 'types/routes';

import styles from '../../style.module.scss';
import { TabTitle } from './TabTitle';

type Props = {
  children: ReactElement[];
};

const RouteMap: Record<string, number> = {
  [ExpertRoutes.PROFILE]: 0,
  [ExpertRoutes.SETTINGS]: 1,
  [ExpertRoutes.PAYMENTS]: 2,
  [ExpertRoutes.SUBSCRIPTION]: 3,
  [ExpertRoutes.NOTIFICATIONS]: 4,
};
export const TabsWrapper: FC<Props> = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <div className={classNames('flexbox', 'direction-column', styles['expert-profile-tabs'])}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            isActive={index === RouteMap[location.pathname]}
          />
        ))}
      </div>
      <Switch>
        <Redirect from="/" to={ExpertRoutes.PROFILE} exact />
        <Route exact path={ExpertRoutes.PROFILE}>
          {children[0]}
        </Route>
        <Route path={ExpertRoutes.SETTINGS}>{children[1]}</Route>
        <Route path={ExpertRoutes.PAYMENTS}>{children[2]}</Route>
        <Route path={ExpertRoutes.SUBSCRIPTION}>{children[3]}</Route>
        <Route path={ExpertRoutes.NOTIFICATIONS}>{children[4]}</Route>
        <Redirect to={ExpertRoutes.PROFILE} exact />
      </Switch>
    </>
  );
};
