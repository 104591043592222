import * as React from 'react';

function EmptyUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width={100} height={100} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.406 48.475c5.515 0 10.29-1.978 14.192-5.88 3.902-3.902 5.88-8.676 5.88-14.191 0-5.514-1.978-10.288-5.88-14.191-3.903-3.902-8.678-5.88-14.192-5.88-5.515 0-10.29 1.978-14.191 5.88-3.902 3.902-5.88 8.677-5.88 14.19 0 5.516 1.978 10.29 5.88 14.193 3.903 3.9 8.678 5.88 14.191 5.88zM84.526 72.412a49.608 49.608 0 00-.675-5.265 41.504 41.504 0 00-1.295-5.294 26.152 26.152 0 00-2.177-4.938c-.94-1.653-2.043-3.092-3.282-4.277-1.295-1.239-2.88-2.235-4.714-2.962-1.827-.723-3.852-1.089-6.018-1.089-.85 0-1.674.35-3.262 1.383a439.18 439.18 0 01-3.399 2.191c-1.091.696-2.57 1.348-4.397 1.938-1.782.576-3.591.869-5.377.869-1.786 0-3.595-.293-5.379-.87-1.825-.589-3.303-1.24-4.394-1.936a463.741 463.741 0 01-3.401-2.192c-1.587-1.035-2.41-1.384-3.261-1.384-2.167 0-4.191.367-6.018 1.09-1.832.726-3.418 1.722-4.715 2.963-1.238 1.185-2.342 2.624-3.28 4.276a26.204 26.204 0 00-2.177 4.938 41.58 41.58 0 00-1.294 5.294 49.38 49.38 0 00-.675 5.267 71.377 71.377 0 00-.167 4.921c0 4.35 1.383 7.872 4.11 10.469 2.693 2.563 6.257 3.863 10.59 3.863h40.126c4.333 0 7.895-1.3 10.589-3.863 2.727-2.596 4.11-6.118 4.11-10.47 0-1.679-.057-3.335-.168-4.922z"
        fill="#DADADA"
      />
      <circle cx={76} cy={80} r={14.5} fill="#fff" stroke="#DADADA" strokeWidth={3} />
      <g clipPath="url(#prefix__clip0)" stroke="#DADADA" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round">
        <path d="M71.188 80h9.624M76 75.188v9.624" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(69 73)" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyUser;
