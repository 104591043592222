export enum SubscriptionPlanName {
  day = 'Дневная', //TODO: need for QA
  month = 'Ежемесячная',
  year = 'Годовая',
}

export enum SubscriptionPlan {
  YEARLY = 'year',
  MONTHLY = 'month',
}
