import * as React from 'react';

function Crown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 16L6.608 18.086C7.51657 18.8127 8.57271 19.3325 9.70279 19.6091C10.8329 19.8857 12.0097 19.9126 13.1512 19.6877C14.2927 19.4628 15.3714 18.9917 16.3121 18.3071C17.2528 17.6225 18.0329 16.741 18.598 15.724L24 6L29.402 15.724C29.9672 16.7409 30.7475 17.6222 31.6883 18.3066C32.6291 18.9909 33.7078 19.4619 34.8493 19.6865C35.9908 19.9112 37.1676 19.8842 38.2976 19.6075C39.4275 19.3307 40.4836 18.8108 41.392 18.084L44 16L40.492 33.54C40.3388 34.3055 40.0129 35.0259 39.5391 35.6463C39.0653 36.2667 38.4561 36.7707 37.758 37.12V37.12C33.4863 39.256 28.776 40.368 24 40.368C19.224 40.368 14.5137 39.256 10.242 37.12V37.12C9.54385 36.7707 8.93466 36.2667 8.46086 35.6463C7.98706 35.0259 7.66116 34.3055 7.508 33.54L4 16Z"
        stroke="#F3B63E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 30C21.192 32.666 26.808 32.666 32 30"
        stroke="#F3B63E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Crown;
