import { CheckupResDto } from 'api/generated';
import { Delete, FileYellowIcon } from 'assets/svg';
import classnames from 'classnames';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { useToggle } from 'hooks';
import moment from 'moment';
import { FC } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/reducers';
import { CommonRoutes } from 'types/routes';

import { UnsetCheckupModal } from './components';
import styles from './styles.module.scss';

type AnalysisRequestCardProps = {
  request: CheckupResDto;
  fromHistory?: boolean;
  patientId?: string;
};

export const AnalysisRequestCard: FC<AnalysisRequestCardProps> = ({ request, fromHistory, patientId }) => {
  const { createdAt, name, id } = request;
  const { i18n } = useTranslation();

  const formattedDate =
    i18n.language === 'ru'
      ? moment(createdAt).locale('ru').format('DD MMMM, YYYY')
      : moment(createdAt).locale('en-gb').format('DD MMMM, YYYY');

  const role = useAppSelector(selectRole);

  const history = useHistory();

  const isExpert = role === RolesEnum.EXPERT;

  const { isOpened: isUnsetCheckupModalOpen, onToggle: toggleUnsetCheckupModalOpen } = useToggle();

  const isUnsetCheckupModal = isUnsetCheckupModalOpen && patientId;

  const handleRequestCardClick = () => {
    history.push(CommonRoutes.CHECK_UP(id));
  };

  const handleToggleModal = (event: any) => {
    toggleUnsetCheckupModalOpen();
    event.stopPropagation();
  };

  return (
    <>
      <div
        onKeyDown={handleRequestCardClick}
        role="button"
        tabIndex={0}
        onClick={handleRequestCardClick}
        className={classnames('flexbox', 'justify-start', styles['analysis-card'])}>
        <div className={classnames('flexbox', 'justify-center', 'align-center', styles.icon)}>
          <FileYellowIcon />
        </div>
        <div className={styles.inner}>
          <div className={styles.content}>
            <p className={classnames('headline', styles['content-date'])}>{name}</p>
            <Dotdotdot tagName="p" clamp={1} className={classnames('body', 'body_medium', styles['content-age'])}>
              {formattedDate}
            </Dotdotdot>
          </div>
          {isExpert && (
            <div
              onClick={(event) => handleToggleModal(event)}
              className={styles.delete__button}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => handleToggleModal(event)}>
              <Delete />
            </div>
          )}
        </div>
      </div>
      {isUnsetCheckupModal && (
        <UnsetCheckupModal
          patientId={patientId}
          checkUpId={id}
          isOpen={isUnsetCheckupModalOpen}
          onClose={toggleUnsetCheckupModalOpen}
        />
      )}
    </>
  );
};
