import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertSettings } from 'api/generated';
import classNames from 'classnames';
import { getExpertSettings, updateExpertSettings } from 'features/expert/thunks';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch } from 'store/reducers';
import { ButtonDefault, SwitchButton } from 'UIcomponents';

import styles from './styles.module.scss';

interface FormValues {
  cooperationNotification: boolean;
  stopCooperationNotification: boolean;
  newAnalysisNotification: boolean;
  anamnesisUpdateNotification: boolean;
  patientUpdateDiaryNotification: boolean;
  newMessageInChatNotification: boolean;
}

export const ExpertNotificationsTab: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.expertProfileTab' });
  const [expertSettings, setExpertSettings] = useState<ExpertSettings>({} as ExpertSettings);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      cooperationNotification: expertSettings.cooperationNotification,
      stopCooperationNotification: expertSettings.stopCooperationNotification,
      newAnalysisNotification: expertSettings.newAnalysisNotification,
      anamnesisUpdateNotification: expertSettings.anamnesisUpdateNotification,
      patientUpdateDiaryNotification: expertSettings.patientUpdateDiaryNotification,
      newMessageInChatNotification: expertSettings.newMessageInChatNotification,
    },
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = async (data: FormValues) => {
    await dispatch(
      updateExpertSettings({
        ...data,
      }),
    )
      .then(unwrapResult)
      .then(() => notify('success', t('notifySucces')))
      .catch((error) => {
        notify('error', error.message);
      });
  };

  useEffect(() => {
    dispatch(getExpertSettings())
      .then(unwrapResult)
      .then((result) => {
        setExpertSettings(result);
        reset(result);
      });

    return () => {
      setExpertSettings({} as ExpertSettings);
      reset({} as FormValues);
    };
  }, [dispatch, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <h4 className={styles['expert-info-form__title']}>{t('notifications')}</h4>

      <p className={classNames('body_medium', styles['expert-info-form__subtitle'])}>{t('description')}</p>

      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('requestForCooperation')}</div>
          <Controller
            name="cooperationNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>
      <hr className={classNames(styles['expert-info-form__styled-hr'])} />
      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('terminationOfCooperation')}</div>
          <Controller
            name="stopCooperationNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <hr className={classNames(styles['expert-info-form__styled-hr'])} />

      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('⁠⁠addingANewAnalysis')}</div>
          <Controller
            name="newAnalysisNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <hr className={classNames(styles['expert-info-form__styled-hr'])} />
      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('changeOfData')}</div>
          <Controller
            name="anamnesisUpdateNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <hr className={classNames(styles['expert-info-form__styled-hr'])} />
      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('changesInTheClientDiary')}</div>
          <Controller
            name="patientUpdateDiaryNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <hr className={classNames(styles['expert-info-form__styled-hr'])} />

      <div className={classNames('flexbox', 'justify-between', styles['expert-info-form'])}>
        <div className={classNames(styles['expert-info-form__input'])}>
          <div className={classNames(styles['expert-info-form__label'])}>{t('chatNotifications')}</div>
          <Controller
            name="newMessageInChatNotification"
            control={control}
            render={({ field: { onChange, value } }) => <SwitchButton onChange={onChange} defaultValue={!!value} />}
          />
        </div>
      </div>

      <div className={classNames('flexbox', 'justify-end', styles['expert-info-form__buttons'])}>
        <ButtonDefault
          onClick={handleSubmit(onSubmit)}
          text={t('action')}
          type={'button'}
          isDisabled={!isDirty}
          customStyles={{ width: '200px', margin: 0 }}
        />
      </div>
    </form>
  );
};
