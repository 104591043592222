import { createAsyncThunk } from '@reduxjs/toolkit';
import { Checkup, CheckupResDto } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from './types';

export const getPatientCheckupsAsync = createAsyncThunk<
  CheckupResDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('checkups/patient', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.PatientsCheckupsApi.patientsCheckupsControllerGetCheckups({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getExpertCheckupsAsync = createAsyncThunk<
  CheckupResDto[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('checkups/expert', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerFindAllCheckups({
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getOnePatientCheckupAsync = createAsyncThunk<Checkup, string, ExtraParamsThunkType<SerializedError>>(
  'checkup/patient',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.PatientsCheckupsApi.patientsCheckupsControllerGetOneCheckup(id, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOneExpertCheckupAsync = createAsyncThunk<Checkup, string, ExtraParamsThunkType<SerializedError>>(
  'checkup/expert',
  async (id: string, { extra: { api }, rejectWithValue }) => {
    try {
      const language = getLanguageFromStorage();

      const { data } = await api.ExpertsCheckupsApi.expertsCheckupsControllerFindOnePatientCheckup(id, {
        params: { l: language },
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
