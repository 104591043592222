import classnames from 'classnames';
import React from 'react';
import { FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './styles.module.scss';
interface Props {
  onChange: (file: any) => void;
}

export const DropZone: FC<Props> = ({ onChange }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: 5, maxSize: 10000000 });

  useEffect(() => {
    acceptedFiles.map((file) => {
      onChange(file);
    });
  }, [acceptedFiles]);

  return (
    <section className={styles['drop-zone']}>
      <div {...getRootProps({ className: classnames('dropzone', styles['drop-zone__content']) })}>
        <input {...getInputProps()} />
        <p className={styles['drop-zone__text']}>Перетащите файл</p>
        <p className={styles['drop-zone__text']}>Чтобы отправить его в сообщении</p>
      </div>
    </section>
  );
};
