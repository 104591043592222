import { LogoBiogeekExpert, LogoBiogeekPatient } from 'assets/svg';
import classnames from 'classnames';
import { RolesEnum } from 'features/auth/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store/types';

import { HeaderContent } from './components';
import styles from './styles.module.scss';

export const HeaderDefault: FC = () => {
  const role = useSelector((state: RootState) => state.auth.role);

  const isPatient = role === RolesEnum.PATIENT;

  return (
    <header className={classnames('flexbox', 'justify-between', 'align-center', styles.header)}>
      <div className={classnames('flexbox', 'justify-start', 'align-center', styles.brand)}>
        <div className={classnames('flexbox', styles.logo)}>
          <Link to="/">{isPatient ? <LogoBiogeekPatient /> : <LogoBiogeekExpert />}</Link>
        </div>
      </div>

      <HeaderContent />
    </header>
  );
};
