import { reducer as analyzesReducer } from 'features/analyzes';
import { reducer as AuthReducer } from 'features/auth';
import { reducer as biomarkersReducer } from 'features/biomarkers';
import { reducer as chatReducer } from 'features/chat';
import { reducer as checkupsReducer } from 'features/checkups';
import { reducer as expertReducer } from 'features/expert';
import { reducer as expertonboardingReducer } from 'features/expertOnbording';
import { reducer as patientReducer } from 'features/patient';
import { reducer as patientOnboarding } from 'features/patientOnboarding';
import { reducer as promocodesReducer } from 'features/promocodes';
import { reducer as subscriptionsReducer } from 'features/subscriptions';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import actions from './actions';
import { AppDispatch } from './types';

const authPersistConfig = {
  storage,
  key: 'auth',
  whitelist: ['token', 'email', 'role'],
};

const analyzesPersistConfig = {
  storage,
  key: 'analyzes',
  whitelist: ['lastAnalysisId'],
};

export const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  expertOnboarding: expertonboardingReducer,
  patientOnboarding: patientOnboarding,
  biomarkers: biomarkersReducer,
  patient: patientReducer,
  chat: chatReducer,
  analyzes: persistReducer(analyzesPersistConfig, analyzesReducer),
  expert: expertReducer,
  subscriptions: subscriptionsReducer,
  promocodes: promocodesReducer,
  checkups: checkupsReducer,
  // cloudPayments: cloudPaymentsReducer,
});

export type State = ReturnType<typeof reducer>;
const typesForClearState = [actions.authActions.signOut, actions.patientsActions.deletePatientAsync.fulfilled.type];
const rootReducer = (state: State, action: Action): State => {
  let nextState = state as State | undefined;

  if (typesForClearState.includes(action.type)) {
    nextState = undefined;
  }

  return reducer(nextState, action);
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default rootReducer as typeof reducer;
