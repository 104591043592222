import classNames from 'classnames';
import { ContactForm } from 'components';
import termsStyles from 'layouts/TermsLayout/styles.module.scss';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'faq.privacyPolicy' });

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  return (
    <>
      <h5 className={termsStyles.subhead}>{t('title')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph1')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead1')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph2')}</p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph3')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead2')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragarph)}>{t('paragarph4')}</p>
      <ul className={termsStyles['unordered-list']}>
        <li className={classNames('body', 'body_medium')}>{t('paragarph5')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph6')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph7')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph7')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph8')}</li>
      </ul>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragarph9')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead3')}</h5>
      <p className={classNames('body', 'body_medium')}>{t('paragarph10')}</p>
      <ul className={termsStyles['unordered-list']}>
        <li className={classNames('body', 'body_medium')}>{t('paragarph11')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph12')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph13')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph14')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph15')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph16')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph17')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragarph18')}</li>
      </ul>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragarph19')}</p>
      <div className={classNames('body', 'body_medium')}>{t('paragarph20')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragarph21')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragarph22')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragarph23')}</div>
      <div className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragarph24')}</div>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragarph25')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead4')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph26')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead5')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph27')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead6')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph28')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead7')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph29')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead7')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph30')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead9')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph31')}</p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph32')}</p>
      <div className={classNames('body', 'body_medium')}>{t('paragraph33')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph34')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph35')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph36')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph37')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph38')}</div>
      <div className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph39')}</div>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph40')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead10')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph41')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead11')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph42')}</p>
      <ul className={termsStyles['unordered-list']}>
        <li className={classNames('body', 'body_medium')}>{t('paragraph43')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph44')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph45')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph46')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph47')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph48')}</li>
        <li className={classNames('body', 'body_medium')}>{t('paragraph49')}</li>
      </ul>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph50')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead12')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph51')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead13')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph52')}</p>
      <h5 className={termsStyles.subhead}>Cookies</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph53')}</p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>
        {t('paragraph54')}
        <a href="##">{t('here')}</a>.
      </p>
      <h5 className={termsStyles.subhead}>{t('subhead14')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph55')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead15')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph56')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead16')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph57')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead17')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph58')}</p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph59')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead18')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph60')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead19')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph61')}</p>
      <div className={classNames('body', 'body_medium')}>{t('paragraph62')}</div>
      <div className={classNames('body', 'body_medium')}>{t('paragraph63')}</div>
      <div className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph64')}</div>
      <h5 className={termsStyles.subhead}>{t('subhead20')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph65')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead21')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph66')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead22')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph67')}</p>
      <h5 className={termsStyles.subhead}>{t('subhead23')}</h5>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>
        {t('paragraph68')}{' '}
        <button className={termsStyles['text-button']} onClick={() => setModalOpened(true)}>
          {t('contactForm')}
        </button>
        .
      </p>
      <p className={classNames('body', 'body_medium', termsStyles.paragraph)}>{t('paragraph69')}</p>
      <ContactForm visible={modalOpened} setModalOpened={() => setModalOpened(false)} />
    </>
  );
};
