import classNames from 'classnames';
import { UserSubscriptionTab } from 'components';
import { selectAnamnes } from 'features/patient/selectors';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/reducers';
import { PatientRoutes } from 'types/routes';
import { LinkBack } from 'UIcomponents';

import { AnamnesisTab, PatientNutritionPlanTab, Tab, TabsWrapper, UserInfoTab } from './components';
import { UserProfilePatientDiaryTab } from './components';
import { PatientNotificationsTab } from './components/Tabs/PatientNotificationsTab';
import { PatientRecommendationsTab } from './components/Tabs/PatientRecommendationsTab';
import { PatientSettingsTab } from './components/Tabs/PatientSettingsTab';
import styles from './styles.module.scss';

export const UserProfile: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient.settingsTabs' });

  const width = useWindowWidth();
  const anamnes = useAppSelector(selectAnamnes);
  const isMobile = width <= 760;

  return (
    <MainLayout>
      <div className={classNames('flexbox', 'justify-between', 'align-start', styles['patient-profile'])}>
        {isMobile && <LinkBack pathname={PatientRoutes.SETTINGS} />}
        <TabsWrapper>
          <Tab title={t('myData')} tabName="user-info-tab">
            <UserInfoTab />
          </Tab>
          <Tab title={t('email')} tabName="user-settings">
            <PatientSettingsTab />
          </Tab>
          <Tab title={t('personalData')} tabName="user-anamnesis-tab">
            <AnamnesisTab id={anamnes.id} anamnes={anamnes} />
          </Tab>
          <Tab title={t('diary')} tabName="user-nutrition-diary-tab">
            <UserProfilePatientDiaryTab />
          </Tab>
          <Tab title={t('nutrition')} tabName="user-nutrition-plan-tab" withoutBackground>
            <PatientNutritionPlanTab />
          </Tab>
          <Tab title={t('recommendations')} tabName="user-recommendations-tab" withoutBackground>
            <PatientRecommendationsTab />
          </Tab>
          <Tab title={t('subscriptions')} tabName="user-subscription-tab">
            <UserSubscriptionTab />
          </Tab>
          <Tab title={t('notifications')} tabName="user-notifications-tab" withoutBackground>
            <PatientNotificationsTab />
          </Tab>
        </TabsWrapper>
      </div>
    </MainLayout>
  );
};
