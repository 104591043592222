import { SubscriptionDtoStatusEnum } from 'api/generated';
import { selectBiomarkersLoading } from 'features/biomarkers/selectors';
import { selectCheckupsLoadingStatus } from 'features/checkups/selectors';
import { getPatientCheckupsAsync } from 'features/checkups/thunks';
import {
  selectAllExpertsLoadingStatus,
  selectAssignedCheckupsStatus,
  selectGetPatientStatus,
  selectPatientData,
  selectPatientRecommendationsLoadingStatus,
} from 'features/patient/selectors';
import {
  calculatesRecommendationsAsync,
  findMeAsync,
  getAnamnesAsync,
  getAssignedCheckups,
  getExpertsAsync,
  getNutrionPlanAsync,
  getPatientDiaryAsync,
  getRecommendationsAsync,
} from 'features/patient/thunks';
import { selectPromocodes } from 'features/promocodes/selectors';
import { selectGetSubscriptionLoadingState } from 'features/subscriptions/selectors';
import { getSubscriptionsConstantsAsync, retreiveSubscriptionAsync } from 'features/subscriptions/thunks';
import { useSubscriptionData } from 'hooks/useSubscriptionData';
import { isEmpty } from 'lodash';
import {
  Analysis,
  AnalyzesHistory,
  CheckUp,
  CheckUps,
  CreateAnalysis,
  DashboardPatient,
  EditAnalysis,
  Experts,
  FAQ,
  Message,
  NewAnalysis,
  PatientOnboarding,
  Recommendation,
  Recommendations,
  UserProfile,
} from 'pages';
import { NewSubscription } from 'pages/NewSubscription';
import { UserSettings } from 'pages/UserProfile/components';
import { useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { SortDirectionFilter } from 'types/analyzes';
import { CommonRoutes, PatientRoutes } from 'types/routes';
import { getRandomInt } from 'utils/getRandomInt';

import { getManyAnalysisKinds, searchAnalyzesAsync } from '../features/analyzes/thunks';
import { findAllBiomarkersAsync, getUnitsCoefficients } from '../features/biomarkers/thunks';
import { Spinner } from '../UIcomponents';

export const PatientRouting = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const patient = useAppSelector(selectPatientData);

  const isBiomarkersLoading = useAppSelector(selectBiomarkersLoading) === LoadingStatus.pending;
  const isPatientLoading = useAppSelector(selectGetPatientStatus) === LoadingStatus.pending;
  const isExpertsLoading = useAppSelector(selectAllExpertsLoadingStatus) === LoadingStatus.pending;
  const isRecomendationsLoading = useAppSelector(selectPatientRecommendationsLoadingStatus) === LoadingStatus.pending;
  const isSubscriptionLoading = useAppSelector(selectGetSubscriptionLoadingState) === LoadingStatus.pending;
  const isCheckupsLoading = useAppSelector(selectCheckupsLoadingStatus) === LoadingStatus.pending;
  const isAssignedCheckupsLoading = useAppSelector(selectAssignedCheckupsStatus) === LoadingStatus.pending;

  const isLoading =
    isSubscriptionLoading ||
    isRecomendationsLoading ||
    isExpertsLoading ||
    isPatientLoading ||
    isBiomarkersLoading ||
    isCheckupsLoading ||
    isAssignedCheckupsLoading ||
    isEmpty(patient);

  const randomSeedForShuffle = useMemo(() => getRandomInt(99), []);

  useEffect(() => {
    function fetchData() {
      batch(() => {
        dispatch(findMeAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(findAllBiomarkersAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getAnamnesAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getNutrionPlanAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getUnitsCoefficients()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getManyAnalysisKinds());
        dispatch(calculatesRecommendationsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(searchAnalyzesAsync({ sortDirection: SortDirectionFilter.ASC, sortBy: 'date' })).catch((error) => {
          console.warn({ error });
        });
        dispatch(getRecommendationsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getPatientDiaryAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getSubscriptionsConstantsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(retreiveSubscriptionAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(
          getExpertsAsync({
            page: 1,
            limit: 2,
            seedForShuffle: randomSeedForShuffle,
          }),
        ).catch((error) => {
          console.warn({ error });
        });
        dispatch(getPatientCheckupsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getAssignedCheckups()).catch((error) => {
          console.warn({ error });
        });
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const promocodes = useAppSelector(selectPromocodes);
  const hasPromocodes = !!promocodes.length;

  const { subscriptionData, hasAccessToAnalyzes } = useSubscriptionData();
  const { status } = subscriptionData;

  const hasSubscriptionPage = status === SubscriptionDtoStatusEnum.PromocodeActive || !hasAccessToAnalyzes;

  if (isLoading) {
    return (
      <div className="main-spinner-wrapper">
        <Spinner radius={20} color="var(--background-primary)" />
      </div>
    );
  }

  if (!patient.isOnboardingCompleted) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={PatientRoutes.ONBOARDING_PATIENT} exact />
          <Route path={PatientRoutes.ONBOARDING_PATIENT} component={PatientOnboarding} />
          <Redirect to={PatientRoutes.ONBOARDING_PATIENT} exact />
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Redirect from="/" to={CommonRoutes.DASHBOARD} exact />
        <Route path={CommonRoutes.DASHBOARD} component={DashboardPatient} />
        <Route path={PatientRoutes.PROFILE} component={UserProfile} />
        <Route path={PatientRoutes.SETTINGS} component={UserSettings} />
        <Route path={CommonRoutes.MESSAGE} component={Message} />
        <Route exact path={PatientRoutes.RECOMMENDATIONS} component={Recommendations} />
        <Route path={PatientRoutes.RECOMMENDATION()} component={Recommendation} />
        <Route path={CommonRoutes.ANALYZES_HISTORY} component={AnalyzesHistory} />
        <Route exact path={CommonRoutes.NEW_ANALYSIS} component={NewAnalysis} />
        <Route path={CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY} component={CreateAnalysis} />
        <Route exact path={CommonRoutes.ANALYSIS()} component={Analysis} />
        <Route path={PatientRoutes.EDIT_ANALYSIS()} component={EditAnalysis} />
        <Route path={PatientRoutes.EXPERTS} component={Experts} />
        <Route path={PatientRoutes.SUBSCRIPTIONS} component={NewSubscription} />
        <Route exact path={CommonRoutes.CHECK_UPS} component={CheckUps} />
        <Route path={CommonRoutes.CHECK_UP()} component={CheckUp} />
        <Route path={CommonRoutes.FAQ} component={FAQ} />
        <Redirect to={CommonRoutes.DASHBOARD} exact />
      </Switch>
    </Router>
  );
};
