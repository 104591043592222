import classnames from 'classnames';
import { CSSProperties, FC } from 'react';
import { isNumberInRange } from 'utils/numbers';

import styles from './styles.module.scss';

const colorsMapping = {
  2: '--background-secondary-dark',
  3: '--background-secondary',
  4: '--secondary-yellow',
  6: '--yellow',
  7: '--secondary-red',
  10: '--red',
};

const maxRangeValues = Object.keys(colorsMapping).map(Number);

type ColorsRanges = keyof typeof colorsMapping;

type RecommendationScaleProps = {
  score: number;
  customStyles?: CSSProperties;
};

export const RecommendationScale: FC<RecommendationScaleProps> = ({ score, customStyles }) => {
  const scaleColor = maxRangeValues.find((item, index) => {
    return isNumberInRange(score, maxRangeValues[index - 1] || 0, maxRangeValues[index]);
  }) as ColorsRanges;

  return (
    <div className={styles['scale__wrapper']} style={customStyles}>
      <div className={styles['scale']}>
        <div
          className={styles['scale__inner']}
          style={{ width: `${10 * score}%`, background: `var(${colorsMapping[scaleColor]})` }}></div>
      </div>
      <p className={classnames('footnote', 'footnote_regular', styles['scale__value'])}>{score}/10</p>
    </div>
  );
};
