import * as React from 'react';

function SvgTube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="#262626" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#tube_svg__clip0)">
        <path d="M20 8c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM16.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM20.707 11.793l-6.5-6.5a.999.999 0 10-1.414 1.414l.14.14-9.644 9.643A4.371 4.371 0 002 19.601a4.403 4.403 0 004.4 4.4c1.174 0 2.28-.459 3.11-1.29l9.644-9.644.14.14a.999.999 0 101.413-1.414zM6 21a1 1 0 11.001-2 1 1 0 010 2zm4-3a1 1 0 11.001-2 1 1 0 010 2zm4.973-3.58c-.4.124-.857.094-1.214-.103l-.787-.444a3.123 3.123 0 00-2.95.003l-.775.437c-.325.18-.732.225-1.099.145l6.199-6.198 3.393 3.393-2.766 2.767z" />
        <path d="M12.96 11.71a1.014 1.014 0 01-1.42 0l-1.09-1.09 1.42-1.42 1.09 1.09c.39.39.39 1.03 0 1.42z" />
      </g>
      <defs>
        <clipPath id="tube_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTube;
