import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const UnreadMessage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'unreadMessage' });

  return (
    <div className={styles.title}>
      <p>{t('newMessage')}</p>
    </div>
  );
};
