import * as React from 'react';

function ClipIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width={19} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 5.5l-7.81 7.94a1.5 1.5 0 102.12 2.12l9.31-9.44a3.002 3.002 0 00-.973-4.892 3 3 0 00-3.269.65l-9.31 9.44a4.5 4.5 0 006.364 6.364L17.125 10"
        stroke="#8E8E8E"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClipIcon;
