import { ExpertUpdateExpertDtoGenderEnum } from 'api/generated';
import { selectExpert } from 'features/expert/selectors';
import { changeExpertAsync } from 'features/expert/thunks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { SwitchButton } from 'UIcomponents';

import styles from './styles.module.scss';

export const SearchSettingSection: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.profileSettingsTab' });

  const dispatch = useAppDispatch();

  const expert = useAppSelector(selectExpert);

  const isHiddenForPatients = expert.hiddenForPatients;

  const handleSwitchChange = () => {
    const { avatar, name, birthday, spesialization, jobExperience, gender, shortInfo } = expert;

    dispatch(
      changeExpertAsync({
        avatar: avatar,
        name: name,
        birthday: birthday || '',
        spesialization: spesialization,
        jobExperience: jobExperience || 0,
        gender: gender as unknown as ExpertUpdateExpertDtoGenderEnum,
        shortInfo: shortInfo || '',
        hiddenForPatients: !isHiddenForPatients,
      }),
    ).then(() => notify('success', t('notifyDataSuccess')));
  };

  return (
    <div className={styles.section}>
      <div className={styles.content__conteiner}>
        <p className={styles.title}>{t('showMyProfile')}</p>
        <p className={styles.content}>{t('showMyProfileContent')}</p>
      </div>
      <div className={styles.button__container}>
        <SwitchButton onChange={handleSwitchChange} defaultValue={!isHiddenForPatients} />
      </div>
    </div>
  );
};
