import classNames from 'classnames';
import { UserSubscriptionTab } from 'components';
import useWindowWidth from 'hooks/useWindowWidth';
import { MainLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpertRoutes } from 'types/routes';
import { LinkBack } from 'UIcomponents';

import { ExpertProfileTab, PaymentsDiaryTab, ProfileSettingsTab, Tab, TabsWrapper } from './components/Tabs';
import { ExpertNotificationsTab } from './components/Tabs/ExpertNotificationsTab';
import styles from './style.module.scss';

export const ExpertProfile: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expertProfile.menuTabs' });

  const width = useWindowWidth();
  return (
    <MainLayout>
      <div className={classNames('flexbox', 'justify-between', 'align-start', styles['expert-profile'])}>
        {width <= 760 && <LinkBack pathname={ExpertRoutes.PROFILE_SETTINGS} />}
        <TabsWrapper>
          <Tab title={t('myData')} tabName="expert-personal-data">
            <ExpertProfileTab />
          </Tab>
          <Tab title={t('profile')} tabName="expert-profile-settings">
            <ProfileSettingsTab />
          </Tab>
          <Tab title={t('paymentsDiary')} tabName="expert-payments-info">
            <PaymentsDiaryTab />
          </Tab>
          <Tab title={t('subscription')} tabName="expert-my-cards">
            <UserSubscriptionTab />
          </Tab>
          <Tab title={t('notifications')} tabName="expert-notifications">
            <ExpertNotificationsTab />
          </Tab>
        </TabsWrapper>
      </div>
    </MainLayout>
  );
};
