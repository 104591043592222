import { ExpertEducationDocumentStatusEnum } from 'api/generated';
import { ProofEducation } from 'components/ProofEducation';
import { getManyAnalysisKindsExperts } from 'features/analyzes/thunks';
import { selectBiomarkersLoading } from 'features/biomarkers/selectors';
import { findAllBiomarkersAsync, getUnitsCoefficients } from 'features/biomarkers/thunks';
import { selectCheckupsLoadingStatus } from 'features/checkups/selectors';
import { getExpertCheckupsAsync } from 'features/checkups/thunks';
import { selectExpertPatientsLoadingStatus, selectLoadingStatus } from 'features/expert/selectors';
import { findMeAsync as findExpert, sortPatientsAsync } from 'features/expert/thunks';
import {
  selectGetSubscriptionLoadingState,
  selectGetSubscriptionsConstantsState,
  selectSubscription,
} from 'features/subscriptions/selectors';
import { getSubscriptionsConstantsAsync, retreiveSubscriptionAsync } from 'features/subscriptions/thunks';
import { useUserAccess } from 'hooks/useUserAccess';
import { isEmpty } from 'lodash';
import {
  Analysis,
  BigCalendar,
  CheckUp,
  CheckUps,
  CreateAnalysis,
  CreateCustomCheckUp,
  DashboardExpert,
  EditAnalysis,
  ExpertOnboarding,
  ExpertProfile,
  FAQ,
  NewAnalysis,
  PatientProfile,
} from 'pages';
import { AssignCheckUp } from 'pages/AssignCheckUp';
import { ExpertSettings } from 'pages/ExpertProfile/components/Responsive';
import { Message } from 'pages/Message';
import { NewSubscriptionExperts } from 'pages/NewSubscriptionExperts';
import { PatientSettings } from 'pages/PatientProfile/components';
import { PatientProfileRecommendationCard } from 'pages/PatientProfileRecommendationCard';
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { LoadingStatus } from 'types';
import { CommonRoutes, ExpertRoutes } from 'types/routes';
import { Spinner } from 'UIcomponents';

import { selectActivePromocode } from '../features/promocodes/selectors';

export const ExpertRouting = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const expert = useAppSelector((state) => state.expert.me);
  const { educationDocumentStatus } = expert;
  const subscription = useAppSelector(selectSubscription);

  const hasFullAccess = useUserAccess();

  const isExpertLoading = useAppSelector(selectLoadingStatus) === LoadingStatus.pending;
  const areExpertPatientsLoading = useAppSelector(selectExpertPatientsLoadingStatus) === LoadingStatus.pending;
  const isSubscriptionLoading = useAppSelector(selectGetSubscriptionLoadingState) === LoadingStatus.pending;
  const areSubscriptionsConstantsLoading =
    useAppSelector(selectGetSubscriptionsConstantsState) === LoadingStatus.pending;
  const areBiomarkersLoading = useAppSelector(selectBiomarkersLoading) === LoadingStatus.pending;
  const activePromocode = useAppSelector(selectActivePromocode);
  const isCheckupsLoading = useAppSelector(selectCheckupsLoadingStatus) === LoadingStatus.pending;

  const hasActivePromocode = !!activePromocode;

  const isApprovedButNeverPaid =
    educationDocumentStatus === ExpertEducationDocumentStatusEnum.Approved &&
    isEmpty(subscription) &&
    !hasActivePromocode;

  const isDocumentStatusDeclinedOrNotSent =
    educationDocumentStatus === ExpertEducationDocumentStatusEnum.NotSent ||
    educationDocumentStatus === ExpertEducationDocumentStatusEnum.Declined;

  const isDocumentStatusChecking = educationDocumentStatus === ExpertEducationDocumentStatusEnum.Checking;

  const isExpertOnboardingUncompleted = !expert.isOnboardingCompleted;

  const isLoading =
    isExpertLoading ||
    areExpertPatientsLoading ||
    isSubscriptionLoading ||
    areSubscriptionsConstantsLoading ||
    areBiomarkersLoading ||
    isCheckupsLoading ||
    isEmpty(expert);

  useEffect(() => {
    function fetchData() {
      batch(() => {
        dispatch(findExpert()).catch((error) => {
          console.warn({ error });
        });

        dispatch(getSubscriptionsConstantsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(retreiveSubscriptionAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getUnitsCoefficients()).catch((error) => {
          console.warn({ error });
        });
        dispatch(
          sortPatientsAsync({
            page: 1, //TODO: need pagination
            limit: 1000,
            sortField: 'createdAt',
            sortDirection: 'DESC',
          }),
        );

        dispatch(findAllBiomarkersAsync());

        dispatch(getExpertCheckupsAsync()).catch((error) => {
          console.warn({ error });
        });
        dispatch(getManyAnalysisKindsExperts());
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="main-spinner-wrapper">
        <Spinner radius={20} color="var(--background-primary)" />
      </div>
    );
  }

  if (isExpertOnboardingUncompleted) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={CommonRoutes.ONBOARDING} exact />
          <Route exact path={CommonRoutes.ONBOARDING} component={ExpertOnboarding} />
          <Redirect to={CommonRoutes.ONBOARDING} exact />
        </Switch>
      </Router>
    );
  }

  if (isDocumentStatusDeclinedOrNotSent) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={ExpertRoutes.PROOF_EDUCATION} exact />
          <Route path={ExpertRoutes.PROFILE} component={ExpertProfile} />
          <Route path={ExpertRoutes.PROFILE_SETTINGS} component={ExpertSettings} />
          <Route path={ExpertRoutes.PROOF_EDUCATION} component={ProofEducation} />
          <Redirect to={ExpertRoutes.PROOF_EDUCATION} exact />
        </Switch>
      </Router>
    );
  }

  if (isDocumentStatusChecking) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={CommonRoutes.DASHBOARD} exact />
          <Route path={ExpertRoutes.PROFILE} component={ExpertProfile} />
          <Route path={ExpertRoutes.PROFILE_SETTINGS} component={ExpertSettings} />
          <Route path={CommonRoutes.DASHBOARD} component={DashboardExpert} exact />
          <Redirect to={CommonRoutes.DASHBOARD} exact />
        </Switch>
      </Router>
    );
  }

  if (isApprovedButNeverPaid) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={ExpertRoutes.SUBSCRIPTIONS} exact />
          <Route path={ExpertRoutes.PROFILE} component={ExpertProfile} />
          <Route path={ExpertRoutes.PROFILE_SETTINGS} component={ExpertSettings} />
          <Route path={ExpertRoutes.SUBSCRIPTIONS} component={NewSubscriptionExperts} exact />
          <Redirect to={ExpertRoutes.SUBSCRIPTIONS} exact />
        </Switch>
      </Router>
    );
  }

  if (hasFullAccess) {
    return (
      <Router>
        <Switch>
          <Redirect from="/" to={CommonRoutes.DASHBOARD} exact />
          <Route path={CommonRoutes.DASHBOARD} component={DashboardExpert} />

          <Route path={ExpertRoutes.PROFILE} component={ExpertProfile} />
          <Route path={ExpertRoutes.PROFILE_SETTINGS} component={ExpertSettings} />

          <Route path={ExpertRoutes.PATIENT_PROFILE()} component={PatientProfile} />
          <Route path={ExpertRoutes.PATIENT_SETTINGS()} component={PatientSettings} />

          <Route path={ExpertRoutes.CALENDAR} component={BigCalendar} />
          <Route path={CommonRoutes.MESSAGE} component={Message} />
          <Route exact path={CommonRoutes.ANALYSIS()} component={Analysis} />
          <Route path={CommonRoutes.FAQ} component={FAQ} />
          <Route path={CommonRoutes.CONTACT_FORM} />

          <Route exact path={CommonRoutes.NEW_ANALYSIS} component={NewAnalysis} />
          <Route path={CommonRoutes.NEW_ANALYSIS_IMPORT_MANUALLY} component={CreateAnalysis} />
          <Route path={ExpertRoutes.EDIT_ANALYSIS()} component={EditAnalysis} />

          <Route exact path={CommonRoutes.CHECK_UPS} component={CheckUps} />
          <Route path={CommonRoutes.CHECK_UP()} component={CheckUp} />
          <Route path={ExpertRoutes.ASSIGN_CHECKUP} component={AssignCheckUp} />
          <Route path={ExpertRoutes.CREATE_CUSTOM_CHECKUP} component={CreateCustomCheckUp} />
          <Route path={ExpertRoutes.PATIENT_RECOMMENDATION()} component={PatientProfileRecommendationCard} />
          <Route path={ExpertRoutes.SUBSCRIPTIONS} component={NewSubscriptionExperts} exact />

          <Redirect to={CommonRoutes.DASHBOARD} exact />
        </Switch>
      </Router>
    );
  }

  //default or when expired Subscription or when expired promocode
  return (
    <Router>
      <Switch>
        <Redirect from="/" to={ExpertRoutes.PROFILE_SETTINGS} exact />
        <Route path={ExpertRoutes.PROFILE_SETTINGS} component={ExpertSettings} />
        <Route path={ExpertRoutes.PROFILE} component={ExpertProfile} />
        <Route path={ExpertRoutes.SETTINGS} component={ExpertSettings} />
        <Route path={CommonRoutes.FAQ} component={FAQ} />
        <Route path={ExpertRoutes.SUBSCRIPTIONS} component={NewSubscriptionExperts} exact />
        <Redirect to={ExpertRoutes.SUBSCRIPTION} exact />
      </Switch>
    </Router>
  );
};
