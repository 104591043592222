import {
  DiaryOther,
  DiaryStress,
  DiaryStressStressLevelEnum,
  EnergyAndSleep,
  EnergyAndSleepEnergyLevelEnum,
  Nutrition,
  PatientDiary,
  PatientDiaryDayEnum,
  TrainingDurationEnum,
  TrainingTimeEnum,
  TrainingTrainingTypeEnum,
  Workout,
  WorkoutStepsPerDayEnum,
} from 'api/generated';
import classnames from 'classnames';
import { selectPatientId } from 'features/expert/selectors';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { DropdownOptions } from 'types';
import { ButtonDefault, DropdownDefault } from 'UIcomponents';

import Context from './Context';
import styles from './styles.module.scss';

export interface FormValues {
  nutrition: Nutrition;
  workout: Workout;
  energyAndSleep: EnergyAndSleep;
  stress: DiaryStress;
  other: DiaryOther;
}

export const patientDiaryDefaultValues: FormValues = {
  nutrition: {
    eatings: [{ name: '', time: '', products: '' }],
    waterRegime: '',
    drugsAndSupplements: '',
  },
  workout: {
    trainings: [
      {
        trainingType: TrainingTrainingTypeEnum.Cardio,
        time: TrainingTimeEnum.BeforeBreakfast,
        duration: TrainingDurationEnum.Twenty,
        commentary: '',
      },
    ],
    stepsPerDay: WorkoutStepsPerDayEnum._5000,
  },
  energyAndSleep: {
    energyLevel: EnergyAndSleepEnergyLevelEnum.Stable,
    sleep: '',
  },
  stress: {
    stressSituation: '',
    stressLevel: DiaryStressStressLevelEnum.Stable,
  },
  other: {
    other: '',
    selfPleasure: '',
  },
};

type Props = {
  children: React.ReactNode;
  tabRoutes: { route: (id: string) => string; label: string }[] | { route: string; label: string }[];
  form: UseFormReturn<FormValues, any>;
  isFormActive?: boolean;
  handleClearDiaryPages?: () => void;
  onSubmitProps?: (data: FormValues, diaryPage: PatientDiary, day: PatientDiaryDayEnum) => void;
  resolver?: (data: FormValues) => Promise<{ values: any; errors: any }>;
  selector: (day: PatientDiaryDayEnum) => any;
};

export const PatientDiaryLayout: React.FC<Props> = ({
  selector,
  children,
  form,
  isFormActive = true,
  tabRoutes,
  handleClearDiaryPages,
  onSubmitProps,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary' });

  const width = useWindowWidth();
  const params = new URLSearchParams(useLocation().search);
  const [day, setDay] = useState<PatientDiaryDayEnum>(
    params.get('DAY') ? (params.get('DAY') as PatientDiaryDayEnum) : PatientDiaryDayEnum._1,
  );
  const diaryPage = useSelector(selector(day)) as PatientDiary;
  const patientId = useSelector(selectPatientId);
  const { patientId: diaryPatientId, id, day: diaryDay, ...diary } = diaryPage || {};
  const history = useHistory();
  const { location } = history;

  const dayOptions: DropdownOptions<PatientDiaryDayEnum>[] = [
    { label: `${t('day')} 1`, value: PatientDiaryDayEnum._1 },
    { label: `${t('day')} 2`, value: PatientDiaryDayEnum._2 },
    { label: `${t('day')} 3`, value: PatientDiaryDayEnum._3 },
    { label: `${t('day')} 4`, value: PatientDiaryDayEnum._4 },
    { label: `${t('day')} 5`, value: PatientDiaryDayEnum._5 },
    { label: `${t('day')} 6`, value: PatientDiaryDayEnum._6 },
    { label: `${t('day')} 7`, value: PatientDiaryDayEnum._7 },
  ];

  const isResponsive = useMemo(() => {
    if (width < 640) {
      return true;
    }

    if (width < 1230 && width > 759) {
      return true;
    }

    if (width > 640 && width < 759) {
      return false;
    }

    if (width > 1230) {
      return false;
    }
  }, [width]);

  const setDayParam = (day: PatientDiaryDayEnum): void => {
    setDay(day);
    params.set('DAY', day);
    history.push({ search: params.toString() });
  };

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form;

  const onSubmit = async (data: FormValues) => {
    if (onSubmitProps) {
      await onSubmitProps(data, diaryPage, day);
    }
  };

  useEffect(() => {
    reset(diary);
  }, []);

  useEffect(() => {
    if (!params.has('DAY')) {
      params.append('DAY', PatientDiaryDayEnum._1);
      history.push({ search: params.toString() });
    }

    if (!diaryPage) {
      reset(patientDiaryDefaultValues);
    } else {
      reset(diary, { keepValues: false, keepDefaultValues: true });
    }
  }, [diaryPage, day]);

  return (
    <div className={styles.diary}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.diary__header}>
            <h4>{t('title')}</h4>
            <div className={classnames('flexbox', 'align-center', styles['diary__button-container'])}>
              {isFormActive && <ButtonDefault text={t('clear')} onClick={handleClearDiaryPages} variant="delete" />}
              {isFormActive && <ButtonDefault type="submit" text={t('save')} isDisabled={isSubmitting || !isDirty} />}
              {!isFormActive && !diaryPage && (
                <p style={{ color: 'var(--red)' }} className={classnames('subtitle')}>
                  {t('emptyDiary')}
                </p>
              )}
              <Controller
                name="day"
                render={({ field: { onChange } }) => {
                  const index = dayOptions.findIndex((elem) => elem.value === day);
                  return (
                    <DropdownDefault
                      options={dayOptions}
                      onChange={({ value }) => {
                        onChange(value);
                        setDayParam(value);
                      }}
                      variant={width < 1230 ? 'max-content' : 'small'}
                      selected={dayOptions[index]}
                      noOptionsMessage=""
                      isSearchable={false}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.diary__tabs}>
            {tabRoutes.map((tab, id) => (
              <Link
                to={`${typeof tab.route === 'string' ? tab.route : tab.route(patientId)}?DAY=${day}`}
                key={id}
                className={classnames(styles.diary__tab, {
                  [styles.diary__tab_selected]:
                    typeof tab.route === 'string'
                      ? tab.route === location.pathname
                      : tab.route(patientId) === location.pathname,
                })}>
                {tab.label}
              </Link>
            ))}
          </div>
          <Context.Provider value={isResponsive}>{children}</Context.Provider>
        </form>
      </FormProvider>
    </div>
  );
};
