import { CloseFileIcon, DocumentCheck } from 'assets/svg';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'UIcomponents';
import stringCircumcision from 'utils/stringCircumcision';

import styles from './styles.module.scss';

interface PropsCurrentFile {
  deletes?: (id: number | string) => void;
  File?: File;
  filename: string;
  URL?: string;
  index?: number | string;
  isLoading?: boolean;
  inChat: boolean;
}

export const CurrentFile: FC<PropsCurrentFile> = ({ File, deletes, index, inChat, isLoading, filename, URL }) => {
  const history = useHistory();

  const openFail = () => {
    URL && history.push(URL);
  };

  return (
    <div className={styles['current-file']}>
      <div className={styles['current-file__container']}>
        {isLoading ? (
          <Spinner variant={'secondary'} radius={20} />
        ) : URL ? (
          <a className={styles['current-file__button']} href={URL}>
            <DocumentCheck />
          </a>
        ) : (
          <DocumentCheck />
        )}
        <div className={styles['current-file__info']}>
          <p className={styles['current-file__text']}>{stringCircumcision(filename)}</p>
          {/*<p className={styles['current-file__text_grey']}>{returnFileSize(Number(File.size))}</p>*/}
        </div>
      </div>
      {!inChat && deletes && index && (
        <button
          className={styles['current-file__button']}
          onClick={() => {
            deletes(index);
          }}>
          <CloseFileIcon />
        </button>
      )}
    </div>
  );
};
