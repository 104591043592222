import { DocumentCheck } from 'assets/svg';
import classnames from 'classnames';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import stringCircumcision from 'utils/stringCircumcision';

import styles from './styles.module.scss';

interface FileProps {
  filename: string;
  URL: string;
  createdAt: string;
}

export const File: FC<FileProps> = ({ filename, createdAt, URL }) => {
  const { i18n } = useTranslation();

  const formattedDate =
    i18n.language === 'ru'
      ? moment(createdAt).locale('ru').format('YYYY, DD MMMM')
      : moment(createdAt).locale('en-gb').format('YYYY, DD MMMM');

  return (
    <a href={URL} target={'_blank'} rel="noreferrer" className={classnames(styles['file'])}>
      <div className={styles['file__circle']}>
        <DocumentCheck />
      </div>
      <div className={styles['file__info']}>
        <p className={styles['file__info-title']}>{stringCircumcision(String(filename))}</p>
        <div className={classnames('flexbox')}>
          <p className={classnames(styles['file__info-subtitle'], styles.subhead)}>{formattedDate}</p>
        </div>
      </div>
    </a>
  );
};
