import { Sign } from 'assets/svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type DisclaimerProps = {
  isDemoMode: boolean;
  hasAccess: boolean;
  hasPatientCooperateWithExpert: boolean;
  hasPatientFreeAttemptCreateAnalyzes: boolean;
};

export const Disclaimer: FC<DisclaimerProps> = ({
  isDemoMode,
  hasAccess,
  hasPatientCooperateWithExpert,
  hasPatientFreeAttemptCreateAnalyzes,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'newAnalysis' });

  const disclaimerTextWithAccess =
    !hasAccess && !hasPatientCooperateWithExpert ? t('startCooperaion') : t('disclaimer');

  const disclaimerText =
    isDemoMode && hasPatientFreeAttemptCreateAnalyzes
      ? t('hasPatientFreeAttemptCreateAnalyzes')
      : disclaimerTextWithAccess;

  return (
    <div className={styles.disclaimer}>
      <div className={styles.disclaimer__exclamator}>
        <Sign />
      </div>
      <p className={styles.disclaimer__text}>{disclaimerText}</p>
    </div>
  );
};
