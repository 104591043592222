import { selectRole } from 'features/auth/selectors';
import { selectActiveChatId, selectAttachments } from 'features/chat/selectors';
import { getAttachmentsAsync } from 'features/chat/thunks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';

import { FilesModal } from '../../../FilesModal';
import styles from './styles.module.scss';

interface MenuProps {
  close: (state: boolean) => void;
  chatId: string;
  boolean: boolean;
}

export const Menu: FC<MenuProps> = ({ close, boolean, chatId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'filesModal' });

  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenDeleteModalMessages, setIsOpenDeleteModalMessages] = useState<boolean>(false);
  const [isOpenModalFiles, setIsOpenModalFiles] = useState<boolean>(false);
  const activeChatId = useSelector(selectActiveChatId);
  const attachments = useSelector(selectAttachments);
  const role = useSelector(selectRole);

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
    close(false);
  };
  const closeDeleteModalChat = () => {
    setIsOpenDeleteModalMessages(false);
    close(false);
  };
  const closeModalFiles = () => {
    setIsOpenModalFiles(false);
    close(false);
  };

  useEffect(() => {
    attachments.length === 0 && dispatch(getAttachmentsAsync(activeChatId)).catch((error) => console.warn(error));
  }, []);

  return (
    <>
      <div className={styles.menu}>
        <div className={styles.menu__container}>
          <button onClick={() => setIsOpenModalFiles((state) => !state)} className={styles['menu__link']} type="button">
            {t('historyFiles')}
          </button>
          {/* <button
            onClick={() => setIsOpenDeleteModal((state) => !state)}
            className={styles['menu__link']}
            type="button">
            Очистить историю
          </button> */}
          {/* {role !== 'PATIENT' && (
            <button
              onClick={() => setIsOpenDeleteModalMessages((state) => !state)}
              className={styles['menu__link_delete']}
              type="button">
              Удалить диалог
            </button>
          )} */}
        </div>
      </div>
      {/* {isOpenDeleteModal && (
        <DeleteModal
          onDelete={deleteHistory}
          title={'Удаление истории файлов'}
          subtitle={'Вы действительно хотите удалить историю файлов ?'}
          isVisible={true}
          onClose={closeDeleteModal}
        />
      )}
      {isOpenDeleteModalMessages && (
        <DeleteModal
          onDelete={deleteChat}
          title={'Удаление диалога'}
          subtitle={'Вы действительно хотите удалить диалог ?'}
          isVisible={true}
          onClose={closeDeleteModalChat}
        />
      )} */}
      {isOpenModalFiles && <FilesModal onClose={closeModalFiles} />}
    </>
  );
};
