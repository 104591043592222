import { unwrapResult } from '@reduxjs/toolkit';
import { ExpertGetPatientsResDto } from 'api/generated';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { selectActiveChat } from 'features/chat/selectors';
import { selectChats } from 'features/expert/selectors';
import { getChatAsync, getPatientsAsync } from 'features/expert/thunks';
import { selectPatientChats, selectPatientExpert } from 'features/patient/selectors';
import { getChatsPatientAsync } from 'features/patient/thunks';
import { useClientSize, useToggle } from 'hooks';
import { MainLayout } from 'layouts';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/reducers';
import { Spinner } from 'UIcomponents';

import { Chat, EmptyMessage, ExpertAsideComponent, Header, ListMessages } from './components';
import styles from './style.module.scss';

enum AsideState {
  HasListMessages,
  HasAsideExpertProfile,
}

export const Message: FC = () => {
  const dispatch = useAppDispatch();

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const role = useAppSelector(selectRole);
  const expertsChatRooms = useAppSelector(selectChats);
  const patientsChatRooms = useAppSelector(selectPatientChats);
  const myExpert = useAppSelector(selectPatientExpert);
  const activeChat = useAppSelector(selectActiveChat);

  const [patients, setPatients] = useState<Array<{ label: string; value: string }> | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { isOpened: isSearchingChats, onOpenToggle: setSearchingChats } = useToggle();
  const { isOpened: isMobileAsideExpertInfoOpen, onToggle: toggleMobileAsideExpertInfo } = useToggle();

  const isExpert = role === RolesEnum.EXPERT;
  const chatRooms = isExpert ? expertsChatRooms : patientsChatRooms;
  const isEmptyChatRooms = chatRooms.length === 0 && !isSearchingChats;

  const myExpertId = myExpert ? myExpert.id : null;
  const activeChatExpertName = activeChat ? activeChat.name : null;
  const activeChatExpertId = activeChat ? activeChat.expertId : null;
  const isOpenChatRoom = !!activeChat.id;

  const createDropdownArray = (patients: ExpertGetPatientsResDto) => {
    const result = patients.data
      .filter((item) => item.cooperatedWithExpertAt !== null)
      .map((patient) => {
        return { label: patient.name, value: patient.id };
      });
    setPatients(result);
  };

  const getExpertChats = async () => {
    setLoading(true);
    await dispatch(getChatAsync({ searchQuery: '' }))
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getPatientChats = async () => {
    setLoading(true);
    await dispatch(getChatsPatientAsync({ searchQuery: '' }))
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getPatients = async () => {
    await dispatch(
      getPatientsAsync({
        page: 1, //TODO: need pagination
        limit: 10000,
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    )
      .then(unwrapResult)
      .then((result) => createDropdownArray(result));
  };

  useEffect(() => {
    isExpert ? getExpertChats() : getPatientChats();
    isExpert && getPatients();
  }, []);

  const patientAsideState = isOpenChatRoom ? AsideState.HasAsideExpertProfile : AsideState.HasListMessages;
  const state = isExpert ? AsideState.HasListMessages : patientAsideState;

  const asideMapping = {
    [AsideState.HasListMessages]: <ListMessages patients={patients} chats={chatRooms} onSearch={setSearchingChats} />,
    [AsideState.HasAsideExpertProfile]: activeChatExpertId && <ExpertAsideComponent expertId={activeChatExpertId} />,
  };

  const renderAside = (state: AsideState) => {
    return asideMapping[state];
  };

  const renderMobileChat = () => {
    return isEmptyChatRooms ? (
      <EmptyMessage patients={patients} isExpert={isExpert} />
    ) : (
      <div className={styles.aside_container}>
        {isOpenChatRoom ? (
          <Chat isOpenExpert={isOpenChatRoom} toggleMobileAsideExpertInfo={toggleMobileAsideExpertInfo} />
        ) : (
          renderAside(state)
        )}
      </div>
    );
  };

  const renderDesktopChat = () => {
    return isEmptyChatRooms ? (
      <EmptyMessage patients={patients} isExpert={isExpert} />
    ) : (
      <div className={styles.aside_container}>
        {renderAside(state)}
        {activeChat && <Chat isOpenExpert={isOpenChatRoom} />}
      </div>
    );
  };

  return (
    <MainLayout>
      <Header
        isOpenExpertInfoAside={isMobileAsideExpertInfoOpen}
        toggleMobileAsideExpertInfo={toggleMobileAsideExpertInfo}
        isOpenChatRoom={isOpenChatRoom}
        isPatient={!isExpert}
        activeChatRoomExpertId={activeChat.expertId}
        myExpertId={myExpertId}
        activeChatExpertName={activeChatExpertName}
      />
      {isLoading ? (
        <div className="main-spinner-wrapper">
          <Spinner radius={20} color="var(--background-primary)" />
        </div>
      ) : (
        <>
          {isMobileAsideExpertInfoOpen ? (
            <>{activeChatExpertId && <ExpertAsideComponent expertId={activeChatExpertId} />}</>
          ) : (
            <>{isMobile ? renderMobileChat() : renderDesktopChat()}</>
          )}
        </>
      )}
    </MainLayout>
  );
};
