import { createAsyncThunk } from '@reduxjs/toolkit';
import { Expert, ExpertCompleteOnboardingDto } from 'api/generated';
import { getLanguageFromStorage } from 'services/storageService';
import { ExtraParamsThunkType } from 'store/types';

import { SerializedError } from './types';

export const completeOnboardingAsync = createAsyncThunk<
  Expert,
  ExpertCompleteOnboardingDto,
  ExtraParamsThunkType<SerializedError>
>('/expert/me/onboarding', async (userData: ExpertCompleteOnboardingDto, { extra: { api }, rejectWithValue }) => {
  try {
    const language = getLanguageFromStorage();

    const { data } = await api.ExpertsApi.expertsControllerCompleteOnboarding(userData, {
      params: { l: language },
    });
    return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
