import { selectGetPatientDiaryStatus, selectPatientDiaryByDay } from 'features/expert/selectors';
import { PatientDiaryLayout } from 'layouts';
import { FormValues } from 'layouts/PatientDiaryLayout';
import { EnergyAndSleepTab, NutritionTab, OtherTab, StressTab, WorkoutTab } from 'layouts/PatientDiaryLayout/tabs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingStatus } from 'types';
import { ExpertRoutes } from 'types/routes';
import { Spinner } from 'UIcomponents';

export const PatientProfilePatientDiaryTab = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'patientDiary' });

  const loadingStatus = useSelector(selectGetPatientDiaryStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const form = useForm<FormValues>({
    // defaultValues: diary,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const tabs = [
    { route: ExpertRoutes.PATIENT_NUTRITION_DIARY_NUTRITION, label: t('tabs.nutrition') },
    { route: ExpertRoutes.PATIENT_NUTRITION_DIARY_WORKOUT, label: t('tabs.workout') },
    { route: ExpertRoutes.PATIENT_NUTRITION_DIARY_ENERGY, label: t('tabs.energy') },
    { route: ExpertRoutes.PATIENT_NUTRITION_DIARY_STRESS, label: t('tabs.stress') },
    { route: ExpertRoutes.PATIENT_NUTRITION_DIARY_OTHER, label: t('tabs.other') },
  ];

  return (
    <PatientDiaryLayout selector={selectPatientDiaryByDay} tabRoutes={tabs} isFormActive={false} form={form}>
      {isLoading ? (
        <Spinner radius={20} color={'secondary'} />
      ) : (
        <Switch>
          <Route
            exact
            path={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_NUTRITION()}`}
            component={() => <NutritionTab isInputsDisabled={true} />}
          />
          <Route
            exact
            path={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_WORKOUT()}`}
            component={() => <WorkoutTab isInputsDisabled={true} />}
          />
          <Route
            exact
            path={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_ENERGY()}`}
            component={() => <EnergyAndSleepTab isInputsDisabled={true} />}
          />
          <Route
            exact
            path={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_STRESS()}`}
            component={() => <StressTab isInputsDisabled={true} />}
          />
          <Route
            exact
            path={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_OTHER()}`}
            component={() => <OtherTab isInputsDisabled={true} />}
          />
          <Redirect to={`${ExpertRoutes.PATIENT_NUTRITION_DIARY_NUTRITION()}`} exact />
        </Switch>
      )}
    </PatientDiaryLayout>
  );
};
