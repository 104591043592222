import { unwrapResult } from '@reduxjs/toolkit';
import { Analysis, BiomarkerGetResDto, PatientGenderEnum } from 'api/generated';
import { Chart } from 'components';
import { actions } from 'features/analyzes';
import { selectAllAnalyzes, selectSelectedAnalysisId } from 'features/analyzes/selectors';
import { searchAnalyzesAsync } from 'features/analyzes/thunks';
import { selectBiomarkers } from 'features/biomarkers/selectors';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notify } from 'services/notificationService';
import { useAppSelector } from 'store/reducers';
import { useAppDispatch } from 'store/reducers';
import { SortDirectionFilter } from 'types/analyzes';
import { CommonRoutes } from 'types/routes';
import { DatePickerDropdown, DropdownDefault } from 'UIcomponents';
import { OptionProps } from 'UIcomponents/DropdownDefault/DropdownDefault';
import { areArraysEqual } from 'utils/array';

import styles from '../../styles.module.scss';

type MappedAnalyzsis = { name: string; marker: number; analysisId: string; isInAnalysis: boolean };

type IndicatorsChartProps = {
  gender: PatientGenderEnum;
};

export const IndicatorsChart: FC<IndicatorsChartProps> = ({ gender }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'patient' });

  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const allAnalyzes = useAppSelector(selectAllAnalyzes);
  const selectedAnalysisId = useAppSelector(selectSelectedAnalysisId);
  const biomarkers = useAppSelector(selectBiomarkers);
  const [analyzes, setAnalyzes] = useState<Analysis[]>(allAnalyzes);
  const [startDate, setStartDate] = useState<Date>(moment(allAnalyzes[0]?.date).toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(analyzes.length - 1);
  const [norm, setNorm] = useState([0, 0]);
  const [unit, setUnit] = useState('');
  const [options, setOptions] = useState<Array<{ value: string; label: string }>>([]);
  const [option, setOption] = useState<{ value: string; label: string }>();

  const [data, setData] = useState<MappedAnalyzsis[]>([]);
  const [maxValue, setMaxValue] = useState(100);

  const getAnalyzes = async (startDate: string, endDate: string) => {
    await dispatch(
      searchAnalyzesAsync({
        sortDirection: SortDirectionFilter.ASC,
        sortBy: 'date',
        startDate: new Date(startDate) > new Date(endDate) ? endDate : startDate,
        endDate: new Date(startDate) > new Date(endDate) ? startDate : endDate,
      }),
    )
      .then(unwrapResult)
      .then((result) => {
        setAnalyzes(result.data);
      })
      .catch((error) => {
        notify('error', error.message);
      });
  };

  const mapAnalyzes = (biomarkerId?: string) => {
    return analyzes.reduce<MappedAnalyzsis[]>((array, analysis) => {
      if (biomarkerId) {
        const marker = analysis.patientAnalysisBiomarkers?.find((biomarker) => biomarker.biomarkerId === biomarkerId);

        if (marker?.value) {
          array.push({
            name: moment(analysis.date).format('D MMM Y'),
            marker: marker?.value || 0,
            analysisId: analysis.id,
            isInAnalysis: marker?.value === undefined ? false : true,
          });
        }
      } else {
        array.push({
          name: moment(analysis.date).format('D MMM Y'),
          marker: 0,
          analysisId: analysis.id,
          isInAnalysis: false,
        });
      }
      return array;
    }, []);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    getAnalyzes(date.toISOString(), endDate.toISOString());
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    getAnalyzes(startDate.toISOString(), date.toISOString());
  };

  const handleAnalysisBarClick = (barData: Partial<MappedAnalyzsis>, index: number) => {
    setActiveIndex(index);
    dispatch(actions.setSelectedAnalysisId(analyzes[index].id));
    push(CommonRoutes.ANALYSIS(barData.analysisId), { allowGoBack: true });
  };

  const upperDomainValue = useMemo(() => {
    return Math.ceil(Math.max(maxValue, norm[1]) + (Math.max(maxValue, norm[1]) / 100) * 20);
  }, [maxValue, norm]);

  const calculateNorm = (biomarker: BiomarkerGetResDto) => {
    return [
      gender === 'male' ? biomarker.lowerNormMale : biomarker.lowerNormFemale,
      gender === 'male' ? biomarker.upperNormMale : biomarker.upperNormFemale,
    ];
  };

  useEffect(() => {
    if (biomarkers.length > 0) {
      const newOptions = biomarkers.reduce<Array<{ value: string; label: string }>>((array, biomarker) => {
        array.push({ value: biomarker.id, label: biomarker.label });
        return array;
      }, []);
      setOptions(newOptions);
    }
  }, [biomarkers]);

  useEffect(() => {
    if (analyzes.length > 0) {
      const index = analyzes.findIndex((analysis) => analysis.id === selectedAnalysisId);
      setActiveIndex(selectedAnalysisId ? index : analyzes.length - 1);
    }
    if (analyzes && biomarkers.length > 0) {
      setData(mapAnalyzes(option?.value));
    }
  }, [analyzes]);

  const clearSelection = () => {
    setOption(undefined);
    setData(mapAnalyzes());
    setNorm([0, 0]);
    setUnit('');
    setMaxValue(100);
  };

  const handleChangeBiomarker = (option: OptionProps<string>) => {
    if (option) {
      setOption(option);
      const newChartData = mapAnalyzes(option.value);
      setData(newChartData);
      setMaxValue(Math.max(...newChartData.map((item) => item.marker)));
      const biomarker = biomarkers.filter((biomarker) => biomarker.id === option.value)[0];
      setNorm(calculateNorm(biomarker));
      setUnit(biomarker.unit?.name || '');
    } else {
      clearSelection();
    }
  };

  const hasAnalyzes = allAnalyzes.length > 0;

  return (
    <div className={styles.indicators}>
      <div className={styles.indicators__header}>
        <h4 className={styles.indicators__title}>{t('indicators')}</h4>
        {hasAnalyzes ? (
          <div className={styles.indicators__selectors}>
            <DropdownDefault
              hideSelectedOptions={false}
              options={options}
              selected={option}
              onChange={handleChangeBiomarker}
              variant="small"
              placeholder={t('biomarkersPlaceholder') as string}
              className={styles.indicators__dropdown}
              isClearable
            />
            <div className={styles['indicators__date-selectors']}>
              <div className={styles['indicators__date-range']}>
                <DatePickerDropdown
                  selectedDay={startDate}
                  setSelectedDay={handleStartDateChange}
                  leftCalendarPosition
                />
                <span className={`body body_bold ${styles['indicators__range-divider']}`}>&mdash;</span>
                <DatePickerDropdown selectedDay={endDate} setSelectedDay={handleEndDateChange} />
              </div>
            </div>
          </div>
        ) : (
          <p className={`subhead subhead_medium ${styles['indicators-chart-header__norm']}`}>
            {t('indicatorsContent')}
          </p>
        )}
      </div>
      <div className={styles['indicators__chart']}>
        {hasAnalyzes && (
          <div className={styles['indicators-chart-header']}>
            <p className={`subhead subhead_medium ${styles['indicators-chart-header__norm']}`}>
              {areArraysEqual(norm, [0, 0]) ? t('selectBiomarker') : `${t('normal')} ${norm[0]}-${norm[1]} ${unit}`}
            </p>
            <div className={styles['indicators-chart-header__norm-zone']}>
              <div className={styles['indicators-chart-header__norm-square']}></div>
              <p className={`subhead subhead_medium ${styles['indicators-chart-header__norm-value']}`}>
                {t('normalZone')}
              </p>
            </div>
          </div>
        )}

        <div className={styles['indicators-chart-main']}>
          <Chart
            activeIndex={activeIndex}
            data={data}
            handleChartBarClick={handleAnalysisBarClick}
            norm={norm}
            unit={unit}
            upperDomainValue={upperDomainValue}
          />
        </div>
      </div>
    </div>
  );
};
