import { ContactForm } from 'components/ContactForm';
import { selectRole } from 'features/auth/selectors';
import { RolesEnum } from 'features/auth/types';
import { actions as patientActions } from 'features/patient/slice';
import { useClientSize, useToggle } from 'hooks';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from 'store/actions';
import { useAppDispatch } from 'store/reducers';
import { CommonRoutes, ExpertRoutes, PatientRoutes } from 'types/routes';
import { LanguageSelector } from 'UIcomponents';

import styles from './styles.module.scss';

type UserProfileMenuProps = {
  forwardedRef: RefObject<HTMLElement>;
  isActive?: boolean;
};

const ProfileSettingsRouteMapping = {
  [RolesEnum.EXPERT]: ExpertRoutes.PROFILE_SETTINGS,
  [RolesEnum.PATIENT]: PatientRoutes.SETTINGS,
};

const ProfileRouteMapping = {
  [RolesEnum.EXPERT]: ExpertRoutes.PROFILE,
  [RolesEnum.PATIENT]: PatientRoutes.PROFILE,
};

export const UserProfileMenu: FC<UserProfileMenuProps> = ({ forwardedRef, isActive = false }) => {
  //TODO: why dublicate UserProfileMenu
  const { t } = useTranslation('translation', { keyPrefix: 'ui.header' });

  const dispatch = useAppDispatch();
  const { getIsBreakpoint } = useClientSize();

  const { isOpened: isContactModalOpen, onToggle: toggleContactModal } = useToggle();

  const role = useSelector(selectRole);

  const isPatient = role === RolesEnum.PATIENT;
  const isMobile = getIsBreakpoint('sm');

  const handleSignOut = () => {
    dispatch(actions.authActions.signOut());
    if (isPatient) {
      dispatch(patientActions.unsetPatients());
    }
  };

  const getProfileRoute = () => {
    if (isMobile) {
      return ProfileSettingsRouteMapping[role as RolesEnum];
    } else {
      return ProfileRouteMapping[role as RolesEnum];
    }
  };

  const handleContactClick = () => {
    toggleContactModal();
  };

  return (
    <div ref={forwardedRef as React.RefObject<HTMLDivElement>} className={styles['main-menu']}>
      <LanguageSelector />
      {isActive && (
        <>
          <Link to={CommonRoutes.CHECK_UPS} className={styles['menu-item']}>
            {t('checkUps')}
          </Link>

          <Link to={getProfileRoute} className={styles['menu-item']}>
            {t('profile')}
          </Link>
        </>
      )}
      {isPatient && (
        <Link className={styles['menu-item']} to={CommonRoutes.ANALYZES_HISTORY} role="button" tabIndex={0}>
          {t('analyzesHistory')}
        </Link>
      )}
      <div
        className={styles['menu-item']}
        role="button"
        tabIndex={0}
        onClick={handleContactClick}
        onKeyUp={handleContactClick}>
        {t('feedback')}
      </div>
      <span
        className={styles['menu-item']}
        onClick={handleSignOut}
        onKeyDown={handleSignOut}
        role="button"
        tabIndex={0}>
        {t('signOut')}
      </span>
      <ContactForm visible={isContactModalOpen} setModalOpened={toggleContactModal} />
    </div>
  );
};
